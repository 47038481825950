import React from 'react';
import classnames from 'classnames';

import { Input, nameInput } from '..';

import styles from './checkbox.module.scss';

const Checkbox = ({
  name,
  disabled,
  onChange,
  className,
  children,
  currentValue,
  tooltip,
  testID,
  checkboxStyle,
  ...props
}) => (
  <Input
    {...props}
    tooltip={tooltip || children}
    name={name}
    className={classnames([
      className,
      styles.input,
      disabled && styles.disabled
    ])}
  >
    <div className={styles.container}>
      <input
        type="checkbox"
        id={name}
        checked={currentValue}
        onChange={e => onChange && onChange(e.target.checked)}
        disabled={disabled}
        data-cy={testID ?? props.fieldLabel}
      />
      <div className={checkboxStyle ?? styles.checkbox}>
        {currentValue ? (
          <img
            src={require('../../../assets/images/Check.png')}
            alt="checked"
          />
        ) : (
          '\u00A0'
        )}
      </div>
    </div>
  </Input>
);

const nCheckbox = nameInput(Checkbox);
export { nCheckbox as Checkbox };
export default nCheckbox;
