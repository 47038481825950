import moment from 'moment';

export function inflateChartSettings(
  settings,
  period,
  isDetail = false,
  chartId = null
) {
  const { datasource, category, filterArgs, yAxis } = settings;
  let ret = { datasource, category, filterArgs, yAxis };
  if (!isDetail && settings.type === 'bar') ret.series = settings.series;

  switch (period?.mode) {
    case 'today':
      ret.startDate = moment().startOf('day');
      ret.endDate = moment().endOf('day');
      break;
    case 'thisWeek':
      ret.startDate = moment().startOf('week');
      ret.endDate = moment().endOf('week');
      break;
    case 'thisMonth':
      ret.startDate = moment().startOf('month');
      ret.endDate = moment().endOf('month');
      break;
    case 'thisQuarter':
      ret.startDate = moment().startOf('quarter');
      ret.endDate = moment().endOf('quarter');
      break;
    case 'thisYear':
      ret.startDate = moment().startOf('year');
      ret.endDate = moment().endOf('year');
      break;
    case 'absolute':
      ret.startDate = period.startDate;
      ret.endDate = moment(period.endDate)
        .startOf('day')
        .subtract(1, 'days');
      break;
    case 'relative':
      ret.startDate = moment().subtract(period.number, period.unit);
      break;
    default:
      break;
  }

  if (settings.type === 'line' && !isDetail)
    ret.frequency = period?.frequency || 'monthly';
  if (settings.type === 'statistic') ret.frequency = 'total';
  console.log({ ...ret, chartId });
  return { ...ret, chartId };
}
