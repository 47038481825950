import {
  CLEAR_USER,
  FETCH_USER_PROFILE,
  LOAD_USER_RESPONSE,
  LOAD_ALL_USERS_RESPONSE,
  UPDATE_DEFAULT_PASSWORD_REQUEST,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_USER_REQUEST,
  SET_ACTIVE_COMPANY,
  SET_ACTIVE_GROUPS,
  SET_ACTIVE_PROJECT,
  CLEAR_ALL_USERS,
  LOAD_ALL_COMPANY_USERS_RESPONSE
} from '../constants/actionTypes';

export const initialState = {
  activeUser: {},
  users: {},
  allUsers: [],
  allLoaded: false,
  companyUsers: []
};

const user = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_USER_REQUEST:
    case UPDATE_PASSWORD_REQUEST:
    case UPDATE_DEFAULT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_USER_PROFILE:
      return {
        ...state,
        loading: false,
        activeUser: payload
      };

    case LOAD_USER_RESPONSE:
      let users = { ...state.users };
      users[payload._id] = payload;
      return {
        ...state,
        users
      };

    case LOAD_ALL_USERS_RESPONSE:
      let userHash = {};
      payload.forEach(u => (userHash[u._id] = u));
      return {
        ...state,
        users: userHash,
        allUsers: payload,
        allLoaded: true
      };

    case CLEAR_USER:
      let u = { ...state.users };
      delete u[payload];
      return {
        ...state,
        users: u
      };
    case CLEAR_ALL_USERS:
      return {
        ...state,
        allUsers: [],
        allLoaded: false
      };
    case SET_ACTIVE_COMPANY:
    case SET_ACTIVE_GROUPS:
    case SET_ACTIVE_PROJECT:
      return {
        ...state,
        allLoaded: false
      };

    case LOAD_ALL_COMPANY_USERS_RESPONSE:
      return {
        ...state,
        companyUsers: payload
      };

    default:
      return state;
  }
};

export default user;
