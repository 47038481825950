import React, { useState } from 'react';

import Card from '../../Card';
import List from '../../List';
import history from '../../../history';

export default function EquipmentSAList(props) {
  const [selectedTab, setState] = useState(0);

  const { activeEquipment, cardClassName } = props;

  const listContent = [
    activeEquipment?.linkedSafetyAudits?.filter(
      sa => sa.currentWfStage === 0 && !sa.isDeleted
    ),
    activeEquipment?.linkedSafetyAudits?.filter(
      sa => sa.currentWfStage === 1 && !sa.isDeleted
    ),
    activeEquipment?.linkedSafetyAudits?.filter(
      sa => sa.currentWfStage === 2 && !sa.isDeleted
    ),
    activeEquipment?.linkedSafetyAudits?.filter(
      sa => sa.currentWfStage === 3 && !sa.isDeleted
    )
  ];

  const activeSettings = [
    { key: 'title', label: 'Title' },
    { key: 'description' },
    {
      key: 'assignedTo',
      datatype: 'users',
      label: 'Assignee(s)'
    },
    {
      key: 'dueDate',
      label: 'Due By',
      datatype: 'date'
    }
  ];

  const tabList = {
    selected: selectedTab,
    onClick: idx => setState(idx),
    list: [
      {
        name: 'New',
        color: 'blue',
        count: listContent[0]?.length
      },
      {
        name: 'In Progress',
        color: 'blue',
        count: listContent[1]?.length
      },
      {
        name: 'Severity Review',
        color: 'blue',
        count: listContent[2]?.length
      },
      {
        name: 'Closed',
        color: 'blue',
        count: listContent[3]?.length
      }
    ]
  };

  return (
    <Card
      title="Safety Audits"
      testID="Safety Audits Card"
      className={cardClassName}
      rightButton={{
        text: 'Assign',
        testID: 'Assign',
        color: 'blue',
        onClick: () =>
          history.push(`/app/safetyAudits/create/`, {
            audit: {
              ownerType: 'equipment',
              ownerId: activeEquipment?._id,
              groupId: activeEquipment?.groupId,
              projectId: activeEquipment?.projectId ?? '',
              schedule: 'immediate'
            },
            linkedSafetyAuditTemplates:
              activeEquipment.linkedSafetyAuditTemplates
          })
      }}
      showHeader
    >
      <List
        saveKey="immediateRas"
        rowClick={row =>
          row.isCompleted
            ? history.push(`/app/safetyAudits/summary/${row._id}`)
            : history.push(`/app/safetyAudits/perform/${row._id}`)
        }
        data={listContent[selectedTab]}
        dataIsHash
        settings={activeSettings}
        tabs={tabList}
      />
    </Card>
  );
}
