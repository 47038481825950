import React, { Component } from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import isEqual from 'lodash/isEqual';

import InfoBar from '../InfoBar';
import Textbox from '../inputs/Textbox';
import Button from '../inputs/Button';
import FrequencyPicker from '../inputs/FrequencyPicker';
import DateRangePicker from '../inputs/DateRangePicker';
import DateUnitPicker from '../inputs/DateUnitPicker';
import classnames from 'classnames/bind';

import { updateAnalyticsDashboard } from '../../api/v4';

import styles from './picker.module.scss';

export default class AnalyticsPeriodPicker extends Component {
  constructor(props) {
    super(props);
    let period = this.props.period || {};

    this.state = {
      currentPeriod: { ...period },
      savedPeriod: period,
      periodNumber: period.number
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.period, this.props.period)) {
      const period = this.props.period || {};
      this.setState(
        {
          currentPeriod: { ...period },
          savedPeriod: period,
          periodNumber: period.number
        },
        () =>
          this.props.onChange &&
          this.props.onChange({ ...this.state.currentPeriod })
      );
    }
  }

  save = () => {
    let period = {
      mode: this.state.currentPeriod.mode,
      frequency: this.state.currentPeriod.frequency
    };

    if (period.mode === 'absolute') {
      period.startDate = this.state.currentPeriod.startDate;
      period.endDate = this.state.currentPeriod.endDate;
    } else if (period.mode === 'relative') {
      period.unit = this.state.currentPeriod.unit;
      period.number = this.state.currentPeriod.number;
    }
    updateAnalyticsDashboard(this.props.dashboardId, { period }).then(
      () => this.props.onSave && this.props.onSave(this.state.currentPeriod)
    );
  };

  itemChange = (i, v) => {
    this.setState(
      (state, props) => {
        let newState = { ...state };
        if ('number' in newState.currentPeriod) {
          newState.currentPeriod.number = Number(newState.currentPeriod.number);
        }
        if (
          newState.currentPeriod.mode === 'relative' &&
          !newState.currentPeriod.unit
        ) {
          newState.currentPeriod.unit = 'days';
        }
        newState.currentPeriod[i] = v;
        return newState;
      },
      () =>
        this.props.onChange &&
        this.props.onChange({ ...this.state.currentPeriod })
    );
  };

  periodNumberChange = () => this.itemChange('number', this.state.periodNumber);
  periodUnitChange = v => this.itemChange('unit', v);
  startDateChange = d => this.itemChange('startDate', d?.toString());
  endDateChange = d => this.itemChange('endDate', d?.toString());
  frequencyChange = v => this.itemChange('frequency', v);
  modeChange = v => this.itemChange('mode', v);

  render() {
    const value = this.state.currentPeriod || {};
    const { className } = this.props;

    let startDate = value.startDate;
    if (typeof startDate === 'string') startDate = moment(startDate);
    let endDate = value.endDate;
    if (typeof endDate === 'string') endDate = moment(endDate);

    return (
      <InfoBar color="white" className={classnames(styles.picker, className)}>
        <DateRangePicker
          className={styles.input}
          onChange={this.modeChange}
          currentValue={value.mode}
          fieldLabel="Date Range:"
          name="range"
          customRange
          customPeriod={this.props.showFrequencyPicker}
        />
        {value.mode === 'absolute' && (
          <div styles={{ display: 'column' }}>
            <div className={styles.period}>
              <SingleDatePicker
                date={startDate}
                onDateChange={this.startDateChange}
                focused={this.state.startDateFocused}
                onFocusChange={f =>
                  this.setState({ startDateFocused: f.focused })
                }
                placeholder="Start"
                isOutsideRange={d => false}
              />
              <SingleDatePicker
                date={endDate}
                onDateChange={this.endDateChange}
                focused={this.state.endDateFocused}
                onFocusChange={f =>
                  this.setState({ endDateFocused: f.focused })
                }
                placeholder="End"
                isOutsideRange={d => false}
              />
            </div>
            {startDate && endDate && moment(startDate).isAfter(endDate) && (
              <span className={styles.warning}> Invalid Date Rate</span>
            )}
          </div>
        )}
        {value.mode === 'relative' && (
          <div className={styles.period}>
            <span>Past</span>
            <Textbox
              className={`${styles.input} ${styles.time}`}
              currentValue={this.state.periodNumber || ''}
              onChange={v => {
                v = v.replace(/[^0-9]/g, '');
                this.setState({ periodNumber: Number(v) });
              }}
              enter={this.periodNumberChange}
              onBlur={this.periodNumberChange}
              type="text"
            />
            <DateUnitPicker
              className={styles.input}
              onChange={this.periodUnitChange}
              currentValue={value.unit}
              name="unit"
            />
          </div>
        )}
        {this.props.showFrequencyPicker && (
          <>
            <FrequencyPicker
              className={styles.input}
              onChange={this.frequencyChange}
              currentValue={value.frequency}
              fieldLabel="Period:"
              displayTooltip={true}
              name="frequency"
              tooltip="This option only applies to line graphs, and controls the time period each dot represents."
            />
            {!isEqual(this.state.currentPeriod, this.state.savedPeriod) &&
              !this.props.readOnly && (
                <Button
                  color="green"
                  onClick={this.save}
                  text="Set as Default"
                  className={styles.save}
                />
              )}
          </>
        )}
      </InfoBar>
    );
  }
}
