import React from 'react';
import './svg.css';

export default props => (
  <svg
    version="1.1"
    x={props.x || "0px"}
    y={props.y || "0px"}
    viewBox="0 0 80 80"
    width="30px"
    height="25px"
  >
    <path
      d="M24.4,2c-3.8,0-6.9,3.1-6.9,6.9v0.3h-4.4c-0.6,0-1,0.4-1,1v3.6H3c-0.6,0-1,0.4-1,1V67c0,0.6,0.4,1,1,1h42.9c0.6,0,1-0.4,1-1
    V14.8c0-0.6-0.4-1-1-1h-9.1v-3.6c0-0.6-0.4-1-1-1h-4.4V8.9C31.3,5.1,28.2,2,24.4,2z M44.9,15.8V66H4V15.8h8.1v3.7c0,0.6,0.4,1,1,1
    h22.7c0.6,0,1-0.4,1-1v-3.7H44.9z M34.8,11.2v3.6c0,0,0,0,0,0s0,0,0,0v3.7H14.1v-3.7c0,0,0,0,0,0s0,0,0,0v-3.6h4.4c0.6,0,1-0.4,1-1
    V8.9c0-2.7,2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9v1.3c0,0.6,0.4,1,1,1H34.8z"
    />
    <path
      d="M24.4,5.7c-1.8,0-3.2,1.4-3.2,3.2s1.4,3.2,3.2,3.2c1.8,0,3.2-1.4,3.2-3.2S26.2,5.7,24.4,5.7z M24.4,10.1
    c-0.7,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2c0.7,0,1.2,0.5,1.2,1.2S25.1,10.1,24.4,10.1z"
    />
    <path
      d="M25.7,38.4h3.9c0.6,0,1-0.4,1-1V29c0-0.6-0.4-1-1-1h-9.8c-0.6,0-1,0.4-1,1v8.5c0,0.6,0.4,1,1,1h3.9v4.4h-12
    c-0.6,0-1,0.4-1,1v5.4c-2,0.5-3.4,2.2-3.4,4.3c0,2.4,2,4.4,4.4,4.4s4.4-2,4.4-4.4c0-2.1-1.5-3.8-3.4-4.3v-4.4h11v4.4
    c-2,0.5-3.4,2.2-3.4,4.3c0,2.4,2,4.4,4.4,4.4s4.4-2,4.4-4.4c0-2.1-1.5-3.8-3.4-4.3v-4.4h10.5v4.4c-2,0.5-3.4,2.2-3.4,4.3
    c0,2.4,2,4.4,4.4,4.4s4.4-2,4.4-4.4c0-2.1-1.5-3.8-3.4-4.3v-5.4c0-0.6-0.4-1-1-1H25.7V38.4z M20.8,30h7.8v6.5h-7.8V30z M14.1,53.6
    c0,1.3-1.1,2.4-2.4,2.4s-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4S14.1,52.2,14.1,53.6z M39.6,53.6c0,1.3-1.1,2.4-2.4,2.4s-2.4-1.1-2.4-2.4
    s1.1-2.4,2.4-2.4S39.6,52.2,39.6,53.6z M27.1,53.6c0,1.3-1.1,2.4-2.4,2.4s-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4S27.1,52.2,27.1,53.6z"
    />
    <path
      d="M52.6,50.5C52.6,50.5,52.6,50.5,52.6,50.5l6.7,16.9c0.2,0.4,0.5,0.6,0.9,0.6s0.8-0.2,0.9-0.6l3.7-9.4c0,0,0,0,0,0
    c0,0,0,0,0,0l3-7.5c0,0,0,0,0,0l0,0c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0V16.4V5.7C68,3.7,66.3,2,64.3,2h-8c-2.1,0-3.7,1.7-3.7,3.7
    v10.7v33.7c0,0,0,0,0,0C52.5,50.2,52.5,50.3,52.6,50.5L52.6,50.5z M54.5,49.1V22.2h4.8v26.8H54.5z M61.2,22.2H66v26.8h-4.8V22.2z
     M66,20.2H54.5v-2.8H66V20.2z M60.2,64.3l-2.4-6c1.6-0.4,3.2-0.4,4.8,0L60.2,64.3z M63.4,56.4c-2.1-0.6-4.3-0.6-6.3,0L55,51.1h10.6
    L63.4,56.4z M54.5,5.7c0-1,0.8-1.7,1.7-1.7h8c1,0,1.7,0.8,1.7,1.7v9.7H54.5V5.7z"
    />
  </svg>
);
