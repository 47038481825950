import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  FREQUENCY_OPTIONS,
  MONTHLY_FREQUENCY_OPTIONS,
  TASK_CATEGORY_DROPDOWN,
  YEARLY_FREQUENCY_OPTIONS
} from '../../constants/constants';
import Card from '../../components/Card';
import {
  Dropdown,
  EmployeeDropdown,
  Checkbox,
  Textbox
} from '../../components/inputs';
import { DatePicker } from '../../components/inputs/DateTimePicker';
import DayOfWeekButtons from '../../components/inputs/DayOfWeekButtons';
import UserBadge from '../../components/UserBadge';
import RadioButtons from '../../components/inputs/RadioButtons';
import DueBy from '../../components/inputs/DueBy';
import { withSocket } from '../../utils/withSocket';
import history from '../../history';
import {
  addUserTaskRequest,
  deleteUserTaskRequest,
  updateUserTaskRequst
} from '../../actions/tasks';
import { getActiveLocationId, getActiveProject } from '../../selectors/company';
import { getActiveIncidentSelector } from '../../selectors/incidents';
import { getActiveTaskSelector } from '../../selectors/tasks';
import { getActiveSafetyWalkSelector } from '../../selectors/safetyWalks';
import { getLoggedInUser } from '../../selectors/users';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../components/Footer';
import Modal from '../../components/Modal';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import HierarchySelector from '../../components/HierarchySelector';
import LeftNav from '../../components/LeftNav';
import AttachmentUploadCard from '../../components/AttachmentUploadCard';
import CommentCard from './comments';
import {
  deleteAttachmentRequest,
  clearUploadedAttachments
} from '../../actions/attachments';
import { addMessage } from '../../actions/messages';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import { addComment, fetchAllUsersForLocation } from '../../api/v4';
import isEqual from 'lodash/isEqual';
import { SubmitSaveForLaterCancelFooter } from '../../components/Footer';
import { formatFiles } from '../../utils/attachmentSelector';

import styles from './task.module.scss';

export class TaskContainer extends Component {
  state = {
    groupId: '',
    projectId: '',
    label: '',
    description: '',
    currentCategory: [],
    dueDate: '',
    assignedTo: [],
    schedule: '',
    dueBy: '',
    dueByTime: '',
    repeatingOn: [],
    isEditing: false,
    hasUnsavedChanges: false,
    isCreating: false,
    openUnsavedChangesModal: false,
    openDeleteTaskModal: false,
    openEndRepeatingTask: false,
    openCreateRepeatingTask: false,
    isIndividual: false,
    frequency: '',
    comments: [],
    attachmentsToUpload: [],
    filesToUpload: [],
    attachmentsAdded: [],
    openCompleteModal: false,
    isPaused: false
  };

  componentDidMount() {
    this.setState((state, props) => {
      let newState = {};
      if (!props.activeTask?._id) {
        newState.groupId = props.activeLocation?._id;
        newState.projectId = props.activeProject?._id;
      }
      if (props.activeTask) {
        newState = { ...props.activeTask };
      }
      newState.isCreating = !props.activeTask?._id;
      newState.attachmentsAdded = props.addedAttachments;
      return newState;
    });
    let locationId =
      this.props.activeTask.locationId ?? this.props.activeTask.groupId;
    fetchAllUsersForLocation(locationId, {
      locationId,
      accessLevel: [500, 900]
    }).then(users => this.setState({ users }));
    if (
      this.props.activeTask?.ownerType === 'safetyWalks' &&
      this.props.activeSafetyWalk?._id
    ) {
      this.props.socketSubscribe({ id: this.props.activeSafetyWalk._id });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeTask && !isEqual(this.props.task, prevProps.task)) {
      this.setState({
        ...this.props.activeTask,
        attachmentsAdded: this.props.addedAttachments
      });
    }

    if (!isEqual(this.props.addedAttachments, prevProps.addedAttachments)) {
      this.setState({ attachmentsAdded: this.props.addedAttachments });
    }
  }

  handleAnswer = stateObject => {
    this.props.socketLock();
    this.setState({
      ...stateObject,
      hasUnsavedChanges: true
    });
  };

  canSubmit = () => {
    const {
      groupId,
      label,
      description,
      dueDate,
      assignedTo,
      currentCategory,
      schedule,
      dueBy,
      dueByTime,
      repeatingOn,
      frequency
    } = this.state;

    const validDueDate =
      dueDate && this.state.isCreating
        ? moment(dueDate).isAfter(moment())
        : true;

    return (
      (groupId || this.props.activeLocation._id) &&
      label &&
      description &&
      assignedTo?.length > 0 &&
      !assignedTo.every(a => a === null) &&
      currentCategory &&
      ((schedule === 'immediate' && dueDate && validDueDate) ||
        (schedule === 'repeating' &&
          frequency &&
          dueBy &&
          dueByTime &&
          (repeatingOn.length > 0 || frequency === 'quarterly')))
    );
  };

  handleTaskSubmit = async (isCompleted = false) => {
    if (this.state.isCreating) {
      if (this.state.filesToUpload?.length > 0) {
        let data = formatFiles(this.state.filesToUpload);
        await this.props.addUserTask({
          ...this.state,
          attachmentData: {
            data: data,
            ownerType: this.state.isIndividual ? 'individualTask' : 'task',
            photoType: 'defective'
          }
        });
      } else {
        await this.props.addUserTask({ ...this.state, isCompleted });
      }
      if (this.state.schedule !== 'immediate') {
        this.setState({
          isCreating: false,
          hasUnsavedChanges: false,
          openCreateRepeatingTask: true
        });
      } else {
        this.setState({
          isCreating: false,
          hasUnsavedChanges: false
        });
      }
    } else {
      await this.props.updateUserTask({ ...this.state, isCompleted });
      this.setState({ isEditing: false, hasUnsavedChanges: false });
      history.goBack();
    }
    this.props.socketDirty();
  };

  handleDeleteTask = () => {
    const allAttachments = [
      ...(this.props.addedAttachments || []),
      ...(this.props.activeTask.photosToUpload || [])
    ];
    this.props.socketLock();
    this.props.deleteUserTask({ ...this.state, attachments: allAttachments });
    this.props.socketDirty();
  };

  handleTaskOwnerChange = () => {
    this.props.updateUserTask({
      ...this.state,
      taskOwner: this.state.newOwnerId
    });
    this.setState({
      taskOwner: this.state.newOwnerId,
      newOwnerId: null,
      openOwnerModal: false
    });
  };

  updateTaskStage = () => {
    this.props.socketLock();
    this.props.updateUserTask({
      ...this.state,
      isCompleted: !this.state.isCompleted
    });
    this.setState(prevState => ({
      isCompleted: !prevState.isCompleted,
      isEditing: prevState.isCompleted
    }));
    this.props.socketDirty();
  };

  getQuickNav = () => {
    let leftNav = [{ label: 'Task Details', id: '0' }];
    if (this.state.schedule !== 'repeating')
      leftNav.push({ label: 'Attachments', id: '1' });
    if (!this.state.isCreating && this.state.schedule !== 'repeating')
      leftNav.push({ label: 'Comments', id: '2' });
    return leftNav;
  };

  addComment = async (comment, parentId) => {
    let fullComment = {
      value: comment,
      ownerId: this.props.activeTask._id,
      ownerType: 'task',
      parentId
    };

    addComment(fullComment).then(newComment => {
      this.setState({ comments: [...this.state.comments, newComment] });
    });
  };

  handleDiscardTask = () => {
    this.props.clearUploadedAttachments();
    this.state.backToDashboard
      ? history.push('/app/dashboard')
      : history.goBack();
  };

  deleteAttachment = attachment => {
    this.props.deleteAttachment(attachment);
    this.props.addMessage({
      error: false,
      message: 'Deleted successfully!'
    });

    let updatedAttachments = this.props.activeTask?.attachments?.filter(
      a => a !== attachment
    );
    this.setState(
      {
        hasUnsavedChanges: this.state.isCreating || this.state.isEditing,
        attachments: updatedAttachments
      },
      () => {
        if (!this.state.isCreating && !this.state.isEditing) {
          this.props.updateUserTask({
            ...this.state,
            attachments: updatedAttachments
          });
        }
      }
    );
  };

  addAttachments = async (attachments, photoType = '') => {
    let data = formatFiles(attachments);
    let attachmentData = {
      data,
      ownerId: this.props.activeTask._id ?? 'pending',
      ownerType: 'task',
      photoType: photoType
    };
    // need to update the task so the attachment array has the new photo ids
    await this.props.updateUserTask({
      ...this.state,
      _id: this.props.activeTask._id,
      attachmentData
    });
  };

  render() {
    const { activeTask, loggedInUser, activeIncident, locked } = this.props;

    const {
      groupId,
      label,
      description,
      dueDate,
      assignedTo,
      currentCategory,
      projectId,
      schedule,
      dueBy,
      dueByTime,
      repeatingOn,
      isEditing,
      hasUnsavedChanges,
      isCreating,
      openDeleteTaskModal,
      openUnsavedChangesModal,
      openEndRepeatingTask,
      openCreateRepeatingTask,
      isIndividual,
      frequency,
      backToDashboard,
      attachmentsToUpload,
      comments,
      attachmentsAdded,
      isCompleted,
      openCompleteModal,
      openOwnerModal,
      isPaused
    } = this.state;

    const ownerType = activeTask?.ownerType;
    const cantEditAll =
      [500, 900].indexOf(loggedInUser.accessLevel) === -1 &&
      activeTask.createdBy !== loggedInUser._id &&
      !isCreating &&
      activeTask.taskOwner !== loggedInUser._id;
    const isCreatedBy = activeTask?.createdBy === loggedInUser._id;
    let isAssignee = activeTask.assignedTo?.some(
      assignee => assignee === loggedInUser._id
    );

    const adminOrCreator =
      loggedInUser._id === activeTask?.createdBy ||
      [500, 900].indexOf(loggedInUser.accessLevel) > -1 ||
      loggedInUser._id === activeTask?.taskOwner;

    const showEditButton =
      !activeTask.isCompleted && !isCreating && !isEditing && adminOrCreator;

    const showPageActionsWhenComplete = activeTask?.isCompleted && !cantEditAll;

    const header = (
      <Header
        title="Tasks"
        section={
          !isEditing && !isCreating
            ? 'View Active'
            : isCreating
            ? 'Create Task'
            : 'Edit Active'
        }
        needsSaved={hasUnsavedChanges}
        clickBack={() =>
          hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : this.handleDiscardTask()
        }
        rightButtons={
          showEditButton && {
            visible: true,
            disabled:
              locked ||
              (!isEditing && !cantEditAll
                ? false
                : !isEditing
                ? !isAssignee
                : !isCreatedBy),
            text: 'Edit Task',
            color: 'blue',
            onClick: () => this.setState({ isEditing: true })
          }
        }
        pageActionOptions={
          showPageActionsWhenComplete || isEditing
            ? [
                {
                  label: 'Delete Task',
                  visible: adminOrCreator,
                  color: 'blueOutline',
                  onClick: () =>
                    this.props.activeTask.fromRepeating
                      ? this.setState({ openEndRepeatingTask: true })
                      : this.setState({ openDeleteTaskModal: true })
                },
                {
                  label: 'Reopen Task',
                  visible: isCompleted && adminOrCreator,
                  color: 'greenOutline',
                  onClick: this.updateTaskStage
                },
                {
                  label: 'Transfer Ownership',
                  visible:
                    !isCompleted &&
                    adminOrCreator &&
                    activeTask.schedule !== 'repeating',
                  color: 'blueOutline',
                  onClick: () => this.setState({ openOwnerModal: true })
                }
              ]
            : null
        }
      />
    );

    const isFromReport =
      ownerType === 'incident' && !!activeTask?.reportComponentName;

    const footer =
      (isEditing || isAssignee) &&
      !this.props.activeTask.fromRepeating &&
      !isCreating ? (
        <SubmitSaveForLaterCancelFooter
          saveButtonClick={() => this.handleTaskSubmit()}
          saveButtonDisabled={!hasUnsavedChanges}
          saveButtonText="Save"
          submitButtonText="Complete"
          cancelButtonClick={() =>
            hasUnsavedChanges
              ? this.setState({ openUnsavedChangesModal: true })
              : this.handleDiscardTask()
          }
          deleteButtonClick={() => this.setState({ openDeleteModal: true })}
          submitButtonClick={() => this.setState({ openCompleteModal: true })}
          submitButtonDisabled={isCompleted}
          hideCompleteButton={isFromReport}
        />
      ) : (
        <SaveCancelFooter
          saveButtonClick={() => this.handleTaskSubmit()}
          saveButtonDisabled={!this.canSubmit()}
          saveButtonText={isEditing ? 'Update' : 'Create'}
          cancelButtonClick={() =>
            backToDashboard ? history.push('/app/dashboard') : history.goBack()
          }
          editing={isEditing}
        />
      );

    const disabled = (!isEditing && !isCreating) || locked;
    const isFromRa =
      ownerType === 'safetyAuditQuestion' || ownerType === 'safetyWalks';
    const isReportCA =
      ownerType === 'incident' &&
      activeTask?.label?.includes('Corrective Action');
    const isFromEquipment = ownerType === 'equipment';
    const isForIncident = ownerType === 'incident';
    const limit =
      isForIncident && activeTask.stage
        ? activeIncident.permissions
            .filter(p => p[activeTask.stage] === 'Edit')
            .map(p => p.userId)
        : undefined;

    const validDueDate =
      dueDate && isCreating ? moment(dueDate).isAfter(moment()) : true;

    const isFromObservation = ownerType === 'observation';

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          showFooter={
            (!isCompleted &&
              (isEditing || (isAssignee && schedule !== 'repeating'))) ||
            isCreating
          }
          lockInfo={this.props.lockInfo}
          Left={<LeftNav items={this.getQuickNav()} />}
        >
          <Card showHeader title="Task Details" name={0}>
            {activeTask?._id && (
              <div className={styles.header}>
                <UserBadge
                  userId={activeTask.taskOwner || activeTask.createdBy}
                  showImage
                  showName
                  size="large"
                  byline="Assigned By"
                  className={styles.assignBy}
                />
                <div className={styles.assignAt}>
                  <div>
                    Assigned at{' '}
                    {moment(Date.parse(activeTask.createdAt)).format('hh:mm A')}
                  </div>
                  <div style={{ fontWeight: 'bold' }}>
                    {moment(Date.parse(activeTask.createdAt)).format('LL')}
                  </div>
                </div>
              </div>
            )}
            <HierarchySelector
              onGroupChange={v => this.handleAnswer({ groupId: v })}
              groupId={groupId}
              groupDisabled={
                disabled ||
                isForIncident ||
                isFromRa ||
                isFromEquipment ||
                isFromObservation
              }
              onProjectChange={v => this.handleAnswer({ projectId: v })}
              projectId={projectId}
              projectDisabled={
                disabled || isFromRa || isFromEquipment || isFromObservation
              }
            />
            <Textbox
              currentValue={label}
              fieldLabel="Title"
              onChange={label => this.handleAnswer({ label })}
              type="textarea"
              placeholder="Type a clear and concise title."
              isRequired={true}
              disabled={
                disabled ||
                isFromRa ||
                isFromReport ||
                isReportCA ||
                isFromObservation
              }
            />
            <Textbox
              currentValue={description}
              fieldLabel="Description"
              onChange={description => this.handleAnswer({ description })}
              type="textarea"
              placeholder="Describe what needs to be done to complete this Task. Be sure to include any important details."
              isRequired={true}
              disabled={disabled || cantEditAll}
            />

            <Dropdown
              options={TASK_CATEGORY_DROPDOWN}
              fieldLabel="Category/Reason"
              currentValue={currentCategory}
              onChange={v => this.handleAnswer({ currentCategory: v })}
              disabled={disabled || isFromReport || cantEditAll}
              isRequired={true}
              multi
              placeholder="Choose one or more options"
            />
            {!isFromRa &&
              !isFromReport &&
              !isEditing &&
              !isForIncident &&
              !isFromObservation && (
                <RadioButtons
                  fieldLabel="Schedule"
                  options={[
                    { value: 'immediate', label: 'Immediately' },
                    { value: 'repeating', label: 'Repeating' }
                  ]}
                  isRequired
                  onChange={v => this.handleAnswer({ schedule: v })}
                  currentValue={schedule}
                  disabled={disabled || isEditing || isFromEquipment}
                />
              )}
            {schedule === 'repeating' && (
              <Dropdown
                options={FREQUENCY_OPTIONS}
                fieldLabel="Frequency"
                currentValue={frequency}
                onChange={v => this.handleAnswer({ frequency: v })}
                isRequired={true}
                disabled={disabled || cantEditAll}
              />
            )}
            {!isFromRa &&
              !isFromReport &&
              !isForIncident &&
              !isFromObservation &&
              schedule === 'repeating' &&
              (frequency === 'weekly' ? (
                <DayOfWeekButtons
                  fieldLabel="Assign Every:"
                  inputClassName="dayOfWeekInput"
                  disabled={disabled || cantEditAll}
                  onChange={v => this.handleAnswer({ repeatingOn: v })}
                  currentValue={repeatingOn}
                  isRequired
                />
              ) : frequency !== 'quarterly' ? (
                <Dropdown
                  options={
                    frequency === 'monthly'
                      ? MONTHLY_FREQUENCY_OPTIONS
                      : YEARLY_FREQUENCY_OPTIONS
                  }
                  fieldLabel="Assign Every:"
                  currentValue={repeatingOn}
                  onChange={v => this.handleAnswer({ repeatingOn: [v] })}
                  isRequired
                  disabled={disabled || cantEditAll}
                />
              ) : (
                <></>
              ))}
            {schedule === 'repeating' &&
            (!isFromRa ||
              !isFromReport ||
              !isForIncident ||
              !isFromObservation) ? (
              <DueBy
                fieldLabel="Due By"
                isRequired
                disabled={disabled || (cantEditAll && !isCreatedBy)}
                name="dueBy"
                dueBy={dueBy}
                dueTime={dueByTime}
                onDueByChange={v => this.handleAnswer({ dueBy: v })}
                onDueTimeChange={v => this.handleAnswer({ dueByTime: v })}
              />
            ) : (
              <DatePicker
                fieldLabel="Due By"
                isRequired
                onChange={value => this.handleAnswer({ dueDate: value })}
                pickTime
                currentValue={dueDate}
                disabled={disabled || (cantEditAll && !isCreatedBy)}
                name="taskDueBy"
                warning={validDueDate ? '' : 'Due Date must be a future date'}
                touched={!validDueDate}
              />
            )}
            <EmployeeDropdown
              limit={limit}
              fieldLabel={isForIncident ? 'Assignee:' : 'Assignee(s)'}
              currentValue={assignedTo}
              onChange={v =>
                this.handleAnswer({ assignedTo: isForIncident ? [v] : v })
              }
              isRequired={true}
              disabled={
                disabled ||
                (cantEditAll && !isCreatedBy) ||
                (isFromObservation &&
                  !assignedTo.some(x => x === null || x === undefined))
              }
              searchable={true}
              name="employees"
              multi={!isForIncident}
              permissions={[100, 400, 500, 900]}
              group={groupId}
            />
            {!isFromRa && !isFromReport && !isForIncident && (
              <Checkbox
                fieldLabel="Assign Individually"
                name="isIndividual"
                onChange={value => this.handleAnswer({ isIndividual: value })}
                currentValue={isIndividual}
                disabled={
                  disabled || schedule === 'repeating'
                    ? !isCreating && !isEditing
                    : !isCreating
                }
              />
            )}
            {schedule === 'repeating' && !isCreating && (
              <Checkbox
                fieldLabel="Pause Task"
                name="pauseTask"
                onChange={value => this.handleAnswer({ isPaused: value })}
                currentValue={isPaused}
                disabled={disabled || (cantEditAll && !isCreatedBy)}
              />
            )}
          </Card>
          {schedule !== 'repeating' && (
            <AttachmentUploadCard
              addAttachments={this.addAttachments}
              deleteAttachment={this.deleteAttachment}
              isCreating={isCreating}
              isEditing={
                adminOrCreator
                  ? isEditing
                  : activeTask?.assignedTo?.includes(loggedInUser._id)
              }
              addedAttachments={attachmentsAdded}
              attachmentsToUpload={attachmentsToUpload}
              name={1}
              handleAnswer={(currentAttachments, currentFiles) => {
                this.handleAnswer({
                  attachmentsToUpload: currentAttachments,
                  filesToUpload: currentFiles,
                  hasUnsavedChanges: true
                });
              }}
              taskOwnedBy={activeTask?.ownerType}
              ownerType="task"
            />
          )}
          {!isCreating && schedule !== 'repeating' && (
            <CommentCard
              name={2}
              addComment={this.addComment}
              comments={comments}
            />
          )}
        </HeaderAndFooter>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() => this.handleDiscardTask()}
          savingWhat="a page"
        />
        <Modal
          title="Delete Task"
          titleClassName="redHeader"
          isOpen={openDeleteTaskModal}
          submitButtonColor="red"
          submitButtonText="Delete Task"
          onRequestClose={() => this.setState({ openDeleteTaskModal: false })}
          submitActions={() => this.handleDeleteTask()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Deleting a Task is permanent. No one will be able to view or edit
              this specific Task again.{' '}
            </span>
            A new Task that is similar or identical can still be created.
          </div>
        </Modal>
        <Modal
          title="End Repeating Task"
          titleClassName="redHeader"
          isOpen={openEndRepeatingTask}
          submitButtonColor="red"
          submitButtonText="Delete Task"
          onRequestClose={() => this.setState({ openEndRepeatingTask: false })}
          submitActions={() => this.handleDeleteTask()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Ending a Repeating Task will remove it from the Repeating section
              of the Task page.{' '}
            </span>
            Tasks that are already Active will not be affected, but no more will
            be created by this Repeating Task.
          </div>
        </Modal>
        <Modal
          title="Repeating Task"
          titleClassName="blueHeader"
          isOpen={openCreateRepeatingTask}
          onRequestClose={() =>
            backToDashboard ? history.push('/app/dashboard') : history.goBack()
          }
          hideButtons={true}
          testID="repeatingTask"
        >
          <div className="reportSectionContainer-emailAssigneeText">
            A Repeating Task has been created.{' '}
            <span
              style={{
                fontWeight: 'bold',
                fontStyle: 'italic'
              }}
            >
              It can be found in the Repeating section of the Tasks page.
              Editing or ending the a Repeating Task will only affect future
              Tasks, and will not alter any Tasks that are already Active
            </span>
          </div>
        </Modal>
        <Modal
          title="Mark as Complete"
          titleClassName="blueHeader"
          isOpen={openCompleteModal}
          submitButtonColor="blue"
          submitButtonText="Complete Task"
          onRequestClose={() => this.setState({ openCompleteModal: false })}
          submitActions={() => this.handleTaskSubmit(true)}
          cancelButtonText="Cancel"
          cancelActions={() => this.setState({ openCompleteModal: false })}
        >
          <div className={styles.text}>
            Are you sure you would like to mark this task complete? This will
            remove the task from the assignee(s) to-do list.
          </div>
        </Modal>
        <Modal
          title="Transfer Ownership"
          titleClassName="blueHeader"
          isOpen={openOwnerModal}
          submitButtonColor="blue"
          submitButtonText="Reassign"
          onRequestClose={() =>
            this.setState({ openOwnerModal: false, newOwnerId: null })
          }
          disableSubmit={!this.state.newOwnerId}
          submitActions={this.handleTaskOwnerChange}
          cancelButtonText="Cancel"
          cancelActions={() =>
            this.setState({ openOwnerModal: false, newOwnerId: null })
          }
        >
          <div className={styles.text}>
            From here, you can assign a different user to be the owner for this
            task. Task Ownership can only be reassigned to an Admin or
            Collaborator User with access to the Group/Establishment this task
            is in.
          </div>
          <div className="incidentContainer-reassignIncident">
            <UserBadge
              size="large"
              userId={
                this.props.activeTask.taskOwner ||
                this.props.activeTask.createdBy
              }
              showImage
              hoverText
            />
            <img
              src={require('../../assets/images/reassignArrow.png')}
              className="reassignArrow"
              alt="assign arrow"
            />
            <UserBadge
              userId={this.state.newOwnerId}
              showImage
              hoverText
              size="large"
            />
          </div>
          <EmployeeDropdown
            fieldLabel="Reassign task owner to:"
            limit={limit}
            group={groupId}
            employees={this.state.users}
            searchable={true}
            isRequired={true}
            permissions={[500, 900]}
            currentValue={
              this.state.newOwnerId ?? this.props.activeTask.taskOwner
            }
            onChange={v => {
              this.setState({ newOwnerId: v });
            }}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state),
  activeLocation: getActiveLocationId(state),
  activeProject: getActiveProject(state),
  activeTask: getActiveTaskSelector(state),
  activeIncident: getActiveIncidentSelector(state),
  activeSafetyWalk: getActiveSafetyWalkSelector(state),
  addedAttachments: getAddedAttachmentsSelector(state)
});

const mapDispatchToProps = dispatch => ({
  addUserTask: task => dispatch(addUserTaskRequest(task)),
  updateUserTask: task => dispatch(updateUserTaskRequst(task)),
  deleteUserTask: task => dispatch(deleteUserTaskRequest(task)),
  deleteAttachment: payload => dispatch(deleteAttachmentRequest(payload)),
  clearUploadedAttachments: () => dispatch(clearUploadedAttachments()),
  addMessage: payload => dispatch(addMessage(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSocket(TaskContainer));
