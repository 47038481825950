import React from 'react';

export default ({ width, height, x, y, ...props }) => (
  <svg
    width={width || '30px'}
    height={height || '25px'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M25.2238 31.0782L17.3949 23.0864L19.2188 20.9513L27.0695 28.9654C27.0695 28.9654 27.7221 29.6316 28.3746 28.9654C29.0272 28.2993 28.3746 27.6331 28.3746 27.6331L20.4347 19.5279L21.2821 18.5358C22.0544 18.7627 22.8894 18.9053 23.7881 18.9542L30.4443 25.749C31.4637 26.7896 31.4637 28.4767 30.4443 29.5173L28.9152 31.0782C27.8959 32.1188 26.2431 32.1188 25.2238 31.0782Z" />
    <path d="M5.29291 4.04617L14.1237 12.5306L12.1533 14.2858L3.96362 5.40314C3.71698 5.13563 3.37309 4.98395 3.01322 4.98395H2.35839C1.89995 4.98395 1.47512 4.73841 1.23925 4.33711L0.186019 2.54518C-0.122103 2.02095 -0.0411816 1.34992 0.382292 0.91763L0.898883 0.390283C1.32236 -0.0420079 1.9797 -0.124615 2.49324 0.189922L4.24862 1.26508C4.64173 1.50586 4.88227 1.93953 4.88227 2.40752V3.07599C4.88227 3.44335 5.03085 3.7944 5.29291 4.04617Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.4955 16.3084C32 16.3084 32 8.64765 32 8.64765C32 8.64765 32 8.30298 31.9284 7.75985C31.8609 7.24819 31.2573 7.07426 30.8997 7.43932L27.8935 10.5081C27.603 10.8047 27.1927 10.9443 26.7861 10.885L23.8331 10.4543C23.2589 10.3706 22.8077 9.91003 22.7257 9.32385L22.3038 6.30942C22.2457 5.89429 22.3825 5.47545 22.673 5.17893L25.7256 2.0627C26.0889 1.6919 25.9056 1.07095 25.3939 1.02559C25.107 1.00016 24.8078 0.986899 24.4955 0.986899C16.991 0.986899 16.991 8.64765 16.991 8.64765C16.991 8.64765 16.991 10.6557 17.9981 12.6157L2.63455 26.3007C2.63455 26.3007 0.350548 28.6322 2.63455 30.9638C4.91854 33.2954 7.20251 30.9638 7.20251 30.9638L20.5387 15.3514C21.5452 15.9343 22.8371 16.3084 24.4955 16.3084ZM6.54994 28.6322C6.54994 28.6322 6.54994 30.2976 4.91853 30.2976C3.28711 30.2976 3.28711 28.6322 3.28711 28.6322C3.28711 28.6322 3.28711 26.9668 4.91853 26.9668C6.54994 26.9668 6.54994 28.6322 6.54994 28.6322Z"
    />
  </svg>
);
