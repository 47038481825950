import React from 'react';
import { useDispatch } from 'react-redux';
import { setActiveTrainingResponse } from '../../../actions/training';
import Card from '../../Card';
import SafetyTrainingList from '../../training/SafetyTrainingList';
import history from '../../../history';

export default function EquipmentTrainingList(props) {
  const dispatch = useDispatch();
  const setActiveTraining = payload =>
    dispatch(setActiveTrainingResponse(payload));

  const { activeEquipment, cardClassName } = props;

  return (
    <Card
      title="Trainings"
      testID="Trainings Card"
      className={cardClassName}
      rightButton={{
        text: 'Assign',
        testID: 'Assign',
        color: 'blue',
        onClick: () => {
          setActiveTraining({
            isFromEquipment: true,
            equipmentId: activeEquipment._id,
            validTemplateIds: activeEquipment.linkedTrainingTemplates
          });
          history.push('/app/training/trainingContainer');
        }
      }}
      showHeader
    >
      <SafetyTrainingList trainings={activeEquipment.linkedTrainings} />
    </Card>
  );
}
