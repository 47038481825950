import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';

import history from '../../../history';
import {
  exportAnalytics,
  fetchAnalyticsDetail,
  fetchAnalyticsChart,
  fetchAnalyticsChartSettings,
  createAnalyticsChartSettings,
  updateAnalyticsChartSettings
} from '../../../api/v4';
import { inflateChartSettings } from '../../../utils/analytics';
import AnalyticsChart from '../../../components/AnalyticsChart';
import AnalyticsPeriodPicker from '../../../components/AnalyticsPeriodPicker';
import Button from '../../../components/inputs/Button';
import Card from '../../../components/Card';
import CheckboxGroup from '../../../components/inputs/CheckboxGroup';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import List from '../../../components/List';
import Modal from '../../../components/Modal';

import styles from './styles.module.scss';
import { TwoColumn } from '../../../components/inputs';

export class MyAnalyticsDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      allData: null,
      currentPeriod: { ...this.props.location?.state?.period } || {},
      savedPeriod: this.props.location?.state?.period,
      openDisplayModal: false,
      types: [],
      chartSettings: {},
      filteredTypes: [],
      selectedTypes: []
    };
  }

  loadData = (filteredColumns = null) => {
    fetchAnalyticsDetail(
      inflateChartSettings(
        this.state?.chart,
        this.state.currentPeriod,
        true,
        this.props.match?.params?.chartId
      )
    ).then(data => {
      this.setState({
        data: data.data,
        allData: data.allData,
        types: data.types,
        filteredTypes: filteredColumns?.length
          ? filteredColumns
          : data?.filteredColumns?.length
          ? data?.filteredColumns
          : data.types?.length
          ? data.types
          : []
      });
    });
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.location, prevProps.location)) {
      this.setState({ data: null }, this.loadData);
    }
    if (!isEqual(this.props.period, prevProps.period)) {
      this.setState({ data: null }, this.loadData);
    }
  }

  componentDidMount() {
    fetchAnalyticsChart(this.props.match?.params?.chartId).then(data =>
      this.setState({ chart: data, readOnly: data.readOnly }, this.loadData)
    );
    fetchAnalyticsChartSettings(this.props.match?.params?.chartId).then(data =>
      this.setState({
        filteredTypes: data.filteredColumns ?? [],
        chartSettings: data
      })
    );
  }

  onPeriodChange = v => {
    this.setState(
      {
        currentPeriod: v,
        data: null
      },
      this.loadData
    );
  };

  onPeriodSave = v => {
    this.setState({ savedPeriod: v });
  };

  exportDetailsViewToCSV = async () => {
    const src = await exportAnalytics({
      ...inflateChartSettings(
        this.state?.chart,
        this.state.currentPeriod,
        true,
        this.props.match?.params?.chartId
      ),
      title: this.state.chart.title
    });
    window.location = src;
  };

  handleCustomColumns = () => {
    let { chartSettings, selectedTypes } = this.state;
    const chartId = this.props.match?.params?.chartId;

    if (chartSettings?._id) {
      updateAnalyticsChartSettings(chartId, {
        ...chartSettings,
        filteredColumns: selectedTypes
      }).then(data => {
        this.loadData(data.filteredColumns);
        this.setState({
          chartSettings: data,
          filteredTypes: data.filteredColumns
        });
      });
    } else {
      createAnalyticsChartSettings(chartId, {
        ...chartSettings,
        filteredColumns: selectedTypes
      }).then(data => {
        this.loadData(data.filteredColumns);
        this.setState({
          chartSettings: data,
          filteredTypes: data.filteredColumns
        });
      });
    }

    this.setState({
      openDisplayModal: false,
      selectedTypes: []
    });
  };

  cancelColumns = () => {
    this.setState({ openDisplayModal: false, selectedTypes: [] });
  };

  clearAll = () => {
    this.setState({ selectedTypes: [] });
  };

  selectAll = () => {
    this.setState({ selectedTypes: [...this.state.types] });
  };

  render() {
    const chart = this.state.chart;
    return (
      <HeaderAndFooter
        Header={
          <Header
            title="My Analytics"
            section="Detailed View"
            clickBack={() => history.goBack()}
            rightButtons={{
              text: 'Edit Card',
              color: 'blue',
              visible: !this.state.readOnly,
              onClick: () =>
                history.push({
                  pathname: `/app/myAnalytics/card/${chart._id}`,
                  state: { chart }
                })
            }}
          />
        }
        className={styles.container}
        showFooter={false}
      >
        <AnalyticsPeriodPicker
          className={styles.width}
          period={this.props.location?.state?.period}
          onChange={this.onPeriodChange}
          dashboardId={this.props.location?.state?.dashboardId}
          save={this.onPeriodSave}
          readOnly={this.state.readOnly}
          showFrequencyPicker
        />
        <Card className={styles.width}>
          <div className={styles.preview}>
            <div className={styles.title}>{chart?.title}</div>
            <AnalyticsChart
              className={styles.previewBody}
              settings={chart}
              period={this.state.currentPeriod}
            />
          </div>
        </Card>
        <List
          saveKey={
            this.state.data?.length ? `analyticsDetail-${chart?._id}` : null
          }
          className={styles.width}
          data={this.state.data}
          settings={this.state?.filteredTypes}
          actions={[
            {
              label: 'Download Data',
              color: 'green',
              onClick: this.exportDetailsViewToCSV,
              visible: this.state.data?.length
            },
            {
              label: 'Display Columns',
              color: 'blue',
              onClick: () =>
                this.setState({
                  selectedTypes: this.state.filteredTypes,
                  openDisplayModal: true
                }),
              visible: this.state.data?.length
            }
          ]}
        />
        <Modal
          title="Customize Table Columns"
          titleClassName="blueHeader"
          isOpen={this.state.openDisplayModal}
          submitButtonColor="blue"
          submitButtonText="Save"
          onRequestClose={this.cancelColumns}
          submitActions={this.handleCustomColumns}
        >
          <TwoColumn>
            <Button
              text="Select All"
              color="blue"
              onClick={this.selectAll}
              testID="selectAllButton"
            />
            <Button
              text="Clear"
              color="white"
              className={styles.button}
              onClick={this.clearAll}
            />
          </TwoColumn>
          <CheckboxGroup
            options={this.state.types.map(t => {
              return { value: t.key, label: t.label };
            })}
            currentValue={this.state.selectedTypes.map(t => {
              return { value: t.key, label: t.label };
            })}
            onChange={value => {
              const types = this.state.types.filter(t => value.includes(t.key));
              this.setState({ selectedTypes: types });
            }}
          />
        </Modal>
      </HeaderAndFooter>
    );
  }
}

export default MyAnalyticsDetail;
