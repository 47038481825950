import {
  ADD_DOCUMENT_FOLDER_REQUEST,
  ADD_DOCUMENT_FOLDER_RESPONSE,
  ADD_DOCUMENTS_TO_FOLDER_REQUEST,
  ADD_DOCUMENTS_TO_FOLDER_RESPONSE,
  ADD_QUALIFICATION_REQUEST,
  ADD_QUALIFICATION_RESPONSE,
  ADD_SAFETY_INSTRUCTION_REQUEST,
  ADD_SAFETY_INSTRUCTION_RESPONSE,
  ADD_SDS_REQUEST,
  ADD_SDS_RESPONSE,
  CREATE_CONTINUOUS_CARE_REQUEST,
  CREATE_CUSTOM_DOCUMENT_REQUEST,
  CREATE_CUSTOM_REPORT_QUESTIONS_REQUEST,
  CREATE_MY_DOCUMENT_REQUEST,
  DELETE_CONTINUOUS_CARE_REQUEST,
  DELETE_CUSTOM_DOCUMENT_REQUEST,
  DELETE_CUSTOM_REPORT_QUESTIONS_REQUEST,
  DELETE_DOCUMENT_FOLDER_REQUEST,
  DELETE_DOCUMENT_FOLDER_RESPONSE,
  DELETE_MY_DOCUMENT_REQUEST,
  DELETE_QUALIFICATION_REQUEST,
  DELETE_QUALIFICATION_RESPONSE,
  DELETE_SAFETY_INSTRUCTION_REQUEST,
  DELETE_SAFETY_INSTRUCTION_RESPONSE,
  DELETE_SDS_REQUEST,
  DELETE_SDS_RESPONSE,
  FETCH_CUSTOM_DOCUMENTS_REQUEST,
  FETCH_CUSTOM_DOCUMENTS_RESPONSE,
  FETCH_CUSTOM_DOCUMENTS_TEMPLATE_REQUEST,
  FETCH_CUSTOM_DOCUMENTS_TEMPLATE_RESPONSE,
  FETCH_DOCUMENT_FOLDERS_REQUEST,
  FETCH_DOCUMENT_FOLDERS_RESPONSE,
  FETCH_MY_DOCUMENTS_REQUEST,
  FETCH_MY_DOCUMENTS_RESPONSE,
  FETCH_QUALIFICATION_REQUEST,
  FETCH_QUALIFICATION_RESPONSE,
  FETCH_QUALIFICATION_TYPES_REQUEST,
  FETCH_QUALIFICATION_TYPES_RESPONSE,
  FETCH_REQUIRE_AUTHORIZATION_REQUEST,
  FETCH_REQUIRE_AUTHORIZATION_RESPONSE,
  FETCH_SAFETY_INSTRUCTION_REQUEST,
  FETCH_SAFETY_INSTRUCTION_RESPONSE,
  FETCH_SDS_REQUEST,
  FETCH_SDS_RESPONSE,
  SET_ACTIVE_CUSTOM_DOCUMENT,
  SET_ACTIVE_CUSTOM_DOCUMENT_TEMPLATE,
  SET_ACTIVE_DOCUMENT_FOLDER_REQUEST,
  SET_ACTIVE_DOCUMENT_FOLDER_RESPONSE,
  SET_ACTIVE_MY_DOCUMENT,
  SET_ACTIVE_QUALIFICATION,
  SET_ACTIVE_SAFETY_INSTRUCTION,
  SET_ACTIVE_SDS,
  UPDATE_CONTINUOUS_CARE_REQUEST,
  UPDATE_CUSTOM_DOCUMENT_REQUEST,
  UPDATE_CUSTOM_REPORT_QUESTIONS_REQUEST,
  UPDATE_DOCUMENT_FOLDER_REQUEST,
  UPDATE_DOCUMENT_FOLDER_RESPONSE,
  UPDATE_MY_DOCUMENT_REQUEST,
  UPDATE_QUALIFICATION_REQUEST,
  UPDATE_QUALIFICATION_RESPONSE,
  UPDATE_SAFETY_INSTRUCTION_REQUEST,
  UPDATE_SAFETY_INSTRUCTION_RESPONSE,
  UPDATE_SDS_REQUEST,
  UPDATE_SDS_RESPONSE
} from '../constants/actionTypes';

export const initialState = {
  safetyInstructions: [],
  sds: [],
  loading: false,
  activeSds: {},
  activeSafetyInstruction: {},
  activeQualification: {},
  lossRun: [],
  qualifications: [],
  qualificationTypes: [],
  documentFolders: [],
  activeDocumentFolder: {},
  activeMyDocument: {},
  myDocsList: [],
  requireAuthDocs: [],
  customDocuments: [],
  activeCustomDocument: {},
  customDocumentTemplates: [],
  activeCustomDocumentTemplate: {}
};

const documents = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_SAFETY_INSTRUCTION_REQUEST:
    case FETCH_SDS_REQUEST:
    case ADD_SDS_REQUEST:
    case UPDATE_SDS_REQUEST:
    case DELETE_SDS_REQUEST:
    case ADD_SAFETY_INSTRUCTION_REQUEST:
    case UPDATE_SAFETY_INSTRUCTION_REQUEST:
    case DELETE_SAFETY_INSTRUCTION_REQUEST:
    case ADD_QUALIFICATION_REQUEST:
    case FETCH_QUALIFICATION_REQUEST:
    case UPDATE_QUALIFICATION_REQUEST:
    case FETCH_QUALIFICATION_TYPES_REQUEST:
    case DELETE_QUALIFICATION_REQUEST:
    case FETCH_DOCUMENT_FOLDERS_REQUEST:
    case ADD_DOCUMENT_FOLDER_REQUEST:
    case UPDATE_DOCUMENT_FOLDER_REQUEST:
    case DELETE_DOCUMENT_FOLDER_REQUEST:
    case SET_ACTIVE_DOCUMENT_FOLDER_REQUEST:
    case ADD_DOCUMENTS_TO_FOLDER_REQUEST:
    case FETCH_MY_DOCUMENTS_REQUEST:
    case CREATE_MY_DOCUMENT_REQUEST:
    case UPDATE_MY_DOCUMENT_REQUEST:
    case DELETE_MY_DOCUMENT_REQUEST:
    case CREATE_CONTINUOUS_CARE_REQUEST:
    case UPDATE_CONTINUOUS_CARE_REQUEST:
    case DELETE_CONTINUOUS_CARE_REQUEST:
    case FETCH_REQUIRE_AUTHORIZATION_REQUEST:
    case FETCH_CUSTOM_DOCUMENTS_REQUEST:
    case CREATE_CUSTOM_DOCUMENT_REQUEST:
    case UPDATE_CUSTOM_DOCUMENT_REQUEST:
    case DELETE_CUSTOM_DOCUMENT_REQUEST:
    case FETCH_CUSTOM_DOCUMENTS_TEMPLATE_REQUEST:
    case CREATE_CUSTOM_REPORT_QUESTIONS_REQUEST:
    case UPDATE_CUSTOM_REPORT_QUESTIONS_REQUEST:
    case DELETE_CUSTOM_REPORT_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case FETCH_SAFETY_INSTRUCTION_RESPONSE:
      return {
        ...state,
        loading: false,
        safetyInstructions: payload
      };

    case FETCH_SDS_RESPONSE:
      return {
        ...state,
        loading: false,
        sds: payload
      };

    case SET_ACTIVE_SDS:
      return {
        ...state,
        activeSds: payload
      };

    case ADD_SDS_RESPONSE:
      return {
        ...state,
        loading: false,
        sds: [...state.sds, payload]
      };

    case UPDATE_SDS_RESPONSE: {
      const sdsList = state.sds.map(sds => {
        if (sds._id === payload._id) {
          return {
            ...payload
          };
        }

        return sds;
      });

      return {
        ...state,
        loading: false,
        sds: sdsList
      };
    }

    case DELETE_SDS_RESPONSE: {
      const sdsList = state.sds;

      const index = sdsList.findIndex(sds => sds._id === payload._id);

      return {
        ...state,
        loading: false,
        sds: [...sdsList.slice(0, index), ...sdsList.slice(index + 1)]
      };
    }

    case SET_ACTIVE_SAFETY_INSTRUCTION:
      return {
        ...state,
        activeSafetyInstruction: payload
      };

    case ADD_SAFETY_INSTRUCTION_RESPONSE:
      return {
        ...state,
        loading: false,
        safetyInstructions: [...state.safetyInstructions, payload]
      };

    case UPDATE_SAFETY_INSTRUCTION_RESPONSE: {
      const safetyInstructionList = state.safetyInstructions.map(
        safetyInstruction => {
          if (safetyInstruction._id === payload._id) {
            return {
              ...payload
            };
          }

          return safetyInstruction;
        }
      );

      return {
        ...state,
        loading: false,
        safetyInstructions: safetyInstructionList
      };
    }

    case DELETE_SAFETY_INSTRUCTION_RESPONSE: {
      const safetyInstructionList = state.safetyInstructions;

      const index = safetyInstructionList.findIndex(
        safetyInstruction => safetyInstruction._id === payload._id
      );

      return {
        ...state,
        loading: false,
        safetyInstructions: [
          ...safetyInstructionList.slice(0, index),
          ...safetyInstructionList.slice(index + 1)
        ]
      };
    }

    case FETCH_QUALIFICATION_RESPONSE:
      return {
        ...state,
        loading: false,
        qualifications: payload
      };

    case ADD_QUALIFICATION_RESPONSE:
      return {
        ...state,
        loading: false,
        qualifications: [...state.qualifications, payload]
      };

    case UPDATE_QUALIFICATION_RESPONSE: {
      let qualificationList = state.qualifications;

      const index = qualificationList.findIndex(
        qualification => qualification._id === payload._id
      );

      qualificationList[index] = payload;

      return {
        ...state,
        loading: false,
        qualifications: qualificationList
      };
    }

    case DELETE_QUALIFICATION_RESPONSE: {
      const qualificationList = state.qualifications;

      const index = qualificationList.findIndex(
        qualifiction => qualifiction._id === payload._id
      );

      return {
        ...state,
        loading: false,
        qualifications: [
          ...qualificationList.slice(0, index),
          ...qualificationList.slice(index + 1)
        ]
      };
    }

    case SET_ACTIVE_QUALIFICATION:
      return {
        ...state,
        activeQualification: payload
      };

    case FETCH_QUALIFICATION_TYPES_RESPONSE:
      return {
        ...state,
        loading: false,
        qualificationTypes: payload
      };

    case FETCH_DOCUMENT_FOLDERS_RESPONSE:
      return {
        ...state,
        loading: false,
        documentFolders: payload
      };

    case ADD_DOCUMENT_FOLDER_RESPONSE:
      return {
        ...state,
        loading: false,
        documentFolders: [...state.documentFolders, payload]
      };

    case UPDATE_DOCUMENT_FOLDER_RESPONSE: {
      let folderList = state.documentFolders;

      const index = folderList.findIndex(folder => folder._id === payload._id);

      folderList[index] = payload;

      return {
        ...state,
        loading: false,
        documentFolders: folderList
      };
    }

    case DELETE_DOCUMENT_FOLDER_RESPONSE: {
      const folderList = state.documentFolders;

      const index = folderList.findIndex(folder => folder._id === payload._id);

      return {
        ...state,
        loading: false,
        documentFolders: [
          ...folderList.slice(0, index),
          ...folderList.slice(index + 1)
        ]
      };
    }

    case SET_ACTIVE_DOCUMENT_FOLDER_RESPONSE:
      return {
        ...state,
        loading: false,
        activeDocumentFolder: payload
      };

    case ADD_DOCUMENTS_TO_FOLDER_RESPONSE:
      return { ...state, loading: false };

    case FETCH_MY_DOCUMENTS_RESPONSE:
      return { ...state, loading: false, myDocsList: payload };

    case SET_ACTIVE_MY_DOCUMENT:
      return { ...state, activeMyDocument: payload };

    case FETCH_REQUIRE_AUTHORIZATION_RESPONSE:
      return { ...state, requireAuthDocs: payload };

    case FETCH_CUSTOM_DOCUMENTS_RESPONSE:
      return { ...state, loading: false, customDocuments: payload };

    case SET_ACTIVE_CUSTOM_DOCUMENT:
      return { ...state, loading: false, activeCustomDocument: payload };

    case FETCH_CUSTOM_DOCUMENTS_TEMPLATE_RESPONSE:
      return { ...state, loading: false, customDocumentTemplates: payload };

    case SET_ACTIVE_CUSTOM_DOCUMENT_TEMPLATE:
      return {
        ...state,
        loading: false,
        activeCustomDocumentTemplate: payload
      };

    default:
      return {
        ...state
      };
  }
};

export default documents;
