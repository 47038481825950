import React, { Component } from 'react';
import { connect } from 'react-redux';

import Card from '../../../components/Card';
import history from '../../../history';
import { clearUploadedAttachments } from '../../../actions/attachments';
import { setActiveSafetyWalk } from '../../../actions/safetyWalks';
import { getAddedAttachmentsSelector } from '../../../selectors/attachments';
import { getActiveCompany } from '../../../selectors/company';
import { getActiveSafetyWalkSelector } from '../../../selectors/safetyWalks';
import { getLoggedInUser } from '../../../selectors/users';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../../components/Footer';
import Modal from '../../../components/Modal';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import { Textbox, EmployeeDropdown } from '../../../components/inputs';

import AttachmentCarousel from '../AttachmentCarousel';

import styles from '../itemCard.module.scss';

class BravoCard extends Component {
  state = {
    isEditing: true,
    hasUnsavedChanges: false,
    openDiscardModal: false,
    openDeletePictureModal: false,
    openUnsavedChangesModal: false,
    selectedAttachment: 0,
    title: '',
    description: '',
    impact: '',
    bravosGiven: [],
    pictures: []
  };

  componentDidMount() {
    const bravoIndex = parseInt(this.props.match.params.bravoIndex, 10);

    if (!Number.isNaN(bravoIndex)) {
      const bravo = this.props.activeSafetyWalk.bravos.find(
        b => b.overallOrder === bravoIndex
      );

      this.setState({
        ...bravo,
        isEditing: !bravo?._id
      });
    }
  }

  handleAnswer = stateObject => {
    this.setState({
      ...stateObject,
      hasUnsavedChanges: true
    });
  };

  handleDelete = stateObject => {
    this.setState({ ...stateObject });
  };

  handleBravosSubmit = () => {
    const activeSW = this.props.activeSafetyWalk;

    const bravos = {
      title: this.state.title,
      description: this.state.description,
      impact: this.state.impact,
      bravosGiven: this.state.bravosGiven,
      pictures: this.props.addedAttachments.concat(this.state.pictures),
      overallOrder:
        this.state.overallOrder !== undefined
          ? this.state.overallOrder
          : this.props.activeSafetyWalk.hazards.length +
            this.props.activeSafetyWalk.bravos.length
    };

    const bravoIndex = parseInt(this.props.match.params.bravoIndex, 10);
    if (!Number.isNaN(bravoIndex)) {
      const index = this.props.activeSafetyWalk.bravos.findIndex(
        k => k.overallOrder === bravoIndex
      );
      activeSW.bravos[index] = bravos;
    } else {
      activeSW.bravos = [...activeSW.bravos, bravos];
    }

    this.props.setSafetyWalk(activeSW);
    this.props.clearAttachments();
    this.setState({ hasUnsavedChanges: false });
    history.goBack();
  };

  canSubmit = () => {
    return (
      (this.state.title &&
        this.state.description &&
        this.state.hasUnsavedChanges) ||
      (this.state.overallOrder !== undefined &&
        this.props.addedAttachments.concat(this.state.pictures).length >
          this.state.pictures.length)
    );
  };

  handleDiscardBravos = () => {
    const { clearAttachments, activeSafetyWalk } = this.props;
    const {
      title,
      description,
      impact,
      bravosGiven,
      pictures,
      overallOrder
    } = this.state;

    const stateBravos = {
      title,
      description,
      impact,
      bravosGiven,
      pictures,
      overallOrder
    };
    const pos = this.state.overallOrder;

    activeSafetyWalk.bravos = activeSafetyWalk.bravos.filter(
      bravos => bravos.overallOrder !== stateBravos.overallOrder
    );

    activeSafetyWalk.hazards.forEach(haz => {
      if (haz.overallOrder > pos) {
        haz.overallOrder--;
      }
      return haz;
    });

    activeSafetyWalk.bravos.forEach(k => {
      if (k.overallOrder > pos) {
        k.overallOrder--;
      }
      return k;
    });

    this.props.setSafetyWalk(activeSafetyWalk);
    clearAttachments();
    history.goBack();
  };

  render() {
    const {
      isEditing,
      hasUnsavedChanges,
      openDiscardModal,
      openUnsavedChangesModal,
      title,
      description,
      impact,
      bravosGiven,
      pictures
    } = this.state;

    const { activeCompany } = this.props;

    const header = (
      <Header
        title={activeCompany.isDash ? 'DASH Report' : 'Safety Walk'}
        section={`${isEditing ? 'Edit' : 'View'} Bravo Info`}
        needsSaved={hasUnsavedChanges}
        clickBack={() => history.goBack()}
        rightButtons={{
          visible: isEditing,
          text: 'Discard Bravo',
          color: 'red',
          onClick: () => this.setState({ openDiscardModal: true })
        }}
      />
    );

    const footer = (
      <SaveCancelFooter
        saveButtonDisabled={!this.canSubmit()}
        saveButtonClick={this.handleBravosSubmit}
        cancelButtonClick={() => history.goBack()}
        editing={isEditing}
      />
    );

    return (
      <>
        <HeaderAndFooter Header={header} Footer={footer} showFooter={isEditing}>
          <Card className={styles.card}>
            <AttachmentCarousel
              isEditing={isEditing}
              pictures={pictures}
              handleDelete={this.handleDelete}
              loggedInUser={this.props.loggedInUser}
              ownerType="bravo"
            ></AttachmentCarousel>
            <div className={styles.padding}>
              <Textbox
                fieldLabel="Bravo Title"
                isRequired
                placeholder="Type a clear and concise title."
                onChange={title =>
                  this.setState({ title, hasUnsavedChanges: true })
                }
                currentValue={title}
                disabled={!isEditing}
              />
              <Textbox
                type="textarea"
                rows={5}
                fieldLabel="Description"
                placeholder="Describe this Bravo. Be sure to include any important details."
                isRequired
                onChange={v =>
                  this.setState({ description: v, hasUnsavedChanges: true })
                }
                currentValue={description}
                disabled={!isEditing}
              />
              <Textbox
                type="textarea"
                rows={5}
                fieldLabel="Impact on Safety Culture"
                placeholder="Describe how this will positively impact your company's safety culture."
                onChange={v =>
                  this.setState({ impact: v, hasUnsavedChanges: true })
                }
                currentValue={impact}
                disabled={!isEditing}
              />
              <EmployeeDropdown
                multi
                fieldLabel="Assign Bravo"
                currentValue={bravosGiven}
                onChange={values => this.handleAnswer({ bravosGiven: values })}
                disabled={!isEditing}
                searchable
                selectButtons
                name="multiEmployees"
              />
            </div>
          </Card>
        </HeaderAndFooter>
        <Modal
          title="Discard Bravo"
          titleClassName="redHeader"
          isOpen={openDiscardModal}
          submitButtonColor="red"
          submitButtonText="Discard Bravo"
          onRequestClose={() => this.setState({ openDiscardModal: false })}
          submitActions={() => this.handleDiscardBravos()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Discarding this Bravo will delete the information within and
              pictures taken (if any).
            </span>
          </div>
        </Modal>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() => history.goBack()}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  addedAttachments: getAddedAttachmentsSelector(state),
  activeSafetyWalk: getActiveSafetyWalkSelector(state),
  loggedInUser: getLoggedInUser(state),
  activeCompany: getActiveCompany(state)
});

const mapDispatchToProps = dispatch => ({
  setSafetyWalk: payload => dispatch(setActiveSafetyWalk(payload)),
  clearAttachments: () => dispatch(clearUploadedAttachments())
});

export default connect(mapStateToProps, mapDispatchToProps)(BravoCard);
