import React from 'react';
import styles from './quizBoxStyles.module.scss';

export default function QuizScoreBox(props) {
  const roundedScore = Number.parseFloat(props.quizScore).toFixed(2);
  return (
    <div className={styles.quizBox}>
      <div className={styles.attempt}> Attempt {props.attemptNumber} </div>
      <div className={styles.score}>
        {roundedScore}% {props.didPass ? '(Pass)' : '(Fail)'}
      </div>
    </div>
  );
}
