import React from 'react';

import { InputRow } from '../inputs';
import Button from '../inputs/Button';
import classnames from 'classnames/bind';

import styles from './styles.module.scss';

export const GenericFooter = ({ className, children, ...props }) => {
  return (
    <footer className={classnames(className, styles.footer)} {...props}>
      {children}
    </footer>
  );
};

export const AddFooter = ({ onClick, disabled, className, ...props }) => {
  return (
    <GenericFooter className={[styles.addFooter, className]} {...props}>
      <div onClick={onClick}>
        <svg width="256" height="56">
          <line x1="0" x2="256" y1="28" y2="28" strokeWidth="2" />
        </svg>
        <svg width="56" height="56">
          <circle cx="28" cy="28" r="20" strokeWidth="2" />
          <line
            x1="28"
            x2="28"
            y1="18"
            y2="38"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <line
            x1="18"
            x2="38"
            y1="28"
            y2="28"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </svg>
        <svg width="256" height="56">
          <line x1="0" x2="256" y1="28" y2="28" strokeWidth="2" />
        </svg>
      </div>
    </GenericFooter>
  );
};

export const SaveCancelFooter = ({
  saveButtonDisabled,
  saveButtonClick,
  saveButtonText,
  cancelButtonDisabled,
  cancelButtonClick,
  editing
}) => (
  <GenericFooter className={styles.buttonFooter}>
    <InputRow>
      <Button
        type="button"
        testID="saveSubmitButton"
        text={saveButtonText || (editing ? 'Save' : 'Create')}
        color={editing ? 'blue' : 'green'}
        onClick={saveButtonClick}
        disabled={saveButtonDisabled}
      />
      <Button
        onClick={cancelButtonClick}
        type="button"
        testID="cancelButton"
        text={editing ? 'Cancel' : 'Discard'}
        color={editing ? 'white' : 'red'}
        disabled={cancelButtonDisabled}
      />
    </InputRow>
  </GenericFooter>
);

export const SubmitSaveForLaterFooter = ({
  saveButtonDisabled,
  saveButtonClick,
  submitButtonDisabled,
  submitButtonClick,
  submitButtonText
}) => {
  return (
    <GenericFooter className={styles.buttonFooter}>
      <InputRow>
        <Button
          type="button"
          text="Save for Later"
          color="blue"
          onClick={saveButtonClick}
          disabled={saveButtonDisabled}
        />
        <Button
          testID="saveSubmitButton"
          onClick={submitButtonClick}
          type="button"
          text={submitButtonText ?? 'Submit'}
          color="green"
          disabled={submitButtonDisabled}
        />
      </InputRow>
    </GenericFooter>
  );
};

export const SubmitSaveForLaterCancelFooter = ({
  saveButtonDisabled,
  saveButtonClick,
  saveButtonText,
  submitButtonDisabled,
  submitButtonClick,
  submitButtonText,
  cancelButtonClick,
  cancelButtonDisabled,
  showDeleteInsteadOfCancel,
  deleteButtonClick,
  deleteButtonDisabled,
  hideCompleteButton = false
}) => {
  return (
    <GenericFooter className={styles.buttonFooter}>
      <InputRow>
        <Button
          type="button"
          text={saveButtonText || 'Save for Later'}
          color="blue"
          onClick={saveButtonClick}
          disabled={saveButtonDisabled}
        />
        {!hideCompleteButton ? (
          <Button
            onClick={submitButtonClick}
            type="button"
            text={submitButtonText ?? 'Submit'}
            color="green"
            disabled={submitButtonDisabled}
            testID="saveSubmitButton"
          />
        ) : null}
        {showDeleteInsteadOfCancel ? (
          <Button
            onClick={deleteButtonClick}
            type="button"
            text="Delete"
            color="red"
            disabled={deleteButtonDisabled}
          />
        ) : (
          <Button
            onClick={cancelButtonClick}
            type="button"
            text="Cancel"
            color="white"
            disabled={cancelButtonDisabled}
          />
        )}
      </InputRow>
    </GenericFooter>
  );
};
