import React from 'react';
import './svg.css';

export default props => (
  <svg
    width="30px"
    height="25px"
    x={props.x || '0px'}
    y={props.y || '0px'}
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
  >
    <g>
      <path d="M85.866,21.683H50.243h-0.301c1.617,0.637,3.111,1.709,4.419,3.131h4.204c5.656,0,10.257,7.161,10.257,15.963   c0,8.801-4.601,15.961-10.257,15.961l-7.621,0.014C55.099,54.129,58,47.311,58,40.069c0-9.605-5.151-17.422-11.483-17.422   s-11.484,7.816-11.484,17.422c0,8.048,3.619,14.826,8.516,16.816v-8.499c0-1.687,1.306-3.054,2.917-3.054   c1.611,0,2.918,1.367,2.918,3.054c0,1.686-1.307,3.053-2.918,3.053c-0.412,0-0.802-0.096-1.157-0.256v4.734   c0,0,0.038,1.695-0.539,2.578c-0.78,1.306-2.854,2.482-6.387,3.049c-5.996,0.964-43.93-0.681-36.632,3.726   c2.588,1.562,22.887,1.202,24.522,2.643c1.343,1.186-9.431-0.059-8.72,4.327c0.546,3.367,21.799,2.886,21.799,2.886   s-10.9,1.439-7.085,3.844s36.293,4.275,35.271-1.44c-0.817-4.568-16.35-6.25-16.35-6.25s32.138,0.814,30.518-4.324   c-1.361-4.327-29.812-5.235-29.812-5.235s-0.972-0.066-0.978-1.008c-0.005-0.858,1.284-0.841,1.284-0.841h33.667   c7.382,0,13.387-8.566,13.387-19.094C99.253,30.248,93.248,21.683,85.866,21.683z M72.551,56.738h-6.652   c3.644-3.415,6.056-9.293,6.056-15.961c0-6.669-2.412-12.548-6.056-15.963h6.652c5.657,0,10.257,7.161,10.257,15.963   C82.808,49.578,78.208,56.738,72.551,56.738z M85.866,56.738h-5.981c3.643-3.415,6.054-9.293,6.054-15.961   c0-6.669-2.411-12.548-6.054-15.963h5.981c5.655,0,10.257,7.161,10.257,15.963C96.123,49.578,91.521,56.738,85.866,56.738z"></path>
    </g>
  </svg>
);
