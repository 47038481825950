import React, { Component } from 'react';

import { DatePicker } from '../../inputs/DateTimePicker';
import Textbox from '../../inputs/Textbox';
import Card from '../../Card';
import { TwoColumn } from '../../inputs';

export default class RecurrenceCard extends Component {
  render() {
    const {
      onChange,
      employeeName,
      dateOfRecurrence,
      dateReported,
      datePhysicianAppt,
      physicianInfo,
      incidentDesc,
      reinjuryOccurred,
      injuryHistory,
      disabled,
      dateOfOriginalInjury,
      timeNotDetermined,
      name
    } = this.props;

    return (
      <Card
        name={name}
        showHeader
        title="Recurrence, Relapse During Recovery & Continuing Treatment"
        wide
      >
        <TwoColumn>
          <Textbox
            fieldLabel="Employee Name"
            placeholder="ex. Jane Doe"
            isRequired={true}
            onChange={v => onChange({ employeeName: v })}
            currentValue={employeeName}
            disabled={disabled}
          />
          <DatePicker
            pickTime
            fieldLabel="Date of Recurrence"
            isRequired
            disabled={disabled}
            onChange={values => onChange({ dateOfRecurrence: values })}
            currentValue={dateOfRecurrence}
            name="dateOfRecurrence"
          />
          <DatePicker
            pickTime
            fieldLabel="Date Reported"
            isRequired
            disabled={disabled}
            onChange={value => onChange({ dateReported: value })}
            currentValue={dateReported}
            name="dateReported"
          />
          <DatePicker
            pickTime
            fieldLabel="Date of Physician Appointment"
            isRequired
            disabled={disabled}
            onChange={value => onChange({ datePhysicianAppt: value })}
            currentValue={datePhysicianAppt}
            name="physicianAppointment"
          />
          <DatePicker
            pickTime
            fieldLabel="Date of Original Injury"
            isRequired
            disabled={disabled}
            onChange={value =>
              onChange({
                dateOfOriginalInjury: value,
                timeNotDetermined: false
              })
            }
            currentValue={dateOfOriginalInjury}
            showNA
            isNA={timeNotDetermined}
            onNAChange={() =>
              onChange({
                timeNotDetermined: !timeNotDetermined,
                dateOfOriginalInjury: ''
              })
            }
            name="originalInjury"
          />
        </TwoColumn>
        <Textbox
          fieldLabel="Physician Name and Information"
          type="textarea"
          rows={5}
          placeholder="Name, Specialization, Office Address, Contact Info"
          onChange={v => onChange({ physicianInfo: v })}
          currentValue={physicianInfo}
          disabled={disabled}
        />
        <Textbox
          fieldLabel="Describe the incident that caused this re-injury (include body parts affected)"
          placeholder="Type here"
          type="textarea"
          rows={5}
          isRequired={true}
          onChange={v => onChange({ incidentDesc: v })}
          currentValue={incidentDesc}
          disabled={disabled}
        />
        <Textbox
          fieldLabel="Where the re-injury occurred"
          placeholder="(Work, Home, Grocery Store, etc.)"
          isRequired={true}
          onChange={v => onChange({ reinjuryOccurred: v })}
          currentValue={reinjuryOccurred}
          disabled={disabled}
        />
        <Textbox
          fieldLabel="Describe, in detail, the history of problems related to the injury"
          placeholder="Type here"
          type="textarea"
          rows={5}
          isRequired={true}
          onChange={v => onChange({ injuryHistory: v })}
          currentValue={injuryHistory}
          disabled={disabled}
        />
      </Card>
    );
  }
}
