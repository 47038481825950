import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import history from '../../history';
import { fetchBehaviorObservations } from '../../api/v4';
import { getActiveLocationId, getActiveProject } from '../../selectors/company';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import List from '../../components/List';

export default function BehaviorObservations() {
  const activeGroup = useSelector(getActiveLocationId);
  const activeProject = useSelector(getActiveProject);
  const [list, setList] = useState([]);
  useEffect(() => {
    fetchBehaviorObservations().then(setList);
  }, []);

  const header = (
    <Header
      title="Behavior Observations"
      pageActionOptions={[
        {
          label: 'Add Behavior Observation',
          color: 'blueOutline',
          onClick: () => history.push(`/app/behaviorObservation/container`),
          visible: true
        },
        {
          label: 'Export Legacy Observations',
          color: 'greenOutline',
          onClick: () => console.log('legacy csv'),
          visible: false
        }
      ]}
    />
  );
  const columns = [
    {
      label: 'Behavior Observations',
      key: 'title'
    },
    {
      label: 'Employee Observed',
      key: 'employeeObserved',
      datatype: 'user'
    },
    {
      label: 'Supervisor Observing',
      key: 'supervisorObserving',
      datatype: 'user'
    },
    { label: 'Last Updated', key: 'updatedAt', datatype: 'date' },
    {
      label: 'Completion Date',
      key: 'completionDate',
      datatype: 'date'
    }
  ];

  if (!activeProject) {
    columns.unshift({
      key: 'projectId',
      label: 'Project',
      datatype: 'project'
    });
  }

  if (!activeGroup) {
    columns.unshift({
      key: 'groupId',
      label: 'Group/Est.',
      datatype: 'group'
    });
  }

  return (
    <HeaderAndFooter Header={header}>
      <List
        settings={columns}
        data={list}
        dataIsHash
        saveKey="behaviorObservations"
        rowClick={row =>
          history.push(`/app/behaviorObservation/container/${row._id}`)
        }
      />
    </HeaderAndFooter>
  );
}
