import React, { useState, useEffect } from 'react';
import history from '../../history';
import { fetchBehaviorObservationTemplates } from '../../api/v4';
import { Dropdown } from '../../components/inputs';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import List from '../../components/List';
import Modal from '../../components/Modal';

export default function BehaviorObservationTemplates() {
  const [list, setList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [templateDropdown, setTemplateDropdown] = useState([]);
  useEffect(() => {
    fetchBehaviorObservationTemplates().then(response => {
      setList(response);
      setTemplateDropdown(
        response.map(r => {
          return { value: r._id, label: r.title };
        })
      );
    });
  }, []);

  const header = (
    <Header
      title="Behavior Observation Templates"
      pageActionOptions={[
        {
          label: 'Add Behavior Observation Template',
          visible: true,
          color: 'blueOutline',
          onClick: () => history.push('/app/behaviorObservation/template')
        },
        {
          label: 'Duplicate Behavior Observation Template',
          onClick: () => setOpenModal(true),
          color: 'blueOutline',
          visible: list.length
        }
      ]}
    />
  );
  const columns = [
    {
      label: 'Title',
      key: 'title'
    },
    {
      label: 'Created At',
      key: 'createdAt',
      datatype: 'date'
    },
    {
      label: 'Last Updated',
      key: 'updatedAt',
      datatype: 'date'
    }
  ];

  return (
    <HeaderAndFooter Header={header}>
      <List
        settings={columns}
        data={list}
        dataIsHash
        saveKey="behaviorObservationTemplate"
        rowClick={row =>
          history.push(`/app/behaviorObservation/template/${row._id}`)
        }
      />
      <Modal
        title="Choose Template"
        titleClassName="blueHeader"
        isOpen={openModal}
        submitButtonColor="blue"
        submitButtonText="Create"
        onRequestClose={() => setOpenModal(false)}
        submitActions={() =>
          history.push(
            `/app/behaviorObservation/template/${currentTemplate}/true`
          )
        }
      >
        <Dropdown
          options={templateDropdown}
          fieldLabel="Behavior Observation Template"
          currentValue={currentTemplate}
          isRequired
          onChange={value => setCurrentTemplate(value)}
          placeholder="Select a behavior observation"
          searchable
        />
      </Modal>
    </HeaderAndFooter>
  );
}
