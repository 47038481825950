import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from '../../../history';
import {
  fetchDocumentFoldersRequest,
  addDocumentsToFolderRequest,
  setActiveDocumentFolderResponse,
  setActiveDocumentFolderRequest
} from '../../../actions/documents';
import {
  fetchTrainingsRequest,
  setActiveTrainingRequest,
  setActiveTrainingResponse
} from '../../../actions/training';
import { getActiveCompany } from '../../../selectors/company';
import {
  getDocumentFoldersSelector,
  getActiveFolderSelector
} from '../../../selectors/documents';
import { getTrainingListSelector } from '../../../selectors/training';
import AddToFolderModal from '../../../components/Modal/addToFolderModal';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import DocumentFolder from '../../../components/DocumentFolder';
import SafetyTrainingList from '../../../components/training/SafetyTrainingList';
import TypeSelection from '../../../components/TypeSelection';
import List from '../../../components/List';
import {
  fetchTrainingRepeatables,
  fetchTrainingRepeatableById,
  fetchTrainingScanOnDemands,
  fetchTrainingScanOnDemandById
} from '../../../api/v4';
import isEqual from 'lodash/isEqual';
import RepeatableList from '../../../components/RepeatableList';

class TrainingList extends Component {
  state = {
    trainingType: 'Active',
    addToFolderModal: false,
    documentFolderId: '',
    addToFolder: [],
    repeatingTrainings: [],
    scanOnDemandTrainings: []
  };

  componentDidMount() {
    this.props.fetchTrainings();
    this.props.fetchDocumentFolders({
      documentType: 'SafetyTraining'
    });
    fetchTrainingRepeatables().then(response =>
      this.setState({ repeatingTrainings: response })
    );
    fetchTrainingScanOnDemands().then(response =>
      this.setState({ scanOnDemandTrainings: response })
    );
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.activeFolder, prevProps.activeFolder)) {
      fetchTrainingRepeatables(this.props.activeFolder?._id).then(response =>
        this.setState({ repeatingTrainings: response })
      );
      fetchTrainingScanOnDemands(this.props.activeFolder?._id).then(response =>
        this.setState({ scanOnDemandTrainings: response })
      );
    }
  }

  getTraining = schedule => {
    if (schedule === 'Active') return this.getImmediateTable();
    else if (schedule === 'Repeating') return this.getRepeatingTable();
    return this.getScanTable();
  };

  handleAddMultipleToFolders = (trainingType = 'Active') => {
    const { trainings } = this.props;
    const { repeatingTrainings, scanOnDemandTrainings } = this.state;
    let addDocsToFolder = [];

    let trainingList =
      trainingType === 'Active'
        ? trainings
        : trainingType === 'Repeating'
        ? repeatingTrainings
        : scanOnDemandTrainings;

    trainingList.forEach(document => {
      if (this.state.addToFolder.indexOf(document._id) > -1) {
        addDocsToFolder.push(document);
      }
    });

    this.props.addDocumentToFolder({
      documents: addDocsToFolder,
      documentType: trainingType === 'Active' ? 'SafetyTraining' : trainingType,
      documentFolderId: this.state.documentFolderId
    });

    this.setState({
      addToFolderModal: false,
      addToFolder: [],
      documentFolderId: ''
    });

    this.props.setActiveFolder({
      ...this.props?.activeFolder,
      page: 1,
      limit: 10000
    });
    fetchTrainingRepeatables().then(response =>
      this.setState({ repeatingTrainings: response })
    );
    fetchTrainingScanOnDemands().then(response =>
      this.setState({ scanOnDemandTrainings: response })
    );
  };

  getImmediateTable() {
    const trainings = this.props?.trainings.filter(
      training => training.schedule.label === 'Immediately'
    );
    return (
      <SafetyTrainingList
        trainings={trainings}
        addToFolder={ids => {
          this.setState({
            addToFolder: ids,
            addToFolderModal: true
          });
        }}
      />
    );
  }

  getRepeatingTable() {
    const repeatingColumns = [
      { key: 'title', label: 'Training Title' },
      {
        key: 'category',
        label: 'Training Category'
      },
      {
        key: 'assignedTo',
        label: 'Employees',
        datatype: 'users',
        areEmployees: r => r.legacy || r.trainingType?.value === 3
      },
      { key: 'lastUpdatedAt', label: 'Last Updated', datatype: 'date' },
      {
        key: 'repeatingOn',
        label: 'Repeat On',
        datatype: 'dow',
        frequency: 'frequency'
      }
    ];

    return (
      <RepeatableList
        repeatables={this.state.repeatingTrainings}
        repeatingColumns={repeatingColumns}
        handleRowClick={value => this.handleViewEdit(value)}
        saveKey="repeatingTrainingList"
        type="Safety Trainings"
        updateData={() =>
          fetchTrainingRepeatables().then(response =>
            this.setState({ repeatingTrainings: response })
          )
        }
        actions={[
          {
            label: 'Add to Folder',
            color: 'blue',
            onClick: ids => {
              this.setState({
                addToFolder: ids,
                addToFolderModal: true
              });
            }
          }
        ]}
      />
    );
  }

  getScanTable() {
    const scanColumns = [
      { key: 'title', label: 'Training Title' },
      { key: 'lastUpdatedAt', label: 'Last Updated', datatype: 'date' },
      {
        key: 'category',
        label: 'Training Category'
      },
      {
        key: 'qrCodeUrl',
        label: 'QR Code',
        datatype: 'url',
        text: 'Download QR Code'
      }
    ];

    return (
      <List
        saveKey="scanTrainingList"
        data={this.state.scanOnDemandTrainings}
        dataIsHash
        settings={scanColumns}
        rowClick={this.handleViewEdit}
        getRowId={r => r._id}
        actions={[
          {
            label: 'Add to Folder',
            color: 'blue',
            onClick: ids => {
              this.setState({
                addToFolder: ids,
                addToFolderModal: true
              });
            }
          }
        ]}
      />
    );
  }

  handleViewEdit = async template => {
    let training;
    if (Object.keys(template).length === 0) {
      this.props.setActiveTraining(template);
    } else if (this.state.trainingType === 'Repeating') {
      const repeatable = await fetchTrainingRepeatableById(template._id);
      training = {
        ...repeatable.training,
        schedule: { value: 2, label: 'Repeating' },
        _id: template._id,
        isCreating: false,
        ...repeatable
      };
      this.props.setActiveTraining(training);
    } else if (this.state.trainingType === 'On Demand') {
      const scanOnDemand = await fetchTrainingScanOnDemandById(template._id);
      training = {
        ...scanOnDemand.training,
        schedule: { value: 2, label: 'Scan on Demand' },
        _id: template._id,
        isCreating: false,
        ...scanOnDemand
      };
      this.props.setActiveTraining(training);
    }
    this.props.setActiveDocumentFolder({});
    history.push('/app/training/trainingContainer');
  };

  render() {
    const { trainingType, addToFolderModal, documentFolderId } = this.state;

    const { trainings, folderList } = this.props;

    const header = (
      <Header
        section="Safety Trainings"
        rightButtons={{
          text: 'Create Training',
          onClick: () => this.handleViewEdit({}),
          color: 'blue'
        }}
      />
    );

    return (
      <HeaderAndFooter Header={header}>
        <TypeSelection
          selected={trainingType}
          selectedArray={['Active', 'Repeating', 'On Demand']}
          onClick={value => this.setState({ trainingType: value })}
        />
        <DocumentFolder content={trainings} docType="Safety Training" />
        {this.getTraining(trainingType)}
        <AddToFolderModal
          isOpen={addToFolderModal}
          folderId={documentFolderId}
          onRequestClose={() => this.setState({ addToFolderModal: false })}
          submitActions={() => this.handleAddMultipleToFolders(trainingType)}
          folderOptions={folderList.map(folder => {
            return {
              value: folder._id,
              label: folder.name
            };
          })}
          onChange={value => this.setState({ documentFolderId: value })}
        />
      </HeaderAndFooter>
    );
  }
}

const mapStateToProps = state => ({
  trainings: getTrainingListSelector(state),
  activeCompany: getActiveCompany(state),
  folderList: getDocumentFoldersSelector(state),
  activeFolder: getActiveFolderSelector(state)
});

const mapDispatchToProps = dispatch => ({
  fetchTrainings: () => dispatch(fetchTrainingsRequest()),
  setActiveTemplate: payload => dispatch(setActiveTrainingRequest(payload)),
  setActiveTraining: payload => dispatch(setActiveTrainingResponse(payload)),
  fetchDocumentFolders: values => dispatch(fetchDocumentFoldersRequest(values)),
  addDocumentToFolder: values => dispatch(addDocumentsToFolderRequest(values)),
  setActiveDocumentFolder: values =>
    dispatch(setActiveDocumentFolderResponse(values)),
  setActiveFolder: values => dispatch(setActiveDocumentFolderRequest(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingList);
