import { call, put, select, all } from 'redux-saga/effects';
import history from '../history';
import moment from 'moment';
import {
  setActiveIncidentRequest,
  setActiveIncidentResponse
} from '../actions/incidents';
import { addMessage } from '../actions/messages';
import { createReportResponse } from '../actions/reports';
import { setActiveSafetyWalk } from '../actions/safetyWalks';
import {
  addUserTaskResponse,
  deleteUserTaskResponse,
  fetchUsersTasksResponse,
  setActiveTaskResponse,
  updateUserTaskResponse,
  updateRemindedAssignments
} from '../actions/tasks';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../selectors/company';
import { getActiveSafetyWalkSelector } from '../selectors/safetyWalks';
import { getLoggedInUser } from '../selectors/users';
import { addUploadedAttachmentsResponse } from '../actions/attachments';
import { getAddedAttachmentsSelector } from '../selectors/attachments';

export function* fetchUsersTasks(apiV4, { payload }) {
  try {
    let response = {};

    if (payload.isRecurring) {
      response = yield call(apiV4.fetchRepeatingTasks);
    } else {
      response = yield call(apiV4.fetchTasks);
    }
    yield put(fetchUsersTasksResponse({ list: response }));
  } catch (e) {
    console.log(e);
  }
}

export function* loadTask(api, apiV4, { payload }) {
  try {
    let task;

    if (payload.fromRepeating) {
      const repeatable = yield call(apiV4.fetchTaskRepeatableById, payload.id);
      task = {
        ...repeatable.task,
        schedule: 'repeating',
        assignedTo: repeatable.assignedTo,
        dueBy: repeatable.dueBy,
        dueByTime: repeatable.dueByTime,
        frequency: repeatable.frequency,
        companyId: repeatable.companyId,
        groupId: repeatable.groupId,
        projectId: repeatable.projectId,
        repeatingOn: repeatable.repeatingOn,
        createdAt: repeatable.createdAt,
        createdBy: repeatable.createdBy,
        _id: repeatable._id,
        type: repeatable.type,
        description: repeatable.description,
        fromRepeating: true,
        isPaused: repeatable.isPaused
      };
    } else {
      task = yield call(apiV4.fetchTaskById, payload);

      let attachments =
        task?.attachments?.length > 0
          ? yield call(
              api.fetchAttachmentsByIds,
              task.companyId,
              task.attachments
            )
          : [];

      yield put(addUploadedAttachmentsResponse(attachments));

      const comments = yield call(apiV4.fetchTaskComments, task._id);

      task = { ...task, comments, schedule: 'immediate' };
    }

    if (task) {
      yield put(setActiveTaskResponse(task));
      history.push('/app/taskContainer');
    }
  } catch (e) {
    console.log(e);
  }
}

export function* sendReminder(
  apiV4,
  { payload: { assignmentId, assignmentType } }
) {
  try {
    let today = new Date();
    let current = JSON.parse(localStorage.getItem('remindersSent'));

    localStorage.setItem(
      'remindersSent',
      JSON.stringify({ ...current, [assignmentId]: today })
    );
    let updated = JSON.parse(localStorage.getItem('remindersSent'));
    const result = yield call(apiV4.sendReminder, assignmentId, assignmentType);
    yield put(updateRemindedAssignments({ ...updated }));

    if (result.failedRecipients.length > 0) {
      const failedNames = result.failedRecipients
        .join(`, `)
        .replace(/, ((?:.(?!, ))+)$/, ' and $1');
      const plural =
        result.failedRecipients.length > 1
          ? { verb: `weren't`, ending: `s` }
          : { verb: `wasn't`, ending: `` };
      yield put(
        addMessage({
          error: true,
          message: `${failedNames} ${plural.verb} reminded.
          To send reminders, add an email for the user${plural.ending}
          or have the user${plural.ending} turn on push notifications.`
        })
      );
    } else {
      yield put(addMessage({ error: false, message: 'Reminder Sent' }));
    }
  } catch (e) {
    console.log(e);
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* setActiveTask(api, { payload }) {
  try {
    yield put(setActiveTaskResponse(payload));
    if (
      payload.ownerType === 'safetyWalks' ||
      payload.ownerType === 'safetyAuditQuestion' ||
      payload.ownerType === 'observation'
    ) {
      const pics = payload.pictures ?? [];
      if (payload?.attachments?.length > 0) {
        const attachments = yield call(
          api.fetchAttachmentsByIds,
          payload.companyId,
          payload?.attachments
        );
        yield put(addUploadedAttachmentsResponse([...pics, ...attachments]));
      } else {
        yield put(addUploadedAttachmentsResponse(pics));
      }
    }
    if (payload.ownerType === 'incident') {
      yield put(addUploadedAttachmentsResponse([]));
    }
    history.push('/app/taskContainer');
  } catch (e) {
    console.log(e);
  }
}

export function* addUserTask(api, apiV4, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const activeGroup = yield select(getActiveLocationId);
    const activeProject = yield select(getActiveProject);
    const loggedInUser = yield select(getLoggedInUser);
    const addedAttachments = yield select(getAddedAttachmentsSelector);

    payload = {
      ...payload,
      groupId: activeGroup?._id ?? payload.groupId,
      projectId: activeProject?._id ?? payload.projectId,
      isCompleted: false,
      dueDate: moment(Date.parse(payload.dueDate)),
      companyId: activeCompany._id,
      assignedTo: payload.assignedTo.filter(x => x !== null && x !== undefined)
    };

    if (payload.schedule === 'repeating') {
      yield call(apiV4.createTaskRepeatable, payload);
    } else {
      let data = payload?.attachmentData;
      delete payload?.attachmentData;
      let pictures = [];

      if (data) {
        pictures = yield call(api.uploadAttachment, data.data, {
          ownerId: 'pending',
          ownerType: data.ownerType,
          isSignature: false,
          isPhotoEvidence: false,
          photoType: data.photoType
        });
      }

      let together = [...addedAttachments, ...pictures];
      payload = { ...payload, attachments: together?.map(a => a._id) };
      let taskPhotos = together.filter(a => a.ownerType === 'task');

      const response = payload.reportComponentName
        ? yield call(apiV4.createReportComponentTask, payload)
        : yield call(apiV4.addTask, payload);

      yield all(
        // only update the ones that were uploaded from the task page
        taskPhotos?.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      yield put(addUploadedAttachmentsResponse([]));

      if (response.ownerType === 'incident') {
        const incident = yield call(apiV4.fetchIncidentById, response.ownerId);

        const reportComponent = incident.reportComponents.filter(report => {
          if (response.label.includes('Corrective Action')) {
            return report[0] === 'Corrective Actions';
          }
          return report[0] === response.reportComponentName;
        });

        yield put(setActiveIncidentResponse(incident));
        yield put(createReportResponse(reportComponent));
      } else if (response.ownerType === 'observation') {
        yield call(apiV4.updateBehaviorObservation, {
          _id: response.ownerId,
          taskId: response._id
        });

        yield put(addUserTaskResponse(response));
      } else if (response.ownerType === 'safetyWalks') {
        const activeSafetyWalk = yield select(getActiveSafetyWalkSelector);
        const hazards = activeSafetyWalk.hazards;

        hazards[`${payload.hazardNumber}`].tasks = [response];
        activeSafetyWalk.hazards = hazards;

        if (activeSafetyWalk._id) {
          const swResponse = yield call(
            apiV4.updateSafetyWalk,
            activeSafetyWalk
          );
          yield put(setActiveSafetyWalk(swResponse));
        } else {
          yield put(setActiveSafetyWalk({ ...activeSafetyWalk }));
        }

        yield put(addUserTaskResponse(response));
      } else if (response.assignedTo.indexOf(loggedInUser._id) > -1) {
        yield put(addUserTaskResponse(response));
      } else {
        yield put(updateUserTaskResponse());
      }

      if (response.ownerType === 'incident') {
        yield put(setActiveIncidentRequest(response.ownerId));
      } else {
        if (response.ownerType === 'equipment') {
          history.push(`/app/equipmentContainer/${response.ownerId}`);
        } else history.goBack();
        yield put(addMessage({ error: false, message: 'Saved Successfully' }));
      }
    }
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateUserTask(api, apiV4, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const addedAttachments = yield select(getAddedAttachmentsSelector);
    payload = {
      ...payload,
      companyId: payload.companyId ? payload.companyId : activeCompany._id
    };

    if (payload.schedule === 'repeating') {
      yield call(apiV4.updateTaskRepeatable, payload);
    } else {
      let data = payload?.attachmentData;
      delete payload?.attachmentData;
      let pictures = [];

      if (data) {
        pictures = yield call(api.uploadAttachment, data.data, {
          ownerId: data.ownerId,
          ownerType: data.ownerType,
          isSignature: false,
          isPhotoEvidence: false,
          photoType: data.photoType
        });
      }

      let allAttachments = [...addedAttachments, ...pictures];
      yield put(addUploadedAttachmentsResponse(allAttachments));
      payload = { ...payload, attachments: allAttachments?.map(a => a._id) };
      if ('dueDate' in payload) {
        payload.dueDate = moment(Date.parse(payload.dueDate));
      }

      delete payload.createdBy;

      const response = yield call(apiV4.updateTask, payload);
      const comments = yield call(apiV4.fetchTaskComments, response._id);

      const together = { ...response, comments };

      if (response.ownerType === 'incident') {
        const incident = yield call(apiV4.fetchIncidentById, response.ownerId);

        const reportComponent = incident.reportComponents.filter(report => {
          if (response.label.includes('Corrective Action')) {
            return report[0] === 'Corrective Actions';
          }
          return report[0] === response.reportComponentName;
        });
        yield put(setActiveIncidentResponse(incident));
        yield put(
          createReportResponse({ report: reportComponent, stage: 'action' })
        );
      } else if (response.ownerType === 'safetyWalks' && response.ownerId) {
        const swResponse = yield call(
          apiV4.fetchSafetyWalkById,
          response.ownerId
        );
        yield put(setActiveSafetyWalk(swResponse));
      } else {
        yield put(updateUserTaskResponse({ ...together }));
      }
    }
    yield put(
      addMessage({
        error: false,
        message: 'Saved Successfully. Any Photos are Autosaved'
      })
    );
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteUserTask(api, apiV4, { payload }) {
  try {
    const addedAttachments = payload.attachments;

    if (payload.schedule === 'repeating') {
      yield call(apiV4.deleteTaskRepeatable, payload);
    } else {
      let taskPhotos = addedAttachments?.filter(a => a.ownerType === 'task');
      yield all(
        taskPhotos?.map(attachment =>
          call(api.deleteAttachment, attachment._id)
        )
      );

      yield call(apiV4.deleteTask, payload);
      yield put(deleteUserTaskResponse(payload));

      if (payload.ownerType === 'safetyWalks') {
        const activeSafetyWalk = yield select(getActiveSafetyWalkSelector);
        if (activeSafetyWalk._id && payload.ownerId) {
          const swResponse = yield call(
            apiV4.updateSafetyWalk,
            activeSafetyWalk
          );
          yield put(setActiveSafetyWalk(swResponse));
        } else {
          let updatedHazards = activeSafetyWalk.hazards?.map(h => {
            let updatedTasks = h?.tasks.filter(t => t._id !== payload._id);
            return { ...h, tasks: updatedTasks };
          });
          yield put(
            setActiveSafetyWalk({
              ...activeSafetyWalk,
              hazards: updatedHazards
            })
          );
        }
      } else if (payload.ownerType === 'incident') {
        const incident = yield call(apiV4.fetchIncidentById, payload.ownerId);

        const reportComponent = incident.reportComponents.filter(report => {
          if (payload.label.includes('Corrective Action')) {
            return report[0] === 'Corrective Actions';
          }
          return report[0] === payload.reportComponentName;
        });
        yield put(setActiveIncidentResponse(incident));
        yield put(createReportResponse(reportComponent));
      }
    }
    yield put(addUploadedAttachmentsResponse([]));
    yield put(addMessage({ error: false, message: 'Deleted Successfully' }));
    history.goBack();
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}
