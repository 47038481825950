import React, { useState, useEffect } from 'react';
import { fetchAllUsersForLocation } from '../../../api/v4';
import { FieldRenderer } from '../../inputs';
import Card from '../../Card';
import HierarchySelector from '../../HierarchySelector';

const IncidentSection = ({
  name,
  reportSectionHeader,
  fields,
  answers,
  isIncidentBasics,
  isEditing,
  isCreating,
  activeIncident,
  handleSelectedAnswer
}) => {
  const groupId = answers?.locationId ?? activeIncident?.locationId;
  const [employees, setEmployees] = useState(false);
  useEffect(() => {
    if (groupId)
      fetchAllUsersForLocation(groupId).then(users => setEmployees(users));
  }, [groupId]);

  const disabled = !isCreating && !isEditing;
  const witnessDisabled = !isCreating;

  return (
    <Card name={name} title={reportSectionHeader} showHeader wide>
      {isIncidentBasics && (
        <HierarchySelector
          onGroupChange={v => handleSelectedAnswer({ locationId: v })}
          groupId={answers?.locationId ?? activeIncident?.locationId}
          groupDisabled={disabled}
          onProjectChange={v => handleSelectedAnswer({ projectId: v })}
          projectId={answers?.projectId ?? activeIncident?.projectId}
          projectDisabled={disabled}
          alphabetize
        />
      )}
      {fields.map((field, index) => {
        if (field.legacy) return <React.Fragment key={index}></React.Fragment>;
        const answer =
          answers?.[field._id] ?? (field.type === 'multiselect' ? [] : '');
        return (
          <FieldRenderer
            key={index}
            fieldLabel={field.label}
            isRequired={field.required}
            name={field.label}
            id={field._id}
            showNA={
              field.type === 'signature' ? false : field.showNotApplicable
            }
            isNA={answer?.length === 1 && answer?.[0] === 'Not Applicable'}
            currentValue={answer}
            onChange={v => handleSelectedAnswer({ [field._id]: v })}
            onNAChange={v =>
              handleSelectedAnswer({
                [field._id]: v
                  ? ['Not Applicable']
                  : field.type === 'multiselect'
                  ? []
                  : ''
              })
            }
            disabled={
              field.label === 'Additional Witnesses of the Incident:'
                ? witnessDisabled
                : disabled
            }
            options={field.options}
            type={field.type}
            bareValues={false}
            groupId={groupId}
            employees={employees}
            // This message will proabably needed to be worded better
            displayTooltip={
              field.displayTooltip ??
              field.label === 'Additional Witnesses of the Incident:'
            }
            tooltip={
              field.tooltip ??
              'Only Active Users are generated in this list. If a user is not found they will need to be activated on the Employee Page'
            }
          />
        );
      })}
    </Card>
  );
};

export default IncidentSection;
