import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';

import Button from '../inputs/Button';
import Modal from '.';
import Textbox from '../inputs/Textbox';
import { InputRow } from '../inputs';
import { checkIfLinkIsYoutube } from '../../utils/attachmentSelector';

import styles from './styles.module.scss';

export class AddYouTubeLinksModal extends Component {
  state = {
    youtubeLinks: [{ title: '', link: '' }]
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.youtubeLinks, this.props.youtubeLinks)) {
      this.setState({ youtubeLinks: this.props.youtubeLinks });
    }
  }

  addYouTubeLink = () => {
    this.setState(state => ({
      youtubeLinks: [...state.youtubeLinks, { title: '', link: '' }]
    }));
  };

  handleSubmit = () => {
    this.props.submitActions(this.state.youtubeLinks);
  };

  render() {
    const youtubeLinks = this.state.youtubeLinks;

    const canSubmit = youtubeLinks?.every(link => checkIfLinkIsYoutube(link));

    return (
      <Modal
        title="Add YouTube Links"
        titleClassName="blueHeader"
        isOpen={this.props.isOpen}
        submitButtonColor="blue"
        submitButtonText="Save Links"
        onRequestClose={this.props.onRequestClose}
        cancelButtonText="Cancel"
        submitActions={this.handleSubmit}
        disableSubmit={!canSubmit}
      >
        {youtubeLinks.map((link, index) => (
          <InputRow key={index} className={styles.grow}>
            <Textbox
              onChange={v => {
                const currentOptions = youtubeLinks;
                currentOptions[index].title = v;
                this.setState(state => ({ youtubeLinks: currentOptions }));
              }}
              currentValue={link.title}
              className={styles.input}
              testID="youtubeLinkTitle"
              fieldLabel="YouTube Video Title"
              isRequired
            />
            <Textbox
              onChange={v => {
                const currentOptions = youtubeLinks;
                currentOptions[index].link = v;
                this.setState(state => ({ youtubeLinks: currentOptions }));
              }}
              currentValue={link.link}
              className={styles.input}
              testID="youtubeLinkUrl"
              fieldLabel="YouTube Video Link"
              isRequired
              warning="You must only use valid youtube links"
              touched={link.link && !checkIfLinkIsYoutube(link)}
            />
          </InputRow>
        ))}
        <Button
          text="Add Another Link"
          color="blue"
          style={{ maxWidth: 'fit-content' }}
          onClick={() => this.addYouTubeLink()}
        />
      </Modal>
    );
  }
}

export default AddYouTubeLinksModal;
