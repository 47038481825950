/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import AssignCorrectiveActionButton from '../../inputs/AssignCorrectiveActionButton';
import { Button, Textbox, InputRow, Checkbox, ButtonGroup } from '../../inputs';
import Card from '../../Card';
import Carousel from '../../Carousel';
import UserBadge from '../../UserBadge';

import styles from './styles.module.scss';
const bStyles = classnames.bind(styles);

export default function QuestionCard({
  question,
  questionNumber,
  sectionNumber,
  updateAnswer,
  isCompleted,
  addAttachment,
  addNote,
  locationId,
  projectId,
  deleteJsaQuestionAttachment,
  section,
  requirePhotoForUnacceptableItems,
  requireNoteForUnacceptableItems,
  requireCAForUnacceptableItems,
  setQuestionId,
  audit,
  updateImmediatelyCorrected,
  editing,
  initiateDeleteNote,
  initiateDeletePhoto,
  handleChangeNotes,
  updatedNotes
}) {
  const [localQuestion, setLocalQuestion] = useState(question);

  useEffect(() => {
    setLocalQuestion(question);
  }, [question, question.notes]);

  const color =
    question.answer === 1 ? 'green' : question.answer === 2 ? 'red' : 'grey';
  const hasLineItemNotes = question?.notes?.length > 0;
  const hasLineItemImages = question?.attachments?.length > 0;
  const totalQuestions = section?.questions?.length;
  const sectionName = section?.title;

  const updateLocalQuestion = (e, note) => {
    let newLocalQuestion = { ...localQuestion };
    let index = newLocalQuestion.notes.findIndex(
      object => object._id === note._id
    );
    newLocalQuestion.notes[index].value = e;
    setLocalQuestion(newLocalQuestion);
  };

  const handleDelete = item => {
    initiateDeletePhoto(item, sectionNumber, questionNumber);
  };

  return (
    <Card
      smallCard
      key={questionNumber}
      testID={`card${questionNumber}`}
      title={`${questionNumber}/${totalQuestions} ${question.label}`}
      showHeader={true}
    >
      <div className={styles.questionCard}>
        {isCompleted ? (
          <div
            className={bStyles({
              selectedButton: true,
              [color]: true
            })}
          >
            <img
              className={bStyles({ image: true, white: true })}
              src={
                question.answer === 1
                  ? require('../../../assets/images/checkmark.png')
                  : question.answer === 2
                  ? require('../../../assets/images/Remove Icon.png')
                  : require('../../../assets/images/NA.png')
              }
              alt={
                question.answer === 1
                  ? 'marked acceptable'
                  : question.answer === 2
                  ? 'marked unacceptable'
                  : 'marked not applicable'
              }
            />
          </div>
        ) : (
          <ButtonGroup
            leftImage={require('../../../assets/images/checkmark.png')}
            centerImage={require('../../../assets/images/Remove Icon.png')}
            rightImage={require('../../../assets/images/NA.png')}
            selected={question.answer}
            onClick={value => updateAnswer(value)}
          />
        )}
        <Checkbox
          currentValue={question.immediatelyFixed}
          name={`immediatelyFixed-${questionNumber}`}
          disabled={isCompleted}
          fieldLabel="Unacceptable Item was immediately corrected"
          onChange={value => updateImmediatelyCorrected(value)}
        />
        {requireNoteForUnacceptableItems &&
        !question?.notes?.length &&
        question.answer == 2 ? (
          <div className={styles.error}>
            *Note required for unacceptable items.
          </div>
        ) : null}
        {requirePhotoForUnacceptableItems &&
        !question?.attachments?.length &&
        question.answer == 2 ? (
          <div className={styles.error}>
            *Photo required for unacceptable items.
          </div>
        ) : null}
        {!isCompleted && (
          <InputRow>
            <Button
              image={require(hasLineItemImages
                ? '../../../assets/images/cameraWithAttachments.png'
                : '../../../assets/images/camera.png')}
              text="Photo"
              color="blue"
              imageClass={styles.image}
              onClick={() => {
                setQuestionId(question._id);
                document.getElementById('myPictureFile').click();
              }}
              testID="safetyAuditPhotoButton"
            />
            <Button
              image={require(hasLineItemNotes
                ? '../../../assets/images/notesWithAttachments.png'
                : '../../../assets/images/notes.png')}
              text="Note"
              color="blue"
              imageClass={styles.image}
              onClick={addNote}
              testID="safetyAuditNoteButton"
            />
            <input
              type="file"
              id="myPictureFile"
              onChange={e => {
                addAttachment(e, sectionNumber, questionNumber);
              }}
              accept="image/png, image/jpeg, image/jpg"
              multiple
              data-cy={`addPicture${questionNumber}`}
            />
          </InputRow>
        )}
      </div>
      {hasLineItemNotes &&
        localQuestion?.notes
          .filter(note => !note.isRemoved)
          .map((note, index) => (
            <div className={styles.notesContainer} key={index}>
              <UserBadge
                userId={note.createdByUserId}
                showImage
                showName
                byline="Added By"
                time={note.updatedAt ?? note.createdAt}
                size="medium"
              />
              {editing ? (
                <div className={styles.note}>
                  <div className={styles.textbox}>
                    <Textbox
                      currentValue={note.value}
                      onChange={e => {
                        updateLocalQuestion(e, note);
                        handleChangeNotes({
                          ...note,
                          value: e,
                          sectionNumber,
                          questionNumber,
                          index
                        });
                      }}
                      touched={note.value.trim() === ''}
                      warning="Empty notes cannot be saved"
                    />
                  </div>
                  <Button
                    className="deleteButton"
                    color="red"
                    text="delete"
                    shrink
                    onClick={() =>
                      initiateDeleteNote(sectionNumber, questionNumber, note)
                    }
                  />
                </div>
              ) : (
                <div className={styles.note}>{note.value}</div>
              )}
            </div>
          ))}
      {hasLineItemImages && (
        <Carousel
          items={question?.attachments}
          title="Attachments"
          deleteAction={editing ? handleDelete : deleteJsaQuestionAttachment}
          canDelete={!isCompleted || editing}
          deleteFromComplete={!isCompleted || editing}
        />
      )}
      <AssignCorrectiveActionButton
        task={question.task}
        description={`Please correct the following which was marked as unsafe: \n ${question.label}`}
        label={`${sectionName} - Corrective Action`}
        ownerType="safetyAuditQuestion"
        ownerId={question._id}
        groupId={locationId}
        projectId={projectId}
        schedule="immediate"
        pictures={question?.attachments}
        onClick={() => {
          localStorage.setItem('activeAudit', JSON.stringify(audit));
          localStorage.setItem('updatedNotes', JSON.stringify(updatedNotes));
          localStorage.setItem('editing', JSON.stringify(editing));
        }}
      />
      {requireCAForUnacceptableItems &&
      !question?.task &&
      question.answer == 2 ? (
        <div className={styles.error}>
          *Corrective Action required for unacceptable items.
        </div>
      ) : null}
    </Card>
  );
}
