import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import moment from 'moment';

import { loadDashboard } from '../../api/v4';
import { clearUploadedAttachments } from '../../actions/attachments';
import {
  setActiveIncidentRequest,
  setActiveIncidentResponse
} from '../../actions/incidents';
import { loadSafetyWalk } from '../../actions/safetyWalks';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import { IncidentCard, SafetyWalkCard } from '../../components/DashboardCard';
import Pagination from '../../components/Pagination';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';

import styles from './dashboard.module.scss';
const perPage = 10;

export class Dashboard extends Component {
  state = {
    isLoading: 0,
    incidents: null,
    safetyWalks: null,
    page: 0
  };

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  componentDidMount = async () => {
    this.props.activeIncidentResponse();
    this.props.clearAttachments();
    loadDashboard().then(data => {
      this.setState({
        ...data,
        page: 0
      });
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.activeLocationId._id !== this.props.activeLocationId._id) {
      loadDashboard().then(data => {
        this.setState({
          ...data,
          page: 0
        });
      });
    }
  }

  renderIncident = incident => (
    <IncidentCard
      incident={incident}
      key={incident._id}
      onClick={() =>
        this.props.setActiveIncident(incident) &&
        this.setState({ isLoading: incident._id })
      }
      incidentLoading={this.state.isLoading === incident._id}
      showAttachments
    />
  );

  renderSafetyWalk = safetyWalk => (
    <SafetyWalkCard
      safetyWalk={safetyWalk}
      key={safetyWalk._id}
      isLoading={this.state.isLoading === safetyWalk._id}
      onClick={() => {
        this.props.loadSafetyWalk(safetyWalk._id);
        this.setState({ isLoading: safetyWalk._id });
      }}
    />
  );

  render() {
    const { safetyWalks, incidents, page } = this.state;
    let cards;
    let total;
    if (safetyWalks !== null && incidents !== null) {
      cards = [
        ...safetyWalks.map(s => ({
          ...s,
          type: 'safetyWalk',
          updatedAt: moment(s.updatedAt),
          createdAt: moment(s.createdAt)
        })),
        ...incidents.map(i => ({
          ...i,
          type: 'incident',
          updatedAt: moment(i.updatedAt)
        }))
      ].sort((a, b) => b.updatedAt.diff(a.updatedAt));
      total = cards.length;
      cards = cards.slice(page * perPage, (page + 1) * perPage);
    }
    const header = <Header title="Dashboard" />;

    return (
      <HeaderAndFooter
        Header={header}
        className={styles.dashboard}
        cards="dashboard"
      >
        <ScrollToTopOnMount />
        <div className={styles.incidents}>
          {cards === undefined ? (
            <ReactLoading
              type="spinningBubbles"
              color="#2f8738"
              className="loading"
            />
          ) : cards.length !== 0 ? (
            cards.map((card, index) => {
              switch (card.type) {
                case 'incident':
                  return this.renderIncident(card);
                case 'safetyWalk':
                  return this.renderSafetyWalk(card);
                default:
                  return <></>;
              }
            })
          ) : (
            <div className={styles.noIncidents}>
              <h2>No Incidents to Display</h2>
            </div>
          )}
        </div>
        <Pagination
          page={page}
          setPage={page => this.setState({ page })}
          perPage={perPage}
          count={total}
        />
      </HeaderAndFooter>
    );
  }
}

export const mapStateToProps = state => ({
  activeCompany: getActiveCompany(state),
  activeLocationId: getActiveLocationId(state),
  activeProject: getActiveProject(state)
});

export const mapDispatchToProps = dispatch => ({
  setActiveIncident: incident => dispatch(setActiveIncidentRequest(incident)),
  activeIncidentResponse: () => dispatch(setActiveIncidentResponse({})),
  clearAttachments: () => dispatch(clearUploadedAttachments()),
  loadSafetyWalk: id => dispatch(loadSafetyWalk(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
