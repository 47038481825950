import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { setActiveIncidentRequest } from '../../actions/incidents';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import {
  REACTIVE_REPORT_TYPES,
  PROACTIVE_REPORT_TYPES
} from '../../constants/constants';
import List from '../List';

const reportTypes = [
  ...REACTIVE_REPORT_TYPES,
  ...PROACTIVE_REPORT_TYPES.filter(r => r.value !== 8 && r.value !== 10)
];

export const IncidentsList = ({ content, tabs, saveKey }) => {
  const dispatch = useDispatch();
  const loadIncident = payload => dispatch(setActiveIncidentRequest(payload));
  const company = useSelector(state => getActiveCompany(state), shallowEqual);
  const activeLocation = useSelector(state => getActiveLocationId(state));
  const activeProject = useSelector(state => getActiveProject(state));

  const columns = [
    {
      key: 'reportTemplateIds',
      label: 'Report Types',
      accessor: r => r.reportTemplateIds.map(v => parseInt(v, 10)),
      datatype: 'svgs',
      disableSortBy: true,
      enum: company?.showAircraft
        ? reportTypes
        : reportTypes.filter(r => r.value !== 6 && r.value !== 16)
    },
    {
      key: 'incidentNumber',
      label: 'Incident Title'
    },
    {
      key: 'involvedEmployee',
      datatype: 'users'
    },
    {
      key: 'createdByUserId',
      label: 'Reporter',
      datatype: 'user'
    },
    {
      key: 'dateIncidentOccured',
      label: 'Date Occurred',
      datatype: 'date'
    },
    {
      key: 'reportStage',
      label: 'Status',
      accessor: r => (r.reportStage?.label === 'Closed' ? 'Completed' : 'Open'),
      enum: [
        { value: 'Open', label: 'Open' },
        { value: 'Completed', label: 'Completed' }
      ]
    },
    {
      key: 'severity',
      enum: [
        { value: 'Minor', label: 'Minor' },
        { value: 'Moderate', label: 'Moderate' },
        { value: 'Major', label: 'Major' }
      ]
    }
  ];

  if (!activeLocation) {
    columns.unshift({
      key: 'locationId',
      label: 'Group/Est.',
      datatype: 'group'
    });
  }
  if (activeLocation && !activeProject) {
    columns.unshift({
      key: 'projectId',
      label: 'Area',
      datatype: 'project'
    });
  }
  const newSaveKey =
    content?.length > 0
      ? !activeLocation
        ? `${saveKey}Groups`
        : activeLocation && !activeProject
        ? `${saveKey}Areas`
        : saveKey
      : null;

  return (
    <>
      <ScrollToTopOnMount />
      <List
        tabs={tabs}
        dataIsHash
        data={content?.sort((a, b) => {
          return (
            new Date(b.dateIncidentOccured) - new Date(a.dateIncidentOccured)
          );
        })}
        settings={columns}
        rowClick={row =>
          loadIncident({
            _id: row._id,
            returnPath: `/app/Incidents`
          })
        }
        saveKey={newSaveKey}
      />
    </>
  );
};

export default IncidentsList;
