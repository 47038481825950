import React from 'react';
import moment from 'moment';
import classnames from 'classnames/bind';
import Clone from '../../svgs/clone';
import Edit from '../../svgs/edit';
import Share from '../../svgs/share';
import Favorite from '../../svgs/favorite';
import Trash from '../../svgs/trash';

import history from '../../history';

import styles from './card.module.scss';
const bStyles = classnames.bind(styles);

export default function DashboardCard({
  dashboard,
  handleShareClick,
  handleEditClick,
  handleCloneClick,
  fromLeaderboard,
  handleToggleFavorite,
  handleDeleteClick,
  bottomStyles,
  readOnly
}) {
  return (
    <div
      key={dashboard._id}
      className={styles.dashboard}
      onClick={() =>
        fromLeaderboard
          ? history.push(`/app/leaderboards/view/${dashboard._id}`)
          : history.push(`/app/myAnalytics/dashboard/${dashboard._id}`)
      }
    >
      <span className={styles.title} data-cy={`${dashboard.name}Card`}>
        {dashboard.name}
      </span>
      <div className={styles.options}>
        {dashboard?.shares?.length && !fromLeaderboard ? (
          <div className={styles.badge}>{dashboard?.shares?.length}</div>
        ) : null}
        {!dashboard.readOnly && (
          <div onClick={e => handleEditClick(e)}>
            <div>
              <Edit />
            </div>
            <span data-cy={`${dashboard.name}EditButton`}>Edit</span>
          </div>
        )}
        {!dashboard.sharedBy && !fromLeaderboard && (
          <div onClick={e => handleShareClick(e)}>
            <div>
              <Share />
            </div>
            <span data-cy={`${dashboard.name}ShareButton`}>Share</span>
          </div>
        )}
        {!fromLeaderboard && (
          <div onClick={e => handleCloneClick(e)}>
            <div>
              <Clone />
            </div>
            <span data-cy={`${dashboard.name}CloneButton`}>Clone</span>
          </div>
        )}
      </div>
      <div className={bStyles(bottomStyles, styles.bottom)}>
        {!fromLeaderboard && (
          <Favorite
            className={bStyles('favorite', {
              isFavorite: dashboard.isFavorite
            })}
            onClick={e => handleToggleFavorite(e)}
          />
        )}
        {!readOnly && (
          <Trash
            className={styles.trash}
            data-cy={`${dashboard.name}TrashButton`}
            onClick={e => handleDeleteClick(e)}
          />
        )}
      </div>
      {dashboard.sharedBy && (
        <div className={styles.sharedInfo}>{`Accessible ${
          dashboard.endDate
            ? 'until ' + moment(dashboard.endDate).format('MM/DD/YYYY')
            : 'Indefinitely'
        }`}</div>
      )}
    </div>
  );
}
