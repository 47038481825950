import config from '../config/config';

const featureToggles = config.featureToggles;
export const leftNavigation = {
  sections: [
    {
      title: 'General',
      section: [
        {
          text: 'Dashboard',
          image: 'Dashboard',
          link: '/app/dashboard',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Tasks',
          image: 'Tasks',
          link: '/app/tasks',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Reports',
          image: 'Incidents',
          link: '/app/incidents',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Claims',
          image: 'Claims',
          link: '/app/claims',
          accessLevel: [500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'OSHA Forms',
          image: 'oshaLogo',
          link: '/app/oshaLog',
          accessLevel: [500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Employees',
          image: 'Employees',
          link: '/app/settings/Employees',
          accessLevel: [500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'My Analytics',
          image: 'BarChart',
          link: '/app/myAnalytics',
          accessLevel: [500, 900],
          appLevel: ['Division', 'Group'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Leaderboards',
          image: 'Leaderboard',
          link: '/app/leaderboards',
          accessLevel: [500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Equipment',
          image: 'Equipment',
          link: '/app/equipmentList',
          accessLevel: [900, 500],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Covid-19 Tracking',
          //image: 'BarChart',
          link: '/app/covidTracking',
          accessLevel: [900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: false
        }
      ],
      accessLevel: [100, 400, 500, 900]
    },
    {
      title: 'Incidents',
      section: [
        {
          text: 'First Report of Injury',
          image: 'first-report-of-injury',
          link: '/app/reportStages/2',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Vehicle Incident Reports',
          image: 'auto-accident',
          link: '/app/reportStages/1',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Property Damage Reports',
          image: 'property-damage',
          link: '/app/reportStages/3',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Near Miss Reports',
          image: 'near-miss-report',
          link: '/app/reportStages/7',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Theft Reports',
          image: 'theft-reports',
          link: '/app/reportStages/4',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Third Party Injury Reports',
          image: 'general-liability',
          link: '/app/reportStages/5',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Aircraft Reports',
          image: '6',
          link: '/app/reportStages/6',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Spill/Misfuel Reports',
          image: '16',
          link: '/app/reportStages/16',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        }
      ],
      accessLevel: [400, 500, 900]
    },
    {
      title: 'Observations',
      section: [
        {
          text: 'Behavior Observations',
          image: 'critical-behavior',
          link: '/app/behaviorObservations',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Safety Audits',
          image: 'jsa',
          link: '/app/safetyAudits',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Safety Audit Exception',
          image: 'trainingException',
          link: '/app/safetyAuditException',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Safety Walks',
          image: 'site-inspection',
          link: '/app/safetyWalkStages',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        }
      ],
      accessLevel: [100, 400, 500, 900]
    },
    {
      title: 'Training & Qualification',
      section: [
        {
          text: 'Safety Trainings',
          image: 'safety-training',
          link: '/app/training/trainingList',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: true,
          folderType: 'SafetyTraining',
          showFeature: true
        },
        {
          text: 'Training Exception',
          image: 'trainingException',
          link: '/app/trainingException',
          accessLevel: [400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Qualifications',
          image: 'qualifications',
          link: '/app/documents/qualifications',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: true,
          folderType: 'Qualification',
          showFeature: true
        }
      ],
      accessLevel: [100, 400, 500, 900]
    },
    {
      title: 'Documents',
      section: [
        {
          text: 'My Documents',
          image: 'Tasks',
          link: '/app/myDocumentsStages',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Safety Procedures',
          image: 'safety-instruction',
          link: '/app/documents/safetyInstructions',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: true,
          folderType: 'SafetyInstruction',
          showFeature: true
        },
        {
          text: 'SDS',
          image: 'sds',
          link: '/app/documents/sds',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: true,
          folderType: 'SDS',
          showFeature: true
        },
        {
          text: 'Custom Documents',
          image: 'CustomDocument',
          link: '/app/customDocumentList',
          accessLevel: [100, 400, 500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: featureToggles.customDocuments
        }
      ],
      accessLevel: [100, 400, 500, 900]
    },
    {
      title: 'Custom Templates',
      section: [
        {
          text: 'Report Questions',
          image: 'CustomQuestions',
          link: '/app/customReportQuestionsList',
          accessLevel: [900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Safety Audit Templates',
          navItem: 'Safety Audit Templates',
          image: 'CustomRa',
          link: '/app/safetyAuditTemplates',
          accessLevel: [900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Document Templates',
          image: 'CustomDocument',
          link: '/app/customDocumentTemplateList',
          accessLevel: [900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: featureToggles.customDocuments
        },
        {
          text: 'Training Templates',
          image: 'safety-training',
          link: '/app/training/templateList',
          accessLevel: [900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Training Quizzes',
          image: 'quiz',
          link: '/app/quiz/quizList',
          accessLevel: [900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Behavior Observation Templates',
          image: 'critical-behavior',
          link: '/app/behaviorObservation/templates',
          accessLevel: [900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        }
      ],
      accessLevel: [900]
    },
    {
      title: 'Settings',
      section: [
        {
          text: 'Areas',
          image: 'Projects',
          link: '/app/settings/Projects',
          accessLevel: [900, 500],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Groups / Establishments',
          image: 'Locations',
          link: '/app/settings/Locations',
          accessLevel: [500, 900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        },
        {
          text: 'Company Division',
          image: 'Dashboard',
          link: '/app/settings/CompanyDetails',
          accessLevel: [900],
          appLevel: ['Division', 'Group', 'Project'],
          hasFolders: false,
          showFeature: true
        }
      ],
      accessLevel: [500, 900]
    }
  ]
};
