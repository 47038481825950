import React from 'react';
import { TRAINING_FILE_TYPES_ACCEPTED } from '../../../constants/constants';
import { InputRow, Button, Input } from '../../../components/inputs';
import {
  getAttachmentSelection,
  isImage,
  isYoutube
} from '../../../utils/attachmentSelector';
import styles from './styles.module.scss';
import videoImg from '../../../assets/images/videoPicture.png';

export const TrainingMaterials = ({
  fromApproveTraining,
  updateAnswer,
  trainingType,
  isCreating,
  isEditing,
  addAttachments,
  attachments,
  addedAttachments,
  deleteAttachment,
  youtubeLinks,
  quizMasterId,
  canAddMaterials,
  isTemplateCreate
}) => {
  const hasMaterials =
    attachments?.length > 0 ||
    addedAttachments?.length > 0 ||
    youtubeLinks?.length > 0 ||
    quizMasterId;

  const materialBox = (material, index, isYoutubeVideo) => {
    const youtubeLink = isYoutubeVideo ?? isYoutube(material);
    const imageBoolean = !youtubeLink ? isImage(material) : false;
    const fromTemplate =
      attachments?.some(a => a._id === material._id) && !isTemplateCreate;
    const disabled = (!isCreating && !isEditing) || fromTemplate;
    return (
      <div className={styles.training}>
        <a
          key={index}
          href={material.source_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={
              youtubeLink
                ? videoImg
                : imageBoolean
                ? material.source_url
                : getAttachmentSelection(material)
            }
            alt="images"
            className={styles.trainingAttachment}
          />
        </a>
        <p className={styles.trainingFileName}>
          {material.title ?? material.original_filename}
        </p>
        {!disabled && !youtubeLink && (
          <Button
            type="button"
            color="red"
            inputClassName={styles.trainingAttachmentButton}
            text="X"
            onClick={() => deleteAttachment(material)}
          />
        )}
      </div>
    );
  };
  return (
    <>
      {!fromApproveTraining && !hasMaterials && (
        <Input
          fieldLabel={
            trainingType?.value === 1
              ? 'Either Training Materials or a Quiz are required to create a training, but you are not required to have both.'
              : trainingType?.value === 2
              ? 'Training Materials required to create a training.'
              : ''
          }
          labelStyles={styles.requiredText}
        />
      )}
      <Input fieldLabel="Training Materials" />
      {(isCreating || canAddMaterials) && (
        <InputRow>
          <Button
            text="Add YouTube Links"
            color="blue"
            onClick={() => updateAnswer({ openYoutubeLinksModal: true })}
            disabled={!isCreating && !isEditing}
          />
          <Button
            text="Upload Training Materials"
            color="blue"
            onClick={() => document.getElementById(`myImage`).click()}
            disabled={!isCreating && !isEditing}
          />
        </InputRow>
      )}
      <input
        type="file"
        id="myImage"
        className={styles.fileUpload}
        onChange={e => addAttachments(e)}
        multiple
        accept={TRAINING_FILE_TYPES_ACCEPTED.join(', ')}
      />
      <div className={styles.materials}>
        {youtubeLinks?.map((link, index) => materialBox(link, index, true))}
        {addedAttachments?.map((attachment, index) =>
          materialBox(attachment, index)
        )}
        {attachments?.map((attachment, index) =>
          materialBox(attachment, index)
        )}
      </div>
    </>
  );
};

export default TrainingMaterials;
