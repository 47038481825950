import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getActiveCompany } from '../../selectors/company';
import history from '../../history';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import Card from '../Card';

import './index.css';

export class CompanyDetails extends Component {
  render() {
    const { activeCompany } = this.props;

    return (
      <Card
        title="Account Settings"
        showHeader
        rightButton={{
          color: 'blue',
          onClick: () => history.push('/app/viewEditCompany'),
          text: 'Edit'
        }}
        className="settings"
      >
        <ScrollToTopOnMount />
        <div className="accountSettings">
          <div className="leftColumn">
            <img
              src={
                activeCompany.logoUrl ||
                require('../../assets/images/no-image-placeholder.jpg')
              }
              alt={`${activeCompany.name}'s Logo`}
            />
          </div>

          <div className="rightColumn-company">
            <div className="smallHeader">COMPANY</div>
            <h1 className="companyName">{activeCompany.name}</h1>

            <h4>
              {activeCompany.mainAddress
                ? `${activeCompany.mainAddress.streetAddress} ${activeCompany.mainAddress.city}, ${activeCompany.mainAddress.stateName} ${activeCompany.mainAddress.zip}`
                : 'Address not available'}
            </h4>

            <div className="smallHeader-stats">PHONE</div>
            <h5 className="companyName">
              {activeCompany.phoneNumber
                ? activeCompany.phoneNumber.fullPhoneNumber
                : 'Not available'}
            </h5>

            <div className="smallHeader-stats">WEBSITE</div>
            {activeCompany.website ? (
              <a
                href={activeCompany.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                {activeCompany.website}
              </a>
            ) : (
              <h5 className="companyName">{'Not available'}</h5>
            )}
          </div>

          {/* TODO Add back when you have NAICS */}
          {/* <div className="smallHeader-stats">CLASS</div>
            <h5 className="companyName">
              {activeCompany.industry
                ? INDUSTRY_LIST[activeCompany.industry - 1]
                : 'Not available'}
            </h5>
          </div> */}
        </div>
      </Card>
    );
  }
}

export const mapStateToProps = state => ({
  activeCompany: getActiveCompany(state)
});

export default connect(mapStateToProps)(CompanyDetails);
