import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import {
  addUploadedAttachmentsRequest,
  addUploadedAttachmentsResponse,
  deleteAttachmentRequest
} from '../../actions/attachments';
import {
  saveIncidentBasicsRequest,
  updateIncidentBasicsRequest,
  createIncidentRequest
} from '../../actions/incidents';
import { addMessage } from '../../actions/messages';
import { createInitialReport, deleteInitialReport } from '../../api/v4';
import { updateAttachment } from '../../api';
import config from '../../config/config';
import { AUTOSAVE_TIME } from '../../constants/constants';
import { withSocket } from '../../utils/withSocket';
import { getActiveCompany, getActiveLocationId } from '../../selectors/company';
import {
  getActiveIncidentSelector,
  getIncidentBasicsSelector,
  getIncidentsLoadingSelector
} from '../../selectors/incidents';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import {
  getActiveReportsSelector,
  getActiveReportTemplatesSelector
} from '../../selectors/reports';
import { getLoggedInUser } from '../../selectors/users';
import { isInitialReportSectionComplete } from '../../utils/incidentHelper';
import Diagram from '../../components/Diagram';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import {
  SaveCancelFooter,
  SubmitSaveForLaterCancelFooter
} from '../../components/Footer';
import DiagramCard from '../../components/reportCards/DiagramCard';
import PhotoEvidence from '../../components/reportCards/PhotoEvidence';
import IncidentSection from '../../components/reportCards/IncidentSection';
import SignatureCard from '../../components/SignatureCard';
import LeftNav from '../../components/LeftNav';
import Assignee from '../../components/Assignee';
import ViewOnly from '../../components/ViewOnly';
import Modal from '../../components/Modal';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import {
  isBodyDiagram,
  isRoadDiagram,
  isVehicleDiagram,
  isAircraftDiagram,
  isImage
} from '../../utils/attachmentSelector';

import styles from './initialReport.modules.scss';

export class InitialReportContainer extends Component {
  state = {
    answers: {},
    rowId: null,
    leftNav: [],
    imageUrl: '',
    diagramCategory: { name: '', url: '' },
    didSubmit: false,
    signature: undefined,
    openUnlockAndReopenReportModal: false,
    isEditing: false,
    hasChanges: false,
    openUnsavedChangesModal: false,
    openSubmitModal: false,
    openSaveModal: false,
    openSaveMissingFieldsModal: false,
    openSubmitMissingFieldsModal: false,
    updatedGroup: false,
    showUpdatedGroupModal: false,
    openDeleteModal: false,
    socketConnected: false,
    savedInitialReport: null,
    autosave: false
  };

  componentDidMount() {
    if (this.props.activeIncident) {
      this.setState({
        answers: {
          ...this.props.activeIncident.answers,
          locationId:
            this.props.activeIncident.locationId ??
            this.props.activeIncident.groupId
        },
        signature: this.props.activeIncident.signature,
        isCreating: this.props?.activeIncident?.rowId?.length,
        rowId: this.props?.activeIncident?.rowId,
        hasChanges: this.props?.activeIncident?.reportComponents?.length
          ? false
          : true
      });
    } else {
      this.setState({ isCreating: true });
    }

    if (this.props.activeLocation && !this.props.activeIncident) {
      this.setState({ answers: { locationId: this.props.activeLocation._id } });
    }
  }

  componentWillUnmount() {
    if (this.state.autosave) clearTimeout(this.state.autosave);
  }

  requiredFieldsToSave = () => {
    return (
      this.state?.answers?.[config.staticIds.incidentDescription]?.length > 3 &&
      (this.state?.answers?.locationId?.length ||
        this.props.activeIncident?.locationId?.length)
    );
  };

  autoSave = () => {
    if (!this.state.autosave) {
      const timeout = setTimeout(() => {
        if (this.requiredFieldsToSave()) {
          const fields = {
            groupId: this.state.answers?.locationId,
            companyId: this.props.activeCompany._id,
            answers: { ...this.state.answers },
            reportTemplateIds: this.props.reportTemplateIds,
            signature: this.state.signature,
            attachments: this.props.addedAttachments
          };
          if (this.state.rowId || this.props.activeIncident) {
            if (!this.state.socketConnected) {
              this.props.socketSubscribe({
                id: this.state.rowId ?? this.props.activeIncident?.rowId
              });
              this.setState({ socketConnected: true });
            }
            this.props.socketEmit('saveInitial', {
              ...(this.state.savedInitialReport ?? this.props.activeIncident),
              ...fields
            });
            this.props.addMessage({
              error: false,
              message: 'Autosaved Successfully'
            });
          } else {
            createInitialReport(fields).then(r => {
              this.setState({ savedInitialReport: r, rowId: r._id });
              this.props.socketSubscribe({ id: r._id });
              const allAttachments = fields.signature
                ? [fields.signature, ...fields.attachments]
                : fields.attachments;
              allAttachments.forEach(attachment => {
                updateAttachment({
                  ...attachment,
                  ownerType: 'incident',
                  ownerId: r._id
                });
              });
            });
            this.props.addMessage({
              error: false,
              message: 'Initial Report Draft Autosaved'
            });
          }
          this.setState({ autosave: false });
          this.setState({ hasChanges: false });
        }
      }, AUTOSAVE_TIME);
      this.setState({ autosave: timeout });
    }
  };

  handleSelectedAnswer = answersObject => {
    if (this.state.isEditing && answersObject.locationId) {
      this.setState({ updatedGroup: true });
    }
    this.setState(state => ({
      answers: { ...state.answers, ...answersObject },
      hasChanges: true
    }));

    this.autoSave();
  };

  submitActions = () => {
    this.setState({ didSubmit: true });
    if (this.state.isEditing) {
      this.props.updateIncidentBasics(
        this.props.activeIncident._id,
        this.state.answers,
        this.state.signature
      );
      this.setState({
        didSubmit: false,
        updatedGroup: false,
        hasChanges: false,
        showUpdatedGroupModal: false
      });
      return;
    }
    this.props.createIncident({
      answers: this.state.answers,
      signature: this.state.signature,
      rowId: this.state.rowId
    });
  };

  saveIncidentBasics = () => {
    this.props.saveIncidentBasics({
      answers: this.state.answers,
      signature: this.state.signature,
      rowId: this.state.rowId
    });
  };

  handlePhotoUpload = async e => {
    const files = e.target.files;
    if (!files.length) return;
    for (let i = 0; i < files.length; i++) {
      if (!isImage(files[i])) {
        alert(`File must be one of png, jpeg, or jpg type`);
        return;
      }
    }
    let data = new FormData();
    for (const file of Object.entries(files)) {
      data.append('attachments', file[1], file[1].name);
    }
    await this.props.addAttachment({
      data,
      ownerId: '1234',
      ownerType: 'incident',
      isPhotoEvidence: true
    });
  };

  updatePhotoEvidence = photo => {
    const { addedAttachments, addUploadedAttachments } = this.props;
    const updated = addedAttachments.map(currentPhoto => {
      if (currentPhoto._id === photo._id) {
        return photo;
      }
      return currentPhoto;
    });
    addUploadedAttachments(updated);
  };

  disableSubmit = () => {
    const { incidentBasics } = this.props;
    let disableSubmit = false;
    for (const section of incidentBasics.sections) {
      if (!isInitialReportSectionComplete(section, this.state.answers)) {
        disableSubmit = true;
      }
    }

    return disableSubmit || !this.state.signature;
  };

  getBodyDiagrams = addedAttachments =>
    addedAttachments.filter(attachment => isBodyDiagram(attachment));

  getAccidentDiagrams = addedAttachments =>
    addedAttachments.filter(attachment => isRoadDiagram(attachment));

  getVehicleDiagrams = addedAttachments =>
    addedAttachments.filter(attachment => isVehicleDiagram(attachment));

  getAircraftDiagrams = addedAttachments =>
    addedAttachments.filter(attachment => isAircraftDiagram(attachment));

  handleGoBack = () => {
    const { activeIncident, loggedInUser } = this.props;

    if (this.state.isEditing) {
      this.setState((state, props) => ({
        isEditing: false,
        openUnsavedChangesModal: false,
        hasChanges: false,
        answers: { ...props.activeIncident.answers }
      }));
      return;
    }

    if (!activeIncident || loggedInUser.accessLevel === 100) {
      history.push('/app/dashboard');
      return;
    }
    history.goBack();
  };

  handleDeleteBasics = () => {
    deleteInitialReport(this.state.rowId).then(() => {
      history.push('/app/dashboard');
    });
  };

  reopenReport = () => {
    this.setState({
      openUnlockAndReopenReportModal: false,
      isEditing: true
    });
  };

  render() {
    const {
      incidentBasics,
      activeCompany,
      addAttachment,
      addedAttachments,
      deleteAttachment,
      loggedInUser,
      activeIncident,
      activeReport
    } = this.props;

    const {
      answers,
      imageUrl,
      diagramCategory,
      didSubmit,
      isEditing,
      isCreating,
      updatedGroup,
      showUpdatedGroupModal
    } = this.state;

    const leftNav = incidentBasics.sections?.length
      ? incidentBasics.sections
          .filter(x => x)
          .map((section, i) => ({
            label: section.label,
            complete: isInitialReportSectionComplete(section, answers)
          }))
      : [];

    if (leftNav && leftNav.length > 0) {
      leftNav.push({ label: 'Diagrams', id: 'diagrams' });
      leftNav.push({ label: 'Signature', id: 'signature' });
    }

    const photoEvidence = addedAttachments =>
      addedAttachments.filter(
        attachment => attachment.isPhotoEvidence && !attachment?.isRemoved
      );

    const footer = isEditing ? (
      <SaveCancelFooter
        saveButtonDisabled={(isEditing && this.disableSubmit()) || didSubmit}
        saveButtonClick={() =>
          updatedGroup
            ? this.setState({ showUpdatedGroupModal: true })
            : this.submitActions()
        }
        cancelButtonClick={() =>
          this.state.hasChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : this.handleGoBack()
        }
        editing={isEditing}
      />
    ) : (
      <SubmitSaveForLaterCancelFooter
        saveButtonClick={() =>
          !this.requiredFieldsToSave()
            ? this.setState({ openSaveMissingFieldsModal: true })
            : this.setState({ openSaveModal: true })
        }
        saveButtonDisabled={didSubmit}
        saveButtonText="Save as Draft"
        cancelButtonClick={() =>
          this.state.hasChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : this.handleGoBack()
        }
        cancelButtonDisabled={didSubmit}
        showDeleteInsteadOfCancel={this.state.rowId}
        deleteButtonClick={() => this.setState({ openDeleteModal: true })}
        submitButtonClick={() =>
          !this.requiredFieldsToSave() || this.disableSubmit()
            ? this.setState({ openSubmitMissingFieldsModal: true })
            : this.setState({ openSubmitModal: true })
        }
        submitButtonDisabled={didSubmit}
      />
    );

    const reporter = (
      <Assignee
        text="Reported By"
        user={activeIncident?.createdByUserId || loggedInUser._id}
      />
    );

    const left = (
      <LeftNav
        items={leftNav}
        onAddEvidence={
          !isEditing &&
          (e => {
            this.handlePhotoUpload(e);
            e.target.value = '';
          })
        }
        showAddEvidence
      />
    );

    const headerCenter = activeIncident && !isCreating && !isEditing && (
      <ViewOnly
        canUnlock={!isEditing && loggedInUser?.accessLevel === 900}
        incident={activeIncident}
        loggedInUser={loggedInUser}
        onClick={() => this.setState({ openUnlockAndReopenReportModal: true })}
        activeReport={activeReport}
      />
    );

    const header = (
      <Header
        title="Initial Report"
        needsSaved={this.state.hasChanges}
        clickBack={() =>
          this.state.hasChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : this.handleGoBack()
        }
        center={headerCenter}
        right={reporter}
      />
    );

    return !imageUrl ? (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          Left={(isCreating || isEditing) && left}
          showFooter={isCreating || isEditing}
        >
          {incidentBasics.sections.map((basic, index) => (
            <IncidentSection
              name={index}
              reportSectionHeader={basic.label}
              fields={basic.fields}
              key={index}
              handleSelectedAnswer={this.handleSelectedAnswer}
              answers={answers}
              isIncidentBasics={index === 0}
              activeIncident={activeIncident}
              isCreating={isCreating}
              isEditing={isEditing}
              isAdmin={loggedInUser.accessLevel >= 900}
            />
          ))}
          {photoEvidence(addedAttachments).map((photo, index) => (
            <PhotoEvidence
              photo={photo}
              key={incidentBasics.sections.length + index}
              name={incidentBasics.sections.length + index}
              updatePhotoEvidence={values =>
                this.updatePhotoEvidence({ ...photo, ...values })
              }
              canRemoveEdit={isCreating}
            />
          ))}
          <DiagramCard
            reportSectionHeader="Diagrams"
            bodyDiagrams={this.getBodyDiagrams(addedAttachments)}
            accidentDiagrams={this.getAccidentDiagrams(addedAttachments)}
            vehicleDiagrams={this.getVehicleDiagrams(addedAttachments)}
            aircraftDiagrams={this.getAircraftDiagrams(addedAttachments)}
            deleteAttachment={deleteAttachment}
            handleDiagramSelection={value => this.setState(value)}
            name="diagrams"
            canRemoveEdit={isCreating}
            hideNA={true}
            showAircraft={activeCompany.showAircraft}
          />
          <SignatureCard
            wide
            header="Signature"
            name="signature"
            onChange={signature => {
              this.setState({ signature, hasChanges: true });
              this.autoSave();
            }}
            disabled={(!isCreating && !isEditing) || this.state.signature}
            currentValue={this.state.signature}
          />
        </HeaderAndFooter>
        <Modal
          title="Unlock and Re-Open Report"
          titleClassName="blueHeader"
          isOpen={this.state.openUnlockAndReopenReportModal}
          submitButtonColor="blue"
          submitButtonText="Re-open Report"
          onRequestClose={() =>
            this.setState({ openUnlockAndReopenReportModal: false })
          }
          submitActions={this.reopenReport}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            Unlocking this report will allow you –and users with edit
            permission– to make changes and additions to the report.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              This also means that the report will no longer be considered
              completed until it is filled out, saved, and marked as complete
              once more. Re-Opening this report will also remove the currently
              assigned user.
            </span>{' '}
            You can reassign this report to the same or a different user as a
            new task.
          </div>
        </Modal>
        <Modal
          title="Save Draft"
          titleClassName="blueHeader"
          isOpen={this.state.openSaveModal}
          submitButtonColor="blue"
          submitButtonText="Save"
          onRequestClose={() => this.setState({ openSaveModal: false })}
          submitActions={this.saveIncidentBasics}
        >
          <div className={styles.modalText}>
            <span style={{ fontWeight: 'bold' }}>
              This report is incomplete, and will be saved as a Draft.
            </span>{' '}
            Go to <span style={{ fontWeight: 'bold' }}>My Saved Drafts</span> on
            your Dashboard to complete and submit the report.
          </div>
        </Modal>
        <Modal
          title="Missing Fields to Save Draft"
          titleClassName="redHeader"
          isOpen={this.state.openSaveMissingFieldsModal}
          submitButtonColor="red"
          submitButtonText="Go Back"
          onRequestClose={() =>
            this.setState({ openSaveMissingFieldsModal: false })
          }
          submitActions={() =>
            this.setState({ openSaveMissingFieldsModal: false })
          }
          hideButtons
        >
          <div className={styles.modalText}>
            You must fill out{' '}
            <span style={{ fontWeight: 'bold' }}>Group/Establishment</span> and{' '}
            <span style={{ fontWeight: 'bold' }}>
              Describe in Detail what happened or what you observed
            </span>{' '}
            in order to save as a draft.
          </div>
        </Modal>
        <Modal
          title="Submit Report"
          titleClassName="blueHeader"
          isOpen={this.state.openSubmitModal}
          submitButtonColor="blue"
          submitButtonText="Submit"
          onRequestClose={() => this.setState({ openSubmitModal: false })}
          submitActions={this.submitActions}
          testID="createReport"
          disableSubmit={didSubmit}
          disableCancel={didSubmit}
        >
          <div className={styles.modalText}>
            This report will now be submitted, and your supervisor notified.
          </div>
        </Modal>
        <Modal
          title="Missing fields to Submit Report"
          titleClassName="redHeader"
          isOpen={this.state.openSubmitMissingFieldsModal}
          submitButtonColor="red"
          submitButtonText="Go Back"
          onRequestClose={() =>
            this.setState({ openSubmitMissingFieldsModal: false })
          }
          submitActions={() =>
            this.setState({ openSubmitMissingFieldsModal: false })
          }
          testID="createReport"
          hideButtons
        >
          <div className={styles.modalText}>
            You must answer all required questions{' '}
            <span style={{ color: 'red' }}>(*)</span> to submit this report.
          </div>
        </Modal>
        <Modal
          title="Change Group/Establishment"
          titleClassName="blueHeader"
          isOpen={showUpdatedGroupModal}
          submitButtonColor="blue"
          submitButtonText="Submit"
          onRequestClose={() => this.setState({ showUpdatedGroupModal: false })}
          submitActions={this.submitActions}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            Changing the Group/Establishment of this incident will update the
            following:
            <p style={{ fontWeight: 'bold' }}>
              Change the incident owner to the default incident owner for the
              Group/Establishment you have selected.
            </p>
            <p style={{ fontWeight: 'bold' }}>
              Remove all current user permissions to see this incident and add
              default permissions for the Group/Establishment you have selected.
            </p>
            <p style={{ fontWeight: 'bold' }}>
              Remove all current Custom Tasks and Report Assignments.
            </p>
            Are you sure you want to continue?
          </div>
        </Modal>
        <Modal
          title="Delete Saved Drafts"
          titleClassName="redHeader"
          isOpen={this.state.openDeleteModal}
          submitButtonColor="red"
          submitButtonText="Delete"
          onRequestClose={() => this.setState({ openDeleteModal: false })}
          submitActions={this.handleDeleteBasics}
        >
          <div className={styles.modalText}>
            <span style={{ fontWeight: 'bold' }}>
              Are you sure you want to delete your Saved Draft?
            </span>{' '}
            You will not be able to come back to this draft and will have to
            start again.
          </div>
        </Modal>
        <SaveChangesModal
          isOpen={this.state.openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() => this.handleGoBack()}
          savingWhat="an incident"
        />
      </>
    ) : (
      <div className="initialReportContainer">
        <div>
          {diagramCategory.name !== '' && (
            <Diagram
              imageUrl={imageUrl}
              addAttachment={addAttachment}
              resetDiagrams={() =>
                this.setState({
                  imageUrl: '',
                  diagramCategory: { name: '', url: '' },
                  diagramSubcategory: { name: '', url: '' }
                })
              }
              category={diagramCategory.name}
              loggedInUser={loggedInUser}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state),
  incidentBasics: getIncidentBasicsSelector(state),
  activeCompany: getActiveCompany(state),
  incidentLoading: getIncidentsLoadingSelector(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  activeIncident: getActiveIncidentSelector(state),
  activeReport: getActiveReportsSelector(state),
  activeLocation: getActiveLocationId(state),
  reportTemplateIds: getActiveReportTemplatesSelector(state)
});

const mapDispatchToProps = dispatch => ({
  createIncident: incident => dispatch(createIncidentRequest(incident)),
  updateIncidentBasics: (id, answers, signature) =>
    dispatch(updateIncidentBasicsRequest({ id, answers, signature })),
  addAttachment: attachment =>
    dispatch(addUploadedAttachmentsRequest(attachment)),
  deleteAttachment: attachment => dispatch(deleteAttachmentRequest(attachment)),
  addUploadedAttachments: values =>
    dispatch(addUploadedAttachmentsResponse(values)),
  saveIncidentBasics: payload => dispatch(saveIncidentBasicsRequest(payload)),
  addMessage: message => dispatch(addMessage(message))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSocket(InitialReportContainer));
