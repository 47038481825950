import ApproveTraining from '../containers/Training/ApproveTraining';
import BehaviorObservations from '../containers/BehaviorObservations';
import BehaviorObservationContainer from '../containers/BehaviorObservations/BOContainer';
import BehaviorObservationTemplates from '../containers/BehaviorObservations/templateList';
import BehaviorObservationTemplate from '../containers/BehaviorObservations/Template';
import Claims from '../containers/Claims';
import ClaimCreate from '../containers/Claims/Create';
import CustomDocumentContainer from '../containers/CustomDocumentContainer';
import CustomDocumentList from '../containers/CustomDocumentList';
import CustomDocumentTemplateContainer from '../containers/CustomDocumentTemplateContainer';
import CustomDocumentTemplateList from '../containers/CustomDocumentTemplateList';
import CustomReportQuestionContainer from '../containers/CustomReportQuestionContainer';
import CustomReportQuestionsList from '../containers/CustomReportQuestionsList';
import CustomTrainingTemplates from '../containers/Training/CustomTemplates';
import Dashboard from '../containers/Dashboard';
import Documents from '../containers/Documents';
import FireExposureForm from '../containers/FireExposureForm';
import HazardCard from '../containers/SafetyWalkContainer/HazardCard';
import IncidentContainer from '../containers/IncidentContainer';
import IncidentStages from '../containers/IncidentStages';
import InitalReportContainer from '../containers/InitialReportContainer';
import BravoCard from '../containers/SafetyWalkContainer/BravoCard';
import MyAnalytics from '../containers/MyAnalytics';
import MyAnalyticsChart from '../containers/MyAnalytics/Chart';
import MyAnalyticsDashboard from '../containers/MyAnalytics/Dashboard';
import MyAnalyticsDetail from '../containers/MyAnalytics/Detail';
import MyDocumentContainer from '../containers/MyDocumentContainer';
import MyDocumentsStages from '../containers/MyDocumentsStages';
import OshaLog from '../containers/OshaLog';
import PhotoEvidenceContainer from '../containers/PhotoEvidenceContainer';
import PerformTraining from '../containers/Training/PerformTraining';
import Permissions from '../containers/Permissions';
import Profile from '../containers/Profile';
import QuizList from '../containers/Quiz/QuizList';
import QuizTemplate from '../containers/Quiz/QuizTemplate';
import RecurrenceContainer from '../containers/RecurrenceContainer';
import ReportSectionContainer from '../containers/ReportSectionContainer';
import ReportStages from '../containers/ReportStages';
import RequireAuthorizationList from '../containers/RequireAuthorizationList';
import SafetyAudits from '../containers/SafetyAudits';
import SafetyAuditCreate from '../containers/SafetyAudits/Create';
import SafetyAuditPerform from '../containers/SafetyAudits/Perform';
import SafetyAuditSummary from '../containers/SafetyAudits/Summary';
import SafetyAuditTemplates from '../containers/SafetyAudits/templateList';
import SafetyAuditTemplate from '../containers/SafetyAudits/Template';
import SafetyInstructionList from '../containers/SafetyInstructionList';
import SafetyWalkContainer from '../containers/SafetyWalkContainer';
import SafetyWalkStages from '../containers/SafetyWalkStages';
import SdsList from '../containers/SdsList';
import SearchPage from '../containers/SearchPage';
import Settings from '../containers/Settings';
import TaskContainer from '../containers/TaskContainer';
import TaskStages from '../containers/TaskStages';
import TemplateList from '../containers/Training/TemplateList';
import TrainingAttachmentViewer from '../containers/Training/TrainingAttachmentViewer';
import TrainingContainer from '../containers/Training/TrainingContainer';
import TrainingList from '../containers/Training/TrainingList';
import TrainingQuizViewer from '../containers/Training/TrainingQuizViewer';
import TrainingSummary from '../containers/Training/TrainingSummary';
import TransitionContainer from '../containers/TransitionContainer';
import ViewEdit from '../containers/ViewEdit';
import ViewEditCompany from '../containers/ViewEditCompany';
import ViewEditEmployee from '../containers/ViewEditEmployee';
import CovidEmployeeList from '../containers/Covid-19/employeeList';
import EmployeeCovidStatus from '../containers/Covid-19/StatusPage';
import EmployeeExposure from '../containers/Covid-19/ExposurePage';
import WitnessStatementContainer from '../containers/WitnessStatementContainer';
import EquipmentList from '../containers/EquipmentList';
import EquipmentContainer from '../containers/EquipmentContainer';
import TrainingReport from '../containers/ExceptionReporting/training';
import Leaderboards from '../containers/MyLeaderBoards';
import CreateMyLeaderboard from '../containers/MyLeaderBoards/Create';
import Leaderboard from '../containers/MyLeaderBoards/Leaderboard';
import LeaderboardDashboard from '../containers/MyLeaderBoards/Dashboard';
import ViewLeaderboard from '../containers/MyLeaderBoards/ViewLeaderboardTables';
import SafetyAuditReport from '../containers/ExceptionReporting/safetyAudit';

import { TYPE_NAMES } from '../constants/constants';

const routes = [
  {
    path: '/app/training/approveTraining/:trainingId?',
    showLeftNav: false,
    leftNav: 'Safety Trainings',
    component: ApproveTraining
  },
  {
    path: '/app/behaviorObservations',
    showLeftNav: true,
    leftNav: 'Behavior Observations',
    component: BehaviorObservations
  },
  {
    path: '/app/behaviorObservation/container/:boId?',
    showLeftNav: false,
    leftNav: 'Behavior Observation',
    component: BehaviorObservationContainer
  },
  {
    path: '/app/behaviorObservation/templates',
    showLeftNav: true,
    leftNav: 'Behavior Observation Templates',
    component: BehaviorObservationTemplates
  },
  {
    path: '/app/behaviorObservation/template/:templateId?/:duplicate?',
    showLeftNav: false,
    leftNav: 'Behavior Observation Templates',
    component: BehaviorObservationTemplate
  },
  {
    path: '/app/claims/create/:claimId?',
    showLeftNav: false,
    leftNav: 'Reports',
    component: ClaimCreate
  },
  {
    path: '/app/claims',
    showLeftNav: true,
    leftNav: 'Claims',
    component: Claims
  },
  {
    path: '/app/myAnalytics/card/:cardId?',
    showLeftNav: false,
    leftNav: 'My Analytics',
    component: MyAnalyticsChart
  },
  {
    path: '/app/myAnalytics/dashboard/:dashboardId?',
    showLeftNav: false,
    leftNav: 'My Analytics',
    component: MyAnalyticsDashboard
  },
  {
    path: '/app/myAnalytics/detail/:chartId?',
    showLeftNav: false,
    leftNav: 'My Analytics',
    component: MyAnalyticsDetail
  },
  {
    path: '/app/myAnalytics',
    showLeftNav: true,
    leftNav: 'My Analytics',
    component: MyAnalytics
  },
  {
    path: '/app/CustomDocumentContainer',
    showLeftNav: false,
    leftNav: 'Custom Documents',
    component: CustomDocumentContainer
  },
  {
    path: '/app/CustomDocumentList',
    showLeftNav: true,
    leftNav: 'Custom Documents',
    component: CustomDocumentList
  },
  {
    path: '/app/CustomDocumentTemplateContainer',
    showLeftNav: false,
    leftNav: 'Document Templates',
    component: CustomDocumentTemplateContainer
  },
  {
    path: '/app/CustomDocumentTemplateList',
    showLeftNav: true,
    leftNav: 'Document Templates',
    component: CustomDocumentTemplateList
  },
  {
    path: '/app/customReportQuestionsList',
    showLeftNav: true,
    leftNav: 'Report Questions',
    component: CustomReportQuestionsList
  },
  {
    path: '/app/customReportQuestionContainer',
    showLeftNav: false,
    leftNav: 'Custom Report Questions',
    component: CustomReportQuestionContainer
  },
  {
    path: '/app/training/customTrainingTemplate',
    showLeftNav: false,
    leftNav: 'Custom Safety Trainings',
    component: CustomTrainingTemplates
  },
  {
    path: '/app/dashboard',
    showLeftNav: true,
    leftNav: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/app/documents/sds',
    showLeftNav: true,
    leftNav: 'SDS',
    component: SdsList
  },
  {
    path: '/app/documents/safetyInstructions',
    showLeftNav: true,
    leftNav: 'Safety Procedures',
    component: SafetyInstructionList
  },
  {
    path: '/app/documents/:documentType/:qualificationType?',
    showLeftNav: true,
    leftNav: 'Qualifications',
    component: Documents
  },
  {
    path: '/app/safetyWalkContainer/hazardCard/:hazardIndex?',
    showLeftNav: false,
    leftNav: 'Safety Walks',
    component: HazardCard
  },
  {
    path: '/app/safetyWalkContainer/bravoCard/:bravoIndex?',
    showLeftNav: false,
    leftNav: 'Safety Walks',
    component: BravoCard
  },
  {
    path: '/app/incidentContainer/:stage?/:incidentId?',
    showLeftNav: false,
    leftNav: 'Reports',
    component: IncidentContainer
  },
  {
    path: '/app/incidents',
    showLeftNav: true,
    leftNav: 'Reports',
    component: IncidentStages
  },
  {
    path: '/app/initalReportContainer',
    showLeftNav: false,
    leftNav: 'Reports',
    component: InitalReportContainer
  },
  {
    path: '/app/myDocumentContainer/:userId?',
    showLeftNav: false,
    leftNav: 'My Documents',
    component: MyDocumentContainer
  },
  {
    path: '/app/myDocumentsStages',
    showLeftNav: true,
    leftNav: 'My Documents',
    component: MyDocumentsStages
  },
  {
    path: '/app/oshaLog/:year?',
    showLeftNav: true,
    leftNav: 'OSHA Forms',
    component: OshaLog
  },
  {
    path: '/app/photoEvidenceContainer/:isPhotoEvidence?',
    showLeftNav: false,
    leftNav: 'Reports',
    component: PhotoEvidenceContainer
  },
  {
    path: '/app/training/performTraining/:trainingId?',
    showLeftNav: false,
    leftNav: 'Safety Trainings',
    component: PerformTraining
  },
  {
    path: '/app/permissions/:type?/:typeId?',
    showLeftNav: false,
    component: Permissions
  },
  {
    path: '/app/profile',
    showLeftNav: true,
    leftNav: 'Dashboard',
    component: Profile
  },
  {
    path: '/app/quiz/quizList',
    showLeftNav: true,
    leftNav: 'Training Quizzes',
    component: QuizList
  },
  {
    path: '/app/quiz/quizTemplate/:quizTemplateId?/:duplicate?',
    showLeftNav: false,
    leftNav: 'Custom Quizzes',
    component: QuizTemplate
  },
  {
    path: '/app/recurrenceContainer/:recurrenceId?',
    showLeftNav: false,
    leftNav: 'My Documents',
    component: RecurrenceContainer
  },
  {
    path: '/app/reportSectionContainer/:stage?/:taskId?',
    showLeftNav: false,
    leftNav: 'Reports',
    component: ReportSectionContainer
  },
  {
    path: '/app/reportStages/:templateId',
    showLeftNav: true,
    leftNav: ({ match }) => TYPE_NAMES[match.params.templateId],
    component: ReportStages
  },
  {
    path: '/app/requireAuthList',
    showLeftNav: true,
    leftNav: 'Require Acknowledgment',
    component: RequireAuthorizationList
  },
  {
    path: '/app/safetyAudits',
    showLeftNav: true,
    leftNav: 'Safety Audits',
    component: SafetyAudits
  },
  {
    path: '/app/safetyAudits/create/:safetyAuditId?/:type?',
    showLeftNav: false,
    leftNav: 'Safety Audits',
    component: SafetyAuditCreate
  },
  {
    path: '/app/safetyAudits/perform/:safetyAuditId?',
    showLeftNav: false,
    leftNav: 'Safety Audits',
    component: SafetyAuditPerform
  },
  {
    path: '/app/safetyAudits/summary/:safetyAuditId?',
    showLeftNav: false,
    leftNav: 'Safety Audits',
    component: SafetyAuditSummary
  },
  {
    path: '/app/safetyAuditTemplates',
    showLeftNav: true,
    leftNav: 'Safety Audit Templates',
    component: SafetyAuditTemplates
  },
  {
    path:
      '/app/safetyAuditTemplates/template/:safetyAuditTemplateId?/:duplicate?',
    showLeftNav: false,
    leftNav: 'Custom Safety Audits',
    component: SafetyAuditTemplate
  },
  {
    path: '/app/safetyWalkContainer/:safetyWalkId?',
    showLeftNav: false,
    leftNav: 'Safety Walks',
    component: SafetyWalkContainer
  },
  {
    path: '/app/safetyWalkStages/',
    showLeftNav: true,
    leftNav: 'Safety Walks',
    component: SafetyWalkStages
  },
  {
    path: '/app/searchPage',
    showLeftNav: true,
    leftNav: 'Dashboard',
    component: SearchPage
  },
  {
    path: '/app/settings/:settingType/:openEditUser?',
    showLeftNav: true,
    leftNav: ({ match }) => {
      switch (match.params.settingType) {
        case 'CompanyDetails':
          return 'Company Division';
        case 'Locations':
          return 'Groups / Establishments';
        default:
          return match.params.settingType;
      }
    },
    component: Settings
  },
  {
    path: '/app/taskContainer/:taskId?',
    showLeftNav: false,
    leftNav: 'Tasks',
    component: TaskContainer
  },
  {
    path: '/app/tasks',
    showLeftNav: true,
    leftNav: 'Tasks',
    component: TaskStages
  },
  {
    path: '/app/training/templateList',
    showLeftNav: true,
    leftNav: 'Training Templates',
    component: TemplateList
  },
  {
    path: '/app/training/trainingAttachmentViewer',
    showLeftNav: false,
    leftNav: 'Safety Trainings',
    component: TrainingAttachmentViewer
  },
  {
    path: '/app/training/trainingContainer',
    showLeftNav: false,
    leftNav: 'Safety Trainings',
    component: TrainingContainer
  },
  {
    path: '/app/training/trainingList',
    showLeftNav: true,
    leftNav: 'Safety Trainings',
    component: TrainingList
  },
  {
    path: '/app/training/trainingQuizViewer',
    showLeftNav: false,
    leftNav: 'Safety Trainings',
    component: TrainingQuizViewer
  },
  {
    path: '/app/training/trainingSummary/:trainingId?',
    showLeftNav: false,
    leftNav: 'Safety Trainings',
    component: TrainingSummary
  },
  {
    path: '/app/transitionContainer/:transitionId?',
    showLeftNav: false,
    leftNav: 'My Documents',
    component: TransitionContainer
  },
  {
    path: '/app/ViewEdit/:editType/:docId?',
    showLeftNav: true,
    leftNav: ({ match }) => {
      switch (match.params.editType) {
        case 'Safety Procedure':
          return 'Safety Procedures';
        default:
          return match.params.editType;
      }
    },
    component: ViewEdit
  },
  {
    path: '/app/viewEditCompany',
    showLeftNav: true,
    leftNav: 'Company Division',
    component: ViewEditCompany
  },
  {
    path: '/app/viewEditPersonnel',
    showLeftNav: false,
    leftNav: 'Employees',
    component: ViewEditEmployee
  },
  {
    path: '/app/covidTracking',
    showLeftNav: true,
    leftNav: 'Covid-19 Tracking',
    component: CovidEmployeeList
  },
  {
    path: '/app/covidTracking/employeeStatus/:id?',
    showLeftNav: false,
    leftNav: 'EmployeeCovidStatus',
    component: EmployeeCovidStatus
  },
  {
    path: '/app/covidTracking/exposure/:id?',
    showLeftNav: false,
    leftNav: 'EmployeeExposure',
    component: EmployeeExposure
  },
  {
    path: '/app/witnessStatements/:statementId?',
    showLeftNav: false,
    leftNav: 'WitnessStatement',
    component: WitnessStatementContainer
  },
  {
    path: '/app/equipmentList',
    showLeftNav: true,
    leftNav: 'Equipment',
    component: EquipmentList
  },
  {
    path: '/app/equipmentContainer/:equipmentId?',
    showLeftNav: false,
    leftNav: 'Equipment',
    component: EquipmentContainer
  },
  {
    path: '/app/trainingException',
    showLeftNav: true,
    leftNav: 'Training Exception',
    component: TrainingReport
  },
  {
    path: '/app/leaderboards',
    showLeftNav: true,
    leftNav: 'Leaderboards',
    component: Leaderboards
  },
  {
    path: '/app/leaderboards/create/:dashboardId?',
    showLeftNav: false,
    leftNav: 'Leaderboard',
    component: CreateMyLeaderboard
  },
  {
    path: '/app/leaderboards/edit/:id?',
    showLeftNav: false,
    leftNav: 'Leaderboard',
    component: Leaderboard
  },
  {
    path: '/app/leaderboards/dashboard/edit/:id?',
    showLeftNav: false,
    leftNav: 'Leaderboard',
    component: LeaderboardDashboard
  },
  {
    path: '/app/leaderboards/view/:id',
    showLeftNav: false,
    leftNav: 'Leaderboard',
    component: ViewLeaderboard
  },
  {
    path: '/app/safetyAuditException',
    showLeftNav: true,
    leftNav: 'Safety Audit Exception',
    component: SafetyAuditReport
  },
  {
    path: '/app/fireexposure/:id?',
    showLeftNav: false,
    leftNav: 'My Documents',
    component: FireExposureForm
  }
];

export default routes;
