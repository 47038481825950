import axios from 'axios';

import config from '../config/config';
import history from '../history';
import store from '../store';
import { addMessage } from '../actions/messages';
import { START_LOADING, STOP_LOADING } from '../constants/actionTypes';
import { setTokenHeader, getQueryString } from './';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../selectors/company';
import { getLoggedInUser } from '../selectors/users';

axios.default.withCredentials = true;

axios.defaults.headers = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
  'Content-Type': 'application/json'
};

const v4Api = axios.create({ baseURL: `${config.apiUrl}/v4` });
const v4ApiNoLoading = axios.create({ baseURL: `${config.apiUrl}/v4` });

const requestAuth = config => {
  if (!config.headers.Authorization) {
    const token = setTokenHeader();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  return config;
};

const requestLoading = config => {
  store.dispatch({ type: START_LOADING });

  return config;
};

const requestAddHierarchyId = config => {
  if (!config.hierarchy) return config;

  const state = store.getState();
  const companyId = getActiveCompany(state)?._id;
  const locationId = getActiveLocationId(state)?._id;
  const projectId = getActiveProject(state)?._id;
  const hierarchyId =
    config.hierarchy === 'company'
      ? companyId
      : config.hierarchy === 'employeeHierarchy'
      ? locationId ?? companyId
      : projectId ?? locationId ?? companyId;

  config.url = `/${hierarchyId}${config.url}`;
  return config;
};

const responseStop = response => {
  store.dispatch({ type: STOP_LOADING });

  return response;
};

const errorStop = error => {
  store.dispatch({ type: STOP_LOADING });
  throw error;
};

const errorHandler = error => {
  if (!error.response) throw error;

  if (error.response.status === 500 || error.response.status === 418) {
    if (process.env.NODE_ENV === 'development') throw error.response;
    else history.push('/error');
  }

  if (error.response.status === 403) {
    history.push('/login');
  }
  throw error.response;
};

v4ApiNoLoading.interceptors.request.use(requestAuth);
v4ApiNoLoading.interceptors.request.use(requestAddHierarchyId);
v4ApiNoLoading.interceptors.response.use(null, errorHandler);

v4Api.interceptors.request.use(requestAuth);
v4Api.interceptors.request.use(requestAddHierarchyId);
v4Api.interceptors.request.use(requestLoading);
v4Api.interceptors.response.use(responseStop, errorStop);
v4Api.interceptors.response.use(null, errorHandler);

const v4APICall = (
  url,
  {
    method = 'get',
    data = undefined,
    loading = true,
    hierarchy = true, // true, false, 'employeeHierarchy' or 'company'
    options = undefined,
    toast = false,
    error = false
  } = {}
) => {
  if (options) url += '?' + getQueryString(options);

  return (loading ? v4Api : v4ApiNoLoading)
    .request({
      url,
      method,
      hierarchy,
      data
    })
    .then(response => {
      if (toast) {
        store.dispatch(addMessage({ error: false, message: response.data }));
      }
      return response.data;
    })
    .catch(err => {
      if (error) {
        store.dispatch(addMessage({ error: true, message: err.data }));
      }
      throw err;
    });
};

export const listEmployees = () =>
  v4APICall('/employee/list', {
    loading: false
  });

export const addEmployee = employee =>
  v4APICall('/employee', { method: 'post', data: employee });

export const updateEmployee = employee =>
  v4APICall(`/employee/${employee._id}`, { method: 'put', data: employee });

export const importEmployees = sourceUrl =>
  v4APICall('/employee/upload', { method: 'post', data: { sourceUrl } });

export const fetchEmployee = id => v4APICall(`/employee/${id}`);

export const deleteEmployee = id =>
  v4APICall(`/employee/${id}`, { method: 'delete' });

export const resendWelcomeNotification = id =>
  v4APICall(`/employee/${id}/resendWelcomeNotification`, {
    toast: true,
    method: 'post'
  });

export const resetPassword = id =>
  v4APICall(`/employee/${id}/resetPassword`, { toast: true, method: 'post' });

export const fetchEmployeeQualifications = id =>
  v4APICall(`/employee/${id}/qualifications`);

export const fetchEmployeeIncidents = id =>
  v4APICall(`/employee/${id}/incidents`);

export const fetchEmployeeTrainings = id =>
  v4APICall(`/employee/${id}/trainings`);

export const fetchEmployeeInteractions = id =>
  v4APICall(`/employee/${id}/interactions`);

export const fetchEmployeeBravos = id => v4APICall(`/employee/${id}/bravos`);

export const fetchCompanyUsers = () =>
  v4APICall('/employee/info', {
    loading: false,
    hierarchy: 'company',
    method: 'post'
  });

export const fetchAllUsers = () =>
  v4APICall('/employee/info', {
    loading: false,
    hierarchy: 'employeeHierarchy',
    method: 'post'
  });

export const fetchAllUsersForLocation = (locationId, data) =>
  v4APICall(`${locationId}/employee/info`, {
    hierarchy: false,
    loading: false,
    method: 'post',
    data
  });
export const fetchUser = id =>
  v4APICall(`/employee/${id}/info`, { loading: false, hierarchy: false });

export const loadDashboard = () => {
  const state = store.getState();
  const id = getLoggedInUser(state)._id;
  return v4APICall(`/employee/${id}/dashboard`);
};
export const getCards = () => v4APICall(`/employee/dashboard/cards`);
export const getCard = payload =>
  v4APICall(`/employee/dashboard/cards/card`, {
    method: 'post',
    data: { type: payload.type, timeFrame: payload.timeFrame }
  });

export const setIncidentOwner = (locationId, employeeId) =>
  v4APICall(`${locationId}/setOwner/${employeeId}`, {
    hierarchy: false,
    loading: false,
    method: 'put'
  });

export const fetchQrCodeSheet = ids =>
  v4APICall(`/employee/createQRCodeSheet/`, { data: { ids }, method: 'post' });

export const exportEmployeesToCSV = options =>
  v4APICall(`/employee/exportEmployeesToCSV`);

export const fetchSafetyAuditTemplates = () =>
  v4APICall('/safetyAudit/templates', { hierarchy: 'company' });
export const fetchSafetyAuditTemplateById = id =>
  v4APICall(`/safetyAudit/templates/${id}`);
export const createSafetyAuditTemplate = template =>
  v4APICall('/safetyAudit/templates', {
    method: 'post',
    data: template,
    error: true
  });
export const updateSafetyAuditTemplate = template =>
  v4APICall(`/safetyAudit/templates/${template._id}`, {
    method: 'put',
    data: template
  });
export const deleteSafetyAuditTemplate = template =>
  v4APICall(`/safetyAudit/templates/${template._id}`, {
    method: 'delete'
  });

export const fetchSafetyAuditRepeatables = () =>
  v4APICall('/repeatable/type/safetyAudits');
export const fetchSafetyAuditRepeatableById = id =>
  v4APICall(`/repeatable/${id}`);
export const createSafetyAuditRepeatable = repeatable =>
  v4APICall(`/repeatable`, {
    method: 'post',
    data: { ...repeatable, type: 'safetyAudits' }
  });
export const updateSafetyAuditRepeatable = repeatable =>
  v4APICall(`/repeatable/${repeatable._id}`, {
    method: 'put',
    data: repeatable
  });
export const deleteSafetyAuditRepeatable = repeatable =>
  v4APICall(`/repeatable/${repeatable._id}`, {
    method: 'delete'
  });

export const fetchSafetyAuditScanOnDemands = () =>
  v4APICall('/scanOnDemand/type/safetyAudits');
export const fetchSafetyAuditScanOnDemandById = id =>
  v4APICall(`/scanOnDemand/${id}`);
export const createSafetyAuditScanOnDemand = repeatable =>
  v4APICall(`/scanOnDemand`, {
    method: 'post',
    data: { ...repeatable, type: 'safetyAudits' }
  });
export const updateSafetyAuditScanOnDemand = repeatable =>
  v4APICall(`/scanOnDemand/${repeatable._id}`, {
    method: 'put',
    data: repeatable
  });
export const deleteSafetyAuditScanOnDemand = repeatable =>
  v4APICall(`/scanOnDemand/${repeatable._id}`, {
    method: 'delete'
  });

export const fetchSafetyAudits = () => v4APICall('/safetyAudit/list');
export const fetchSafetyAuditById = id => v4APICall(`/safetyAudit/${id}`);
export const createSafetyAudit = audit =>
  v4APICall('/safetyAudit', { method: 'post', data: audit });
export const updateSafetyAudit = audit =>
  v4APICall(`/safetyAudit/${audit._id}`, {
    method: 'put',
    data: audit
  });
export const updateSafetyAuditStage = audit =>
  v4APICall(`/safetyAudit/${audit._id}/updateStage`, {
    method: 'put',
    data: { currentWfStage: audit.currentWfStage }
  });
export const updateSafetyAuditAdditionalNotes = audit =>
  v4APICall(`/safetyAudit/${audit._id}/updateAdditionalNotes`, {
    method: 'put',
    data: { additionalNotes: audit.additionalNotes }
  });
export const deleteSafetyAudit = audit =>
  v4APICall(`/safetyAudit/${audit._id}`, {
    method: 'delete'
  });
export const filterSafetyAuditSections = (auditId, filter) =>
  v4APICall(`/safetyAudit/${auditId}/filter`, { method: 'post', data: filter });
export const setSafetyAuditNumber = (safetyAuditId, safetyAuditNumber) =>
  v4APICall(
    `/safetyAudit/${safetyAuditId}/safetyAuditNumber/${safetyAuditNumber}`,
    { method: 'put', error: true }
  );

export const getAnalyticsDashboard = (dashboardId = '') =>
  v4APICall(`/analytics/dashboards/${dashboardId}`, { hierarchy: false });
export const createAnalyticsDashboard = data =>
  v4APICall(`/analytics/dashboards`, {
    method: 'post',
    data,
    hierarchy: false
  });
export const deleteAnalyticsDashboard = dashboardId =>
  v4APICall(`/analytics/dashboards/${dashboardId}`, {
    method: 'delete',
    hierarchy: false
  });
export const updateAnalyticsDashboard = (dashboardId = '', data) =>
  v4APICall(`/analytics/dashboards/${dashboardId}`, {
    method: 'put',
    data,
    hierarchy: false
  });
export const cloneAnalyticsDashboard = data =>
  v4APICall(`/analytics/dashboards/${data._id}/clone`, {
    method: 'post',
    hierarchy: false,
    data
  });
export const setDashboardFavorite = (id, fav = true) =>
  v4APICall(`/analytics/dashboards/${id}/favorite/${fav}`, {
    method: 'put',
    hierarchy: false
  });

export const createAnalyticsChart = (dashboardId, data) =>
  v4APICall(`/analytics/charts/${dashboardId}`, {
    method: 'post',
    hierarchy: false,
    data
  });
export const deleteAnalyticsChart = chartId =>
  v4APICall(`/analytics/charts/${chartId}`, {
    method: 'delete',
    hierarchy: false
  });
export const updateAnalyticsChart = (chartId, data) =>
  v4APICall(`/analytics/charts/${chartId}`, {
    method: 'put',
    data,
    hierarchy: false
  });
export const fetchAnalyticsChart = chartId =>
  v4APICall(`/analytics/charts/${chartId}`, { hierarchy: false });
export const fetchAnalyticsChartSettings = chartId =>
  v4APICall(`/analytics/charts/${chartId}/settings`, { hierarchy: false });
export const createAnalyticsChartSettings = (chartId, filteredColumns) =>
  v4APICall(`/analytics/charts/${chartId}/settings`, {
    hierarchy: false,
    method: 'post',
    data: filteredColumns
  });
export const updateAnalyticsChartSettings = (chartId, filteredColumns) =>
  v4APICall(`/analytics/charts/${chartId}/settings`, {
    hierarchy: false,
    method: 'put',
    data: filteredColumns
  });

export const fetchAnalyticsDatasources = () =>
  v4APICall('/analytics/datasources', { hierarchy: false });
export const fetchAnalyticsDatapoints = datasource =>
  v4APICall(`/analytics/${datasource}/datapoints`, { hierarchy: true });
export const fetchAnalyticsDatapointValues = (datasource, datapoint) => {
  return v4APICall(`/analytics/${datasource}/datapoints/datapoint`, {
    data: { datapoint },
    method: 'post'
  });
};
export const fetchAnalyticsPreview = data =>
  v4APICall(`/analytics/preview`, { method: 'post', data, loading: false });
export const fetchAnalyticsDetail = data =>
  v4APICall(`/analytics/detailed`, {
    method: 'post',
    data,
    hierarchy: 'company'
  });
export const exportAnalytics = data =>
  v4APICall('/analytics/export', { method: 'post', data });

// incidents
export const listReports = reportTemplateId =>
  v4APICall(`/report/list/${reportTemplateId}`, {
    loading: false
  });

export const listAllReports = () =>
  v4APICall(`/incidents`, {
    loading: false
  });

export const reportBreakdown = () =>
  v4APICall(`/incidents/breakdown`, {
    loading: false
  });

export const updateActivityAttachments = attachment =>
  v4APICall(`/incidents/updateIncidentActivity`, {
    method: 'post',
    data: attachment
  });

export const emailInitialReport = payload => {
  return v4APICall(`/incidents/${payload.incidentId}/emailInitialReport`, {
    method: 'put',
    data: payload
  });
};

export const fetchIncidentById = id =>
  v4APICall(`/incidents/${id}`, {
    hierarchy: 'company'
  });

export const createIncident = payload =>
  v4APICall(`/incidents`, {
    hierarchy: 'company',
    data: payload,
    method: 'post'
  });

export const populateOshaForms = incidentId =>
  v4APICall(`/oshaForms/populate/?incidentId=${incidentId}`, {
    hierarchy: 'company'
  });

export const fetchInitialReportList = () =>
  v4APICall(`/initialReport/userReportList`);

export const fetchInitialReportById = id => v4APICall(`/initialReport/${id}`);

export const createInitialReport = payload =>
  v4APICall(`/initialReport`, {
    data: payload,
    method: 'post',
    hierarchy: 'company'
  });

export const updateInitialReport = payload =>
  v4APICall(`/initialReport/${payload._id}`, {
    data: payload,
    method: 'put'
  });

export const deleteInitialReport = payload =>
  v4APICall(`/initialReport/${payload}`, {
    method: 'delete'
  });

export const deleteManyInitialReports = ids =>
  v4APICall(`/initialReport/deleteMany`, {
    method: 'put',
    data: { ids },
    toast: true
  });

export const updateReportComponent = payload =>
  v4APICall(`/incidents/${payload.incidentId}/updateReportComponent`, {
    data: payload.activeReportComponent,
    method: 'post'
  });

export const updateIncidentPermissions = (incidentId, permissions) =>
  v4APICall(`/incidents/${incidentId}/permissions`, {
    data: permissions,
    method: 'put'
  });

export const fetchIncidentBasics = () => v4APICall(`/fieldGroups`);

export const fetchReportBasics = reportLabel =>
  v4APICall(`/fieldGroups/${reportLabel}`);

export const updateIncidentBasics = (incidentId, answers, signature) =>
  v4APICall(`/incidents/${incidentId}/basics`, {
    data: { answers, signature },
    method: 'put'
  });

export const updateIncidentNumber = payload =>
  v4APICall(`/incidents/${payload.incidentId}/incidentNumber`, {
    data: payload,
    method: 'put'
  });
export const updateIncidentOwner = data =>
  v4APICall(`/incidents/${data.incidentId}/incidentOwner`, {
    data,
    method: 'put'
  });

export const updateIncidentStage = (incidentId, reportStage) =>
  v4APICall(`/incidents/${incidentId}/incidentStage`, {
    data: reportStage,
    method: 'put'
  });

export const deleteIncident = incidentId =>
  v4APICall(`/incidents/${incidentId}`, {
    method: 'delete'
  });

export const printReport = (incidentId, options) =>
  v4APICall(`/incidents/${incidentId}/printReport/?${getQueryString(options)}`);

//trainings
export const fetchAllTrainings = documentFolderId =>
  v4APICall(`/trainings/list/${documentFolderId}`, { loading: false });

export const fetchCustomTrainingTemplates = () =>
  v4APICall('/trainingTemplates/list', {
    hierarchy: true
  });

export const fetchCustomTrainingTemplateTitles = () =>
  v4APICall('/trainingTemplates/templateTitles', {
    hierarchy: true
  });

export const addCustomTrainingTemplate = template =>
  v4APICall('/trainingTemplates', {
    method: 'post',
    data: template
  });

export const updateCustomTrainingTemplate = template =>
  v4APICall(`/trainingTemplates/${template._id}`, {
    method: 'put',
    data: template
  });

export const deleteCustomTrainingTemplate = template =>
  v4APICall(`/trainingTemplates/${template._id}`, {
    method: 'delete'
  });

export const addTraining = training =>
  v4APICall('/trainings', { method: 'post', data: training });

export const fetchTrainingById = id => v4APICall(`/trainings/${id}`);

export const fetchTrainingsByMasterId = id =>
  v4APICall(`/trainings/master/${id}`);

export const updateTraining = training =>
  v4APICall(`/trainings/${training._id}`, {
    method: 'put',
    data: training
  });

export const fetchPerformTraining = id =>
  v4APICall(`/trainings/${id}?perform=true`);

export const deleteTraining = training =>
  v4APICall(`/trainings/${training._id}`, {
    method: 'delete'
  });

export const createTrainingRepeatable = repeatable =>
  v4APICall(`/repeatable`, {
    method: 'post',
    data: { ...repeatable, type: 'trainings' }
  });

export const updateTrainingRepeatable = repeatable =>
  v4APICall(`/repeatable/${repeatable._id}`, {
    method: 'put',
    data: repeatable
  });

export const fetchTrainingRepeatables = documentFolderId =>
  v4APICall(`/repeatable/type/trainings/${documentFolderId}`);

export const fetchTrainingRepeatableById = id =>
  v4APICall(`/repeatable/${id}`, { data: { type: 'trainings' } });

export const deleteTrainingRepeatable = repeatable =>
  v4APICall(`/repeatable/${repeatable._id}`, {
    method: 'delete'
  });

export const fetchTrainingScanOnDemands = documentFolderId =>
  v4APICall(`/scanOnDemand/type/trainings/${documentFolderId}`);

export const fetchTrainingScanOnDemandById = id =>
  v4APICall(`/scanOnDemand/${id}`);

export const createTrainingScanOnDemand = repeatable =>
  v4APICall(`/scanOnDemand`, {
    method: 'post',
    data: { ...repeatable, type: 'trainings' }
  });

export const updateTrainingScanOnDemand = repeatable =>
  v4APICall(`/scanOnDemand/${repeatable._id}`, {
    method: 'put',
    data: repeatable
  });

export const deleteTrainingScanOnDemand = repeatable =>
  v4APICall(`/scanOnDemand/${repeatable._id}`, {
    method: 'delete'
  });

export const getUserApprovedTrainings = userId =>
  v4APICall(`/trainings/approved/${userId}`, { loading: false });

export const getUserNonApprovedTrainings = userId =>
  v4APICall(`/trainings/nonApproved/${userId}`, { loading: false });

//osha
export const fetchOshaLogs = year => v4APICall(`/oshaLogs/fetch/${year}`);

export const createOshaLog = data =>
  v4APICall('/oshaLogs/', {
    method: 'post',
    data
  });

export const updateOshaLog = data =>
  v4APICall('/oshaLogs/update', {
    method: 'post',
    data
  });

export const printExcel300 = year =>
  v4APICall(`/oshaLogs/printExcel300/${year}`);

export const printExcel300A = payload =>
  v4APICall(`/oshaLogs/printExcel300A/${payload.year}`, {
    method: 'post',
    data: payload
  });

export const printExcel301 = year =>
  v4APICall(`/oshaLogs/printExcel301/${year}`);

export const printCSV = year => v4APICall(`/oshaLogs/printCSV/${year}`);

export const singlePrintExcel301 = incidentId =>
  v4APICall(`/oshaLogs/singlePrintExcel301/${incidentId}`);

export const getAttachmentById = id => v4APICall(`/attachment/${id}`);

export const importReports = (sourceUrl, reportType) =>
  v4APICall('/incidents/upload', {
    method: 'post',
    data: { sourceUrl, reportType },
    hierarchy: 'company'
  });

//tasks
export const addTask = task =>
  v4APICall('/tasks', { method: 'post', data: task });

export const createReportComponentTask = task =>
  v4APICall('/tasks/reportComponent', { method: 'post', data: task });

export const createTaskRepeatable = repeatable =>
  v4APICall(`/repeatable`, {
    method: 'post',
    data: { ...repeatable, type: 'tasks' }
  });

export const updateTaskRepeatable = repeatable =>
  v4APICall(`/repeatable/${repeatable._id}`, {
    method: 'put',
    data: repeatable
  });

export const deleteTaskRepeatable = repeatable =>
  v4APICall(`/repeatable/${repeatable._id}`, {
    method: 'delete'
  });

export const fetchTaskById = id => v4APICall(`/tasks/${id}`);

export const fetchTasks = () => v4APICall(`/tasks/`);

export const fetchRepeatingTasks = () => v4APICall('/repeatable/type/tasks');

export const fetchTaskRepeatableById = id =>
  v4APICall(`/repeatable/${id}`, { data: { type: 'tasks' } });

export const fetchCreatedByTasks = userId =>
  v4APICall(`/tasks/createdBy/${userId}`);

export const updateTask = task =>
  v4APICall(`/tasks/${task._id}`, {
    method: 'put',
    data: task
  });

export const deleteTask = task =>
  v4APICall(`/tasks/${task._id}`, {
    method: 'delete'
  });

//reminder
export const sendReminder = (assignmentId, assignmentType) =>
  v4APICall(`/tasks/reminder/${assignmentType}/${assignmentId}`, {
    method: 'post',
    hierarchy: 'company'
  });

// comments
export const fetchCommentById = id => v4APICall(`/comment/${id}`);

export const fetchTaskComments = taskId => v4APICall(`/comment/${taskId}`);

export const updateComment = comment =>
  v4APICall(`/comment/${comment._id}`, {
    method: 'put',
    data: comment
  });

export const deleteComment = comment =>
  v4APICall(`/comment/${comment._id}`, {
    method: 'delete'
  });

export const addComment = comment =>
  v4APICall('/comment', { method: 'post', data: comment });

//covid
export const fetchCovidEmployees = () =>
  v4APICall('/covidTracking/list', { loading: false });

export const fetchEmployeeCovidInfo = id =>
  v4APICall(`/covidTracking/employeeStatus/${id}`);

export const generateExceptionList = data =>
  v4APICall('/covidTracking/generateExceptionList', {
    method: 'post',
    data
  });

export const fetchCovidExposureList = () =>
  v4APICall('/covidTracking/exposure');

export const fetchCovidExposureById = id =>
  v4APICall(`/covidTracking/exposure/${id}`);

export const addTestResult = (employeeId, testResults) =>
  v4APICall(`/covidTracking/addTestResult/${employeeId}`, {
    method: 'post',
    data: testResults
  });

export const updateEmployeeVaccinationInfo = (id, data) =>
  v4APICall(`/covidTracking/updateVaccinationInfo/${id}`, {
    method: 'post',
    data
  });

export const addExposure = data =>
  v4APICall('/covidTracking/addExposure', {
    method: 'post',
    data
  });

export const updateExposure = (id, data) =>
  v4APICall(`/covidTracking/updateExposure/${id}`, {
    method: 'post',
    data
  });

export const sendCovidExposureNotification = data =>
  v4APICall(`/covidTracking/sendExposureNotification`, {
    method: 'post',
    data
  });

export const downloadVaccinatedEmployees = () =>
  v4APICall(`/covidTracking/downloadVaccinatedEmployees`, {
    method: 'post'
  });

export const downloadNonVaccinatedEmployees = () =>
  v4APICall(`/covidTracking/downloadNonVaccinatedEmployees`, {
    method: 'post'
  });

export const downloadCovidExposures = data =>
  v4APICall(`/covidTracking/downloadCovidExposure`, {
    method: 'post',
    data
  });

// notifications
export const getUserNotifications = id =>
  v4APICall(`/notifications/user/${id}`, {
    hierarchy: false
  });

// documents
export const fetchEmployeeDocuments = empId =>
  v4APICall(`/documents/empDocuments/${empId}`, {
    hierarchy: 'company'
  });

export const fetchDocumentsByType = (documentType, documentFolderId) =>
  v4APICall(`/documents/list/${documentType}/${documentFolderId}`, {
    hierarchy: true
  });

export const fetchDocumentFoldersByType = documentType =>
  v4APICall(`/documentFolders/${documentType}`, {
    hierarchy: 'company'
  });

export const fetchQualificationType = () =>
  v4APICall(`/documents/getQualificationCategories`, {
    hierarchy: 'company'
  });

export const createDocument = data =>
  v4APICall(`/documents`, {
    method: 'post',
    data
  });

export const updateDocument = payload =>
  v4APICall(`/documents/${payload._id}`, {
    method: 'put',
    data: payload
  });

export const deleteDocument = payload =>
  v4APICall(`/documents/${payload._id}`, {
    method: 'delete',
    data: payload
  });

export const printCustomDoc = payload =>
  v4APICall(`/documents/printCustomDoc/${payload._id}`, {
    data: payload
  });

export const fetchDocumentById = id => v4APICall(`/documents/${id}`);
export const createDocumentQRCodeSheet = ids =>
  v4APICall('/documents/createQRCodeSheet', {
    hierarchy: 'company',
    method: 'post',
    data: { ids }
  });

export const exportCustomDocs = label =>
  v4APICall(`/documents/export/${label}`, {
    method: 'post'
  });

export const getDistinctCustomDocTitles = () =>
  v4APICall('/documents/customDocTitles', {
    hierarchy: 'company'
  });

// notes
export const addNote = data =>
  v4APICall(`/notes`, {
    method: 'post',
    data
  });

export const updateNote = payload =>
  v4APICall(`/notes/${payload._id}`, {
    method: 'put',
    data: payload
  });

export const updateNotes = payload =>
  v4APICall(`/notes/update`, {
    method: 'put',
    data: payload
  });

export const deleteNoteById = payload =>
  v4APICall(`/notes/${payload.note._id}`, {
    method: 'delete',
    data: payload
  });

// equipment
export const fetchEquipmentListAll = () => v4APICall('/equipment/list');

export const fetchEquipmentById = equipmentId =>
  v4APICall(`/equipment/fetch/${equipmentId}`);

export const createEquipment = payload =>
  v4APICall('/equipment/create', {
    method: 'post',
    data: payload
  });

export const updateEquipmentById = payload =>
  v4APICall(`/equipment/update/${payload._id}`, {
    method: 'put',
    data: payload
  });

export const deleteEquipment = equipment =>
  v4APICall(`/equipment/${equipment._id}`, {
    method: 'delete',
    data: equipment
  });

export const equipmentUpdateStatus = payload =>
  v4APICall(`/equipment/updateStatus/${payload._id}`, {
    method: 'put',
    data: payload
  });

export const uploadEquipment = files =>
  v4APICall('/equipment/upload', { method: 'post', data: { files } });

export const downloadEquipmentQrCodes = payload =>
  v4APICall(`/equipment/downloadQrCodes`, {
    method: 'post',
    data: payload
  });
// safety walks
export const fetchSafetyWalks = () =>
  v4APICall('/safetyWalks/listAll', {
    hierarchy: true
  });

export const fetchSafetyWalkById = id => v4APICall(`/safetyWalks/${id}`);

export const createSafetyWalk = data =>
  v4APICall(`/safetyWalks`, {
    method: 'post',
    data
  });

export const updateSafetyWalk = data =>
  v4APICall(`/safetyWalks/${data._id}`, {
    method: 'put',
    data
  });

export const setSafetyWalkNumber = (safetyWalkId, safetyWalkNumber) =>
  v4APICall(`/safetyWalks/updateSafetyWalkNumber/${safetyWalkId}`, {
    method: 'put',
    data: { safetyWalkNumber }
  });

export const deleteSafetyWalk = id =>
  v4APICall(`/safetyWalks/${id}`, {
    method: 'delete'
  });

export const printSafetyWalk = id => v4APICall(`/safetyWalks/print/${id}`);

// continuous care
export const fetchContinuousCareByEmployeeId = employeeId =>
  v4APICall(`/continuousCares/list/${employeeId}`, {
    hierarchy: 'company'
  });

export const fetchContinuousCareByHrRepId = employeeId =>
  v4APICall(`/continuousCares/list/${employeeId}/&isApprovedByHr=${false}`, {
    hierarchy: 'company'
  });

export const fetchContinuousCareBySupervisorId = employeeId =>
  v4APICall(
    `/continuousCares/list/${employeeId}/&isApprovedBySupervisor=${false}`,
    {
      hierarchy: 'company'
    }
  );

export const fetchContinuousCareById = id =>
  v4APICall(`/continuousCares/${id}`, {
    hierarchy: 'company'
  });

export const createContinuousCare = data =>
  v4APICall(`/continuousCares`, {
    method: 'post',
    data
  });

export const updateContinuousCare = data =>
  v4APICall(`/continuousCares/${data._id}`, {
    method: 'put',
    data
  });

export const printContinuousCare = id =>
  v4APICall(`/continuousCares/print/${id}`, {
    hierarchy: 'company'
  });

export const deleteContinuousCare = id =>
  v4APICall(`/continuousCares/${id}`, {
    method: 'delete',
    hierarchy: 'company'
  });

// custom report questions
export const fetchCustomReportQuestions = (
  reportTemplateNumber,
  reportComponentName
) =>
  v4APICall(
    `/customReportQuestions/${reportTemplateNumber}/${reportComponentName}`,
    {
      hierarchy: 'company'
    }
  );

export const createCustomReportQuestions = data =>
  v4APICall(`/customReportQuestions`, {
    method: 'post',
    data
  });

export const updateCustomReportQuestions = data =>
  v4APICall(`/customReportQuestions/${data._id}`, {
    method: 'put',
    data
  });

export const deleteCustomReportQuestions = data =>
  v4APICall(`/customReportQuestions/${data._id}`, {
    method: 'delete',
    data
  });

// custom doc templates
export const fetchCustomDocumentTemplates = () =>
  v4APICall(`/customDocuments`, {
    hierarchy: 'company'
  });

export const createCustomDocumentTemplates = data =>
  v4APICall(`/customDocuments`, {
    method: 'post',
    data
  });

export const updateCustomDocumentTemplates = data =>
  v4APICall(`/customDocuments/${data._id}`, {
    method: 'put',
    data
  });

export const deleteCustomDocumentTemplates = id =>
  v4APICall(`/customDocuments/${id}`, {
    method: 'delete'
  });

// claims
export const fetchClaims = () => v4APICall('/claim');
export const fetchClaimById = id => v4APICall(`/claim/${id}`);
export const createClaim = claim =>
  v4APICall('/claim/', {
    method: 'post',
    data: claim,
    hierarchy: 'company',
    error: true
  });
export const updateClaim = claim =>
  v4APICall(`/claim/${claim._id}`, {
    method: 'put',
    data: claim,
    hierarchy: 'company',
    error: true
  });
export const deleteClaim = claimId =>
  v4APICall(`/claim/${claimId}`, {
    method: 'delete',
    error: true
  });
export const fetchClaimPermissions = id =>
  v4APICall(`/claim/${id}/permissions`);
export const updateClaimPermisisons = (claimId, permissions) =>
  v4APICall(`/claim/${claimId}/permissions`, {
    method: 'put',
    data: permissions
  });
export const printClaim = claimId => v4APICall(`/claim/${claimId}/print`);

// witness statements
export const createWitnessStatement = ws =>
  v4APICall('/witnessStatements', {
    method: 'post',
    data: ws
  });

export const fetchWitnessStatement = (id, needIncidentInfo) =>
  v4APICall(`/witnessStatements/${id}/${needIncidentInfo}`);

export const updateWitnessStatement = statement =>
  v4APICall(`/witnessStatements/${statement.id}`, {
    method: 'put',
    data: statement
  });

// companies
export const addCompany = company =>
  v4APICall('/companies', {
    hierarchy: false,
    method: 'post',
    data: company
  });

export const updateCompany = company =>
  v4APICall(`/companies`, {
    hierarchy: 'company',
    method: 'put',
    data: company
  });

export const deleteCompany = company =>
  v4APICall(`/companies`, {
    hierarchy: 'company',
    method: 'delete',
    data: company
  });

export const getHoursWorked = year =>
  v4APICall(`/companies/hoursWorked/${year}`, {
    hierarchy: 'company'
  });

export const setHoursWorked = (year, hours) =>
  v4APICall(`/companies/hoursWorked/${year}`, {
    hierarchy: 'company',
    method: 'put',
    data: hours
  });

// groups
export const createGroup = group =>
  v4APICall('/groups', {
    hierarchy: false,
    method: 'post',
    data: group
  });

export const updateGroup = group =>
  v4APICall(`/groups/${group._id}`, {
    hierarchy: false,
    method: 'put',
    data: group
  });

export const deleteGroup = id =>
  v4APICall(`/groups/${id}`, {
    hierarchy: false,
    method: 'delete'
  });

//projects
export const addGroupProject = project =>
  v4APICall('/projects', {
    hierarchy: false,
    method: 'post',
    data: project
  });

export const updateGroupProject = project =>
  v4APICall(`/projects/${project._id}`, {
    hierarchy: false,
    method: 'put',
    data: project
  });

export const deleteGroupProject = id =>
  v4APICall(`/projects/${id}`, {
    hierarchy: false,
    method: 'delete'
  });

// Behavior Observations
export const fetchEmployeeObservations = id =>
  v4APICall(`/behaviorObservation/employee/${id}`);

export const fetchBehaviorObservations = () =>
  v4APICall(`/behaviorObservation`);

export const fetchBehaviorObservationById = id =>
  v4APICall(`/behaviorObservation/${id}`);

export const createBehaviorObservation = bo =>
  v4APICall('/behaviorObservation', {
    hierarchy: 'company',
    method: 'post',
    data: bo
  });

export const updateBehaviorObservation = bo =>
  v4APICall(`/behaviorObservation/${bo._id}`, {
    hierarchy: 'company',
    method: 'put',
    data: bo
  });

export const deleteBehaviorObservation = id =>
  v4APICall(`/behaviorObservation/${id}`, { method: 'delete' });

export const printBehaviorObservation = id =>
  v4APICall(`/behaviorObservation/${id}/print`, { hierarchy: false });

export const fetchBehaviorObservationTemplates = () =>
  v4APICall('/behaviorObservation/templates', {
    hierarchy: 'company'
  });

export const fetchBehaviorObservationTemplateById = id =>
  v4APICall(`/behaviorObservation/template/${id}`);

export const createBehaviorObservationTemplate = bot =>
  v4APICall('/behaviorObservation/template', {
    method: 'post',
    hierarchy: 'company',
    data: bot
  });

export const updateBehaviorObservationTemplate = bot =>
  v4APICall(`/behaviorObservation/template/${bot._id}`, {
    method: 'put',
    data: bot
  });

export const deleteBehaviorObservationTemplate = id =>
  v4APICall(`/behaviorObservation/template/${id}`, { method: 'delete' });

// training exceptions
export const fetchTrainingExceptionTitles = data =>
  v4APICall(`/trainings/exception/trainingTitles`, {
    method: 'post',
    hierarchy: 'company',
    data
  });

export const fetchTrainingExceptionReport = data =>
  v4APICall(`/trainings/exception/list`, {
    method: 'post',
    data
  });

export const downloadExceptionList = data =>
  v4APICall(`/trainings/exception/download`, {
    method: 'post',
    data
  });

export const exportCustomReportQuestions = data =>
  v4APICall(`/customReportQuestions/export`, {
    method: 'post',
    data
  });

// leaderboard
export const fetchDashboards = data =>
  v4APICall(`/leaderboards/dashboards`, {
    hierarchy: 'company',
    data
  });

export const fetchDashboardById = (id, data) =>
  v4APICall(`/leaderboards/dashboard/${id}`, {
    hierarchy: 'company',
    method: 'post',
    data
  });

export const updateDashboardById = (id, data) =>
  v4APICall(`/leaderboards/updateDashboard/${id}`, {
    method: 'post',
    hierarchy: 'company',
    data
  });

export const createDashboard = data =>
  v4APICall(`/leaderboards/dashboard/create`, {
    method: 'post',
    hierarchy: 'company',
    data
  });

export const deleteDashboardById = id =>
  v4APICall(`/leaderboards/deleteDashboard/${id}`, {
    hierarchy: 'company',
    method: 'delete'
  });

export const fetchLeaderboardById = id =>
  v4APICall(`/leaderboards/${id}`, {
    hierarchy: 'company',
    method: 'post'
  });

export const createLeaderboard = data =>
  v4APICall(`/leaderboards`, {
    hierarchy: 'company',
    method: 'post',
    data
  });

export const updateLeaderboardById = (id, data) =>
  v4APICall(`/leaderboards/update/${id}`, {
    hierarchy: 'company',
    method: 'post',
    data
  });

export const deleteLeaderboardById = data =>
  v4APICall(`/leaderboards`, {
    hierarchy: 'company',
    method: 'delete',
    data
  });

export const downloadLeaderboard = data =>
  v4APICall(`/leaderboards/download`, {
    hierarchy: 'company',
    method: 'post',
    data
  });

export const updateManyRepeatables = data =>
  v4APICall(`/repeatable/updateMany`, {
    method: 'put',
    data: data
  });

// safety audit exceptions
export const fetchSafetyAuditExceptionTitles = data =>
  v4APICall(`/safetyAudit/exception/titles`, {
    method: 'post',
    hierarchy: 'company',
    data
  });
export const fetchFireExposures = () =>
  v4APICall(`/fireexposure`, {
    hierarchy: 'company'
  });
export const fetchFireExposureById = id =>
  v4APICall(`/fireexposure/${id}`, {
    hierarchy: false
  });
export const createFireExposure = data =>
  v4APICall(`/fireexposure`, {
    data,
    method: 'post',
    hierarchy: 'company'
  });

export const fetchSafetyAuditExceptionReport = data =>
  v4APICall(`/safetyAudit/exception/list`, {
    method: 'post',
    data
  });

export const downloadSafetyAuditExceptionList = data =>
  v4APICall(`/safetyAudit/exception/download`, {
    method: 'post',
    data
  });
export const deleteFireExposureById = id =>
  v4APICall(`/fireexposure/${id}`, {
    hierarchy: false,
    method: 'delete'
  });
export const printExposure = id => v4APICall(`/fireexposure/${id}/print`);
