import React, { Component } from 'react';

import { TRANSITIONAL_INFORMATION } from '../../../constants/constants';
import { DatePicker } from '../../inputs/DateTimePicker';
import Textbox from '../../inputs/Textbox';
import Card from '../../Card';
import Notebox from '../../Notebox';

export default class TransitionalInfoCard extends Component {
  render() {
    const {
      employeeName,
      workRestrictions,
      dateOfTransition,
      endDateOfTransition,
      onChange,
      disabled,
      name
    } = this.props;
    return (
      <Card
        name={name}
        showHeader
        title="Transitional Return to Work Program"
        wide
      >
        <Textbox
          fieldLabel="Employee Name"
          placeholder="ex. Jane Doe"
          isRequired={true}
          onChange={employeeName => onChange({ employeeName })}
          currentValue={employeeName}
          disabled={disabled}
        />
        <Notebox note={TRANSITIONAL_INFORMATION[0]} />
        <Textbox
          fieldLabel="List your Work Restrictions (according to your physician)"
          type="textarea"
          rows={5}
          placeholder="Type here"
          onChange={workRestrictions => onChange({ workRestrictions })}
          currentValue={workRestrictions}
          isRequired
          disabled={disabled}
        />
        <DatePicker
          pickTime
          fieldLabel="Date Transitional Return to Work Program starts"
          isRequired
          disabled={disabled}
          onChange={value => onChange({ dateOfTransition: value })}
          currentValue={dateOfTransition}
          name="returnToWorkStarts"
        />
        <DatePicker
          pickTime
          fieldLabel="Date Transitional Return to Work Program ends"
          disabled={disabled}
          onChange={value => onChange({ endDateOfTransition: value })}
          currentValue={endDateOfTransition}
          name="returnToWorkends"
        />
        <Notebox note={TRANSITIONAL_INFORMATION[1]} />
      </Card>
    );
  }
}
