import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import Card from '../../Card';
import { getActiveCompany } from '../../../selectors/company';
import { getActiveIncidentSelector } from '../../../selectors/incidents';
import { oshaHasAnswers } from '../../../utils/oshaDbMapper';
import CheckboxGroup from '../../inputs/CheckboxGroup';
import { DatePicker } from '../../inputs/DateTimePicker';
import OshaInjuredWorker from '../../OshaInjuredWorker';
import Textbox from '../../inputs/Textbox';
import RadioButtons from '../../inputs/RadioButtons';

export const OshaComplianceCard = ({
  name,
  answerOsha,
  oshaForm,
  disabled
}) => {
  const activeCompany = useSelector(getActiveCompany, shallowEqual);
  const activeIncident = useSelector(getActiveIncidentSelector);

  const isPerrp = activeCompany.reportingType === 2;

  const hasAnswers = oshaHasAnswers(oshaForm);
  let rightOptions = [];
  if ((hasAnswers || oshaForm?.redline) && oshaForm?._id) {
    rightOptions.push({
      value: 'redline',
      label: 'Redline The Original Entry'
    });
  }
  const answerRight = values => {
    let answers = {};
    if (hasAnswers || oshaForm?.redline) {
      answers.redline = values.some(v => v === 'redline');
    }

    answerOsha && answerOsha(answers);
  };
  const yesNoOptions = [
    { value: false, label: 'Yes' },
    { value: true, label: 'No' }
  ];

  const rightValues = [];
  if (oshaForm.redline) rightValues.push('redline');
  if (oshaForm.isNotApplicable) rightValues.push('isNotApplicable');

  return (
    <Card
      showHeader
      title={isPerrp ? 'PERRP' : 'OSHA Compliance'}
      name={name}
      rightButton={
        <CheckboxGroup
          options={rightOptions}
          onChange={answerRight}
          currentValue={rightValues}
          disabled={disabled}
        />
      }
      wide
    >
      <RadioButtons
        fieldLabel={`Is ${isPerrp ? 'PERRP' : 'OSHA'} Recordable`}
        options={yesNoOptions}
        onChange={values => answerOsha?.({ isNotApplicable: values })}
        currentValue={oshaForm.isNotApplicable}
        disabled={disabled}
      />
      <DatePicker
        fieldLabel="When did the incident occur?"
        isRequired
        name="dateTime"
        onChange={(dateTime, timeNotDetermined) =>
          answerOsha?.({ dateTime, timeNotDetermined })
        }
        currentValue={oshaForm.dateTime}
        timeNotDetermined={oshaForm.timeNotDetermined}
        pickTime
        pickNotExact
        disabled={disabled}
      />
      <Textbox
        fieldLabel="Describe where the incident occurred. "
        onChange={v => answerOsha?.({ description: v })}
        isRequired
        currentValue={oshaForm.description}
        type="textarea"
        placeholder="e.g. Loading Dock North End"
        disabled={disabled}
      />
      <OshaInjuredWorker
        handleInjuredWorkerChange={values => answerOsha?.(values)}
        oshaForm={oshaForm}
        isPerrp={isPerrp}
        disabled={disabled}
        groupId={activeIncident.groupId}
      />
    </Card>
  );
};

export default OshaComplianceCard;
