import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from '../../../history';
import * as api from '../../../api';
import * as apiV4 from '../../../api/v4';
import {
  addUploadedAttachmentsRequest,
  deleteAttachmentRequest,
  clearUploadedAttachments,
  updateAttachmentRequest
} from '../../../actions/attachments';
import {
  addCustomTrainingTemplateRequest,
  updateCustomTrainingTemplateRequest,
  deleteCustomTrainingTemplateRequest,
  setActiveCustomTrainingTemplate
} from '../../../actions/training';
import { getAddedAttachmentsSelector } from '../../../selectors/attachments';
import { getActiveCompany } from '../../../selectors/company';
import {
  getActiveTemplateSelector,
  getActiveTrainingSelector
} from '../../../selectors/training';
import { getLoggedInUser } from '../../../selectors/users';
import AddYouTubeLinksModal from '../../../components/Modal/addYouTubeLinksModal';
import Card from '../../../components/Card';
import DeleteTrainingTemplateModal from '../../../components/Modal/deleteTrainingTemplateModal';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import TrainingTemplate from '../../../components/training/TrainingTemplate';

import styles from './styles.module.scss';

class CustomTrainingTemplate extends Component {
  state = {
    title: '',
    instructions: '',
    needsApproval: false,
    doesExpire: false,
    expiresAfterNumber: '',
    expiresAfterOption: '',
    hasUnsavedChanges: false,
    isEditing: false,
    isCreating: false,
    openDeleteConfirmationModal: false,
    openUnsavedChanges: false,
    attachments: [],
    approver: '',
    youtubeLinks: [],
    openYoutubeLinksModal: false,
    quizMasterId: '',
    quizzes: [],
    oldAttachments: [],
    category: '',
    titleList: []
  };

  componentDidMount() {
    let activeTemplate = this.props.activeTemplate;
    if (activeTemplate?._id) {
      this.setState({
        ...activeTemplate,
        expiresAfterOption: activeTemplate?.expiresAfterOption ?? 'days',
        oldAttachments: activeTemplate.attachments
      });
    } else if (activeTemplate.duplicated) {
      this.setState({
        ...activeTemplate,
        expiresAfterOption: activeTemplate?.expiresAfterOption ?? 'days',
        oldAttachments: activeTemplate.attachments,
        isCreating: true
      });
    } else {
      this.setState({
        isCreating: true,
        expiresAfterOption: 'days'
      });
    }

    if (this.props?.location?.state?.fromScreen) {
      this.setState({ ...this.props.activeTraining });
    }

    api
      .fetchQuizzes(this.props?.activeCompany?._id)
      .then(response => this.setState({ quizzes: response }));

    apiV4
      .fetchCustomTrainingTemplates()
      .then(response => this.setState({ titleList: response }));
  }

  canSubmit = () => {
    const {
      title,
      doesExpire,
      expiresAfterNumber,
      expiresAfterOption,
      attachments,
      youtubeLinks,
      quizMasterId
    } = this.state;

    const { addedAttachments } = this.props;

    if (
      title.trim().length > 2 &&
      (attachments.length > 0 ||
        addedAttachments.length > 0 ||
        youtubeLinks.length > 0 ||
        quizMasterId)
    ) {
      if (
        doesExpire &&
        (!expiresAfterNumber || expiresAfterOption.length === 0)
      ) {
        return false;
      }

      return true;
    }

    return false;
  };

  addAttachments = async e => {
    const files = e.target.files;
    if (!files.length) return;

    for (let i = 0; i < files.length; i++) {
      const FileSize = files[i].size / 1024 / 1024; // in MB
      if (FileSize > 2000) {
        alert('File size exceeds 2000 MB');
        return;
      }
    }
    if (files.length > 0) {
      let data = new FormData();

      Object.entries(files).forEach(async file => {
        data.append('attachments', file[1], file[1].name);
      });

      await this.props.addAttachments({
        data,
        ownerId: '1234',
        ownerType: 'TrainingTemplate'
      });

      this.setState({ hasUnsavedChanges: true });
    }
  };

  handleSubmit = () => {
    const { addCustomTemplate, updateCustomTemplate } = this.props;
    const template = this.state;

    if (template._id) {
      updateCustomTemplate(template);
    } else {
      addCustomTemplate(template);
    }
  };

  handleDelete = () => {
    const { deleteCustomTemplate } = this.props;

    deleteCustomTemplate(this.state);
  };

  handleBack = () => {
    if (this.state.isEditing) {
      const oldAttachments = this.state.oldAttachments;
      const attachments = this.state.attachments;

      oldAttachments.forEach(attachment => {
        if (!attachments.find(att => att._id === attachment._id)) {
          this.props.updateAttachment({ ...attachment, isDeleted: false });
        }
      });
    }

    this.props.clearUploadedAttachments();
    this.props.setActiveTemplate();
    history.push('/app/training/templateList');
  };

  handleDeleteAttachment = attachment => {
    this.props.deleteAttachment(attachment);

    let attachments = this.state.attachments;
    attachments = attachments.filter(attach => attach._id !== attachment._id);

    this.setState({ attachments, hasUnsavedChanges: true });
  };

  render() {
    const {
      title,
      instructions,
      needsApproval,
      doesExpire,
      expiresAfterOption,
      expiresAfterNumber,
      hasUnsavedChanges,
      isEditing,
      isCreating,
      openDeleteConfirmationModal,
      openUnsavedChanges,
      attachments,
      approver,
      youtubeLinks,
      openYoutubeLinksModal,
      quizMasterId,
      quizzes,
      category,
      titleList
    } = this.state;

    const { addedAttachments } = this.props;

    const quizDropdown = quizzes?.map(quiz => {
      return {
        label: quiz.title,
        value: quiz._id
      };
    });

    const header = (
      <Header
        title="Training Template"
        section={
          !isCreating && !isEditing
            ? 'View Template'
            : isEditing
            ? 'Edit Template'
            : 'Create Template'
        }
        clickBack={() =>
          hasUnsavedChanges
            ? this.setState({ openUnsavedChanges: true })
            : this.handleBack()
        }
        rightButtons={
          isEditing
            ? {
                text: 'Delete Template',
                onClick: () =>
                  this.setState({ openDeleteConfirmationModal: true }),
                color: 'red',
                visible: !isCreating
              }
            : {
                text: 'Edit Template',
                onClick: () => this.setState({ isEditing: true }),
                color: 'blue',
                visible: !isCreating
              }
        }
      />
    );

    const footer = (
      <SaveCancelFooter
        saveButtonDisabled={!this.canSubmit()}
        saveButtonClick={() => this.handleSubmit()}
        cancelButtonClick={() =>
          hasUnsavedChanges
            ? this.setState({ openUnsavedChanges: true })
            : this.handleBack()
        }
        editing={isEditing}
      />
    );
    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          showFooter={isEditing || isCreating}
          className={styles.trainingTemplate}
        >
          <Card showHeader title="Training Template Creation">
            <TrainingTemplate
              updateAnswer={values => this.setState({ ...values })}
              title={title}
              instructions={instructions}
              needsApproval={needsApproval}
              doesExpire={doesExpire}
              expiresAfterNumber={expiresAfterNumber}
              expiresAfterOption={expiresAfterOption}
              isEditing={isEditing}
              isCreating={isCreating}
              approver={approver}
              quizMasterId={quizMasterId}
              quizDropdown={quizDropdown}
              category={category}
              addAttachments={e => {
                this.addAttachments(e);
                e.target.value = '';
              }}
              addedAttachments={addedAttachments}
              deleteAttachment={attachment =>
                this.handleDeleteAttachment(attachment)
              }
              attachments={attachments}
              isTemplateCreate={true}
              titleList={titleList}
              youtubeLinks={youtubeLinks}
            />
          </Card>
        </HeaderAndFooter>
        <SaveChangesModal
          isOpen={openUnsavedChanges}
          savingWhat="Training Template"
          onRequestClose={() => this.setState({ openUnsavedChanges: false })}
          submitActions={() => this.handleBack()}
        />
        <DeleteTrainingTemplateModal
          isOpen={openDeleteConfirmationModal}
          onRequestClose={() =>
            this.setState({ openDeleteConfirmationModal: false })
          }
          submitActions={() => this.handleDelete()}
        />
        <AddYouTubeLinksModal
          isOpen={openYoutubeLinksModal}
          onRequestClose={() => this.setState({ openYoutubeLinksModal: false })}
          submitActions={values =>
            this.setState({
              youtubeLinks: values,
              openYoutubeLinksModal: false
            })
          }
          youtubeLinks={youtubeLinks}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  activeTemplate: getActiveTemplateSelector(state),
  activeTraining: getActiveTrainingSelector(state),
  activeCompany: getActiveCompany(state)
});

const mapDispatchToProps = dispatch => ({
  addAttachments: payload => dispatch(addUploadedAttachmentsRequest(payload)),
  setActiveTemplate: () => dispatch(setActiveCustomTrainingTemplate()),
  addCustomTemplate: payload =>
    dispatch(addCustomTrainingTemplateRequest(payload)),
  updateCustomTemplate: payload =>
    dispatch(updateCustomTrainingTemplateRequest(payload)),
  deleteCustomTemplate: payload =>
    dispatch(deleteCustomTrainingTemplateRequest(payload)),
  deleteAttachment: payload => dispatch(deleteAttachmentRequest(payload)),
  clearUploadedAttachments: () => dispatch(clearUploadedAttachments()),
  updateAttachment: payload => dispatch(updateAttachmentRequest(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomTrainingTemplate);
