import React, { useState } from 'react';
import moment from 'moment';
import Button from '../Button';
import Datebox from './dateBox';
import Checkbox from '../Checkbox';
import RadioButtons from '../RadioButtons';
import Textbox from '../Textbox';
import Modal from '../../Modal';
import { Input, nameInput } from '../';
import { DayPickerRangeController } from 'react-dates';
import { extendMoment } from 'moment-range';

import 'react-dates/lib/css/_datepicker.css';
import styles from './styles.module.scss';

extendMoment(moment);

const MultipleDateRangePicker = ({
  onChange,
  minDate,
  fieldLabel,
  datesArray,
  datesCheckbox,
  disabled,
  daysAway,
  name,
  handleDatesSubmit,
  deleteDate,
  modalLabel,
  ...props
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [dateButtonChoice, setDateButtonChoice] = useState(0);
  const [focusedInput, setFocusedInput] = useState('startDate');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [editing, setEditing] = useState(false);
  const [previousStartDate, setPreviousStartDate] = useState(null);
  const [previousEndDate, setPreviousEndDate] = useState(null);
  const [endDateString, setEndDateString] = useState('');
  const [startDateString, setStartDateString] = useState('');

  const closeModal = () => {
    setOpenModal(false);
    setStartDate(null);
    setEndDate(null);
    setFocusedInput('startDate');
    setDateButtonChoice(0);
    setStartDateString('');
    setEndDateString('');
  };

  const findBlockedDatesArray = () => {
    let start = moment(previousStartDate);
    let end = moment(previousEndDate);

    let dates = editing
      ? datesArray.filter(d => {
          if (!d.endDate === null) {
            return (
              !start.isSame(d.startDate, 'day') && !end.isSame(d.endDate, 'day')
            );
          } else {
            return !start.isSame(d.startDate, 'day');
          }
        })
      : datesArray ?? [];

    return dates;
  };

  const isBlockedDate = date => {
    let beforeIncidentDate = date.isBefore(minDate, 'day');
    let sameDates;

    let dates = findBlockedDatesArray();

    sameDates = dates?.some(d => {
      if (d.endDate === null) {
        return date.isSameOrAfter(d.startDate, 'days', '[]')
          ? date
          : date.isSame(d.startDate, 'day');
      } else {
        return date.isBetween(d.startDate, d.endDate, 'days', '[]')
          ? date
          : null;
      }
    });

    return beforeIncidentDate || sameDates;
  };

  const checkForBlockedDates = () => {
    // issues when ongoing if after todays date
    if (startDate) {
      let end = endDate ?? moment();
      let dates = findBlockedDatesArray();

      const selectedDateRange = moment.range(startDate, end);

      for (let i = 0; i < dates.length; i++) {
        let endDate = dates[i].endDate ?? moment();
        const range = moment.range(dates[i].startDate, endDate);
        if (selectedDateRange.overlaps(range)) {
          return true;
        }
      }

      return false;
    }
    return false;
  };

  const handleDateChange = dates => {
    let start = dates.startDate;
    // if its ongoing set the end date to null
    let end = dateButtonChoice === 1 ? dates.startDate : dates.endDate;

    setStartDate(prevState => (start ? start : prevState));
    setEndDate(prevState => (start ? end : prevState));
    setStartDateString(prevState =>
      start
        ? moment(start).format('MM/DD/YYYY')
        : moment(prevState).format('MM/DD/YYYY')
    );
    setEndDateString(prevState =>
      end
        ? moment(end).format('MM/DD/YYYY')
        : end === null
        ? ''
        : moment(prevState).format('MM/DD/YYYY')
    );
  };

  const handleFocusChange = focus => {
    if (dateButtonChoice === 0) {
      setFocusedInput(!focus ? 'startDate' : focus);
    } else {
      setFocusedInput('startDate');
    }
  };

  const checkForInvalidDateRange = () => {
    if (dateButtonChoice === 0 && moment(startDate).isAfter(endDate)) {
      return 'Start date cannot be after end date';
    }
    if (dateButtonChoice !== 1 && checkForBlockedDates()) {
      return 'Date range cannot include blocked dates';
    }
  };

  const warningMessage = date => {
    const pattern = /(^0[1-9]|^1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)(\d{2}$)/;
    let match = date.match(pattern);
    let warningMessage;
    if (match === null && date !== '') {
      warningMessage = 'Invalid Date';
    } else if (date !== '') {
      const blocked = isBlockedDate(moment(date));
      if (blocked) {
        warningMessage = 'Cannot be a blocked date';
      } else {
        warningMessage = null;
      }
    } else {
      warningMessage = null;
    }

    return warningMessage;
  };

  const handleDateInputChange = (date, type) => {
    const pattern = /(^0[1-9]|^1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)(\d{2}$)/;
    var match = date.match(pattern);
    if (date !== '' && match !== null) {
      const blocked = isBlockedDate(moment(date));
      if (!blocked) {
        type === 'start'
          ? setStartDate(moment(date))
          : setEndDate(moment(date));

        if (dateButtonChoice === 1) {
          setEndDate(moment(date));
        }
      }
    } else {
      type === 'start' ? setStartDate(null) : setEndDate(null);
    }
  };

  const disableSubmit = () => {
    if (
      startDate === null ||
      checkForInvalidDateRange() ||
      checkForBlockedDates()
    ) {
      return true;
    }
    return dateButtonChoice !== 2 && endDate === null;
  };

  const handleDateBoxClick = values => {
    let startDate = values.date.startDate;
    let endDate = values.date.endDate;
    setOpenModal(true);
    setStartDate(moment(startDate));
    setEndDate(moment(endDate) ?? endDate);
    setFocusedInput('startDate');
    setEditing(true);
    setStartDateString(moment(startDate).format('MM/DD/YYYY'));
    setEndDateString(endDate ? moment(endDate).format('MM/DD/YYYY') : '');
    setPreviousEndDate(endDate);
    setPreviousStartDate(startDate);
    setDateButtonChoice(values.dateType);
  };

  return (
    <Input {...props} name={name} disabled={disabled} fieldLabel={fieldLabel}>
      <Checkbox
        fieldLabel={0 + ' ' + fieldLabel}
        name={`${name}Checkbox`}
        onChange={onChange}
        currentValue={datesCheckbox}
        disabled={disabled}
      />
      <span className={styles.numberOfDaysBox}>
        {daysAway || 0} {fieldLabel}
      </span>
      {!datesCheckbox &&
        datesArray?.map((date, index) => (
          <Datebox
            index={index}
            date={date}
            deleteDate={deleteDate}
            onClick={handleDateBoxClick}
            disabled={disabled}
          />
        ))}
      <Button
        className={styles.button}
        color="blue"
        text="Add Dates"
        onClick={() => setOpenModal(true)}
        disabled={datesCheckbox || !minDate || disabled}
      />
      {!minDate ? (
        <span className={styles.incidentDateWarning}>
          Please enter an incident date
        </span>
      ) : (
        ''
      )}
      <Modal
        title="Add Dates"
        titleClassName="blueHeader"
        isOpen={openModal}
        submitButtonColor="blue"
        submitButtonText="Add Dates"
        onRequestClose={() => closeModal()}
        submitActions={() => {
          setOpenModal(false);
          setStartDate(null);
          setEndDate(null);
          setFocusedInput('startDate');
          setDateButtonChoice(0);
          setEditing(false);
          setStartDateString('');
          setEndDateString('');
          handleDatesSubmit({
            startDate: startDate,
            endDate: endDate,
            peviousEndDate: previousEndDate,
            previousStartDate: previousStartDate,
            editing: editing
          });
        }}
        disableSubmit={disableSubmit()}
        wide
      >
        <div>
          <span>{modalLabel}</span>
        </div>
        <RadioButtons
          fieldLabel=""
          options={[
            { value: 0, label: 'Date Range' },
            { value: 1, label: 'Single Day' },
            { value: 2, label: 'Ongoing' }
          ]}
          isRequired={true}
          onChange={value => {
            // if editing ongoing and chaning to date range we want to keep the start date
            value === 0 && dateButtonChoice === 2 && editing
              ? setFocusedInput('endDate')
              : setFocusedInput('startDate');
            if (!(value === 0 && dateButtonChoice === 2 && editing)) {
              setStartDate(null);
              setStartDateString('');
            }
            setEndDate(null);
            setEndDateString('');
            setDateButtonChoice(value);
          }}
          currentValue={dateButtonChoice}
          disabled={disabled}
        />
        <React.Fragment>
          <Textbox
            className={styles.dateInputs}
            name={`${name}StartDate`}
            placeholder="MM/DD/YYYY"
            currentValue={startDateString}
            onChange={v => {
              setStartDateString(v);
              handleDateInputChange(v, 'start');
            }}
          />
          {dateButtonChoice === 0 && (
            <div className={styles.dateInputs}>
              <span className={styles.dash}> - </span>
              <Textbox
                name={`${name}EndDate`}
                placeholder="MM/DD/YYYY"
                currentValue={endDateString}
                onChange={v => {
                  setEndDateString(v);
                  handleDateInputChange(v, 'end');
                }}
              />
            </div>
          )}
        </React.Fragment>
        <div>
          <span className={styles.warning}>
            {warningMessage(startDateString) ?? checkForInvalidDateRange()}
          </span>
          <span className={styles.warning}>
            {warningMessage(endDateString)}
          </span>
        </div>
        {openModal && (
          <div className={styles.dateRangePicker}>
            <DayPickerRangeController
              startDate={startDate}
              endDate={endDate}
              minimumNights={dateButtonChoice === 0 ? 1 : 0}
              onDatesChange={handleDateChange}
              focusedInput={focusedInput}
              onFocusChange={handleFocusChange}
              numberOfMonths={3}
              isDayBlocked={isBlockedDate}
              initialVisibleMonth={() => moment(minDate) || moment()}
            />
          </div>
        )}
      </Modal>
    </Input>
  );
};

const m = nameInput(MultipleDateRangePicker);
export { m as MultipleDateRangePicker };
export default m;
