import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { isDirty, hasSubmitSucceeded } from 'redux-form';

import history from '../../history';
import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments,
  deleteAttachmentRequest
} from '../../actions/attachments';
import {
  deleteSafetyInstructionAttachment,
  deleteSafetyInstructionRequest,
  deleteSdsAttachment,
  deleteSdsRequest,
  updateSafetyInstructionRequest,
  updateSdsRequest
} from '../../actions/documents';
import { setActiveIncidentRequest } from '../../actions/incidents';
import {
  getAddedAttachmentsSelector,
  getAttachmentLoadingSelector
} from '../../selectors/attachments';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject,
  getCompanyLocationsSelector
} from '../../selectors/company';
import {
  getActiveSafetyInstructionSelector,
  getActiveSdsSelector,
  getDocumentFoldersSelector
} from '../../selectors/documents';
import { getLoggedInUser } from '../../selectors/users';
import SafetyInstructionForm from '../../forms/SafetyInstructionForm';
import SdsForm from '../../forms/SdsForm';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Card from '../../components/Card';
import SaveChangesModal from '../../components/Modal/saveChangesModal';

export class ViewEdit extends Component {
  state = {
    viewing: true,
    sds: {
      label: '',
      expires: '',
      ghsLabels: [],
      locationId: '',
      projectId: ''
    },
    openUnsavedChangesModal: false,
    sdsDirty: false
  };

  componentDidMount = () => {
    this.setState({
      sds: {
        ...this.props.activeSds,
        ghsLabels:
          this.props.activeSds.documentData &&
          this.props.activeSds.documentData.ghsLabels
            ? this.props.activeSds.documentData.ghsLabels
            : []
      }
    });
  };

  updateSds = values => {
    this.setState(state => ({
      sds: { ...state.sds, ...values }
    }));
  };

  handleSPSubmit = values => {
    this.props.updateSafetyInstruction(values);
    this.setState({ viewing: true });

    this.props.clearAttachment();
  };

  handleSubmit = () => {
    this.props.updateSds(this.state.sds);

    this.props.clearAttachment();
  };

  handleCancel = (discard = false) => {
    const dirty =
      this.props.match.params.editType === 'SDS'
        ? this.state.sdsDirty
        : this.props.siDirty && !this.props.siSubmitted;

    if (dirty && !discard) {
      this.setState({ openUnsavedChangesModal: true });
      return;
    }

    this.props.clearAttachment();
    history.goBack();
  };

  render() {
    const {
      match,
      companyLocations,
      activeSds,
      deleteSds,
      activeSafetyInstruction,
      deleteSafetyInstruction,
      activeCompany,
      addAttachment,
      clearAttachment,
      deleteSIAttachment,
      deleteSDSAttachment,
      attachmentLoading,
      loggedInUser,
      deleteAttachment,
      addedAttachments,
      activeLocation,
      activeProject,
      documentFolders,
      siDirty
    } = this.props;

    const { viewing, openUnsavedChangesModal, sdsDirty } = this.state;
    const header = (
      <Header
        title={`View/Edit ${match.params.editType}`}
        clickBack={() => this.handleCancel(false)}
        needsSaved={
          match.params.editType === 'SDS'
            ? sdsDirty
            : siDirty && !this.props.siSubmitted
        }
        rightButtons={{
          visible:
            match.params.editType !== 'SDS' &&
            viewing &&
            loggedInUser.accessLevel !== 100,
          text: 'Edit',
          color: 'white',
          onClick: () =>
            this.setState({
              viewing: false
            })
        }}
      />
    );

    return (
      <>
        <HeaderAndFooter Header={header}>
          <Card>
            {match.params.editType === 'SDS' ? (
              <SdsForm
                submitForm={() => {
                  this.setState({ viewing: true, sdsDirty: false });
                  this.handleSubmit();
                }}
                sds={{
                  ...activeSds,
                  expires: moment(activeSds.expires).format('MM/DD/YYYY')
                }}
                disabled={viewing}
                onCancel={() =>
                  clearAttachment() && this.setState({ viewing: true })
                }
                onDirty={() => this.setState({ sdsDirty: true })}
                onDelete={() => deleteSds(activeSds)}
                activeCompanyId={activeCompany._id}
                addAttachment={addAttachment}
                activeAttachments={activeSds.attachments}
                deleteSDSAttachment={deleteSDSAttachment}
                loading={attachmentLoading}
                isUpdate={true}
                accessLevel={loggedInUser.accessLevel}
                addedAttachments={addedAttachments}
                deleteAttachment={deleteAttachment}
                activeLocation={activeLocation}
                activeProject={activeProject}
                locationList={companyLocations}
                handleSdsSelection={this.updateSds}
                documentFolders={documentFolders}
              />
            ) : match.params.editType === 'Safety Procedure' ? (
              <SafetyInstructionForm
                onSubmit={values => this.handleSPSubmit(values)}
                initialValues={{
                  ...activeSafetyInstruction,
                  locationId: activeSafetyInstruction.groupIds,
                  projectId: activeSafetyInstruction.projectIds
                }}
                disabled={viewing}
                onCancel={() =>
                  clearAttachment() && this.setState({ viewing: true })
                }
                onDelete={() =>
                  deleteSafetyInstruction(activeSafetyInstruction)
                }
                activeCompanyId={activeCompany._id}
                addAttachment={addAttachment}
                activeAttachments={activeSafetyInstruction.attachments}
                deleteSIAttachment={deleteSIAttachment}
                loading={attachmentLoading}
                isUpdate={true}
                accessLevel={loggedInUser.accessLevel}
                addedAttachments={addedAttachments}
                deleteAttachment={deleteAttachment}
                activeLocation={activeLocation}
                activeProject={activeProject}
                locationList={companyLocations}
                documentFolders={documentFolders}
              />
            ) : (
              <></>
            )}
          </Card>
        </HeaderAndFooter>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() => this.handleCancel(true)}
          savingWhat="this document"
        />
      </>
    );
  }
}

export const mapStateToProps = state => ({
  companyLocations: getCompanyLocationsSelector(state),
  activeSds: getActiveSdsSelector(state),
  activeSafetyInstruction: getActiveSafetyInstructionSelector(state),
  activeCompany: getActiveCompany(state),
  attachmentLoading: getAttachmentLoadingSelector(state),
  loggedInUser: getLoggedInUser(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  activeLocation: getActiveLocationId(state),
  activeProject: getActiveProject(state),
  documentFolders: getDocumentFoldersSelector(state),
  siDirty: isDirty('SafetyInstructionForm')(state),
  siSubmitted: hasSubmitSucceeded('SafetyInstructionForm')(state)
});

export const mapDispatchToProps = dispatch => ({
  updateSds: sds => dispatch(updateSdsRequest(sds)),
  deleteSds: sds => dispatch(deleteSdsRequest(sds)),
  updateSafetyInstruction: safetyInstruction =>
    dispatch(updateSafetyInstructionRequest(safetyInstruction)),
  deleteSafetyInstruction: safetyInstruction =>
    dispatch(deleteSafetyInstructionRequest(safetyInstruction)),
  setActiveIncident: incident => dispatch(setActiveIncidentRequest(incident)),
  deleteSIAttachment: attachment =>
    dispatch(deleteSafetyInstructionAttachment(attachment)),
  deleteSDSAttachment: attachment => dispatch(deleteSdsAttachment(attachment)),
  addAttachment: attachment =>
    dispatch(addUploadedAttachmentsRequest(attachment)),
  clearAttachment: () => dispatch(clearUploadedAttachments()),
  deleteAttachment: values => dispatch(deleteAttachmentRequest(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewEdit);
