import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import * as api from '../../../api';

import history from '../../../history';
import {
  setActiveTrainingRequest,
  setActiveTrainingResponse,
  updateTrainingRequest
} from '../../../actions/training';
import { getActiveTrainingSelector } from '../../../selectors/training';
import { getLoggedInUser } from '../../../selectors/users';
import ApprovalItemModal from '../../../components/Modal/approvalItemModal';
import AssigneeTable from '../../../components/training/AssigneeTable';
import Card from '../../../components/Card';
import { EmployeeDropdown } from '../../../components/inputs/Dropdown';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import LeftNav from '../../../components/LeftNav';
import Modal from '../../../components/Modal';
import UserBadge from '../../../components/UserBadge';
import QuizTable from '../../../components/training/QuizTable';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import SaveProgressModal from '../../../components/Modal/saveProgressModal';
import SignatureCard from '../../../components/SignatureCard';
import { SubmitSaveForLaterFooter } from '../../../components/Footer';
import TrainingDetails from '../TrainingDetails';
import { TwoColumn, Textbox, Dropdown } from '../../../components/inputs';
import { getActiveCompany } from '../../../selectors/company';

class ApproveTraining extends Component {
  state = {
    hasUnsavedChanges: false,
    openUnsavedChanges: false,
    openApprovalComplateModal: false,
    assigneeApproval: [],
    openChangeApproverModal: false,
    changeApproverId: undefined,
    approverSignature: undefined,
    openSavedProgress: false,
    quizzes: null
  };

  defaultTrainingTitle = 'Safety Training';

  componentDidMount() {
    this.loadTraining();

    let _id = this.props?.match?.params?.trainingId;

    if (_id) {
      this.props.setTrainingReq({ _id, stay: true });
    }

    api
      .fetchQuizzes(this.props.activeCompany._id)
      .then(response => this.setState({ quizzes: response }));
  }

  loadTraining = () => {
    let assigneeApproval = [];
    let attachments = this.props.activeTraining?.childAttachments;
    let quizzes = this.props.activeTraining?.childQuizzes;

    if (quizzes?.length > 0) {
      for (let i = 0; i < quizzes.length; i++) {
        const quiz = quizzes[i];
        if (
          assigneeApproval.findIndex(
            assignee => assignee.assignee === quiz.assignee
          ) === -1
        ) {
          assigneeApproval.push({
            approveReject: quiz.approveReject,
            assignee: quiz.assignee
          });
        }
      }
    } else if (attachments?.length > 0) {
      for (let i = 0; i < attachments.length; i++) {
        const attachment = attachments[i];
        if (
          assigneeApproval.findIndex(
            assignee => assignee.assignee === attachment?.assignedTo
          ) === -1
        ) {
          assigneeApproval.push({
            approveReject: attachment?.approveReject,
            assignee: attachment?.assignedTo
          });
        }
      }
    }

    this.setState({
      approverSignature: this.props.activeTraining?.approverSignature,
      assigneeApproval
    });
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.activeTraining, this.props.activeTraining)) {
      if (prevProps.activeTraining?._id) {
        this.setState({ hasUnsavedChanges: true });
      }
      this.loadTraining();
    }
  }

  handleBack = () => {
    this.props.setActiveTraining({});
    history.goBack();
  };

  handleSubmit = (isComplete, comingFromReassigned = false) => {
    const { updateTraining, activeTraining } = this.props;
    const { assigneeApproval } = this.state;
    let response = { isCompleted: isComplete };

    if (isComplete) {
      response.completionDate = moment();
    }

    activeTraining.approverSignature = this.state.approverSignature;

    updateTraining({
      ...activeTraining,
      ...response,
      assigneeApproval,
      comingFromReassigned
    });
  };

  canSubmit = () => {
    const { activeTraining } = this.props;
    const { assigneeApproval } = this.state;
    return (
      assigneeApproval?.length === activeTraining?.assignedTo?.length &&
      this.state.approverSignature
    );
  };

  updateAnswer = values => {
    const { activeTraining } = this.props;
    let assignees = cloneDeep(this.state.assigneeApproval);

    let ids = values.assignee;

    if (ids?.length > 0) {
      ids.forEach(id => {
        let index = assignees.findIndex(aa => aa.assignee === id);
        if (index > -1) {
          assignees[index] = {
            approveReject: values.approveReject,
            assignee: id
          };
        } else {
          assignees = [
            ...assignees,
            {
              assignee: id,
              approveReject: values.approveReject
            }
          ];
        }
      });
    }

    this.setState({ assigneeApproval: assignees });

    ids.forEach(id => {
      activeTraining.childQuizzes.forEach(q => {
        if (q.assignee === id) {
          q.approveReject = values.approveReject;
        }
      });
    });

    ids.forEach(id => {
      activeTraining.childAttachments.forEach(a => {
        if (a.assignee === id) {
          a.approveReject = values.approveReject;
        }
      });
    });

    this.props.setActiveTraining({
      ...this.props.activeTraining
    });
  };

  getQuickNav = () => {
    const { activeTraining = {} } = this.props;

    let leftNav = [
      { label: 'Summary', id: '0' },
      { label: 'Training Details', id: '1' }
    ];

    if (activeTraining.childAttachments?.length) {
      leftNav = [...leftNav, { label: 'Training Material', id: '2' }];
    }

    if (activeTraining.quiz) {
      leftNav = [...leftNav, { label: activeTraining?.quiz?.title, id: '3' }];
    }

    leftNav = [...leftNav, { label: 'Approver Signature', id: '4' }];

    return leftNav;
  };

  handleChangeApprover = () => {
    this.props.updateTraining({
      ...this.props.activeTraining,
      approver: this.state.changeApproverId,
      isApprove: false
    });
    history.goBack();
  };

  render() {
    const { activeTraining = {}, loggedInUser } = this.props;

    const {
      openUnsavedChanges,
      assigneeApproval,
      openChangeApproverModal,
      changeApproverId,
      hasUnsavedChanges,
      openApprovalComplateModal,
      openSavedProgress,
      quizzes
    } = this.state;
    const header = (
      <Header
        title="Require Acknowledgment"
        section={activeTraining.title}
        clickBack={() => this.handleBack()}
        hasUnsavedChanges={hasUnsavedChanges}
        rightButtons={{
          text: 'Change Approver',
          onClick: () => this.setState({ openChangeApproverModal: true }),
          color: 'red',
          visible: activeTraining.isApprove
        }}
      />
    );

    const footer = (
      <SubmitSaveForLaterFooter
        submitButtonDisabled={!this.canSubmit()}
        submitButtonClick={() =>
          this.setState({ openApprovalComplateModal: true })
        }
        saveButtonClick={() => this.handleSubmit(false)}
        submitButtonText="Submit Approval"
      />
    );

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          showFooter={activeTraining.isEditing}
          Left={<LeftNav items={this.getQuickNav()} />}
        >
          <Card title="Summary" showHeader name="0">
            <TwoColumn>
              <Dropdown
                multi
                selectButtons
                searchable={true}
                labelKey="name"
                valueKey="_id"
                fieldLabel="Group or Establishment:"
                currentValue={activeTraining.groupIds}
                disabled
              />

              <Dropdown
                multi
                selectButtons
                searchable={true}
                fieldLabel="Area:"
                currentValue={activeTraining.projectIds}
                disabled
              />
            </TwoColumn>
            <Dropdown
              fieldLabel="Add To Folder"
              labelKey="name"
              valueKey="_id"
              currentValue={activeTraining.documentFolderId}
              disabled
            />
            <Textbox
              fieldLabel="Training Format"
              currentValue={activeTraining?.trainingType?.label}
              disabled
            />
          </Card>
          <TrainingDetails
            fromApproveTraining
            activeTraining={{ ...activeTraining, quizDropdown: quizzes }}
            name="1"
            quizDropdown={quizzes?.map(q => ({ label: q.title, value: q._id }))}
          />
          {activeTraining?.attachments?.length ? (
            <AssigneeTable
              setActiveTraining={values =>
                this.props.setActiveTraining({
                  ...values,
                  isEditing: false,
                  isFromSummary: true,
                  masterTraining: activeTraining
                })
              }
              assignees={activeTraining.assignedTo}
              attachments={activeTraining.attachments}
              childAttachments={activeTraining.childAttachments || []}
              needsApproval={
                activeTraining.needsApproval && !activeTraining?.quiz
              }
              isApprover={activeTraining.approver === loggedInUser._id}
              updateAnswer={values => this.updateAnswer(values)}
              assigneeApproval={assigneeApproval}
              name="2"
              fromAssigneeTable={true}
              dueDate={activeTraining.dueDate}
              fromApproveTraining={true}
              trainingType={activeTraining.trainingType}
              updateModal={value => this.setState({ openSavedProgress: value })}
              isMaster
            />
          ) : null}
          {activeTraining?.quiz && (
            <QuizTable
              childQuizzes={activeTraining?.childQuizzes}
              setActiveTraining={values =>
                this.props.setActiveTraining({
                  ...values,
                  isEditing: false,
                  isFromSummary: true,
                  masterTraining: activeTraining
                })
              }
              assigneeApproval={assigneeApproval}
              needsApproval={activeTraining.needsApproval}
              isApprover={activeTraining.approver === loggedInUser._id}
              updateAnswer={values => this.updateAnswer(values)}
              name="3"
              dueDate={activeTraining.dueDate}
              fromApproveTraining={true}
              activeTraining={activeTraining}
              updateModal={value => this.setState({ openSavedProgress: value })}
            />
          )}
          <SignatureCard
            header="Sign for Approval"
            currentValue={this.state.approverSignature}
            userDisabled={true}
            dropdownName="Approver Name"
            permissions={[400, 500, 900]}
            userId={activeTraining.approver}
            name="4"
            onChange={sig => this.setState({ approverSignature: sig })}
            ownerType="training"
            ownerId={activeTraining._id}
          />
        </HeaderAndFooter>
        <SaveChangesModal
          isOpen={openUnsavedChanges}
          savingWhat={activeTraining.title}
          onRequestClose={() => this.setState({ openUnsavedChanges: false })}
          submitActions={() => this.handleBack()}
        />
        <ApprovalItemModal
          isOpen={openApprovalComplateModal}
          approvingWhat="training"
          onRequestClose={() =>
            this.setState({ openApprovalComplateModal: false })
          }
          submitActions={() => this.handleSubmit(true)}
          cancelActions={() => this.handleSubmit(false)}
        />
        <Modal
          title="Change Approver"
          titleClassName="redHeader"
          isOpen={openChangeApproverModal}
          submitButtonColor="red"
          submitButtonText="Save Changes"
          onRequestClose={() =>
            this.setState({ openChangeApproverModal: false })
          }
          submitActions={() => this.handleChangeApprover()}
          disableSubmit={!changeApproverId}
        >
          <div className="reportSectionContainer-emailAssigeeText">
            From here you can assign a different user as the Approver for this
            Safety Training.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              This is to be used only if you are not the person meant to approve
              this safety training (as a Approver). You will be removed from
              this safety training.
            </span>
          </div>
          <div className="incidentContainer-reassignIncident">
            <UserBadge
              showImage
              hoverName
              userId={activeTraining.approver}
              size="large"
            />
            <img
              src={require('../../../assets/images/reassignArrow.png')}
              className="reassignArrow"
              alt="assign arrow"
            />
            <UserBadge
              showImage
              hoverName
              userId={changeApproverId}
              size="large"
            />
          </div>
          <EmployeeDropdown
            permissions={[400, 500, 900]}
            currentValue={changeApproverId}
            onChange={value => this.setState({ changeApproverId: value })}
            placeholder="Select one.."
          />
        </Modal>
        <SaveProgressModal
          isOpen={openSavedProgress}
          savingWhat="Training"
          onRequestClose={() => this.setState({ openSavedProgress: false })}
          submitActions={() => this.handleSubmit(false, true)}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeTraining: getActiveTrainingSelector(state),
  loggedInUser: getLoggedInUser(state),
  activeCompany: getActiveCompany(state)
});

const mapDispatchToProps = dispatch => ({
  setActiveTraining: payload => dispatch(setActiveTrainingResponse(payload)),
  updateTraining: payload => dispatch(updateTrainingRequest(payload)),
  setTrainingReq: payload => dispatch(setActiveTrainingRequest(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ApproveTraining);
