import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { positiveIntsOnly } from '../../../utils/formValidation';
import Checkbox from '../../inputs/Checkbox';
import DateUnitPicker from '../../inputs/DateUnitPicker';
import { Textbox, EmployeeDropdown, Dropdown } from '../../inputs';
import { TRAINING_CATEGORY_DROPDOWN } from '../../../constants/constants';
import Button from '../../../components/inputs/Button';
import history from '../../../history';
import { setActiveTrainingResponse } from '../../../actions/training';
import { getLoggedInUser } from '../../../selectors/users';

import styles from './trainingTemplate.module.scss';
import { TrainingMaterials } from '../../../containers/Training/TrainingMaterials';

export default function TrainingTemplate(props) {
  const {
    title,
    instructions,
    needsApproval,
    doesExpire,
    expiresAfterNumber,
    expiresAfterOption,
    isEditing,
    isCreating,
    updateAnswer,
    disabled,
    approver,
    trainingType,
    quizMasterId,
    isTemplateCreate,
    permissions,
    category,
    fromApproveTraining,
    deleteAttachment,
    addAttachments,
    hasAttachments,
    addedAttachments,
    attachments,
    youtubeLinks,
    quizDropdown,
    titleList,
    createdByUserId,
    isMasterTraining,
    equipmentId
  } = props;

  const activeUser = useSelector(getLoggedInUser);
  const dispatch = useDispatch();
  const validTitleLength = title ? title.trim().length > 2 : true;

  const isTitleValid = title => {
    let valid;
    if (title !== '') {
      valid =
        titleList.filter(t => t.title.toLowerCase() === title.toLowerCase())
          .length === 0;
    }
    return valid;
  };

  const canAddMaterials =
    isEditing &&
    isMasterTraining &&
    (activeUser.accessLevel === 900 ||
      activeUser.accessLevel === 500 ||
      activeUser._id === createdByUserId);

  return (
    <>
      {isTemplateCreate ? (
        <Textbox
          fieldLabel="Template Title (if textbox turns red, title is not available)"
          placeholder="Name of the template, e.g. Forklift Training"
          currentValue={title}
          onChange={(values, e) => {
            if (values.length > 2) {
              e.target.style.background = isTitleValid(e.target.value)
                ? 'lightgreen'
                : 'lightcoral';
            }
            updateAnswer({ title: e.target.value, hasUnsavedChanges: true });
          }}
          disabled={!isCreating || disabled}
          name="templateTitle"
          isRequired
          warning={
            validTitleLength ? '' : 'Title must be at least 3 characters long'
          }
          touched={!validTitleLength}
        />
      ) : (
        <Textbox
          fieldLabel="Training Title"
          placeholder={
            equipmentId
              ? 'Please select one of the training templates'
              : 'Name of the template, e.g. Forklift Training'
          }
          currentValue={title}
          onChange={title => updateAnswer({ title, hasUnsavedChanges: true })}
          disabled={!isCreating || disabled || equipmentId}
          name="trainingTitle"
          isRequired
          warning={
            validTitleLength ? '' : 'Title must be at least 3 characters long'
          }
          touched={!validTitleLength}
        />
      )}
      <Dropdown
        options={TRAINING_CATEGORY_DROPDOWN}
        fieldLabel="Category"
        currentValue={category}
        onChange={values => updateAnswer({ category: values })}
        disabled={(!isCreating && !isEditing) || disabled}
      />
      <Textbox
        fieldLabel="Instructions"
        type="textarea"
        placeholder="Complete steps 1 and 2"
        currentValue={instructions}
        onChange={v =>
          updateAnswer({ instructions: v, hasUnsavedChanges: true })
        }
        disabled={!isCreating && !isEditing}
      />
      <TrainingMaterials
        deleteAttachment={deleteAttachment}
        isCreating={isCreating}
        isEditing={isEditing}
        fromApproveTraining={fromApproveTraining}
        updateAnswer={updateAnswer}
        addAttachments={addAttachments}
        hasAttachments={hasAttachments}
        addedAttachments={addedAttachments}
        attachments={attachments}
        youtubeLinks={youtubeLinks}
        quizMasterId={quizMasterId}
        canAddMaterials={canAddMaterials || (isTemplateCreate && isEditing)}
        isTemplateCreate={isTemplateCreate}
        trainingType={trainingType}
      />
      {(trainingType?.value === 1 || isTemplateCreate) && (
        <Dropdown
          options={quizDropdown || []}
          fieldLabel="Quiz for this training"
          currentValue={quizMasterId}
          onChange={value => updateAnswer({ quizMasterId: value })}
          disabled={(!isCreating && !isEditing) || disabled}
          searchable
          name="quizDropdown"
          testID="Quiz for this training"
        />
      )}
      {isTemplateCreate && isCreating && (
        <Button
          text="Create New Quiz"
          color="blue"
          onClick={() => {
            dispatch(setActiveTrainingResponse({ ...props }));
            history.push({
              pathname: '/app/quiz/quizTemplate',
              state: { fromScreen: 'trainingTemplate' }
            });
          }}
          disabled={(!isCreating && !isEditing) || disabled}
          inputClassName={styles.button}
        />
      )}
      {(trainingType?.value === 1 || isTemplateCreate) && (
        <Checkbox
          fieldLabel="Approval Flow"
          name="needsApproval"
          onChange={value =>
            updateAnswer({
              needsApproval: value,
              hasUnsavedChanges: true
            })
          }
          currentValue={needsApproval}
          displayTooltip
          disabled={(!isCreating && !isEditing) || disabled}
          tooltip={
            <div>
              <p>
                When this is selected, all the trainings of this type will need
                to be approved or rejected one by one.
              </p>
              <p>
                The approver will be able to view the amount of time each
                employee spent on their training. This will not include any
                tests or quizzes.
              </p>
            </div>
          }
        />
      )}
      {needsApproval && trainingType && (
        <EmployeeDropdown
          permissions={permissions}
          fieldLabel="Approver"
          currentValue={approver}
          onChange={approver => updateAnswer({ approver })}
          disabled={!isCreating && !isEditing}
          searchable
          name="dropdownEmployees"
          isRequired
        />
      )}
      <Checkbox
        fieldLabel="Training Expires"
        name="expires"
        onChange={value =>
          updateAnswer({ doesExpire: value, hasUnsavedChanges: true })
        }
        currentValue={doesExpire}
        displayTooltip
        disabled={(!isCreating && !isEditing) || disabled}
        tooltip={
          <div>
            <p>
              This is how long the training will last after the employee
              performs it.
            </p>
            <p>This will start counting after the training due date.</p>
          </div>
        }
      />
      {doesExpire && trainingType?.value !== 3 && (
        <div className={styles.sameLine}>
          <Textbox
            fieldLabel="Expires After"
            type="number"
            onChange={v => {
              const onlyNums = positiveIntsOnly(v);
              updateAnswer({
                expiresAfterNumber: onlyNums,
                hasUnsavedChanges: true
              });
            }}
            currentValue={expiresAfterNumber}
            disabled={(!isCreating && !isEditing) || disabled}
            isRequired
            warning={
              expiresAfterNumber === '' || expiresAfterNumber > 0
                ? ''
                : 'Must be greater than 0'
            }
            touched={true}
          />
          <div className={styles.topMargin}>
            <DateUnitPicker
              currentValue={expiresAfterOption}
              onChange={values =>
                updateAnswer({
                  expiresAfterOption: values,
                  hasUnsavedChanges: true
                })
              }
              disabled={(!isCreating && !isEditing) || disabled}
              isRequired
            />
          </div>
        </div>
      )}
    </>
  );
}
