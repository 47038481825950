import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import { leftNavigation } from '../../constants/leftNavigation';
import { clearUploadedAttachments } from '../../actions/attachments';
import { signOut } from '../../actions/auth';
import {
  addDocumentFolderRequest,
  fetchQualificationRequest,
  fetchSafetyInstructionRequest,
  fetchSdsRequest,
  setActiveDocumentFolderRequest
} from '../../actions/documents';
import {
  fetchIncidentBasicsRequest,
  setActiveIncidentResponse
} from '../../actions/incidents';
import { setActiveSafetyWalk } from '../../actions/safetyWalks';
import { fetchTrainingsRequest } from '../../actions/training';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import {
  getDocumentFoldersSelector,
  getActiveFolderSelector
} from '../../selectors/documents';
import { getLoggedInUser } from '../../selectors/users';

import Button from '../../components/inputs/Button';
import LeftNavSection from '../../components/LeftNavSection/';
import Modal from '../../components/Modal';
import UserBadge from '../../components/UserBadge';
import ReportSelector from '../../components/inputs/ReportSelector';

import styles from './leftNav.module.scss';

export class LeftNav extends Component {
  state = {
    sectionClickedOn: '',
    modalOpen: false,
    reportTemplates: []
  };

  handleOpenModal = () => {
    this.setState({
      modalOpen: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      modalOpen: false,
      reportTemplates: []
    });
  };

  handleTemplateModalOpen = () => {
    this.setState({
      modalTemplateOpen: true,
      reportTemplates: []
    });
  };

  handleTemplateModalClose = () => {
    this.setState({
      modalTemplateOpen: false,
      reportTemplates: []
    });
  };

  handleSubmit = () => {
    this.props.setIncident();
    this.props.setSafetyWalk();
    this.props.clearAttachments();
    this.handleCloseModal();

    if (this.state.reportTemplates.indexOf(8) > -1) {
      history.push('/app/safetyAudits/create');
    } else if (this.state.reportTemplates.indexOf(10) > -1) {
      history.push('/app/safetyWalkContainer');
    } else if (this.state.reportTemplates.indexOf(12) > -1) {
      history.push('/app/behaviorObservation/container');
    } else {
      this.props.fetchIncidentBasics({
        reportTemplateIds: this.state.reportTemplates
      });
    }
  };

  handleSectionOpen = sectionClickedOn => {
    this.setState({ sectionClickedOn });
  };

  render() {
    const {
      loggedInUser,
      activeCompany,
      activeLocation,
      activeProject,
      documentFolders,
      addDocumentFolder,
      setActiveFolder,
      fetchSds,
      fetchQualifications,
      fetchSafetyInstructions,
      showLeftNav,
      logOut,
      fetchTrainings,
      activeSection,
      activeFolder
    } = this.props;

    const { sectionClickedOn, modalOpen, reportTemplates } = this.state;
    return (
      <div className={showLeftNav ? styles.sidebar : styles.hideSidebar}>
        <aside className={showLeftNav ? styles.sections : styles.hideSections}>
          <Button
            text="New Report"
            color="green"
            onClick={() => this.handleOpenModal()}
            testID="newReport"
          />
          {leftNavigation.sections.map((navSection, index) => {
            return navSection.accessLevel.indexOf(loggedInUser.accessLevel) !==
              -1 ? (
              <LeftNavSection
                testID={navSection.title}
                activeSection={
                  index === activeSection?.[0] ? activeSection[1] : null
                }
                activeSubSection={index}
                title={navSection.title}
                section={navSection.section}
                key={index}
                accessLevel={loggedInUser.accessLevel}
                loggedInUser={loggedInUser}
                sectionOpen={
                  parseInt(sectionClickedOn) >= 0
                    ? sectionClickedOn === index
                    : activeSection?.[0] === index
                }
                openNewSection={this.handleSectionOpen}
                activeCompany={activeCompany}
                activeLocation={activeLocation}
                activeProject={activeProject}
                documentFolders={documentFolders}
                addDocumentFolder={addDocumentFolder}
                setActiveFolder={setActiveFolder}
                fetchSds={fetchSds}
                fetchQualifications={fetchQualifications}
                fetchSafetyInstructions={fetchSafetyInstructions}
                fetchTrainings={fetchTrainings}
                activeFolder={activeFolder}
              />
            ) : (
              ''
            );
          })}
        </aside>
        <div className={styles.bottomNav}>
          <UserBadge
            userId={loggedInUser._id}
            size="x-large"
            showName
            showImage
            nameUnder
          />
          <Button
            testID="profile"
            text="Profile"
            color="blue"
            onClick={() => history.push('/app/profile')}
          />
          <Button
            text="Logout"
            color="white"
            testID="logoutButton"
            onClick={() => logOut()}
          />
        </div>
        {modalOpen && (
          <Modal
            title="Type of Incident (one or more)"
            titleClassName="blueHeader"
            wide
            isOpen={modalOpen}
            className="modalBig"
            submitButtonColor="green"
            submitButtonText="Create"
            onRequestClose={() => this.handleCloseModal()}
            submitActions={() => this.handleSubmit()}
            disableSubmit={reportTemplates.length === 0}
          >
            <ReportSelector
              accessLevel={loggedInUser.accessLevel}
              currentValue={reportTemplates}
              onChange={values => this.setState({ reportTemplates: values })}
              activeCompany={activeCompany}
            />
          </Modal>
        )}
      </div>
    );
  }
}
export const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state),
  activeCompany: getActiveCompany(state),
  activeLocation: getActiveLocationId(state),
  activeProject: getActiveProject(state),
  documentFolders: getDocumentFoldersSelector(state),
  activeFolder: getActiveFolderSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  addDocumentFolder: values => dispatch(addDocumentFolderRequest(values)),
  setActiveFolder: values => dispatch(setActiveDocumentFolderRequest(values)),
  fetchSds: () => dispatch(fetchSdsRequest({ page: 1, limit: 10000 })),
  fetchQualifications: () =>
    dispatch(fetchQualificationRequest({ page: 1, limit: 10000 })),
  fetchSafetyInstructions: () =>
    dispatch(fetchSafetyInstructionRequest({ page: 1, limit: 10000 })),
  logOut: () => dispatch(signOut()),
  fetchIncidentBasics: reportTypes =>
    dispatch(fetchIncidentBasicsRequest(reportTypes)),
  setIncident: () => dispatch(setActiveIncidentResponse({})),
  clearAttachments: () => dispatch(clearUploadedAttachments()),
  setSafetyWalk: () => dispatch(setActiveSafetyWalk({})),
  fetchTrainings: () => dispatch(fetchTrainingsRequest())
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftNav);
