import React, { useState } from 'react';
import {
  fetchSafetyAuditExceptionReport,
  downloadSafetyAuditExceptionList,
  fetchSafetyAuditExceptionTitles
} from '../../api/v4';
import Card from '../../components/Card';
import { Button, Dropdown } from '../../components/inputs';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import HierarchySelector from '../../components/HierarchySelector';
import List from '../../components/List';
import RemoveExceptionItemModel from '../../components/Modal/removeExcptionItemModal';
import { TwoColumn } from '../../components/inputs';
import BlockText from '../../components/inputs/BlockText';
import { SingleDatePicker } from 'react-dates';
import {
  EXCEPTION_REPORTING_FREQUENCY_OPTIONS,
  SAFETY_AUDIT_STAGES
} from '../../constants/constants';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import styles from './styles.module.scss';
import moment from 'moment';

export default function SafetyAuditReport() {
  const { company, location, project } = useActiveHeirarchy();

  const [dateRange, setDateRange] = useState(null);
  const [auditTitles, setAuditTitles] = useState([]);
  const [nonValidTitles, setNonValidTitles] = useState([]);
  const [selectedAudits, setSelectedAudits] = useState();
  const [groupIds, setGroupIds] = useState([]);
  const [projectIds, setProjectIds] = useState([]);
  const [stages, setStages] = useState([]);
  const [exceptionData, setExceptionData] = useState([]);
  const [exceptionColumns, setExceptionColumns] = useState([]);
  const [hasRanReport, setHasRanReport] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [rangeStartDate, setRangeStartDate] = useState(null);
  const [rangeStartDateFocused, setRangeStartDateFocused] = useState(false);
  const [rangeEndDate, setRangeEndDate] = useState(null);
  const [rangeEndDateFocused, setRangeEndDateFocused] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  const [tempDateRange, setTempDateRange] = useState(null);
  const [tempRangeStartDate, setTempRangeStartDate] = useState(null);
  const [tempRangeEndDate, setTempRangeEndDate] = useState(null);
  const [dateRangeErrorMessage, setDateRangeErrorMessage] = useState(null);

  const handleExportTable = async () => {
    const src = await downloadSafetyAuditExceptionList({
      dateRange,
      startDate: rangeStartDate,
      endDate: rangeEndDate,
      auditTitles: selectedAudits,
      stages,
      groupIds,
      projectIds
    });
    window.location = src;
  };

  const handleRunReport = () => {
    fetchSafetyAuditExceptionReport({
      dateRange,
      startDate: rangeStartDate,
      endDate: rangeEndDate,
      auditTitles: selectedAudits,
      stages,
      groupIds,
      projectIds
    }).then(response => {
      setExceptionData(response);
      getColumns();
      setHasRanReport(true);
      setHasChanges(false);
    });
  };

  const getColumns = () => {
    let columns = [
      {
        key: 'assignee',
        label: 'Employee Name',
        datatype: 'users'
      },
      { key: 'assigned', label: '# Assigned', disableFilterBy: true },
      { key: 'completed', label: '# Completed', disableFilterBy: true }
    ];

    setExceptionColumns(columns);
  };

  const handleCustomDateChange = async (start, end, type = 'end') => {
    let titles;

    if (type === 'start') {
      setTempRangeStartDate(start);
      if (!selectedAudits?.length) setRangeStartDate(start);
    } else {
      setTempRangeEndDate(end);
      if (!selectedAudits?.length) setRangeEndDate(end);
    }
    if (start && end) {
      if (moment(start).isBefore(end)) {
        titles = await fetchSafetyAuditExceptionTitles({
          dateRange: 'customRange',
          startDate: start,
          endDate: end
        });
        if (selectedAudits?.length) {
          // checkForRemovedTitles(titles, 'customRange', start, end);
          setDateRangeErrorMessage('');
        } else {
          setAuditTitles(titles);
          setDateRange('customRange');
          setDateRangeErrorMessage('');
          setRangeEndDate(end);
          setRangeStartDate(start);
        }
      } else {
        setDateRangeErrorMessage('Invalid Date Range');
      }
    }
  };

  const handleDateChange = async value => {
    let titles;
    if (value !== 'customRange') {
      titles = await fetchSafetyAuditExceptionTitles({ dateRange: value });
      setTempDateRange(value);
      handleTitlesForDateChange(value, titles);
      setExceptionData([]);
    } else {
      if (!selectedAudits?.length) {
        setDateRange(value);
        // make sure values get reset
        setTempRangeStartDate(null);
        setTempRangeEndDate(null);
        setRangeStartDate(null);
        setRangeEndDate(null);
      } else {
        setTempDateRange(value);
        setRangeStartDate(null);
        setRangeEndDate(null);
      }
    }
    setHasRanReport(false);
  };

  const handleTitlesForDateChange = (date, titles) => {
    setDateRange(date);
    setAuditTitles(titles);
  };

  const handleSubmitRemoval = () => {
    setDateRange(tempDateRange);
    setOpenRemoveModal(false);
    setHasChanges(true);
    setRangeEndDate(tempRangeEndDate);
    setRangeStartDate(tempRangeStartDate);
    setTempDateRange(null);
  };

  const handleCancelRemoval = () => {
    setOpenRemoveModal(false);
    setNonValidTitles([]);
    setTempRangeStartDate(rangeStartDate);
    setTempRangeEndDate(rangeEndDate);
    setTempDateRange(null);
  };

  const handleGroupChange = values => {
    const validProjectIds = company.groups
      .map(g => {
        if (values.includes(g._id)) {
          return g.projects.map(p => p._id);
        } else {
          return [];
        }
      })
      .reduce((acc, x) => acc.concat(x), []);
    setGroupIds(values);

    const newProjectIds = projectIds.filter(p => validProjectIds.includes(p));
    setProjectIds(newProjectIds);
    if (hasRanReport) setHasChanges(true);
  };

  const header = (
    <Header
      title="Safety Audit Report"
      rightButtons={{
        color: 'blue',
        text: 'Export Table',
        disabled: !hasRanReport,
        onClick: () => handleExportTable()
      }}
    />
  );

  const canRun = dateRange && selectedAudits?.length > 0;

  return (
    <HeaderAndFooter Header={header}>
      <Card>
        <TwoColumn>
          {dateRange === 'customRange' || tempDateRange === 'customRange' ? (
            <TwoColumn>
              <Dropdown
                options={EXCEPTION_REPORTING_FREQUENCY_OPTIONS}
                fieldLabel="Date"
                currentValue={tempDateRange ?? dateRange}
                onChange={value => handleDateChange(value)}
                isRequired
              />
              <div
                className={{ display: 'flex', flexDirection: 'column-reverse' }}
              >
                <div className={styles.datePicker}>
                  <SingleDatePicker
                    date={tempRangeStartDate ?? rangeStartDate}
                    onDateChange={value =>
                      selectedAudits?.length > 0
                        ? handleCustomDateChange(
                            value,
                            tempRangeEndDate,
                            'start'
                          )
                        : handleCustomDateChange(value, rangeEndDate, 'start')
                    }
                    focused={rangeStartDateFocused}
                    onFocusChange={f => setRangeStartDateFocused(f.focused)}
                    placeholder="Start"
                    isOutsideRange={d => false}
                  />
                  <SingleDatePicker
                    date={tempRangeEndDate ?? rangeEndDate}
                    onDateChange={value =>
                      selectedAudits?.length > 0
                        ? handleCustomDateChange(tempRangeStartDate, value)
                        : handleCustomDateChange(rangeStartDate, value)
                    }
                    focused={rangeEndDateFocused}
                    onFocusChange={f => setRangeEndDateFocused(f.focused)}
                    placeholder="End"
                    isOutsideRange={d => false}
                  />
                </div>
                <span className={styles.dateWarning}>
                  {dateRangeErrorMessage}
                </span>
              </div>
            </TwoColumn>
          ) : (
            <Dropdown
              options={EXCEPTION_REPORTING_FREQUENCY_OPTIONS}
              fieldLabel="Date"
              currentValue={tempDateRange ?? dateRange}
              onChange={value => handleDateChange(value)}
              isRequired
            />
          )}
          <Dropdown
            options={auditTitles}
            fieldLabel="Safety Audit Title"
            currentValue={selectedAudits}
            onChange={value => {
              setSelectedAudits(value);
              if (hasRanReport) setHasChanges(true);
            }}
            isRequired
            disabled={!dateRange?.length}
            selectButtons
            searchable
          />
        </TwoColumn>
        <HierarchySelector
          onGroupChange={values => handleGroupChange(values)}
          groupId={location ? [location._id] : groupIds}
          groupDisabled={
            dateRange?.length < 1 || selectedAudits?.length < 1 || location._id
          }
          onProjectChange={values => {
            setProjectIds(values);
            if (hasRanReport) setHasChanges(true);
          }}
          projectId={project ? [project._id] : projectIds}
          projectDisabled={project ?? (groupIds?.length < 1 && !location)}
          groupRequired={false}
          multi
          alwaysShowGroupDropdown
          alwaysShowProjectDropdown
        />
        <Dropdown
          searchable
          options={SAFETY_AUDIT_STAGES}
          fieldLabel="Stage"
          currentValue={stages}
          onChange={value => {
            setStages(value);
            if (hasRanReport) setHasChanges(true);
          }}
          disabled={dateRange?.length < 1 || selectedAudits?.length < 1}
          placeholder="Choose one or more options"
          multi
        />
        <BlockText blockOfText="If a user has multiple audits with the same template and due date the average completion will be shown" />
        <Button
          color="blue"
          text={hasRanReport ? 'Update Report' : 'Run Report'}
          onClick={handleRunReport}
          disabled={
            (!hasRanReport && !canRun) ||
            (hasRanReport && (!hasChanges || !canRun))
          }
          className={styles.button}
        />
      </Card>
      <Card>
        {canRun && hasRanReport ? (
          <List data={exceptionData} dataIsHash settings={exceptionColumns} />
        ) : (
          <div className={styles.messageText}>
            Fill fields above to generate report
          </div>
        )}
      </Card>
      <RemoveExceptionItemModel
        isOpen={openRemoveModal}
        onRequestClose={() => handleCancelRemoval()}
        submitActions={() => handleSubmitRemoval()}
        itemsToRemove={nonValidTitles}
        removeWhat="Safety Audits"
      />
    </HeaderAndFooter>
  );
}
