import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames/bind';

import history from '../../history';
import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments,
  deleteAttachmentRequest
} from '../../actions/attachments';
import {
  createMyDocumentRequest,
  deleteMyDocumentRequest,
  setActiveMyDocument,
  updateMyDocumentRequest
} from '../../actions/documents';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import { getActiveMyDocumentSelector } from '../../selectors/documents';
import { getLoggedInUser } from '../../selectors/users';
import Button from '../../components/inputs/Button';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../components/Footer';
import Modal from '../../components/Modal';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import Textbox from '../../components/inputs/Textbox';
import { isImage, isPDF } from '../../utils/attachmentSelector';
import Carousel from '../../components/Carousel';
import Card from '../../components/Card';

import styles from './myDocumentContainer.module.scss';
const bStyles = classnames.bind(styles);

class MyDocumentContainer extends Component {
  state = {
    isCreating: false,
    hasUnsavedChanges: false,
    isEditing: false,
    selectedAttachment: 0,
    label: '',
    description: '',
    openDeleteDocumentModal: false,
    openDiscardDocumentModal: false,
    openUnsavedChangesModal: false,
    images: []
  };

  componentDidMount() {
    if (!this.props.activeMyDoc._id) {
      this.setState({ isCreating: true });
    } else {
      this.setState({
        label: this.props.activeMyDoc.label,
        description:
          this.props.activeMyDoc.documentData &&
          this.props.activeMyDoc.documentData.description,
        images: this.props.activeMyDoc.attachments
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.addedAttachments &&
      this.props.addedAttachments !== prevProps.addedAttachments
    ) {
      this.setState({
        images: [
          ...this.props.activeMyDoc.attachments,
          ...this.props.addedAttachments
        ]
      });
    }
  }

  handleDocumentUpload = async e => {
    const files = e.target.files;
    if (!files.length) return;

    let data = new FormData();
    Object.entries(files).forEach(async file => {
      if (isImage(file[1].name) || isPDF(file[1].name)) {
        data.append('attachments', file[1], file[1].name);
      } else {
        alert('Needs to be either an image file or a PDF file.');
      }
    });

    if (data.get('attachments') !== null) {
      await this.props.addAttachment({
        data,
        ownerId: this.props.loggedInUser?._id,
        ownerType: 'EmployeeDocument'
      });
    }

    this.setState({ hasUnsavedChanges: true });
  };

  canSubmit = () => {
    const { label, description, images } = this.state;

    if (label && description && (images.length > 0 || images.length > 0)) {
      return true;
    }

    return false;
  };

  handleSubmit = () => {
    const { label, description } = this.state;
    const { activeMyDoc, match } = this.props;
    if (activeMyDoc._id) {
      this.props.updateMyDocument({ ...activeMyDoc, label, description });
    } else {
      this.props.createMyDocument({
        label,
        description,
        userId: match.params.userId
      });
    }
  };

  handleDeleteDocument = () => {
    this.props.deleteMyDocument(this.props.activeMyDoc);
    this.props.clearUploadedAttachments();
  };

  handleNavigateAway = () => {
    this.props.setActiveMyDocument({ attachments: [] });
    this.props.clearUploadedAttachments();
    history.goBack();
  };

  handleDeleteAttachment = attachment => {
    const updatedImages = this.props.activeMyDoc.attachments.filter(
      i => i._id !== attachment._id
    );
    this.props.setActiveMyDocument({
      ...this.props.activeMyDoc,
      attachments: updatedImages
    });
    this.props.deleteAttachment(attachment);
  };

  render() {
    const {
      isCreating,
      hasUnsavedChanges,
      isEditing,
      label,
      description,
      openDeleteDocumentModal,
      openDiscardDocumentModal,
      openUnsavedChangesModal,
      images
    } = this.state;

    const header = (
      <Header
        title="My Documents"
        section={
          !isEditing && !isCreating
            ? 'View Document'
            : isCreating
            ? 'Create Document'
            : 'Edit Document'
        }
        needsSaved={hasUnsavedChanges}
        clickBack={() =>
          hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : history.goBack()
        }
        rightButtons={{
          visible: !isCreating,
          text: isEditing ? 'Delete Document' : 'Edit Document',
          color: isEditing ? 'red' : 'blue',
          onClick: () =>
            isEditing
              ? this.setState({ openDeleteDocumentModal: true })
              : this.setState({ isEditing: true })
        }}
      />
    );

    const footer = (
      <SaveCancelFooter
        saveButtonDisabled={!this.canSubmit()}
        saveButtonClick={this.handleSubmit}
        cancelButtonText={isCreating ? 'Discard' : 'Cancel'}
        cancelButtonColor={isCreating ? 'red' : 'white'}
        cancelButtonClick={() =>
          isCreating
            ? this.setState({ openDiscardDocumentModal: true })
            : isEditing && hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : history.goBack()
        }
        editing={isEditing}
      />
    );

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          showFooter={isEditing || isCreating}
        >
          <Card>
            {images.length > 0 && (
              <Carousel
                items={images}
                deleteAction={this.handleDeleteAttachment}
                canDelete={true}
              />
            )}
            {(isEditing || isCreating) && (
              <div className={styles.addDocs}>
                <Button
                  text="Add Attachments"
                  color="blue"
                  testID="Add Attachments"
                  onClick={() => document.getElementById('myDocument').click()}
                />
                {images.length === 0 && (
                  <div className={styles.requiredDocs}>
                    *At least 1 attachment is required
                  </div>
                )}
              </div>
            )}
            <input
              type="file"
              id="myDocument"
              onChange={e => {
                this.handleDocumentUpload(e);
                e.target.value = '';
              }}
              multiple
              data-cy="customDocAttachment"
            />
            <Textbox
              fieldLabel="Document Title"
              placeholder="Type a clear and consise title."
              isRequired={true}
              onChange={e =>
                this.setState({
                  label: e,
                  hasUnsavedChanges: true
                })
              }
              currentValue={label}
              disabled={!isEditing && !isCreating}
              className={bStyles({
                padding: isCreating || isEditing || images.length > 0
              })}
            />
            <Textbox
              type="textarea"
              rows={5}
              fieldLabel="Description"
              placeholder="Describe this document. Be sure to include any important details."
              onChange={e =>
                this.setState({
                  description: e,
                  hasUnsavedChanges: true
                })
              }
              currentValue={description}
              disabled={!isEditing && !isCreating}
              isRequired={true}
            />
          </Card>
        </HeaderAndFooter>
        <Modal
          title="Delete Document"
          titleClassName="redHeader"
          isOpen={openDeleteDocumentModal}
          submitButtonColor="red"
          submitButtonText="Delete Document"
          onRequestClose={() =>
            this.setState({ openDeleteDocumentModal: false })
          }
          submitActions={() => this.handleDeleteDocument()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Deleting the Document will delete it for you as well as anyone who
              may be associated with it.
            </span>
          </div>
        </Modal>
        <Modal
          title="Discard Document"
          titleClassName="redHeader"
          isOpen={openDiscardDocumentModal}
          submitButtonColor="red"
          submitButtonText="Discard Document"
          onRequestClose={() =>
            this.setState({ openDiscardDocumentModal: false })
          }
          submitActions={this.handleNavigateAway}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Discarding the Document will delete all information that has been
              gathered and pictures taken.
            </span>
          </div>
        </Modal>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={this.handleNavigateAway}
          savingWhat="a page"
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeMyDoc: getActiveMyDocumentSelector(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  loggedInUser: getLoggedInUser(state)
});

const mapDispatchToProps = dispatch => ({
  setActiveMyDocument: payload => dispatch(setActiveMyDocument(payload)),
  addAttachment: payload => dispatch(addUploadedAttachmentsRequest(payload)),
  createMyDocument: payload => dispatch(createMyDocumentRequest(payload)),
  updateMyDocument: payload => dispatch(updateMyDocumentRequest(payload)),
  deleteMyDocument: payload => dispatch(deleteMyDocumentRequest(payload)),
  clearUploadedAttachments: () => dispatch(clearUploadedAttachments()),
  deleteAttachment: payload => dispatch(deleteAttachmentRequest(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyDocumentContainer);
