import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { states } from '../../constants/states';
import {
  booleanRequired,
  renderDropdownField,
  renderField,
  renderNumberField,
  renderRadioButtons,
  required,
  validNAICS,
  validSIC,
  validZip
} from '../../utils/formValidation';
import { InputRow } from '../../components/inputs';

class NewLocationForm extends Component {
  render() {
    const { handleSubmit, sepEstablishment, isPerrp } = this.props;
    const perrpOrDash = isPerrp ? 'PERRP' : 'OSHA';
    return (
      <form className="newLocationForm" onSubmit={handleSubmit}>
        <Field
          name="name"
          type="text"
          component={renderField}
          label="Group/Establishment Name"
          validate={[required]}
          placeholder="e.g. Mark's Team/Tallahassee District Office"
          requiredLabel={true}
        />
        <Field
          name="isSeparateEstablishment"
          component={renderRadioButtons}
          label={`Does this require its own ${perrpOrDash} Report?`}
          validate={[booleanRequired]}
          requiredLabel={true}
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' }
          ]}
          displayToolTip={true}
        >
          <p>
            Single physical location where work is done, or that supervises/acts
            as a base for personnel that don’t work at a single location.
          </p>
          <p> &mdash; With &mdash; </p>
          <p>A separate address from the associated company division.</p>
        </Field>
        <div style={{ display: sepEstablishment ? 'block' : 'none' }}>
          <Field
            label="Establishment Address"
            name="streetAddress"
            type="text"
            component={renderField}
            requiredLabel={true}
            placeholder="Street Address"
            validate={sepEstablishment ? [required] : []}
          />
          <InputRow>
            <Field
              name="city"
              type="text"
              component={renderField}
              placeholder="City"
              validate={sepEstablishment ? [required] : []}
              testID="city"
            />
            <Field
              name="state"
              type="text"
              component={renderDropdownField}
              placeholder="State"
              options={states}
              searchable={true}
              testID="state"
            />
            <Field
              name="zip"
              type="text"
              component={renderField}
              placeholder="ZIP Code"
              validate={sepEstablishment ? [required, validZip] : []}
              testID="zipCode"
            />
          </InputRow>
          <Field
            name="operationalForYearOrLonger"
            component={renderRadioButtons}
            label="Has or will this establishment be operational for a year or longer?"
            validate={sepEstablishment ? [booleanRequired] : []}
            requiredLabel={true}
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' }
            ]}
          />
          <InputRow>
            <Field
              name="NAICS"
              component={renderNumberField}
              label="NAICS"
              placeholder="000000"
              requiredLabel={true}
              validate={sepEstablishment ? [required, validNAICS] : []}
              displayToolTip={true}
              testID="NaicsNumber"
            >
              <p>North American Industrial Classification</p>
            </Field>
            <Field
              name="SIC"
              component={renderNumberField}
              label="SIC"
              placeholder="0000"
              warn={validSIC}
              displayToolTip={true}
            >
              <p>Standard Industrial Classification</p>
            </Field>
          </InputRow>
        </div>
      </form>
    );
  }
}

NewLocationForm = reduxForm({
  form: 'NewLocationForm',
  enableReinitialize: true
})(NewLocationForm);

const selector = formValueSelector('NewLocationForm');

const mapStateToProps = state => ({
  sepEstablishment: selector(state, 'isSeparateEstablishment')
});

export default connect(mapStateToProps)(NewLocationForm);
