import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import { createDocumentQRCodeSheet } from '../../api/v4';
import { getAccessLevel } from '../../selectors/users';
import AddToFolderModal from '../../components/Modal/addToFolderModal';
import DocumentFolder from '../../components/DocumentFolder';
import Modal from '../../components/Modal';
import SdsForm from '../../forms/SdsForm';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import List from '../../components/List';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments,
  deleteAttachmentRequest
} from '../../actions/attachments';
import {
  addDocumentsToFolderRequest,
  addSdsRequest,
  fetchDocumentFoldersRequest,
  fetchSdsRequest,
  setActiveSds
} from '../../actions/documents';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import {
  getCompanySdsSelector,
  getDocumentFoldersSelector
} from '../../selectors/documents';

export class SdsList extends Component {
  state = {
    modalOpen: false,
    sds: {
      label: '',
      expires: '',
      ghsLabels: [],
      locationId: [],
      projectId: [],
      documentFolderId: ''
    },
    addToFolder: [],
    addToFolderModal: false,
    documentFolderId: ''
  };

  componentDidMount = () => {
    this.props.fetchSds();
    this.props.fetchDocumentFolders({
      documentType: 'SDS'
    });
  };

  handleOpenModal() {
    this.setState({ modalOpen: true });
  }

  handleCloseModal() {
    this.setState({ modalOpen: false });
  }

  updateSds = values => {
    this.setState(state => ({ sds: { ...state.sds, ...values } }));
  };

  handleSubmit = () => {
    this.props.addSds(this.state.sds);
    this.props.clearAttachment();
    this.handleCloseModal();
  };

  handleAddMultipleToFolders = () => {
    const { sdsList } = this.props;
    let addDocsToFolder = [];

    const addToFolder = this.state.addToFolder;

    sdsList.forEach(document => {
      if (addToFolder.indexOf(document._id) > -1) {
        addDocsToFolder.push(document);
      }
    });

    this.props.addDocumentToFolder({
      documents: addDocsToFolder,
      documentFolderId: this.state.documentFolderId
    });

    this.setState({
      addToFolderModal: false,
      addToFolder: [],
      documentFolderId: ''
    });
  };

  createQRCodes = ids => {
    createDocumentQRCodeSheet(ids).then(response =>
      window.open(response, '_blank')
    );
  };

  render() {
    const {
      sdsList,
      setSds,
      activeCompany,
      addAttachment,
      clearAttachment,
      loading,
      deleteAttachment,
      addedAttachments,
      accessLevel,
      activeProject,
      activeLocation,
      documentFolders
    } = this.props;

    const { modalOpen, sds, addToFolderModal, documentFolderId } = this.state;
    const folderList = documentFolders.map(folder => {
      return {
        value: folder._id,
        label: folder.name
      };
    });

    const columns = [
      { key: 'label', label: 'Title' },
      { key: 'attachments', datatype: 'attachments' },
      { key: 'expires', label: 'Expiration Date', datatype: 'futureDate' }
    ];

    if (!activeLocation) {
      columns.unshift({
        key: 'groupIds',
        label: 'Group/Est.',
        datatype: 'groups'
      });
    }

    const header = (
      <Header
        title="SDS"
        rightButtons={{
          color: 'green',
          text: 'Add SDS',
          onClick: () => this.handleOpenModal(),
          visible: accessLevel > 100
        }}
      />
    );

    return (
      <>
        <HeaderAndFooter Header={header}>
          <ScrollToTopOnMount />
          <DocumentFolder content={sdsList} docType="Safety Data Sheet" />
          <List
            saveKey="sdsList"
            data={sdsList}
            dataIsHash
            settings={columns}
            actions={[
              {
                label: 'Add to Folder',
                color: 'blue',
                onClick: ids =>
                  this.setState({
                    addToFolder: ids,
                    addToFolderModal: true
                  })
              },
              {
                label: 'Download QR Codes',
                color: 'green',
                onClick: ids => this.createQRCodes(ids)
              }
            ]}
            getRowId={r => r._id}
            rowClick={row => {
              setSds(row);
              history.push('/app/viewEdit/SDS');
            }}
          />
        </HeaderAndFooter>
        {modalOpen && (
          <Modal
            title="Add SDS"
            titleClassName="greenHeader"
            wide
            submitButtonColor="green"
            isOpen={modalOpen}
            onRequestClose={() => clearAttachment() && this.handleCloseModal()}
            submitActions={() => this.handleSubmit()}
            disableSubmit={sds?.label?.length < 3}
            testID="sds"
          >
            <SdsForm
              activeCompanyId={activeCompany._id}
              addAttachment={addAttachment}
              loading={loading}
              sds={{}}
              handleSdsSelection={values => this.updateSds(values)}
              locationList={activeCompany.groups}
              deleteAttachment={deleteAttachment}
              addedAttachments={addedAttachments}
              activeLocation={activeLocation}
              activeProject={activeProject}
              documentFolders={documentFolders}
            />
          </Modal>
        )}
        <AddToFolderModal
          isOpen={addToFolderModal}
          folderId={documentFolderId}
          onRequestClose={() => this.setState({ addToFolderModal: false })}
          submitActions={this.handleAddMultipleToFolders}
          folderOptions={folderList}
          onChange={value => this.setState({ documentFolderId: value })}
        />
      </>
    );
  }
}

export const mapStateToProps = state => ({
  sdsList: getCompanySdsSelector(state),
  activeCompany: getActiveCompany(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  activeProject: getActiveProject(state),
  activeLocation: getActiveLocationId(state),
  documentFolders: getDocumentFoldersSelector(state),
  accessLevel: getAccessLevel(state)
});

export const mapDispatchToProps = dispatch => ({
  fetchSds: paginationInfo => dispatch(fetchSdsRequest(paginationInfo)),
  addSds: sds => dispatch(addSdsRequest(sds)),
  setSds: sds => dispatch(setActiveSds(sds)),
  addAttachment: attachment =>
    dispatch(addUploadedAttachmentsRequest(attachment)),
  clearAttachment: () => dispatch(clearUploadedAttachments()),
  deleteAttachment: values => dispatch(deleteAttachmentRequest(values)),
  fetchDocumentFolders: values => dispatch(fetchDocumentFoldersRequest(values)),
  addDocumentToFolder: values => dispatch(addDocumentsToFolderRequest(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(SdsList);
