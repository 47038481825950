import React from 'react';
import ReactTooltip from 'react-tooltip';

import history from '../../history';
import UserBadge from '../UserBadge';
import { noBubble } from '../../utils/events';

import './index.css';

const CompanyLogo = ({
  company,
  setActiveCompany,
  setActiveLocation,
  allLocations,
  setActiveGroups,
  setActiveProject,
  user,
  assignIncidentOwner
}) => {
  const restricted = user.accessLevel !== 900;
  const handleLogoClick = () => {
    setActiveCompany(company);
    setActiveLocation(0);
    setActiveGroups(company.groups);
    allLocations(company.allLocations);
    setActiveProject();
    history.push('/app/dashboard');
  };

  return (
    <div
      className={restricted ? 'companyLogo-noAccess' : 'companyLogo'}
      data-for={`${company._id}`}
      data-tip="customTheme"
      onClick={!restricted && handleLogoClick}
    >
      {company.logoUrl && (
        <img
          src={company.logoUrl}
          alt={`${company.name}'s Logo`}
          className="companyLogoImage"
        />
      )}
      <div className="companyLogo-company">
        <UserBadge
          size="medium"
          userId={company?.defaultIncidentOwner?.userId}
          showImage
          hoverName
          className="companyLogoHover"
          onClick={e => {
            noBubble(e);
            if (!restricted)
              assignIncidentOwner(company.defaultIncidentOwner?.userId ?? '');
          }}
        />
        <div
          className={
            restricted
              ? 'companyLogo-companyDivWithoutHover'
              : 'companyLogo-companyDiv'
          }
        >
          {company.name}
        </div>
        {!restricted && (
          <div className="companyLogo-companyDivHover">
            {company.defaultIncidentOwner ? 'Reassign' : 'Assign'} Incident
            Owner
          </div>
        )}
      </div>
      {restricted && (
        <ReactTooltip className="customTheme" id={`${company._id}`}>
          *Permission Restricted to lower levels
        </ReactTooltip>
      )}
    </div>
  );
};
export default CompanyLogo;
