import React, { Component } from 'react';
import { DOCUMENT_TYPES_CUSTOMIZABLE } from '../../../constants/constants';
import Button from '../../inputs/Button';
import Checkbox from '../../inputs/Checkbox';
import Dropdown from '../../inputs/Dropdown';
import Modal from '../../Modal';
import Textbox from '../../inputs/Textbox';
import { InputRow } from '../../inputs';
import HoverImage from '../../HoverImage';
import Card from '../../Card';
import { cloneDeep } from 'lodash';

import styles from './customDocCard.module.scss';
import { Rearranger } from '../../Rearranger';

export default class CustomDocumentCard extends Component {
  state = {
    modalOpen: false,
    activeOptions: [],
    rowIndex: 0,
    selectedFieldType: '',
    dragIdx: null,
    dragTargetIdx: null
  };

  handleQuestionChange = (index, questionLabel) => {
    let fields = [...this.props.fields];
    let answers = questionLabel.answer;

    if (questionLabel.field === 'options') {
      answers = answers.filter(answer => answer.option);
    }

    fields[index][questionLabel.field] = answers;

    this.setState({
      activeOptions: [],
      rowIndex: '',
      modalOpen: false,
      selectedFieldType: ''
    });

    this.props.onChange({ fields });
  };

  addRowsToTemplate = numberOfRows => {
    let fields = [...this.props.fields];

    for (let i = 1; i <= numberOfRows; i++) {
      fields.push({
        type: '',
        label: '',
        options: [],
        required: false
      });
    }

    this.props.onChange({ fields });
  };

  removeRowFromTemplate = index => {
    let fields = [...this.props.fields];
    if (
      this.props.isEditing &&
      fields[index].label !== '' &&
      fields[index].options.length !== 0
    ) {
      fields[index].legacy = true;
    } else {
      fields.splice(index, 1);
    }
    this.props.onChange({ fields });
  };

  setChangeOptions = (options, rowIndex, fieldType) => {
    options = options.map(option => {
      if (option.option) return option;
      if (option && this.props.duplicating)
        return {
          option,
          isEditable: true
        };
      return {
        option,
        isEditable: false
      };
    });

    this.setState({
      modalOpen: true,
      rowIndex,
      activeOptions: options,
      selectedFieldType: fieldType.value
    });
  };

  renderItem = (item, index) => {
    const notEditable = '_id' in item;
    const { disabled } = this.props;
    const buttonOptions = [
      'dropdown',
      'multiselect',
      'radiobuttons',
      'checkbox'
    ];
    const optionsIncludeItem =
      buttonOptions.includes(item.type.value) ||
      buttonOptions.includes(item.type);

    return (
      <>
        <Textbox
          currentValue={item.label}
          onChange={answer =>
            this.handleQuestionChange(index, { field: 'label', answer })
          }
          fieldLabel="Question"
          type="textarea"
          placeholder="Type your question here."
          disabled={disabled}
          testID={`question${index}`}
        />
        <Dropdown
          options={DOCUMENT_TYPES_CUSTOMIZABLE}
          fieldLabel="Type of Input"
          onChange={values =>
            this.handleQuestionChange(index, {
              field: 'type',
              answer: values
            })
          }
          isRequired
          bareValues={false}
          placeholder="Choose a question type."
          currentValue={item.type}
          disabled={disabled || notEditable}
          testID={`dropdown${index}`}
        />
        {item.type.value !== 'blockOfText' && (
          <Checkbox
            fieldLabel="Is Required"
            onChange={v =>
              this.handleQuestionChange(index, {
                field: 'required',
                answer: v
              })
            }
            currentValue={item.required}
            disabled={disabled || notEditable}
          />
        )}
        {(optionsIncludeItem ||
          item.type === 'multiselectPersonnel' ||
          item.type.value === 'multiselectPersonnel') && (
          <Checkbox
            fieldLabel="Show Question on Analytics"
            onChange={v =>
              this.handleQuestionChange(index, {
                field: 'showOnAnalytics',
                answer: v
              })
            }
            currentValue={item.showOnAnalytics}
            disabled={disabled}
          />
        )}
        {optionsIncludeItem && (
          <Button
            text="View Options"
            color="blue"
            onClick={() =>
              this.setChangeOptions(item.options, index, item.type)
            }
            disabled={disabled}
          />
        )}
        {optionsIncludeItem && item.options.length === 0 && (
          <div style={{ color: '#c74846', fontWeight: 'bold' }}>
            * Must add at least one option
          </div>
        )}
      </>
    );
  };

  render() {
    const {
      modalOpen,
      activeOptions,
      rowIndex,
      selectedFieldType
    } = this.state;
    const {
      fields,
      disabled,
      label,
      onChange,
      shouldHaveExpiredDate
    } = this.props;
    return (
      <Card title="Document Template" showHeader>
        <Textbox
          currentValue={label}
          onChange={(v, e) => onChange({ label: v }, e)}
          fieldLabel="Title"
          placeholder="Title of Document"
          disabled={disabled}
        />
        <Checkbox
          fieldLabel="Should be able to be expired"
          onChange={checked => onChange({ shouldHaveExpiredDate: checked })}
          currentValue={shouldHaveExpiredDate}
          disabled={disabled}
        />
        <Rearranger
          className={styles.container}
          items={fields}
          onChange={fields => this.props.onChange({ fields })}
          renderItem={this.renderItem}
          disabled={this.props.disabled || this.props.notEditable}
          onDuplicate={row => {
            const clone = cloneDeep(row);
            delete clone._id;
            return clone;
          }}
        />
        <InputRow className={styles.footer}>
          <Button
            text="Add Row"
            color="blue"
            onClick={() => this.addRowsToTemplate(1)}
            disabled={disabled}
          />
          <Button
            text="Add Five Rows"
            color="blue"
            onClick={() => this.addRowsToTemplate(5)}
            disabled={disabled}
          />
        </InputRow>
        <Modal
          title="Change Options"
          titleClassName="blueHeader"
          isOpen={modalOpen}
          submitButtonColor="blue"
          submitButtonText="Save Changes"
          onRequestClose={() =>
            this.setState({ modalOpen: false, activeOptions: [] })
          }
          submitActions={() =>
            this.handleQuestionChange(rowIndex, {
              field: 'options',
              answer: activeOptions
            })
          }
        >
          <div className={styles.modal}>
            {activeOptions.map((option, index) => (
              <div className={styles.options} key={index}>
                <Textbox
                  testID={`addOption${index}`}
                  currentValue={option.option}
                  onChange={v => {
                    const currentOptions = activeOptions;
                    currentOptions[index].option = v;
                    this.setState({ activeOptions: currentOptions });
                  }}
                  placeholder="Type your option here."
                  disabled={!option.isEditable && fields[rowIndex]._id}
                  className={styles.option}
                />
                {option.isEditable && (
                  <HoverImage
                    src={require('../../../assets/images/removePermissions.png')}
                    srcHover={require('../../../assets/images/removePermissonsHover.png')}
                    className={styles.removeImage}
                    alt="remove row"
                    onClick={() =>
                      this.setState({
                        activeOptions: [
                          ...activeOptions.slice(0, index),
                          ...activeOptions.slice(index + 1)
                        ]
                      })
                    }
                  />
                )}
              </div>
            ))}
            <Button
              text="Add Option"
              color="blue"
              onClick={() =>
                this.setState({
                  activeOptions: [
                    ...activeOptions,
                    { option: '', isEditable: true }
                  ]
                })
              }
              disabled={
                activeOptions.length === 2 &&
                selectedFieldType === 'radiobuttons'
              }
            />
            {activeOptions.length === 2 &&
              selectedFieldType === 'radiobuttons' && (
                <div style={{ color: '#c74846', fontWeight: 'bold' }}>
                  * Only two options are allowed for Radio Buttons
                </div>
              )}
          </div>
        </Modal>
      </Card>
    );
  }
}
