import React from 'react';
import { useState } from 'react';
import classnames from 'classnames/bind';
import { cloneDeep } from 'lodash';

import styles from './rearranger.module.scss';

export const Rearranger = ({
  className,
  items,
  onChange,
  renderItem,
  disabled,
  onDuplicate
}) => {
  const [dragIdx, setdragIdx] = useState(null);
  const [dragTargetIdx, setdragTargetIdx] = useState(null);

  const onDragStart = (e, index) => {
    setdragIdx(index);
    e.dataTransfer.dropEffect = 'move';
    e.dataTransfer.setData('text/plain', index);
  };

  const onDragOver = (e, index) => {
    if (e.preventDefault) e.preventDefault();
    setdragTargetIdx(index);
  };

  const onDragLeave = (e, index) => {
    setdragTargetIdx(null);
  };

  const onDrop = e => {
    if (e.preventDefault) e.preventDefault();
    if (dragIdx === dragTargetIdx || dragTargetIdx === null) {
      setdragIdx(null);
      setdragTargetIdx(null);
      return;
    }

    let fields = [...items];
    const tmp = fields.splice(dragIdx, 1)[0];
    let target = dragTargetIdx;
    if (target > dragIdx) target--;
    fields.splice(dragTargetIdx, 0, tmp);

    onChange(fields);

    setdragIdx(null);
    setdragTargetIdx(null);
  };

  const duplicateRow = rowIndex => {
    let fields = [...items];
    let newRowIndex = rowIndex + 1;
    var rowObject = (onDuplicate || cloneDeep)(items[rowIndex]);

    fields.splice(newRowIndex, 0, rowObject);

    onChange(fields);
  };

  const removeRowFromTemplate = index => {
    let fields = [...items];
    fields.splice(index, 1);
    onChange(fields);
  };

  return (
    <ul className={classnames(styles.container, className ? className : '')}>
      {items.map((item, index) => {
        return (
          <li
            className={classnames(
              styles.listItem,
              dragTargetIdx === index && dragIdx !== index ? styles.target : ''
            )}
            key={index}
            onDragOver={event => onDragOver(event, index)}
            onDragLeave={event => onDragLeave(event, index)}
            onDrop={event => onDrop(event, index)}
            data-cy={`card${index}`}
            draggable={!disabled}
            onDragStart={event => onDragStart(event, index)}
          >
            <div
              className={classnames(styles.item, disabled && styles.disabled)}
            >
              <div className={styles.buttonBar}>
                <img
                  src={require('../../assets/images/drag.png')}
                  alt="drag to new position"
                  className={styles.dragImage}
                />
                <div className={styles.dupAndRemoveImages}>
                  <img
                    src={require('../../assets/images/duplicate.png')}
                    alt="duplicate question"
                    onClick={() => duplicateRow(index)}
                    className={styles.duplicateImage}
                  />
                  <img
                    src={require('../../assets/images/remove.png')}
                    alt="delete question"
                    onClick={() => removeRowFromTemplate(index)}
                    className={styles.removeImage}
                    data-cy="removeButton"
                  />
                </div>
              </div>
            </div>
            <div className={styles.field}>{renderItem(item, index)}</div>
          </li>
        );
      })}
    </ul>
  );
};
