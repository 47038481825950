import React from 'react';
import './svg.css';

export default props => (
  <svg
    version="1.1"
    width="30px"
    height="25px"
    x={props.x || "0px"}
    y={props.y || "0px"}
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <g>
          <path
            d="M475.4,187.7c0-0.6,0-1.2,0-1.8l-28-126.2c-6.1-28-31.1-48.2-59.7-48.2H118.2c-28.6,0-53.6,20.1-59.7,48.2
                          l-28,126.8c-0.6,1.8-0.6,3,0,4.9C12.2,201.8,0,221.9,0,244.4v121.9C0,385.2,10.4,401,25,412c0,0.6-0.6,1.8-0.6,3v42.7
                          c0,23.8,18.9,42.7,42.7,42.7h12.2c23.8,0,42.7-18.9,42.7-42.7v-30.5h268.2v30.5c0,23.8,18.9,42.7,42.7,42.7H445
                          c23.8,0,42.7-18.9,42.7-42.7V415c14.6-11,24.4-28.6,24.4-48.8V243.8C512,218.8,496.8,197.5,475.4,187.7z M82.9,65.2
                          c3.7-16.5,18.3-28.6,35.4-28.6h269.4c17.1,0,32.3,12.2,35.4,28.6l26.2,117.6H61c-1.2,0-3,0-4.3,0L82.9,65.2z M97.5,457.1
                          c0,10.4-7.9,18.3-18.3,18.3H67c-10.4,0-18.3-7.9-18.3-18.3v-33.5c6.1,1.8,12.2,3,18.3,3h30.5V457.1z M463.2,457.1
                          c0,10.4-7.9,18.3-18.3,18.3h-12.2c-10.4,0-18.3-7.9-18.3-18.3v-30.5H451c4.3,0,8.5-0.6,12.2-1.2V457.1z M487.6,365.7
                          c0,20.1-16.5,36.6-36.6,36.6H67c-19.5,0-42.7-16.5-42.7-36.6V243.8c0-20.1,16.5-36.6,36.6-36.6H451c20.1,0,36.6,16.5,36.6,36.6
                          V365.7z"
          />
          <path
            d="M91.4,262.1c-20.1,0-36.6,16.5-36.6,36.6s16.5,36.6,36.6,36.6s36.6-16.5,36.6-36.6S111.5,262.1,91.4,262.1z
                           M91.4,310.9c-6.7,0-12.2-5.5-12.2-12.2s5.5-12.2,12.2-12.2s12.2,5.5,12.2,12.2S98.1,310.9,91.4,310.9z"
          />
          <path
            d="M420.6,262.1c-20.1,0-36.6,16.5-36.6,36.6s16.5,36.6,36.6,36.6s36.6-16.5,36.6-36.6
                          C457.1,278.6,440.7,262.1,420.6,262.1z M420.6,310.9c-6.7,0-12.2-5.5-12.2-12.2s5.5-12.2,12.2-12.2s12.2,5.5,12.2,12.2
                          C432.8,305.4,427.3,310.9,420.6,310.9z"
          />
        </g>
      </g>
    </g>
  </svg>
);
