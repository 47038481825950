import React, { Component } from 'react';
import moment from 'moment';

import { stateAbbr } from '../../constants/states';
import {
  getLocationDropdown,
  getProjectDropdown
} from '../../utils/formHelpers';
import AddToFolderModal from '../Modal/addToFolderModal';
import Button from '../inputs/Button';
import { DatePicker } from '../inputs/DateTimePicker';
import DocumentFolder from '../DocumentFolder';
import { Dropdown, EmployeeDropdown } from '../inputs/Dropdown';
import FileDownloader from '../FileDownloader';
import FileUploader from '../FileUploader';
import Modal from '../Modal';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import Textbox from '../inputs/Textbox';
import Header from '../Header';
import HeaderAndFooter from '../HeaderAndFooter';
import { InputRow } from '../inputs';
import List from '../List';

export default class QualificationList extends Component {
  state = {
    modalOpen: 0,
    groupIds: [],
    projectIds: [],
    scheduledDate: '',
    expires: '',
    qualificationType: [],
    evaluationTypes: '',
    employeeId: [],
    certificationEntity: '',
    certificationId: '',
    certificateState: [],
    documentFolderId: '',
    _id: '',
    addToFolder: [],
    addToFolderModal: false
  };

  componentDidMount = () => {
    this.props.fetchDocumentFolders({
      documentType: 'Qualification'
    });

    if (this.props.activeQualification._id) {
      this.setState({
        ...this.props.activeQualification.documentData,
        scheduledDate: moment(
          this.props.activeQualification.documentData.scheduledDate
        ).format('MM/DD/YYYY'),
        expires: moment(this.props.activeQualification.expires).format(
          'MM/DD/YYYY'
        ),
        grades: this.props.activeQualification.documentData.grades,
        currentGrades: {
          currentGrades: this.props.activeQualification.documentData
            .currentGrades
        },
        _id: this.props.activeQualification._id,
        companyId: this.props.activeQualification.companyId,
        groupIds: this.props.activeQualification.groupIds,
        projectIds: this.props.activeQualification.projectIds,
        modalOpen: 1
      });
    }
  };

  resetState = () => {
    this.setState({
      modalOpen: 0,
      groupIds: [],
      projectIds: [],
      scheduledDate: '',
      expires: '',
      qualificationType: [],
      evaluationTypes: '',
      employeeId: [],
      certificationEntity: '',
      certificationId: '',
      certificateState: [],
      documentFolderId: '',
      _id: ''
    });
  };

  handleOpenModal = value => {
    this.setState({ modalOpen: value });
  };

  handleCloseModal = () => {
    this.props.clearAttachment();
    this.props.setActiveQualification({});
    this.resetState();
  };

  updateState = stateObject => {
    this.setState(stateObject);
  };

  handleBasicSubmit = () => {
    if (this.state._id) {
      this.props.updateQualification({
        ...this.state,
        isFromEmployee: this.props.isFromEmployee
      });
    } else {
      this.props.addQualification({
        ...this.state,
        isFromEmployee: this.props.isFromEmployee
      });
    }

    this.props.clearAttachment();

    this.handleCloseModal();
  };

  handleSetActiveQualification = async (row, modalOpen) => {
    await this.setState({
      ...row.documentData,
      scheduledDate: moment(row.documentData.scheduledDate).format(
        'MM/DD/YYYY'
      ),
      expires: moment(row.expires).format('MM/DD/YYYY'),
      _id: row._id,
      companyId: row.companyId,
      groupIds: row.groupIds,
      projectIds: row.projectIds
    });
    await this.props.setActiveQualification(row);
    this.handleOpenModal(modalOpen);
  };

  handleDeleteQualification = () => {
    const { deleteQualification } = this.props;
    const { addToFolder } = this.state;

    addToFolder.forEach(id => {
      deleteQualification({
        _id: id,
        isFromEmployee: this.props.isFromEmployee
      });
    });

    this.handleCloseModal();
  };

  handleAddMultipleToFolders = () => {
    const { content } = this.props;
    let addDocsToFolder = [];

    const addToFolder = this.state.addToFolder;

    content.forEach(document => {
      if (addToFolder.indexOf(document._id) > -1) {
        addDocsToFolder.push(document);
      }
    });

    this.props.addDocumentToFolder({
      documents: addDocsToFolder,
      documentFolderId: this.state.documentFolderId
    });

    this.setState({ addToFolderModal: false, addToFolder: [] });
  };

  canSubmit = () => {
    const {
      groupIds,
      scheduledDate,
      expires,
      qualificationType,
      employeeId,
      certificationEntity,
      certificationId
    } = this.state;

    const { activeLocation } = this.props;

    return (
      (groupIds || activeLocation) &&
      scheduledDate &&
      expires &&
      qualificationType &&
      employeeId.length &&
      certificationEntity &&
      certificationId
    );
  };

  render() {
    const {
      content,
      locationList,
      activeQualification,
      activeLocation,
      activeProject,
      addedAttachments,
      deleteAttachment,
      activeAttachments,
      activeCompany,
      deleteQualificationAttachment,
      addAttachment,
      loading,
      qualificationTypes,
      accessLevel,
      documentFolders,
      isOnEmployee
    } = this.props;

    const {
      modalOpen,
      certificationEntity,
      certificationId,
      qualificationType,
      employeeId,
      groupIds,
      projectIds,
      certificateState,
      expires,
      scheduledDate,
      documentFolderId,
      addToFolder,
      addToFolderModal
    } = this.state;

    const qualificationTypeDropdown = qualificationTypes.map(
      (qualification, index) => {
        return { value: index, label: qualification.fullName };
      }
    );

    const columns = [
      { key: 'title', accessor: r => r.documentData?.qualificationType?.label },
      {
        key: 'employeeId',
        label: 'Employee Name',
        datatype: 'users',
        accessor: r => r.documentData.employeeId
      },
      {
        key: 'scheduledDate',
        datatype: 'futureDate',
        accessor: r => r.documentData.scheduledDate
      },
      { key: 'expires', datatype: 'futureDate' },
      { key: 'attachments', label: 'Certificate', datatype: 'attachments' }
    ];

    if (!activeLocation) {
      columns.unshift({
        key: 'groupIds',
        label: 'Group/Est.',
        datatype: 'groups'
      });
    }

    const folderDropdown = documentFolders.map(folder => {
      return {
        value: folder._id,
        label: folder.name
      };
    });

    const modals = (
      <>
        {modalOpen === 1 && (
          <Modal
            title={
              activeQualification._id
                ? 'Edit Qualification'
                : 'Add Qualification'
            }
            titleClassName={
              activeQualification._id ? 'blueHeader' : 'greenHeader'
            }
            isOpen={modalOpen === 1}
            submitButtonColor="green"
            submitButtonText="Submit"
            onRequestClose={() => this.handleCloseModal()}
            submitActions={() => this.handleBasicSubmit()}
            disableSubmit={!this.canSubmit()}
            testID="modal"
          >
            <div>
              {!activeLocation ? (
                <Dropdown
                  multi
                  selectButtons
                  name="groupId"
                  fieldLabel="Group/Establishment"
                  options={getLocationDropdown(locationList)}
                  onChange={values => this.updateState({ groupIds: values })}
                  isRequired
                  currentValue={groupIds}
                  searchable
                />
              ) : (
                ''
              )}
              {!activeProject ? (
                <Dropdown
                  multi
                  selectButtons
                  name="projectId"
                  fieldLabel="Area:"
                  options={getProjectDropdown(
                    groupIds,
                    activeLocation,
                    locationList
                  )}
                  placeholder="Select an area"
                  currentValue={projectIds}
                  onChange={value => this.updateState({ projectIds: value })}
                  searchable={true}
                />
              ) : (
                ''
              )}
              <Dropdown
                name="qualification"
                fieldLabel="Qualification"
                options={qualificationTypeDropdown}
                onChange={values =>
                  this.updateState({ qualificationType: values })
                }
                isRequired={true}
                currentValue={qualificationType}
                searchable={true}
                bareValues={false}
                valueKey="label"
              />
              <Dropdown
                fieldLabel="Add to Folder:"
                options={folderDropdown}
                currentValue={documentFolderId}
                onChange={value =>
                  this.updateState({ documentFolderId: value })
                }
                searchable={false}
              />
              <DatePicker
                fieldLabel="Schedule Date"
                onChange={value => this.updateState({ scheduledDate: value })}
                currentValue={scheduledDate}
                isRequired
                appendToBody
                name="qualificationScheduleDate"
              />
              <DatePicker
                fieldLabel="Expiration Date"
                onChange={value => this.updateState({ expires: value })}
                currentValue={expires}
                isRequired
                appendToBody
                name="qualificationExpirationDate"
              />
              <Dropdown
                name="statesOfCerts"
                fieldLabel="State of Certification"
                options={stateAbbr}
                className="state"
                placeholder="State"
                currentValue={certificateState}
                onChange={value =>
                  this.updateState({ certificateState: value })
                }
                searchable={true}
                bareValues={false}
              />
              <EmployeeDropdown
                multi
                selectButtons
                name="employees"
                fieldLabel="Personnel Name"
                onChange={values => this.updateState({ employeeId: values })}
                isRequired={true}
                currentValue={employeeId}
                searchable={true}
              />
              <Textbox
                currentValue={certificationEntity}
                fieldLabel="Certification Entity"
                onChange={v => this.updateState({ certificationEntity: v })}
                isRequired={true}
              />
              <Textbox
                currentValue={certificationId}
                fieldLabel="Certification ID"
                onChange={v => this.updateState({ certificationId: v })}
                isRequired={true}
              />
              <div>
                <h4>Attachments</h4>
                {addedAttachments &&
                  addedAttachments.map((attachment, index) => (
                    <FileDownloader
                      attachment={attachment}
                      key={index}
                      attachmentIndex={index}
                      deleteAttachment={deleteAttachment}
                    />
                  ))}
                {activeAttachments &&
                  activeAttachments.map((attachment, attachmentIndex) => (
                    <FileDownloader
                      attachment={attachment}
                      key={attachmentIndex}
                      attachmentIndex={attachmentIndex}
                      deleteAttachment={deleteQualificationAttachment}
                    />
                  ))}
                <FileUploader
                  activeCompanyId={activeCompany._id}
                  addAttachment={addAttachment}
                  loading={loading}
                  ownerId="1234"
                  ownerType="documents"
                />
              </div>
            </div>
          </Modal>
        )}
        <Modal
          title="Delete Qualification"
          titleClassName="redHeader"
          isOpen={modalOpen === 5}
          submitButtonColor="red"
          submitButtonText="Delete"
          onRequestClose={() => this.handleCloseModal()}
          submitActions={() => this.handleDeleteQualification()}
        >
          <h2>
            Are you sure you want to delete{' '}
            {addToFolder.length === 1
              ? 'this 1 qualification'
              : `these ${addToFolder.length} qualifications`}
            ?
          </h2>
        </Modal>
        <AddToFolderModal
          isOpen={addToFolderModal}
          folderId={documentFolderId}
          onRequestClose={() => this.setState({ addToFolderModal: false })}
          submitActions={this.handleAddMultipleToFolders}
          folderOptions={folderDropdown}
          onChange={value => this.setState({ documentFolderId: value })}
        />
      </>
    );

    const header = (
      <Header
        title="Qualifications"
        rightButtons={{
          color: 'green',
          text: 'Add Qualification',
          onClick: () => {
            this.resetState();
            this.handleOpenModal(1);
          }
        }}
      />
    );

    const page = (
      <>
        <ScrollToTopOnMount />
        <DocumentFolder content={content} docType="Qualification" />
        <List
          saveKey="qualList"
          data={content}
          dataIsHash
          settings={columns}
          getRowId={r => r._id}
          actions={[
            {
              label: 'Add to Folder',
              color: 'blue',
              visible: accessLevel !== 100,
              onClick: ids =>
                this.setState({
                  addToFolder: ids,
                  addToFolderModal: true
                })
            },
            {
              label: 'Delete',
              color: 'red',
              onClick: ids =>
                this.setState({
                  addToFolder: ids,
                  modalOpen: 5
                })
            }
          ]}
          rowClick={original =>
            original.documentData.qualificationType?.label?.includes(
              'Crane Specific'
            )
              ? this.handleSetActiveQualification(original, 3)
              : this.handleSetActiveQualification(original, 1)
          }
        />
      </>
    );
    return isOnEmployee ? (
      <>
        <InputRow right>
          <Button
            color="green"
            text="Add Qualification"
            onClick={() => {
              this.resetState();
              this.handleOpenModal(1);
            }}
          />
        </InputRow>
        {page}
        {modals}
      </>
    ) : (
      <>
        <HeaderAndFooter Header={header}>{page}</HeaderAndFooter>
        {modals}
      </>
    );
  }
}
