import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  newUserRegistrationRequest,
  registrationFailure
} from '../../actions/auth';
import { getRegistrationFailure } from '../../selectors/auth';
import NewUserRegistrationForm from '../../forms/NewUserRegistrationForm';
import Button from '../../components/inputs/Button';
import { addMessage } from '../../actions/messages';

import styles from './register.module.scss';

export class Register extends Component {
  state = {
    badFirstName: false,
    badUsername: false,
    badPassword: false,
    badLastName: false,
    badEmail: false,
    badConfirmPassword: false
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.registrationFailure ===
      "Username already in use. Are you sure you don't have an account?"
    ) {
      this.setState({ badUsername: true });
      this.props.setRegistrationFailure('');
    } else if (
      this.props.registrationFailure ===
      "Email already in use. Are you sure you don't have an account?"
    ) {
      this.setState({ badEmail: true });
      this.props.setRegistrationFailure('');
    }
  };

  handleBlankFields = (values, badData) => {
    if (!values.firstName) {
      this.setState({ badFirstName: true });
      badData = true;
    } else {
      this.setState({ badFirstName: false });
    }
    if (!values.username) {
      this.setState({ badUsername: true });
      badData = true;
    } else {
      this.setState({ badUsername: false });
    }
    if (!values.password) {
      this.setState({ badPassword: true });
      badData = true;
    } else {
      this.setState({ badPassword: false });
    }
    if (!values.lastName) {
      this.setState({ badLastName: true });
      badData = true;
    } else {
      this.setState({ badLastName: false });
    }
    if (!values.email) {
      this.setState({ badEmail: true });
      badData = true;
    } else {
      this.setState({ badEmail: false });
    }
    if (!values.confirmPassword) {
      this.setState({ badConfirmPassword: true });
      badData = true;
    } else {
      this.setState({ badConfirmPassword: false });
    }
    return badData;
  };

  handleNewUserSubmit = values => {
    if (this.handleBlankFields(values, false)) {
      this.props.newMessage({
        error: true,
        message: 'Fill in all required Fields'
      });
    } else {
      this.props.newUserRegistration(values);
    }
  };

  render() {
    const {
      badFirstName,
      badUsername,
      badPassword,
      badLastName,
      badEmail,
      badConfirmPassword
    } = this.state;
    const { newGoogleLogin } = this.props;
    return (
      <div className={styles.newAccountWholePage}>
        <div className={styles.newAccountFlexbox}>
          <div className={styles.learnMore}>
            <img src={require('../../assets/images/Logo.png')} alt="" />
            <h2>
              Join some of the <br />
              world's safest companies!
            </h2>
            <img
              src={require('../../assets/images/partner-logos-5.png')}
              alt=""
            />
            <Button
              color="green"
              text="Learn More"
              shrink
              onClick={() =>
                (window.location.href = 'https://ireportsource.com/')
              }
            />
          </div>
          <div className={styles.loginRegistration}>
            <div className={styles.createAccountHeader}>
              <h2>Create Account</h2>
              <section>
                Already have an account? <a href="/login">Log in.</a>
              </section>
            </div>
            <NewUserRegistrationForm
              onSubmit={values => this.handleNewUserSubmit(values)}
              setLoginPage={value => this.setState({ loginPage: value })}
              onGoogleSuccess={values => newGoogleLogin(values)}
              badFirstName={badFirstName}
              badUsername={badUsername}
              badPassword={badPassword}
              badLastName={badLastName}
              badEmail={badEmail}
              badConfirmPassword={badConfirmPassword}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  registrationFailure: getRegistrationFailure(state)
});

const mapDispatchToProps = dispatch => ({
  newMessage: payload => dispatch(addMessage(payload)),
  newUserRegistration: payload => dispatch(newUserRegistrationRequest(payload)),
  setRegistrationFailure: payload => dispatch(registrationFailure(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
