import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Card from '../../../components/Card';
import { EmployeeDropdown, Textbox } from '../../../components/inputs';
import DashboardShares from '../../DashboardShares';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../../components/Footer';
import { SaveChangesModal } from '../../../components/Modal/saveChangesModal';
import { getMyUserId } from '../../../selectors/users';
import history from '../../../history';

import { fetchDashboardById, updateDashboardById } from '../../../api/v4';

export default function Dashboard({
  onChange,
  title,
  shares,
  setHasError,
  setShowAddButton
}) {
  const myUserId = useSelector(getMyUserId);
  const { id } = useParams();

  const [dashboard, setDashboard] = useState(null);

  const [hasChanges, setHasChanges] = useState(false);
  const [titleError, setTitleError] = useState(null);
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const [createdBy, setCreatedBy] = useState('');

  useEffect(() => {
    if (id) {
      fetchDashboardById(id).then(response => {
        setDashboard({ name: response.name, shares: response.shares });
        setCreatedBy(response.createdByUserId);
      });
    }
  }, [id]);

  const checkForValidTitle = title => {
    const regex = /^[a-zA-Z0-9]{3,}$/g;
    const valid = title.replace(/\s/g, '')?.match(regex);
    if (valid) {
      setTitleError(null);
      if (!id) {
        setHasError(false);
        setShowAddButton(true);
      }
    } else {
      setTitleError('Title must be at least 3 letters/numbers long');
      if (!id) {
        setHasError(true);
        setShowAddButton(false);
      }
    }

    id ? handleChange({ name: title }) : onChange({ title });
    setHasChanges(true);
  };

  const addPermissions = (value, shares) => {
    let updated = [
      ...shares,
      {
        userId: value,
        readOnly: true,
        endDate: null
      }
    ];

    id ? handleChange({ shares: updated }) : onChange({ shares: updated });
    setHasChanges(true);
  };

  const handleGoBack = () => {
    hasChanges ? setShowSaveChangesModal(true) : history.goBack();
  };

  const handleChange = values => {
    setDashboard({ ...dashboard, ...values });
    setHasChanges(true);
  };

  const header = (
    <Header
      title="Leaderboard"
      section="Edit Dashboard"
      clickBack={handleGoBack}
      needsSaved={hasChanges}
    />
  );

  const footer = (
    <SaveCancelFooter
      editing
      cancelButtonClick={() => handleGoBack()}
      saveButtonDisabled={!hasChanges || titleError}
      saveButtonClick={() =>
        updateDashboardById(id, dashboard).then(() => history.goBack())
      }
      saveButtonText="Save"
    />
  );

  const renderCard = (title, shares) => {
    return (
      <>
        <Card title="Dashboard" name={0} showHeader={true}>
          <Textbox
            fieldLabel="Dashboard Title"
            currentValue={title}
            onChange={value => checkForValidTitle(value)}
            isRequired
            error={titleError}
            touched={title}
          />
        </Card>
        <Card title="Permissions" name={1} showHeader={true}>
          <EmployeeDropdown
            searchable
            onChange={values => addPermissions(values[0], shares)}
            fieldLabel="Share With"
            multi
            exclude={shares
              ?.map(s => s.userId)
              .concat(myUserId)
              .concat(createdBy)}
            permissions={[500, 900]}
          />
          {shares?.length > 0 && (
            <>
              <DashboardShares
                title="Active Permissions"
                expired={false}
                onChange={value =>
                  id
                    ? handleChange({ shares: value })
                    : onChange({ shares: value })
                }
                shares={shares}
              />
              <DashboardShares
                title="Expired Permissions"
                expired
                onChange={value =>
                  id
                    ? handleChange({ shares: value })
                    : onChange({ shares: value })
                }
                shares={shares}
              />
            </>
          )}
        </Card>
      </>
    );
  };

  return id ? (
    <HeaderAndFooter Header={header} Footer={footer}>
      {renderCard(dashboard?.name, dashboard?.shares)}
      <SaveChangesModal
        savingWhat="Dashboard"
        isOpen={showSaveChangesModal}
        onRequestClose={() => setShowSaveChangesModal(false)}
        submitActions={() => history.goBack()}
      />
    </HeaderAndFooter>
  ) : (
    renderCard(title, shares)
  );
}
