import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { useTable } from 'react-table';

import { getHoursWorked } from '../../api/v4';
import {
  OSHA_PERRP_DROPDOWN,
  MONTHS,
  PERRP_ENTITY_CODES,
  POLICY_PROVIDERS_OPTIONS
} from '../../constants/constants';
import { states } from '../../constants/states';
import {
  normalizeBwcPolicy,
  normalizePhone,
  normalizeWebsite,
  renderDropdownField,
  renderEmployeeDropdown,
  renderField,
  renderNumberField,
  required,
  validNAICS,
  validSIC,
  validZip
  // renderRadioButtons
} from '../../utils/formValidation';
import { Input, InputRow, TwoColumn } from '../../components/inputs';
import Textbox from '../../components/inputs/Textbox';
import Dropdown from '../../components/inputs/Dropdown';
import Button from '../../components/inputs/Button';
import FileCropper from '../../components/FileCropper';

import '../form.css';

const thisYear = new Date().getFullYear();

let CompanyForm = props => {
  const {
    handleSubmit,
    activeCompany,
    isUpdate,
    addAttachment,
    addedAttachments,
    removeAttachment,
    selectedState,
    founded,
    isPerrp,
    change
  } = props;

  const [year, setYear] = React.useState(thisYear);
  const [hours, dispatchHours] = React.useReducer((state, action) => {
    switch (action.type) {
      case 'set':
      default:
        return action.hours;
      case 'update':
        let hours = [...state];
        let value = parseInt(action.value, 10);
        let idx = hours.findIndex(c => c._id === action.id);
        if (idx === -1) return state;
        if (Number.isNaN(value)) value = undefined;
        if (!hours[idx].hoursWorked) hours[idx].hoursWorked = [];
        hours[idx].hoursWorked[action.month] = value;

        change('hours', { hours, year });
        return hours;
    }
  }, undefined);
  React.useEffect(() => {
    if (activeCompany) {
      getHoursWorked(year).then(data =>
        dispatchHours({ type: 'set', hours: data })
      );
    }
  }, [activeCompany, year]);

  let columns = React.useMemo(() => {
    let options = [];
    for (let i = founded || 2010; i <= thisYear; i++)
      options.push({ label: i, value: i });

    let columns = MONTHS.map((m, i) => ({
      Header: m,
      id: m,
      sortable: false,
      accessor: d => d?.hoursWorked?.[i],
      Footer: d =>
        d?.data?.reduce((acc, cur) => acc + (cur?.hoursWorked?.[i] ?? 0), 0),
      Cell: ({ row, value }) => (
        <Textbox
          type="number"
          currentValue={value ?? ''}
          name={`hoursWorked-${row.original._id}-${m}`}
          onChange={value =>
            dispatchHours({
              type: 'update',
              id: row.original._id,
              month: i,
              value
            })
          }
          className="form-small-input"
        />
      )
    }));
    columns.unshift({
      Header: 'Group',
      accessor: 'name',
      Footer: 'Total'
    });
    columns.push({
      id: 'groupTotal',
      Header: (
        <Dropdown
          options={options}
          currentValue={year}
          onChange={setYear}
          className="form-small-dropdown"
        />
      ),
      accessor: d =>
        d?.hoursWorked?.reduce?.((acc, cur) => acc + (cur ?? 0), 0),
      Footer: d =>
        d?.data?.reduce(
          (acc, cur) =>
            acc +
            cur?.hoursWorked?.reduce?.(
              (hwAcc, hwCur) => hwAcc + (hwCur ?? 0),
              0
            ),
          0
        ),
      sortable: false
    });
    return columns;
  }, [founded, year]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data: hours || [] });

  return (
    <form className="companyForm" onSubmit={handleSubmit}>
      {isUpdate ? (
        <div className="form-fields--logo">
          <img
            src={
              (activeCompany && activeCompany.logoUrl) ||
              require('../../assets/images/no-image-placeholder.jpg')
            }
            alt={`${activeCompany.name}'s Logo`}
          />
        </div>
      ) : (
        ''
      )}
      <div className="form-fields--split">
        <div className="form-fields--attachmentsLeftSide">
          {addedAttachments && addedAttachments.length > 0 ? (
            <div className="form-fields--uploaded">
              <img
                src={addedAttachments[0].source_url}
                alt="Uploaded Logo"
                className="form-fields--attachmentsLeftSideImage"
              />
              <Button
                text="Remove Logo"
                color="red"
                onClick={() => removeAttachment()}
                className="form-fields--hoverButton"
              />
            </div>
          ) : (
            <Field
              name="attachments"
              type="text"
              component={FileCropper}
              placeholder={''}
              activeCompany={activeCompany}
              addAttachment={addAttachment}
            />
          )}
        </div>
        <div className="form-fields--attachmentsRightSide">
          <Field
            name="name"
            type="text"
            component={renderField}
            label="Division Name"
            validate={[required]}
            placeholder="e.g. Aperture Science Human Resources"
            requiredLabel={true}
          />
          <Field
            name="phoneNumber.fullPhoneNumber"
            type="text"
            component={renderField}
            label="Phone"
            placeholder="(000) 000-0000"
            normalize={normalizePhone}
            validate={[required]}
            requiredLabel={true}
          />
        </div>
      </div>
      <Field
        label="Division Address"
        name="mainAddress.streetAddress"
        type="text"
        component={renderField}
        requiredLabel={true}
        placeholder="Street Address"
        validate={[required]}
      />
      <InputRow>
        <Field
          name="mainAddress.city"
          type="text"
          component={renderField}
          placeholder="City"
          validate={[required]}
        />
        <Field
          id="state"
          name="mainAddress.stateName"
          type="text"
          component={renderDropdownField}
          placeholder="State"
          options={states}
          validate={[required]}
          searchable
        />
        <Field
          name="mainAddress.zip"
          type="text"
          component={renderNumberField}
          placeholder="ZIP Code"
          validate={[required, validZip]}
        />
      </InputRow>
      <Field
        name="website"
        type="text"
        component={renderField}
        label="Website"
        placeholder="e.g. www.ireportsource.com"
        normalize={normalizeWebsite}
      />
      {selectedState === 'OH' && (
        <Field
          name="reportingType"
          component={renderDropdownField}
          options={OSHA_PERRP_DROPDOWN}
          label="OSHA or PERRP"
          requiredLabel={true}
        />
      )}
      {selectedState === 'OH' && isPerrp === 2 && (
        <Field
          name="entityCode"
          component={renderDropdownField}
          options={PERRP_ENTITY_CODES}
          label="Entity Code"
        />
      )}
      {selectedState === 'OH' && isPerrp === 2 && (
        <Field
          name="bwcNumber"
          component={renderField}
          type="text"
          placeholder="e.g. 12345678-000"
          label="BWC Policy Number"
          normalize={normalizeBwcPolicy}
          requiredLabel={true}
          validate={required}
        />
      )}
      <InputRow>
        <Field
          name="NAICS"
          type="text"
          component={renderNumberField}
          label="NAICS"
          placeholder="000000"
          requiredLabel={true}
          validate={[required, validNAICS]}
          displayToolTip={true}
        >
          North American Industrial Classification
        </Field>
        <Field
          name="SIC"
          type="text"
          component={renderNumberField}
          label="SIC"
          placeholder="0000"
          warn={validSIC}
          displayToolTip={true}
        >
          Standard Industrial Classification
        </Field>
      </InputRow>
      <InputRow>
        <Field
          name="yearCompanyWasFounded"
          type="text"
          component={renderField}
          label="What year was the company founded?"
          placeholder="yyyy"
        />
      </InputRow>
      <TwoColumn>
        <Field
          name="policyProvider"
          component={renderDropdownField}
          label="Insurance Provider"
          options={POLICY_PROVIDERS_OPTIONS}
        />
        <Field
          component={renderField}
          type="text"
          label="Policy Number"
          name="policyNumber"
        />
      </TwoColumn>
      {isUpdate && (
        <Field
          name="supervisorsWithAllIncidentAccess"
          component={renderEmployeeDropdown}
          label="Supervisors with access to all Incidents in their Group/Establishments"
          displayToolTip
          permissions={[400]}
        >
          Supervisors selected will get access/notifications for all incidents
          in the group/establishments they have access to. In order to restrict
          supervisors again, remove them from this list.
        </Field>
      )}
      {/* <InputRow>
        <Field
          name="turnOnCovidTracking"
          component={renderRadioButtons}
          label="Opt-in to Covid-19 Tracking"
          displayToolTip={true}
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' }
          ]}
          value={activeCompany?.turnOnCovidTracking}
        >
          Opt in to track Covid-19 Vaccination, Testing and Exposure. Easily
          upload Test Results or Proof of Vaccination. Admins will be notified
          every Monday of employees who missed testing last week and employees
          who are not vaccinated will a weekly reminder and To-Do to get tested
        </Field>
      </InputRow> */}
      {isUpdate && (
        <Input
          fieldLabel="Hours Worked"
          tooltip="We collect this data to generate your OSHA Compliance Forms and to compile your DART, TCIR, and LTIR metrics."
          displayTooltip
          name="hours"
          className="hours-worked-table-parent"
        >
          <table {...getTableProps({ className: 'hours-worked-table' })}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              {footerGroups.map(group => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map(column => (
                    <td {...column.getFooterProps()}>
                      {column.render('Footer')}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          </table>
        </Input>
      )}
    </form>
  );
};

CompanyForm = reduxForm({
  form: 'CompanyForm',
  enableReinitialize: true
})(CompanyForm);

const selector = formValueSelector('CompanyForm');

const mapStateToProps = state => ({
  selectedState: selector(state, 'mainAddress.stateName'),
  founded: parseInt(selector(state, 'yearCompanyWasFounded'), 10),
  isPerrp: selector(state, 'reportingType')
});

export default connect(mapStateToProps)(CompanyForm);
