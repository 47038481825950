import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listAllReports, reportBreakdown } from '../../api/v4';
import { setActiveIncidentRequest } from '../../actions/incidents';
import {
  REACTIVE_REPORT_TYPES,
  PROACTIVE_REPORT_TYPES
} from '../../constants/constants';
import { getIsPerrp } from '../../selectors/company';
import { getAccessLevel } from '../../selectors/users';
import { useActiveHeirarchy } from '../../utils/useActiveHeirarchy';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import List from '../../components/List';
import IncidentsList from '../../components/IncidentsList';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import TypeSelection from '../../components/TypeSelection';

const reportTypes = [
  ...REACTIVE_REPORT_TYPES,
  ...PROACTIVE_REPORT_TYPES.filter(r => r.value !== 8 && r.value !== 10)
];

export default function IncidentStages() {
  const dispatch = useDispatch();
  const loadIncident = payload => dispatch(setActiveIncidentRequest(payload));
  const { company, location, project } = useActiveHeirarchy();
  const accessLevel = useSelector(getAccessLevel);
  const isPerrp = useSelector(getIsPerrp);
  const [selectedTab, setSelectedTab] = useState('List');
  const [data, setData] = useState(null);
  const [breakdownData, setBreakdownData] = useState(null);
  useEffect(() => {
    listAllReports().then(setData);
    if (accessLevel > 400 && selectedTab === 'Breakdown')
      reportBreakdown().then(setBreakdownData);
  }, [accessLevel, selectedTab]);

  const breakdownColumns = [
    {
      key: 'reportTemplateIds',
      label: 'Report Types',
      accessor: r => r.reportTemplateIds.map(v => parseInt(v, 10)),
      datatype: 'svgs',
      disableSortBy: true,
      enum: company?.showAircraft
        ? reportTypes
        : reportTypes.filter(r => r.value !== 6 && r.value !== 16)
    },
    {
      key: 'incidentNumber',
      label: 'Incident Title'
    },
    {
      key: 'Photo Evidence',
      label: 'Photo Evidence'
    },
    {
      key: 'Diagrams',
      label: 'Diagrams'
    },
    {
      key: 'Witness Statements',
      label: 'Witness Statements'
    },
    {
      key: 'Supervisor Summary',
      label: 'Supervisor Summary'
    },
    {
      key: 'Root Cause',
      label: 'Root Cause'
    },
    {
      key: 'Corrective Actions',
      label: 'Corrective Actions'
    },
    {
      key: 'OSHA Compliance',
      label: isPerrp ? 'PERRP Compliance' : 'OSHA Compliance'
    },
    {
      key: 'Follow-Up',
      label: 'Follow-Up'
    },
    {
      key: 'Return to Work',
      label: 'Return to Work'
    },
    {
      key: 'Closure Checklist',
      label: 'Closure Checklist'
    },
    {
      key: 'reportStage',
      label: 'Status',
      accessor: r => (r.reportStage?.label === 'Closed' ? 'Completed' : 'Open'),
      enum: [
        { value: 'Open', label: 'Open' },
        { value: 'Completed', label: 'Completed' }
      ]
    }
  ];

  if (!location) {
    breakdownColumns.unshift({
      key: 'groupId',
      label: 'Group/Est.',
      datatype: 'group'
    });
  }
  if (location && !project) {
    breakdownColumns.unshift({
      key: 'projectId',
      label: 'Area',
      datatype: 'project'
    });
  }

  const breakdownSaveKey =
    breakdownData?.length > 0
      ? !location
        ? `BreakdownGroups`
        : location && !project
        ? `BreakdownAreas`
        : 'Breakdown'
      : null;

  return (
    <HeaderAndFooter Header={<Header title="Reports" />}>
      <ScrollToTopOnMount />
      {accessLevel > 400 ? (
        <TypeSelection
          selected={selectedTab}
          selectedArray={['List', 'Breakdown']}
          onClick={value => setSelectedTab(value)}
        />
      ) : null}
      {selectedTab === 'List' ? (
        <IncidentsList content={data} saveKey="incidents" />
      ) : (
        <List
          dataIsHash
          data={breakdownData}
          settings={breakdownColumns}
          rowClick={row =>
            loadIncident({
              _id: row._id,
              returnPath: `/app/Incidents`
            })
          }
          saveKey={breakdownSaveKey}
        />
      )}
    </HeaderAndFooter>
  );
}
