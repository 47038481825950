import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from '../../history';
import {
  fetchMyDocumentsRequest,
  setActiveMyDocument
} from '../../actions/documents';
import {
  setActiveTrainingRequest,
  setActiveTrainingResponse
} from '../../actions/training';
import { getActiveCompany } from '../../selectors/company';
import { getMyDocumentsSelector } from '../../selectors/documents';
import Modal from '../../components/Modal';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import RadioButtons from '../../components/inputs/RadioButtons';
import List from '../../components/List';

import './index.css';

class MyDocumentStages extends Component {
  state = {
    openCreateModal: false,
    openDocumentTypeModal: false,
    docType: 0
  };

  componentDidMount() {
    this.props.fetchMyDocuments();
  }

  handleSelectDocument = () => {
    this.props.setActiveMyDocument({});
    if (this.state.docType === 0) {
      history.push('/app/recurrenceContainer');
    } else if (this.state.docType === 1) {
      history.push('/app/transitionContainer');
    } else {
      history.push('/app/fireexposure');
    }
  };

  handleView = original => {
    if (original.title) {
      if (original?.trainingType?.value !== 3) {
        history.push(`/app/training/performTraining/${original._id}`);
      } else {
        this.props.setActiveTemplate({ ...original, isEditing: false });
      }
    } else {
      this.props.setActiveMyDocument({ ...original });

      if (
        original.label ===
        'Recurrence, Relapse During Recovery & Continuing Treatment'
      ) {
        history.push(`/app/recurrenceContainer/${original._id}`);
      } else if (original.label === 'Transitional Return to Work Program') {
        history.push(`/app/transitionContainer/${original._id}`);
      } else if (original.label === 'Personal Exposure Form') {
        history.push(`/app/fireexposure/${original._id}`);
      } else {
        history.push('/app/myDocumentContainer');
      }
    }
  };

  render() {
    const { myDocs, setActiveMyDocument } = this.props;
    const { openCreateModal, openDocumentTypeModal, docType } = this.state;

    const columns = [
      { key: 'label', label: 'Title' },
      { key: 'createdAt', label: 'Created Date', datatype: 'date' },
      {
        key: 'expirationDate',
        datatype: 'futureDate',
        accessor: r => r.expirationDate ?? 'Does Not Expire'
      },
      {
        key: 'status',
        accessor: r => {
          if (
            r.label ===
            'Recurrence, Relapse During Recovery & Continuing Treatment'
          ) {
            if (r.isApprovedBySupervisor) {
              return 'Approved';
            } else {
              return 'Supervisor Review';
            }
          } else if (r.label === 'Transitional Return to Work Program') {
            if (!r.isApprovedBySupervisor) {
              return 'Supervisor Review';
            } else if (r.isApprovedByHr) {
              return 'Approved';
            } else {
              return 'HR Review';
            }
          } else if (r.title) {
            return 'Approved';
          } else {
            return '--';
          }
        },
        enum: ['Approved', 'Supervisor Review', 'HR Review']
      },
      {
        key: 'docType',
        label: 'Document Type',
        accessor: r => {
          if (r.title) {
            return 'Safety Training';
          } else if (
            r.label === 'Transitional Return to Work Program' ||
            r.label ===
              'Recurrence, Relapse During Recovery & Continuing Treatment' ||
            r.label === 'Personal Fire Exposure Form'
          ) {
            return 'Premade Documents';
          } else {
            return 'Uploaded Documents';
          }
        },
        enum: ['Safety Training', 'Premade Documents', 'Uploaded Documents']
      }
    ];

    const header = (
      <Header
        title="My Documents"
        rightButtons={{
          text: 'Create Document',
          color: 'blue',
          onClick: () => this.setState({ openCreateModal: true })
        }}
      />
    );

    return (
      <>
        <HeaderAndFooter Header={header}>
          <List
            saveKey="docStages"
            data={myDocs}
            dataIsHash
            settings={columns}
            rowClick={this.handleView}
          />
        </HeaderAndFooter>
        <Modal
          title="Choose Document Type"
          titleClassName="blueHeader"
          isOpen={openCreateModal}
          onRequestClose={() => this.setState({ openCreateModal: false })}
          hideButtons={true}
        >
          <div className="myDocumentList-reportSelection">
            <div
              className="myDocumentList-button"
              onClick={() =>
                this.setState({
                  openDocumentTypeModal: true,
                  openCreateModal: false
                })
              }
              data-cy="Premade Documents"
            >
              <img
                src={require('../../assets/images/PremadeForms.png')}
                className="myDocumentList-premadeForms"
                alt="Select For Premande forms"
              />
              <span>Premade Documents</span>
            </div>
            <div
              className="myDocumentList-button"
              onClick={() =>
                setActiveMyDocument({ attachments: [] }) &&
                history.push('/app/myDocumentContainer')
              }
              data-cy="Upload Documents"
            >
              <img
                src={require('../../assets/images/Upload Image.png')}
                className="myDocumentList-uploadForms"
                alt="Select For Employee Document Upload"
              />
              <span>Upload Document</span>
            </div>
          </div>
        </Modal>
        <Modal
          title="Choose Document Type"
          titleClassName="blueHeader"
          isOpen={openDocumentTypeModal}
          onRequestClose={() => this.setState({ openDocumentTypeModal: false })}
          submitButtonColor="blue"
          submitButtonText="Continue"
          submitActions={() => this.handleSelectDocument()}
        >
          <div className="myDocumentList-premadeFormsHeader">Premade Forms</div>
          <div className="myDocumentList-docTypeSelection">
            <RadioButtons
              options={
                this.props.activeCompany.showExposure
                  ? [
                      {
                        label:
                          'Recurrence, Relapse During Recovery & Continuing Treatment',
                        value: 0
                      },
                      {
                        value: 1,
                        label: 'Transitional Return to Work Program'
                      },
                      { value: 2, label: 'Personal Exposure Report' }
                    ]
                  : [
                      {
                        label:
                          'Recurrence, Relapse During Recovery & Continuing Treatment',
                        value: 0
                      },
                      { value: 1, label: 'Transitional Return to Work Program' }
                    ]
              }
              onChange={value => this.setState({ docType: value })}
              isRequired={true}
              currentValue={docType}
            />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  myDocs: getMyDocumentsSelector(state),
  activeCompany: getActiveCompany(state)
});

const mapDispatchToProps = dispatch => ({
  fetchMyDocuments: () => dispatch(fetchMyDocumentsRequest()),
  setActiveMyDocument: paylaod => dispatch(setActiveMyDocument(paylaod)),
  setActiveTemplate: payload => dispatch(setActiveTrainingRequest(payload)),
  setActiveTraining: payload => dispatch(setActiveTrainingResponse(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyDocumentStages);
