import {
  ADD_INCIDENT_COMMENTS_REQUEST,
  ADD_INCIDENT_NOTES_REQUEST,
  CREATE_INCIDENT_REQUEST,
  DELETE_INCIDENT_REQUEST,
  EMAIL_INCIDENT_ASSIGNEE_REQUEST,
  EMAIL_INCIDENT_ASSIGNEE_RESPONSE,
  FETCH_INCIDENT_BASICS_REQUEST,
  FETCH_INCIDENT_BASICS_RESPONSE,
  FETCH_INCIDENT_BY_ID_REQUEST,
  RECATAGORIZE_REPORTS_REQUEST,
  SAVE_INCIDENT_BASICS_REQUEST,
  SAVE_INCIDENT_BASICS_RESPONSE,
  SET_ACTIVE_INCIDENT_REQUEST,
  SET_ACTIVE_INCIDENT_RESPONSE,
  UPDATE_INCIDENT_COMMENTS_REQUEST,
  UPDATE_INCIDENT_NOTES_REQUEST,
  UPDATE_INCIDENT_BASICS_REQUEST,
  UPDATE_INCIDENT_BASICS_RESPONSE,
  UPDATE_REPORT_COMPONENT_REQUEST
} from '../constants/actionTypes';

export const initialState = {
  loading: false,
  list: [],
  incidentBasics: [],
  activeIncident: {}
};

const incidents = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_INCIDENT_BASICS_REQUEST:
    case UPDATE_INCIDENT_BASICS_REQUEST:
    case SET_ACTIVE_INCIDENT_REQUEST:
    case ADD_INCIDENT_COMMENTS_REQUEST:
    case ADD_INCIDENT_NOTES_REQUEST:
    case DELETE_INCIDENT_REQUEST:
    case EMAIL_INCIDENT_ASSIGNEE_REQUEST:
    case UPDATE_INCIDENT_COMMENTS_REQUEST:
    case UPDATE_INCIDENT_NOTES_REQUEST:
    case FETCH_INCIDENT_BY_ID_REQUEST:
    case RECATAGORIZE_REPORTS_REQUEST:
    case SAVE_INCIDENT_BASICS_REQUEST:
    case UPDATE_REPORT_COMPONENT_REQUEST:
    case CREATE_INCIDENT_REQUEST:
      return {
        ...state,
        loading: true
      };

    case FETCH_INCIDENT_BASICS_RESPONSE:
      return {
        ...state,
        loading: false,
        incidentBasics: payload
      };

    case UPDATE_INCIDENT_BASICS_RESPONSE:
      let id = payload.id;
      delete payload.id;

      let activeIncident = {
        ...state.activeIncident,
        ...payload
      };

      const list = state.list.map(incident => {
        if (id === incident._id) return { ...activeIncident };
        return incident;
      });

      return {
        ...state,
        loading: false,
        activeIncident,
        list
      };

    case SET_ACTIVE_INCIDENT_RESPONSE:
      return {
        ...state,
        loading: false,
        activeIncident: payload
      };

    case EMAIL_INCIDENT_ASSIGNEE_RESPONSE:
    case SAVE_INCIDENT_BASICS_RESPONSE:
      return {
        ...state,
        loading: false
      };

    default:
      return {
        ...state
      };
  }
};

export default incidents;
