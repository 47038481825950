import { all, call, put, select } from 'redux-saga/effects';
import moment from 'moment';
import history from '../history';
import { clearUploadedAttachments } from '../actions/attachments';
import {
  addDocumentFolderResponse,
  addDocumentsToFolderResponse,
  addQualificationResponse,
  addSafetyInstructionResponse,
  addSdsResponse,
  deleteDocumentFolderResponse,
  deleteQualificationResponse,
  deleteSafetyInstructionResponse,
  deleteSdsResponse,
  fetchCustomDocumentsResponse,
  fetchCustomDocumentTemplatesResponse,
  fetchDocumentFoldersResponse,
  fetchMyDocumentsResponse,
  fetchQualificationResponse,
  fetchQualificationTypeResponse,
  fetchRequireAuthResponse,
  fetchSafetyInstructionResponse,
  fetchSdsResponse,
  setActiveCustomDocument,
  setActiveCustomDocumentTemplate,
  setActiveDocumentFolderResponse,
  setActiveQualification,
  setActiveSafetyInstruction,
  setActiveSds,
  updateDocumentFolderResponse,
  updateQualificationResponse,
  updateSafetyInstructionResponse,
  updateSdsResponse
} from '../actions/documents';
import { addMessage } from '../actions/messages';
import { setActiveEmployeeResponse } from '../actions/personnel';
import { fetchTrainingsResponse } from '../actions/training';
import { getAddedAttachmentsSelector } from '../selectors/attachments';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../selectors/company';
import {
  getActiveCustomDocSelector,
  getActiveQualificationSelector,
  getActiveSafetyInstructionSelector,
  getActiveSdsSelector
} from '../selectors/documents';
import { getActiveEmployeeSelector } from '../selectors/personnel';
import { getLoggedInUser } from '../selectors/users';

export function* fetchCompanySafetyInstructions(api, apiV4, { payload }) {
  try {
    const company = yield select(getActiveCompany);

    let response = [];
    response = yield call(
      apiV4.fetchDocumentsByType,
      'SafetyInstruction',
      payload?.documentFolderId
    );
    const attachments = yield all(
      response.map(document =>
        call(
          api.fetchAttachmentByOwnerId,
          company._id,
          document._id,
          'documents'
        )
      )
    );

    response.map((res, index) => (res.attachments = attachments[index]));

    yield put(fetchSafetyInstructionResponse(response));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchCompanySds(api, apiV4, { payload }) {
  try {
    const company = yield select(getActiveCompany);

    let response = [];

    response = yield call(apiV4.fetchDocumentsByType, 'SDS');

    const attachments = yield all(
      response.map(document =>
        call(
          api.fetchAttachmentByOwnerId,
          company._id,
          document._id,
          'documents'
        )
      )
    );

    response.map((res, index) => (res.attachments = attachments[index]));
    yield put(fetchSdsResponse(response));
  } catch (e) {
    console.log(e);
  }
}

export function* addSds(api, apiV4, { payload }) {
  try {
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);
    const activeCompany = yield select(getActiveCompany);
    const group = yield select(getActiveLocationId);
    const project = yield select(getActiveProject);

    const groupIds = payload.groupIds.map(group => group.value);
    const projectIds = payload.projectIds.map(project => project.value);

    payload = {
      label: payload.label,
      expires: moment(payload.expires),
      documentData: {
        ghsLabels: payload.ghsLabels
      },
      documentType: 'SDS',
      companyId: activeCompany._id,
      groupIds: group ? [group._id] : groupIds,
      projectIds: project ? [project._id] : projectIds,
      documentFolderId: payload.documentFolderId
    };

    const response = yield call(apiV4.createDocument, payload);

    let newAttachments = [];

    if (uploadedAttachments.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      newAttachments = temp;
    }

    response.attachments = newAttachments;

    yield put(addSdsResponse(response));
    yield put(clearUploadedAttachments());
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateSds(api, apiV4, { payload }) {
  try {
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);

    const groupId = payload.groupIds.map(group => {
      if (group.value) {
        return group.value;
      }

      return group;
    });

    const projectId = payload.projectIds.map(project => {
      if (project.value) {
        return project.value;
      }

      return project;
    });

    payload = {
      ...payload,
      documentData: {
        ghsLabels: payload.ghsLabels
      },
      expires: moment(payload.expires),
      groupIds: groupId,
      projectIds: projectId
    };

    const response = yield call(apiV4.updateDocument, payload);

    let newAttachments = [];

    if (uploadedAttachments.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      newAttachments = temp;
    }

    response.attachments = [...payload.attachments, ...newAttachments];

    yield put(updateSdsResponse(response));
    yield put(setActiveSds(response));
    yield put(clearUploadedAttachments());
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteSds(apiV4, { payload }) {
  try {
    yield call(apiV4.deleteDocument, payload);

    yield put(deleteSdsResponse(payload));
    yield put(addMessage({ error: false, message: 'Deleted Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* addSafetyInstruction(api, apiV4, { payload }) {
  try {
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);
    const company = yield select(getActiveCompany);
    const group = yield select(getActiveLocationId);
    const project = yield select(getActiveProject);

    const groupIds =
      payload.locationId && payload.locationId.map(group => group.value);
    const projectIds =
      payload.projectId && payload.projectId.map(project => project.value);

    payload = {
      ...payload,
      expires: payload.expires
        ? moment(payload.expires).format('MM/DD/YYYY')
        : '',
      documentData: {
        ...payload.documentData
      },
      documentType: 'SafetyInstruction',
      companyId: company._id,
      groupIds: group ? [group._id] : groupIds,
      projectIds: project ? [project._id] : projectIds
    };

    delete payload.locationId;
    delete payload.projectId;

    const response = yield call(apiV4.createDocument, payload);

    let newAttachments = [];

    if (uploadedAttachments.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      newAttachments = temp;
    }

    response.attachments = newAttachments;

    yield put(addSafetyInstructionResponse(response));
    yield put(clearUploadedAttachments());
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateSafetyInstruction(api, apiV4, { payload }) {
  try {
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);

    const groupIds =
      payload.locationId &&
      payload.locationId.map(group => {
        if (group.value) {
          return group.value;
        }

        return group;
      });

    const projectIds =
      payload.projectId &&
      payload.projectId.map(project => {
        if (project.value) {
          return project.value;
        }

        return project;
      });

    payload = {
      ...payload,
      expires: payload.expires ? moment(payload.expires) : null,
      groupIds,
      projectIds
    };

    const response = yield call(apiV4.updateDocument, payload);

    let newAttachments = [];

    if (uploadedAttachments.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      newAttachments = temp;
    }

    response.attachments = [...payload.attachments, ...newAttachments];

    yield put(updateSafetyInstructionResponse(response));
    yield put(setActiveSafetyInstruction(response));
    yield put(clearUploadedAttachments());
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteSafetyInstruction(apiV4, { payload }) {
  try {
    yield call(apiV4.deleteDocument, payload);

    yield put(deleteSafetyInstructionResponse(payload));
    yield put(addMessage({ error: false, message: 'Deleted Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteSafetyInstructionAttachment(api, { payload }) {
  try {
    const activeSI = yield select(getActiveSafetyInstructionSelector);

    yield call(api.deleteAttachment, payload._id);

    const attachments = yield call(
      api.fetchAttachmentByOwnerId,
      activeSI.companyId,
      activeSI._id,
      'documents'
    );

    activeSI.attachments = attachments;

    yield put(updateSafetyInstructionResponse(activeSI));

    yield put(
      addMessage({ error: false, message: 'Attachment Deleted Successfully' })
    );
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteSdsAttachment(api, { payload }) {
  try {
    const activeSds = yield select(getActiveSdsSelector);

    yield call(api.deleteAttachment, payload._id);

    const attachments = yield call(
      api.fetchAttachmentByOwnerId,
      activeSds.companyId,
      activeSds._id,
      'documents'
    );

    activeSds.attachments = attachments;

    yield put(updateSdsResponse(activeSds));

    yield put(
      addMessage({ error: false, message: 'Attachment Deleted Successfully' })
    );
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteQualificationAttachment(api, { payload }) {
  try {
    const activeQual = yield select(getActiveQualificationSelector);

    yield call(api.deleteAttachment, payload._id);

    const attachments = yield call(
      api.fetchAttachmentByOwnerId,
      activeQual.companyId,
      activeQual._id,
      'documents'
    );

    activeQual.attachments = attachments;

    yield put(updateQualificationResponse(activeQual));
    yield put(setActiveQualification(activeQual));

    yield put(
      addMessage({ error: false, message: 'Attachment Deleted Successfully' })
    );
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* fetchQualifications(api, apiV4, { payload }) {
  try {
    const company = yield select(getActiveCompany);

    let response = [];
    response = yield call(apiV4.fetchDocumentsByType, 'Qualification');

    const attachments = yield all(
      response.map(document =>
        call(
          api.fetchAttachmentByOwnerId,
          company._id,
          document._id,
          'documents'
        )
      )
    );

    response.map((res, index) => (res.attachments = attachments[index]));

    yield put(fetchQualificationResponse(response));
  } catch (e) {
    console.log(e);
  }
}

export function* addQualification(api, apiV4, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);
    const group = yield select(getActiveLocationId);
    const project = yield select(getActiveProject);

    const isFromEmployee = payload.isFromEmployee;

    payload = {
      expires: moment(new Date(payload.expires)),
      documentData: {
        ...payload,
        scheduledDate: moment(new Date(payload.scheduledDate)),
        employeeId: payload.employeeId
      },
      documentType: 'Qualification',
      companyId: activeCompany._id,
      groupIds: group ? [group] : payload.groupIds,
      projectIds: project ? [project._id] : payload.projectIds,
      employees: payload.employeeId,
      documentFolderId: payload.documentFolderId
    };

    delete payload.documentData.expires;
    delete payload.documentData.modalOpen;

    const response = yield call(apiV4.createDocument, payload);

    let newAttachments = [];

    if (uploadedAttachments.length > 0) {
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      newAttachments = temp;
    }

    response.attachments = newAttachments;

    if (isFromEmployee) {
      const activeEmployee = yield select(getActiveEmployeeSelector);

      activeEmployee.qualifications.documents = [
        ...activeEmployee.qualifications.documents,
        response
      ];

      yield put(setActiveEmployeeResponse(activeEmployee));
    }

    yield put(setActiveQualification({}));
    yield put(addQualificationResponse(response));
    yield put(clearUploadedAttachments());
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateQualification(api, apiV4, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);
    const isFromEmployee = payload.isFromEmployee;

    payload = {
      expires: moment(new Date(payload.expires)),
      documentData: {
        ...payload,
        scheduledDate: moment(new Date(payload.scheduledDate))
      },
      groupIds: payload.groupIds,
      projectIds: payload.projectIds,
      documentType: 'Qualification',
      companyId: activeCompany._id,
      _id: payload._id,
      documentFolderId: payload.documentFolderId
    };

    const response = yield call(apiV4.updateDocument, payload);

    if (uploadedAttachments.length > 0) {
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );
    }

    const attachments = yield call(
      api.fetchAttachmentByOwnerId,
      activeCompany._id,
      response._id,
      'documents'
    );

    response.attachments = attachments;

    if (isFromEmployee) {
      const activeEmployee = yield select(getActiveEmployeeSelector);

      activeEmployee.qualifications.documents.forEach((q, i) => {
        if (response._id === q._id) {
          activeEmployee.qualifications.documents[i] = {
            ...activeEmployee.qualifications.documents[i],
            documentData: response.documentData,
            attachments: response.attachments
          };
        }
      });

      let containsActiveEmplopyee = response.documentData.employeeId.includes(
        activeEmployee._id
      );

      // if employee was removed from Personnel name move it out of their qual list
      if (!containsActiveEmplopyee) {
        activeEmployee.qualifications.documents = activeEmployee.qualifications.documents.filter(
          q => q._id !== response.documentData._id
        );
      }
      yield put(setActiveEmployeeResponse(activeEmployee));
    }
    yield put(updateQualificationResponse(response));
    yield put(clearUploadedAttachments());
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* fetchQualificationTypes(apiV4) {
  try {
    const response = yield call(apiV4.fetchQualificationType);

    yield put(fetchQualificationTypeResponse(response));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteQualification(apiV4, { payload }) {
  try {
    yield call(apiV4.deleteDocument, payload);

    if (payload.isFromEmployee) {
      const activeEmployee = yield select(getActiveEmployeeSelector);

      const docs = activeEmployee.qualifications.documents.filter(
        doc => doc._id !== payload._id
      );

      yield put(
        setActiveEmployeeResponse({
          ...activeEmployee,
          qualifications: { ...activeEmployee.qualifications, documents: docs }
        })
      );
    }

    yield put(deleteQualificationResponse(payload));
    yield put(addMessage({ error: false, message: 'Deleted Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* fetchDocumentsByFolder(api, apiV4, { payload }) {
  try {
    const company = yield select(getActiveCompany);

    let response = [];

    if (payload.documentType === 'SafetyTraining') {
      response = yield call(apiV4.fetchAllTrainings, payload._id);

      const training = response.filter(training => !training.legacy);
      const legacyTrainings = response.filter(training => training.legacy);

      const attachments = yield all(
        legacyTrainings.map(document =>
          call(
            api.fetchAttachmentByOwnerId,
            company._id,
            document.masterTemplateId,
            'documents'
          )
        )
      );

      legacyTrainings.map(
        (res, index) => (res.attachments = attachments[index])
      );

      yield put(fetchTrainingsResponse([...training, ...legacyTrainings]));
      yield put(setActiveDocumentFolderResponse(payload));
    } else {
      response = yield call(
        apiV4.fetchDocumentsByType,
        payload.documentType,
        payload._id
      );

      delete payload.page;
      delete payload.limit;
      const attachments = yield all(
        response.map(document =>
          call(
            api.fetchAttachmentByOwnerId,
            company._id,
            document._id,
            'documents'
          )
        )
      );
      response.map((res, index) => (res.attachments = attachments[index]));

      yield put(setActiveDocumentFolderResponse(payload));

      if (payload.documentType === 'SDS') {
        yield put(fetchSdsResponse(response));
      } else if (payload.documentType === 'SafetyInstruction') {
        yield put(fetchSafetyInstructionResponse(response));
      } else if (payload.documentType === 'Qualification') {
        yield put(fetchQualificationResponse(response));
      }
    }
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* fetchDocumentFolders(apiV4, { payload }) {
  try {
    const response = yield call(
      apiV4.fetchDocumentFoldersByType,
      payload.documentType
    );

    yield put(fetchDocumentFoldersResponse(response));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* addDocumentFolder(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);

    payload = {
      ...payload,
      companyId: activeCompany._id
    };

    const response = yield call(
      api.addDocumentFolder,
      activeCompany._id,
      payload
    );

    yield put(addDocumentFolderResponse(response));
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateDocumentFolder(api, { payload }) {
  try {
    const response = yield call(api.updateDocumentFolder, payload);

    yield put(updateDocumentFolderResponse(response));
    yield put(setActiveDocumentFolderResponse(response));
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* deleteDocumentFolder(api, apiV4, { payload }) {
  try {
    const documents = payload.documents;
    delete payload.documents;

    let response = {};

    if (payload.deleteAll) {
      delete payload.deleteAll;

      if (payload.documentType === 'SafetyTraining') {
        yield all(
          documents.map(document => call(apiV4.deleteTraining, document))
        );
      } else {
        yield all(
          documents.map(document => call(apiV4.deleteDocument, document))
        );
      }

      response = yield call(api.deleteDocumentFolder, payload);
    } else {
      delete payload.deleteAll;

      if (payload.documentType === 'SafetyTraining') {
        yield all(
          documents.map(document =>
            call(apiV4.updateTraining, { ...document, documentFolderId: '' })
          )
        );
      } else {
        yield all(
          documents.map(document =>
            call(apiV4.updateDocument, { ...document, documentFolderId: '' })
          )
        );
      }

      response = yield call(api.deleteDocumentFolder, payload);
    }

    yield put(deleteDocumentFolderResponse(response));
    yield put(setActiveDocumentFolderResponse({}));
    yield put(addMessage({ error: false, message: 'Deleted Successfully' }));

    if (payload.documentType === 'Safety Training') {
      response = yield call(api.fetchTrainings, {
        companyId: payload.companyId
      });

      const training = response.filter(training => !training.legacy);
      const legacyTrainings = response.filter(training => training.legacy);

      const attachments = yield all(
        legacyTrainings.map(document =>
          call(
            api.fetchAttachmentByOwnerId,
            payload.companyId,
            document.masterTemplateId,
            'documents'
          )
        )
      );

      legacyTrainings.map(
        (res, index) => (res.attachments = attachments[index])
      );

      yield put(fetchTrainingsResponse([...training, ...legacyTrainings]));
    } else if (payload.documentType === 'SDS') {
      yield call(fetchCompanySds, api, apiV4, {
        documentType: payload.documentType
      });
    } else if (payload.documentType === 'SafetyInstruction') {
      yield call(fetchCompanySafetyInstructions, api, apiV4, {
        documentType: payload.documentType
      });
    } else if (payload.documentType === 'Qualification') {
      yield call(fetchQualifications, api, apiV4, {
        documentType: payload.documentType
      });
    }
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* addDocumentsToFolder(apiV4, { payload }) {
  try {
    yield all(
      payload.documents.map(document =>
        payload.documentType === 'SafetyTraining'
          ? call(apiV4.updateTraining, {
              _id: document._id,
              documentFolderId: payload.documentFolderId,
              fromAddFolder: true
            })
          : payload.documentType === 'Repeating'
          ? call(apiV4.updateTrainingRepeatable, {
              _id: document._id,
              type: 'trainings',
              documentFolderId: payload.documentFolderId,
              fromAddFolder: true
            })
          : payload.documentType === 'On Demand'
          ? call(apiV4.updateTrainingScanOnDemand, {
              _id: document._id,
              type: 'trainings',
              documentFolderId: payload.documentFolderId,
              fromAddFolder: true
            })
          : call(apiV4.updateDocument, {
              ...document,
              documentFolderId: payload.documentFolderId
            })
      )
    );
    yield put(addDocumentsToFolderResponse());
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* fetchMyDocuments(apiV4, { payload }) {
  try {
    const activeUser = yield select(getLoggedInUser);
    const userId = payload?.userId ?? activeUser._id;

    const response = yield call(apiV4.fetchEmployeeDocuments, userId);

    const continuousCareResponse = yield call(
      apiV4.fetchContinuousCareByEmployeeId,
      userId
    );

    let fireExposureResponse = [];
    if (!payload?.userId)
      fireExposureResponse = yield call(apiV4.fetchFireExposures);

    let completedAndApprovedTraining = [];
    if (!payload?.fromEmployeeProfile) {
      const trainings = yield call(apiV4.getUserApprovedTrainings, userId);

      completedAndApprovedTraining = trainings.map(training => {
        return {
          ...training,
          label: training.title,
          formData: {
            employeeName: `${activeUser.firstName} ${activeUser.lastName}`
          },
          isPerform: true
        };
      });
    }

    yield put(
      fetchMyDocumentsResponse([
        ...response,
        ...continuousCareResponse,
        ...completedAndApprovedTraining,
        ...fireExposureResponse
      ])
    );
  } catch (e) {
    console.log(e);
  }
}

export function* createMyDocument(api, apiV4, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const addedAttachments = yield select(getAddedAttachmentsSelector);
    const activeUser = yield select(getLoggedInUser);

    payload = {
      label: payload.label,
      documentType: 'EmployeeDocument',
      companyId: company._id,
      employees: payload.userId ?? activeUser._id,
      documentData: {
        description: payload.description
      }
    };

    const response = yield call(apiV4.createDocument, payload);

    yield all(
      addedAttachments.map(attachment =>
        call(api.updateAttachment, {
          ...attachment,
          ownerId: response._id
        })
      )
    );

    yield put(clearUploadedAttachments());

    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* updateMyDocument(api, apiV4, { payload }) {
  try {
    const addedAttachments = yield select(getAddedAttachmentsSelector);

    payload = {
      ...payload,
      documentData: {
        description: payload.description
      }
    };

    const response = yield call(apiV4.updateDocument, payload);

    yield all(
      addedAttachments.map(attachment =>
        call(api.updateAttachment, {
          ...attachment,
          ownerId: response._id
        })
      )
    );

    yield put(clearUploadedAttachments());

    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* deleteMyDocument(apiV4, { payload }) {
  try {
    yield call(apiV4.deleteDocument, payload);

    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* fetchAuthorizationDocuments(api, apiV4) {
  try {
    const company = yield select(getActiveCompany);
    const activeUser = yield select(getLoggedInUser);

    const supervisorAuth = yield call(
      apiV4.fetchContinuousCareBySupervisorId,
      activeUser._id
    );

    const hrAuth = yield call(
      apiV4.fetchContinuousCareByHrRepId,
      activeUser._id
    );

    const trainings = yield call(
      apiV4.getUserNonApprovedTrainings,
      activeUser._id
    );

    const newTrainings = trainings
      .filter(training => moment(training.dueDate).isAfter())
      .map(training => {
        return {
          ...training,
          label: training.title,
          formData: {
            employeeName: `${activeUser.firstName} ${activeUser.lastName}`
          },
          isPerform: true
        };
      });

    const trainingsForApproval = yield call(api.fetchTrainingForApprover, {
      companyId: company._id,
      approverId: activeUser._id
    });

    const newApprovals = trainingsForApproval.map(training => {
      return {
        ...training,
        label: training.title,
        formData: {
          employeeName: `${activeUser.firstName} ${activeUser.lastName}`
        },
        isApprove: true
      };
    });

    yield put(
      fetchRequireAuthResponse([
        ...supervisorAuth,
        ...hrAuth,
        ...newTrainings,
        ...newApprovals
      ])
    );
  } catch (e) {
    console.log(e);
  }
}

export function* createContinuousCare(api, apiV4, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const activeUser = yield select(getLoggedInUser);

    const sig = payload.employeeSignature;

    payload = {
      label: payload.label,
      companyId: company._id,
      employeeId: activeUser._id,
      supervisorId: payload.supervisorId,
      hrRepId: payload.hrRepId,
      formData: {
        employeeName: payload.employeeName,
        dateOfRecurrence: moment(Date.parse(payload.dateOfRecurrence)),
        dateOfOriginalInjury: moment(Date.parse(payload.dateOfOriginalInjury)),
        dateReported: moment(Date.parse(payload.dateReported)),
        datePhysicianAppt: moment(Date.parse(payload.datePhysicianAppt)),
        workRestrictions: payload.workRestrictions,
        dateOfTransition: moment(Date.parse(payload.dateOfTransition)),
        endDateOfTransition: payload?.endDateOfTransition
          ? moment(Date.parse(payload.endDateOfTransition))
          : null,
        incidentDesc: payload.incidentDesc,
        reinjuryOccurred: payload.reinjuryOccurred,
        injuryHistory: payload.injuryHistory,
        employeeSignature: sig?.source_url || sig,
        physicianInfo: payload.physicianInfo,
        timeNotDetermined: payload.timeNotDetermined
      }
    };

    let cc = yield call(apiV4.createContinuousCare, payload);

    if (sig?._id) {
      yield call(api.updateAttachment, {
        _id: sig._id,
        ownerId: cc._id,
        ownerType: 'continuousCare'
      });
    }

    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* updateContinuousCare(apiV4, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const sig = payload.employeeSignature;

    let update = {
      _id: payload._id,
      companyId: company._id,
      supervisorId: payload.supervisorId,
      hrRepId: payload.hrRepId,
      formData: {
        employeeName: payload.employeeName,
        dateOfRecurrence: moment(Date.parse(payload.dateOfRecurrence)),
        dateOfOriginalInjury: moment(Date.parse(payload.dateOfOriginalInjury)),
        dateReported: moment(Date.parse(payload.dateReported)),
        datePhysicianAppt: moment(Date.parse(payload.datePhysicianAppt)),
        incidentDesc: payload.incidentDesc,
        reinjuryOccurred: payload.reinjuryOccurred,
        injuryHistory: payload.injuryHistory,
        workRestrictions: payload.workRestrictions,
        dateOfTransition: moment(Date.parse(payload.dateOfTransition)),
        endDateOfTransition: payload?.endDateOfTransition
          ? moment(Date.parse(payload.endDateOfTransition))
          : null,
        employeeSignature: sig?.source_url || sig,
        physicianInfo: payload.physicianInfo,
        timeNotDetermined: payload.timeNotDetermined
      }
    };

    if (payload.supervisorSignature) {
      let supSig = payload.supervisorSignature;
      update.isApprovedBySupervisor = supSig !== undefined;
      update.formData.supervisorSignature = supSig?.source_url || supSig;
    }
    if (payload.hrSignature) {
      let hrSig = payload.hrSignature;
      update.isApprovedByHr = hrSig !== undefined;
      update.formData.hrSignature = hrSig?.source_url || hrSig;
    }

    yield call(apiV4.updateContinuousCare, update);

    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* deleteContinuousCare(apiV4, { payload }) {
  try {
    yield call(apiV4.deleteContinuousCare, payload);

    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* fetchCustomDocumentTemplates(apiV4) {
  try {
    const response = yield call(apiV4.fetchCustomDocumentTemplates);

    yield put(fetchCustomDocumentTemplatesResponse(response));
  } catch (e) {
    console.log(e);
  }
}

export function* createCustomDocumentTemplate(apiV4, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);

    payload.fields.forEach(field => {
      field.type = field.type.value ?? field.type;
      field.options = field?.options?.map(option => option.option ?? option);
    });

    payload = {
      ...payload,
      shouldHaveExpiredDate: payload.shouldHaveExpiredDate,
      companyId: activeCompany._id
    };

    yield call(apiV4.createCustomDocumentTemplates, payload);
    yield put(setActiveCustomDocumentTemplate({}));
    history.goBack();
  } catch (e) {
    console.log(e);
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateCustomDocumentTemplate(apiV4, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);

    payload.fields.forEach(field => {
      field.type = field.type.value ? field.type.value : field.type;

      if (field.options.length > 0 && field.options[0].option) {
        field.options = field.options.map(option => option.option);
      }
    });

    payload = {
      ...payload,
      companyId: activeCompany._id
    };

    yield call(apiV4.updateCustomDocumentTemplates, payload);
    yield put(setActiveCustomDocumentTemplate({}));
    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* deleteCustomDocumentTemplate(apiV4, { payload }) {
  try {
    yield call(apiV4.deleteCustomDocumentTemplates, payload._id);
    yield put(setActiveCustomDocumentTemplate({}));
    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* fetchCustomDocuments(api, apiV4) {
  try {
    const company = yield select(getActiveCompany);

    let response = [];
    response = yield call(apiV4.fetchDocumentsByType, 'CustomDocument');

    const attachments = yield all(
      response.map(document =>
        call(
          api.fetchAttachmentByOwnerId,
          company._id,
          document._id,
          'documents'
        )
      )
    );

    response.map((res, index) => (res.attachments = attachments[index]));

    const templates = yield call(apiV4.fetchCustomDocumentTemplates);

    yield put(fetchCustomDocumentTemplatesResponse(templates));
    yield put(fetchCustomDocumentsResponse(response));
  } catch (e) {
    console.log(e);
  }
}

export function* createCustomDocument(api, apiV4, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const activeGroup = yield select(getActiveLocationId);
    const activeProject = yield select(getActiveProject);
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);
    let sigs = [];

    payload.fields.forEach(f => {
      if (f.type === 'signature' && f.answer?._id) {
        sigs.push(f.answer);
        f.answer = f.answer.source_url;
      }
    });

    payload = {
      documentType: 'CustomDocument',
      label: payload.label,
      expires: payload.expires,
      documentData: {
        fields: payload.fields
      },
      companyId: activeCompany._id,
      groupIds: activeGroup ? [activeGroup._id] : [],
      projectIds: activeProject ? [activeProject._id] : []
    };

    const response = yield call(apiV4.createDocument, payload);

    yield all(
      sigs.map(s =>
        call(api.updateAttachment, {
          _id: s._id,
          ownerId: response._id,
          ownerType: 'customDocument'
        })
      )
    );

    let newAttachments = [];

    if (uploadedAttachments.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      newAttachments = temp;
    }

    response.attachments = newAttachments;

    yield put(setActiveCustomDocument(response));
    yield put(setActiveCustomDocumentTemplate({}));
    yield put(clearUploadedAttachments());
    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* updateCustomDocument(api, apiV4, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const uploadedAttachments = yield select(getAddedAttachmentsSelector);
    const sigs = [];

    payload.fields.forEach(f => {
      if (f.type === 'signature' && f?.answer?._id) {
        sigs.push(f.answer);
        f.answer = f.answer.source_url;
      }
    });

    yield all(
      sigs.map(s =>
        call(api.updateAttachment, {
          _id: s._id,
          ownerId: payload._id,
          ownerType: 'customDocument'
        })
      )
    );

    payload = {
      documentType: 'CustomDocument',
      label: payload.label,
      expires: payload.expires,
      documentData: {
        fields: payload.fields
      },
      companyId: activeCompany._id,
      _id: payload._id
    };

    const response = yield call(apiV4.updateDocument, payload);

    let newAttachments = [];

    if (uploadedAttachments.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = uploadedAttachments.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
      );

      newAttachments = temp;
    }

    response.attachments = [...(response.attachments || []), ...newAttachments];

    yield put(setActiveCustomDocument(response));
    yield put(setActiveCustomDocumentTemplate({}));
    yield put(clearUploadedAttachments());
    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* deleteCustomDocument(apiV4, { payload }) {
  try {
    yield call(apiV4.deleteDocument, payload);
    yield put(setActiveCustomDocument({}));
    yield put(setActiveCustomDocumentTemplate({}));
    history.goBack();
  } catch (e) {
    console.log(e);
  }
}

export function* deleteCustomDocumentAttachment(api, { payload }) {
  try {
    const activeCD = yield select(getActiveCustomDocSelector);

    yield call(api.deleteAttachment, payload._id);

    const attachments = yield call(
      api.fetchAttachmentByOwnerId,
      activeCD.companyId,
      activeCD._id,
      'documents'
    );

    activeCD.attachments = attachments;

    yield put(setActiveCustomDocument(activeCD));

    yield put(
      addMessage({ error: false, message: 'Attachment Deleted Successfully' })
    );
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}
