import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import history from '../../../history';
import {
  fetchSafetyAuditRepeatableById,
  fetchSafetyAuditScanOnDemandById,
  createSafetyAudit,
  createSafetyAuditRepeatable,
  updateSafetyAuditRepeatable,
  deleteSafetyAuditRepeatable,
  createSafetyAuditScanOnDemand,
  updateSafetyAuditScanOnDemand,
  deleteSafetyAuditScanOnDemand,
  fetchSafetyAuditTemplates
} from '../../../api/v4';
import {
  FREQUENCY_OPTIONS,
  MONTHLY_FREQUENCY_OPTIONS,
  YEARLY_FREQUENCY_OPTIONS
} from '../../../constants/constants';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../../selectors/company';
import { isFutureDate, notFutureDate } from '../../../utils/formValidation';
import Card from '../../../components/Card';
import { DatePicker } from '../../../components/inputs/DateTimePicker';
import DayOfWeekButtons from '../../../components/inputs/DayOfWeekButtons';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import DueBy from '../../../components/inputs/DueBy';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import HierarchySelector from '../../../components/HierarchySelector';
import RadioButtons from '../../../components/inputs/RadioButtons';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import {
  Textbox,
  Checkbox,
  Dropdown,
  EmployeeDropdown
} from '../../../components/inputs';

export default function SafetyAuditCreate() {
  const company = useSelector(getActiveCompany);
  const group = useSelector(getActiveLocationId);
  const project = useSelector(getActiveProject);
  const [audit, setAudit] = useState({});
  const [templates, setTemplates] = useState([]);
  const [templateDropdown, setTemplateDropdown] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { safetyAuditId, type } = useParams();

  const fromEquipment =
    history.location?.state?.audit?.ownerType === 'equipment';

  useEffect(() => {
    if (safetyAuditId && type) {
      type === 'repeatable'
        ? fetchSafetyAuditRepeatableById(safetyAuditId).then(response =>
            setAudit({ ...response, schedule: 'repeating' })
          )
        : fetchSafetyAuditScanOnDemandById(safetyAuditId).then(response =>
            setAudit({ ...response, schedule: 'scan' })
          );
      setIsEditing(true);
    } else {
      setAudit({
        groupId: group?._id,
        projectId: project?._id
      });
      setIsCreating(true);
    }

    if (!fromEquipment) {
      fetchSafetyAuditTemplates().then(response => {
        setTemplates(response);
        setTemplateDropdown(
          response.map(template => {
            return { label: template.title, value: template._id };
          })
        );
      });
    } else {
      const passedState = history.location.state;
      setIsCreating(true);
      setAudit({ ...passedState?.audit });
      setTemplates(passedState.linkedSafetyAuditTemplates);
      setTemplateDropdown(
        passedState.linkedSafetyAuditTemplates.map(template => {
          return { label: template.title, value: template._id };
        })
      );
    }
  }, [fromEquipment, group, project, safetyAuditId, type]);

  const handleTemplateSelection = value => {
    const template = { ...templates.find(temp => temp._id === value) };
    delete template._id;
    delete template.auditLog;
    template.sections.forEach(s => {
      delete s._id;
      s.questions.forEach(q => {
        delete q._id;
      });
    });
    setAudit({ ...audit, ...template, template: value });
    setHasChanges(true);
  };

  const submit = () => {
    if (audit.repeatingOn) {
      audit.repeatingOn = Array.isArray(audit?.repeatingOn)
        ? audit?.repeatingOn
        : [audit?.repeatingOn];
    }

    audit.companyId = company._id;
    audit.projectId = audit.projectId ? audit.projectId : null;

    if (audit?._id) {
      if (audit.schedule === 'repeating') {
        updateSafetyAuditRepeatable(audit).then(() =>
          history.push('/app/safetyAudits')
        );
      } else {
        updateSafetyAuditScanOnDemand(audit).then(() =>
          history.push('/app/safetyAudits')
        );
      }
    } else {
      if (audit.schedule === 'immediate') {
        createSafetyAudit(audit).then(() =>
          fromEquipment ? history.goBack() : history.push('/app/safetyAudits')
        );
      } else if (audit.schedule === 'repeating') {
        createSafetyAuditRepeatable({
          ...audit
        }).then(() => history.push('/app/safetyAudits'));
      } else {
        createSafetyAuditScanOnDemand({
          ...audit
        }).then(() => history.push('/app/safetyAudits'));
      }
    }
  };

  const canSubmit =
    audit?.groupId &&
    ((audit?.schedule === 'immediate' &&
      audit?.dueDate &&
      notFutureDate(audit?.dueDate) &&
      audit?.assignedTo?.length > 0 &&
      audit?.sections?.length > 0) ||
      (audit?.schedule === 'repeating' &&
        audit?.frequency &&
        audit?.dueBy &&
        audit?.dueByTime &&
        (audit?.frequency === 'quarterly' || audit?.repeatingOn?.length > 0) &&
        audit?.assignedTo?.length > 0 &&
        audit?.sections?.length > 0) ||
      (audit?.schedule === 'scan' &&
        (audit?.sections?.title || audit?.sections?.length > 0)));

  const handleDelete = () => {
    if (audit.schedule === 'repeating') {
      deleteSafetyAuditRepeatable(audit).then(() =>
        history.push('/app/safetyAudits')
      );
    } else {
      deleteSafetyAuditScanOnDemand(audit).then(() =>
        history.push('/app/safetyAudits')
      );
    }
  };

  if (isEditing) {
    const template = templates?.find(t => t.title === audit.title);
    audit.template = template?._id;
  }

  const header = (
    <Header
      title="Safety Audit"
      section={isCreating ? 'Create New Audit' : 'Edit Audit'}
      clickBack={() =>
        hasChanges
          ? setShowSaveChangesModal(true)
          : fromEquipment
          ? history.goBack()
          : history.push('/app/safetyAudits')
      }
      rightButtons={{
        text: 'Delete Safety Audit',
        onClick: () => setShowDeleteModal(true),
        color: 'red',
        visible: isEditing
      }}
    />
  );

  const footer = (
    <SaveCancelFooter
      editing={isEditing}
      cancelButtonClick={() =>
        isEditing && hasChanges
          ? setShowSaveChangesModal(true)
          : isEditing && !hasChanges
          ? history.push('/app/safetyAudits')
          : setShowSaveChangesModal(true)
      }
      saveButtonDisabled={!canSubmit}
      saveButtonClick={submit}
    />
  );

  const template = templates.find(r => r.title === audit.title);

  return (
    <HeaderAndFooter
      Header={header}
      Footer={footer}
      showFooter={isCreating || isEditing}
    >
      <Card>
        <HierarchySelector
          onGroupChange={values => setAudit({ ...audit, groupId: values })}
          groupId={audit?.groupId}
          groupDisabled={!isCreating || fromEquipment}
          onProjectChange={values => setAudit({ ...audit, projectId: values })}
          projectId={audit?.projectId}
          projectDisabled={!audit?.groupId || !isCreating || fromEquipment}
        />
        <Dropdown
          options={templateDropdown}
          fieldLabel="Safety Audit Template"
          currentValue={audit?.template || template}
          isRequired
          onChange={value => handleTemplateSelection(value)}
          placeholder="Select a safety audit"
        />
        <RadioButtons
          fieldLabel="Schedule"
          options={[
            { value: 'immediate', label: 'Immediately' },
            { value: 'repeating', label: 'Repeating' },
            { value: 'scan', label: 'Scan on Demand' }
          ]}
          isRequired
          onChange={values =>
            setAudit({ ...audit, schedule: values, frequency: '' })
          }
          currentValue={audit?.schedule}
          disabled={!isCreating || fromEquipment}
        />
        <Textbox
          currentValue={audit?.jobLocation}
          fieldLabel="Specific Location"
          onChange={e => setAudit({ ...audit, jobLocation: e })}
          type="textarea"
          placeholder="Briefly describe where this Safety Audit should be done."
        />
        {audit?.schedule === 'repeating' && (
          <Dropdown
            options={FREQUENCY_OPTIONS}
            fieldLabel="Frequency:"
            currentValue={audit?.frequency}
            onChange={value =>
              setAudit({ ...audit, frequency: value, repeatingOn: [] })
            }
            isRequired
          />
        )}
        {audit?.frequency === 'weekly' && (
          <DayOfWeekButtons
            fieldLabel="Assign Every:"
            disabled={audit?.schedule !== 'repeating'}
            onChange={values => setAudit({ ...audit, repeatingOn: values })}
            currentValue={audit?.repeatingOn}
          />
        )}
        {audit?.frequency === 'monthly' && (
          <Dropdown
            options={MONTHLY_FREQUENCY_OPTIONS}
            fieldLabel="Assign Every:"
            currentValue={audit?.repeatingOn}
            onChange={values => setAudit({ ...audit, repeatingOn: values })}
            isRequired
          />
        )}
        {audit?.frequency === 'yearly' && (
          <Dropdown
            options={YEARLY_FREQUENCY_OPTIONS}
            fieldLabel="Assign Every:"
            currentValue={audit?.repeatingOn}
            onChange={values => setAudit({ ...audit, repeatingOn: values })}
            isRequired
          />
        )}
        {audit?.schedule === 'repeating' ? (
          <DueBy
            fieldLabel="Due By:"
            isRequired
            dueBy={audit?.dueBy}
            dueTime={audit?.dueByTime}
            onDueByChange={v => setAudit({ ...audit, dueBy: v })}
            onDueTimeChange={v => setAudit({ ...audit, dueByTime: v })}
          />
        ) : audit?.schedule === 'immediate' ? (
          <DatePicker
            fieldLabel="Due By:"
            isRequired
            onChange={value => setAudit({ ...audit, dueDate: value })}
            pickTime
            currentValue={audit?.dueDate}
            name="createAudit"
            warning={isFutureDate(audit?.dueDate)}
            touched={audit?.dueDate}
          />
        ) : (
          ''
        )}
        {audit?.schedule !== 'scan' && (
          <EmployeeDropdown
            multi
            selectButtons
            permissions={[100, 400, 500, 900]}
            fieldLabel="Assignee(s):"
            currentValue={audit?.assignedTo}
            isRequired
            onChange={values => setAudit({ ...audit, assignedTo: values })}
            searchable
            name="multiEmployees"
            group={audit.groupId}
          />
        )}
        {audit?.schedule !== 'scan' && (
          <Checkbox
            fieldLabel="Assign Individually"
            name="isIndividual"
            onChange={value => setAudit({ ...audit, isIndividual: value })}
            currentValue={audit?.isIndividual}
          />
        )}
        {audit?.schedule === 'repeating' && !isCreating && (
          <Checkbox
            fieldLabel="Pause Safety Audit"
            name="pauseAudit"
            onChange={value => setAudit({ ...audit, isPaused: value })}
            currentValue={audit?.isPaused}
          />
        )}
      </Card>
      <SaveChangesModal
        isOpen={showSaveChangesModal}
        onRequestClose={() => setShowSaveChangesModal(false)}
        savingWhat="Safety Audit"
        submitActions={() => history.goBack()}
      />
      <DeleteItemModal
        isOpen={showDeleteModal}
        onRequestClose={() => setShowDeleteModal(false)}
        deletingWhat="Safety Audit"
        submitActions={handleDelete}
      />
    </HeaderAndFooter>
  );
}
