import React, { useState, useEffect } from 'react';
import { noBubble } from '../../utils/events';
import { useDispatch } from 'react-redux';

import { addMessage } from '../../actions/messages';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Modal from '../../components/Modal';
import { Textbox } from '../../components/inputs';
import noDashboardImage from '../../assets/images/noDashboards.png';
import UserBadge from '../../components/UserBadge';
import history from '../../history';
import DashboardCard from '../DashboardCard';

import { deleteDashboardById, fetchDashboards } from '../../api/v4';

import styles from './leaderboardStyles.module.scss';

export default function Leaderboards() {
  const [dashboards, setDashboards] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filteredDashboards, setFilteredDashboards] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [dashboardToDelete, setDashboardToDelete] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchDashboards().then(response => {
      setDashboards(response);
      setFilteredDashboards(response);
    });
  }, []);

  const header = (
    <Header
      title="Leaderboards"
      rightButtons={{
        color: 'blue',
        text: 'Create Dashboard',
        onClick: () => history.push('/app/leaderboards/create')
      }}
    />
  );

  const handleSearch = text => {
    setSearchText(text);
    let filtered = dashboards.filter(d =>
      d.name?.toLowerCase().includes(text?.toLowerCase())
    );
    setFilteredDashboards(filtered);
  };

  const handleDelete = () => {
    deleteDashboardById(dashboardToDelete._id).then(() => {
      dispatch(addMessage({ error: false, message: 'Successfully Deleted' }));
      setDashboardToDelete(null);
      setOpenDeleteModal(false);
      fetchDashboards().then(response => {
        setDashboards(response);
        setFilteredDashboards(response);
      });
    });
  };

  return (
    <HeaderAndFooter Header={header} className={styles.container}>
      <Textbox
        currentValue={searchText}
        onChange={value => handleSearch(value)}
        placeholder="Search"
        inputClassName={styles.search}
      />
      {filteredDashboards?.length ? (
        <div className={styles.cards}>
          {filteredDashboards?.map(d => (
            <DashboardCard
              dashboard={d}
              handleEditClick={value => {
                noBubble(value);
                history.push(`/app/leaderboards/dashboard/edit/${d?._id}`);
              }}
              handleDeleteClick={value => {
                noBubble(value);
                setDashboardToDelete(d);
                setOpenDeleteModal(true);
              }}
              fromLeaderboard
              bottomStyles={styles.bottom}
              readOnly={d.readOnly}
            />
          ))}
        </div>
      ) : (
        <img
          src={noDashboardImage}
          alt="noDashboards"
          class={styles.noDashboardImage}
        />
      )}
      <Modal
        title="Confirm Delete"
        titleClassName="redHeader"
        isOpen={openDeleteModal}
        submitButtonColor="red"
        submitButtonText="Delete"
        testID="Delete"
        onRequestClose={() => {
          setOpenDeleteModal(false);
        }}
        submitActions={handleDelete}
      >
        <h2>Are you sure you want to delete {dashboardToDelete?.name}?</h2>
        {dashboardToDelete?.sharedBy && (
          <>
            This dashboard was shared with you by{' '}
            <UserBadge userId={dashboardToDelete?.sharedBy} nameOnly />.
          </>
        )}
      </Modal>
    </HeaderAndFooter>
  );
}
