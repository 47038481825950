import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classnames from 'classnames/bind';
import history from '../../../history';
import {
  fetchSafetyAuditById,
  updateSafetyAuditStage,
  filterSafetyAuditSections,
  setSafetyAuditNumber,
  deleteSafetyAudit,
  updateNotes,
  deleteNoteById
} from '../../../api/v4';
import { addMessage } from '../../../actions/messages';
import { useSocket } from '../../../utils/withSocket';
import AdditionalCommentsCard from '../../../components/safetyAuditCards/AdditionalCommentsCard';
import Card from '../../../components/Card';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import {
  Dropdown,
  TwoColumn,
  Button,
  InputRow
} from '../../../components/inputs';
import Header from '../../../components/Header';
import { SaveCancelFooter } from '../../../components/Footer';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import ProgressBar from '../../../components/ProgressBar';
import QuestionCard from '../../../components/safetyAuditCards/QuestionCard';
import StageSelector from '../../../components/StageSelector';
import SummaryCard from '../../../components/safetyAuditCards/SummaryCard';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import { getLoggedInUser } from '../../../selectors/users';
import { deleteAttachment } from '../../../api';

import styles from './summary.module.scss';

const bStyles = classnames.bind(styles);

export default function Summary() {
  const socket = useSocket();
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [audit, setAudit] = useState({});
  const [sections, setSections] = useState([]);
  const [additionalNotes, setAdditionalNotes] = useState();
  const [buttonText, setButtonText] = useState('Collapse All Sections');
  const [caOptions, setCaOptions] = useState([]);
  const [responseOptions, setResponseOptions] = useState([]);
  const [collasped, setCollasped] = useState([]);
  const [generated, setGenerated] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [showDeleteImageModal, setShowDeleteImageModal] = useState(false);
  const [showDeleteNotesModal, setShowDeleteNotesModal] = useState(false);
  const [openSaveChangesModal, setOpenSaveChangesModal] = useState(false);
  const [updatedNotes, setUpdatedNotes] = useState([]);
  const [noteToDelete, setNoteToDelete] = useState([]);
  const [imageToDelete, setImageToDelete] = useState({
    id: null,
    sectionNumber: null,
    questionNumber: null
  });
  const { safetyAuditId } = useParams();
  const loggedInUser = useSelector(getLoggedInUser);

  useEffect(() => {
    if (safetyAuditId) {
      fetchSafetyAuditById(safetyAuditId).then(response => {
        const localupdatedNotes = localStorage.getItem('updatedNotes');

        const localEditing = localStorage.getItem('editing') || null;

        if (localupdatedNotes && localupdatedNotes !== 'undefined') {
          const parsedLocalNotes = JSON.parse(localupdatedNotes);
          if (parsedLocalNotes.length) {
            setUpdatedNotes(parsedLocalNotes);
            response.sections.map((section, si) => {
              return section.questions.map((question, qi) => {
                return question.notes.map((note, ni) => {
                  const foundNote = parsedLocalNotes.find(
                    localNote => localNote._id === note._id
                  );
                  if (foundNote !== undefined) {
                    response.sections[si].questions[qi].notes[ni] = foundNote;
                  }
                  return foundNote;
                });
              });
            });
          }
        }

        if (localEditing && localEditing !== 'undefined') {
          setEditing(JSON.parse(localEditing));
        }

        setAudit(response);
        setAdditionalNotes(response.additionalNotes);
        setSections(response.sections);
        const c = [];
        response.sections.map(() => c.push({ collasped: false }));
        setCollasped(c);

        if (!socket) return;
        socket.emit('subscribeRa', response._id);

        socket.on('raURL', value => {
          setGenerated(value);
          setLoading(false);
        });
        socket.on('printFail', error => {
          setGenerated(null);
          dispatch(
            addMessage({
              error: true,
              message: `Could not generate Safety Audit: ${error}`
            })
          );
        });
      });
    }
  }, [dispatch, safetyAuditId, socket]);

  const onFilterUpdate = (fo, type) => {
    if (type === 'response') {
      setResponseOptions(fo);
      filterSafetyAuditSections(audit._id, {
        response: fo,
        ca: caOptions
      }).then(response => setSections(response));
    } else {
      setCaOptions(fo);
      filterSafetyAuditSections(audit._id, {
        response: responseOptions,
        ca: fo
      }).then(response => setSections(response));
    }
  };

  const handleCollaspedSection = index => {
    collasped[index].collasped = !collasped[index].collasped;
    setCollasped([...collasped]);
  };

  const handleCollaspeAll = () => {
    let c = collasped.map(() => {
      return { collasped: buttonText === 'Collapse All Sections' };
    });
    setCollasped([...c]);
    if (buttonText === 'Collapse All Sections') {
      setButtonText('Expand All Sections');
    } else {
      setButtonText('Collapse All Sections');
    }
  };

  const sectionData = sectionIndex => {
    return audit?.summary?.data[sectionIndex];
  };

  const openRaLink = () => {
    return window.open(generated.url, '_blank');
  };

  const raButton = () => {
    socket.emit('generateRaPdf', audit?._id);
    setGenerated(false);
    setLoading(true);
  };

  const submitSafetyAuditName = newName => {
    setAudit(audit => ({ ...audit, safetyAuditNumber: newName }));
    setSafetyAuditNumber(audit._id, newName);
  };

  const initiateGoBack = () => {
    if (editing && updatedNotes.length) {
      setOpenSaveChangesModal(true);
    } else {
      handleGoBack();
    }
  };

  const handleGoBack = () => {
    localStorage.removeItem('editing');
    localStorage.removeItem('updatedNotes');
    history.goBack();
  };

  const name =
    generated === null
      ? 'Generate PDF Link'
      : generated?.url
      ? 'Regenerate PDF'
      : 'Generating PDF';

  const initiateDeleteNote = (sectionNumber, questionNumber, note) => {
    setNoteToDelete({ sectionNumber, questionNumber, note });
    setShowDeleteNotesModal(true);
  };

  const deleteNote = () => {
    const { sectionNumber, questionNumber, note } = noteToDelete;

    deleteNoteById({
      note: note,
      ownerId: safetyAuditId,
      ownerType: 'safetyAuditQuestion'
    });

    let newSections = [...sections];

    const index = newSections[sectionNumber].questions[
      questionNumber - 1
    ].notes.findIndex(object => object._id === note._id);

    newSections[sectionNumber].questions[questionNumber - 1].notes[
      index
    ].isRemoved = true;

    let newUpdatedNotes = [...updatedNotes];
    const updatedNotesIndex = updatedNotes.findIndex(
      updateNote => updateNote._id === note._id
    );

    if (updatedNotesIndex !== -1) {
      newUpdatedNotes[updatedNotesIndex].isRemoved = true;
    }

    setGenerated(null);
    setUpdatedNotes(newUpdatedNotes);
    setSections(newSections);
    setShowDeleteNotesModal(false);
  };

  const handleChangeNotes = note => {
    let newUpdatedNotes = [...updatedNotes];
    const index = updatedNotes.findIndex(
      updateNote => updateNote._id === note._id
    );
    if (index === -1) {
      newUpdatedNotes.push(note);
    } else {
      newUpdatedNotes[index].value = note.value;
    }

    setUpdatedNotes(newUpdatedNotes);
    localStorage.setItem('updatedNotes', JSON.stringify(newUpdatedNotes));
  };

  const initiateDeletePhoto = (item, sectionNumber, questionNumber) => {
    setImageToDelete({ id: item._id, sectionNumber, questionNumber });
    setShowDeleteImageModal(true);
  };

  const completeDeletePhoto = () => {
    const { id, sectionNumber, questionNumber } = imageToDelete;
    let newSections = sections;

    newSections[sectionNumber].questions[
      questionNumber - 1
    ].attachments = sections[sectionNumber].questions[
      questionNumber - 1
    ].attachments.filter(image => image._id !== id);
    setSections(newSections);
    deleteAttachment(id);
    setShowDeleteImageModal(false);
  };

  const handleSubmit = () => {
    localStorage.removeItem('editing');
    localStorage.removeItem('updatedNotes');
    setShowDeleteNotesModal(false);
    updateNotes({
      notes: updatedNotes.filter(note => note.value !== ''),
      ownerId: safetyAuditId,
      ownerType: 'safetyAudit'
    })
      .then(() => {
        dispatch(
          addMessage({
            error: false,
            message: `Notes Saved Successfully!`
          })
        );
      })
      .catch(err => {
        dispatch(
          addMessage({
            error: true,
            message: `An Error Occurred. Please try again.`
          })
        );
      });

    if (updatedNotes.length) {
      setGenerated(null);
    }

    setEditing(false);
    setUpdatedNotes([]);
  };

  const header = (
    <Header
      title="Safety Audit Summary"
      section={audit.safetyAuditNumber}
      editName={submitSafetyAuditName}
      clickBack={initiateGoBack}
      center={
        <StageSelector
          label="Safety Audit Stage"
          options={[
            { label: 'Severity Review', value: 2 },
            { label: 'Closed', value: 3 }
          ]}
          currentValue={audit?.currentWfStage}
          onChange={option =>
            updateSafetyAuditStage({
              _id: audit._id,
              currentWfStage: option
            }).then(setAudit)
          }
        />
      }
      pageActionOptions={[
        {
          label: 'Edit Notes/Photos',
          visible:
            audit?.isCompleted && loggedInUser.accessLevel === 900 && !editing,
          color: 'blueOutline',
          onClick: () => {
            setEditing(true);
          },
          disabled: loading || name === 'Generating PDF'
        },
        {
          label: name,
          visible: !editing,
          color: 'blueOutline',
          onClick: raButton,
          disabled: loading || name === 'Generating PDF'
        },
        {
          label: 'Download PDF',
          onClick: openRaLink,
          color: 'greenOutline',
          visible: generated?.url && !editing
        },
        {
          label: 'Delete Safety Audit',
          visible: true,
          color: 'redOutline',
          onClick: () => setOpenDeleteModal(true)
        }
      ]}
    />
  );

  const tooltips = audit?.summary?.data?.map(s => {
    return (
      <div className={styles.tooltip}>
        <div className={bStyles({ greenOutline: true })}>
          <span
            className={bStyles({ white: true })}
          >{`${s?.acceptable}/${s?.total}`}</span>{' '}
          Marked Acceptable
        </div>
        <div className={bStyles({ redOutline: true })}>
          <span
            className={bStyles({ white: true })}
          >{`${s?.unacceptable}/${s?.total}`}</span>{' '}
          Marked Unacceptable
        </div>
        <div className={bStyles({ greyOutline: true })}>
          <span
            className={bStyles({ white: true })}
          >{`${s?.notApplicable}/${s?.total}`}</span>{' '}
          Marked Not Applicable
        </div>
      </div>
    );
  });

  const footer = (
    <SaveCancelFooter
      editing={editing}
      saveButtonDisabled={updatedNotes.some(
        note => note.value.trim() === '' && !note.isRemoved
      )}
      saveButtonClick={() => handleSubmit()}
      cancelButtonClick={() => {
        if (updatedNotes.length) {
          setOpenSaveChangesModal(true);
        } else {
          handleGoBack();
        }
      }}
    />
  );

  return (
    <HeaderAndFooter Header={header} showFooter={editing} Footer={footer}>
      <SummaryCard audit={audit} />
      <AdditionalCommentsCard notes={additionalNotes} disabled />
      <Card showHeader title="Filters">
        <TwoColumn>
          <Dropdown
            fieldLabel="Response"
            options={[
              { value: 1, label: 'Acceptable' },
              { value: 2, label: 'Unacceptable' },
              { value: 3, label: 'Not Applicable' }
            ]}
            multi
            currentValue={responseOptions}
            onChange={values => onFilterUpdate(values, 'response')}
          />
          <Dropdown
            fieldLabel="Corrective Action"
            options={[
              { value: 'complete', label: 'Completed' },
              { value: 'pastDue', label: 'Past Due' },
              { value: 'assigned', label: 'Assigned' },
              { value: 'notAssigned', label: 'Not Assigned' }
            ]}
            multi
            currentValue={caOptions}
            onChange={values => onFilterUpdate(values, 'correctiveAction')}
          />
        </TwoColumn>
      </Card>
      <InputRow className={styles.overallFooter} space>
        <Button
          color="blue"
          text={buttonText}
          onClick={() => handleCollaspeAll()}
        />
      </InputRow>
      {sections?.map((section, i) => (
        <Card
          key={i}
          title={`${section.title}: ${section.questions.length} Questions`}
          showHeader
          collapsible
          name={i}
          rightButton={
            <Button
              color="white"
              imageClass={bStyles({ flipChevron: collasped?.[i]?.collasped })}
              image={require('../../../assets/images/Chevron.png')}
              onClick={() => handleCollaspedSection(i)}
            />
          }
          collasped={collasped?.[i]?.collasped}
          showSubHeader
          subHeader={
            <div>
              <div className={styles.sectionTitle}>
                Section Score: {Math.round(sectionData(i)?.value)}%
              </div>
              <ProgressBar
                showTooltip
                tooltip={tooltips[i]}
                className={styles.progressBar}
                scale={sectionData(i)?.total}
                bars={[
                  {
                    width: sectionData(i)?.acceptable,
                    color: 'green'
                  },
                  {
                    width: sectionData(i)?.unacceptable,
                    color: 'red'
                  },
                  {
                    width: sectionData(i)?.notApplicable,
                    color: 'grey'
                  }
                ]}
                name={`progressBar-${i}`}
              />
            </div>
          }
        >
          {section.questions.map((question, questionIndex) => (
            <QuestionCard
              isCompleted
              section={section}
              question={question}
              questionNumber={`${questionIndex + 1}`}
              key={questionIndex}
              locationId={audit.groupId}
              editing={editing}
              initiateDeleteNote={initiateDeleteNote}
              sectionNumber={i}
              initiateDeletePhoto={initiateDeletePhoto}
              handleChangeNotes={handleChangeNotes}
              audit={audit}
              updatedNotes={updatedNotes}
            />
          ))}
        </Card>
      ))}
      <DeleteItemModal
        isOpen={openDeleteModal}
        deletingWhat="Safety Audit"
        onRequestClose={() => setOpenDeleteModal(false)}
        submitActions={() =>
          deleteSafetyAudit(audit).then(() => history.push('/app/safetyAudits'))
        }
      />
      <DeleteItemModal
        isOpen={showDeleteImageModal}
        deletingWhat="Safety Audit Image"
        onRequestClose={() => setShowDeleteImageModal(false)}
        submitActions={completeDeletePhoto}
        hideCustomTemplate
      />
      <DeleteItemModal
        isOpen={showDeleteNotesModal}
        deletingWhat="Note"
        onRequestClose={() => setShowDeleteNotesModal(false)}
        submitActions={deleteNote}
        hideCustomTemplate
      />
      <SaveChangesModal
        savingWhat="Safety Audit Comments"
        isOpen={openSaveChangesModal}
        onRequestClose={() => setOpenSaveChangesModal(false)}
        submitActions={() => handleGoBack()}
      />
    </HeaderAndFooter>
  );
}
