import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  fetchFireExposureById,
  createFireExposure,
  deleteFireExposureById,
  printExposure
} from '../../api/v4';
import history from '../../history';
import { getMyUserId } from '../../selectors/users';
import { parseTime, renderTime } from '../../utils/dateHelpers';
import BlockText from '../../components/inputs/BlockText';
import Card from '../../components/Card';
import DeleteItemModal from '../../components/Modal/deleteItemModal';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import {
  Textbox,
  TwoColumn,
  EmployeeDropdown,
  Dropdown
} from '../../components/inputs';
import { DatePicker, TimePicker } from '../../components/inputs/DateTimePicker';
import RadioButtons from '../../components/inputs/RadioButtons';
import { SaveCancelFooter } from '../../components/Footer';
import SaveChangesModal from '../../components/Modal/saveChangesModal';

export default function FireExposureForm() {
  const { id } = useParams();
  const loggedInUserId = useSelector(getMyUserId);
  const [creating, setCreating] = useState(true);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [fireForm, setFireForm] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);

  useEffect(() => {
    if (id) {
      fetchFireExposureById(id).then(response => {
        const [hours, minutes] = parseTime(response.incidentTime);
        setFireForm({
          ...response,
          hours,
          minutes
        });
      });
      setCreating(false);
    }
  }, [id]);

  const incidentTypeOptions = [
    { value: 0, label: 'Residential Fire' },
    { value: 1, label: 'Commercial Fire' },
    { value: 2, label: 'Dumpster/Trash Fire' },
    { value: 3, label: 'Explosion' },
    { value: 4, label: 'Extrication' },
    { value: 5, label: 'Haz Mat' },
    { value: 6, label: 'Industrial Fire' },
    { value: 7, label: 'Railroad Incident' },
    { value: 8, label: 'Rescue' },
    { value: 9, label: 'Training Fire' },
    { value: 10, label: 'Vehicle Fire' },
    { value: 11, label: 'Water Response' },
    { value: 12, label: 'Wildland Fire' },
    { value: 13, label: 'EMS' },
    { value: 14, label: 'Other' }
  ];

  const activityPerformedOptions = [
    { value: 0, label: 'EMS' },
    { value: 1, label: 'Extriction' },
    { value: 2, label: 'Fire Suppression Ext.' },
    { value: 3, label: 'Fire Suppression Int.' },
    { value: 4, label: 'Haz Mat' },
    { value: 5, label: 'Incident Command' },
    { value: 6, label: 'Investigation' },
    { value: 7, label: 'Ladder Operations (ext)' },
    { value: 8, label: 'Overhaul' },
    { value: 9, label: 'Pump Operator' },
    { value: 10, label: 'RIC' },
    { value: 11, label: 'Search and Rescue' },
    { value: 12, label: 'Ventilation Exterior' },
    { value: 13, label: 'Ventilation Interior' },
    { value: 14, label: 'Support Activites' },
    { value: 15, label: 'Water Rescue' },
    { value: 16, label: 'Other' }
  ];

  const exposureOptions = [
    { value: 0, label: 'Smoke' },
    { value: 1, label: 'Chemical' },
    { value: 2, label: 'Water' },
    { value: 3, label: 'Bodily Fluids' },
    { value: 4, label: 'Other' }
  ];

  const exposureRoutesOptions = [
    { value: 0, label: 'Absorption' },
    { value: 1, label: 'Inhaled' },
    { value: 2, label: 'Ingested' },
    { value: 3, label: 'Skin Contact' },
    { value: 4, label: 'Bite/Sting' },
    { value: 5, label: 'Mucocutaneous (nose, mouth, eye)' }
  ];

  const symptomsOptions = [
    { value: 0, label: 'Cough' },
    { value: 1, label: 'Eyes Burn' },
    { value: 2, label: 'None at this time' },
    { value: 3, label: 'Respiratory Irritation' },
    { value: 4, label: 'Skin Irritation' },
    { value: 5, label: 'Other' }
  ];

  const infectiousOptions = [
    { value: 0, label: 'Hepatitis A' },
    { value: 1, label: 'Hepatitis B' },
    { value: 2, label: 'Hepatitis C' },
    { value: 3, label: 'TB' },
    { value: 4, label: 'HIV' },
    { value: 5, label: 'N/A' },
    { value: 6, label: 'Other' }
  ];

  const handleForm = value => {
    setFireForm({ ...fireForm, ...value });
    setHasUnsavedChanges(true);
  };

  const print = () => {
    printExposure(id)
      .then(response => {
        window.open(response, '_blank');
      })
      .catch(error => console.log(error));
  };

  const header = (
    <Header
      title="Personal Exposure Report"
      clickBack={() =>
        hasUnsavedChanges ? setOpenUnsavedChangesModal(true) : history.goBack()
      }
      section={creating ? 'Create' : 'View'}
      needsSaved={hasUnsavedChanges}
      pageActionOptions={
        !creating
          ? [
              {
                visible: true,
                label: 'Download Form',
                color: 'blueOutline',
                onClick: () => print()
              },
              {
                visible: true,
                label: 'Delete Form',
                color: 'redOutline',
                onClick: () => setOpenDeleteModal(true)
              }
            ]
          : []
      }
    />
  );
  const footer = (
    <SaveCancelFooter
      saveButtonClick={() =>
        createFireExposure(fireForm).then(response => {
          setFireForm(response);
          setCreating(false);
          setHasUnsavedChanges(false);
        })
      }
      cancelButtonClick={() => history.goBack()}
    />
  );
  return (
    <HeaderAndFooter Header={header} Footer={footer} showFooter={creating}>
      <Card>
        <BlockText blockOfText="This form is NOT to be used to report a claim. It is to be used after an incident so that the firefighter will have recorded in a personal file that they were exposed to smoke, chemicals, hazardous materials, or infectious disease which might be harmful to their health at some future time. Not all categories may be applicable. If you need to report an injury, you will need to fill out a First Report of Injury." />
        <EmployeeDropdown
          currentValue={loggedInUserId}
          fieldLabel="Employee Name"
          disabled
        />
        <TwoColumn>
          <Textbox
            fieldLabel="FDID#"
            currentValue={fireForm.fdidNumber}
            onChange={value => handleForm({ fdidNumber: value })}
            disabled={!creating}
          />
          <Textbox
            fieldLabel="Unit"
            currentValue={fireForm.unit}
            onChange={value => handleForm({ unit: value })}
            disabled={!creating}
          />
          <Textbox
            fieldLabel="Apparatus Assigned During Incident"
            currentValue={fireForm.apparatusAssigned}
            onChange={value => handleForm({ apparatusAssigned: value })}
            disabled={!creating}
          />
          <TwoColumn style={{ margin: 0 }}>
            <DatePicker
              fieldLabel="Incident Date"
              currentValue={fireForm.incidentDate}
              onChange={value => handleForm({ incidentDate: value })}
              disabled={!creating}
              name="incidentDate"
            />
            <TimePicker
              fieldLabel="Alarm Time"
              currentValue={fireForm.incidentTime}
              onChange={(hours, minutes) =>
                handleForm({ incidentTime: renderTime(hours, minutes, true) })
              }
              disabled={!creating}
              name="alarmTime"
              hours={fireForm.hours}
              minutes={fireForm.minutes}
            />
          </TwoColumn>
          <Textbox
            fieldLabel="Incident #"
            currentValue={fireForm.incidentNumber}
            onChange={value => handleForm({ incidentNumber: value })}
            disabled={!creating}
          />
          <Textbox
            fieldLabel="Incident Location (Address)"
            currentValue={fireForm.incidentLocation}
            onChange={value => handleForm({ incidentLocation: value })}
            disabled={!creating}
          />
        </TwoColumn>
        <TwoColumn>
          <Dropdown
            multi
            searchable
            selectButtons
            fieldLabel="Incident Type"
            currentValue={fireForm.incidentType}
            onChange={value => handleForm({ incidentType: value })}
            disabled={!creating}
            options={incidentTypeOptions}
          />
          {fireForm.incidentType?.includes(14) ? (
            <Textbox
              fieldLabel="Other Incident Type"
              currentValue={fireForm.incidentTypeOther}
              onChange={value => handleForm({ incidentTypeOther: value })}
              disabled={!creating}
            />
          ) : null}
        </TwoColumn>
        <TwoColumn>
          <Dropdown
            multi
            searchable
            selectButtons
            fieldLabel="Activity Performed - Fire/Rescue/EMS"
            currentValue={fireForm.activityPerformed}
            onChange={value => handleForm({ activityPerformed: value })}
            disabled={!creating}
            options={activityPerformedOptions}
          />
          {fireForm.activityPerformed?.includes(16) ? (
            <Textbox
              fieldLabel="Other Activity Performed"
              currentValue={fireForm.activityPerformedOther}
              onChange={value => handleForm({ activityPerformedOther: value })}
              disabled={!creating}
            />
          ) : null}
        </TwoColumn>
        <TwoColumn>
          <Dropdown
            searchable
            fieldLabel="Type of Exposure"
            currentValue={fireForm.typeOfExposure}
            onChange={value => handleForm({ typeOfExposure: value })}
            disabled={!creating}
            options={exposureOptions}
          />
          {fireForm.typeOfExposure === 4 ? (
            <Textbox
              fieldLabel="Other Type of Exposure"
              currentValue={fireForm.typeOfExposureOther}
              onChange={value => handleForm({ typeOfExposureOther: value })}
              disabled={!creating}
            />
          ) : null}
        </TwoColumn>
        <Textbox
          fieldLabel="Chemical/Product Name, if known"
          currentValue={fireForm.chemicalName}
          onChange={value => handleForm({ chemicalName: value })}
          disabled={!creating}
        />
        <TwoColumn>
          <Textbox
            fieldLabel="Duration of Exposure"
            currentValue={fireForm.durationOfExposure}
            onChange={value => handleForm({ durationOfExposure: value })}
            disabled={!creating}
          />
          <Textbox
            fieldLabel="Water Temp and Conditions"
            currentValue={fireForm.waterTempAndConditions}
            onChange={value => handleForm({ waterTempAndConditions: value })}
            disabled={!creating}
          />
        </TwoColumn>
        <Dropdown
          multi
          searchable
          selectButtons
          fieldLabel="Exposure Route"
          currentValue={fireForm.exposureRoute}
          onChange={value => handleForm({ exposureRoute: value })}
          disabled={!creating}
          options={exposureRoutesOptions}
        />
        <Textbox
          fieldLabel="PPE at time of Exposure"
          currentValue={fireForm.ppeAtExposure}
          onChange={value => handleForm({ ppeAtExposure: value })}
          disabled={!creating}
        />
        <TwoColumn>
          <Dropdown
            multi
            searchable
            selectButtons
            fieldLabel="Symptoms"
            currentValue={fireForm.symptoms}
            onChange={value => handleForm({ symptoms: value })}
            disabled={!creating}
            options={symptomsOptions}
          />
          {fireForm.symptoms?.includes(5) ? (
            <Textbox
              fieldLabel="Other Symptoms"
              currentValue={fireForm.symptomsOther}
              onChange={value => handleForm({ symptomsOther: value })}
              disabled={!creating}
            />
          ) : null}
        </TwoColumn>
        <TwoColumn>
          <Dropdown
            searchable
            fieldLabel="If known, Infectious Disease"
            currentValue={fireForm.infectiousDisease}
            onChange={value => handleForm({ infectiousDisease: value })}
            disabled={!creating}
            options={infectiousOptions}
          />
          {fireForm.infectiousDisease === 6 ? (
            <Textbox
              fieldLabel="Other Infectious Disease"
              currentValue={fireForm.infectiousDiseaseOther}
              onChange={value => handleForm({ infectiousDiseaseOther: value })}
              disabled={!creating}
            />
          ) : null}
        </TwoColumn>
        <TwoColumn>
          <RadioButtons
            fieldLabel="Was SO4 notified?"
            currentValue={fireForm.so4Notified}
            onChange={value => handleForm({ so4Notified: value })}
            options={[
              { label: 'Yes', value: 'yes' },
              { label: 'No', value: 'no' }
            ]}
            disabled={!creating}
          />
          <DatePicker
            fieldLabel="If so, on what date?"
            currentValue={fireForm.so4NotifiedDate}
            onChange={value => handleForm({ so4NotifiedDate: value })}
            disabled={!creating}
            name="so4NotifiedDate"
          />
        </TwoColumn>
      </Card>
      <DeleteItemModal
        isOpen={openDeleteModal}
        deletingWhat="Personal Exposure Report"
        onRequestClose={() => setOpenDeleteModal(false)}
        submitActions={() =>
          deleteFireExposureById(fireForm._id).then(() => history.goBack())
        }
      />
      <SaveChangesModal
        isOpen={openUnsavedChangesModal}
        onRequestClose={() => setOpenUnsavedChangesModal(false)}
        submitActions={() => history.goBack()}
        savingWhat="Personal Exposure Report"
      />
    </HeaderAndFooter>
  );
}
