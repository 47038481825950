import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import moment from 'moment';

import history from '../../history';
import * as apiV4 from '../../api/v4';
import {
  addUploadedAttachmentsRequest,
  addUploadedAttachmentsResponse,
  updateAttachmentRequest
} from '../../actions/attachments';
import {
  addIncidentCommentsRequest,
  addIncidentNotesRequest,
  deleteIncidentRequest,
  emailIncidentAssigneeRequest,
  recatagorizeReportsRequest,
  setActiveIncidentRequest,
  setActiveIncidentResponse,
  updateIncidentCommentsRequest,
  updateIncidentNotesRequest
} from '../../actions/incidents';
import { createReportResponse } from '../../actions/reports';
import { setActiveTaskRequest, loadTask } from '../../actions/tasks';
import {
  ACTION_COMPONENTS,
  ACTION_COMPONENTS_PRINT,
  CLAIM_STAGES,
  CLOSURE_COMPONENTS,
  CLOSURE_COMPONENTS_PRINT,
  DOWNLOAD_REPORTS_OPTIONS,
  HAZARD_STAGES,
  INVESTIGATION_COMPONENTS,
  INVESTIGATION_COMPONENTS_PRINT,
  REACTIVE_KEYS
} from '../../constants/constants';
import { getActiveCompany } from '../../selectors/company';
import { getActiveIncidentSelector } from '../../selectors/incidents';
import {
  getDiagramSelector,
  getAddedAttachmentsSelector,
  getPhotoEvidenceSelector,
  getNonPhotoEvidenceDiagramSelector
} from '../../selectors/attachments';
import { getActiveOshaLogSelector } from '../../selectors/oshaLogs';
import { getLoggedInUser } from '../../selectors/users';
import {
  getAvailableUsersForPermissions,
  getPercentages,
  isSectionComplete
} from '../../utils/incidentHelper';
import {
  InputRow,
  Button,
  EmployeeDropdown,
  Textbox
} from '../../components/inputs';
import SvgWithTooltip from '../../components/SvgWithTooltip';
import CheckboxGroup from '../../components/inputs/CheckboxGroup';
import IncidentStages from '../../components/incidentComponents/IncidentStages';
import Modal from '../../components/Modal';
import PermissionsDropdown from '../../components/inputs/Dropdown/permissionsDropdown';
import UserBadge from '../../components/UserBadge';
import ReportActions from '../../components/ReportActions';
import ReportComponentCard from '../../components/incidentComponents/ReportComponentCard';
import ReportSelector from '../../components/inputs/ReportSelector';
import Assignee from '../../components/Assignee';
import Header from '../../components/Header';
import ProgressBar from '../../components/ProgressBar';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import StageSelector from '../../components/StageSelector';
import { fetchAllUsersForLocation } from '../../api/v4';
import { addMessage } from '../../actions/messages';

import './index.css';

export class IncidentContainer extends Component {
  state = {
    downloadReportModalOpen: false,
    stage: '',
    openEmailAssigneeModal: false,
    emailMessage: '',
    openDeleteIncidentModal: false,
    openReassignIncidentOwnerModal: false,
    reassignUserId: undefined,
    openCreateTaskModal: false,
    reportStage: 0,
    openEditPermissionsModal: false,
    permissions: [],
    downloadReport: false,
    openRecategorizeModal: false,
    openReportTypeModal: false,
    downloadInvestigationReports: [],
    downloadActionReports: [],
    downloadClosureReports: [],
    reportOptions: [],
    users: [],
    emailInitialReportPDF: false,
    sendToList: [],
    openFileAClaimModal: false
  };

  componentDidMount() {
    const { match, activeIncident } = this.props;
    if (match.params.stage === 'action') {
      this.setState({ stage: 1 });
    } else if (match.params.stage === 'closure') {
      this.setState({ stage: 2 });
    } else {
      this.setState({ stage: '' });
    }
    this.props.setActiveIncidentReq(activeIncident);
    fetchAllUsersForLocation(activeIncident.locationId, {
      locationId: activeIncident.locationId,
      accessLevel: [400, 500, 900]
    }).then(users => this.setState({ users }));
  }

  sendInitialReportPDF = () => {
    const { sendToList } = this.state;

    const payload = {
      incidentId: this.props.activeIncident._id,
      sendToList: sendToList
    };

    apiV4.emailInitialReport(payload).then(response => {
      if (response.length > 0) {
        const failedNames = response
          .join(`, `)
          .replace(/, ((?:.(?!, ))+)$/, ' and $1');
        const plural = response.length > 1 ? 's' : '';
        this.props.addMessage({
          error: true,
          message: `${failedNames} did not get notified.
              To send notification, add an email for the user${plural}
              or have the user${plural} turn on push notifications.`
        });
      } else {
        this.props.addMessage({ error: false, message: 'Notification Sent' });
      }
    });
  };

  sendEmail = () => {
    this.props.emailIncidentAssignee({
      messageContent: this.state.emailMessage,
      incidentId: this.props.activeIncident._id,
      userId: this.props.loggedInUser._id,
      sentMessageToId: this.props.activeIncident.incidentOwner
    });

    this.setState({ openEmailAssigneeModal: false, emailMessage: '' });
  };

  handleReportComponentClick = async (reportComponent, stage) => {
    await this.props.setActiveReportComponent({
      report: reportComponent,
      stage
    });

    if (reportComponent[0] === 'Initial Report') {
      history.push('/app/initalReportContainer');
    } else if (reportComponent[0][0] === 'Photo Evidence') {
      history.push('/app/photoEvidenceContainer/true');
    } else if (reportComponent[0][0] === 'Diagrams') {
      history.push('/app/photoEvidenceContainer');
    } else {
      history.push(`/app/reportSectionContainer/${stage}`);
    }
  };

  handleReassignUser = () => {
    const { setActiveIncident, activeIncident } = this.props;
    const { reassignUserId } = this.state;

    setActiveIncident({ ...activeIncident, incidentOwner: reassignUserId });
    apiV4.updateIncidentOwner({
      dontLeavePage: true,
      incidentId: activeIncident._id,
      newIncidentOwnerId: reassignUserId
    });

    this.setState({
      reassignUserId: undefined,
      openReassignIncidentOwnerModal: false
    });
  };

  submitIncidentName = async newName => {
    const { setActiveIncident, activeIncident } = this.props;
    await setActiveIncident({
      ...activeIncident,
      incidentNumber: newName,
      incidentNumberLegacy: activeIncident.incidentNumber
    });

    apiV4.updateIncidentNumber({
      incidentId: activeIncident._id,
      incidentNumber: newName
    });
  };

  removeActionItem = async actionItem => {
    const {
      addedAttachments,
      addIncidentAttachments,
      loggedInUser,
      activeIncident,
      updateComment,
      updateNote
    } = this.props;

    actionItem = {
      ...actionItem,
      isRemoved: true,
      isRemovedByOwner: loggedInUser._id === activeIncident.incidentOwner
    };

    if (actionItem.type === 'attachment') {
      let flatList = addedAttachments.reduce((acc, x) => acc.concat(x), []);

      flatList = flatList.map(currentAttachment => {
        if (currentAttachment._id === actionItem._id) {
          return { ...actionItem };
        }

        return { ...currentAttachment };
      });

      await this.props.updateAttachment({ ...actionItem });
      await apiV4.updateActivityAttachments({ ...actionItem });
      await addIncidentAttachments(flatList);
    } else if (actionItem.type === 'comment') {
      await updateComment(actionItem);
    } else if (actionItem.type === 'log') {
      await updateNote(actionItem);
    }
  };

  updateReportStage = reportStage => {
    const { activeIncident, setActiveIncident } = this.props;

    apiV4.updateIncidentStage(activeIncident._id, {
      reportStage
    });

    setActiveIncident({
      ...activeIncident,
      reportStage
    });

    this.setState({ reportStage });
  };

  handleReportSelectionFromTask = async taskObject => {
    const reportComponents = this.props.activeIncident.reportComponents;

    const reportComponent = reportComponents.filter(
      report => report[0] === taskObject.reportComponentName
    );

    await this.props.setActiveReportComponent({ report: reportComponent });

    if (reportComponent[0][0] === 'Photo Evidence') {
      history.push('/app/photoEvidenceContainer/true');
    } else if (reportComponent[0][0] === 'Diagrams') {
      history.push('/app/photoEvidenceContainer');
    } else {
      history.push('/app/reportSectionContainer');
    }
  };

  addUsersToIncident = async () => {
    const { permissions } = this.state;
    const { setActiveIncident, activeIncident } = this.props;

    const perms = permissions.map(permission => ({
      userId: permission.value,
      action: 'View',
      closure: permission.accessLevel < 500 ? 'None' : 'View',
      investigation: 'View'
    }));

    await setActiveIncident({
      ...activeIncident,
      permissions: [...activeIncident.permissions, ...perms]
    });

    await this.setState({ permissions: [] });
  };

  removeUsersFromIncident = async user => {
    const { setActiveIncident, activeIncident } = this.props;

    const perms = activeIncident.permissions.filter(
      permission => permission.userId !== user.userId
    );

    await setActiveIncident({
      ...activeIncident,
      permissions: [...perms]
    });
  };

  handleUserPermissionUpdates = user => {
    const { setActiveIncident, activeIncident } = this.props;

    const userIndex = activeIncident.permissions.findIndex(
      perm => perm.userId === user.userId
    );

    let incident = { ...activeIncident };

    incident.permissions[userIndex] = {
      ...activeIncident.permissions[userIndex],
      ...user
    };

    setActiveIncident(incident);
  };

  handlePermissions = () => {
    apiV4.updateIncidentPermissions(
      this.props.activeIncident._id,
      this.props.activeIncident.permissions
    );
    this.setState({ openEditPermissionsModal: false });
  };

  downloadReport = async () => {
    this.setState({ downloadReport: true, downloadReportModalOpen: false });

    const {
      downloadInvestigationReports,
      downloadActionReports,
      downloadClosureReports,
      reportOptions
    } = this.state;

    let reportsToDownload = [
      ...downloadInvestigationReports,
      ...downloadActionReports,
      ...downloadClosureReports
    ];
    let options = {};
    reportsToDownload.forEach(report => (options[report] = true));
    reportOptions.forEach(option => (options[option] = true));

    const url = await apiV4.printReport(this.props.activeIncident._id, options);

    url.map(report => window.open(report, '_blank'));
    this.closeDownloadModal();
  };

  closeDownloadModal = () => {
    this.setState({
      downloadReport: false,
      downloadReportModalOpen: false,
      downloadInvestigationReports: [],
      downloadActionReports: [],
      downloadClosureReports: [],
      reportOptions: []
    });
  };

  getCurrentNumber = (activeComponent, photoEvidence, diagrams, oshaForms) => {
    if (activeComponent[0][0] === 'Photo Evidence') {
      return photoEvidence.filter(photo => !photo.isRemoved).length;
    } else if (activeComponent[0][0] === 'Diagrams') {
      return diagrams.length;
    } else if (
      activeComponent[0][0] === 'Witness Statements' &&
      this.props.activeIncident.isWitnessLegacy
    ) {
      const subSections = activeComponent[0][1].subSections;
      subSections.filter(section => isSectionComplete(section));
      return subSections.length;
    } else if (activeComponent[0][0] === 'Witness Statements') {
      return activeComponent[0][1]?.statements?.length ?? 0;
    } else if (activeComponent[0][0] === 'OSHA Compliance') {
      return oshaForms.filter(o => o._id).length;
    } else if (activeComponent[0][0] === 'Corrective Actions') {
      return activeComponent[0][6]?.minSections;
    }

    return '';
  };

  filterDownloadableComponents = options =>
    options.filter(
      option =>
        this.props.activeIncident.reportComponents
          .map(component => component[0])
          .includes(option.label) || option.label === 'Initial Report'
    );

  typeOfClaimAccess = () => {
    const { activeCompany, activeIncident, loggedInUser } = this.props;
    if (loggedInUser.isAccountOwner) return 'Edit';

    const companyAccess = activeCompany?.claimPermissions?.find(
      p => p.userId === loggedInUser._id
    );
    const claimAccess = activeIncident?.claimPermissions?.find(
      p => p.userId === loggedInUser._id
    );

    if (!companyAccess && !claimAccess) return 'None';

    if (
      !activeIncident.claimId &&
      (companyAccess?.readOnly ?? claimAccess?.readOnly)
    )
      return 'None';

    return companyAccess?.readOnly ?? claimAccess?.readOnly ? 'View' : 'Edit';
  };

  render() {
    const {
      loggedInUser,
      activeIncident,
      deleteIncident,
      addComment,
      addNote,
      nonPhotoEvidenceDiagrams,
      addAttachment,
      activeCompany,
      photoEvidence,
      diagrams,
      oshaForms,
      addReports,
      setActiveTask,
      loadTaskWithComments
    } = this.props;

    if (!activeIncident?._id) return <div />;

    const {
      downloadReportModalOpen,
      stage,
      openEmailAssigneeModal,
      emailMessage,
      openDeleteIncidentModal,
      openReassignIncidentOwnerModal,
      reassignUserId,
      reportStage,
      openEditPermissionsModal,
      permissions,
      downloadReport,
      openRecategorizeModal,
      openReportTypeModal,
      downloadInvestigationReports,
      downloadActionReports,
      downloadClosureReports,
      reportOptions,
      users,
      emailInitialReportPDF,
      sendToList,
      openFileAClaimModal
    } = this.state;

    const permUsers = getAvailableUsersForPermissions(users, activeIncident);

    const percentages =
      activeIncident &&
      getPercentages(
        activeIncident.reportComponents,
        Number(stage),
        activeIncident.reportTemplateIds
      );

    const getReportStages =
      activeIncident && activeIncident.reportTemplateIds
        ? REACTIVE_KEYS.indexOf(
            parseInt(activeIncident.reportTemplateIds[0], 10)
          ) > -1
          ? CLAIM_STAGES.map((stage, index) => {
              return {
                value: index,
                label: stage
              };
            })
          : HAZARD_STAGES.map((stage, index) => {
              return {
                value: index,
                label: stage
              };
            })
        : [];

    const rStage = reportStage || activeIncident.reportStage;
    const center = (
      <StageSelector
        options={getReportStages}
        currentValue={rStage?.value ?? rStage}
        onChange={this.updateReportStage}
        bareValues={false}
        label={
          REACTIVE_KEYS.indexOf(
            parseInt(activeIncident.reportTemplateIds[0], 10)
          ) > -1
            ? 'Claim Stage'
            : 'Hazard Stage'
        }
      />
    );

    const right = (
      <Assignee
        text="Incident Owner"
        user={activeIncident?.incidentOwner}
        options={[
          {
            label: 'Email Incident Owner',
            onClick: () =>
              this.setState({
                openEmailAssigneeModal: true
              }),
            visible: true
          },
          {
            label: 'Reassign Incident',
            visible:
              loggedInUser?.accessLevel === 900 ||
              activeIncident?.incidentOwner === loggedInUser._id,
            onClick: () =>
              this.setState({
                openReassignIncidentOwnerModal: true
              })
          },
          {
            label: 'Assign Permissions',
            visible:
              loggedInUser?.accessLevel === 900 ||
              activeIncident?.incidentOwner === loggedInUser._id,
            onClick: () =>
              this.setState({
                openEditPermissionsModal: true
              })
          },
          {
            label: 'Delete Incident',
            visible:
              loggedInUser?.accessLevel === 900 ||
              activeIncident?.incidentOwner === loggedInUser._id,
            onClick: () => this.setState({ openDeleteIncidentModal: true })
          },
          {
            label: 'Email Initial Report PDF',
            visible: true,
            onClick: () => this.setState({ emailInitialReportPDF: true })
          }
        ]}
      />
    );

    const header = (
      <Header
        clickBack={() =>
          history.push(activeIncident?.returnPath ?? '/app/dashboard')
        }
        title="Incident ID"
        section={activeIncident?.incidentNumber}
        editName={this.submitIncidentName}
        center={center}
        right={right}
      />
    );

    const initialReportComponent = ['Initial Report', []];

    return (
      <>
        <HeaderAndFooter Header={header} className="incidentContainer-mainPage">
          <div className="incidentContainer-mainPage--left">
            <div
              className={
                stage === 2 &&
                activeIncident.reportTemplateIds.includes('2') &&
                activeCompany.showClaims &&
                ['View', 'Edit'].includes(this.typeOfClaimAccess())
                  ? 'incidentContainer-mainPage--categorization claims'
                  : 'incidentContainer-mainPage--categorization'
              }
            >
              <Button
                text={`${
                  activeIncident.claimSubmitted
                    ? 'View'
                    : activeIncident.claimInProgress
                    ? 'Continue'
                    : 'File'
                } Claim`}
                color="blue"
                onClick={() =>
                  activeIncident.claimId
                    ? history.push(
                        `/app/claims/create/${activeIncident.claimId}`
                      )
                    : this.setState({ openFileAClaimModal: true })
                }
                className="file-claim-button"
              />

              <div className="incidentContainer-reportCategories">
                <div className="incidentContainer-reportCategories--reported">
                  <span>
                    Reported at{' '}
                    {moment(Date.parse(activeIncident.createdAt)).format(
                      'hh:mm A'
                    )}
                  </span>
                  <span style={{ fontWeight: 'bold' }}>
                    {activeIncident.createdAt &&
                      moment(Date.parse(activeIncident.createdAt)).format('LL')}
                  </span>
                </div>
                {activeIncident.reportTemplateIds.map((templateId, index) => (
                  <SvgWithTooltip type={templateId} key={index} />
                ))}
              </div>
              {downloadReport ? (
                <ReactLoading
                  type="spinningBubbles"
                  color="#008284"
                  className="loading"
                />
              ) : (
                <Button
                  text="Download Reports"
                  color="blue"
                  onClick={() =>
                    this.setState({ downloadReportModalOpen: true })
                  }
                />
              )}
            </div>
            <div className="incidentContainer-incidentCard">
              <IncidentStages
                changeStage={stage => this.setState({ stage })}
                selectedStage={stage}
                activeIncident={activeIncident}
                loggedInUser={loggedInUser}
              />
              <div className="incidentContainer-incidentCard--content">
                <ProgressBar
                  scale={percentages.total}
                  complete={percentages.completed / percentages.total === 1}
                  bars={[
                    {
                      width: percentages.completed,
                      color: 'green'
                    },
                    {
                      width: percentages.started,
                      color: 'blue'
                    },
                    {
                      width: percentages.pastDue,
                      color: 'red'
                    }
                  ]}
                />
                {stage === '' ? (
                  <div className="incidentContainer-cardContainer">
                    <ReportComponentCard
                      reportComponent={initialReportComponent}
                      setActiveReportComponent={() =>
                        this.handleReportComponentClick(
                          initialReportComponent,
                          'investigation'
                        )
                      }
                      activeIncident={activeIncident}
                      loggedInUser={loggedInUser}
                      stage="investigation"
                      setActiveTask={setActiveTask}
                      activeCompany={activeCompany}
                    />
                    {INVESTIGATION_COMPONENTS.map((component, index) => {
                      const activeComponent = activeIncident.reportComponents.filter(
                        report => report[0] === component
                      );
                      if (!activeComponent?.length)
                        return <div key={index}></div>;
                      return (
                        <ReportComponentCard
                          key={index}
                          reportComponent={activeComponent[0]}
                          setActiveReportComponent={() =>
                            this.handleReportComponentClick(
                              activeComponent,
                              'investigation'
                            )
                          }
                          activeIncident={activeIncident}
                          loggedInUser={loggedInUser}
                          currentNumber={this.getCurrentNumber(
                            activeComponent,
                            photoEvidence,
                            diagrams,
                            oshaForms
                          )}
                          showNumbers={true}
                          stage="investigation"
                          setActiveTask={setActiveTask}
                          activeCompany={activeCompany}
                        />
                      );
                    })}
                  </div>
                ) : stage === 1 ? (
                  <div className="incidentContainer-cardContainer">
                    {ACTION_COMPONENTS.map((component, index) => {
                      const activeComponent = activeIncident.reportComponents.filter(
                        report => report[0] === component
                      );
                      if (!activeComponent?.length) return <></>;
                      return (
                        <ReportComponentCard
                          key={index}
                          reportComponent={activeComponent[0]}
                          setActiveReportComponent={() =>
                            this.handleReportComponentClick(
                              activeComponent,
                              'action'
                            )
                          }
                          currentNumber={this.getCurrentNumber(
                            activeComponent,
                            photoEvidence,
                            diagrams,
                            oshaForms
                          )}
                          activeIncident={activeIncident}
                          loggedInUser={loggedInUser}
                          showNumbers={true}
                          stage="action"
                          setActiveTask={setActiveTask}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div className="incidentContainer-cardContainer">
                    {CLOSURE_COMPONENTS.map((component, index) => {
                      const activeComponent = activeIncident.reportComponents.filter(
                        report => report[0] === component
                      );
                      if (!activeComponent?.length) return <></>;
                      return (
                        <ReportComponentCard
                          key={index}
                          reportComponent={activeComponent[0]}
                          setActiveReportComponent={() =>
                            this.handleReportComponentClick(
                              activeComponent,
                              'closure'
                            )
                          }
                          activeIncident={activeIncident}
                          loggedInUser={loggedInUser}
                          showNumbers={true}
                          stage="closure"
                          setActiveTask={setActiveTask}
                          activeCompany={activeCompany}
                          oshaForms={
                            activeComponent[0][0] === 'OSHA Compliance'
                              ? oshaForms
                              : null
                          }
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="incidentContainer-mainPage--right">
            <ReportActions
              comments={activeIncident.incidentActivity.comments}
              notes={activeIncident.incidentActivity.notes}
              activityItems={activeIncident.incidentActivity.activityItems}
              tasks={activeIncident.incidentActivity.openTasks}
              attachments={nonPhotoEvidenceDiagrams}
              loggedInUser={loggedInUser}
              activeIncident={activeIncident}
              addNote={addNote}
              addComment={addComment}
              removeActionItem={actionItem => this.removeActionItem(actionItem)}
              addAttachment={addAttachment}
              handleReportSelection={task =>
                this.handleReportSelectionFromTask(task)
              }
              setActiveTask={setActiveTask}
              loadTask={loadTaskWithComments}
              stage={stage}
            />
          </div>
        </HeaderAndFooter>
        <Modal
          title="Download Reports"
          titleClassName="blueHeader"
          isOpen={downloadReportModalOpen}
          submitButtonColor="blue"
          submitButtonText="Download"
          onRequestClose={this.closeDownloadModal}
          submitActions={() => this.downloadReport()}
        >
          <div className="incidentContainer-downloadReports">
            <div className="incidentContainer-downloadReports--components">
              <div className="incidentContainer-downloadReports--stages">
                <div>Investigation</div>
                <div>Action</div>
                <div>Closure</div>
              </div>
              <div className="incidentContainer-downloadReports--checkboxContainer">
                <div className="downloadReports-checkboxContainer--checkboxGroup">
                  <CheckboxGroup
                    options={this.filterDownloadableComponents(
                      INVESTIGATION_COMPONENTS_PRINT
                    )}
                    name="investigation"
                    className="incidentContainer-downloadReports--input"
                    currentValue={downloadInvestigationReports}
                    onChange={value =>
                      this.setState({ downloadInvestigationReports: value })
                    }
                  />
                </div>
                <div className="downloadReports-checkboxContainer--checkboxGroup">
                  <CheckboxGroup
                    options={this.filterDownloadableComponents(
                      ACTION_COMPONENTS_PRINT
                    )}
                    name="action"
                    className="incidentContainer-downloadReports--input"
                    currentValue={downloadActionReports}
                    onChange={value =>
                      this.setState({ downloadActionReports: value })
                    }
                  />
                </div>
                <div className="downloadReports-checkboxContainer--checkboxGroup">
                  <CheckboxGroup
                    options={this.filterDownloadableComponents(
                      CLOSURE_COMPONENTS_PRINT
                    )}
                    name="closure"
                    className="incidentContainer-downloadReports--input"
                    currentValue={downloadClosureReports}
                    onChange={value =>
                      this.setState({ downloadClosureReports: value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="incidentContainer-downloadReports--selectAll">
              <Button
                text="Select All"
                color="blue"
                inputClassName="incidentContainer-downloadReports--input"
                onClick={() =>
                  this.setState({
                    downloadInvestigationReports: this.filterDownloadableComponents(
                      INVESTIGATION_COMPONENTS_PRINT
                    ).map(component => component.value),
                    downloadActionReports: this.filterDownloadableComponents(
                      ACTION_COMPONENTS_PRINT
                    ).map(component => component.value),
                    downloadClosureReports: this.filterDownloadableComponents(
                      CLOSURE_COMPONENTS_PRINT
                    ).map(component => component.value)
                  })
                }
              />
            </div>
            <div className="incidentContainer-downloadReports--options">
              <CheckboxGroup
                options={DOWNLOAD_REPORTS_OPTIONS}
                name="options"
                currentValue={reportOptions}
                className="incidentContainer-downloadReports--input"
                columns={true}
                onChange={value => this.setState({ reportOptions: value })}
              />
            </div>
          </div>
        </Modal>
        <Modal
          title="Download Reports"
          titleClassName="blueHeader"
          isOpen={downloadReportModalOpen}
          submitButtonColor="blue"
          submitButtonText="Download"
          onRequestClose={this.closeDownloadModal}
          submitActions={() => this.downloadReport()}
        >
          <div className="incidentContainer-downloadReports">
            <div className="incidentContainer-downloadReports--components">
              <div className="incidentContainer-downloadReports--stages">
                <div>Investigation</div>
                <div>Action</div>
                <div>Closure</div>
              </div>
              <div className="incidentContainer-downloadReports--checkboxContainer">
                <div className="downloadReports-checkboxContainer--checkboxGroup">
                  <CheckboxGroup
                    options={this.filterDownloadableComponents(
                      INVESTIGATION_COMPONENTS_PRINT
                    )}
                    name="investigation"
                    className="incidentContainer-downloadReports--input"
                    currentValue={downloadInvestigationReports}
                    onChange={value =>
                      this.setState({ downloadInvestigationReports: value })
                    }
                  />
                </div>
                <div className="downloadReports-checkboxContainer--checkboxGroup">
                  <CheckboxGroup
                    options={this.filterDownloadableComponents(
                      ACTION_COMPONENTS_PRINT
                    )}
                    name="action"
                    className="incidentContainer-downloadReports--input"
                    currentValue={downloadActionReports}
                    onChange={value =>
                      this.setState({ downloadActionReports: value })
                    }
                  />
                </div>
                <div className="downloadReports-checkboxContainer--checkboxGroup">
                  <CheckboxGroup
                    options={this.filterDownloadableComponents(
                      CLOSURE_COMPONENTS_PRINT
                    )}
                    name="closure"
                    className="incidentContainer-downloadReports--input"
                    currentValue={downloadClosureReports}
                    onChange={value =>
                      this.setState({ downloadClosureReports: value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="incidentContainer-downloadReports--selectAll">
              <Button
                text="Select All"
                color="blue"
                inputClassName="incidentContainer-downloadReports--input"
                onClick={() =>
                  this.setState({
                    downloadInvestigationReports: this.filterDownloadableComponents(
                      INVESTIGATION_COMPONENTS_PRINT
                    ).map(component => component.value),
                    downloadActionReports: this.filterDownloadableComponents(
                      ACTION_COMPONENTS_PRINT
                    ).map(component => component.value),
                    downloadClosureReports: this.filterDownloadableComponents(
                      CLOSURE_COMPONENTS_PRINT
                    ).map(component => component.value)
                  })
                }
              />
            </div>
            <div className="incidentContainer-downloadReports--options">
              <CheckboxGroup
                options={DOWNLOAD_REPORTS_OPTIONS}
                name="options"
                currentValue={reportOptions}
                className="incidentContainer-downloadReports--input"
                columns={true}
                onChange={value => this.setState({ reportOptions: value })}
              />
            </div>
          </div>
        </Modal>
        <Modal
          title="Email Incident Owner"
          titleClassName="blueHeader"
          isOpen={openEmailAssigneeModal}
          submitButtonColor="blue"
          submitButtonText="Send Email"
          onRequestClose={() =>
            this.setState({ openEmailAssigneeModal: false, emailMessage: '' })
          }
          submitActions={() => this.sendEmail()}
          disableSubmit={!emailMessage}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            This email will go through the iReportSource system for record
            keeping purposes.
          </div>
          <Textbox
            fieldLabel="Email Content"
            rows={5}
            type="textarea"
            placeholder="Type email content here"
            currentValue={emailMessage}
            onChange={v => this.setState({ emailMessage: v })}
          />
        </Modal>
        <Modal
          title={`Delete ${activeIncident.incidentNumber}`}
          titleClassName="redHeader"
          isOpen={openDeleteIncidentModal}
          submitButtonColor="red"
          submitButtonText="Delete Incident"
          onRequestClose={() =>
            this.setState({ openDeleteIncidentModal: false })
          }
          submitActions={async () => await deleteIncident(activeIncident)}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Deleting this incident will delete all relavent data with the
              incident forever.{' '}
            </span>
            Are you sure?
          </div>
        </Modal>
        <Modal
          title="Reassign Incident Owner"
          titleClassName="blueHeader"
          isOpen={openReassignIncidentOwnerModal}
          submitButtonColor="blue"
          submitButtonText="Save Changes"
          onRequestClose={() =>
            this.setState({ openReassignIncidentOwnerModal: false })
          }
          submitActions={() => this.handleReassignUser()}
          disableSubmit={!reassignUserId}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            From here you can assign a different user as the owner of this
            incident.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              The previous incident owner will be removed, lose special
              privileges, and be replaced by the new incident owner.
            </span>{' '}
            Incident ownership can only by assigned to Collaborators or Admins
            with assess to the Establishment/Group of this incident.
          </div>
          <div className="incidentContainer-reassignIncident">
            <UserBadge
              size="large"
              userId={activeIncident?.incidentOwner}
              showImage
              hoverText
            />
            <img
              src={require('../../assets/images/reassignArrow.png')}
              className="reassignArrow"
              alt="assign arrow"
            />
            <UserBadge
              userId={reassignUserId}
              showImage
              hoverText
              size="large"
            />
          </div>
          <EmployeeDropdown
            options={users}
            permissions={[500, 900]}
            currentValue={reassignUserId}
            onChange={value => this.setState({ reassignUserId: value })}
          />
        </Modal>
        <Modal
          title="Edit Permissions"
          titleClassName="blueHeader"
          isOpen={openEditPermissionsModal}
          submitButtonColor="blue"
          submitButtonText="Save Changes"
          onRequestClose={() =>
            this.setState({ openEditPermissionsModal: false })
          }
          submitActions={() => this.handlePermissions()}
        >
          <div className="incidentContainer-editPermissions">
            <div className="incidentContainer-editPermissions--stages">
              <div>Users</div>
              <div>Investigation</div>
              <div>Action</div>
              <div>Closure</div>
            </div>
            <ul className="incidentContainer-editPermissions--userList">
              {activeIncident.permissions
                .map(p => ({ ...p, user: users.find(u => u._id === p.userId) }))
                .filter(p => p.user)
                .map((user, index) => (
                  <li key={index}>
                    <div className="userTable">
                      {user.user.accessLevel !== 900 && (
                        <img
                          src={require('../../assets/images/removePermissions.png')}
                          className="removePermissions"
                          alt="Remove permissions from Incident"
                          onClick={() => this.removeUsersFromIncident(user)}
                        />
                      )}
                      <UserBadge
                        userId={user.userId}
                        showImage
                        showName
                        className="incidentContainer-editPermissions--user"
                      />
                    </div>
                    <div className="userTable">
                      <PermissionsDropdown
                        currentValue={user.investigation}
                        onChange={value =>
                          this.handleUserPermissionUpdates({
                            investigation: value,
                            userId: user.userId
                          })
                        }
                        accessLevel={user.user.accessLevel}
                        stage={0}
                      />
                    </div>
                    <div className="userTable">
                      <PermissionsDropdown
                        currentValue={user.action}
                        onChange={value =>
                          this.handleUserPermissionUpdates({
                            action: value,
                            userId: user.userId
                          })
                        }
                        accessLevel={user.user.accessLevel}
                        stage={1}
                      />
                    </div>
                    <div className="userTable">
                      <PermissionsDropdown
                        currentValue={user.closure}
                        onChange={value =>
                          this.handleUserPermissionUpdates({
                            closure: value,
                            userId: user.userId
                          })
                        }
                        accessLevel={user.user.accessLevel}
                        stage={2}
                        disabled={user.user.accessLevel <= 400}
                      />
                    </div>
                  </li>
                ))}
            </ul>
            <InputRow>
              <EmployeeDropdown
                multi
                selectButtons
                employees={permUsers}
                currentValue={permissions}
                onChange={values => this.setState({ permissions: values })}
                disabled={permUsers.length === 0}
                placeholder={
                  permUsers.length === 0
                    ? 'No Users Available'
                    : 'Select Users to add'
                }
                className="incidentContainer-editPermissions--grow"
                bareValues={false}
              />
              <Button
                text="Add"
                color="blue"
                onClick={this.addUsersToIncident}
                disabled={permUsers.length === 0}
              />
            </InputRow>
            <div className="reportSectionContainer-emailAssigneeText">
              Only Collaborators and Admins may be given full permissions.{' '}
              <span style={{ color: '#c74846', fontWeight: 'bold' }}>
                Admins can not be removed from any stage or the incident as a
                whole.
              </span>{' '}
              Supervisors can only be added to Investigation, and Reporters can
              not be added.{' '}
              <span style={{ color: '#c74846', fontWeight: 'bold' }}>
                Removing a user’s permission from all stages will completely
                remove them after saving changes.
              </span>
            </div>
          </div>
        </Modal>
        <Modal
          title="Are you sure you want to add a report?"
          titleClassName="blueHeader"
          isOpen={openRecategorizeModal}
          submitButtonColor="blue"
          submitButtonText="Add Report"
          onRequestClose={() => this.setState({ openRecategorizeModal: false })}
          submitActions={async () =>
            (await addReports(this.state.reportTemplates)) &&
            this.setState({ openRecategorizeModal: false })
          }
        >
          <div className="reportSectionContainer-emailAssigneeText">
            You have added new report categories associated with this incident.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Saving these changes will change what questions appear on other
              reports within this incident and might mark completed report
              components as non-complete.{' '}
            </span>
            Are you sure?
          </div>
        </Modal>
        <Modal
          title="Email Initial Report PDF"
          titleClassName="blueHeader"
          isOpen={emailInitialReportPDF}
          className="modalBig"
          submitButtonColor="blue"
          submitButtonText="Send Email"
          disableSubmit={!sendToList.length}
          onRequestClose={() =>
            this.setState({ emailInitialReportPDF: false, sendToList: [] })
          }
          submitActions={() => {
            this.sendInitialReportPDF();
            this.setState({ emailInitialReportPDF: false, sendToList: [] });
          }}
        >
          <EmployeeDropdown
            fieldLabel="Send Initial Report PDF to"
            searchable={true}
            multi={true}
            isRequired={true}
            permissions={[100, 400, 500, 900]}
            currentValue={sendToList}
            onChange={v => {
              this.setState({ sendToList: v });
            }}
          />
        </Modal>
        <Modal
          title="Type of Incident (one or more)"
          titleClassName="blueHeader"
          wide
          isOpen={openReportTypeModal}
          className="modalBig"
          submitButtonColor="blue"
          onRequestClose={() => this.setState({ openReportTypeModal: false })}
          submitActions={() =>
            this.setState({
              openReportTypeModal: false,
              openRecategorizeModal: true
            })
          }
        >
          <ReportSelector
            accessLevel={loggedInUser.accessLevel}
            handleReportSelection={values =>
              this.setState({ reportTemplates: values })
            }
            currentReports={activeIncident.reportTemplateIds}
          />
        </Modal>
        <Modal
          title="File a Claim"
          titleClassName="blueHeader"
          isOpen={openFileAClaimModal}
          submitButtonColor="blue"
          submitButtonText="File"
          onRequestClose={() => this.setState({ openFileAClaimModal: false })}
          submitActions={() => history.push('/app/claims/create')}
        >
          Are you sure you want to file a claim?{' '}
          <span className="blueText">
            Filing a claim through iReportSource will not file claim with your
            insurance provider until you submit a claim through iReportSource.
          </span>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state),
  activeIncident: getActiveIncidentSelector(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  nonPhotoEvidenceDiagrams: getNonPhotoEvidenceDiagramSelector(state),
  activeCompany: getActiveCompany(state),
  photoEvidence: getPhotoEvidenceSelector(state),
  diagrams: getDiagramSelector(state),
  oshaForms: getActiveOshaLogSelector(state)
});

const mapDispatchToProps = dispatch => ({
  emailIncidentAssignee: payload =>
    dispatch(emailIncidentAssigneeRequest(payload)),
  setActiveReportComponent: payload => dispatch(createReportResponse(payload)),
  deleteIncident: payload => dispatch(deleteIncidentRequest(payload)),
  addComment: payload => dispatch(addIncidentCommentsRequest(payload)),
  addNote: payload => dispatch(addIncidentNotesRequest(payload)),
  setActiveIncidentReq: payload => dispatch(setActiveIncidentRequest(payload)),
  setActiveIncident: payload => dispatch(setActiveIncidentResponse(payload)),
  addIncidentAttachments: payload =>
    dispatch(addUploadedAttachmentsResponse(payload)),
  updateComment: payload => dispatch(updateIncidentCommentsRequest(payload)),
  updateNote: payload => dispatch(updateIncidentNotesRequest(payload)),
  addAttachment: payload => dispatch(addUploadedAttachmentsRequest(payload)),
  setActiveTask: payload => dispatch(setActiveTaskRequest(payload)),
  loadTaskWithComments: payload => dispatch(loadTask(payload)),
  addReports: payload => dispatch(recatagorizeReportsRequest(payload)),
  updateAttachment: payload => dispatch(updateAttachmentRequest(payload)),
  addMessage: payload => dispatch(addMessage(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(IncidentContainer);
