import React, { Component } from 'react';
import Button from '../../inputs/Button';
import Checkbox from '../../inputs/Checkbox';
import DiagramSelection from '../../DiagramSelection';
import FileDownloader from '../../FileDownloader';
import Modal from '../../Modal';
import { InputRow } from '../../inputs';
import Card from '../../Card';

import styles from './styles.module.scss';

export default class DiagramCard extends Component {
  state = {
    modalOpen: false,
    diagramCategory: { name: '', url: '' },
    diagramSubcategory: { name: '', url: '' }
  };

  canSubmit = () => {
    const { diagramCategory, diagramSubcategory } = this.state;

    if (diagramCategory.name === 'bodyDiagram') {
      return true;
    } else if (
      (diagramCategory.name === 'roadDiagram' ||
        diagramCategory.name === 'vehicleDiagram' ||
        diagramCategory.name === 'aircraftDiagram') &&
      diagramSubcategory.name
    ) {
      return true;
    }

    return false;
  };

  handleDiagramSelection = () => {
    const { diagramCategory, diagramSubcategory } = this.state;

    if (diagramCategory.name === 'bodyDiagram') {
      this.props.handleDiagramSelection({
        imageUrl: diagramCategory.url,
        diagramCategory
      });
      this.setState({ modalOpen: false });
    } else {
      this.props.handleDiagramSelection({
        imageUrl: diagramSubcategory.url,
        diagramCategory
      });
      this.setState({ modalOpen: false });
    }
  };

  render() {
    const {
      name,
      reportSectionHeader,
      bodyDiagrams,
      accidentDiagrams,
      vehicleDiagrams,
      aircraftDiagrams,
      deleteAttachment,
      canRemoveEdit,
      sectionNotApplicable,
      handleNaSection,
      hideNA,
      showAircraft
    } = this.props;

    const { modalOpen, diagramCategory, diagramSubcategory } = this.state;

    return (
      <Card
        className={styles.diagramCard}
        title={reportSectionHeader}
        showHeader
        name={name}
        rightButton={
          !hideNA && (
            <Checkbox
              fieldLabel="Not Applicable"
              onChange={value => handleNaSection(value)}
              currentValue={sectionNotApplicable}
              disabled={!canRemoveEdit}
            />
          )
        }
        wide
      >
        <InputRow>
          <Button
            text="Create A Diagram"
            color="green"
            onClick={() => this.setState({ modalOpen: true })}
            disabled={!canRemoveEdit}
          />
        </InputRow>
        <h3 className={styles.diagramCardSubHeader}>Body Diagrams</h3>
        <div className={styles.files}>
          {bodyDiagrams.map((attachment, attachmentIndex) => (
            <FileDownloader
              attachment={attachment}
              key={attachmentIndex}
              attachmentIndex={attachmentIndex}
              deleteAttachment={deleteAttachment}
              disabled={!canRemoveEdit}
            />
          ))}
        </div>
        <h3 className={styles.diagramCardSubHeader}>Road Diagrams</h3>
        <div className={styles.files}>
          {accidentDiagrams.map((attachment, attachmentIndex) => (
            <FileDownloader
              attachment={attachment}
              key={attachmentIndex}
              attachmentIndex={attachmentIndex}
              deleteAttachment={deleteAttachment}
              disabled={!canRemoveEdit}
            />
          ))}
        </div>
        <h3 className={styles.diagramCardSubHeader}>Vehicle Diagrams</h3>
        <div className={styles.files}>
          {vehicleDiagrams.map((attachment, attachmentIndex) => (
            <FileDownloader
              attachment={attachment}
              key={attachmentIndex}
              attachmentIndex={attachmentIndex}
              deleteAttachment={deleteAttachment}
              disabled={!canRemoveEdit}
            />
          ))}
        </div>
        {showAircraft && (
          <>
            <h3 className={styles.diagramCardSubHeader}>Aircraft Diagrams</h3>
            <div className={styles.files}>
              {aircraftDiagrams.map((attachment, attachmentIndex) => (
                <FileDownloader
                  attachment={attachment}
                  key={attachmentIndex}
                  attachmentIndex={attachmentIndex}
                  deleteAttachment={deleteAttachment}
                  disabled={!canRemoveEdit}
                />
              ))}
            </div>
          </>
        )}
        <Modal
          title="Select A Diagram"
          titleClassName="blueHeader"
          wide
          isOpen={modalOpen}
          submitActions={() => this.handleDiagramSelection()}
          submitButtonColor="green"
          onRequestClose={() =>
            this.setState({
              modalOpen: false,
              diagramCategory: { name: '', url: '' },
              diagramSubcategory: { name: '', url: '' }
            })
          }
          disableSubmit={!this.canSubmit()}
          submitButtonText="Continue"
        >
          <DiagramSelection
            handleCategorySelection={category =>
              this.setState({
                diagramCategory: category,
                diagramSubcategory: { name: '', url: '' }
              })
            }
            handleSubcategorySelection={subcategory =>
              this.setState({ diagramSubcategory: subcategory })
            }
            category={diagramCategory}
            subcategory={diagramSubcategory}
            showAircraft={showAircraft}
          />
        </Modal>
      </Card>
    );
  }
}
