import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

import classnames from 'classnames/bind';
import Checkbox from './Checkbox';

import styles from './input.module.scss';
const bStyles = classnames.bind(styles);

export class Input extends Component {
  render() {
    const {
      fieldLabel,
      isRequired,
      touched,
      error,
      warning,
      children,
      tooltip,
      displayTooltip,
      className,
      showNA,
      isNA,
      disabled,
      onNAChange,
      skipInputWrapper,
      inline,
      name,
      labelStyles,
      richText
    } = this.props;

    if (skipInputWrapper) return children;

    return (
      <div
        className={bStyles({ inline: inline }, 'input', className)}
        data-cy={fieldLabel + ' input'}
      >
        {displayTooltip && (
          <ReactTooltip className="customTheme" id={name}>
            {tooltip || children}
          </ReactTooltip>
        )}
        {fieldLabel && !richText ? (
          <span className={bStyles(labelStyles, 'label')}>
            {isRequired ? <span className={styles.isRequired}>*</span> : ''}
            <label data-tip="customTheme" data-for={name} htmlFor={name}>
              {fieldLabel}
              {displayTooltip && (
                <img
                  className="label-info"
                  src={require(`../../assets/images/Info Icon.png`)}
                  alt="Info Icon"
                />
              )}
            </label>
          </span>
        ) : fieldLabel && richText ? (
          <div className={bStyles(labelStyles, 'richText')}>
            {isRequired ? <span className={styles.isRequired}>*</span> : ''}
            <div>
              <ReactMarkdown children={fieldLabel} />
            </div>
          </div>
        ) : null}
        {showNA && (
          <Checkbox
            fieldLabel="Not Applicable"
            name={`${name}-na`}
            currentValue={isNA}
            onChange={onNAChange}
            disabled={disabled}
            className={styles.na}
          />
        )}
        {!isNA && this.props.children}
        {touched &&
          ((error && <span className="form-error">{error}</span>) ||
            (warning && <span className="form-warning">{warning}</span>))}
      </div>
    );
  }
}

export class TwoColumn extends Component {
  render() {
    const { className, children, ...props } = this.props;
    return (
      <div className={classnames(className, styles.twoColumn)} {...props}>
        {children}
      </div>
    );
  }
}

export class InputRow extends Component {
  render() {
    const { className, children, right, center, space, ...props } = this.props;
    const classes = {
      row: true,
      right: right,
      center: center,
      space: space
    };
    return (
      <div className={bStyles(className, classes)} {...props}>
        {children}
      </div>
    );
  }
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function nameInput(Input) {
  class nameInput extends Component {
    constructor(props) {
      super(props);
      this.state = { name: props.name || uuidv4() };
    }

    render() {
      let { name, ...props } = this.props;
      name = name || uuidv4();
      return <Input name={this.state.name} {...props} />;
    }
  }
  nameInput.displayName = `nameInput(${getDisplayName(Input)})`;
  return nameInput;
}

export { Button } from './Button';
export { Dropdown, EmployeeDropdown } from './Dropdown';
export { CalendarDropdown } from './CalendarDropdown';
export { Textbox } from './Textbox';
export { Checkbox } from './Checkbox';
export { FieldRenderer } from './fieldRenderer';
export { ButtonGroup } from './ButtonGroup';
export default Input;
