import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import history from '../../../history';
import List from '../../List';
import { TRAINING_CATEGORY_DROPDOWN } from '../../../constants/constants';
import { fetchTrainingById } from '../../../api/v4';
import {
  setActiveTrainingRequest,
  setActiveTrainingResponse
} from '../../../actions/training';

export const SafetyTrainingList = ({
  trainings,
  addToFolder,
  fromEmployee
}) => {
  const dispatch = useDispatch();
  const setActiveTraining = payload =>
    dispatch(setActiveTrainingResponse(payload));
  const setActiveTemplate = payload =>
    dispatch(setActiveTrainingRequest(payload));

  const handleViewEdit = async template => {
    let training = await fetchTrainingById(template._id);
    if (template?.trainingType?.value === 3 || template.legacy) {
      setActiveTraining(training);
      history.push('/app/training/trainingContainer');
    } else if (fromEmployee) {
      history.push(`/app/training/performTraining/${template._id}`);
    } else {
      setActiveTemplate(training);
    }
  };

  const columns = [
    { key: 'title' },
    {
      key: 'status',
      accessor: original => {
        const now = new moment();
        if (now.isAfter(original.expirationDate) && original.doesExpire) {
          return 'Expired';
        } else if (
          !original.completionDate &&
          !original.legacy &&
          now.isAfter(original.dueDate)
        ) {
          return 'Past Due';
        }
        // may need later
        // } else if (original.isReassigned) {
        //   return 'Training Re-Assigned';
        // }
        else if (original.completionDate || original.legacy) {
          return 'Completed';
        } else {
          return 'Assigned';
        }
      },
      //  'Training Re-Assigned'
      enum: ['Assigned', 'Completed', 'Expired', 'Past Due']
    },
    {
      key: 'category',
      label: 'Training Category',
      accessor: t =>
        TRAINING_CATEGORY_DROPDOWN.map(c =>
          c.value === t.category ? c.label : ''
        ) ?? ''
    },
    {
      key: 'trainingType',
      label: 'Format',
      accessor: training => training?.trainingType?.label,
      enum: [
        'Mobile/Web (Online)',
        'Scheduled (In-Person)',
        'On the Spot (In-Person)'
      ]
    },
    {
      key: 'dueDate',
      datatype: 'futureDate'
    },
    {
      key: 'completionDate',
      datatype: 'date'
    },
    {
      key: 'expirationDate',
      datatype: 'futureDate',
      accessor: r => r.expirationDate ?? 'Does Not Expire'
    },
    {
      key: 'assignedTo',
      label: 'Employees',
      datatype: 'users',
      areEmployees: r => r.legacy || r.trainingType?.value === 3
    }
  ];

  return (
    <List
      saveKey="trainingList"
      data={trainings}
      settings={columns}
      dataIsHash
      rowClick={handleViewEdit}
      getRowId={r => r._id}
      actions={
        addToFolder && [
          {
            label: 'Add to Folder',
            color: 'blue',
            onClick: addToFolder
          }
        ]
      }
    />
  );
};

export default SafetyTrainingList;
