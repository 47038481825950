import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getActiveCompany } from '../../../selectors/company';
import history from '../../../history';
import { fetchQuizzes } from '../../../api';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import List from '../../../components/List';
import Modal from '../../../components/Modal';
import { Dropdown } from '../../../components/inputs';

export default function QuizList() {
  const [quizzes, setQuizzes] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [quizDropdown, setQuizDropdown] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState(null);

  const company = useSelector(getActiveCompany);

  useEffect(() => {
    fetchQuizzes(company._id).then(response => {
      setQuizzes(response);
      setQuizDropdown(
        response.map(r => {
          return { value: r._id, label: r.title };
        })
      );
    });
  }, [company]);

  const header = (
    <Header
      section="Quiz Templates"
      pageActionOptions={[
        {
          label: 'Create Quiz',
          visible: true,
          color: 'blueOutline',
          onClick: () => {
            history.push('/app/quiz/quizTemplate');
          }
        },
        {
          label: 'Duplicate Quiz',
          onClick: () => setOpenModal(true),
          color: 'blueOutline',
          visible: quizDropdown?.length > 0
        }
      ]}
    />
  );

  const columns = [
    {
      key: 'title',
      label: 'Quiz Title'
    },
    {
      key: 'updatedAt',
      label: 'Last Updated At',
      datatype: 'date'
    }
  ];

  return (
    <HeaderAndFooter Header={header}>
      <List
        saveKey="quizList"
        dataIsHash
        data={quizzes}
        settings={columns}
        rowClick={row => history.push(`/app/quiz/quizTemplate/${row._id}`)}
      />
      <Modal
        title="Choose Template"
        titleClassName="blueHeader"
        isOpen={openModal}
        submitButtonColor="blue"
        submitButtonText="Create"
        onRequestClose={() => setOpenModal(false)}
        submitActions={() =>
          history.push(`/app/quiz/quizTemplate/${currentTemplate}/true`)
        }
      >
        <Dropdown
          options={quizDropdown}
          fieldLabel="Quiz Template"
          currentValue={currentTemplate}
          isRequired
          onChange={value => setCurrentTemplate(value)}
          placeholder="Select a quiz"
          searchable
        />
      </Modal>
    </HeaderAndFooter>
  );
}
