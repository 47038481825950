export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_SIGNOUT = 'AUTH_SIGNOUT';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';

export const NEW_USER_REGISTRATION_REQUEST = 'NEW_USER_REGISTRATION_REQUEST';
export const NEW_USER_REGISTRATION_RESPONSE = 'NEW_USER_REGISTRATION_RESPONSE';

export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

export const FETCH_USER_COMPANIES_RESPONSE = 'FETCH_USER_COMPANIES_RESPONSE';

export const SET_ACTIVE_COMPANY = 'SET_ACTIVE_COMPANY';
export const SET_ACTIVE_LOCATION = 'SET_ACTIVE_LOCATION';

export const LOAD_TASK = 'LOAD_TASK';
export const SET_ACTIVE_TASK_REQUEST = 'SET_ACTIVE_TASK_REQUEST';
export const SET_ACTIVE_TASK_RESPONSE = 'SET_ACTIVE_TASK_RESPONSE';

export const SEND_REMINDER_REQUEST = 'SEND_REMINDER_REQUEST';
export const UPDATE_REMINDERS = 'UPDATE_REMINDERS';

export const FETCH_USERS_TASKS_REQUEST = 'FETCH_USERS_TASKS_REQUEST';
export const FETCH_USERS_TASKS_RESPONSE = 'FETCH_USERS_TASKS_RESPONSE';

export const ADD_USER_TASK_REQUEST = 'ADD_USER_TASK_REQUEST';
export const ADD_USER_TASK_RESPONSE = 'ADD_USER_TASK_RESPONSE';

export const UPDATE_USER_TASK_REQUEST = 'UPDATE_USER_TASK_REQUEST';
export const UPDATE_USER_TASK_RESPONSE = 'UPDATE_USER_TASK_RESPONSE';

export const DELETE_USER_TASK_REQUEST = 'DELETE_USER_TASK_REQUEST';
export const DELETE_USER_TASK_RESPONSE = 'DELETE_USER_TASK_RESPONSE';

export const FETCH_COMPANY_AUDITS_REQUEST = 'FETCH_COMPANY_AUDITS_REQUEST';
export const FETCH_COMPANY_AUDITS_RESPONSE = 'FETCH_COMPANY_AUDITS_RESPONSE';

export const FETCH_SAFETY_INSTRUCTION_REQUEST =
  'FETCH_SAFETY_INSTRUCTIONS_REQUEST';
export const FETCH_SAFETY_INSTRUCTION_RESPONSE =
  'FETCH_SAFETY_INSTUCTIONS_RESPONSE';

export const FETCH_SDS_REQUEST = 'FETCH_SDS_REQUEST';
export const FETCH_SDS_RESPONSE = 'FETCH_SDS_RESPONSE';

export const ADD_COMPANY_REQUEST = 'ADD_COMPANY_REQUEST';
export const ADD_COMPANY_RESPONSE = 'ADD_COMPANY_RESPONSE';

export const ADD_COMPANY_LOCATION_REQUEST = 'ADD_COMPANY_LOCATION_REQUEST';
export const ADD_COMPANY_LOCATION_RESPONSE = 'ADD_COMPANY_LOCATION_RESPONSE';

export const UPDATE_COMPANY_LOCATION_REQUEST =
  'UPDATE_COMPANY_LOCATION_REQUEST';
export const UPDATE_COMPANY_LOCATION_RESPONSE =
  'UPDATE_COMPANY_LOCATION_RESPONSE';

export const DELETE_COMPANY_LOCATION_REQUEST =
  'DELETE_COMPANY_LOCATION_REQUEST';
export const DELETE_COMPANY_LOCATION_RESPONSE =
  'DELETE_COMPANY_LOCATION_RESPONSE';

export const UPDATE_LOCATION_EMPLOYEE_REQUEST =
  'UPDATE_LOCATION_EMPLOYEE_REQUEST';

export const SET_ACTIVE_GROUPS = 'SET_ACTIVE_GROUPS';

export const SET_ACTIVE_EMPLOYEE_REQUEST = 'SET_ACTIVE_EMPLOYEE_REQUEST';

export const ADD_SDS_REQUEST = 'ADD_SDS_REQUEST';
export const ADD_SDS_RESPONSE = 'ADD_SDS_RESPONSE';

export const UPDATE_SDS_REQUEST = 'UPDATE_SDS_REQUEST';
export const UPDATE_SDS_RESPONSE = 'UPDATE_SDS_RESPONSE';

export const DELETE_SDS_REQUEST = 'DELETE_SDS_REQUEST';
export const DELETE_SDS_RESPONSE = 'DELETE_SDS_RESPONSE';

export const SET_ACTIVE_SDS = 'SET_ACTIVE_SDS';

export const ADD_SAFETY_INSTRUCTION_REQUEST = 'ADD_SAFETY_INSTRUCTION_REQUEST';
export const ADD_SAFETY_INSTRUCTION_RESPONSE =
  'ADD_SAFETY_INSTRUCTION_RESPONSE';

export const UPDATE_SAFETY_INSTRUCTION_REQUEST =
  'UPDATE_SAFETY_INSTRUCTION_REQUEST';
export const UPDATE_SAFETY_INSTRUCTION_RESPONSE =
  'UPDATE_SAFETY_INSTRUCTION_RESPONSE';

export const DELETE_SAFETY_INSTRUCTION_REQUEST =
  'DELETE_SAFETY_INSTRUCTION_REQUEST';
export const DELETE_SAFETY_INSTRUCTION_RESPONSE =
  'DELETE_SAFETY_INSTRUCTION_RESPONSE';

export const SET_ACTIVE_SAFETY_INSTRUCTION = 'SET_ACTIVE_SAFETY_INSTRUCTION';

export const ADD_UPLOADED_ATTACHMENTS_REQUEST =
  'ADD_UPLOADED_ATTACHMENTS_REQUEST';
export const ADD_UPLOADED_ATTACHMENTS_RESPONSE =
  'ADD_UPLOADED_ATTACHMENTS_RESPONSE';
export const CLEAR_UPLOADED_ATTACHMENTS = 'CLEAR_UPLOADED_ATTACHMENTS';

export const SET_INCIDENT_OWNER = 'SET_INCIDENT_OWNER';

export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_RESPONSE = 'UPDATE_COMPANY_RESPONSE';

export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
export const DELETE_COMPANY_RESPONSE = 'DELETE_COMPANY_RESPONSE';

export const FETCH_INCIDENT_BASICS_REQUEST = 'FETCH_INCIDENT_BASICS_REQUEST';
export const FETCH_INCIDENT_BASICS_RESPONSE = 'FETCH_INCIDENT_BASICS_RESPONSE';

export const CREATE_INCIDENT_REQUEST = 'CREATE_INCIDENT_REQUEST';

export const UPDATE_REPORT_COMPONENT_REQUEST =
  'UPDATE_REPORT_COMPONENT_REQUEST';

export const UPDATE_INCIDENT_BASICS_REQUEST = 'UPDATE_INCIDENT_BASICS_REQUEST';
export const UPDATE_INCIDENT_BASICS_RESPONSE =
  'UPDATE_INCIDENT_BASICS_RESPONSE';

export const SAVE_INCIDENT_BASICS_REQUEST = 'SAVE_INCIDENT_BASICS_REQUEST';
export const SAVE_INCIDENT_BASICS_RESPONSE = 'SAVE_INCIDENT_BASICS_RESPONSE';

export const DELETE_INCIDENT_REQUEST = 'DELETE_INCIDENT_REQUEST';

export const SET_ACTIVE_INCIDENT_REQUEST = 'SET_ACTIVE_INCIDENT_REQUEST';
export const SET_ACTIVE_INCIDENT_RESPONSE = 'SET_ACTIVE_INCIDENT_RESPONSE';

export const SET_ACTIVE_REPORT_REQUEST = 'SET_ACTIVE_REPORT_REQUEST';

export const CREATE_REPORT_RESPONSE = 'CREATE_REPORT_RESPONSE';

export const UPDATE_REPORT_RESPONSE = 'UPDATE_REPORT_RESPONSE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_RESPONSE = 'UPDATE_USER_RESPONSE';
export const UPDATE_USER_PROFILE_PICTURE_URL =
  'UPDATE_USER_PROFILE_PICTURE_URL';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_DEFAULT_PASSWORD_REQUEST =
  'UPDATE_DEFAULT_PASSWORD_REQUEST';

export const ADD_INCIDENT_COMMENTS_REQUEST = 'ADD_INCIDENT_COMMENTS_REQUEST';
export const UPDATE_INCIDENT_COMMENTS_REQUEST =
  'UPDATE_INCIDENT_COMMENTS_REQUEST';

export const ADD_INCIDENT_NOTES_REQUEST = 'ADD_INCIDENT_NOTES_REQUEST';
export const UPDATE_INCIDENT_NOTES_REQUEST = 'UPDATE_INCIDENT_NOTES_REQUEST';

export const SET_REPORT_TEMPLATES = 'SET_REPORT_TEMPLATES';

export const SET_ACTIVE_EMPLOYEE_RESPONSE = 'SET_ACTIVE_EMPLOYEE_RESPONSE';

export const FETCH_INCIDENT_REPORT_RESPONSE = 'FETCH_INCIDENT_REPORT_RESPONSE';

export const DELETE_SAFETY_INSTRUCTION_ATTACHMENT =
  'DELETE_SAFETY_INSTRUCTION_ATTACHMENT';

export const DELETE_SDS_ATTACHMENT = 'DELETE_SDS_ATTACHMENT';

export const DELETE_INCIDENT_ATTACHMENT = 'DELETE_INCIDENT_ATTACHMENT';

export const DELETE_JSA_QUESTION_ATTACHMENT = 'DELETE_JSA_QUESTION_ATTACHMENT';

export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_RESPONSE = 'SEARCH_RESPONSE';

export const SET_ALL_LOCATIONS = 'SET_ALL_LOCATIONS';

export const CREATE_OSHA_FORM_REQUEST = 'CREATE_OSHA_FORM_REQUEST';
export const CREATE_OSHA_FORM_RESPONSE = 'CREATE_OSHA_FORM_RESPONSE';

export const UPDATE_OSHA_FORM_REQUEST = 'UPDATE_OSHA_FORM_REQUEST';
export const UPDATE_OSHA_FORM_RESPONSE = 'UPDATE_OSHA_FORM_RESPONSE';

export const FETCH_OSHA_LOGS_REQUEST = 'FETCH_OSHA_LOGS_REQUEST';

export const FETCH_OSHA_FORM_RESPONSE = 'FETCH_OSHA_FORM_RESPONSE';

export const FETCH_OSHA_300LOGS_RESPONSE = 'FETCH_OSHA_300LOGS_RESPONSE';

export const FETCH_OSHA_300A_RESPONSE = 'FETCH_OSHA_300A_RESPONSE';

export const CREATE_OSHA_LOG_REQUEST = 'CREATE_OSHA_LOG_REQUEST';
export const CREATE_OSHA_LOG_RESPONSE = 'CREATE_OSHA_LOG_RESPONSE';

export const PRINT_OSHA_REQUEST = 'PRINT_OSHA_REQUEST';
export const PRINT_OSHA_RESPONSE = 'PRINT_OSHA_RESPONSE';

export const PRINT_SELECTED_OSHA_REQUEST = 'PRINT_SELECTED_OSHA_REQUEST';

export const DELETE_ATTACHMENT_REQUEST = 'DELETE_ATTACHMENT_REQUEST';
export const DELETE_ATTACHMENT_RESPONSE = 'DELETE_ATTACHMENT_RESPONSE';

export const FETCH_QUALIFICATION_REQUEST = 'FETCH_QUALIFICATION_REQUEST';
export const FETCH_QUALIFICATION_RESPONSE = 'FETCH_QUALIFICATION_RESPONSE';

export const ADD_QUALIFICATION_REQUEST = 'ADD_QUALIFICATION_REQUEST';
export const ADD_QUALIFICATION_RESPONSE = 'ADD_QUALIFICATION_RESPONSE';

export const UPDATE_QUALIFICATION_REQUEST = 'UPDATE_QUALIFICATION_REQUEST';
export const UPDATE_QUALIFICATION_RESPONSE = 'UPDATE_QUALIFICATION_RESPONSE';

export const DELETE_QUALIFICATION_REQUEST = 'DELETE_QUALIFICATION_REQUEST';
export const DELETE_QUALIFICATION_RESPONSE = 'DELETE_QUALIFICATION_RESPONSE';

export const SET_ACTIVE_QUALIFICATION = 'SET_ACTIVE_QUALIFICATION';

export const DELETE_QUALIFICATION_ATTACHMENT =
  'DELETE_QUALIFICATION_ATTACHMENT';

export const DOWNLOAD_EMPLOYEE_QRCODE_REQUEST =
  'DOWNLOAD_EMPLOYEE_QRCODE_REQUEST';
export const DOWNLOAD_EMPLOYEE_QRCODE_RESPONSE =
  'DOWNLOAD_EMPLOYEE_QRCODE_RESPONSE';

export const ADD_PROJECT_REQUEST = 'ADD_PROJECT_REQUEST';

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';

export const SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT';

export const FETCH_QUALIFICATION_TYPES_REQUEST =
  'FETCH_QUALIFICATION_TYPES_REQUEST';
export const FETCH_QUALIFICATION_TYPES_RESPONSE =
  'FETCH_QUALIFICATION_TYPES_RESPONSE';

export const SET_ACTIVE_COMPANY_USER = 'SET_ACTIVE_COMPANY_USER';

export const FETCH_DOCUMENT_FOLDERS_REQUEST = 'FETCH_DOCUMENT_FOLDERS_REQUEST';
export const FETCH_DOCUMENT_FOLDERS_RESPONSE =
  'FETCH_DOCUMENT_FOLDERS_RESPONSE';

export const ADD_DOCUMENT_FOLDER_REQUEST = 'ADD_DOCUMENT_FOLDER_REQUEST';
export const ADD_DOCUMENT_FOLDER_RESPONSE = 'ADD_DOCUMENT_FOLDER_RESPONSE';

export const UPDATE_DOCUMENT_FOLDER_REQUEST = 'UPDATE_DOCUMENT_FOLDER_REQUEST';
export const UPDATE_DOCUMENT_FOLDER_RESPONSE =
  'UPDATE_DOCUMENT_FOLDER_RESPONSE';

export const DELETE_DOCUMENT_FOLDER_REQUEST = 'DELETE_DOCUMENT_FOLDER_REQUEST';
export const DELETE_DOCUMENT_FOLDER_RESPONSE =
  'DELETE_DOCUMENT_FOLDER_RESPONSE';

export const SET_ACTIVE_DOCUMENT_FOLDER_REQUEST =
  'SET_ACTIVE_DOCUMENT_FOLDER_REQUEST';
export const SET_ACTIVE_DOCUMENT_FOLDER_RESPONSE =
  'SET_ACTIVE_DOCUMENT_FOLDER_RESPONSE';

export const ADD_DOCUMENTS_TO_FOLDER_REQUEST =
  'ADD_DOCUMENTS_TO_FOLDER_REQUEST';
export const ADD_DOCUMENTS_TO_FOLDER_RESPONSE =
  'ADD_DOCUMENTS_TO_FOLDER_RESPONSE';

export const EMAIL_INCIDENT_ASSIGNEE_REQUEST =
  'EMAIL_INCIDENT_ASSIGNEE_REQUEST';
export const EMAIL_INCIDENT_ASSIGNEE_RESPONSE =
  'EMAIL_INCIDENT_ASSIGNEE_RESPONSE';

export const FETCH_INCIDENT_BY_ID_REQUEST = 'FETCH_INCIDENT_BY_ID_REQUEST';

export const RECATAGORIZE_REPORTS_REQUEST = 'RECATAGORIZE_REPORTS_REQUEST';

export const MICROSOFT_LOGIN = 'MICROSOFT_LOGIN';

export const CREATE_USER_SECURITY_QUESTIONS_REQUEST =
  'CREATE_USER_SECURITY_QUESTIONS_REQUEST';
export const SUBMIT_SECURITY_QUESTIONS = 'SUBMIT_SECURITY_QUESTIONS';
export const SET_SECURITY_QUESTIONS = 'SET_SECURITY_QUESTIONS';

export const FETCH_SAFETY_WALKS_REQUEST = 'FETCH_SAFETY_WALKS_REQUEST';
export const FETCH_SAFETY_WALKS_RESPONSE = 'FETCH_SAFETY_WALKS_RESPONSE';

export const ADD_SAFETY_WALK_REQUEST = 'ADD_SAFETY_WALK_REQUEST';

export const DELETE_SAFETY_WALK_REQUEST = 'DELETE_SAFETY_WALK_REQUEST';

export const SET_ACTIVE_SAFETY_WALK = 'SET_ACTIVE_SAFETY_WALK';
export const LOAD_SAFETY_WALK = 'LOAD_ACTIVE_SAFETY_WALK';

export const FETCH_MY_DOCUMENTS_REQUEST = 'FETCH_MY_DOCUMENTS_REQUEST';
export const FETCH_MY_DOCUMENTS_RESPONSE = 'FETCH_MY_DOCUMENTS_RESPONSE';

export const SET_ACTIVE_MY_DOCUMENT = 'SET_ACTIVE_MY_DOCUMENT';

export const CREATE_MY_DOCUMENT_REQUEST = 'CREATE_MY_DOCUMENT_REQUEST';
export const UPDATE_MY_DOCUMENT_REQUEST = 'UPDATE_MY_DOCUMENT_REQUEST';
export const DELETE_MY_DOCUMENT_REQUEST = 'DELETE_MY_DOCUMENT_REQUEST';

export const FETCH_REQUIRE_AUTHORIZATION_REQUEST =
  'FETCH_REQUIRE_AUTHORIZATION_REQUEST';
export const FETCH_REQUIRE_AUTHORIZATION_RESPONSE =
  'FETCH_REQUIRE_AUTHORIZATION_RESPONSE';

export const CREATE_CONTINUOUS_CARE_REQUEST = 'CREATE_CONTINUOUS_CARE_REQUEST';
export const UPDATE_CONTINUOUS_CARE_REQUEST = 'UPDATE_CONTINUOUS_CARE_REQUEST';
export const DELETE_CONTINUOUS_CARE_REQUEST = 'DELETE_CONTINUOUS_CARE_REQUEST';

export const FETCH_CUSTOM_DOCUMENTS_TEMPLATE_REQUEST =
  'FETCH_CUSTOM_DOCUMENTS_TEMPLATE_REQUEST';
export const FETCH_CUSTOM_DOCUMENTS_TEMPLATE_RESPONSE =
  'FETCH_CUSTOM_DOCUMENTS_TEMPLATE_RESPONSE';

export const SET_ACTIVE_CUSTOM_DOCUMENT_TEMPLATE =
  'SET_ACTIVE_CUSTOM_DOCUMENT_TEMPLATE';

export const CREATE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST =
  'CREATE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST';
export const UPDATE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST =
  'UPDATE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST';
export const DELETE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST =
  'DELETE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST';

export const FETCH_CUSTOM_DOCUMENTS_REQUEST = 'FETCH_CUSTOM_DOCUMENTS_REQUEST';
export const FETCH_CUSTOM_DOCUMENTS_RESPONSE =
  'FETCH_CUSTOM_DOCUMENTS_RESPONSE';

export const SET_ACTIVE_CUSTOM_DOCUMENT = 'SET_ACTIVE_CUSTOM_DOCUMENT';

export const CREATE_CUSTOM_DOCUMENT_REQUEST = 'CREATE_CUSTOM_DOCUMENT_REQUEST';
export const UPDATE_CUSTOM_DOCUMENT_REQUEST = 'UPDATE_CUSTOM_DOCUMENT_REQUEST';
export const DELETE_CUSTOM_DOCUMENT_REQUEST = 'DELETE_CUSTOM_DOCUMENT_REQUEST';
export const DELETE_CUSTOM_DOCUMENT_ATTACHMENT_REQUEST =
  'DELETE_CUSTOM_DOCUMENT_ATTACHMENT_REQUEST';

export const FETCH_CUSTOM_REPORT_QUESTIONS_REQUEST =
  'FETCH_CUSTOM_REPORT_QUESTIONS_REQUEST';

export const SET_ACTIVE_CUSTOM_REPORT_QUESTIONS =
  'SET_ACTIVE_CUSTOM_REPORT_QUESTIONS';
export const CREATE_CUSTOM_REPORT_QUESTIONS_REQUEST =
  'CREATE_CUSTOM_REPORT_QUESTIONS_REQUEST';
export const UPDATE_CUSTOM_REPORT_QUESTIONS_REQUEST =
  'UPDATE_CUSTOM_REPORT_QUESTIONS_REQUEST';
export const DELETE_CUSTOM_REPORT_QUESTIONS_REQUEST =
  'DELETE_CUSTOM_REPORT_QUESTIONS_REQUEST';

export const FETCH_TRAININGS_REQUEST = 'FETCH_TRAININGS_REQUEST';
export const FETCH_TRAININGS_RESPONSE = 'FETCH_TRAININGS_RESPONSE';

export const ADD_TRAINING_REQUEST = 'ADD_TRAINING_REQUEST';

export const UPDATE_TRAINING_REQUEST = 'UPDATE_TRAINING_REQUEST';

export const DELETE_TRAINING_REQUEST = 'DELETE_TRAINING_REQUEST';

export const SET_ACTIVE_TRAINING_REQUEST = 'SET_ACTIVE_TRAINING_REQUEST';
export const SET_ACTIVE_TRAINING_RESPONSE = 'SET_ACTIVE_TRAINING_RESPONSE';

export const DELETE_SAFETY_TRAINING_ATTACHMENT =
  'DELETE_SAFETY_TRAINING_ATTACHMENT';

export const FETCH_CUSTOM_TRAINING_TEMPLATES_REQUEST =
  'FETCH_CUSTOM_TRAINING_TEMPLATES_REQUEST';
export const FETCH_CUSTOM_TRAINING_TEMPLATES_RESPONSE =
  'FETCH_CUSTOM_TRAINING_TEMPLATES_RESPONSE';

export const ADD_CUSTOM_TRAINING_TEMPLATE_REQUEST =
  'ADD_CUSTOM_TRAINING_TEMPLATE_REQUEST';

export const UPDATE_CUSTOM_TRAINING_TEMPLATE_REQUEST =
  'UPDATE_CUSTOM_TRAINING_TEMPLATE_REQUEST';

export const DELETE_CUSTOM_TRAINING_TEMPLATE_REQUEST =
  'DELTE_CUSTOM_TRAINING_TEMPLATE_REQUEST';

export const SET_ACTIVE_CUSTOM_TRAINING_TEMPLATE =
  'SET_ACTIVE_CUSTOM_TRAINING_TEMPLATE';

export const SET_ACTIVE_TRAINING_ATTACHMENT = 'SET_ACTIVE_TRAINING_ATTACHMENT';

export const CLEAR_USER = 'CLEAR_USER';
export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST';
export const LOAD_USER_RESPONSE = 'LOAD_USER_RESPONSE';
export const LOAD_ALL_USERS_RESPONSE = 'LOAD_ALL_USERS_RESPONSE';
export const LOAD_ALL_USERS_REQUEST = 'LOAD_ALL_USERS_REQUEST';
export const CLEAR_ALL_USERS = 'CLEAR_ALL_USERS';
export const LOAD_ALL_COMPANY_USERS_REQUEST = 'LOAD_ALL_COMPANY_USERS_REQUEST';
export const LOAD_ALL_COMPANY_USERS_RESPONSE =
  'LOAD_ALL_COMPANY_USERS_RESPONSE';

export const UPDATE_ATTACHMENT_REQUEST = 'UPDATE_ATTACHMENT_REQUEST';
