import { all, call, put, select } from 'redux-saga/effects';
import history from '../history';
import {
  emailIncidentAssigneeResponse,
  fetchIncidentBasicsResponse,
  setActiveIncidentRequest,
  setActiveIncidentResponse,
  updateIncidentBasicsResponse
} from '../actions/incidents';
import { addUploadedAttachmentsResponse } from '../actions/attachments';
import { addMessage } from '../actions/messages';
import { fetchOshaFormResponse } from '../actions/oshaLogs';
import { createReportResponse, setReportTemplate } from '../actions/reports';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../selectors/company';
import { getActiveIncidentSelector } from '../selectors/incidents';
import { getAddedAttachmentsSelector } from '../selectors/attachments';
import {
  getActiveReportsSelector,
  getActiveReportTemplatesSelector
} from '../selectors/reports';
import { oshaQuestionMapper } from '../utils/oshaDbMapper';
import { getLoggedInUser } from '../selectors/users';
import reportTemplateMapper from '../utils/reportTemplateMapper';
import { isDiagram } from '../utils/attachmentSelector';

export function* fetchIncidentById(apiV4, { payload }) {
  try {
    const response = yield call(apiV4.fetchIncidentById, payload._id);

    yield put(setActiveIncidentResponse({ ...response }));
    yield put(
      addUploadedAttachmentsResponse(
        response.incidentActivity.attachments.filter(a => !a.isRemoved)
      )
    );
  } catch (e) {
    console.log(e);
  }
}

export function* setActiveIncident(apiV4, { payload }) {
  const returnPath = payload.returnPath;
  const leavePage = payload.leavePage ?? true;
  try {
    const loggedInUser = yield select(getLoggedInUser);
    if (typeof payload === 'string') {
      payload = yield call(apiV4.fetchIncidentById, payload);
    } else {
      payload = yield call(apiV4.fetchIncidentById, payload._id);
    }

    const froi = payload.reportTemplateIds.indexOf('2') > -1;

    if (froi) {
      let oshaForm = yield call(apiV4.populateOshaForms, payload._id);

      if (oshaForm.length > 0) {
        oshaForm.map(
          form => (form.formAnswers = oshaQuestionMapper(form.questions))
        );
      }

      yield put(fetchOshaFormResponse(oshaForm));
    } else {
      yield put(fetchOshaFormResponse([]));
    }

    const response = { sections: [] };

    const incidentBasics = payload.incidentBasicsFieldGroup;

    const reportBasics = payload.reportBasicsFieldGroups;

    response.sections = [incidentBasics, ...reportBasics];
    yield put(
      setActiveIncidentResponse({ ...payload, returnPath: returnPath })
    );

    yield put(
      addUploadedAttachmentsResponse(payload.incidentActivity.attachments)
    );
    yield put(fetchIncidentBasicsResponse(response));

    if (leavePage)
      loggedInUser.accessLevel === 100
        ? history.push('/app/initalReportContainer')
        : history.push('/app/incidentContainer');
  } catch (e) {
    console.error(e);
  }
}

export function* fetchIncidentBasics(api, apiV4, { payload }) {
  try {
    const company = yield select(getActiveCompany);

    const response = { sections: [] };

    const incidentBasics = yield call(apiV4.fetchIncidentBasics);

    const reportBasics = yield all(
      payload.reportTemplateIds.map(fieldGroupId =>
        call(apiV4.fetchReportBasics, reportTemplateMapper(fieldGroupId).basic)
      )
    );

    let customQuestions = yield all(
      payload.reportTemplateIds.map(fieldGroupId =>
        call(apiV4.fetchCustomReportQuestions, fieldGroupId, 'Report Basics')
      )
    );

    customQuestions = customQuestions.reduce((acc, x) => acc.concat(x), []);

    if (customQuestions.length > 0) {
      let customQuestionFields = [];

      customQuestions.forEach(custom =>
        customQuestionFields.push(...custom.fields)
      );

      response.sections = [...incidentBasics];
      reportBasics.forEach(rb => {
        if (Array.isArray(rb)) {
          rb.forEach(r => response.sections.push(r));
        } else {
          response.sections.push(rb);
        }
      });

      response.sections = [
        ...response.sections,
        {
          label: 'Custom Questions',
          fields: customQuestionFields
        }
      ];
    } else {
      response.sections = [...incidentBasics];
      reportBasics.forEach(rb => {
        if (Array.isArray(rb)) {
          rb.forEach(r => response.sections.push(r));
        } else {
          response.sections.push(rb);
        }
      });
    }

    if (payload._id) {
      const initialReport = yield call(
        apiV4.fetchInitialReportById,
        payload._id
      );
      initialReport.rowId = initialReport._id;

      const response = yield call(
        api.fetchAttachmentByOwnerId,
        company._id,
        initialReport._id,
        'incident'
      );
      delete initialReport._id;

      yield put(addUploadedAttachmentsResponse(response));

      if (initialReport.signature) {
        const signature = yield call(
          apiV4.getAttachmentById,
          initialReport.signature
        );
        initialReport.signature = signature;
      }

      yield put(setActiveIncidentResponse(initialReport));
    } else {
      yield put(setActiveIncidentResponse());
      yield put(addUploadedAttachmentsResponse([]));
    }

    yield put(fetchIncidentBasicsResponse(response));
    yield put(setReportTemplate(payload.reportTemplateIds));
  } catch (e) {
    console.log(e);
  } finally {
    history.push('/app/initalReportContainer');
  }
}

export function* saveIncidentBasics(api, apiV4, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const locationId = yield select(getActiveLocationId);
    let project = yield select(getActiveProject);
    let addedAttachments = yield select(getAddedAttachmentsSelector);
    const reportTemplates = yield select(getActiveReportTemplatesSelector);

    project = payload?.answers?.projectId
      ? payload?.answers?.projectId
      : project
      ? project._id
      : '';

    const id = payload?.rowId;

    payload = {
      groupId: locationId?._id ?? payload?.answers?.locationId,
      companyId: company._id,
      projectId: project,
      answers: payload.answers,
      signature: payload?.signature?._id,
      reportTemplateIds: payload?.reportTemplates ?? reportTemplates
    };

    const savedBasics = id
      ? yield call(apiV4.updateInitialReport, { ...payload, _id: id })
      : yield call(apiV4.createInitialReport, payload);

    if (addedAttachments.length) {
      yield all(
        addedAttachments.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: savedBasics._id,
            ownerType: 'incident'
          })
        )
      );
    }

    yield put(addMessage({ message: 'Initial Report Saved' }));
    yield put(addUploadedAttachmentsResponse([]));
    history.goBack();
  } catch (err) {
    console.error(err);
    yield put(addMessage({ error: true, message: err }));
  }
}

export function* updateIncidentBasics(api, apiV4, { payload }) {
  try {
    const response = yield call(
      apiV4.updateIncidentBasics,
      payload.id,
      payload.answers,
      payload.signature._id
    );

    call(api.updateAttachment, {
      _id: payload.signature._id,
      ownerType: 'incident',
      ownerId: payload.id
    });

    yield put(updateIncidentBasicsResponse(response));
    yield put(addMessage({ error: false, message: 'Initial Report Updated!' }));
    yield put(setActiveIncidentRequest(payload.id));
  } catch (e) {
    yield put(addMessage({ error: true, message: e.data }));
    return;
  }
}

export function* createIncident(api, apiV4, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const locationId = yield select(getActiveLocationId);
    let project = yield select(getActiveProject);
    const addedSignature = payload.signature;
    let addedAttachments = yield select(getAddedAttachmentsSelector);
    const reportTemplates = yield select(getActiveReportTemplatesSelector);
    const rowId = payload.rowId;

    project = payload?.answers?.projectId
      ? payload?.answers?.projectId
      : project
      ? project._id
      : '';
    let incidentPayload = yield call(apiV4.createIncident, {
      locationId: locationId ? locationId._id : payload.answers.locationId,
      companyId: company._id,
      groupId: locationId ? locationId._id : payload.answers.locationId,
      answers: payload.answers,
      projectId: project,
      reportTemplateIds: reportTemplates,
      signature: addedSignature._id
    });

    const report = yield all(
      reportTemplates.map(reportTemplateId =>
        call(api.createReport, company._id, {
          locationId: incidentPayload.locationId,
          groupId: incidentPayload.groupId,
          companyId: company._id,
          currentWfStage: 0,
          incidentId: incidentPayload._id,
          reportTemplateId: reportTemplateId,
          projectId: incidentPayload.projectId
        })
      )
    );

    let newAttachments = [];

    if (addedAttachments.length > 0) {
      const temp = yield all(
        addedAttachments.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: incidentPayload._id,
            ownerType: 'incident'
          })
        )
      );

      newAttachments = temp;
    }

    yield put(addUploadedAttachmentsResponse([...newAttachments]));

    incidentPayload = yield call(apiV4.fetchIncidentById, incidentPayload._id);

    let hasPhotoEvidence = false;
    let hasDiagrams = false;
    newAttachments.forEach(attachment => {
      if (attachment.isPhotoEvidence) {
        hasPhotoEvidence = true;
      } else if (isDiagram(attachment)) {
        hasDiagrams = true;
      }
    });

    incidentPayload.reportComponents.forEach(report => {
      if (report[0] === 'Photo Evidence') {
        report[5].isStarted = hasPhotoEvidence;
      } else if (report[0] === 'Diagrams') {
        report[5].isStarted = hasDiagrams;
      }
    });

    if (rowId) yield call(apiV4.deleteInitialReport, rowId);

    yield put(createReportResponse(report));
    yield put(setActiveIncidentResponse(incidentPayload));
    const froi = incidentPayload.reportTemplateIds.indexOf('2') > -1;
    if (froi) {
      let oshaForm = yield call(apiV4.populateOshaForms, incidentPayload._id);

      if (oshaForm.length > 0) {
        oshaForm.map(
          form => (form.formAnswers = oshaQuestionMapper(form.questions))
        );
      }

      yield put(fetchOshaFormResponse(oshaForm));
    } else {
      yield put(fetchOshaFormResponse([]));
    }

    yield put(
      addUploadedAttachmentsResponse(
        incidentPayload.incidentActivity.attachments
      )
    );
    history.push('/app/incidentContainer');
  } catch (error) {
    console.error(error);
  }
}

export function* updateReportComponent(api, apiV4, { payload }) {
  try {
    let activeReportComponent = yield select(getActiveReportsSelector);
    let activeIncident = yield select(getActiveIncidentSelector);
    let addedAttachments = yield select(getAddedAttachmentsSelector);
    const stage = payload.stage;

    if (activeReportComponent?.report) {
      activeReportComponent = activeReportComponent.report;
    }

    const dontLeavePage = payload.dontLeavePage;

    payload = {
      activeReportComponent: activeReportComponent?.[0],
      incidentId: activeIncident._id
    };

    yield call(apiV4.updateReportComponent, payload);

    if (addedAttachments.length > 0) {
      yield all(
        addedAttachments.map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: activeIncident._id,
            ownerType: 'incident'
          })
        )
      );
    }

    const incident = yield call(apiV4.fetchIncidentById, activeIncident._id);

    yield put(setActiveIncidentResponse(incident));
    yield put(addMessage({ error: false, message: 'Report Updated!' }));
    if (!dontLeavePage) history.push(`/app/incidentContainer/${stage}`);
  } catch (error) {
    console.error(error);
  }
}

export function* deleteIncident(apiV4, { payload }) {
  try {
    yield call(apiV4.deleteIncident, payload._id);

    history.push('/app/dashboard');
  } catch (e) {
    console.log(e);
  }
}

export function* addIncidentComment(api, apiV4, { payload }) {
  try {
    const activeIncident = yield select(getActiveIncidentSelector);

    payload = {
      value: payload.name,
      ownerId: activeIncident._id,
      ownerType: 'incident'
    };

    yield call(apiV4.addComment, payload);

    const incidentPayload = yield call(
      apiV4.fetchIncidentById,
      activeIncident._id
    );

    yield put(setActiveIncidentResponse(incidentPayload));
  } catch (e) {
    console.log(e);
  }
}

export function* addIncidentNote(apiV4, { payload }) {
  try {
    const activeIncident = yield select(getActiveIncidentSelector);

    payload = {
      value: payload.name,
      ownerId: activeIncident._id,
      ownerType: 'incident'
    };

    yield call(apiV4.addNote, payload);

    const incidentPayload = yield call(
      apiV4.fetchIncidentById,
      activeIncident._id
    );

    yield put(setActiveIncidentResponse(incidentPayload));
  } catch (e) {
    console.log(e);
  }
}

export function* updateIncidentComment(api, apiV4, { payload }) {
  try {
    const activeIncident = yield select(getActiveIncidentSelector);

    yield call(apiV4.updateComment, payload);

    const incidentPayload = yield call(
      apiV4.fetchIncidentById,
      activeIncident._id
    );

    yield put(setActiveIncidentResponse(incidentPayload));
  } catch (e) {
    console.log(e);
  }
}

export function* updateIncidentNote(apiV4, { payload }) {
  try {
    const activeIncident = yield select(getActiveIncidentSelector);
    yield call(apiV4.updateNote, payload);

    const incidentPayload = yield call(
      apiV4.fetchIncidentById,
      activeIncident._id
    );

    yield put(setActiveIncidentResponse(incidentPayload));
  } catch (e) {
    console.log(e);
  }
}

export function* deleteIncidentAttachment(api, apiV4, { payload }) {
  try {
    const activeIncident = yield select(getActiveIncidentSelector);
    let activeIncidentAttachments = yield select(getAddedAttachmentsSelector);

    yield call(api.deleteAttachment, payload._id);

    const incidentPayload = yield call(
      apiV4.fetchIncidentById,
      activeIncident._id
    );

    yield put(setActiveIncidentResponse(incidentPayload));

    yield put(
      addUploadedAttachmentsResponse(
        activeIncidentAttachments.filter(a => a._id !== payload._id)
      )
    );
  } catch (e) {
    console.log(e);
  }
}

export function* emailIncidentAssignee(api, { payload }) {
  try {
    const response = yield call(api.emailIncidentAssignee, payload);

    yield put(emailIncidentAssigneeResponse(response));
    yield put(addMessage({ error: false, message: response }));
  } catch (e) {
    console.log(e);
    yield put(addMessage({ error: true, message: e }));
  }
}
