import React from 'react';
import './svg.css';

export default props => (
  <svg
    version="1.1"
    width="30px"
    height="25px"
    x={props.x || "0px"}
    y={props.y || "0px"}
    viewBox="0 0 60 60"
  >
    <path
      d="M54.949,28.251c0.018-0.069,0.039-0.135,0.042-0.207C54.992,28.029,55,28.016,55,28C55,14.55,44.323,3.553,31,3.025V1
                    c0-0.553-0.448-1-1-1s-1,0.447-1,1v2.025C15.677,3.553,5,14.55,5,28c0,0.016,0.008,0.029,0.009,0.045
                    c0.003,0.071,0.023,0.137,0.042,0.207c0.016,0.059,0.025,0.118,0.05,0.172c0.025,0.053,0.064,0.097,0.098,0.145
                    c0.042,0.059,0.081,0.118,0.135,0.167c0.011,0.01,0.016,0.025,0.028,0.035c0.036,0.03,0.08,0.04,0.118,0.064
                    c0.059,0.037,0.115,0.074,0.182,0.098c0.07,0.026,0.141,0.034,0.213,0.043C5.918,28.98,5.956,29,6,29
                    c0.015,0,0.027-0.008,0.042-0.008c0.076-0.003,0.147-0.024,0.22-0.044c0.054-0.015,0.109-0.023,0.158-0.047
                    c0.057-0.026,0.104-0.068,0.156-0.105c0.055-0.04,0.111-0.077,0.157-0.127c0.011-0.012,0.026-0.017,0.037-0.03
                    C8.18,26.937,10.038,26,12,26s3.82,0.937,5.23,2.639c0.019,0.023,0.048,0.032,0.069,0.053c0.024,0.024,0.036,0.056,0.063,0.078
                    c0.04,0.033,0.088,0.045,0.13,0.071c0.051,0.031,0.098,0.062,0.152,0.082c0.082,0.032,0.164,0.045,0.249,0.054
                    C17.93,28.981,17.964,29,18,29c0.016,0,0.031-0.007,0.046-0.008c0.051-0.002,0.098-0.015,0.148-0.025
                    c0.069-0.014,0.135-0.03,0.2-0.059c0.05-0.022,0.095-0.05,0.141-0.081c0.033-0.021,0.07-0.032,0.101-0.058
                    c0.024-0.02,0.035-0.048,0.057-0.07c0.023-0.023,0.054-0.035,0.075-0.061C20.18,26.937,22.038,26,24,26c1.86,0,3.62,0.85,5,2.386V55
                    c0,1.654-1.346,3-3,3s-3-1.346-3-3c0-0.553-0.448-1-1-1s-1,0.447-1,1c0,2.757,2.243,5,5,5s5-2.243,5-5V28.386
                    C32.38,26.85,34.14,26,36,26c1.962,0,3.82,0.937,5.23,2.639c0.019,0.023,0.048,0.032,0.069,0.053
                    c0.024,0.024,0.036,0.056,0.063,0.078c0.04,0.033,0.088,0.044,0.13,0.07c0.051,0.031,0.098,0.062,0.153,0.083
                    c0.081,0.031,0.163,0.045,0.249,0.054C41.93,28.981,41.964,29,42,29c0.016,0,0.031-0.007,0.046-0.008
                    c0.051-0.002,0.099-0.015,0.149-0.026c0.068-0.014,0.134-0.03,0.199-0.058c0.051-0.022,0.096-0.051,0.142-0.081
                    c0.033-0.021,0.07-0.032,0.101-0.057c0.024-0.02,0.035-0.048,0.056-0.07c0.023-0.023,0.054-0.035,0.076-0.061
                    C44.18,26.937,46.038,26,48,26s3.82,0.937,5.23,2.639c0.01,0.012,0.024,0.015,0.035,0.027c0.07,0.077,0.154,0.135,0.245,0.188
                    c0.031,0.018,0.055,0.045,0.088,0.059C53.721,28.967,53.856,29,54,29h0h0c0.123,0,0.245-0.027,0.361-0.073
                    c0.045-0.018,0.082-0.047,0.124-0.071c0.051-0.029,0.106-0.048,0.152-0.087c0.012-0.01,0.017-0.024,0.028-0.035
                    c0.054-0.049,0.092-0.107,0.135-0.167c0.034-0.048,0.073-0.093,0.098-0.145C54.924,28.369,54.934,28.31,54.949,28.251z M48,24
                    c-2.218,0-4.312,0.895-6,2.534C40.312,24.895,38.218,24,36,24s-4.312,0.895-6,2.534C28.312,24.895,26.218,24,24,24
                    s-4.312,0.895-6,2.534C16.312,24.895,14.218,24,12,24c-1.75,0-3.418,0.566-4.875,1.605C8.327,14.044,18.127,5,30,5
                    s21.673,9.044,22.875,20.605C51.418,24.566,49.75,24,48,24z"
    />
  </svg>
);
