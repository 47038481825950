import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';

import { getAccessLevel } from '../../../selectors/users';
import history from '../../../history';
import AuthorizeRejectButtons from '../../inputs/AuthorizeRejectButtons';
import List from '../../List';

export const QuizTable = ({
  isApprover,
  assigneeApproval,
  updateAnswer,
  setActiveTraining,
  childQuizzes,
  name,
  needsApproval,
  dueDate,
  fromApproveTraining,
  activeTraining,
  updateModal,
  isMaster
}) => {
  const accessLevel = useSelector(getAccessLevel, shallowEqual);
  const userAccessor = isMaster
    ? { key: 'assignee', datatype: 'user' }
    : { key: 'assignedTo', datatype: 'user' };
  const columns = [
    userAccessor,
    {
      key: 'passedQuiz',
      label: 'Pass/Fail',
      accessor: r =>
        !r.quiz.isCompleted
          ? 'In Progress'
          : r.quiz.passedQuiz
          ? 'Passed'
          : 'Failed'
    },
    {
      key: 'attemptNumber',
      label: 'Number of Attempts',
      accessor: r => (r.quiz.attemptNumber > 1 ? r.quiz.attemptNumber - 1 : 1)
    },
    { key: 'completionDate', datatype: 'date' },
    {
      key: 'approveReject',
      label: 'Authorize',
      noClick: true,
      Cell: ({ value, row }) => {
        if (isApprover) {
          value = assigneeApproval.find(
            aa => aa.assignee === row.original.assignee
          )?.approveReject;
        }
        return (
          <AuthorizeRejectButtons
            answer={value}
            updateAnswer={approveReject =>
              updateAnswer({
                approveReject,
                assignee: [row.original.assignee]
              })
            }
            afterApproving={
              !(fromApproveTraining && needsApproval && isApprover)
            }
            disabled={!isApprover}
          />
        );
      }
    }
  ];

  const handleApproveRejectTrainings = (trainingIds, approveReject) => {
    updateAnswer({
      approveReject,
      assignee: trainingIds
    });
  };

  const actions = [];
  const current = moment();
  const afterDueDate = moment(dueDate).isBefore(current);
  if (
    afterDueDate &&
    !fromApproveTraining &&
    accessLevel > 400 &&
    activeTraining.masterTemplateId === ''
  ) {
    actions.push({
      label: 'Reassign',
      onClick: () => {
        setActiveTraining({ ...activeTraining, isReassigned: true });
        history.push('/app/training/trainingContainer');
      }
    });
  }

  if (fromApproveTraining && needsApproval && isApprover) {
    actions.unshift(
      {
        label: 'Approve',
        onClick: row => handleApproveRejectTrainings(row, 'approve')
      },
      {
        label: 'Reject',
        onClick: row => handleApproveRejectTrainings(row, 'reject')
      }
    );

    if (afterDueDate) {
      actions.push(
        {
          label: 'Reject & Reassign',
          onClick: row => {
            handleApproveRejectTrainings(row, 'reject');
            updateModal(true);
          }
        },
        {
          label: 'Reassign',
          onClick: () => {
            setActiveTraining({ ...activeTraining, isReassigned: true });
            updateModal(true);
          }
        }
      );
    }
  }

  return (
    <List
      saveKey="quizList"
      data={childQuizzes}
      settings={columns}
      dataIsHash
      rowClick={row => {
        setActiveTraining(row);
        history.push('/app/training/TrainingQuizViewer');
      }}
      title="Assignee(s) Quiz List"
      name={name}
      getRowId={r => r.assignee}
      actions={actions}
    />
  );
};

export default QuizTable;
