import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  fetchSafetyWalksRequest,
  loadSafetyWalk
} from '../../actions/safetyWalks';
import { SAFETY_WALKS_STAGES } from '../../constants/constants';
import { getActiveCompany, getActiveLocationId } from '../../selectors/company';
import { getSafetyWalksSelector } from '../../selectors/safetyWalks';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import List from '../../components/List';

class SafetyWalkStages extends Component {
  state = { selectedStage: 0 };

  componentDidMount() {
    this.props.fetchSafetyWalks();
  }

  render() {
    const {
      activeCompany,
      safetyWalksList,
      loadSafetyWalk,
      activeLocation
    } = this.props;

    const content = SAFETY_WALKS_STAGES.map(status =>
      safetyWalksList && safetyWalksList.length > 0
        ? safetyWalksList.filter(
            safetyWalk => safetyWalk?.stage?.value === status.value
          )
        : []
    );

    const tabList = {
      selected: this.state.selectedStage,
      onClick: idx => this.setState({ selectedStage: idx }),
      list: SAFETY_WALKS_STAGES.map((stage, i) => ({
        name: stage.label,
        color: 'blue',
        count: content[i]?.length
      }))
    };

    const columns = [
      {
        key: 'safetyWalkNumber',
        label: activeCompany.isDash ? 'DASH title' : 'SW Title'
      },
      {
        key: 'createdByUser',
        label: 'Reporter',
        datatype: 'user',
        accessor: r => r.auditLog[0]?.user || r.createdBy
      },
      {
        key: 'hazardsFound',
        label: 'Hazard Count',
        accessor: r => r.summary?.hazardsFound,
        filter: false
      },
      {
        key: 'bravosFound',
        label: 'Bravos Count',
        accessor: d => d.summary?.bravosFound,
        filter: false
      },
      {
        key: 'correctiveActionsCreated',
        label: 'Corrective Actions Created',
        accessor: d => d.summary?.correctiveActionsCreated,
        filter: false
      },
      {
        key: 'createdAt',
        label: 'Created Date',
        datatype: 'date'
      }
    ];

    if (!activeLocation) {
      columns.unshift({
        key: 'groupId',
        label: 'Group/Est.',
        datatype: 'group'
      });
    }
    const header = (
      <Header title={activeCompany.isDash ? 'DASH Report' : 'Safety Walks'} />
    );

    return (
      <HeaderAndFooter Header={header}>
        <ScrollToTopOnMount />
        <List
          saveKey="swStages"
          tabs={tabList}
          data={content[this.state.selectedStage]}
          dataIsHash
          settings={columns}
          rowClick={row => loadSafetyWalk(row._id)}
        />
      </HeaderAndFooter>
    );
  }
}

const mapStateToProps = state => ({
  safetyWalksList: getSafetyWalksSelector(state),
  activeLocation: getActiveLocationId(state),
  activeCompany: getActiveCompany(state)
});

const mapDispatchToProps = dispatch => ({
  fetchSafetyWalks: () => dispatch(fetchSafetyWalksRequest()),
  loadSafetyWalk: id => dispatch(loadSafetyWalk(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(SafetyWalkStages);
