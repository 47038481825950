import { all, call, put } from 'redux-saga/effects';

import history from '../history';
import {
  fetchSafetyWalksResponse,
  setActiveSafetyWalk
} from '../actions/safetyWalks';
import { addMessage } from '../actions/messages';

export function* loadSafetyWalk(apiV4, { payload }) {
  try {
    const response = yield call(apiV4.fetchSafetyWalkById, payload);

    yield put(setActiveSafetyWalk(response));
    history.push('/app/safetyWalkContainer');
  } catch (e) {
    console.log(e);
  }
}

export function* fetchSafetyWalks(apiV4, { payload }) {
  try {
    let response = yield call(apiV4.fetchSafetyWalks);

    yield put(fetchSafetyWalksResponse(response));
  } catch (e) {
    console.log(e);
  }
}

export function* addSafetyWalk(api, apiV4, { payload }) {
  try {
    const sig = payload.signature;
    payload.signature = sig?.source_url || sig;

    let response = yield call(apiV4.createSafetyWalk, payload);
    const tasks = (payload.hazards ?? []).reduce(
      (acc, hazard) => [...acc, ...(hazard.tasks ?? []).map(t => t._id)],
      []
    );

    if (sig?._id) {
      yield call(api.updateAttachment, {
        _id: sig._id,
        ownerId: response._id,
        ownerType: 'safetyWalk'
      });
    }

    yield all(
      tasks.map(task =>
        call(apiV4.updateTask, {
          _id: task,
          ownerType: 'safetyWalks',
          ownerId: response._id
        })
      )
    );

    response = yield call(apiV4.fetchSafetyWalkById, response._id);

    yield put(setActiveSafetyWalk(response));
  } catch (e) {
    console.log(e);
  }
}

export function* deleteSafetyWalk(apiV4, { payload }) {
  try {
    yield call(apiV4.deleteSafetyWalk, payload);

    yield put(setActiveSafetyWalk(undefined));
    yield put(addMessage({ error: false, message: 'Deleted Successfully' }));
  } catch (e) {
    console.log(e);
  }
}
