import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import history from '../../../history';
import store from '../../../store';
import * as api from '../../../api';
import { positiveIntsOnly } from '../../../utils/formValidation';
import Card from '../../../components/Card';
import QuizQuestionList from '../../../components/quiz/QuizQuestionList';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import RadioButtons from '../../../components/inputs/RadioButtons';
import Textbox from '../../../components/inputs/Textbox';

export default function QuizTemplate(props) {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [quiz, setQuiz] = useState({
    title: '',
    numberOfQuestionsCorrectNeededToPass: '',
    canBeRetaken: false,
    numberOfRetriesAllowed: ''
  });
  const [questions, setQuestions] = useState([]);

  const { quizTemplateId, duplicate } = useParams();

  useEffect(() => {
    if (quizTemplateId) {
      api.fetchQuizById(quizTemplateId).then(response => {
        setQuiz({
          ...response,
          canBeRetaken: response.canBeRetaken
            ? { value: 1, label: 'Yes' }
            : { value: 2, label: 'No' },
          title: duplicate ? `${response.title} - Copy` : response.title
        });
        setQuestions(response.questions);
      });
      if (duplicate) {
        setIsCreating(true);
      }
    } else {
      setIsCreating(true);
      setQuestions([
        {
          label: '',
          answerOptions: []
        }
      ]);
    }
  }, [quizTemplateId, duplicate]);

  const updateQuestions = newQuestions => {
    setQuestions(newQuestions);
    setHasUnsavedChanges(true);
    checkCorrectNumber(
      quiz.numberOfQuestionsCorrectNeededToPass,
      questions.length + newQuestions.length
    );
  };

  const canSubmit = () => {
    const questionsMissingInfo = questions.filter(question => {
      const hasCorrectAnswers = question.answerOptions.some(
        answer => answer.isCorrectAnswer
      );
      const hasAnswerLabels = question.answerOptions.every(
        answer => answer.answer
      );

      return !question.label || !hasCorrectAnswers || !hasAnswerLabels;
    });

    const validRetry =
      quiz.canBeRetaken.label === 'No'
        ? true
        : quiz.canBeRetaken.label === 'Yes'
        ? quiz.numberOfRetriesAllowed > 0
        : false;

    return (
      quiz.title.length > 2 &&
      quiz.numberOfQuestionsCorrectNeededToPass &&
      questions.length &&
      quiz.numberOfQuestionsCorrectNeededToPass <= questions.length &&
      !questionsMissingInfo.length &&
      questions.length >= quiz.numberOfQuestionsCorrectNeededToPass &&
      validRetry
    );
  };

  const handleSubmit = () => {
    let state = store.getState();
    let companyId = state.company?.activeCompany?._id;
    const newQuiz = {
      ...quiz,
      questions,
      companyId,
      canBeRetaken: quiz?.canBeRetaken?.value === 1
    };
    if (newQuiz._id && !duplicate) {
      api
        .updateQuiz(newQuiz)
        .then(() => history.goBack())
        .catch(e => alert(e));
    } else {
      delete newQuiz._id;
      api
        .createQuiz(newQuiz)
        .then(() =>
          props?.location?.state?.fromScreen
            ? history.push({
                pathname: '/app/training/customTrainingTemplate',
                state: { fromScreen: 'quizTemplate' }
              })
            : history.goBack()
        )
        .catch(e => alert(e));
    }
  };

  const handleDelete = () => {
    api.deleteQuiz(quiz).then(() => history.goBack());
  };

  const checkTitleAvailability = v => {
    api.doesQuizHaveTitle(v).then(response => {
      const titleField = window.document.getElementById('quizTitle');

      if (response && response.titleExists) {
        titleField.style.background = 'lightcoral';
      } else {
        titleField.style.background = 'lightgreen';
      }
    });
  };

  const checkCorrectNumber = (num, numberOfQuestions) => {
    const numberField = window.document.getElementById('numberNeededRight');
    if (num === '') {
      numberField.style.background = 'white';
    } else if (num > numberOfQuestions) {
      numberField.style.background = 'lightcoral';
    } else {
      numberField.style.background = 'lightgreen';
    }
  };

  const handleGoBack = () => {
    props?.location?.state?.fromScreen
      ? history.push({
          pathname: '/app/training/customTrainingTemplate',
          state: { fromScreen: 'quizTemplate' }
        })
      : history.goBack();
  };

  const header = (
    <Header
      title="Quiz Template"
      section="Create Template"
      rightButtons={
        isEditing
          ? {
              text: 'Delete Quiz',
              onClick: () => {
                handleDelete();
              },
              color: 'red',
              visible: !isCreating
            }
          : {
              text: 'Edit Quiz',
              onClick: () => {
                setIsEditing(true);
              },
              color: 'blue',
              visible: !isCreating
            }
      }
      clickBack={() =>
        hasUnsavedChanges ? setOpenUnsavedChangesModal(true) : handleGoBack()
      }
      needsSaved={hasUnsavedChanges}
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonDisabled={!canSubmit()}
      saveButtonClick={() => handleSubmit()}
      cancelButtonClick={() =>
        hasUnsavedChanges ? setOpenUnsavedChangesModal(true) : handleGoBack()
      }
      editing={isEditing}
    />
  );

  const disabled = !isCreating && !isEditing;

  return (
    <>
      <HeaderAndFooter Header={header} Footer={footer} showFooter={!disabled}>
        <Card showHeader title="Quiz Template">
          <Textbox
            currentValue={quiz.title}
            onChange={title => {
              checkTitleAvailability(title);
              setQuiz({ ...quiz, title });
              setHasUnsavedChanges(true);
            }}
            fieldLabel="Title (if textbox turns red, title is not available)"
            placeholder="Title of Quiz (e.g. Forklift Quiz)"
            isRequired
            disabled={disabled}
            name="quizTitle"
          />
          <Textbox
            type="number"
            currentValue={quiz.numberOfQuestionsCorrectNeededToPass}
            onChange={v => {
              const onlyNums = positiveIntsOnly(v);
              setQuiz({
                ...quiz,
                numberOfQuestionsCorrectNeededToPass: onlyNums
              });
              setHasUnsavedChanges(true);
              checkCorrectNumber(v, questions.length);
            }}
            fieldLabel="How many questions must be correct to pass?  (If textbook turns red, it means the # of total questions on the quiz is less than the # needed to be correct)."
            placeholder="0"
            isRequired
            disabled={disabled}
            name="numberNeededRight"
          />
          <RadioButtons
            fieldLabel="Can be retaken?"
            options={[
              { value: 1, label: 'Yes' },
              { value: 2, label: 'No' }
            ]}
            isRequired
            onChange={values => {
              setQuiz({
                ...quiz,
                canBeRetaken: values,
                numberOfRetriesAllowed: ''
              });
              setHasUnsavedChanges(true);
            }}
            currentValue={quiz.canBeRetaken}
            disabled={disabled}
            bareValues={false}
          />
          {quiz.canBeRetaken.value === 1 && (
            <Textbox
              type="number"
              currentValue={quiz.numberOfRetriesAllowed}
              onChange={v => {
                const onlyNums = positiveIntsOnly(v);
                setQuiz({
                  ...quiz,
                  numberOfRetriesAllowed: onlyNums
                });
                setHasUnsavedChanges(true);
              }}
              fieldLabel="How many retries allowed?"
              placeholder="0"
              isRequired
              disabled={disabled}
            />
          )}
          <QuizQuestionList
            questions={questions}
            quiz={quiz}
            disabled={disabled}
            updateQuestions={values => updateQuestions(values)}
          />
        </Card>
      </HeaderAndFooter>
      <SaveChangesModal
        isOpen={openUnsavedChangesModal}
        savingWhat="Quiz Template"
        onRequestClose={() => setOpenUnsavedChangesModal(false)}
        submitActions={() => handleGoBack()}
      />
    </>
  );
}
