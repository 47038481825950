import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments,
  deleteAttachmentRequest
} from '../../actions/attachments';
import {
  addDocumentsToFolderRequest,
  addQualificationRequest,
  deleteQualificationAttachment,
  deleteQualificationRequest,
  fetchDocumentFoldersRequest,
  fetchQualificationRequest,
  fetchQualificationTypeRequest,
  setActiveQualification,
  updateQualificationRequest
} from '../../actions/documents';
import { setActiveIncidentResponse } from '../../actions/incidents';
import {
  getAddedAttachmentsSelector,
  getAttachmentLoadingSelector
} from '../../selectors/attachments';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import {
  getActiveQualificationSelector,
  getCompanyQualificationsSelector,
  getDocumentFoldersSelector,
  getDocumentLoadingSelector,
  getQualificationTypesSelector
} from '../../selectors/documents';
import { getLoggedInUser } from '../../selectors/users';
import QualificationList from '../../components/QualificationList';

import '../../components/ObjectList/index.css';

export class Documents extends Component {
  componentDidMount = () => {
    this.props.fetchQualificationTypes();
    this.props.fetchQualifications();
    this.props.activeIncidentResponse();
    this.props.clearAttachment();
  };

  render() {
    const {
      activeCompany,
      addAttachment,
      clearAttachment,
      attachmentLoading,
      loggedInUser,
      addedAttachments,
      deleteAttachment,
      qualifications,
      addQualification,
      updateQualification,
      activeQualification,
      setActiveQualifications,
      activeLocation,
      activeProject,
      qualificationTypes,
      deleteQualification,
      fetchDocumentFolders,
      documentFolders,
      addDocumentToFolder,
      deleteQualificationAttachment
    } = this.props;

    return (
      <QualificationList
        locationList={activeCompany.groups}
        content={qualifications}
        addQualification={addQualification}
        updateQualification={updateQualification}
        activeQualification={activeQualification}
        setActiveQualification={setActiveQualifications}
        activeLocation={activeLocation}
        activeProject={activeProject}
        qualificationTypes={qualificationTypes}
        activeCompany={activeCompany}
        deleteAttachment={deleteAttachment}
        deleteQualificationAttachment={deleteQualificationAttachment}
        addedAttachments={addedAttachments}
        addAttachment={addAttachment}
        activeAttachments={activeQualification.attachments}
        clearAttachment={clearAttachment}
        loading={attachmentLoading}
        deleteQualification={deleteQualification}
        modalOpen={this.props.match.params.qualificationType}
        accessLevel={loggedInUser.accessLevel}
        user={loggedInUser}
        fetchDocumentFolders={fetchDocumentFolders}
        documentFolders={documentFolders}
        addDocumentToFolder={addDocumentToFolder}
      />
    );
  }
}

export const mapStateToProps = state => ({
  activeCompany: getActiveCompany(state),
  attachmentLoading: getAttachmentLoadingSelector(state),
  documentLoading: getDocumentLoadingSelector(state),
  loggedInUser: getLoggedInUser(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  qualifications: getCompanyQualificationsSelector(state),
  activeQualification: getActiveQualificationSelector(state),
  activeProject: getActiveProject(state),
  activeLocation: getActiveLocationId(state),
  qualificationTypes: getQualificationTypesSelector(state),
  documentFolders: getDocumentFoldersSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  addAttachment: attachment =>
    dispatch(addUploadedAttachmentsRequest(attachment)),
  clearAttachment: () => dispatch(clearUploadedAttachments()),
  activeIncidentResponse: () => dispatch(setActiveIncidentResponse({})),
  deleteAttachment: values => dispatch(deleteAttachmentRequest(values)),
  fetchQualifications: () =>
    dispatch(fetchQualificationRequest({ page: 1, limit: 10000 })),
  addQualification: values => dispatch(addQualificationRequest(values)),
  updateQualification: values => dispatch(updateQualificationRequest(values)),
  setActiveQualifications: values => dispatch(setActiveQualification(values)),
  fetchQualificationTypes: () => dispatch(fetchQualificationTypeRequest()),
  deleteQualification: values => dispatch(deleteQualificationRequest(values)),
  fetchDocumentFolders: values => dispatch(fetchDocumentFoldersRequest(values)),
  addDocumentToFolder: values => dispatch(addDocumentsToFolderRequest(values)),
  deleteQualificationAttachment: values =>
    dispatch(deleteQualificationAttachment(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
