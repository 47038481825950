import React from 'react';
import moment from 'moment';

import { Dropdown } from '../inputs';

export const DateCell = ({ value }) =>
  value === 'Does Not Expire'
    ? 'Does Not Expire'
    : value
    ? moment(value).format('l LT')
    : '--';

export const DateFilter = ({ column: { filterValue, setFilter } }) => (
  <Dropdown
    options={{
      all: 'Show All',
      today: 'Today',
      last7Days: 'Past 7 Days',
      last30Days: 'Past 30 Days',
      thisQuarter: 'This Quarter',
      thisYear: 'This Year'
    }}
    currentValue={filterValue || 'all'}
    onChange={v => setFilter(v === 'all' ? undefined : v)}
    testID="filterOption"
  />
);

export const FutureDateFilter = ({ column: { filterValue, setFilter } }) => (
  <Dropdown
    options={{
      all: 'Show All',
      today: 'Today',
      last7Days: 'Past 7 Days',
      last30Days: 'Past 30 Days',
      thisQuarter: 'This Quarter',
      thisYear: 'This Year',
      next7Days: 'Next 7 Days',
      next30Days: 'Next 30 Days'
    }}
    currentValue={filterValue || 'all'}
    onChange={v => setFilter(v === 'all' ? undefined : v)}
    testID="futureDate"
  />
);

export function filterDate(rows, columnIds, filter) {
  let startDate;
  let endDate;

  switch (filter) {
    case 'today':
      startDate = moment().startOf('day');
      endDate = moment().endOf('day');
      break;
    case 'last7Days':
      startDate = moment()
        .subtract(8, 'days')
        .startOf('day');
      endDate = moment()
        .subtract(1, 'days')
        .endOf('day');
      break;
    case 'last30Days':
      startDate = moment()
        .subtract(31, 'days')
        .startOf('day');
      endDate = moment()
        .subtract(1, 'days')
        .endOf('day');
      break;
    case 'thisQuarter':
      startDate = moment().startOf('quarter');
      endDate = moment().endOf('quarter');
      break;
    case 'thisYear':
      startDate = moment().startOf('year');
      endDate = moment().endOf('year');
      break;
    case 'next7Days':
      startDate = moment()
        .add(1, 'day')
        .startOf('day');
      endDate = moment()
        .add(8, 'days')
        .endOf('day');
      break;
    case 'next30Days':
      startDate = moment()
        .add(1, 'day')
        .startOf('day');
      endDate = moment()
        .add(31, 'days')
        .endOf('day');
      break;
    case 'all':
      return rows;
    default:
      break;
  }

  return rows.filter(r =>
    r.values[columnIds[0]]
      ? moment(r.values[columnIds[0]]).isBetween(
          startDate,
          endDate,
          'days',
          '[]'
        )
      : false
  );
}
