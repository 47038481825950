import React from 'react';

import { Input, nameInput } from '../';

import '../input.css';
import './index.css';

const RadioButtons = ({
  name,
  options,
  disabled,
  className,
  displayTooltip,
  columns,
  currentValue,
  tooltips,
  onChange,
  bareValues = true,
  needSeparateLines,
  testID,
  ...props
}) => {
  let value = currentValue?.value ?? currentValue;

  return (
    <Input
      {...props}
      name={name}
      displayTooltip={displayTooltip}
      disabled={disabled}
    >
      <div
        className={needSeparateLines ? 'radioButtons-col' : 'radioButtons-row'}
      >
        {options.map((option, index) => (
          <div
            className={
              needSeparateLines ? 'radioButtonColInput' : 'radioButtoninput'
            }
            key={index}
          >
            <div className="radioButtonDiv">
              <label className="radioButton-label">
                {option.label}
                <input
                  type="checkbox"
                  name={`${name}[${index}]`}
                  value={option.value}
                  checked={value === option.value}
                  onChange={() => {
                    onChange(bareValues ? option.value : option);
                  }}
                  disabled={disabled}
                  data-cy={(testID ?? props.fieldLabel) + ' ' + option.label}
                />
                <span
                  className={disabled ? 'radioButtonDisabled' : 'radioButton'}
                />
              </label>
            </div>
          </div>
        ))}
      </div>
    </Input>
  );
};

export default nameInput(RadioButtons);
