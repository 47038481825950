import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setActiveIncidentResponse } from '../../actions/incidents';
import {
  fetchUsersTasksRequest,
  loadTask,
  setActiveTaskRequest
} from '../../actions/tasks';
import { setActiveIncidentRequest } from '../../actions/incidents';
import { getUserTasksSelector } from '../../selectors/tasks';
import { getAccessLevel } from '../../selectors/users';
import List from '../../components/List';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import TypeSelection from '../../components/TypeSelection';
import RepeatableList from '../../components/RepeatableList';
import { getActiveLocationId } from '../../selectors/company';

export class TaskStages extends Component {
  state = {
    schedule: 'Active'
  };

  componentDidMount() {
    this.props.fetchUsersTasks(false);
    this.props.activeIncidentResponse();
  }

  handleTypeSelection = schedule => {
    this.props.fetchUsersTasks(schedule === 'Repeating');

    this.setState({ schedule });
  };

  render() {
    const {
      setActiveTask,
      accessLevel,
      allTasks,
      loadTask,
      activeLocation,
      loadIncident
    } = this.props;

    const { schedule } = this.state;

    const repeatingSettings = [
      { key: 'label', label: 'Title', accessor: r => r?.task?.label },
      { key: 'description' },
      {
        key: 'assignedTo',
        datatype: 'users',
        label: 'Assignee(s)'
      },
      {
        key: 'repeatingOn',
        datatype: 'dow',
        label: 'Repeat On',
        frequency: 'frequency'
      }
    ];
    const activeSettings = [
      { key: 'label', label: 'Title' },
      { key: 'description' },
      {
        key: 'assignedTo',
        datatype: 'users',
        label: 'Assignee(s)'
      },
      {
        key: 'dueDate',
        datatype: 'futureDate',
        label: 'Due Date'
      },
      {
        key: 'completionDate',
        datatype: 'futureDate',
        label: 'Completion Date'
      },
      {
        key: 'incidentNumber',
        label: 'Incident Number'
      },

      {
        key: 'isCompleted',
        label: 'Status',
        accessor: r => (r?.isCompleted ? 'Completed' : 'Open'),
        enum: [
          { value: 'Open', label: 'Open' },
          { value: 'Completed', label: 'Completed' }
        ]
      }
    ];
    const header = (
      <Header
        title="Tasks"
        rightButtons={
          accessLevel > 100 && {
            text: 'Create Task',
            color: 'blue',
            onClick: () => setActiveTask({})
          }
        }
      />
    );

    if (!activeLocation) {
      repeatingSettings.unshift({
        key: 'groupId',
        label: 'Group/Est.',
        datatype: 'group'
      });
      activeSettings.unshift({
        key: 'groupId',
        label: 'Group/Est.',
        datatype: 'group'
      });
    }

    return (
      <HeaderAndFooter Header={header}>
        <TypeSelection
          selected={schedule}
          selectedArray={['Active', 'Repeating']}
          onClick={this.handleTypeSelection}
        />
        {schedule === 'Repeating' ? (
          <RepeatableList
            repeatables={allTasks}
            repeatingColumns={repeatingSettings}
            handleRowClick={row =>
              loadTask({ id: row._id, fromRepeating: true })
            }
            updateData={() => this.handleTypeSelection('Repeating')}
            saveKey="repeatingTaskList"
            type="Tasks"
          />
        ) : (
          <List
            saveKey="immediateTaskList"
            rowClick={row =>
              row.ownerType === 'incident'
                ? loadIncident(row.ownerId)
                : loadTask(row._id)
            }
            dataIsHash
            data={allTasks}
            settings={activeSettings}
          />
        )}
      </HeaderAndFooter>
    );
  }
}

export const mapStateToProps = state => ({
  accessLevel: getAccessLevel(state),
  allTasks: getUserTasksSelector(state),
  activeLocation: getActiveLocationId(state)
});

export const mapDispatchToProps = dispatch => ({
  fetchUsersTasks: isRecurring =>
    dispatch(fetchUsersTasksRequest({ isRecurring })),
  setActiveTask: task => dispatch(setActiveTaskRequest(task)),
  activeIncidentResponse: () => dispatch(setActiveIncidentResponse({})),
  loadTask: taskId => dispatch(loadTask(taskId)),
  loadIncident: incidentId => dispatch(setActiveIncidentRequest(incidentId))
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskStages);
