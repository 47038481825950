import React from 'react';

import List from '../../List';

export default function TrainingTable({ attachments, setActiveAttachment }) {
  const columns = [
    { key: 'title', accessor: r => r.original_filename },
    { key: 'timeSpent', label: 'Time Spent (minutes)' }
  ];

  return (
    <List
      saveKey="trainingTable"
      data={attachments}
      settings={columns}
      rowClick={setActiveAttachment}
      dataIsHash
    />
  );
}
