import React, { useMemo } from 'react';
import moment from 'moment';

import AuthorizeRejectButtons from '../../inputs/AuthorizeRejectButtons';
import List from '../../List';

export const AssigneeTable = ({
  assignees,
  attachments,
  childAttachments,
  needsApproval,
  assigneeApproval,
  updateAnswer,
  isApprover,
  name,
  dueDate,
  fromApproveTraining,
  trainingType,
  isMaster,
  childCompletionDates
}) => {
  const data = useMemo(() => {
    const data = [];

    const attachmentsUsed = isMaster ? childAttachments : attachments;
    assignees.forEach(assignee => {
      const attachmentMap = attachments.map(attachment => {
        const childAttachment = attachmentsUsed?.filter(
          ca =>
            (ca.assignedTo === assignee || !isMaster) &&
            ca.original_filename === attachment.original_filename
        );
        return {
          name: attachment.original_filename,
          timeSpent: childAttachment?.[0]?.timeSpent
        };
      });

      let approveReject = '';
      if (needsApproval) {
        const index = assigneeApproval.findIndex(
          aa => aa.assignee === assignee
        );

        approveReject = index > -1 ? assigneeApproval[index].approveReject : '';
      }

      data.push({
        assignee,
        approveReject,
        attachments: attachmentMap,
        completionDate: isMaster
          ? childCompletionDates?.find(ca => ca.assignedTo === assignee)
              ?.completionDate
          : childCompletionDates
      });
    });

    return data;
  }, [
    assigneeApproval,
    assignees,
    attachments,
    childAttachments,
    needsApproval,
    childCompletionDates,
    isMaster
  ]);
  const current = moment();
  const afterDueDate = moment(dueDate).isBefore(current);

  const columns = useMemo(() => {
    const columns = [{ key: 'assignee', label: 'Employee', datatype: 'user' }];

    if (trainingType?.value === 1) {
      let seen = {};

      const Cell = ({ value }) => (value ? `${value} mins` : 'Not Opened');
      const attachmentsUsed = isMaster ? childAttachments : attachments;
      attachmentsUsed.forEach(a => {
        let name = a.original_filename;
        let lcName = name.toLowerCase();
        if (seen[lcName]) return;
        columns.push({
          key: name.toLowerCase(),
          label: name,
          accessor: r =>
            r.attachments.find(a => a.name.toLowerCase() === lcName)?.timeSpent,
          Cell
        });
        seen[lcName] = true;
      });
    } else {
      let seen = {};

      attachments.forEach(a => {
        let name = a.original_filename;
        let lcName = name.toLowerCase();
        if (seen[lcName]) return;
        columns.push({
          key: name.toLowerCase(),
          label: name,
          accessor: r =>
            r.attachments.find(a => a.name.toLowerCase() === lcName)?.timeSpent,
          Cell: 'In-Person'
        });
        seen[lcName] = true;
      });
    }

    columns.push({
      key: 'completionDate',
      datatype: 'date'
    });

    if (needsApproval) {
      columns.push({
        key: 'approveReject',
        label: 'Authorize',
        noClick: true,
        Cell: ({ value, row }) => (
          <AuthorizeRejectButtons
            updateAnswer={approveReject =>
              updateAnswer({
                approveReject,
                assignee: [row.original.assignee]
              })
            }
            answer={value}
            disabled={!isApprover}
            afterApproving={afterDueDate && !fromApproveTraining}
          />
        )
      });
    }

    return columns;
  }, [
    childAttachments,
    isApprover,
    updateAnswer,
    needsApproval,
    afterDueDate,
    fromApproveTraining,
    attachments,
    trainingType,
    isMaster
  ]);

  const handleApproveRejectTrainings = (trainingIds, approveReject) => {
    updateAnswer({
      approveReject,
      assignee: trainingIds
    });
  };

  const actions = [];
  // We may use this later so removing for now
  // if (
  //   afterDueDate &&
  //   !fromApproveTraining &&
  //   accessLevel > 400 &&
  //   activeTraining.repeatingOn.length === 0 &&
  //   activeTraining.masterTemplateId === ''
  // ) {
  //   actions.push({
  //     label: 'Reassign',
  //     onClick: () => {
  //       setActiveTraining({ ...activeTraining, isReassigned: true });
  //       history.push('/app/training/trainingContainer');
  //     }
  //   });
  // }

  if (fromApproveTraining && needsApproval && isApprover) {
    actions.unshift(
      {
        label: 'Approve',
        onClick: row => handleApproveRejectTrainings(row, 'approve')
      },
      {
        label: 'Reject',
        onClick: row => handleApproveRejectTrainings(row, 'reject')
      }
    );

    // We may use this later so removing for now
    // if (afterDueDate) {
    //   actions.push(
    //     {
    //       label: 'Reject & Reassign',
    //       onClick: row => {
    //         handleApproveRejectTrainings(row, 'reject');
    //         updateModal(true);
    //       }
    //     },
    //     {
    //       label: 'Reassign',
    //       onClick: () => {
    //         setActiveTraining({ ...activeTraining, isReassigned: true });
    //         updateModal(true);
    //       }
    //     }
    //   );
    // }
  }

  return (
    <List
      saveKey="assignees"
      data={data}
      dataIsHash
      settings={columns}
      title="Assignee(s) Material List"
      name={name}
      getRowId={r => r.assignee}
      actions={actions}
    />
  );
};

export default AssigneeTable;
