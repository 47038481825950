import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import { setActiveCompany } from '../../actions/company';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import logoImage from './logo.png';
import Button from '../../components/inputs/Button';

import styles from './topHeader.module.scss';

export class TopHeader extends Component {
  getButtonText = () => {
    const { activeCompany, activeGroup, activeProject } = this.props;
    let buttonText = '';

    if (activeProject) {
      buttonText = `${activeCompany.name}: ${activeGroup.name}: ${activeProject.name}`;
    } else if (activeGroup) {
      buttonText = `${activeCompany.name}: ${activeGroup.name}`;
    } else if (activeCompany) {
      buttonText = `${activeCompany.name}`;
    }

    return buttonText;
  };

  render() {
    const { activeCompany, setActiveCompany } = this.props;

    return (
      <header className={styles.topNav}>
        <div className={styles.leftSide}>
          <div
            className={styles.logoContainer}
            onClick={() =>
              activeCompany?._id
                ? setActiveCompany() && history.push('/home')
                : null
            }
          >
            <img src={logoImage} alt="iReportSource logo" />
          </div>
          {activeCompany._id && (
            <div className={styles.locationContainer}>
              <div className={styles.locationContainerText}> Location:</div>
              <Button
                text={this.getButtonText()}
                color="white"
                className={styles.locationButton}
                onClick={() => history.push('/app/dashboard')}
              />
            </div>
          )}
        </div>
      </header>
    );
  }
}

export const mapStateToProps = state => ({
  activeCompany: getActiveCompany(state),
  activeGroup: getActiveLocationId(state),
  activeProject: getActiveProject(state)
});

export const matchDispatchToProps = dispatch => ({
  setActiveCompany: () => dispatch(setActiveCompany({}))
});

export default connect(mapStateToProps, matchDispatchToProps)(TopHeader);
