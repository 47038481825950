import React from 'react';
import history from '../../history';
import moment from 'moment';
import ReactLoading from 'react-loading';
import { COMBINED_EMPLOYEE_STATUS } from '../../constants/constants';
import Button from '../inputs/Button';
import { noBubble } from '../../utils/events';

const handleCardClick = (onClick, employee) => {
  onClick(employee);
  history.push('/app/viewEditPersonnel');
};

const getStatus = status => {
  if (!status) return '';

  return COMBINED_EMPLOYEE_STATUS.filter(emp => `${emp.value}` === status)[0]
    .label;
};

const EmployeeCard = ({
  employee,
  onClick,
  employeeLoading,
  setEmployeeType,
  downloadQRCodes
}) => (
  <div>
    {employeeLoading ? (
      <div className="incidentCard">
        <ReactLoading
          type="spinningBubbles"
          color="#58a15b"
          className="loading"
        />
      </div>
    ) : (
      <div
        className="incidentCard"
        onClick={noBubble(() => handleCardClick(onClick, employee))}
      >
        <div className="incidentCard-left">
          <div className="incidentCard-left-profile">{`${employee.firstName} ${employee.lastName}`}</div>
          <div className="incidentCard-left-profile">{employee.gender}</div>
          <div className="incidentCard-left-profile">
            {getStatus(employee.status)}
          </div>
          <Button
            color="blue"
            text="Download QR"
            onClick={e => {
              noBubble(e);
              downloadQRCodes({
                ids: [employee._id],
                employeeType: setEmployeeType
              });
            }}
          />
        </div>
        <div className="incidentCard-right">
          <div className="incidentCard-right-incidentDate">
            {moment(employee.dateOfHire).format('MM/DD/YYYY')}
          </div>
          <div className="incidentCard-right-incidentNumber">
            {employee.jobTitle}
          </div>
          <div className="incidentCard-right-incidentNumber">
            {employee.department}
          </div>
          <div className="incidentCard-right-incidentNumber">
            {employee.locationName}
          </div>
        </div>
      </div>
    )}
  </div>
);

export default EmployeeCard;
