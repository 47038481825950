import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { noBubble } from '../../utils/events';
import {
  createAnalyticsDashboard,
  getAnalyticsDashboard,
  cloneAnalyticsDashboard,
  updateAnalyticsDashboard,
  deleteAnalyticsDashboard,
  setDashboardFavorite
} from '../../api/v4';
import { getMyUserId } from '../../selectors/users';
import { useEffectUpdate } from '../../utils/hooks';
import UserBadge from '../../components/UserBadge';
import { Textbox, EmployeeDropdown, Checkbox } from '../../components/inputs';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import DashboardShares from '../DashboardShares';
import DashboardCard from '../DashboardCard';

import styles from './analytics.module.scss';

export const MyAnalytics = () => {
  const myUserId = useSelector(getMyUserId);
  const [dashboards, setDashboards] = useState([]);
  // Delete, Clone, Edit, Create, Share
  const [operation, setOperation] = useState(null);
  const [editing, setEditing] = useState(null);
  const [name, setName] = useState('');
  const [sharingDirty, setSharingDirty] = useState(false);
  const [search, setSearch] = useState(undefined);

  useEffect(() => {
    getAnalyticsDashboard().then(data => {
      setDashboards(
        data.map(d => ({
          ...d,
          createdAt: d.createdAt ? moment(d.createdAt) : -1
        }))
      );
    });
  }, []);

  useEffectUpdate(() => {
    if (!operation && sharingDirty) {
      getAnalyticsDashboard().then(data => {
        setDashboards(
          data.map(d => ({
            ...d,
            createdAt: d.createdAt ? moment(d.createdAt) : -1
          }))
        );
      });
      setSharingDirty(false);
    }
  }, [sharingDirty, operation]);

  const cloneDashboard = () => {
    cloneAnalyticsDashboard(editing).then(() => {
      getAnalyticsDashboard().then(data => {
        setDashboards(
          data.map(d => ({
            ...d,
            createdAt: d.createdAt ? moment(d.createdAt) : -1
          }))
        );
        setOperation(null);
        setEditing(null);
      });
    });
  };

  const updateShares = () => {
    updateAnalyticsDashboard(editing._id, { shares: editing.shares }).then(
      () => {
        setDashboards(current => [
          ...current.map(d => (d._id === editing._id ? editing : d))
        ]);
        setEditing(undefined);
        setOperation(null);
      }
    );
  };

  const updateDashboard = () => {
    const _id = editing?._id;
    if (_id) {
      updateAnalyticsDashboard(_id, { name }).then(data => {
        setDashboards(current => {
          let dashboards = [...current];
          let dashboard = dashboards.find(d => d._id === _id);
          if (dashboard) dashboard.name = name;
          return dashboards;
        });
        setOperation(null);
        setEditing(null);
      });
    } else {
      const dashboard = {
        name,
        period: {
          mode: 'year',
          frequency: 'monthly'
        }
      };
      createAnalyticsDashboard(dashboard).then(data => {
        setDashboards(current => [data, ...current]);
        setOperation(null);
      });
    }
  };

  const deleteDashboard = () => {
    const { _id } = editing;
    deleteAnalyticsDashboard(_id).then(data => {
      setDashboards(current => current.filter(d => d._id !== _id));
      setEditing(null);
      setOperation(null);
    });
  };

  const toggleFavorite = id => {
    let dashboard = dashboards.find(d => d._id === id);
    let isFavorite = !dashboard.isFavorite;
    setDashboardFavorite(id, isFavorite).then(data => {
      setDashboards(current => {
        let dashboards = [...current];

        let dashboard = dashboards.find(d => d._id === id);
        dashboard.isFavorite = isFavorite;

        return dashboards;
      });
    });
  };

  const header = (
    <Header
      title="My Analytics"
      rightButtons={[
        {
          text: 'Create Dashboard',
          type: 'button',
          color: 'blue',
          onClick: () => {
            setName('');
            setOperation('New');
          }
        }
      ]}
    />
  );

  let sortedDashboards = dashboards.sort((a, b) => b.createdAt - a.createdAt);
  if (search) {
    const s = search.trim().toLowerCase();
    sortedDashboards = dashboards.filter(d => d.name.toLowerCase().includes(s));
  }

  const favorites = sortedDashboards.filter(d => d.isFavorite);
  const normal = sortedDashboards.filter(d => !d.isFavorite);

  return (
    <>
      <HeaderAndFooter Header={header} className={styles.container}>
        <Textbox
          placeholder="Search"
          currentValue={search}
          onChange={setSearch}
          className={styles.search}
        />
        <div className={styles.favoritesWrapper}>
          <div className={styles.favoritesLabel}>Favorites</div>
          <div
            className={
              favorites.length ? styles.favorites : styles.addFavorites
            }
          >
            {favorites.length ? (
              favorites.map(f => (
                <DashboardCard
                  dashboard={f}
                  handleShareClick={value => {
                    noBubble(value);
                    setEditing(f);
                    setOperation('Share');
                    setSharingDirty(false);
                  }}
                  handleCloneClick={value => {
                    noBubble(value);
                    setEditing(f);
                    setOperation('Clone');
                  }}
                  handleEditClick={value => {
                    noBubble(value);
                    setEditing(f);
                    setName(f.name);
                    setOperation('Edit');
                  }}
                  handleToggleFavorite={value => {
                    noBubble(value);
                    toggleFavorite(f._id);
                  }}
                  handleDeleteClick={value => {
                    noBubble(value);
                    setEditing(f);
                    setOperation('Delete');
                  }}
                  bottomStyles={styles.bottom}
                />
              ))
            ) : (
              <>Mark dashboards as favorite to have them appear here.</>
            )}
          </div>
        </div>
        <div className={styles.other}>
          {normal.map(n => (
            <DashboardCard
              dashboard={n}
              handleShareClick={value => {
                noBubble(value);
                setEditing(n);
                setOperation('Share');
                setSharingDirty(false);
              }}
              handleCloneClick={value => {
                noBubble(value);
                setEditing(n);
                setOperation('Clone');
              }}
              handleEditClick={value => {
                noBubble(value);
                setEditing(n);
                setName(n.name);
                setOperation('Edit');
              }}
              handleToggleFavorite={value => {
                noBubble(value);
                toggleFavorite(n._id);
              }}
              handleDeleteClick={value => {
                noBubble(value);
                setEditing(n);
                setOperation('Delete');
              }}
              bottomStyles={styles.bottom}
            />
          ))}
        </div>
      </HeaderAndFooter>
      <Modal
        title="Confirm Delete"
        titleClassName="redHeader"
        isOpen={operation === 'Delete'}
        submitButtonColor="red"
        submitButtonText="Delete"
        onRequestClose={() => {
          setEditing(null);
          setOperation(null);
        }}
        submitActions={deleteDashboard}
      >
        <h2>Are you sure you want to delete {editing?.name}?</h2>
        {editing?.sharedBy ? (
          <>
            This dashboard was shared with you by{' '}
            <UserBadge userId={editing?.sharedBy} nameOnly />.
          </>
        ) : (
          <></>
        )}
      </Modal>
      <Modal
        title={`${operation} Dashboard`}
        titleClassName={operation === 'Create' ? 'greenHeader' : 'blueHeader'}
        isOpen={['Edit', 'New', 'Clone'].includes(operation)}
        submitButtonColor={operation === 'New' ? 'green' : 'blue'}
        submitButtonText={operation === 'Edit' ? 'Save' : operation}
        onRequestClose={() => setOperation(null)}
        submitActions={operation === 'Clone' ? cloneDashboard : updateDashboard}
        disableSubmit={
          operation === 'Clone'
            ? !editing?.cloneToMyProfile && !editing?.shares?.length
            : !name
        }
      >
        {operation === 'Clone' ? (
          <div>
            <Checkbox
              fieldLabel="Clone to my own profile"
              onChange={value =>
                setEditing(current => ({ ...current, cloneToMyProfile: value }))
              }
              currentValue={editing?.cloneToMyProfile}
            />
            <EmployeeDropdown
              multi
              currentValue={editing?.shares}
              fieldLabel="Clone to Profile"
              searchable
              exclude={[myUserId]}
              permissions={[500, 900]}
              placeholder="Choose one or more user(s)"
              onChange={userId => {
                setEditing(current => ({
                  ...current,
                  shares: userId
                }));
              }}
            />
          </div>
        ) : (
          <Textbox
            fieldLabel="Dashboard Title"
            currentValue={name}
            onChange={setName}
            placeholder="Type here"
          />
        )}
        <div className={styles.modalMessage}>
          {operation === 'New'
            ? 'This will add a new dashboard to your Analytics Page.'
            : operation === 'Clone'
            ? 'This will duplicate the dashboard to the selected user profile(s) and will be updated separately.'
            : ''}
        </div>
      </Modal>
      {operation === 'Share' ? (
        <Modal
          title={`Share ${editing?.name}`}
          isOpen={operation === 'Share'}
          titleClassName="blueHeader"
          submitButtonColor="blue"
          submitButtonText="Update"
          onRequestClose={() => {
            setEditing(null);
            setOperation(null);
          }}
          submitActions={updateShares}
          wide
          disableSubmit={!sharingDirty}
        >
          {editing?.shares?.length && sharingDirty ? (
            <div
              className={styles.sharingWith}
            >{`Sharing with ${editing?.shares?.length} user(s)`}</div>
          ) : null}
          <EmployeeDropdown
            multi
            fieldLabel="Share With"
            searchable
            permissions={[500, 900]}
            exclude={[...(editing?.shares ?? []).map(s => s.userId), myUserId]}
            placeholder="Select a user to share with"
            onChange={userIds => {
              setEditing(current => ({
                ...current,
                shares: [
                  ...(current.shares ?? []),
                  ...userIds.map(userId => {
                    return { userId, readOnly: true };
                  })
                ]
              }));
              setSharingDirty(true);
            }}
          />
          <DashboardShares
            title="Active Permissions"
            expired={false}
            onChange={value => {
              setEditing({ ...editing, shares: value });
              setSharingDirty(true);
            }}
            setSharingDirty={value => setSharingDirty(value)}
            shares={editing?.shares?.filter(
              s => !s.endDate || moment().isBefore(s.endDate)
            )}
          />
          <DashboardShares
            title="Expired Permissions"
            expired
            onChange={value => {
              setEditing({ ...editing, shares: value });
              setSharingDirty(true);
            }}
            setSharingDirty={value => setSharingDirty(value)}
            shares={editing?.shares?.filter(
              s => s.endDate && moment().isSameOrAfter(s.endDate)
            )}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default MyAnalytics;
