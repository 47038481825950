import React from 'react';
import './svg.css';

export default props => (
  <svg
    version="1.1"
    x={props.x || "0px"}
    y={props.y || "0px"}
    width="30px"
    height="25px"
    viewBox="0 0 200 200"
  >
    <g id="lDZoYy.tif">
      <g>
        <path
          d="M93.7,194c-8.9-2.2-18.2-3.3-26.6-6.7C35,174.2,15.2,150.3,9.5,116c-5.6-33.9,5.2-62.7,30.9-85.3
                      C61.6,12.1,86.8,4.9,114.6,9.2c33,5.1,56.6,23.5,71,53.5c4.1,8.6,6.4,17.7,7.6,27.1c0.2,1.3,0.5,2.6,0.7,3.9c0,4.8,0,9.7,0,14.5
                      c-0.5,2.6-0.9,5.2-1.4,7.8c-6.4,34.2-25.7,58.1-57.8,71.2c-8.4,3.4-17.7,4.5-26.6,6.7C103.4,194,98.6,194,93.7,194z M100.9,15.4
                      C53.8,15.5,15.5,53.9,15.4,101c-0.1,47,38.3,85.4,85.5,85.6c47,0.2,86-38.8,85.7-85.7C186.3,53.6,147.9,15.3,100.9,15.4z"
        />
        <path
          d="M95.3,114.4c0-9.3,0.1-18.6-0.1-27.9c0-2.8,0.8-3.8,3.7-3.7c8.6,0.3,7.6-1.2,7.6,7.4
                      c0,17.4-0.1,34.8,0.1,52.2c0,3.3-0.9,4.5-4.3,4.3c-7.7-0.3-7,0.9-7-7C95.3,131.4,95.3,122.9,95.3,114.4z"
        />
        <path
          d="M106.8,62.3c-0.1,6.7,0.8,6.1-5.9,6.1c-6.6,0-5.7,0.6-5.7-6.3c0-6.7-0.8-6.1,5.9-6.1
                      C107.7,56,106.7,55.5,106.8,62.3z"
        />
      </g>
    </g>
  </svg>
);
