export const PART_OF_BODY_OPTIONS = [
  { label: 'Head', value: 10 },
  { label: 'Skull', value: 11 },
  { label: 'Brain', value: 12 },
  { label: 'Ears', value: 13 },
  { label: 'Eye', value: 14 },
  { label: 'Nose', value: 15 },
  { label: 'Teeth', value: 16 },
  { label: 'Mouth', value: 17 },
  { label: 'Facial Soft Tissue', value: 18 },
  { label: 'Facial Bones', value: 19 },
  { label: 'Neck', value: 20 },
  { label: 'Vertebrae In Neck', value: 21 },
  { label: 'Disc In Neck', value: 22 },
  { label: 'Spinal Cord Neck Area', value: 23 },
  { label: 'Larynx', value: 24 },
  { label: 'Neck Soft Tissue', value: 25 },
  { label: 'Trachea', value: 26 },
  { label: 'Upper Extremities', value: 30 },
  { label: 'Upper Arm Clavicle or Scapula', value: 31 },
  { label: 'Elbow', value: 32 },
  { label: 'Lower Arm', value: 33 },
  { label: 'Wrist', value: 34 },
  { label: 'Hand', value: 35 },
  { label: 'Finger', value: 36 },
  { label: 'Thumb', value: 37 },
  { label: 'Shoulder', value: 38 },
  { label: 'Wrist and Hand', value: 39 },
  { label: 'Trunk', value: 40 },
  { label: 'Upper Back Thoracic', value: 41 },
  { label: 'Low Back Lumbar or Lumbosacral', value: 42 },
  { label: 'Disc in Low or Middle Back', value: 43 },
  { label: 'Chest Ribs or Sternum', value: 44 },
  { label: 'Sacrum or Coccyx', value: 45 },
  { label: 'Pelvis', value: 46 },
  { label: 'Spinal Cord Low or Middle Back Area', value: 47 },
  { label: 'Internal Organs', value: 48 },
  { label: 'Heart Angina', value: 49 },
  { label: 'Lower Extremities', value: 50 },
  { label: 'Hip', value: 51 },
  { label: 'Thigh', value: 52 },
  { label: 'Knee', value: 53 },
  { label: 'Lower Leg', value: 54 },
  { label: 'Ankle', value: 55 },
  { label: 'Foot', value: 56 },
  { label: 'Toe', value: 57 },
  { label: 'Great Toe', value: 58 },
  { label: 'Lungs', value: 60 },
  { label: 'Abdomen', value: 61 },
  { label: 'Buttocks', value: 62 },
  { label: 'Lumbar and/or Sacral Vertebrae', value: 63 },
  { label: 'Artifical Appliance', value: 64 },
  { label: 'Insufficient Information', value: 65 },
  { label: 'No Physical Injury', value: 66 },
  { label: 'Multiple Body Parts', value: 90 },
  { label: 'Body Systems and Mulitple Systems', value: 91 }
];

export const NATURE_OF_INJURY_OPTIONS = [
  {
    label: 'Acquired Immunodeficiency Syndrome',
    value: 75,
    applicableBodyParts: [90, 91]
  },
  {
    label: 'Adverse Reaction to Vaccination or Inoculation',
    value: 38,
    applicableBodyParts: [91]
  },
  {
    label: 'Amputation',
    value: 2,
    applicableBodyParts: [
      11,
      13,
      15,
      23,
      24,
      25,
      26,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      40,
      47,
      48,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      90
    ]
  },
  { label: 'Angina Pectoris', value: 3, applicableBodyParts: [44, 49] },
  {
    label: 'Asphyxiation',
    value: 54,
    applicableBodyParts: [10, 12, 15, 17, 19, 20, 24, 26, 44, 60]
  },
  {
    label: 'Burn',
    value: 4,
    applicableBodyParts: [
      10,
      12,
      13,
      14,
      15,
      17,
      18,
      19,
      20,
      24,
      25,
      26,
      30,
      31,
      32,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42
    ]
  },
  {
    label: 'Cancer',
    value: 74,
    applicableBodyParts: [
      10,
      11,
      12,
      13,
      14,
      15,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      60,
      90,
      91
    ]
  },
  { label: 'Concussion', value: 7, applicableBodyParts: [10, 11, 12, 19] },
  { label: 'Contagious Disease', value: 73, applicableBodyParts: [10] },
  {
    label: 'Contusion',
    value: 10,
    applicableBodyParts: [
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      61,
      62,
      63,
      90
    ]
  },
  { label: 'Covid19/Coronavirus', value: 83, applicableBodyParts: [60, 91] },
  {
    label: 'Crushing',
    value: 13,
    applicableBodyParts: [
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      61,
      63,
      90
    ]
  },
  {
    label: 'Cumulative Injury',
    value: 80,
    applicableBodyParts: [
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      90
    ]
  },
  {
    label: 'Dermatitis',
    value: 68,
    applicableBodyParts: [
      10,
      11,
      13,
      15,
      17,
      18,
      20,
      25,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      44,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      61,
      62,
      90
    ]
  },
  {
    label: 'Dislocation',
    value: 16,
    applicableBodyParts: [
      15,
      16,
      19,
      20,
      21,
      22,
      23,
      30,
      31,
      32,
      34,
      36,
      37,
      38,
      39,
      41,
      42,
      43,
      45,
      46,
      47,
      48,
      50,
      51,
      53,
      55,
      57,
      58,
      63
    ]
  },
  {
    label: 'Dust Exposure',
    value: 60,
    applicableBodyParts: [10, 20, 24, 30, 40, 44, 48, 50, 60, 90]
  },
  {
    label: 'Electric Shock',
    value: 19,
    applicableBodyParts: [
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      20,
      23,
      25,
      30,
      31,
      35,
      36,
      37,
      40,
      47,
      49,
      50,
      54,
      56,
      57,
      90,
      91
    ]
  },
  {
    label: 'Foreign Body',
    value: 25,
    applicableBodyParts: [
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      61,
      62,
      90
    ]
  },
  {
    label: 'Fracture',
    value: 28,
    applicableBodyParts: [
      10,
      11,
      15,
      16,
      19,
      20,
      21,
      22,
      23,
      24,
      26,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      63,
      90
    ]
  },
  {
    label: 'Freezing',
    value: 30,
    applicableBodyParts: [
      10,
      11,
      13,
      14,
      15,
      16,
      17,
      18,
      20,
      25,
      30,
      31,
      33,
      35,
      36,
      37,
      40,
      41,
      42,
      44,
      50,
      54,
      56,
      57,
      58,
      90
    ]
  },
  {
    label: 'Hearing Loss Non Traumatic',
    value: 72,
    applicableBodyParts: [10, 12, 13]
  },
  {
    label: 'Hearing Loss Traumatic',
    value: 31,
    applicableBodyParts: [10, 13, 20]
  },
  {
    label: 'Heat Prostration',
    value: 32,
    applicableBodyParts: [10, 12, 20, 30, 40, 50, 90]
  },
  {
    label: 'Hernia',
    value: 34,
    applicableBodyParts: [21, 22, 23, 24, 25, 26, 43, 44, 46, 48, 61]
  },
  {
    label: 'Infection',
    value: 36,
    applicableBodyParts: [
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      60,
      61,
      62,
      90
    ]
  },
  {
    label: 'Inflammation',
    value: 37,
    applicableBodyParts: [
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      60,
      61,
      62,
      90
    ]
  },
  {
    label: 'Laceration',
    value: 40,
    applicableBodyParts: [
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      61,
      62,
      90
    ]
  },
  {
    label: 'Lung Disease Asbestosis',
    value: 61,
    applicableBodyParts: [10, 20, 24, 30, 40, 44, 50, 60, 90]
  },
  {
    label: 'Lung Disease Byssinosis',
    value: 63,
    applicableBodyParts: [10, 20, 24, 30, 40, 44, 50, 60, 90]
  },
  {
    label: 'Lung Disease Pneumoconiosis',
    value: 62,
    applicableBodyParts: [10, 20, 24, 30, 40, 44, 48, 50, 60, 90]
  },
  {
    label: 'Lung Disease Silicosis',
    value: 64,
    applicableBodyParts: [10, 20, 24, 30, 40, 44, 50, 60, 90]
  },
  {
    label: 'Mental Disorder',
    value: 69,
    applicableBodyParts: [10, 12, 20, 23, 30, 40, 50, 90]
  },
  { label: 'Mental Stress', value: 77, applicableBodyParts: [10, 12, 66] },
  { label: 'Multiple Injuries', value: 91, applicableBodyParts: [90, 91] },
  {
    label: 'Multiple Physical Injuries',
    value: 90,
    applicableBodyParts: [48, 90, 91]
  },
  { label: 'Myocardial Infarction', value: 41, applicableBodyParts: [49] },
  { label: 'No Physical Injury', value: 1, applicableBodyParts: [64] },
  {
    label: 'Occupational Disease',
    value: 71,
    applicableBodyParts: [
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      60,
      90,
      91
    ]
  },
  {
    label: 'Other Injury Not Otherwise Catagorized',
    value: 59,
    applicableBodyParts: [
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      60,
      61,
      62,
      63,
      65,
      90,
      91
    ]
  },
  {
    label: 'Poisoning - Chemical',
    value: 66,
    applicableBodyParts: [
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      60,
      90,
      91
    ]
  },
  {
    label: 'Poisoning - Metal',
    value: 67,
    applicableBodyParts: [
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      60,
      90,
      91
    ]
  },
  {
    label: 'Poisoning Other than Chemical or Metal',
    value: 42,
    applicableBodyParts: [48, 60, 90, 91]
  },
  {
    label: 'Puncture',
    value: 43,
    applicableBodyParts: [
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      60,
      61,
      62,
      90
    ]
  },
  {
    label: 'Radiation',
    value: 70,
    applicableBodyParts: [
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      60,
      90,
      91
    ]
  },
  {
    label: 'Respiratory Disorder',
    value: 65,
    applicableBodyParts: [
      10,
      12,
      15,
      17,
      20,
      23,
      24,
      26,
      30,
      40,
      44,
      47,
      48,
      50,
      60,
      90
    ]
  },
  {
    label: 'Severance',
    value: 47,
    applicableBodyParts: [
      10,
      13,
      15,
      23,
      25,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      40,
      45,
      47,
      50,
      53,
      54,
      55,
      56,
      57,
      58,
      90
    ]
  },
  {
    label: 'Sprain',
    value: 49,
    applicableBodyParts: [
      10,
      13,
      19,
      20,
      21,
      23,
      25,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      44,
      45,
      46,
      47,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      62,
      90
    ]
  },
  {
    label: 'Strain',
    value: 52,
    applicableBodyParts: [
      10,
      13,
      14,
      15,
      17,
      19,
      20,
      21,
      23,
      24,
      25,
      26,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      40,
      41,
      42,
      44,
      45,
      46,
      47,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      61,
      62,
      90
    ]
  },
  {
    label: 'Syncope',
    value: 53,
    applicableBodyParts: [10, 12, 49, 60, 66, 90, 91]
  },
  {
    label: 'Vascular',
    value: 55,
    applicableBodyParts: [
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      90,
      91
    ]
  },
  {
    label: 'Video Display Terminal Related Disease',
    value: 76,
    applicableBodyParts: [10, 14, 20, 30, 40, 50, 90]
  },
  { label: 'Vision Loss', value: 58, applicableBodyParts: [10, 12, 14] }
];

export const INJURY_CAUSE_CODE_OPTIONS = [
  { label: 'Burn or scald by chemicals', value: 1 },
  { label: 'Burn or scald by hot object or substance', value: 2 },
  { label: 'Burn or scald by temperature extreme', value: 3 },
  { label: 'Burn or scald by fire or flame', value: 4 },
  { label: 'Burn or scald by steam or hot fluids', value: 5 },
  { label: 'Burn or scald by dust gases, fumes, or vapors', value: 6 },
  { label: 'Burn or scald by welding operation', value: 7 },
  { label: 'Burn or scald by radiation', value: 8 },
  { label: 'Other cause of burn', value: 9 },
  { label: 'Caught in/under/between machine or machinery', value: 10 },
  { label: 'Burn or scald by cold objects or substances', value: 11 },
  { label: 'Caught in/under/between object handled', value: 12 },
  { label: 'Other caught in/under/between', value: 13 },
  { label: 'Burn or scald by abnormal air pressure', value: 14 },
  { label: 'Cut/punctured/scraped by broken glass', value: 15 },
  {
    label: 'Cut/punctured/scraped by hand tool/utensil not powered',
    value: 16
  },
  {
    label: 'Cut/punctured/scraped by object being lifted or handled',
    value: 17
  },
  { label: 'Cut/punctured/scraped by powered hand tool appliance', value: 18 },
  { label: 'Other cause of cut/puncture/scrape', value: 19 },
  {
    label: 'Caught in/under/betwwen collapsing materials/slides of earth',
    value: 20
  },
  { label: 'Slip/fall/trip from different level', value: 25 },
  { label: 'Slip/fall/trip from ladder or scaffolding', value: 26 },
  { label: 'Slip/fall/trip from liquid or grease spills', value: 27 },
  { label: 'Slip/fall/trip into opening', value: 28 },
  { label: 'Slip/fall/trip on same level', value: 29 },
  { label: 'Slip or trip did no fall', value: 30 },
  { label: 'Other cause of slip/trip/fall', value: 31 },
  { label: 'Slip/fall/trip on ice or snow', value: 32 },
  { label: 'Slip/fall/trip on stairs', value: 33 },
  { label: 'Crash of water vehicle', value: 40 },
  { label: 'Crash of rail vehicle', value: 41 },
  { label: 'Motor vehicle collision with another vehicle', value: 45 },
  { label: 'Motor vehicle collision with fixed object', value: 46 },
  { label: 'Crash of ariplane', value: 47 },
  { label: 'Motor vehicle upset', value: 48 },
  { label: 'Other motor vehicle cause or injury', value: 50 },
  { label: 'Strain or injury by continual noise', value: 52 },
  { label: 'Strain or injury by twisting', value: 53 },
  { label: 'Strain or injury by jumping or leaping', value: 54 },
  { label: 'Strain or injury by holding or carrying', value: 55 },
  { label: 'Strain or injury by lifting', value: 56 },
  { label: 'Strain or injury by pushing or pulling', value: 57 },
  { label: 'Strain or injury by reaching', value: 58 },
  { label: 'Strain or injury by using tool or machine', value: 59 },
  { label: 'Other cause of strain', value: 60 },
  { label: 'Strain or injury by wielding or throwing', value: 61 },
  { label: 'Struck against or stepped on moving parts', value: 65 },
  {
    label: 'Struck against or stepped on object being lifted or held',
    value: 66
  },
  { label: 'Injured by sanding/scraping/cleaning operations', value: 67 },
  { label: 'Struck by or stepped on stationary object', value: 68 },
  { label: 'Struck by or stepped on sharp object', value: 69 },
  { label: 'Other struck against or stepped on causes', value: 70 },
  {
    label: 'Struck or injured by person, fellow worker, patient or other',
    value: 74
  },
  { label: 'Struck or injured by object falling or flying object', value: 75 },
  {
    label: 'Struck or injured by object hand tool or machine in use',
    value: 76
  },
  { label: 'Struck or injured by object motor vehicle', value: 77 },
  { label: 'Struck or injured by object moving parts of machine', value: 78 },
  { label: 'Struck or injured by object being lifted or handled', value: 79 },
  { label: 'Struck or injured by object handled by others', value: 80 },
  { label: 'Other struck or injured by causes', value: 81 },
  { label: 'Absorption/ingestion/inhalation/vaccination', value: 82 },
  { label: 'Pandemic', value: 83 },
  { label: 'Burn or scald by electric current', value: 84 },
  { label: 'Struck or injured by animal or insect', value: 85 },
  { label: 'Struck or injured by explosion or flare back', value: 86 },
  { label: 'Foreign matter in eye', value: 87 },
  { label: 'Natural disasters/earthquake/hurricane/tornado/etc', value: 88 },
  {
    label: 'Act of crime, robbery or criminal assult by other person',
    value: 89
  },
  { label: 'Other than physical cause of injury', value: 90 },
  { label: 'Mold', value: 91 },
  { label: 'Rubbed or abraded by repetitive motion', value: 94 },
  { label: 'Otehr rubbed or abraded by causes', value: 95 },
  { label: 'Terrorism', value: 96 },
  { label: 'Strain or injury by repetitive motion', value: 97 },
  { label: 'Cumulative injury', value: 98 },
  { label: 'Miscellaneous/not otherwise categorized causes', value: 99 }
];

export const MARITAL_STATUS = [
  { label: 'Single', value: 'single' },
  { label: 'Married', value: 'married' },
  { label: 'Separated', value: 'separated' },
  { label: 'Divorced', value: 'divorced' },
  { label: 'Widowed', value: 'widowed' },
  { label: 'Other', value: 'other' },
  { label: 'Unknown', value: 'unknown' }
];

export const EMPLOYEMENT_STATUS = [
  { label: 'Full Time', value: 'fullTimeRegularEmployee' },
  { label: 'Part Time', value: 'partTimeEmployee' },
  { label: 'Unemployed', value: 'unemployed' },
  { value: 'onStrikeEmployee', label: 'On Strike' },
  { label: 'Disabled', value: 'disabledEmployee' },
  { label: 'Retired', value: 'retiredEmployee' },
  { label: 'Other', value: 'other' },
  { label: 'Seasonal', value: 'seasonalOrTemporaryEmployee' },
  { label: 'Volunteer', value: 'volunteer' },
  { label: 'Full Time Apprentice', value: 'fullTimeApprenticeOrIntern' },
  { label: 'Part Time Apprentice', value: 'partTimeApprenticeOrIntern' },
  { label: 'Piece Worker', value: 'pieceWorker' },
  { label: 'Unknown', value: 'unknown' }
];

export const WAGES_PAID_FOR_DATE_OF_INJURY_OPTIONS = [
  { label: 'No pay for day of injury', value: 'noPayForDayOfInjury' },
  { label: 'Unknown', value: 'unknown' },
  { label: 'Full pay for day of injury', value: 'fullPayForDayOfInjury' },
  { label: 'Partial pay for day of injury', value: 'partialPayForDayOfInjury' }
];

export const ABSENCE_FROM_WORK_DUE_TO_INJURY_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
  { label: 'Unknown', value: 'unknown' }
];

export const INITIAL_TREATMENT_OPTIONS = [
  { label: 'No medical treatment', value: 'noMedicalTreatment' },
  { label: 'Minor onsite treatment', value: 'minorOnsiteTreatment' },
  {
    label: "Treatment at physican's office",
    value: 'treatmentAtPhysiciansOffice'
  },
  { label: 'Treatment at clinic', value: 'treatmentAtClinic' },
  {
    label: 'Hospital emergency room treated and released',
    value: 'hospitalEmergencyRoomTreatedAndReleased'
  },
  {
    label: 'Hospital admitted treated longer than 24 hours',
    value: 'hospitalAdmittedTreatedLongerThan24Hours'
  },
  {
    label: 'Hospital admitted treated less than 24 hours',
    value: 'hospitalAdmittedTreatedLessThan24Hours'
  },
  { label: 'Treatment unknown', value: 'treatmentUnknown' }
];

export const EVENT_LOCATION_DESCRIPTION = [
  {
    label: 'Employees designated company location',
    value: 'EmployeesDesignatedCompanyLocation'
  },
  { label: 'A different company location', value: 'DifferentCompanyLocation' },
  {
    label: 'Another location, not a company location',
    value: 'NonCompanyLocation'
  },
  {
    label: 'Working from home employees address',
    value: 'WorkingFromHomeEmployeesAddress'
  }
];

export const CLAIM_REPORTED_BY_TITLE = [
  { label: 'Injured Worker', value: 'InjuredWorker' },
  { label: 'Customer Contact', value: 'CustomerContact' },
  { label: 'Supervisor Of Injured Worker', value: 'SupervisorOfInjuredWorker' },
  { label: 'Attorney For Injured Worker', value: 'AttorneyForInjuredWorker' },
  { label: 'Insurance Broker Or Agent', value: 'InsuranceBrokerOrAgent' },
  {
    label: 'Human Resources Or Personnel Department',
    value: 'HumanResourcesOrPersonnelDepartment'
  },
  { label: 'Company Nurse Or Doctor', value: 'CompanyNurseOrDoctor' },
  {
    label: 'Work Comp Board Or Commission',
    value: 'WorkCompBoardOrCommission'
  },
  { label: 'Medical Provider', value: 'MedicalProvider' },
  { label: 'Managed Care Organization', value: 'ManagedCareOrganization' },
  { label: 'Other', value: 'Other' }
];
