import React, { Component } from 'react';

import UserBadge from '../../components/UserBadge';
import classnames from 'classnames/bind';

import styles from './assignee.module.scss';
const bStyles = classnames.bind(styles);

export class Assignee extends Component {
  state = { showOptions: false };

  render() {
    const { user, className } = this.props;
    const options = this.props.options || [];
    const text = this.props.text || 'Assignee';

    const { showOptions } = this.state;

    let classes = {
      shown: showOptions,
      assignee: true,
      hasOption: options.length > 0
    };

    return (
      <div className={bStyles(className, classes)}>
        <div onClick={() => this.setState({ showOptions: !showOptions })}>
          <UserBadge
            userId={user}
            showImage
            showName
            size="medium"
            byline={text}
            className={styles.profileBadge}
          />
          {options.length ? (
            <div className={styles.dropdown}>
              <img
                src={
                  showOptions
                    ? require('../../assets/images/Chevron.png')
                    : require('../../assets/images/downChevron.png')
                }
                alt={showOptions ? 'dropdown open' : 'dropdown closed'}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        {options.length ? (
          <ul>
            {options
              .filter(o => o.visible)
              .map((o, i) => {
                // if ('visible' in o && !o.visible) {
                //   return <></>;
                // }
                return (
                  <li
                    onClick={() =>
                      this.setState(
                        { showOptions: false },
                        o.onClick || (() => {})
                      )
                    }
                    key={i}
                  >
                    <div>{o.label}</div>
                  </li>
                );
              })}
          </ul>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default Assignee;
