import React from 'react';
import './svg.css';

export default props => (
  <svg
    version="1.1"
    x={props.x || "0px"}
    y={props.y || "0px"}
    width="30px"
    height="25px"
    viewBox="0 0 1000 1000"
  >
    <g id="Layer_2">
      <g id="Layer_2-2">
        <g id="icon-40-clipboard-list">
          <path
            id="clipboard-list"
            d="M499.983,100c-9.226,0-16.7,7.477-16.7,16.7c0,9.223,7.477,16.7,16.7,16.7
                        c9.223,0,16.698-7.477,16.698-16.7C516.683,107.477,509.207,100,499.983,100L499.983,100z M499.983,100
                        c-9.226,0-16.7,7.477-16.7,16.7c0,9.223,7.477,16.7,16.7,16.7c9.223,0,16.698-7.477,16.698-16.7
                        C516.683,107.477,509.207,100,499.983,100L499.983,100z M499.983,100c-9.226,0-16.7,7.477-16.7,16.7
                        c0,9.223,7.477,16.7,16.7,16.7c9.223,0,16.698-7.477,16.698-16.7C516.683,107.477,509.207,100,499.983,100L499.983,100z
                         M499.983,100c-9.226,0-16.7,7.477-16.7,16.7c0,9.223,7.477,16.7,16.7,16.7c9.223,0,16.698-7.477,16.698-16.7
                        C516.683,107.477,509.207,100,499.983,100L499.983,100z M499.983,100c-9.226,0-16.7,7.477-16.7,16.7
                        c0,9.223,7.477,16.7,16.7,16.7c9.223,0,16.698-7.477,16.698-16.7C516.683,107.477,509.207,100,499.983,100L499.983,100z
                         M499.983,133.4c9.223,0,16.698-7.477,16.698-16.7c0-9.223-7.478-16.7-16.698-16.7c-9.226,0-16.7,7.477-16.7,16.7
                        c0.037,9.197,7.503,16.633,16.7,16.633V133.4L499.983,133.4z M499.983,100c-9.226,0-16.7,7.477-16.7,16.7
                        c0,9.223,7.477,16.7,16.7,16.7c9.223,0,16.698-7.477,16.698-16.7C516.683,107.477,509.207,100,499.983,100L499.983,100z
                         M499.983,100c-9.226,0-16.7,7.477-16.7,16.7c0,9.223,7.477,16.7,16.7,16.7c9.223,0,16.698-7.477,16.698-16.7
                        C516.683,107.477,509.207,100,499.983,100L499.983,100z M499.983,100c-9.226,0-16.7,7.477-16.7,16.7
                        c0,9.223,7.477,16.7,16.7,16.7c9.223,0,16.698-7.477,16.698-16.7C516.683,107.477,509.207,100,499.983,100L499.983,100z
                         M499.983,100c-9.226,0-16.7,7.477-16.7,16.7c0,9.223,7.477,16.7,16.7,16.7c9.223,0,16.698-7.477,16.698-16.7
                        C516.683,107.477,509.207,100,499.983,100L499.983,100z M499.983,100c-9.226,0-16.7,7.477-16.7,16.7
                        c0,9.223,7.477,16.7,16.7,16.7c9.223,0,16.698-7.477,16.698-16.7C516.683,107.477,509.207,100,499.983,100L499.983,100z
                         M750.084,166.8h-33.4c0-36.893-29.906-66.8-66.801-66.8h-33.397c-0.111-55.235-44.9-99.963-100.136-100H483.65
                        c-55.263,0-100.092,44.739-100.2,100h-33.4c-36.893,0-66.8,29.907-66.8,66.8l0,0h-33.4c-36.745,0-66.533,29.788-66.533,66.533
                        c0,0.034,0,0.067,0,0.101v699.8c-0.11,36.78,29.618,66.688,66.399,66.8c0.067,0,0.134,0,0.201,0h500.167
                        c36.745,0.037,66.562-29.722,66.6-66.466c0-0.111,0-0.225,0-0.334v-699.8c-0.019-36.802-29.799-66.656-66.6-66.767V166.8z
                         M316.65,166.8c-0.148-18.298,14.564-33.251,32.862-33.399c0.111-0.001,0.226-0.001,0.335-0.001h66.803V100
                        c0.108-36.828,30.006-66.619,66.833-66.6h33.031c36.781-0.111,66.689,29.617,66.802,66.399c0,0.067,0,0.134,0,0.201v33.4h66.801
                        c18.368,0.11,33.2,15.031,33.199,33.4v33.4c0.129,18.317-14.614,33.271-32.934,33.399c-0.089,0.001-0.179,0.001-0.268,0.001
                        H349.85c-18.366-0.11-33.198-15.032-33.197-33.4L316.65,166.8L316.65,166.8z M783.35,933.233
                        c0.131,18.314-14.611,33.271-32.931,33.397c-0.156,0.002-0.312,0.002-0.469,0H250.117c-18.446,0-33.4-14.953-33.4-33.397v-699.8
                        c-0.13-18.317,14.614-33.271,32.931-33.4c0.157-0.001,0.313-0.001,0.469,0h33.4c0,36.893,29.908,66.8,66.8,66.8h299.599
                        c36.894,0,66.801-29.907,66.801-66.8h33.4c18.446,0,33.4,14.954,33.4,33.4L783.35,933.233z"
          />
        </g>
        <rect x="308.834" y="669.605" width="382.265" height="28.99" />
        <rect x="308.834" y="818.236" width="382.265" height="28.989" />
        <rect x="308.834" y="520.941" width="382.265" height="28.99" />
        <rect x="308.834" y="372.312" width="382.265" height="28.991" />
      </g>
    </g>
  </svg>
);
