import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import * as api from '../../../api';
import history from '../../../history';
import {
  setActiveTrainingResponse,
  deleteTrainingRequest,
  setActiveTrainingRequest
} from '../../../actions/training';
import { getActiveCompany } from '../../../selectors/company';
import { getActiveTrainingSelector } from '../../../selectors/training';
import AssigneeTable from '../../../components/training/AssigneeTable';
import Card from '../../../components/Card';
import { DatePicker } from '../../../components/inputs/DateTimePicker';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import LeftNav from '../../../components/LeftNav';
import QuizTable from '../../../components/training/QuizTable';
import SignatureCard from '../../../components/SignatureCard';
import TrainingDetails from '../TrainingDetails';
class TrainingSummary extends Component {
  state = {
    assigneeApproval: [],
    openDeleteConfirmationModal: false,
    quizzes: []
  };

  componentDidMount() {
    this.loadTraining();

    let _id = this.props?.match?.params?.trainingId;

    if (_id) {
      this.props.setTrainingReq({ _id, stay: true });
    }
    api
      .fetchQuizzes(this.props.activeCompany._id)
      .then(response => this.setState({ quizzes: response }));
  }

  loadTraining = () => {
    let assigneeApproval = [];
    let attachments = this.props.activeTraining?.childAttachments;

    if (attachments?.length > 0) {
      for (let i = 0; i < attachments.length; i++) {
        const attachment = attachments[i];
        if (
          assigneeApproval.findIndex(
            assignee => assignee.assignee === attachment.assignedTo
          ) === -1
        ) {
          assigneeApproval.push({
            approveReject: attachment.approveReject,
            assignee: attachment.assignedTo
          });
        }
      }
    }
    this.setState({ assigneeApproval });
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.activeTraining, this.props.activeTraining)) {
      if (prevProps.activeTraining?._id) {
        this.setState({ hasUnsavedChanges: true });
      }
      this.loadTraining();
    }
  }

  printTrainingSummary = async () => {
    const { activeTraining } = this.props;

    const url = await api.printTrainingSummary(activeTraining);

    window.open(url, '_blank');
  };

  handleBack = () => {
    this.props.setActiveTraining({});
    history.goBack();
  };

  handleDelete = () => {
    const { deleteTraining } = this.props;

    deleteTraining(this.props.activeTraining);
  };

  getQuickNav = () => {
    const { activeTraining } = this.props;

    let leftNav = [{ label: activeTraining.title, id: '0' }];

    if (activeTraining.childAttachments?.length) {
      leftNav = [...leftNav, { label: 'Material List', id: '1' }];
    }

    if (activeTraining.quiz) {
      leftNav = [...leftNav, { label: 'Quiz List', id: '2' }];
    }

    if (activeTraining.needsApproval && activeTraining.approverSignature) {
      leftNav = [...leftNav, { label: 'Signed and Approved', id: '3' }];
    }

    return leftNav;
  };

  render() {
    const { activeTraining } = this.props;
    const { assigneeApproval, quizzes } = this.state;

    const quizDropdown = quizzes?.map(quiz => {
      return {
        label: quiz.title,
        value: quiz._id
      };
    });

    const header = (
      <Header
        title="Safety Training"
        section={activeTraining.title}
        clickBack={() => this.handleBack()}
        rightButtons={{
          visible: activeTraining.isMaster,
          text: 'Delete Training',
          onClick: () => this.setState({ openDeleteConfirmationModal: true }),
          color: 'red'
        }}
      />
    );

    return (
      <>
        <HeaderAndFooter
          Header={header}
          showFooter={false}
          Left={<LeftNav items={this.getQuickNav()} />}
        >
          <Card
            title={activeTraining.title}
            showHeader
            name="0"
            rightButton={{
              text: 'Print',
              onClick: () => this.printTrainingSummary(),
              color: 'blue'
            }}
            wide
          >
            {activeTraining.legacy && (
              <DatePicker
                fieldLabel="Expiration Date"
                pickTime
                currentValue={activeTraining.expirationDate}
                disabled
                name="trainingSummaryExpirationDate"
              />
            )}
            {!activeTraining.legacy &&
              !activeTraining.isCompleted &&
              activeTraining.dueDate &&
              moment().isBefore(activeTraining.dueDate) && (
                <DatePicker
                  fieldLabel="Due Date"
                  pickTime
                  currentValue={activeTraining.dueDate}
                  disabled
                  name="trainingSummaryDueDate"
                />
              )}
            {!activeTraining.legacy && activeTraining.isCompleted && (
              <DatePicker
                fieldLabel="Completion Date"
                pickTime
                currentValue={activeTraining.completionDate}
                disabled
                name="trainingSummaryCompletionDate"
              />
            )}
          </Card>
          <TrainingDetails
            activeTraining={activeTraining}
            wide
            activeCompany={this.props.activeCompany}
            quizzes={quizzes}
            hasAttachments={activeTraining?.attachments?.length > 0}
            quizDropdown={quizDropdown}
          />
          {activeTraining?.attachments?.length && !activeTraining.legacy ? (
            <AssigneeTable
              assignees={activeTraining.assignedTo}
              attachments={activeTraining.attachments}
              childAttachments={activeTraining.childAttachments}
              needsApproval={
                activeTraining.needsApproval && !activeTraining?.quiz
              }
              assigneeApproval={assigneeApproval}
              name="1"
              dueDate={activeTraining.dueDate}
              trainingType={activeTraining.trainingType}
              childCompletionDates={
                activeTraining.isMaster
                  ? activeTraining.childCompletionDates
                  : activeTraining.completionDate
              }
              isMaster={activeTraining.isMaster}
              // We may need this later
              // setActiveTraining={() =>
              //   this.props.setActiveTraining({
              //     ...activeTraining,
              //     isReassigned: true
              //   })
              // }
              // activeTraining={activeTraining}
            />
          ) : null}
          {activeTraining?.quiz && (
            <QuizTable
              childQuizzes={
                activeTraining?.isMaster
                  ? activeTraining.childQuizzes
                  : [activeTraining]
              }
              setActiveTraining={values =>
                this.props.setActiveTraining({
                  ...values,
                  isEditing: false,
                  isFromSummary: true,
                  masterTraining: activeTraining
                })
              }
              assigneeApproval={assigneeApproval}
              needsApproval={activeTraining.needsApproval}
              name="2"
              dueDate={activeTraining.dueDate}
              activeTraining={activeTraining}
              isMaster={activeTraining.isMaster}
              childCompletionDates={activeTraining.childCompletionDates}
            />
          )}
          {activeTraining.needsApproval && activeTraining.approverSignature && (
            <SignatureCard
              header="Signed and Approved"
              dropdownName="Approver Name"
              permissions={[400, 500, 900]}
              userId={activeTraining.approver}
              name="3"
              currentValue={activeTraining?.approverSignature}
              userDisabled
              disabled
            />
          )}
        </HeaderAndFooter>
        <DeleteItemModal
          isOpen={this.state.openDeleteConfirmationModal}
          deletingWhat="Training"
          onRequestClose={() =>
            this.setState({ openDeleteConfirmationModal: false })
          }
          submitActions={() => this.handleDelete()}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeTraining: getActiveTrainingSelector(state),
  activeCompany: getActiveCompany(state)
});

const mapDispatchToProps = dispatch => ({
  setActiveTraining: payload => dispatch(setActiveTrainingResponse(payload)),
  deleteTraining: payload => dispatch(deleteTrainingRequest(payload)),
  setTrainingReq: payload => dispatch(setActiveTrainingRequest(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingSummary);
