import React from 'react';

import { Input, nameInput } from '../';
import Loading from '../../Loading';
import classnames from 'classnames/bind';
import { noBubble } from '../../../utils/events';

import styles from './styles.module.scss';
const bStyles = classnames.bind(styles);

const Button = ({
  text,
  color,
  inputClassName,
  type,
  image,
  name,
  onClick,
  onMouseDown,
  disabled,
  loading,
  shrink,
  testID,
  imageClass,
  ...props
}) => {
  type = type || 'button';
  color = color || 'white';
  let buttonClasses = {
    button: true,
    [color]: true,
    shrink: shrink
  };

  if (loading) {
    disabled = true;
    onClick = noBubble;
  }

  return (
    <Input name={name} disabled={disabled} inline={shrink} {...props}>
      <button
        data-cy={testID}
        className={bStyles(inputClassName, buttonClasses)}
        type={type}
        id={name}
        disabled={disabled}
        onClick={onClick}
        onMouseDown={onMouseDown}
      >
        {loading ? (
          <Loading size={32} skipDiv />
        ) : image ? (
          typeof image !== 'string' ? (
            image
          ) : (
            <img
              src={image}
              className={bStyles(styles.image, imageClass)}
              alt="Button"
            />
          )
        ) : (
          <></>
        )}
        <div>{text}</div>
      </button>
    </Input>
  );
};

const nButton = nameInput(Button);
export { nButton as Button };
export default nButton;
