import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import history from '../../history';
import { fetchWitnessStatement, updateWitnessStatement } from '../../api/v4';
import { Textbox, Dropdown } from '../../components/inputs';
import Signature from '../../components/inputs/Signature';
import { DatePicker } from '../../components/inputs/DateTimePicker';
import Card from '../../components/Card';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import { SubmitSaveForLaterFooter } from '../../components/Footer';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import MarkItemAsCompleteModal from '../../components/Modal/markItemAsCompleteModal';
import { WEATHER_CONDITIONS } from '../../constants/constants';

import styles from './styles.module.scss';

export default function WitnessStatementContainer(props) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [incidentDateTime, setIncidentDateTime] = useState(null);
  const [incidentDetails, setIncidentDetails] = useState('');
  const [dueDate, setDueDate] = useState(null);
  const [actionDuringIncident, setActionDuringIncident] = useState('');
  const [signature, setSignature] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [openCompleteModal, setOpenCompleteModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangedModal] = useState(false);
  const [incidentDescription, setIncidentDescription] = useState('');
  const [incidentNumber, setIncidentNumber] = useState('');
  const [weatherConditions, setWeatherConditions] = useState([]);

  const { statementId } = useParams();

  useEffect(() => {
    if (statementId) {
      fetchWitnessStatement(statementId, true).then(response => {
        setFirstName(response.firstName);
        setLastName(response.lastName);
        setContactInfo(response.contactInfo);
        setIncidentDateTime(response.incidentDateTime);
        setIncidentDetails(response.incidentDetails);
        setDueDate(response.dueDate);
        setActionDuringIncident(response.actionDuringIncident);
        setSignature(response.signature);
        setIncidentDescription(response.incidentDescription);
        setIncidentNumber(response.incidentNumber);
        setWeatherConditions(response?.weatherConditions);
      });
    } else {
      setFirstName(props.statement.firstName);
      setLastName(props.statement.lastName);
      setContactInfo(props.statement.contactInfo);
      setIncidentDateTime(props.statement.incidentDateTime);
      setIncidentDetails(props.statement.incidentDetails);
      setDueDate(props.statement.dueDate);
      setActionDuringIncident(props.statement.actionDuringIncident);
      setWeatherConditions(props.statement.weatherConditions);
      setSignature(props.statement.signature);
      setIsDisabled(true);
    }
  }, [statementId, props.statement]);

  const renderWsContainer = () => {
    return (
      <>
        <Textbox
          currentValue={firstName}
          fieldLabel="First Name"
          onChange={e => {
            setFirstName(e);
            setHasChanges(true);
          }}
          disabled={true}
          isRequired
        />
        <Textbox
          currentValue={lastName}
          fieldLabel="Last Name"
          onChange={e => {
            setLastName(e);
            setHasChanges(true);
          }}
          disabled={true}
          isRequired
        />
        <Textbox
          currentValue={contactInfo}
          fieldLabel="Contact Information"
          onChange={e => {
            setContactInfo(e);
            setHasChanges(true);
          }}
          type="textarea"
          disabled={isDisabled}
          isRequired
        />
        <DatePicker
          pickTime
          fieldLabel="Date and time of the incident"
          isRequired
          onChange={value => {
            setIncidentDateTime(value);
            setHasChanges(true);
          }}
          currentValue={incidentDateTime}
          name="incidentDateTime"
          disabled={true}
        />

        <Textbox
          currentValue={incidentDetails}
          fieldLabel="Describe the incident in detail"
          onChange={e => {
            setIncidentDetails(e);
            setHasChanges(true);
          }}
          type="textarea"
          disabled={isDisabled}
          isRequired
        />
        <Textbox
          currentValue={actionDuringIncident}
          fieldLabel="What were you doing when the incident occurred?"
          onChange={e => {
            setActionDuringIncident(e);
            setHasChanges(true);
          }}
          type="textarea"
          disabled={isDisabled}
          isRequired
        />
        <Dropdown
          multi
          searchable
          selectButtons
          fieldLabel="Weather Conditions"
          options={WEATHER_CONDITIONS}
          currentValue={weatherConditions}
          alphabetize
          onChange={values => {
            console.log('values', values);
            setWeatherConditions(values);
            setHasChanges(true);
          }}
          bareValues={false}
          disabled={isDisabled}
        />
        <Signature
          currentValue={signature}
          onChange={signature => {
            setSignature(signature);
            setHasChanges(true);
          }}
          ownerType="witnessStatement"
          ownerId={statementId}
          disabled={isDisabled}
        />
      </>
    );
  };

  const handleUpdateWitnessStatement = (isCompleted = false) => {
    const body = {
      firstName,
      lastName,
      contactInfo,
      incidentDateTime,
      incidentDetails,
      actionDuringIncident,
      weatherConditions,
      signature,
      isCompleted,
      id: statementId,
      hasStarted: true
    };
    updateWitnessStatement(body).then(() => history.goBack());
  };

  const canMarkAsComplete =
    firstName &&
    lastName &&
    contactInfo &&
    incidentDateTime &&
    incidentDetails &&
    actionDuringIncident &&
    signature;

  let dueDateFormatted = dueDate
    ? ` ${moment(dueDate).format('MM/DD/YYYY hh:mm A')}`
    : ' No Due Date Assigned';

  const header = (
    <Header
      title={incidentNumber}
      section="Witness Statement"
      clickBack={() =>
        hasChanges ? setOpenUnsavedChangedModal(true) : history.goBack()
      }
      needsSaved={hasChanges}
    />
  );

  const footer = (
    <SubmitSaveForLaterFooter
      saveButtonClick={() => handleUpdateWitnessStatement(false)}
      saveButtonDisabled={!hasChanges}
      submitButtonDisabled={!canMarkAsComplete}
      submitButtonClick={() => setOpenCompleteModal(true)}
    />
  );

  return isDisabled ? (
    renderWsContainer()
  ) : (
    <HeaderAndFooter Header={header} Footer={footer} showFooter={true}>
      <Card>
        <div className={styles.incidentInfo}>
          <div className={styles.title}>Incident incidentDetails: </div>
          <div className={styles.info}> {incidentDescription}</div>
        </div>
        <div className={styles.incidentInfo}>
          <div className={styles.title}>Due Date: </div>
          <div className={styles.info}>{dueDateFormatted} </div>
        </div>

        {renderWsContainer()}
      </Card>
      <MarkItemAsCompleteModal
        isOpen={openCompleteModal}
        completingWhat="Witness Statement"
        onRequestClose={() => setOpenCompleteModal(false)}
        submitActions={() => handleUpdateWitnessStatement(true)}
        cancelActions={() => handleUpdateWitnessStatement(false)}
      />
      <SaveChangesModal
        isOpen={openUnsavedChangesModal}
        onRequestClose={() => setOpenUnsavedChangedModal(false)}
        submitActions={() => history.goBack()}
        savingWhat="a witness statement"
      />
    </HeaderAndFooter>
  );
}
