import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactLoading from 'react-loading';

import history from '../../history';
import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments,
  deleteAttachmentRequest
} from '../../actions/attachments';
import {
  addQualificationRequest,
  deleteQualificationAttachment,
  deleteQualificationRequest,
  fetchDocumentFoldersRequest,
  fetchMyDocumentsRequest,
  fetchQualificationTypeRequest,
  setActiveMyDocument,
  setActiveQualification,
  updateQualificationRequest
} from '../../actions/documents';
import {
  setActiveEmployeeResponse,
  updateLocationEmployeeRequest
} from '../../actions/personnel';
import {
  setActiveTrainingRequest,
  setActiveTrainingResponse
} from '../../actions/training';
import {
  getAddedAttachmentsSelector,
  getAttachmentLoadingSelector
} from '../../selectors/attachments';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject,
  getUserCompaniesSelector
} from '../../selectors/company';
import {
  getActiveFolderSelector,
  getActiveQualificationSelector,
  getDocumentFoldersSelector,
  getMyDocumentsSelector,
  getQualificationTypesSelector
} from '../../selectors/documents';
import {
  getActiveEmployeeSelector,
  getPersonnelLoadingSelector
} from '../../selectors/personnel';
import { getLoggedInUser } from '../../selectors/users';
import Button from '../../components/inputs/Button';
import EmployeeDocumentRepo from '../../components/EmployeeDocumentRepo';
import EmployeeForm from '../../forms/EmployeeForm';
import EmployeeSnapshot from '../../components/EmployeeSnapshot';
import IncidentsList from '../../components/IncidentsList';
import Modal from '../../components/Modal';
import QualificationList from '../../components/QualificationList';
import SafetyTrainingList from '../../components/training/SafetyTrainingList';
import UserForm from '../../forms/UserForm';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Card from '../../components/Card';
import List from '../../components/List';
import { deleteEmployee } from '../../api/v4';
import { loadSafetyWalk } from '../../actions/safetyWalks';

import './index.css';

export class ViewEditEmployee extends Component {
  state = {
    modalOpen: false,
    selected: 0
  };

  componentDidMount = () => {
    this.props.clearAttachment();
    this.props.fetchQualificationTypes();
    this.props.fetchMyDocuments({
      userId: this.props.activeEmployee?._id,
      fromEmployeeProfile: true
    });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.activeEmployee._id &&
      this.props.activeEmployee._id !== prevProps.activeEmployee._id
    ) {
      this.props.fetchMyDocuments({
        userId: this.props.activeEmployee?._id,
        fromEmployeeProfile: true
      });
    }
  }

  handleDeleteEmployee = () => {
    deleteEmployee(this.props.activeEmployee._id).then(() =>
      history.push('/app/settings/Employees')
    );
  };

  render() {
    const { selected, modalOpen } = this.state;

    const {
      activeEmployee,
      addAttachment,
      clearAttachment,
      setActiveQualifications,
      addQualification,
      activeQualification,
      updateQualification,
      qualificationTypes,
      activeCompany,
      addedAttachments,
      loggedInUser,
      deleteAttachment,
      fetchDocumentFolders,
      documentFolders,
      activeFolder,
      activeEmployeeDocs,
      userCompanies,
      personnelLoading,
      deleteQualification,
      deleteQualificationAttachment,
      setActiveMyDocument,
      setActiveEmployee,
      setActiveTemplate,
      setActiveTraining
    } = this.props;

    const incidentTabs = [
      'Open',
      'Awaiting Info',
      'Investigation',
      'Remediation',
      'Closed'
    ];

    const header = (
      <Header
        title="Employee Profile"
        clickBack={() => {
          setActiveEmployee();
          history.goBack();
        }}
      />
    );

    const tabs = {
      selected: this.state.selected,
      onClick: i => this.setState({ selected: i }),
      list: [
        { name: 'Snapshot' },
        { name: 'Personal Info' },
        { name: 'Trainings' },
        { name: 'Reports' },
        { name: 'Behavior Observation' },
        { name: 'Bravos' },
        { name: 'Qualifications' },
        { name: 'User Info', visible: loggedInUser.accessLevel === 900 },
        { name: 'Doc Repo' }
      ]
    };

    const bravoColumns = [
      {
        key: 'safetyWalkNumber',
        label: activeCompany.isDash ? 'DASH title' : 'SW Title'
      },
      {
        key: 'createdByUser',
        label: 'Reporter',
        datatype: 'user',
        accessor: r => r.createdBy?._id || r.createdBy
      },
      {
        key: 'createdAt',
        label: 'Created Date',
        datatype: 'date'
      }
    ];

    const observationColumns = [
      {
        label: 'Behavior Observations',
        key: 'title'
      },
      {
        label: 'Employee Observed',
        key: 'employeeObserved',
        datatype: 'user'
      },
      {
        label: 'Supervisor Observing',
        key: 'supervisorObserving',
        datatype: 'user'
      },
      { label: 'Last Updated', key: 'updatedAt', datatype: 'date' },
      {
        label: 'Completion Date',
        key: 'completionDate',
        datatype: 'date'
      }
    ];

    return (
      <HeaderAndFooter Header={header}>
        {personnelLoading ? (
          <ReactLoading
            type="spinningBubbles"
            color="#58a15b"
            className="loading"
          />
        ) : (
          <Card wide tabs={tabs} className="employeeSnapshot">
            {selected === 0 && (
              <EmployeeSnapshot
                activeEmployee={activeEmployee}
                userCompanies={userCompanies}
                accessLevel={loggedInUser.accessLevel}
                systemInteractions={activeEmployee.systemInteractions}
              />
            )}
            {selected === 1 && (
              <EmployeeForm
                onSubmit={this.props.updateLocationEmployee}
                onDelete={() => this.setState({ modalOpen: true })}
                initialValues={{
                  ...activeEmployee,
                  dateOfBirth:
                    activeEmployee.dateOfBirth !== ''
                      ? moment(Date.parse(activeEmployee.dateOfBirth)).format(
                          'MM/DD/YYYY'
                        )
                      : '',
                  dateOfHire: moment(
                    Date.parse(activeEmployee.dateOfHire)
                  ).format('MM/DD/YYYY'),
                  dateOfTermination:
                    activeEmployee.dateOfTermination !== ''
                      ? moment(
                          Date.parse(activeEmployee.dateOfTermination)
                        ).format('MM/DD/YYYY')
                      : ''
                }}
                isUpdate={true}
                userCompanies={userCompanies}
                isAccountOwner={activeEmployee.isAccountOwner}
                accessLevel={activeEmployee.accessLevel}
              />
            )}
            {selected === 2 && (
              <>
                <Button
                  className="createTrainingButton"
                  color="blue"
                  text="Create Training"
                  onClick={() => {
                    setActiveTraining({ isFromEmployee: true });
                    history.push('/app/training/trainingContainer');
                  }}
                />
                <SafetyTrainingList
                  trainings={activeEmployee.trainings?.documents || []}
                  fromEmployee
                />
              </>
            )}
            {selected === 3 && (
              <IncidentsList
                content={activeEmployee?.incidents ?? []}
                tabs={incidentTabs}
                saveKey={`incidents-${activeEmployee._id}`}
              />
            )}
            {selected === 4 && (
              <List
                settings={observationColumns}
                data={activeEmployee.observations}
                dataIsHash
                saveKey="behaviorObservations"
                rowClick={row =>
                  history.push(`/app/behaviorObservation/container/${row._id}`)
                }
              />
            )}
            {selected === 5 && (
              <List
                saveKey="employeeBravos"
                data={activeEmployee.bravos}
                dataIsHash
                settings={bravoColumns}
                rowClick={row => this.props.loadSafetyWalk(row._id)}
              />
            )}
            {selected === 6 && (
              <QualificationList
                content={activeEmployee.qualifications.documents}
                locationList={activeCompany.groups}
                hideGradeButton={true}
                setActiveQualification={setActiveQualifications}
                addQualification={addQualification}
                activeQualification={activeQualification}
                updateQualification={updateQualification}
                qualificationTypes={qualificationTypes}
                activeCompany={activeCompany}
                addAttachment={addAttachment}
                clearAttachment={clearAttachment}
                fetchDocumentFolders={fetchDocumentFolders}
                documentFolders={documentFolders}
                activeFolder={activeFolder}
                isOnEmployee={true}
                deleteAttachment={deleteAttachment}
                addedAttachments={addedAttachments}
                user={loggedInUser}
                isFromEmployee={true}
                deleteQualificationAttachment={deleteQualificationAttachment}
                deleteQualification={deleteQualification}
              />
            )}
            {selected === 7 && loggedInUser.accessLevel === 900 && (
              <UserForm
                user={activeEmployee}
                updateActiveUser={value =>
                  this.props.updateLocationEmployee({
                    ...activeEmployee,
                    ...value
                  })
                }
              />
            )}
            {selected === 8 && (
              <EmployeeDocumentRepo
                content={activeEmployeeDocs}
                setActiveMyDocument={setActiveMyDocument}
                setActiveTraining={setActiveTraining}
                employee={activeEmployee}
                setActiveTemplate={setActiveTemplate}
              />
            )}

            <Modal
              title={`Are you sure you want to delete ${activeEmployee.firstName} ${activeEmployee.lastName}?`}
              titleClassName="redHeader"
              isOpen={modalOpen}
              onRequestClose={() => this.setState({ modalOpen: false })}
              submitActions={this.handleDeleteEmployee}
              submitButtonColor="red"
              testID="deleteEmployee"
            />
          </Card>
        )}
      </HeaderAndFooter>
    );
  }
}

export const mapStateToProps = state => ({
  activeEmployee: getActiveEmployeeSelector(state),
  activeQualification: getActiveQualificationSelector(state),
  activeProject: getActiveProject(state),
  activeLocationId: getActiveLocationId(state),
  qualificationTypes: getQualificationTypesSelector(state),
  activeCompany: getActiveCompany(state),
  attachmentLoading: getAttachmentLoadingSelector(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  loggedInUser: getLoggedInUser(state),
  documentFolders: getDocumentFoldersSelector(state),
  activeFolder: getActiveFolderSelector(state),
  activeEmployeeDocs: getMyDocumentsSelector(state),
  userCompanies: getUserCompaniesSelector(state),
  personnelLoading: getPersonnelLoadingSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  addAttachment: attachment =>
    dispatch(addUploadedAttachmentsRequest(attachment)),
  clearAttachment: () => dispatch(clearUploadedAttachments()),
  updateLocationEmployee: employee =>
    dispatch(updateLocationEmployeeRequest(employee)),
  setActiveQualifications: values => dispatch(setActiveQualification(values)),
  addQualification: values => dispatch(addQualificationRequest(values)),
  updateQualification: value => dispatch(updateQualificationRequest(value)),
  fetchQualificationTypes: () => dispatch(fetchQualificationTypeRequest()),
  deleteAttachment: values => dispatch(deleteAttachmentRequest(values)),
  fetchDocumentFolders: values => dispatch(fetchDocumentFoldersRequest(values)),
  deleteQualificationAttachment: values =>
    dispatch(deleteQualificationAttachment(values)),
  deleteQualification: values => dispatch(deleteQualificationRequest(values)),
  setActiveMyDocument: values => dispatch(setActiveMyDocument(values)),
  fetchMyDocuments: values => dispatch(fetchMyDocumentsRequest(values)),
  setActiveEmployee: () =>
    dispatch(
      setActiveEmployeeResponse({
        safetyTrainings: { expiring3Months: [] },
        incidents: [],
        qualifications: { expiring3Months: [], upcomingQuals: [] }
      })
    ),
  setActiveTemplate: payload => dispatch(setActiveTrainingRequest(payload)),
  setActiveTraining: payload => dispatch(setActiveTrainingResponse(payload)),
  loadSafetyWalk: id => dispatch(loadSafetyWalk(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewEditEmployee);
