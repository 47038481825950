import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm, change } from 'redux-form';

import {
  COMBINED_EMPLOYEE_STATUS,
  DAY_OF_WEEK_DROPDOWN,
  GENDER_DROPDOWN
} from '../../constants/constants';
import { states } from '../../constants/states';
import {
  getSelectedCompanies,
  getSelectedGroups
} from '../../utils/employeeDGPHelper';
import {
  email,
  isValidDate,
  multiSelectRequired,
  normalizePhone,
  notFutureDate,
  renderDatePicker,
  renderDropdownField,
  renderField,
  renderMultiSelectField,
  renderRadioButtons,
  renderUserButtons,
  renderUsernameField,
  required,
  booleanRequired,
  normalizeSSN,
  commonName,
  validZip
} from '../../utils/formValidation';
import { InputRow, Button, TwoColumn } from '../../components/inputs';
import BlockText from '../../components/inputs/BlockText';

import '../form.css';

let EmployeeForm = props => {
  const {
    handleSubmit,
    onDelete,
    disabled,
    isUpdate,
    groupIds,
    companyIds,
    userCompanies,
    accessLevel,
    isAccountOwner,
    checkUsernameAvailability,
    userClassName
  } = props;

  const isAdmin = accessLevel === 900 && isUpdate;

  const getGroupDropdown = () => {
    const selectedCompanies = getSelectedCompanies(userCompanies, companyIds);

    let groupDropdown = [];

    if (selectedCompanies.length > 0) {
      selectedCompanies.forEach(company =>
        company.groups.forEach(group =>
          groupDropdown.push({
            value: group._id,
            label: `${company.name} - ${group.name}`
          })
        )
      );
    }
    if (isAdmin) {
      let groupIds = groupDropdown.map(g => g.value);
      props.dispatch(change('EmployeeForm', 'groupIds', groupIds));
    }
    return groupDropdown;
  };

  const getProjectDropdown = () => {
    if (!groupIds) {
      return [];
    }
    const selectedCompanies = getSelectedCompanies(userCompanies, companyIds);
    const selectedGroup = getSelectedGroups(selectedCompanies, groupIds);

    let projectsDropdown = [];
    selectedGroup.forEach(group => {
      group.projects.forEach(project => {
        projectsDropdown.push({
          value: project._id,
          label: `${group.name} - ${project.name}`
        });
      });
    });

    if (isAdmin) {
      let projectIds = projectsDropdown.map(g => g.value);
      props.dispatch(change('EmployeeForm', 'projectIds', projectIds));
    }

    return projectsDropdown;
  };

  const companiesDropdown = (userCompanies ?? []).map(company => ({
    value: company._id,
    label: company.name
  }));

  return (
    <form className="editEmployeeForm" onSubmit={handleSubmit}>
      {isUpdate ? (
        <InputRow right>
          {props.submitFailed ? (
            <p style={{ color: 'red' }}>Please validate all form information</p>
          ) : (
            <></>
          )}
          <Button text="Update" color="blue" type="submit" />
          {!isAccountOwner && (
            <Button text="Delete" color="red" onClick={onDelete} />
          )}
        </InputRow>
      ) : (
        <></>
      )}
      <TwoColumn>
        <div>
          <Field
            name="companyIds"
            component={renderMultiSelectField}
            type="select-multiple"
            label="Company Division:"
            options={companiesDropdown}
            disabled={disabled || isAccountOwner}
            validate={[multiSelectRequired]}
            requiredLabel={true}
            searchable
            selectButtons
          />
          <BlockText blockOfText="Admins are  given access to all Groups/Establishments and Areas within the selected companies. To limit access change the user type to Collaborator" />
          <Field
            name="groupIds"
            component={renderMultiSelectField}
            type="select-multiple"
            label="Group or Establishment:"
            validate={[multiSelectRequired]}
            requiredLabel={true}
            options={getGroupDropdown()}
            disabled={disabled || isAccountOwner || isAdmin}
            searchable
            selectButtons
          />
          <Field
            name="projectIds"
            component={renderMultiSelectField}
            label="Area:"
            options={getProjectDropdown()}
            type="select-multiple"
            disabled={disabled || isAccountOwner || isAdmin}
            searchable
            selectButtons
          />
          <Field
            name="employeeNumber"
            type="text"
            component={renderField}
            label="Employee Id:"
            placeholder={''}
            disabled={disabled}
            validate={[required]}
            requiredLabel
          />
          <Field
            name="firstName"
            type="text"
            component={renderField}
            label="First Name:"
            validate={[commonName, required]}
            placeholder={''}
            requiredLabel={true}
            disabled={disabled}
          />
          <Field
            name="middleName"
            type="text"
            component={renderField}
            label="Middle Name:"
            validate={commonName}
            disabled={disabled}
          />
          <Field
            name="lastName"
            type="text"
            component={renderField}
            label="Last Name:"
            validate={[commonName, required]}
            requiredLabel={true}
            disabled={disabled}
          />
          <Field
            name="gender"
            type="text"
            component={renderDropdownField}
            label="Gender:"
            placeholder={''}
            options={GENDER_DROPDOWN}
            disabled={disabled}
          />
          <Field
            name="dateOfBirth"
            type="text"
            component={renderDatePicker}
            label="Date of Birth:"
            placeholder={''}
            disabled={disabled}
            warn={notFutureDate}
          />
          <Field
            name="ssn"
            type="text"
            component={renderField}
            label="SSN:"
            disabled={disabled}
            normalize={normalizeSSN}
          />
          <Field
            name="dateOfHire"
            type="text"
            component={renderDatePicker}
            label="Date of Hire:"
            placeholder={''}
            disabled={disabled}
            validate={[isValidDate, required]}
            requiredLabel={true}
          />
        </div>
        <div>
          <Field
            name="jobTitle"
            type="text"
            component={renderField}
            label="Job Title:"
            validate={[required]}
            placeholder={''}
            requiredLabel={true}
            disabled={disabled}
          />
          <Field
            name="status"
            component={renderDropdownField}
            label="Employee Status:"
            placeholder={''}
            options={COMBINED_EMPLOYEE_STATUS}
            format={v => parseInt(v, 10)}
            disabled={disabled}
            validate={[required]}
            requiredLabel={true}
            searchable={true}
          />
          <Field
            name="trainingRequired"
            type="text"
            component={renderDropdownField}
            label="Training Required:"
            placeholder={''}
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' }
            ]}
            disabled={disabled}
          />
          <Field
            name="location"
            type="text"
            component={renderField}
            label="Location:"
            placeholder={''}
            disabled={disabled}
          />
          <Field
            name="department"
            type="text"
            component={renderField}
            label="Department:"
            placeholder={''}
            disabled={disabled}
          />
          <Field
            name="supervisor"
            type="text"
            component={renderField}
            label="Supervisor:"
            placeholder={''}
            disabled={disabled}
          />
          <Field
            name="workDays"
            component={renderMultiSelectField}
            type="select-multiple"
            label="Work Days:"
            placeholder={''}
            options={DAY_OF_WEEK_DROPDOWN}
            disabled={disabled}
          />
          <Field
            name="hoursPerWeek"
            type="text"
            component={renderField}
            label="Hours Per Week:"
            placeholder={''}
            disabled={disabled}
          />
          <Field
            name="dateOfTermination"
            type="text"
            component={renderDatePicker}
            label="Last Date of Employment:"
            placeholder={''}
            disabled={disabled}
          />
        </div>
      </TwoColumn>
      <h2>Contact Information</h2>
      <TwoColumn>
        <div>
          <Field
            name="address.streetAddress"
            type="text"
            component={renderField}
            label="Home Address:"
            placeholder={''}
            disabled={disabled}
          />
          <Field
            name="address.city"
            type="text"
            component={renderField}
            label="City:"
            placeholder={''}
            disabled={disabled}
          />
        </div>
        <div>
          <Field
            name="address.stateName"
            type="text"
            component={renderDropdownField}
            label="State:"
            placeholder={''}
            options={states}
            disabled={disabled}
            searchable={true}
          />
          <Field
            name="address.zip"
            type="text"
            component={renderField}
            label="Zip:"
            placeholder={''}
            disabled={disabled}
            validate={[validZip]}
          />
        </div>
      </TwoColumn>
      <h2>Emergency Contact Information</h2>
      <TwoColumn>
        <div>
          <Field
            name="emergencyContact.emergencyContact"
            type="text"
            component={renderField}
            label="Contact:"
            placeholder={''}
            disabled={disabled}
          />
          <Field
            name="emergencyContact.phoneNumber"
            type="text"
            component={renderField}
            label="Phone:"
            placeholder={''}
            normalize={normalizePhone}
            disabled={disabled}
          />
        </div>
        <div>
          <Field
            name="emergencyContact.relationship"
            type="text"
            component={renderField}
            label="Relationship:"
            placeholder={''}
            disabled={disabled}
          />
          <Field
            name="emergencyContact.email"
            type="text"
            component={renderField}
            label="Email:"
            placeholder={''}
            warn={[email]}
            disabled={disabled}
          />
        </div>
      </TwoColumn>
      {!isUpdate && accessLevel === 900 ? (
        <div>
          <h2>User Information</h2>
          <Field
            name="username"
            type="text"
            component={renderUsernameField}
            label="Username: (if textbox turns red, username is not available)"
            validate={[required]}
            placeholder="Enter a username"
            required={true}
            requiredLabel={true}
            onChange={checkUsernameAvailability}
            className={userClassName}
          />
          <h3>
            In order to receive notifications about new Reports, Tasks, and Risk
            Assessments you need to provide a valid phone number, email, or
            both.
          </h3>
          <TwoColumn>
            <div>
              <Field
                name="phoneNumber"
                type="text"
                component={renderField}
                label="Phone:"
                placeholder={''}
                normalize={normalizePhone}
                disabled={disabled}
              />
            </div>
            <div>
              <Field
                name="email"
                type="text"
                component={renderField}
                label="Email:"
                placeholder={''}
                warn={[email]}
                disabled={disabled}
              />
            </div>
          </TwoColumn>
          <Field
            name="isMicrosoftUser"
            options={[
              { value: true, label: 'Microsoft Login' },
              { value: false, label: 'N/A' }
            ]}
            component={renderRadioButtons}
            label="Using Microsoft login? NOTE: Must have first set up your company's Azure Active Directory with iReportSource. If unsure, chose N/A."
            validate={[required]}
            required={true}
            requiredLabel={true}
          />
          <Field
            name="isDeactivated"
            options={[
              { value: false, label: 'Activated User' },
              { value: true, label: 'Deactivated User (cannot login)' }
            ]}
            component={renderRadioButtons}
            label="Should this user be activated? (activated users will receive a welcome email once they are created, giving them instructions on how to log in to iReport. Deactivated users can be activated later on.)"
            validate={[booleanRequired]}
            required={true}
            requiredLabel={true}
          />
          <Field
            name="accessLevel"
            component={renderUserButtons}
            label="User Type:"
            placeholder={''}
            disabled={disabled}
            validate={[required]}
            required={true}
            requiredLabel={true}
          />
        </div>
      ) : (
        ''
      )}
    </form>
  );
};

EmployeeForm = reduxForm({
  form: 'EmployeeForm',
  enableReinitialize: true
})(EmployeeForm);

const selector = formValueSelector('EmployeeForm');

const mapStateToProps = state => ({
  groupIds: selector(state, 'groupIds'),
  companyIds: selector(state, 'companyIds')
});

export default connect(mapStateToProps)(EmployeeForm);
