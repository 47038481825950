import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactLoading from 'react-loading';
import { PRIORITY_DROPDOWN } from '../../constants/constants';
import priorityMapper from '../../utils/priorityMapper';

import './index.css';

const SafetyInstructionCard = ({
  safetyInstruction,
  onClick,
  safetyInstructionLoading
}) => (
  <div>
    {safetyInstructionLoading ? (
      <div className="incidentCard">
        <ReactLoading
          type="spinningBubbles"
          color="#58a15b"
          className="loading"
        />
      </div>
    ) : (
      <div className="searchCard">
        <Link
          to={{
            pathname: '/app/viewEdit/Safety Procedure'
          }}
          onClick={() => onClick(safetyInstruction)}
        >
          {moment().isAfter(safetyInstruction.expires, 'day') ? (
            <div className="safetyInstructionCard-cardHeader--overdue">
              <div className="safetyInstructionCard-cardHeader--overDueText">
                Expired
              </div>
            </div>
          ) : (
            <div className="safetyInstructionCard-cardHeader--due">
              <div className="safetyInstructionCard-cardHeader--dueText">
                Valid
              </div>
            </div>
          )}
          <div
            className={
              moment().isAfter(safetyInstruction.expires, 'day')
                ? 'safetyInstructionCard pastDue'
                : 'safetyInstructionCard'
            }
          >
            <div className="safetyInstructionCard-card">
              <div className="safetyInstructionCard-leftSide">
                <div className="safetyInstructionCard-cardMargin">
                  <div className="safetyInstructionCard-cardTypes">Title: </div>
                  {safetyInstruction.label}
                </div>
                <div className="safetyInstructionCard-cardMargin">
                  <div className="safetyInstructionCard-cardTypes">
                    Description:{' '}
                  </div>
                  {safetyInstruction.documentData?.description ?? ''}
                </div>
                <div className="safetyInstructionCard-cardMargin">
                  <div className="safetyInstructionCard-cardTypes">
                    Expires:{' '}
                  </div>
                  {safetyInstruction.expires
                    ? moment(safetyInstruction.expires).format('MM/DD/YYYY')
                    : 'Not Specified'}
                </div>
                <div className="safetyInstructionCard-cardMargin">
                  <div className="safetyInstructionCard-cardTypes">
                    Priority:{' '}
                  </div>
                  {
                    PRIORITY_DROPDOWN[
                      priorityMapper(
                        safetyInstruction.documentData?.currentPriority ?? ''
                      ) - 1
                    ].label
                  }
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    )}
  </div>
);

export default SafetyInstructionCard;
