import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import history from '../../history';

import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments
} from '../../actions/attachments';
import {
  deleteCompanyRequest,
  updateCompanyRequest
} from '../../actions/company';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import { getActiveCompany } from '../../selectors/company';
import CompanyForm from '../../forms/CompanyForm';
import Card from '../../components/Card';
import { InputRow } from '../../components/inputs';
import Button from '../../components/inputs/Button';

export class ViewEditCompany extends Component {
  state = {
    redirect: false
  };

  render() {
    const {
      activeCompany,
      updateCompany,
      deleteCompany,
      addedAttachments,
      addAttachment,
      removeAttachment,
      submitForm
    } = this.props;

    return (
      <Card
        title="Edit Company"
        showHeader
        wide
        rightButton={
          <InputRow right>
            <Button color="blue" text="Update" onClick={() => submitForm()} />
            <Button
              color="white"
              text="Cancel"
              onClick={() => history.push('/app/settings/CompanyDetails')}
            />
          </InputRow>
        }
      >
        <CompanyForm
          onSubmit={values =>
            updateCompany(values) &&
            history.push('/app/settings/CompanyDetails')
          }
          initialValues={{ ...activeCompany }}
          activeCompany={activeCompany}
          onDelete={() => deleteCompany(activeCompany._id)}
          isUpdate={true}
          addedAttachments={addedAttachments}
          addAttachment={addAttachment}
          removeAttachment={removeAttachment}
        />
      </Card>
    );
  }
}

export const mapStateToProps = state => ({
  activeCompany: getActiveCompany(state),
  addedAttachments: getAddedAttachmentsSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  updateCompany: company => dispatch(updateCompanyRequest(company)),
  deleteCompany: companyId => dispatch(deleteCompanyRequest(companyId)),
  addAttachment: values => dispatch(addUploadedAttachmentsRequest(values)),
  removeAttachment: () => dispatch(clearUploadedAttachments()),
  submitForm: () => dispatch(submit('CompanyForm'))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewEditCompany);
