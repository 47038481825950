import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';
import { Field, reduxForm } from 'redux-form';

import { renderField } from '../../utils/formValidation';
import Button from '../../components/inputs/Button';
import '../../components/inputs/input.css';
import '../../components/inputs/Textbox/index.css';
import { Checkbox } from '../../components/inputs';
import '../form.css';

import styles from './loginForm.module.scss';

const LoginForm = props => {
  const {
    handleSubmit,
    isLoading,
    setLoginPage,
    onMicrosoftSubmit,
    rememberUser,
    handleChangeCheckbox,
    badUsername,
    badPassword,
    change
  } = props;

  useEffect(() => {
    if (localStorage.getItem('username')) {
      change('username', localStorage.getItem('username'));
    }
  }, [change]);

  return (
    <form onSubmit={handleSubmit}>
      <h2>Log In</h2>
      <Field
        name="username"
        className={badUsername && styles.badEntry}
        type="text"
        component={renderField}
        label="Username"
        placeholder=""
      />
      <Field
        name="password"
        className={badPassword && styles.badEntry}
        type="password"
        component={renderField}
        label="Password"
        placeholder=""
      />
      {/* eslint-disable-next-line */}
      <a href="#" onClick={() => setLoginPage('forgotPassword')}>
        Forgot password?
      </a>
      <Checkbox
        fieldLabel="Remember Me"
        onChange={handleChangeCheckbox}
        currentValue={rememberUser}
      />
      <Button
        type="submit"
        color="green"
        text={
          isLoading ? (
            <ReactLoading
              type="spinningBubbles"
              color="#FFF"
              className="loading"
              height="45px"
              width="45px"
            />
          ) : (
            'Login'
          )
        }
      />
      <Button
        color="white"
        image={require('../../assets/images/microsoft logo.png')}
        text="Sign in with Microsoft"
        className={styles.microsoftLoginButton}
        onClick={onMicrosoftSubmit}
      />
    </form>
  );
};

export default reduxForm({
  form: 'LoginForm'
})(LoginForm);
