import React, { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import CheckboxGroup from '../../inputs/CheckboxGroup';
import RadioButtons from '../../inputs/RadioButtons';
import Card from '../../../components/Card';

import quizCardStyles from './styles.module.scss';

export default function QuizCard(props) {
  const [quiz, setQuiz] = useState([]);
  const [question, setQuestion] = useState();
  useEffect(() => {
    setQuiz(props.quiz);
    setQuestion(props?.question);
  }, [props, props.quiz]);

  const handleQuestionAnswer = answer => {
    setQuestion({ ...question, assigneeSelectedAnswers: answer });
    props.handleQuizAnswers({ ...question, assigneeSelectedAnswers: answer });
  };

  const answerOptions = props?.question?.answerOptions;
  const assigneeSelectedAnswers = props?.question?.assigneeSelectedAnswers;
  const answers = answerOptions?.map((answerOption, answerIndex) => {
    return {
      value: answerOption._id,
      label: answerOption.answer
    };
  });
  const correctAnswers = answerOptions
    ?.filter(answer => answer.isCorrectAnswer)
    .map(answerOption => answerOption.answer);
  const hasCorrectAnswers = isEqual(
    correctAnswers,
    assigneeSelectedAnswers.map(selected => selected.label)
  );

  const getImage = hasCorrectAnswers
    ? require('../../../assets/images/Check.png')
    : require('../../../assets/images/Remove Icon.png');

  const subHeaderText =
    props?.question?.correctAnswers?.length === 1
      ? `${props?.questionNumber + 1}. ${props?.question?.label}`
      : `${props?.questionNumber + 1}. ${
          props?.question?.label
        } (Mark All Potential Answers)`;

  const subHeader = (
    <div className={quizCardStyles.subHeader}>
      {quiz.isCompleted && !props.isRetaking && (
        <div className={quizCardStyles.imageDiv}>
          <img
            src={getImage}
            alt={hasCorrectAnswers ? 'you got it right' : 'you got it wrong'}
          />
        </div>
      )}
      <h3 className={quizCardStyles.subHeaderText}> {subHeaderText} </h3>
    </div>
  );

  return (
    <>
      <Card showSubHeader subHeader={subHeader} border name={props.name}>
        {correctAnswers?.length === 1 ? (
          <RadioButtons
            options={answers}
            onChange={values => handleQuestionAnswer([values])}
            currentValue={assigneeSelectedAnswers[0]}
            disabled={
              (quiz.isCompleted && !props.isRetaking) || props.isFromSummary
            }
            bareValues={false}
            needSeparateLines
          />
        ) : (
          <CheckboxGroup
            options={answers}
            onChange={values => handleQuestionAnswer(values)}
            bareValues={false}
            currentValue={assigneeSelectedAnswers}
            disabled={
              (quiz.isCompleted && !props.isRetaking) || props.isFromSummary
            }
            labelStyles={quizCardStyles.label}
          />
        )}
        {quiz.isCompleted && !hasCorrectAnswers && !props.isRetaking && (
          <div className={quizCardStyles.correctAnswers}>
            <h4>Correct Answers</h4>
            {correctAnswers.join(', ')}
          </div>
        )}
      </Card>
    </>
  );
}
