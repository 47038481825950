import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from '../../history';
import {
  fetchRequireAuthRequest,
  setActiveMyDocument
} from '../../actions/documents';
import { getRequireAuthDocsSelector } from '../../selectors/documents';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import List from '../../components/List';

class RequireAuthorizationList extends Component {
  componentDidMount() {
    this.props.fetchMyDocuments();
  }

  handleView = original => {
    if (original.title) {
      if (original.isPerform) {
        history.push(`/app/training/performTraining/${original._id}`);
      } else {
        history.push(`/app/training/approveTraining/${original._id}`);
      }
    } else {
      this.props.setActiveMyDocument({ ...original });

      if (
        original.label ===
        'Recurrence, Relapse During Recovery & Continuing Treatment'
      ) {
        history.push('/app/recurrenceContainer');
      } else {
        history.push('/app/transitionContainer');
      }
    }
  };

  render() {
    const { myDocs } = this.props;

    const columns = [
      { key: 'label', label: 'Title' },
      {
        key: 'employeeName',
        label: 'Employee',
        accessor: r => r.formData.employeeName
      },
      { key: 'createdAt', label: 'Created Date', datatype: 'date' },
      { key: 'dueDate', datatype: 'futureDate' }
    ];

    const header = <Header title="Require Acknowledgment" />;
    return (
      <HeaderAndFooter Header={header}>
        <List
          saveKey="authList"
          data={myDocs}
          dataIsHash
          settings={columns}
          rowClick={this.handleView}
        />
      </HeaderAndFooter>
    );
  }
}

const mapStateToProps = state => ({
  myDocs: getRequireAuthDocsSelector(state)
});

const mapDispatchToProps = dispatch => ({
  fetchMyDocuments: () => dispatch(fetchRequireAuthRequest()),
  setActiveMyDocument: paylaod => dispatch(setActiveMyDocument(paylaod))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequireAuthorizationList);
