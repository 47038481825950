import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import {
  createContinuousRequest,
  deleteContinuousRequest,
  setActiveMyDocument,
  updateContinuousRequest
} from '../../actions/documents';
import { fetchContinuousCareById, printContinuousCare } from '../../api/v4';
import { getActiveCompany } from '../../selectors/company';
import { getActiveMyDocumentSelector } from '../../selectors/documents';
import { getLoggedInUser } from '../../selectors/users';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import { SaveCancelFooter } from '../../components/Footer';
import { EmployeeDropdown } from '../../components/inputs/Dropdown';
import Modal from '../../components/Modal';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import UserBadge from '../../components/UserBadge';
import TransitionalInfoCard from '../../components/transitionalCards/TransitionalInfoCard';
import SignatureCard from '../../components/SignatureCard';
import LeftNav from '../../components/LeftNav';

class TransitionContainer extends Component {
  state = {
    isCreating: false,
    hasUnsavedChanges: false,
    isEditing: false,
    label: 'Transitional Return to Work Program',
    openDeleteDocumentModal: false,
    openDiscardDocumentModal: false,
    openUnsavedChangesModal: false,
    employeeName: '',
    workRestrictions: '',
    dateOfTransition: '',
    endDateOfTransition: '',
    supervisorId: '',
    hrRepId: '',
    isSupervisor: false,
    isHR: false,
    isApprovedByHr: false,
    isApprovedBySupervisor: false,
    downloading: false,
    openReassignModal: false,
    reassignUserId: undefined,
    openAuthorizationModal: false,
    employeeSignature: undefined,
    supervisorSignature: undefined,
    hrSignature: undefined
  };

  transitionId = this.props?.match?.params?.transitionId;

  componentDidMount() {
    if (!this.transitionId) {
      this.setState({ isCreating: true });
    } else {
      fetchContinuousCareById(this.transitionId).then(response => {
        this.setState({
          employeeName: response.formData.employeeName,
          workRestrictions: response.formData.workRestrictions,
          dateOfTransition: response.formData.dateOfTransition,
          endDateOfTransition: response.formData.endDateOfTransition,
          supervisorId: response.supervisorId,
          hrRepId: response.hrRepId,
          isSupervisor: this.props.loggedInUser._id === response.supervisorId,
          isHR: this.props.loggedInUser._id === response.hrRepId,
          employeeSignature: response.formData.employeeSignature,
          supervisorSignature: response.formData.supervisorSignature,
          hrSignature: response.formData.hrSignature,
          isApprovedByHr: response.isApprovedByHr,
          isApprovedBySupervisor: response.isApprovedBySupervisor
        });
      });
    }
  }

  handleChange = values => {
    this.setState({ ...values, hasUnsavedChanges: true });
  };

  canSubmit = () => {
    const {
      employeeName,
      workRestrictions,
      dateOfTransition,
      supervisorId,
      isSupervisor,
      isHR,
      hrRepId
    } = this.state;

    if (
      employeeName &&
      workRestrictions &&
      dateOfTransition &&
      this.state.employeeSignature &&
      supervisorId &&
      hrRepId &&
      !isSupervisor &&
      !isHR
    ) {
      return this.state.hasUnsavedChanges;
    }
    if (isSupervisor && this.state.supervisorSignature) {
      if (isHR && !this.state.hrSignature) {
        return false;
      }
      return this.state.hasUnsavedChanges;
    }
    if (isHR && this.state.hrSignature) {
      return this.state.hasUnsavedChanges;
    }

    return false;
  };

  handleSubmit = () => {
    const {
      isHR,
      isSupervisor,
      isApprovedByHr,
      isApprovedBySupervisor
    } = this.state;
    if (this.transitionId) {
      if (
        (!isHR && !isSupervisor) ||
        (isSupervisor && isApprovedBySupervisor) ||
        (isHR && isApprovedByHr)
      ) {
        this.props.updateContinuous({
          ...this.state,
          _id: this.transitionId
        });
      }
      if (isSupervisor && !this.state.isApprovedBySupervisor) {
        this.props.updateContinuous({
          ...this.state,
          _id: this.transitionId,
          supervisorSignature: this.state.supervisorSignature
        });
      }
      if (isHR && !this.state.isApprovedByHr) {
        this.props.updateContinuous({
          ...this.state,
          _id: this.transitionId,
          hrSignature: this.state.hrSignature
        });
      }
    } else {
      this.props.createContinuous({
        ...this.state,
        employeeSignature: this.state.employeeSignature
      });
    }
  };

  printForm = async () => {
    this.setState({ downloading: true });
    const url = await printContinuousCare(this.props.activeMyDoc._id);
    window.open(url, '_blank');
    this.setState({ downloading: false });
  };

  handleDeleteDocument() {
    this.props.deleteContinuous(this.props.activeMyDoc);
  }

  handleReassignUser = () => {
    const { isHR, reassignUserId } = this.state;

    if (isHR) {
      this.props.updateContinuous({
        ...this.state,
        _id: this.transitionId,
        hrRepId: reassignUserId
      });
    } else {
      this.props.updateContinuous({
        ...this.state,
        _id: this.transitionId,
        supervisorId: reassignUserId
      });
    }
  };

  handleNavigateAway = () => {
    history.goBack();
  };

  render() {
    const {
      isCreating,
      hasUnsavedChanges,
      isEditing,
      openDeleteDocumentModal,
      openDiscardDocumentModal,
      openUnsavedChangesModal,
      employeeName,
      workRestrictions,
      dateOfTransition,
      endDateOfTransition,
      supervisorId,
      hrRepId,
      isSupervisor,
      isHR,
      isApprovedByHr,
      isApprovedBySupervisor,
      downloading,
      openReassignModal,
      reassignUserId,
      openAuthorizationModal
    } = this.state;

    const leftNav = [
      { label: 'Transitional Return to Work Program' },
      { label: 'Employee Signature' },
      { label: 'Supervisor Approval' },
      { label: 'HR Approval' }
    ];

    const footer = (
      <SaveCancelFooter
        editing={!isCreating}
        saveButtonDisabled={!this.canSubmit()}
        saveButtonClick={() =>
          (isSupervisor && !isApprovedBySupervisor) || (isHR && !isApprovedByHr)
            ? this.setState({ openAuthorizationModal: true })
            : this.handleSubmit()
        }
        cancelButtonClick={() =>
          isCreating
            ? this.setState({ openDiscardDocumentModal: true })
            : isEditing && hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : history.goBack()
        }
      />
    );

    const accessLevel = this.props.loggedInUser.accessLevel;
    const header = (
      <Header
        title="My Documents"
        section={
          !isEditing && !isCreating
            ? 'View Document'
            : isCreating
            ? 'Create Document'
            : 'Edit Document'
        }
        needsSaved={hasUnsavedChanges}
        clickBack={() =>
          hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : history.goBack()
        }
        rightButtons={[
          {
            visible:
              (!isCreating &&
                !isSupervisor &&
                !isApprovedBySupervisor &&
                !isHR &&
                !isApprovedByHr) ||
              accessLevel >= 500,
            text: isEditing ? 'Delete Document' : 'Edit Document',
            color: isEditing ? 'red' : 'blue',
            onClick: () =>
              isEditing
                ? this.setState({ openDeleteDocumentModal: true })
                : this.setState({ isEditing: true })
          },
          {
            visible: isSupervisor && !isApprovedBySupervisor,
            text: 'Change Supervisor',
            color: 'red',
            onClick: () => this.setState({ openReassignModal: true })
          },
          {
            visible: isHR && !isApprovedByHr,
            text: 'Change HR Rep',
            color: 'red',
            onClick: () => this.setState({ openReassignModal: true })
          },
          {
            visible: isApprovedBySupervisor && isApprovedByHr,
            loading: downloading,
            text: 'Print Form',
            color: 'blue',
            onClick: this.printForm
          }
        ]}
      />
    );

    const left = <LeftNav items={leftNav} />;

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          showFooter={isEditing || isCreating || isSupervisor || isHR}
          Left={left}
        >
          <TransitionalInfoCard
            employeeName={employeeName}
            workRestrictions={workRestrictions}
            dateOfTransition={dateOfTransition}
            endDateOfTransition={endDateOfTransition}
            onChange={this.handleChange}
            disabled={!isCreating && !isEditing}
            name={0}
          />
          <SignatureCard
            wide
            header="Employee Signature"
            currentValue={this.state.employeeSignature}
            onChange={employeeSignature =>
              this.handleChange({ employeeSignature })
            }
            ownerType="continuousCare"
            ownerId={this.transitionId}
            name={1}
            disabled={!isCreating}
          />
          <SignatureCard
            wide
            header="Supervisor Approval"
            dropdownName="Select your Supervisor"
            permissions={[400, 500, 900]}
            onUserChange={values => this.handleChange({ supervisorId: values })}
            userDisabled={isApprovedBySupervisor || (!isEditing && !isCreating)}
            userId={supervisorId}
            disabled={!isSupervisor || isApprovedBySupervisor}
            currentValue={this.state.supervisorSignature}
            onChange={supervisorSignature =>
              this.handleChange({ supervisorSignature })
            }
            ownerType="continuousCare"
            ownerId={this.transitionId}
            name={2}
          />
          <SignatureCard
            wide
            header="HR Approval"
            dropdownName="Select your HR Representative"
            permissions={[400, 500, 900]}
            onUserChange={values => this.handleChange({ hrRepId: values })}
            userDisabled={isApprovedByHr || (!isEditing && !isCreating)}
            userId={hrRepId}
            disabled={!isHR || isApprovedByHr}
            currentValue={this.state.hrSignature}
            onChange={hrSignature => this.handleChange({ hrSignature })}
            ownerType="continuousCare"
            ownerId={this.transitionId}
            name={3}
          />
        </HeaderAndFooter>
        <Modal
          title="Delete Document"
          titleClassName="redHeader"
          isOpen={openDeleteDocumentModal}
          submitButtonColor="red"
          submitButtonText="Delete Document"
          onRequestClose={() =>
            this.setState({ openDeleteDocumentModal: false })
          }
          submitActions={() => this.handleDeleteDocument()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Deleting the Document will delete it for you as well as anyone who
              may be associated with it.
            </span>
          </div>
        </Modal>
        <Modal
          title="Discard Document"
          titleClassName="redHeader"
          isOpen={openDiscardDocumentModal}
          submitButtonColor="red"
          submitButtonText="Discard Document"
          onRequestClose={() =>
            this.setState({ openDiscardDocumentModal: false })
          }
          submitActions={() => this.handleNavigateAway()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Discarding the Document will delete all information that has been
              gathered and pictures taken.
            </span>
          </div>
        </Modal>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() => this.handleNavigateAway()}
        />
        <Modal
          title={isHR ? 'Change HR' : 'Change Supervisor'}
          titleClassName="redHeader"
          isOpen={openReassignModal}
          submitButtonColor="red"
          submitButtonText="Save Changes"
          onRequestClose={() => this.setState({ openReassignModal: false })}
          submitActions={() => this.handleReassignUser()}
          disableSubmit={!reassignUserId}
        >
          {isHR ? (
            <div className="reportSectionContainer-emailAssigneeText">
              From here you can assign a different user as the HR Representative
              for this Document.
              <span style={{ color: '#c74846', fontWeight: 'bold' }}>
                This is to be used only if you are not the person meant to
                approve this document (as an HR Representative). You will be
                removed from this document.
              </span>
            </div>
          ) : (
            <div className="reportSectionContainer-emailAssigneeText">
              From here you can assign a different user as the Supervisor for
              this Document. .{' '}
              <span style={{ color: '#c74846', fontWeight: 'bold' }}>
                This is to be used only if you are not the person meant to
                approve this document (as a Supervisor). You will be removed
                from this document.
              </span>
            </div>
          )}
          <div className="incidentContainer-reassignIncident">
            <UserBadge
              userId={isHR ? hrRepId : supervisorId}
              showImage
              size="large"
              hoverName
            />
            <img
              src={require('../../assets/images/reassignArrow.png')}
              className="reassignArrow"
              alt="assign arrow"
            />
            <UserBadge
              userId={reassignUserId}
              showImage
              size="large"
              hoverName
            />
          </div>
          <EmployeeDropdown
            permissions={[400, 500, 900]}
            currentValue={reassignUserId}
            onChange={value => this.setState({ reassignUserId: value })}
            placeholder="Select one..."
          />
        </Modal>
        <Modal
          title="Authorization Notice"
          titleClassName="blueHeader"
          isOpen={openAuthorizationModal}
          submitButtonColor="blue"
          submitButtonText="Save Changes"
          onRequestClose={() =>
            this.setState({ openAuthorizationModal: false })
          }
          submitActions={() => this.handleSubmit()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Upon saving, this form will be removed from your To-Do list.
            </span>
            If you need to see it later, it can be found in the Doc Repo section
            of the Employee Profile.
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeCompany: getActiveCompany(state),
  activeMyDoc: getActiveMyDocumentSelector(state),
  loggedInUser: getLoggedInUser(state)
});

const mapDispatchToProps = dispatch => ({
  setActiveMyDocument: payload => dispatch(setActiveMyDocument(payload)),
  createContinuous: payload => dispatch(createContinuousRequest(payload)),
  updateContinuous: payload => dispatch(updateContinuousRequest(payload)),
  deleteContinuous: payload => dispatch(deleteContinuousRequest(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransitionContainer);
