import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Image, Layer, Stage } from 'react-konva';

import { Input, InputRow, nameInput } from '../';
import Button from '../Button';
import { deleteAttachment, uploadAttachment } from '../../../api';
import { getActiveCompany } from '../../../selectors/company';

import styles from './signature.module.scss';

class Signature extends Component {
  state = {
    isDrawing: false,
    points: [],
    mode: 'brush',
    brushType: 'freeLine',
    lineWidth: 2,
    brushColor: '#000',
    width: 700,
    height: 200
  };

  componentDidMount = () => {
    const canvas = document.createElement('canvas');
    canvas.width = this.state.width;
    canvas.height = this.state.height;

    var context = canvas.getContext('2d');
    context.lineJoin = 'round';
    context.lineWidth = this.state.lineWidth;

    this.setState({ canvas });
  };

  handleMouseDown = () => {
    this.setState({ isDrawing: true });

    const { brushColor, brushType } = this.state;

    const stage = this.image.parent.parent;
    this.lastPointerPosition = stage.getPointerPosition();
    this.updatePoints({
      x: this.lastPointerPosition.x,
      y: this.lastPointerPosition.y,
      size: this.state.lineWidth,
      color: brushColor,
      mode: 'begin',
      movement: brushType
    });
  };

  handleMouseUp = () => {
    const { brushType, brushColor, isDrawing } = this.state;

    if (!isDrawing) return;

    const stage = this.image.parent.parent;
    this.lastPointerPosition = stage.getPointerPosition();
    this.setState({ isDrawing: false });

    this.updatePoints({
      x: this.lastPointerPosition.x,
      y: this.lastPointerPosition.y,
      size: this.state.lineWidth,
      color: brushColor,
      mode: 'end',
      movement: brushType
    });
  };

  handleMouseMove = () => {
    const { isDrawing, canvas, brushColor, brushType } = this.state;

    const context = canvas.getContext('2d');

    if (isDrawing) {
      context.strokeStyle = brushColor;
      context.globalCompositeOperation = 'source-over';
      context.beginPath();

      var localPos = {
        x: this.lastPointerPosition.x - this.image.x(),
        y: this.lastPointerPosition.y - this.image.y()
      };
      context.moveTo(localPos.x, localPos.y);

      const stage = this.image.parent.parent;

      var pos = stage.getPointerPosition();
      localPos = {
        x: pos.x - this.image.x(),
        y: pos.y - this.image.y()
      };
      context.lineTo(localPos.x, localPos.y);
      context.closePath();
      context.stroke();
      this.lastPointerPosition = pos;

      this.updatePoints({
        x: this.lastPointerPosition.x,
        y: this.lastPointerPosition.y,
        mode: 'brush',
        size: this.state.lineWidth,
        color: brushColor,
        movement: brushType
      });

      this.image.getLayer().draw();
    }
  };

  clearStage = () => {
    const canvas = document.createElement('canvas');
    canvas.width = this.state.width;
    canvas.height = this.state.height;

    var context = canvas.getContext('2d');
    context.lineJoin = 'round';
    context.lineWidth = this.state.lineWidth;

    this.setState({ canvas, points: [] });
  };

  handleSave() {
    const dataUrl = this.image.parent.parent.toDataURL();

    var binary = atob(dataUrl.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    const file = new Blob([new Uint8Array(array)], { type: 'image/png' });

    let data = new FormData();

    this.props.questionId
      ? data.append(
          'attachments',
          file,
          `signature_${this.props.questionId}.png`
        )
      : data.append('attachments', file, 'signature.png');

    uploadAttachment(data, {
      ownerId: this.props.ownerId || this.props.activeCompany._id,
      ownerType: this.props.ownerType || 'pending',
      isSignature: true
    }).then(attachment => {
      if (Array.isArray(attachment)) attachment = attachment[0];

      this.props.onChange && this.props.onChange(attachment);
      this.clearStage();
    });
  }

  handleDelete = () => {
    let id = this.props.currentValue?._id;
    if (id) {
      deleteAttachment(id)
        .then(() => {
          this.props.onChange && this.props.onChange(null);
        })
        .catch(e => {
          throw e;
        });
    } else {
      this.props.onChange && this.props.onChange(undefined);
    }
  };

  updatePoints = values => {
    const oldPoints = this.state.points;
    oldPoints.push(values);
    this.setState({ points: oldPoints });
  };

  renderSignaturePad(disabled) {
    const { canvas } = this.state;

    return (
      <>
        <Stage width={this.state.width} height={this.state.height}>
          <Layer offsetX={0}>
            <Image
              image={canvas}
              ref={node => (this.image = node)}
              onMouseDown={this.handleMouseDown}
              onMouseUp={this.handleMouseUp}
              onMouseMove={this.handleMouseMove}
              onMouseOut={this.handleMouseUp}
              onTouchStart={this.handleMouseDown}
              onTouchMove={this.handleMouseMove}
              onTouchEnd={this.handleMouseUp}
            />
          </Layer>
        </Stage>
      </>
    );
  }

  render() {
    const {
      currentValue,
      className,
      disabled,
      ownerId,
      ownerType,
      onChange,
      ...props
    } = this.props;

    let url = currentValue?.source_url || currentValue;

    return (
      <Input
        className={[className, styles.container]}
        disabled={disabled}
        {...props}
      >
        {!url && this.renderSignaturePad(disabled)}
        {url && (
          <img
            src={url}
            style={{ width: 900, height: 150, objectFit: 'contain' }}
            alt="signature"
          />
        )}
        {!disabled && (
          <InputRow className={styles.footer}>
            <Button
              onClick={url ? this.handleDelete : this.clearStage}
              text="Clear Signature"
              color="red"
            />
            {!url && (
              <Button
                onClick={this.handleSave.bind(this)}
                text="Save Signature"
                color="blue"
                disabled={disabled || !this.state.points.length}
              />
            )}
          </InputRow>
        )}
      </Input>
    );
  }
}

const mapStateToProps = state => ({
  activeCompany: getActiveCompany(state)
});

export default nameInput(connect(mapStateToProps)(Signature));
