import React, { Component } from 'react';
import moment from 'moment';

import { getPercentComplete, hasAccess } from '../../../utils/incidentHelper';
import Button from '../../inputs/Button';
import UserBadge from '../../UserBadge';
import HoverImage from '../../HoverImage';

import './index.css';

const isPastDue = dueDate => {
  return moment().diff(Date.parse(dueDate), 'days') > 0;
};

export default class ReportComponentCard extends Component {
  render() {
    const {
      reportComponent,
      setActiveReportComponent,
      currentNumber,
      activeIncident,
      loggedInUser,
      showNumbers,
      stage,
      setActiveTask,
      activeCompany,
      oshaForms
    } = this.props;

    let name = reportComponent?.[0];
    let isComplete = reportComponent?.[4]?.isComplete;
    let isStarted = reportComponent?.[5]?.isStarted;
    let assignedTo = reportComponent?.[2]?.assignedTo;
    let dueDate = reportComponent?.[3]?.dueDate;
    let canAssign =
      (loggedInUser?._id === activeIncident?.incidentOwner ||
        [900, 500].includes(loggedInUser?.accessLevel)) &&
      name !== 'Corrective Actions' &&
      ((name === 'Witness Statements' && activeIncident.isWitnessLegacy) ||
        name !== 'Witness Statements');
    if (assignedTo === 'unassigned') assignedTo = undefined;

    if (name === 'Initial Report') {
      isComplete = true;
      assignedTo = activeIncident.createdByUserId;
    }

    return (
      <div className="reportComponentCard">
        <div
          className={
            isComplete
              ? 'reportComponentCard-reportComponentCompleted'
              : isPastDue(dueDate)
              ? 'reportComponentCard-reportComponentPastDue '
              : isStarted
              ? 'reportComponentCard-reportComponentStarted'
              : 'reportComponentCard-reportComponent'
          }
          onClick={setActiveReportComponent}
        >
          {isComplete && (
            <div className="reportComponentCard-headerCompleted">
              Report Completed
            </div>
          )}
          {!isComplete && dueDate && dueDate !== 'No Due Date has been set' && (
            <div
              className={
                isPastDue(dueDate)
                  ? 'reportComponentCard-headerPastDue'
                  : 'reportComponentCard-header'
              }
            >
              {isPastDue(dueDate) ? 'Past Due: ' : 'Due: '}
              <span style={{ fontWeight: 'normal' }}>
                {moment(Date.parse(dueDate)).format('MM/DD/YYYY')}
              </span>
            </div>
          )}
          {showNumbers &&
          getPercentComplete(reportComponent, oshaForms) === 'no percentage' ? (
            <div className="reportComponentCard-numbersToComplete reportComponentCard-marginTop">
              {currentNumber || 0}
            </div>
          ) : (
            showNumbers && (
              <div className="reportComponentCard-numbersToComplete reportComponentCard-marginTop">
                {getPercentComplete(reportComponent, oshaForms)}
                <span style={{ fontSize: 36 }}>%</span>
              </div>
            )
          )}
          <div className="reportComponentCard-reportSectionName">
            {reportComponent[0] === 'OSHA Compliance' &&
            activeCompany.reportingType === 2 ? (
              'PERRP'
            ) : reportComponent[0] === 'Initial Report' ? (
              <div style={{ width: '100px' }}>Initial Report</div>
            ) : (
              reportComponent[0]
            )}
          </div>
        </div>
        {assignedTo ? (
          <div
            className={
              isComplete
                ? 'reportComponent-assignedTo--backgroundCompleted'
                : isPastDue(dueDate)
                ? 'reportComponent-assignedTo--backgroundPastDue'
                : 'reportComponent-assignedTo--background'
            }
          >
            <UserBadge
              userId={assignedTo}
              showImage
              showName
              className="reportComponent-assignedTo--assignee"
            />
          </div>
        ) : canAssign && !isComplete ? (
          <div className="reportComponent-assignedTo">
            <Button
              text="Assign as Report Task"
              color="blue"
              inputClassName="assignButton"
              onClick={() =>
                setActiveTask({
                  ownerId: activeIncident._id,
                  ownerType: 'incident',
                  reportComponentName: reportComponent[0],
                  groupId: activeIncident.groupId,
                  label: `${activeIncident.incidentNumber}, ${reportComponent[0]}`,
                  currentCategory: [6, 16],
                  schedule: 'immediate',
                  stage
                })
              }
            />
          </div>
        ) : (
          <div className="reportComponent-assignedTo" />
        )}
        {(isComplete || !hasAccess(loggedInUser, stage, activeIncident)) && (
          <div className="reportComponentCard-reportLocked">
            <HoverImage
              src={require('../../../assets/images/reportLock.png')}
              srcHover={require('../../../assets/images/reportLockHover.png')}
              alt="report is view only"
            />
          </div>
        )}
      </div>
    );
  }
}
