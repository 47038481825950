import axios from 'axios';
import { isDesktop, isMobileOnly } from 'react-device-detect';
import config from '../config/config';
import history from '../history';
import store from '../store';
import { START_LOADING, STOP_LOADING } from '../constants/actionTypes';
import { MOBILE_MIN_VERSION } from '../constants/constants';

export function setTokenHeader() {
  return sessionStorage.getItem('token');
}

export function getQueryString(options = {}) {
  return Object.keys(options)
    .reduce((acc, cur) => {
      const name = encodeURIComponent(cur);
      let val = options[cur];
      if (val !== undefined) {
        if (Array.isArray(val)) {
          val.forEach(v => acc.push(`${name}[]=${encodeURIComponent(v)}`));
        } else {
          acc.push(`${name}=${encodeURIComponent(val)}`);
        }
      }
      return acc;
    }, [])
    .join('&');
}

axios.default.withCredentials = true;

axios.defaults.headers = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
  'Content-Type': 'application/json'
};

const v2Api = axios.create({
  baseURL: `${config.apiUrl}/v2`
});

const companyApi = axios.create({
  baseURL: `${config.apiUrl}/v2/companies`
});

const companyApiNoLoading = axios.create({
  baseURL: `${config.apiUrl}/v2/companies`
});

const requestConfigNoLoading = config => {
  if (!config.headers.Authorization) {
    const token = setTokenHeader();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  if (!isDesktop && !isMobileOnly) {
    config.headers['ireport-mobile-version'] = MOBILE_MIN_VERSION;
  }

  return config;
};

const requestConfig = config => {
  store.dispatch({ type: START_LOADING });

  return requestConfigNoLoading(config);
};

const responseStop = response => {
  store.dispatch({ type: STOP_LOADING });

  return response.data;
};

const errorHandlerNoLoading = error => {
  if (!error.response) throw error;

  if (error.response.status === 500 || error.response.status === 418) {
    if (process.env.NODE_ENV === 'development') throw error.response.data;
    else history.push('/error');
  }

  if (error.response.status === 403) {
    history.push('/login');
  }

  throw error.response.data;
};

const errorHandler = error => {
  store.dispatch({ type: STOP_LOADING });

  return errorHandlerNoLoading(error);
};

v2Api.interceptors.request.use(requestConfig, error => Promise.reject(error));
v2Api.interceptors.response.use(responseStop, errorHandler);

const addCompanyId = config => {
  let state = store.getState();
  let companyId =
    state.company?.activeCompany?._id || state.company?.list?.[0]?._id;

  config.url = `/${companyId || '1234'}${config.url}`;
  return requestConfig(config);
};

companyApi.interceptors.request.use(addCompanyId, error =>
  Promise.reject(error)
);
companyApi.interceptors.response.use(responseStop, errorHandler);

companyApiNoLoading.interceptors.request.use(
  config => {
    let state = store.getState();
    let companyId =
      state.company?.activeCompany?._id || state.company?.list?.[0]?._id;

    config.url = `/${companyId}${config.url}`;
    return requestConfigNoLoading(config);
  },
  error => Promise.reject(error)
);
companyApiNoLoading.interceptors.response.use(
  response => response.data,
  errorHandlerNoLoading
);

const v2ApiNoLoading = axios.create({
  baseURL: `${config.apiUrl}/v2`
});

v2ApiNoLoading.interceptors.request.use(requestConfigNoLoading, error =>
  Promise.reject(error)
);
v2ApiNoLoading.interceptors.response.use(
  response => response.data,
  errorHandlerNoLoading
);

const companyApiGet = url =>
  companyApi
    .get(url)
    .then(response => response)
    .catch(err => {
      throw err;
    });

const companyApiPost = (url, data) =>
  companyApi
    .post(url, data)
    .then(response => response)
    .catch(err => {
      throw err;
    });

const companyApiPut = (url, data) =>
  companyApi
    .put(url, data)
    .then(response => response)
    .catch(err => {
      throw err;
    });

const companyApiDelete = url =>
  companyApi
    .delete(url)
    .then(response => response)
    .catch(err => {
      throw err;
    });

const companyApiNLGet = url =>
  companyApiNoLoading
    .get(url)
    .then(response => response)
    .catch(err => {
      throw err;
    });

const companyApiNLPut = (url, data) =>
  companyApiNoLoading
    .put(url, data)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchUserAccount = payload =>
  v2Api
    .post(`/userAccounts/login`, payload)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchMicrosoftLoginConfig = payload =>
  v2Api
    .get(`/userAccounts/auth/sso/${payload.companyName}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const userMicrosoftLogin = payload =>
  v2Api
    .post(`/userAccounts/microsoftLogin`, payload)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const forgotPasswordReset = payload =>
  v2Api
    .post(`/userAccounts/auth/resetPassword`, payload)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const doesUsernameExist = payload =>
  v2ApiNoLoading
    .get(`/userAccounts/doesUsernameExist/${payload.candidateUsername}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const createUserAccount = userAccount =>
  v2Api
    .post(`/userAccounts/`, userAccount)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updatePassword = (userId, values) =>
  v2Api
    .put(`/userAccounts/${userId}/updatePassword`, values)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateDefaultPassword = values =>
  v2Api
    .put(`/userAccounts/default/password`, values)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteUserAccount = userAccount =>
  v2Api
    .delete(`/userAccounts/${userAccount._id}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const validateSecurityQuestions = payload =>
  v2Api
    .post(`/userAccounts/auth/resetpassword/securityQuestions`, payload)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchCompanies = userAccountId =>
  v2Api
    .post(`/companies/?userAccountId=${userAccountId}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchReportTemplate = (companyId, reportType) =>
  v2Api
    .get(`/companies/${companyId || '1234'}/templates/?title=${reportType}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchReports = options =>
  companyApiNLGet(`/reports/?${getQueryString(options)}`);

export const createReport = (companyId, report) => {
  return v2Api
    .post(`/companies/${companyId || '1234'}/reports/`, report)
    .then(response => response)
    .catch(err => {
      throw err;
    });
};

export const updateReport = (companyId, reportId, report) => {
  return v2Api
    .put(`/companies/${companyId || '1234'}/reports/${reportId}`, report)
    .then(response => response)
    .catch(err => {
      throw err;
    });
};

export const uploadAttachment = (attachment, options) =>
  companyApiPost(`/attachments/?${getQueryString(options)}`, attachment);

export const updateAttachment = (attachment, background = false) =>
  (background ? companyApiNLPut : companyApiPut)(
    `/attachments/${attachment._id}`,
    attachment
  );

export const fetchAttachmentByOwnerId = (companyId, ownerId, ownerType) =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/attachments/?ownerId=${ownerId}&ownerType=${ownerType}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchAttachmentById = (companyId, id) =>
  v2Api
    .get(`/companies/${companyId || '1234'}/attachments/${id}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchAttachmentsByIds = (companyId, ids) =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/attachments/getAttachmentsByIds/${ids}`,
      ids
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const addDocumentFolder = (companyId, documentFolder) =>
  v2Api
    .post(`/companies/${companyId || '1234'}/documentFolders/`, documentFolder)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateDocumentFolder = documentFolder =>
  v2Api
    .put(
      `/companies/${documentFolder.companyId || '1234'}/documentFolders/${
        documentFolder._id
      }`,
      documentFolder
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteDocumentFolder = documentFolder =>
  v2Api
    .delete(
      `/companies/${documentFolder.companyId || '1234'}/documentFolders/${
        documentFolder._id
      }`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteAttachment = (id, fromOnTheSpot = false) =>
  companyApiDelete(`/attachments/${id}/${fromOnTheSpot}`);

export const searchItems = (companyId, searchTerms, searchType) =>
  v2Api
    .get(
      `/companies/${companyId ||
        '1234'}/search/?searchTerm=${searchTerms}&searchType=${searchType}`,
      {
        headers: { 'Cache-Control': 'no-cache' }
      }
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const createOshaLog = payload =>
  v2Api
    .post(`/companies/${payload.companyId || '1234'}/oshaQuestions/`, payload)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateOshaLog = payload =>
  v2Api
    .put(
      `/companies/${payload.companyId || '1234'}/oshaQuestions/${payload._id}`,
      payload
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchOshaFormByIncident = payload =>
  v2Api
    .get(
      `/companies/${payload.companyId ||
        '1234'}/oshaQuestions/?companyId=${payload.companyId ||
        '1234'}&incidentId=${payload.incidentId}`
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const createGroup = payload =>
  v2Api
    .post(`/companies/${payload.companyId || '1234'}/groups/`, payload)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateGroup = payload =>
  v2Api
    .put(
      `/companies/${payload.companyId || '1234'}/groups/${payload._id}`,
      payload
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteGroup = payload =>
  v2Api
    .delete(`/companies/${payload.companyId || '1234'}/groups/${payload.id}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const addGroupProject = payload =>
  v2Api
    .post(`/companies/${payload.companyId || '1234'}/projects/`, payload)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const updateGroupProject = payload =>
  v2Api
    .put(
      `/companies/${payload.companyId || '1234'}/projects/${payload._id}`,
      payload
    )
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const deleteGroupProject = payload =>
  v2Api
    .delete(`/companies/${payload.companyId || '1234'}/projects/${payload._id}`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const emailIncidentAssignee = payload =>
  v2Api.post(`/userAccounts/${payload.sentMessageToId}/message`, payload);

export const printRA = payload =>
  v2Api
    .get(`/companies/${payload.companyId || '1234'}/jsas/${payload._id}/print`)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const createUserSecurityQuestions = payload =>
  v2Api
    .post(`/userAccounts/auth/create/securityQuestions`, payload)
    .then(response => response)
    .catch(err => {
      throw err;
    });

export const fetchTrainingForApprover = payload =>
  companyApiGet(
    `/trainings/trainingForApproval/?companyId=${payload.companyId}&approver=${payload.approverId}&isMaster=true&isCompleted=false`
  );

export const printTrainingSummary = payload =>
  companyApiGet(`/trainings/${payload._id}/print`);

export const fetchQuizzes = payload =>
  companyApiGet(`/quizTemplates/?companyId=${payload}`);

export const fetchQuizById = payload =>
  companyApiGet(`/quizTemplates/${payload}`);

export const createQuiz = payload => companyApiPost(`/quizTemplates`, payload);

export const updateQuiz = payload =>
  companyApiPut(`/quizTemplates/${payload._id}`, payload);

export const deleteQuiz = payload =>
  companyApiDelete(`/quizTemplates/${payload._id}`);

export const submitQuiz = payload =>
  companyApiPut(`/trainings/submitQuiz/${payload._id}`, payload);

export const doesQuizHaveTitle = payload =>
  companyApiNLGet(`/quizTemplates/doesTitleExist/${payload}`);

export const doesTrainingHaveTitle = payload =>
  companyApiNLGet(`/trainings/doesTitleExist/${payload}`);

export const getTrainingTitles = () =>
  companyApiNLGet(`/trainings/trainingTitlesForCompany`);
