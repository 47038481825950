import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactLoading from 'react-loading';

import './index.css';

const QualificationCard = ({
  qualification,
  onClick,
  qualificationLoading
}) => (
  <div>
    {qualificationLoading ? (
      <div className="incidentCard">
        <ReactLoading
          type="spinningBubbles"
          color="#58a15b"
          className="loading"
        />
      </div>
    ) : (
      <div className="searchCard">
        <Link
          to={{
            pathname: `/app/documents/qualifications`
          }}
          onClick={() => onClick(qualification)}
        >
          {moment().isAfter(qualification.expires, 'day') ? (
            <div className="qualificationCard-cardHeader--overdue">
              <div className="qualificationCard-cardHeader--overDueText">
                Expired
              </div>
            </div>
          ) : (
            <div className="qualificationCard-cardHeader--due">
              <div className="qualificationCard-cardHeader--dueText">Valid</div>
            </div>
          )}
          <div
            className={
              moment().isAfter(qualification.expires, 'day')
                ? 'qualificationCard pastDue'
                : 'qualificationCard'
            }
          >
            <div className="qualificationCard-card">
              <div className="qualificationCard-leftSide">
                <div className="safetyTrainingCard-cardMargin">
                  <div className="safetyTrainingCard-cardTypes">
                    Evaluation For:{' '}
                  </div>
                  {qualification.documentData.qualificationType.label}
                </div>
                <div className="safetyTrainingCard-cardMargin">
                  <div className="safetyTrainingCard-cardTypes">
                    Name of Worker:{' '}
                  </div>
                  {Array.isArray(qualification.documentData.employeeId)
                    ? qualification.documentData.employeeId
                        .map(e => e.label)
                        .join(', ')
                    : qualification.documentData.employeeId.label}
                </div>
              </div>
              <div className="incidentCard-rightSide">
                <div className="safetyTrainingCard-cardMargin">
                  <div className="safetyTrainingCard-cardTypes">
                    Performed Date:{' '}
                  </div>
                  {qualification.documentData.scheduledDate
                    ? moment(qualification.documentData.scheduledDate).format(
                        'MM/DD/YYYY'
                      )
                    : 'Not Specified'}
                </div>
                <div className="safetyTrainingCard-cardMargin">
                  <div className="safetyTrainingCard-cardTypes">Expires: </div>
                  {qualification.expires
                    ? moment(qualification.expires).format('MM/DD/YYYY')
                    : 'Not Specified'}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    )}
  </div>
);

export default QualificationCard;
