import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import history from '../../../history';
import {
  fetchSafetyAuditTemplateById,
  createSafetyAuditTemplate,
  updateSafetyAuditTemplate,
  deleteSafetyAuditTemplate
} from '../../../api/v4';
import { getActiveCompany } from '../../../selectors/company';
import Card from '../../../components/Card';
import DeleteTemplateModal from '../../../components/Modal/deleteTemplateModal';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import Modal from '../../../components/Modal';
import { Rearranger } from '../../../components/Rearranger';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import {
  Textbox,
  Checkbox,
  Button,
  InputRow
} from '../../../components/inputs';

import styles from './template.module.scss';

export default function SafetyAuditTemplate() {
  const company = useSelector(getActiveCompany);
  const [template, setTemplate] = useState({});
  const [sections, setSections] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const [showUnsavedTemplateModal, setShowUnSavedTemplateModal] = useState(
    false
  );
  const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false);
  const { safetyAuditTemplateId, duplicate } = useParams();

  useEffect(() => {
    if (safetyAuditTemplateId && duplicate) {
      fetchSafetyAuditTemplateById(safetyAuditTemplateId).then(response => {
        delete response._id;
        const newTitle = `${response.title} - Copy`;
        setSections(response.sections);
        setTemplate({ ...response, title: newTitle });
        setIsCreating(true);
      });
    } else if (safetyAuditTemplateId) {
      fetchSafetyAuditTemplateById(safetyAuditTemplateId).then(response => {
        setSections(response.sections);
        setTemplate(response);
      });
    } else {
      setIsCreating(true);
    }
  }, [safetyAuditTemplateId, duplicate]);

  const handleQuestionChange = (sectionIndex, questionIndex, value) => {
    sections[sectionIndex].questions[questionIndex].label = value;
    setSections([...sections]);
    setHasChanges(true);
  };

  const handleSectionChange = (sectionIndex, title) => {
    sections[sectionIndex].title = title;
    setSections([...sections]);
    setHasChanges(true);
  };

  const addRows = (sectionIndex, numberOfRows) => {
    let questions = sections[sectionIndex].questions;
    for (let i = 1; i <= numberOfRows; i++) {
      questions.push({ label: '' });
    }
    sections[sectionIndex].questions = questions;

    setSections([...sections]);
    setHasChanges(true);
  };

  const removeQuestion = (sectionIndex, questions) => {
    sections[sectionIndex].questions = [...questions];
    setSections([...sections]);
    setHasChanges(true);
  };

  const addSection = () => {
    sections.push({ title: '', questions: [{ label: '', answer: 0 }] });
    setSections([...sections]);
    setHasChanges(true);
  };

  const questionIsMissing =
    sections.filter(
      section =>
        section.questions.filter(question => !question.label).length > 0
    ).length > 0;

  const canSubmit =
    (hasChanges || duplicate) &&
    template.title &&
    sections.length > 0 &&
    !sections.some(section => !section.title) &&
    !sections.some(section => !section.questions.length) &&
    !questionIsMissing;

  const submit = () => {
    template.sections = sections;
    if (template._id && !duplicate) {
      updateSafetyAuditTemplate(template).then(() =>
        history.push('/app/safetyAuditTemplates')
      );
    } else {
      createSafetyAuditTemplate({
        ...template,
        companyId: company._id
      }).then(() => history.push('/app/safetyAuditTemplates'));
    }
  };

  const header = (
    <Header
      title="Custom Safety Audit"
      section={
        !isCreating && !isEditing
          ? 'View Template'
          : isCreating
          ? 'Create New Template'
          : 'Edit Template'
      }
      needsSaved={hasChanges}
      clickBack={() =>
        hasChanges && isEditing
          ? setShowSaveChangesModal(true)
          : hasChanges && isCreating
          ? setShowUnSavedTemplateModal(true)
          : history.goBack()
      }
      rightButtons={{
        text: isEditing ? 'Delete Template' : 'Edit Template',
        color: isEditing ? 'red' : 'blue',
        visible: !isCreating,
        onClick: () => {
          isEditing ? setShowDeleteTemplateModal(true) : setIsEditing(true);
        }
      }}
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonDisabled={!canSubmit}
      saveButtonClick={submit}
      cancelButtonClick={() =>
        isEditing && hasChanges
          ? setShowSaveChangesModal(true)
          : isEditing && !hasChanges
          ? setIsEditing(false)
          : setShowSaveChangesModal(true)
      }
      editing={isEditing}
    />
  );

  return (
    <HeaderAndFooter
      Header={header}
      Footer={footer}
      showFooter={isCreating || isEditing}
    >
      <Card title="Safety Audit Details" showHeader>
        <Textbox
          currentValue={template.title}
          fieldLabel="Title"
          isRequired
          onChange={text => {
            setTemplate({ ...template, title: text });
            setHasChanges(true);
          }}
          disabled={!isCreating && !isEditing}
        />
        <Textbox
          currentValue={template.description}
          fieldLabel="Description"
          onChange={text => {
            setTemplate({ ...template, description: text });
            setHasChanges(true);
          }}
          disabled={!isCreating && !isEditing}
        />
        <Textbox
          currentValue={template.instructions}
          fieldLabel="Instructions"
          onChange={text => {
            setTemplate({ ...template, instructions: text });
            setHasChanges(true);
          }}
          disabled={!isCreating && !isEditing}
        />
        <Checkbox
          currentValue={template.allowBulkActions}
          fieldLabel="Allow Bulk Actions"
          displayTooltip
          tooltip={`Bulk Actions allow a user to quickly mark all questions in a section with \u2713, X, N/A`}
          onChange={checked => {
            setTemplate({ ...template, allowBulkActions: checked });
            setHasChanges(true);
          }}
        />
        <Checkbox
          fieldLabel="Require Photo for Unacceptable Items"
          currentValue={template.requirePhotoForUnacceptableItems}
          onChange={checked => {
            setTemplate({
              ...template,
              requirePhotoForUnacceptableItems: checked
            });
            setHasChanges(true);
          }}
        />
        <Checkbox
          fieldLabel="Require Note for Unacceptable Items"
          currentValue={template.requireNoteForUnacceptableItems}
          onChange={checked => {
            setTemplate({
              ...template,
              requireNoteForUnacceptableItems: checked
            });
            setHasChanges(true);
          }}
        />
        <Checkbox
          fieldLabel="Require Corrective Action for Unacceptable Items"
          currentValue={template.requireCAForUnacceptableItems}
          onChange={checked => {
            setTemplate({
              ...template,
              requireCAForUnacceptableItems: checked
            });
            setHasChanges(true);
          }}
        />
      </Card>
      {sections?.map((section, index) => (
        <Card
          key={index}
          showFooter
          footer={
            <InputRow>
              <Button
                color="blue"
                text="Add Row"
                onClick={() => addRows(index, 1)}
                disabled={!isCreating && !isEditing}
              />
              <Button
                color="blue"
                text="Add 5 Rows"
                onClick={() => addRows(index, 5)}
                disabled={!isCreating && !isEditing}
              />
            </InputRow>
          }
        >
          <Textbox
            fieldLabel="Section Title"
            isRequired
            onChange={v => handleSectionChange(index, v)}
            currentValue={section.title}
            disabled={!isCreating && !isEditing}
          />
          <Rearranger
            items={section.questions}
            renderItem={(item, questionIndex) => (
              <Textbox
                key={index}
                currentValue={item.label}
                onChange={e => handleQuestionChange(index, questionIndex, e)}
                type="textarea"
                placeholder={`Type your question here. (Safety Audits are answered with \u2713, X, or N/A)`}
                disabled={!isCreating && !isEditing}
                className={styles.question}
                testID={`question ${questionIndex}`}
              />
            )}
            disabled={!isCreating && !isEditing}
            onChange={questions => removeQuestion(index, questions)}
          />
        </Card>
      ))}
      <InputRow className={styles.overallFooter}>
        <Button
          color="blue"
          text="Add Section"
          disabled={!isCreating && !isEditing}
          onClick={addSection}
        />
      </InputRow>
      <Modal
        title="Unsaved Safety Audit Template"
        titleClassName="redHeader"
        isOpen={showUnsavedTemplateModal}
        submitButtonColor="red"
        submitButtonText="Leave Page"
        onRequestClose={() => setShowUnSavedTemplateModal(false)}
        submitActions={() => history.goBack()}
      >
        Leaving now will stop creation of this Safety Audit Template. Are you
        sure you want to leave?
      </Modal>
      <DeleteTemplateModal
        isOpen={showDeleteTemplateModal}
        deletingWhat="Safety Audit"
        onRequestClose={() => setShowDeleteTemplateModal(false)}
        submitActions={() => {
          deleteSafetyAuditTemplate(template).then(() => history.goBack());
        }}
      />
      <SaveChangesModal
        isOpen={showSaveChangesModal}
        onRequestClose={() => setShowSaveChangesModal(false)}
        submitActions={() => {
          history.goBack();
        }}
        savingWhat="Safety Audit"
      />
    </HeaderAndFooter>
  );
}
