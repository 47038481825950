import React from 'react';
import './svg.css';

export default props => (
  <svg width="35" height="35" viewBox="0 0 70 70">
    <path d="M43 29C43 28.7348 42.8946 28.4804 42.7071 28.2929C42.5196 28.1054 42.2652 28 42 28H8C7.73478 28 7.48043 28.1054 7.29289 28.2929C7.10536 28.4804 7 28.7348 7 29C7 29.2652 7.10536 29.5196 7.29289 29.7071C7.48043 29.8946 7.73478 30 8 30H42C42.2652 30 42.5196 29.8946 42.7071 29.7071C42.8946 29.5196 43 29.2652 43 29Z" />
    <path d="M29 34H8C7.73478 34 7.48043 34.1054 7.29289 34.2929C7.10536 34.4804 7 34.7348 7 35C7 35.2652 7.10536 35.5196 7.29289 35.7071C7.48043 35.8946 7.73478 36 8 36H29C29.2652 36 29.5196 35.8946 29.7071 35.7071C29.8946 35.5196 30 35.2652 30 35C30 34.7348 29.8946 34.4804 29.7071 34.2929C29.5196 34.1054 29.2652 34 29 34Z" />
    <path d="M30 41C30 40.7348 29.8946 40.4804 29.7071 40.2929C29.5196 40.1054 29.2652 40 29 40H8C7.73478 40 7.48043 40.1054 7.29289 40.2929C7.10536 40.4804 7 40.7348 7 41C7 41.2652 7.10536 41.5196 7.29289 41.7071C7.48043 41.8946 7.73478 42 8 42H29C29.2652 42 29.5196 41.8946 29.7071 41.7071C29.8946 41.5196 30 41.2652 30 41Z" />
    <path d="M26 46H8C7.73478 46 7.48043 46.1054 7.29289 46.2929C7.10536 46.4804 7 46.7348 7 47C7 47.2652 7.10536 47.5196 7.29289 47.7071C7.48043 47.8946 7.73478 48 8 48H26C26.2652 48 26.5196 47.8946 26.7071 47.7071C26.8946 47.5196 27 47.2652 27 47C27 46.7348 26.8946 46.4804 26.7071 46.2929C26.5196 46.1054 26.2652 46 26 46Z" />
    <path d="M26 52H8C7.73478 52 7.48043 52.1054 7.29289 52.2929C7.10536 52.4804 7 52.7348 7 53C7 53.2652 7.10536 53.5196 7.29289 53.7071C7.48043 53.8946 7.73478 54 8 54H26C26.2652 54 26.5196 53.8946 26.7071 53.7071C26.8946 53.5196 27 53.2652 27 53C27 52.7348 26.8946 52.4804 26.7071 52.2929C26.5196 52.1054 26.2652 52 26 52Z" />
    <path d="M35 16C35 14.0222 34.4135 12.0888 33.3147 10.4443C32.2159 8.79981 30.6541 7.51809 28.8268 6.76121C26.9996 6.00433 24.9889 5.8063 23.0491 6.19215C21.1093 6.578 19.3275 7.53041 17.9289 8.92894C16.5304 10.3275 15.578 12.1093 15.1922 14.0491C14.8063 15.9889 15.0043 17.9996 15.7612 19.8268C16.5181 21.6541 17.7998 23.2159 19.4443 24.3147C21.0888 25.4135 23.0222 26 25 26C27.6509 25.9958 30.192 24.9408 32.0664 23.0664C33.9408 21.192 34.9958 18.6509 35 16ZM23 20V18H21C20.7348 18 20.4804 17.8946 20.2929 17.7071C20.1054 17.5196 20 17.2652 20 17V15C20 14.7348 20.1054 14.4804 20.2929 14.2929C20.4804 14.1054 20.7348 14 21 14H23V12C23 11.7348 23.1054 11.4804 23.2929 11.2929C23.4804 11.1054 23.7348 11 24 11H26C26.2652 11 26.5196 11.1054 26.7071 11.2929C26.8946 11.4804 27 11.7348 27 12V14H29C29.2652 14 29.5196 14.1054 29.7071 14.2929C29.8946 14.4804 30 14.7348 30 15V17C30 17.2652 29.8946 17.5196 29.7071 17.7071C29.5196 17.8946 29.2652 18 29 18H27V20C27 20.2652 26.8946 20.5196 26.7071 20.7071C26.5196 20.8946 26.2652 21 26 21H24C23.7348 21 23.4804 20.8946 23.2929 20.7071C23.1054 20.5196 23 20.2652 23 20Z" />
    <path d="M43 2H7C5.67392 2 4.40215 2.52678 3.46447 3.46447C2.52678 4.40215 2 5.67392 2 7V57C2 58.3261 2.52678 59.5979 3.46447 60.5355C4.40215 61.4732 5.67392 62 7 62H38.854C39.1192 61.9962 39.372 61.8871 39.5569 61.6969C39.7417 61.5066 39.8433 61.2507 39.8395 60.9855C39.8357 60.7203 39.7266 60.4675 39.5364 60.2826C39.3461 60.0978 39.0902 59.9962 38.825 60H7C6.20508 59.9976 5.4434 59.6808 4.8813 59.1187C4.31921 58.5566 4.00237 57.7949 4 57V7C4.00237 6.20508 4.31921 5.4434 4.8813 4.8813C5.4434 4.31921 6.20508 4.00237 7 4H43C43.7949 4.00237 44.5566 4.31921 45.1187 4.8813C45.6808 5.4434 45.9976 6.20508 46 7C46 7 46.04 23.409 46 28.878C45.991 30.078 48.008 30.078 48 28.878C47.962 23.407 48 7 48 7C48 6.34339 47.8707 5.69321 47.6194 5.08658C47.3681 4.47995 46.9998 3.92876 46.5355 3.46447C46.0712 3.00017 45.52 2.63188 44.9134 2.3806C44.3068 2.12933 43.6566 2 43 2Z" />
    <path d="M61.9 37.7229C61.8839 37.4392 61.8051 37.1625 61.6692 36.9129C61.5334 36.6632 61.3439 36.4468 61.1143 36.2792C60.8848 36.1115 60.621 35.9969 60.3419 35.9434C60.0627 35.8899 59.7752 35.899 59.5 35.9699C54.524 37.1019 50.093 33.7319 48.419 32.2319C48.0638 31.9144 47.604 31.7389 47.1275 31.7389C46.651 31.7389 46.1913 31.9144 45.836 32.2319C44.151 33.7319 39.698 37.1039 34.75 35.9729C34.4764 35.9095 34.1924 35.9058 33.9173 35.962C33.6422 36.0183 33.3824 36.1332 33.1557 36.2989C32.9289 36.4646 32.7406 36.6772 32.6035 36.9222C32.4663 37.1673 32.3836 37.439 32.361 37.7189C31.843 45.3109 32.73 56.0719 46.361 61.8479C46.603 61.9499 46.8629 62.0024 47.1255 62.0024C47.3881 62.0024 47.648 61.9499 47.89 61.8479C61.524 56.0699 62.414 45.3119 61.9 37.7229ZM54.12 45.5899L47.24 52.0999C46.8589 52.4637 46.3507 52.6641 45.8239 52.6585C45.2971 52.6529 44.7933 52.4417 44.42 52.0699L41.07 48.7099C40.8784 48.5231 40.7259 48.3001 40.6213 48.0538C40.5167 47.8076 40.4621 47.543 40.4606 47.2754C40.4592 47.0078 40.5109 46.7426 40.6129 46.4952C40.7148 46.2478 40.8649 46.0232 41.0544 45.8343C41.244 45.6454 41.4692 45.4961 41.7169 45.3951C41.9647 45.294 42.23 45.2432 42.4976 45.2455C42.7651 45.2479 43.0296 45.3035 43.2755 45.4089C43.5214 45.5144 43.7439 45.6677 43.93 45.8599L45.89 47.8199L51.34 42.6499C51.7299 42.2813 52.2502 42.0826 52.7866 42.0976C53.3229 42.1126 53.8314 42.3401 54.2 42.7299C54.5687 43.1198 54.7673 43.6402 54.7523 44.1765C54.7373 44.7129 54.5099 45.2213 54.12 45.5899Z" />
  </svg>
);
