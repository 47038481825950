import { call, put, select } from 'redux-saga/effects';
import saveAs from 'file-saver';
import { addMessage } from '../actions/messages';
import {
  createOshaFormResponse,
  createOshaLogResponse,
  fetchOsha300AResponse,
  fetchOsha300LogsResponse,
  updateOshaFormResponse
} from '../actions/oshaLogs';
import { getActiveCompany } from '../selectors/company';
import {
  getOsha300ALogSelector,
  getOshaLogInfoSelector
} from '../selectors/oshaLogs';
import { oshaQuestionMapper } from '../utils/oshaDbMapper';

export function* fetchOshaLogs(apiV4, { payload }) {
  try {
    const response = yield call(apiV4.fetchOshaLogs, payload.year);

    yield put(createOshaLogResponse(response));
    yield put(fetchOsha300LogsResponse(response.osha300Data));
    yield put(fetchOsha300AResponse(response.osha300AData));
  } catch (e) {
    console.log(e);
  }
}
export function* createOshaForm(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);

    const response = yield call(api.createOshaLog, payload);

    yield put(createOshaFormResponse(response));
    yield put(
      addMessage({
        error: false,
        message:
          activeCompany.reportingType === 2
            ? 'PERRP Compliance Saved Successfully'
            : 'OSHA Compliance Saved Successfully'
      })
    );
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateOshaForm(api, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);

    delete payload.formAnswers;

    let response = yield call(api.updateOshaLog, payload);

    response.formAnswers = oshaQuestionMapper(response.questions);

    yield put(updateOshaFormResponse([response]));
    yield put(
      addMessage({
        error: false,
        message:
          activeCompany.reportingType === 2
            ? 'PERRP Compliance Updated Successfully'
            : 'OSHA Compliance Updated Successfully'
      })
    );
  } catch (e) {
    console.log(e);
    yield put(addMessage({ error: true, message: `${e}` }));
  }
}

export function* createOshaLog(apiV4, { payload }) {
  try {
    const oshaLogInfo = yield select(getOshaLogInfoSelector);
    const osha300A = yield select(getOsha300ALogSelector);

    let response = {};

    response = oshaLogInfo?.oshaLogId
      ? yield call(apiV4.updateOshaLog, {
          ...payload,
          oshaId: oshaLogInfo.oshaLogId,
          auditLog: []
        })
      : yield call(apiV4.createOshaLog, payload);

    console.log('response = ', response);

    osha300A.annualAverageNumberOfWorkers =
      response.annualAverageNumberOfWorkers;
    osha300A.totalHoursWorkedByAllEmployees =
      response.totalHoursWorkedByAllEmployees;
    osha300A.maximumNumberOfWorkersEmployed =
      response.maximumNumberOfWorkersEmployed;
    osha300A.numberOfFullTime = response.numberOfFullTime;
    osha300A.numberOfPartTime = response.numberOfPartTime;
    osha300A.numberOfFireEmtParamedic = response.numberOfFireEmtParamedic;
    osha300A.numberOfPoliceFireEmt = response.numberOfPoliceFireEmt;
    osha300A.numberOfTeachersInstructors = response.numberOfTeachersInstructors;
    osha300A.numberOfAllOtherSupportStaff =
      response.numberOfAllOtherSupportStaff;
    response.oshaLogId = response._id;

    yield put(fetchOsha300AResponse(osha300A));
    yield put(createOshaLogResponse(response));
    yield put(
      addMessage({
        error: false,
        message: 'Employment Information Saved Successfully'
      })
    );
  } catch (e) {
    addMessage({ error: true, message: `${e}` });
  }
}

export function* printOshaFiles(apiV4, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const printValues = payload.print;

    delete payload.print;
    let data;

    if (printValues.indexOf(3) > -1) {
      let response = yield call(apiV4.printCSV, payload.year);

      response = 'data:text/csv;charset=utf-8,' + response;
      data = encodeURI(response);

      const link = document.createElement('a');
      link.download = `OSHA300A_${activeCompany.name}`;
      link.href = data;
      link.click();
    }
    if (printValues.indexOf(0) > -1) {
      const response = yield call(apiV4.printExcel300, payload.year);
      const link = document.createElement('a');
      link.download = response.original_filename;
      link.href = response.source_url;
      link.click();
    }

    if (printValues.indexOf(1) > -1) {
      const response = yield call(apiV4.printExcel300A, payload);

      const link = document.createElement('a');
      link.download = `OSHA300A_${activeCompany.name}`;
      link.href = response.source_url;
      link.click();
    }

    if (printValues.indexOf(2) > -1) {
      const response = yield call(apiV4.printExcel301, payload.year);
      let zip = require('jszip')();

      response.forEach((file, index) => {
        let blob = fetch(file.source_url)
          .then(r => r.blob())
          .catch(e => console.log(e));
        zip.file(`301_${index}.xlsx`, blob, {
          binary: true
        });
      });

      zip.generateAsync({ type: 'blob' }).then(function(blob) {
        saveAs(blob, `301_${payload.year}_Reports.zip`);
      });
    }

    yield put(printOshaFiles({}));
    addMessage({
      error: false,
      message: 'Documents Downloaded Successfully.'
    });
  } catch (e) {
    addMessage({ error: true, message: `${e}` });
  }
}

export function* printSelectedOsha301(apiV4, { payload }) {
  try {
    const response = yield call(apiV4.singlePrintExcel301, payload.incidentId);

    let iframe = document.createElement('iframe');
    iframe.style.visibility = 'collapse';
    document.body.append(iframe);

    iframe.contentDocument.write(
      `<form action="${response.source_url.replace(
        /"/g,
        '"'
      )}" method="GET"></form>`
    );
    iframe.contentDocument.forms[0].submit();

    setTimeout(() => iframe.remove(), 2000);

    yield put(printOshaFiles({}));
    addMessage({
      error: false,
      message: 'Selected Osha 301 Downloaded Successfully.'
    });
  } catch (e) {
    addMessage({ error: true, message: `${e}` });
  }
}
