import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../actions/messages';
import { Button, Dropdown } from '../../../components/inputs';
import Card from '../../../components/Card';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import {
  LEADERBOARD_CATEGORIES,
  LEADERBOARD_SUB_CATEGORIES
} from '../../../constants/constants';
import RadioButtons from '../../../components/inputs/RadioButtons';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import { SaveCancelFooter } from '../../../components/Footer';

import { fetchLeaderboardById, updateLeaderboardById } from '../../../api/v4';
import history from '../../../history';
import useActiveHeirarchy from '../../../utils/useActiveHeirarchy';

import styles from '../leaderboardStyles.module.scss';

export default function Leaderboard({
  leaderboards,
  onChange,
  onLeftNavChange
}) {
  const { company } = useActiveHeirarchy();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [leaderboard, setLeaderboard] = useState({});
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (id) {
      fetchLeaderboardById(id).then(setLeaderboard);
    }
  }, [id]);

  const groupDropdown = company?.groups.map(g => {
    return { value: g._id, label: g.name };
  });

  const updateLeaderBoard = (index, value) => {
    if (id) {
      setLeaderboard({ ...leaderboard, ...value });
      setHasChanges(true);
    } else {
      let updated = leaderboards?.map((l, i) => {
        if (i === index) {
          return { ...l, ...value };
        }
        return l;
      });
      onChange(updated);
    }
  };

  const deleteLeaderBoard = board => {
    let updated = leaderboards?.filter(l => l !== board);
    let updatedLeftNav = updated.map((u, i) => {
      return {
        value: `Leaderboard ${i + 1}`,
        label: `Leaderboard ${i + 1}`,
        id: `Leaderboard ${i + 1}`
      };
    });
    onChange(updated);
    onLeftNavChange(updatedLeftNav);
  };

  const handleSave = () => {
    updateLeaderboardById(id, leaderboard).then(() => {
      dispatch(addMessage({ error: false, message: 'Successfully Updated' }));
      history.goBack();
    });
  };

  const renderCard = (l, i) => (
    <Card
      title={`Leaderboard ${i + 1}`}
      name={`Leaderboard ${i + 1}`}
      showHeader={true}
    >
      <RadioButtons
        fieldLabel="Display Content For"
        options={[
          { value: 'Individual Employees', label: 'Individual Employees' },
          { value: 'Locations', label: 'Locations' }
        ]}
        isRequired={true}
        onChange={values =>
          values === 'Locations'
            ? updateLeaderBoard(i, {
                displayContent: values,
                groupIds: []
              })
            : updateLeaderBoard(i, { displayContent: values })
        }
        currentValue={l.displayContent}
        needSeparateLines
      />
      {l.displayContent === 'Individual Employees' && (
        <Dropdown
          options={groupDropdown}
          fieldLabel="Group/Establishment"
          onChange={values => updateLeaderBoard(i, { groupIds: values })}
          currentValue={l.groupIds}
          searchable
          selectButtons
          multi
        />
      )}
      <Dropdown
        options={LEADERBOARD_CATEGORIES}
        fieldLabel="Category"
        onChange={values =>
          updateLeaderBoard(i, { category: values, subCategory: null })
        }
        alphabetize
        isRequired
        currentValue={l.category}
      />
      <Dropdown
        options={LEADERBOARD_SUB_CATEGORIES?.filter(
          s => s.category === l.category
        )}
        fieldLabel="Subcategory"
        onChange={values => updateLeaderBoard(i, { subCategory: values })}
        isRequired
        currentValue={l.subCategory}
        alphabetize
      />
      {leaderboards?.length > 1 && (
        <Button
          color="red"
          onClick={() => deleteLeaderBoard(l)}
          text="Delete"
          inputClassName={styles.deleteButton}
        />
      )}
    </Card>
  );

  const handleGoBack = () => {
    hasChanges ? setShowSaveChangesModal(true) : history.goBack();
  };

  const header = (
    <Header
      section="Edit Leaderboard"
      title={id ? leaderboard?.title : 'Leaderboard'}
      clickBack={handleGoBack}
      needsSaved={hasChanges}
    />
  );

  const footer = (
    <SaveCancelFooter
      editing
      cancelButtonClick={() => handleGoBack()}
      saveButtonDisabled={
        !hasChanges || !leaderboard.category || !leaderboard.subCategory
      }
      saveButtonClick={() => handleSave()}
      saveButtonText="Save"
    />
  );

  return id ? (
    <HeaderAndFooter Header={header} Footer={footer}>
      {renderCard(leaderboard, 0)}
      <SaveChangesModal
        savingWhat="Leaderboard"
        isOpen={showSaveChangesModal}
        onRequestClose={() => setShowSaveChangesModal(false)}
        submitActions={() => history.goBack()}
      />
    </HeaderAndFooter>
  ) : (
    leaderboards?.map((l, i) => renderCard(l, i))
  );
}
