import { select, put, call, all } from 'redux-saga/effects';
import history from '../history';
import { addUploadedAttachmentsResponse } from '../actions/attachments';
import { addMessage } from '../actions/messages';
import {
  fetchCustomTrainingTemplatesResponse,
  fetchTrainingsResponse,
  setActiveTrainingResponse
} from '../actions/training';
import { getAddedAttachmentsSelector } from '../selectors/attachments';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../selectors/company';
import { isYoutube } from '../utils/attachmentSelector';

export function* fetchCustomTemplates(api, apiV4) {
  try {
    const company = yield select(getActiveCompany);

    const response = yield call(apiV4.fetchCustomTrainingTemplates, {
      companyId: company._id
    });

    const attachments = yield all(
      response.map(document =>
        call(
          api.fetchAttachmentByOwnerId,
          company._id,
          document._id,
          'TrainingTemplate'
        )
      )
    );

    response.map((res, index) => (res.attachments = attachments[index]));

    yield put(fetchCustomTrainingTemplatesResponse(response));
  } catch (error) {
    console.log(error);
  }
}

export function* addCustomTemplate(api, apiV4, { payload }) {
  try {
    const company = yield select(getActiveCompany);
    const addedAttachments = yield select(getAddedAttachmentsSelector);

    payload = {
      ...payload,
      companyId: company._id,
      expiresAfterOption: payload.doesExpire ? payload.expiresAfterOption : '',
      approver: payload?.approver?.value
    };

    const response = yield call(apiV4.addCustomTrainingTemplate, payload);

    yield all(
      addedAttachments.map(attachment =>
        call(api.updateAttachment, {
          ...attachment,
          ownerId: response._id
        })
      )
    );

    yield put(addUploadedAttachmentsResponse([]));
    history.push('/app/training/templateList');
  } catch (error) {
    console.log(error);
    yield put(addMessage({ error: true, message: error.data ?? error }));
  }
}

export function* updateCustomTemplate(api, apiV4, { payload }) {
  try {
    const addedAttachments = yield select(getAddedAttachmentsSelector);

    payload = {
      ...payload,
      approver: payload?.approver?.value
    };

    const response = yield call(apiV4.updateCustomTrainingTemplate, payload);

    yield all(
      addedAttachments.map(attachment =>
        call(api.updateAttachment, {
          ...attachment,
          ownerId: response._id
        })
      )
    );

    yield put(addUploadedAttachmentsResponse([]));
    history.push('/app/training/templateList');
  } catch (error) {
    console.log(error);
    yield put(addMessage({ error: true, message: error }));
  }
}

export function* deleteCustomTemplate(apiV4, { payload }) {
  try {
    yield call(apiV4.deleteCustomTrainingTemplate, payload);

    history.push('/app/training/templateList');
  } catch (error) {
    console.log(error);
  }
}

export function* fetchTrainings(api, apiV4) {
  try {
    const company = yield select(getActiveCompany);

    let response = yield call(apiV4.fetchAllTrainings);
    const training = response.filter(training => !training?.legacy);
    const legacyTrainings = response.filter(training => training?.legacy);

    const attachments = yield all(
      legacyTrainings?.map(document =>
        call(
          api.fetchAttachmentByOwnerId,
          company._id,
          document.masterTemplateId,
          'documents'
        )
      )
    );

    legacyTrainings.map((res, index) => (res.attachments = attachments[index]));

    yield put(fetchTrainingsResponse([...training, ...legacyTrainings]));
  } catch (error) {
    console.error(error);
  }
}

export function* addTraining(api, apiV4, { payload }) {
  try {
    const isFromEmployee = payload.isFromEmployee;
    const company = yield select(getActiveCompany);
    const group = yield select(getActiveLocationId);
    const project = yield select(getActiveProject);

    let addedAttachments = yield select(getAddedAttachmentsSelector);

    if (payload.youtubeLinks?.length) {
      const youtubeAttachments = payload.youtubeLinks
        .filter(link => link.title)
        .map(link => {
          let url;
          try {
            url = new URL(link.link);
          } catch (e) {
            throw new Error(`Invalid YouTube Link ${link.link}`);
          }
          let videoId = url.searchParams.get('v');
          if (videoId) {
            /* Already have it */
          } else if (url.host === 'youtu.be') {
            videoId = url.pathname.substring(1);
          } else if (url.pathname.startsWith('/embed/')) {
            videoId = url.pathname.replace('/embed/', '');
          } else {
            throw new Error(`Invalid YouTube Link ${link.link}`);
          }
          return {
            source_url: `https://www.youtube.com/embed/${videoId}`,
            original_filename: link.title
          };
        });

      addedAttachments = [...addedAttachments, ...youtubeAttachments];
    }

    payload = {
      ...payload,
      companyId: company._id,
      attachments: [...addedAttachments, ...payload.attachments],
      groupIds: group ? [group._id] : payload.groupIds,
      projectIds: project ? [project._id] : payload.projectIds
    };

    delete payload._id;
    delete payload.isFromEmployee;

    let response;
    if (payload.schedule?.label === 'Immediately') {
      response = yield call(apiV4.addTraining, payload);
    } else if (payload.schedule?.label === 'Repeating') {
      response = yield call(apiV4.createTrainingRepeatable, payload);
    } else {
      response = yield call(apiV4.createTrainingScanOnDemand, payload);
    }

    yield all(
      addedAttachments
        .filter(attachment => !isYoutube(attachment))
        .map(attachment =>
          call(api.updateAttachment, {
            ...attachment,
            ownerId: response._id
          })
        )
    );

    yield put(addUploadedAttachmentsResponse([]));
    if (isFromEmployee || payload.equipmentId) {
      history.goBack();
    } else {
      history.push('/app/training/trainingList');
    }
    yield put(addMessage({ error: false, message: 'Created Successfully' }));
  } catch (error) {
    console.log(error);
    yield put(addMessage({ error: true, message: error.data }));
  }
}

export function* updateTraining(api, apiV4, { payload }) {
  try {
    let addedAttachments = yield select(getAddedAttachmentsSelector);

    const empSig = payload.employeeSignature;
    payload.employeeSignature = empSig?.source_url || empSig;
    const appSig = payload.approverSignature;
    payload.approverSignature = appSig?.source_url || appSig;

    if (!payload.isApprove && !payload.isPerform) {
      yield all(
        addedAttachments
          .filter(attachment => !isYoutube(attachment))
          .map(attachment =>
            call(api.updateAttachment, {
              ...attachment,
              ownerId: payload._id
            })
          )
      );

      if (payload.youtubeLinks?.length) {
        const youtubeAttachments = payload.youtubeLinks
          .filter(link => link.title)
          .map(link => {
            let url;
            try {
              url = new URL(link.link);
            } catch (e) {
              throw new Error(`Invalid YouTube Link ${link.link}`);
            }
            let videoId = url.searchParams.get('v');
            if (videoId) {
              /* Already have it */
            } else if (url.host === 'youtu.be') {
              videoId = url.pathname.substring(1);
            } else if (url.pathname.startsWith('/embed/')) {
              videoId = url.pathname.replace('/embed/', '');
            } else {
              throw new Error(`Invalid YouTube Link ${link.link}`);
            }
            return {
              source_url: `https://www.youtube.com/embed/${videoId}`,
              original_filename: link.title
            };
          });

        addedAttachments = [
          ...payload?.attachments,
          ...addedAttachments,
          ...youtubeAttachments
        ];
      } else {
        addedAttachments = [...payload.attachments, ...addedAttachments];
      }
      payload.attachments = addedAttachments;
    }

    if (payload.schedule?.label === 'Repeating') {
      yield call(apiV4.updateTrainingRepeatable, payload);
    } else if (payload.schedule?.label === 'Scan on Demand') {
      console.log('inside sc on demand');
      yield call(apiV4.updateTrainingScanOnDemand, payload);
    } else {
      if (payload.isPerform && !payload.needsApproval && payload.isCompleted) {
        if (payload?.quiz) {
          payload.approveReject = payload?.quiz?.passedQuiz
            ? 'approve'
            : 'reject';
        } else {
          payload.approveReject = 'approve';
        }
      }

      if (payload.isApprove) {
        const childTrainings = yield call(
          apiV4.fetchTrainingsByMasterId,
          payload._id
        );

        yield all(
          childTrainings.map(training => {
            const index = payload.assigneeApproval.findIndex(
              aa => aa.assignee === training.assignedTo[0]
            );
            if (index > -1) {
              return call(apiV4.updateTraining, {
                ...training,
                approveReject: payload.assigneeApproval[index].approveReject,
                approverSignature: payload.approverSignature,
                approver: payload.approver
              });
            }
            return '';
          })
        );
      }
      yield call(apiV4.updateTraining, payload);
    }

    yield put(addUploadedAttachmentsResponse([]));
    if (payload.comingFromReassigned) {
      yield put(
        setActiveTrainingResponse({
          ...payload,
          isReassigned: true
        })
      );
      history.push('/app/training/trainingContainer');
    } else if (payload.isPerform) {
      history.push('/app/dashboard');
    } else {
      history.goBack();
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deleteTraining(apiV4, { payload }) {
  try {
    if (payload.schedule?.label === 'Immediately') {
      yield call(apiV4.deleteTraining, payload);
    } else if (payload.schedule?.label === 'Repeating') {
      yield call(apiV4.deleteTrainingRepeatable, payload);
    } else {
      yield call(apiV4.deleteTrainingScanOnDemand, payload);
    }
    history.goBack();
  } catch (error) {
    console.log(error);
  }
}

export function* setActiveTraining(apiV4, { payload }) {
  try {
    const childTrainings = yield call(
      apiV4.fetchTrainingsByMasterId,
      payload._id
    );

    if (payload.stay) {
      payload = yield call(apiV4.fetchTrainingById, payload._id);
      payload.stay = true;
    }

    const childTrainingAttachments = [];
    const childQuizzes = [];
    let childCompletionDates = [];

    childTrainings.forEach(child => {
      child.attachments.forEach(attachment => {
        childTrainingAttachments.push({
          ...attachment,
          assignedTo: child.assignedTo[0],
          approveReject: child.approveReject
        });
      });
      childQuizzes.push({
        ...child,
        assignee: child.assignedTo[0],
        completionDate: child.completionDate
      });
      childCompletionDates.push({
        assignedTo: child.assignedTo[0],
        completionDate: child.completionDate
      });
    });

    yield put(
      setActiveTrainingResponse({
        ...payload,
        childAttachments: childTrainingAttachments,
        childQuizzes,
        childCompletionDates
      })
    );

    if (payload.isApprove) {
      history.push('/app/training/approveTraining');
    } else if (!payload.stay) {
      history.push('/app/training/trainingSummary');
    }
  } catch (error) {
    console.error(error);
  }
}
