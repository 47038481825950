import React from 'react';
import './svg.css';

export default props => (
  <svg
    version="1.1"
    width="30px"
    height="25px"
    x={props.x || "0px"}
    y={props.y || "0px"}
    viewBox="0 0 100 100"
  >
    <g>
      <path d="M 50 7 C 33.986097 7 21 20.20243 21 36.46875 C 21 44.28772 24.753976 51.90845 30.03125 58.15625 L 30.03125 58.1875 L 30 58.1875 C 35.725537 65.3196 44.916784 77.5968 49.03125 92.25 A 1.0001 1.0001 0 0 0 50.96875 92.25 C 55.083206 77.5971 64.274472 65.3196 70 58.1875 L 69.96875 58.1875 C 69.97415 58.1775 69.96335 58.16225 69.96875 58.15625 C 75.246027 51.90815 79 44.28762 79 36.46875 C 79 20.20243 66.013903 7 50 7 z M 50 9 C 64.914057 9 77 21.26467 77 36.46875 C 77 43.65267 73.503559 50.91485 68.4375 56.90625 A 1.0001 1.0001 0 0 0 68.4375 56.9375 C 63.014978 63.6924 54.541165 75.20395 50 89.09375 C 45.458829 75.20395 36.985033 63.6921 31.5625 56.9375 A 1.0001 1.0001 0 0 0 31.5625 56.90625 C 26.496438 50.91455 23 43.65277 23 36.46875 C 23 21.26467 35.085943 9 50 9 z M 50 20 C 40.623005 20 33 27.623 33 37 C 33 46.377 40.623001 54 50 54 C 59.376998 54 67 46.377 67 37 C 67 27.623 59.376994 20 50 20 z M 50 22 C 58.296114 22 65 28.70388 65 37 C 65 45.29612 58.29611 52 50 52 C 41.703889 52 35 45.29612 35 37 C 35 28.70388 41.703885 22 50 22 z " />
    </g>
  </svg>
);
