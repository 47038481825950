import {
  ADD_USER_TASK_REQUEST,
  ADD_USER_TASK_RESPONSE,
  DELETE_USER_TASK_REQUEST,
  DELETE_USER_TASK_RESPONSE,
  FETCH_USERS_TASKS_REQUEST,
  FETCH_USERS_TASKS_RESPONSE,
  SET_ACTIVE_TASK_REQUEST,
  SET_ACTIVE_TASK_RESPONSE,
  UPDATE_REMINDERS,
  UPDATE_USER_TASK_REQUEST,
  UPDATE_USER_TASK_RESPONSE
} from '../constants/actionTypes';

export const initialState = {
  list: [],
  activeTask: {},
  reminders: JSON.parse(localStorage.getItem('remindersSent'))
};

const tasks = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_USERS_TASKS_REQUEST:
    case ADD_USER_TASK_REQUEST:
    case UPDATE_USER_TASK_REQUEST:
    case DELETE_USER_TASK_REQUEST:
    case SET_ACTIVE_TASK_REQUEST:
      return {
        ...state
      };

    case UPDATE_REMINDERS:
      return {
        ...state,
        reminders: payload
      };

    case FETCH_USERS_TASKS_RESPONSE:
      return {
        ...state,
        ...payload
      };

    case ADD_USER_TASK_RESPONSE:
      return {
        ...state,
        list: [...state.list, payload]
      };

    case UPDATE_USER_TASK_RESPONSE: {
      let taskList = [...state.list];

      if (!payload) {
        return {
          ...state,
          list: taskList
        };
      }

      const index = taskList.findIndex(task => task._id === payload._id);

      taskList[index] = payload;
      if (state.activeTask._id === payload._id) {
        state.activeTask = { ...payload };
      }

      return {
        ...state,
        list: taskList
      };
    }

    case DELETE_USER_TASK_RESPONSE: {
      const taskList = state.list;

      const index = taskList.findIndex(task => task._id === payload._id);

      return {
        ...state,
        list: [...taskList.slice(0, index), ...taskList.slice(index + 1)]
      };
    }

    case SET_ACTIVE_TASK_RESPONSE:
      return {
        ...state,
        activeTask: payload
      };

    default:
      return {
        ...state
      };
  }
};

export default tasks;
