import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import { PieChart, Pie, Cell } from 'recharts';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import history from '../../../history';
import { uploadAttachment, deleteAttachment } from '../../../api';
import { addNote } from '../../../api/v4';
import {
  updateSafetyAudit,
  deleteSafetyAudit,
  fetchSafetyAuditById,
  setSafetyAuditNumber
} from '../../../api/v4';
import { getLoggedInUser } from '../../../selectors/users';
import AdditionalCommentsCard from '../../../components/safetyAuditCards/AdditionalCommentsCard';
import Card from '../../../components/Card';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import LeftNav from '../../../components/LeftNav';
import Modal from '../../../components/Modal';
import QuestionCard from '../../../components/safetyAuditCards/QuestionCard';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import { Textbox, InputRow, Dropdown } from '../../../components/inputs';
import UserBadge from '../../../components/UserBadge';
import { useSocket } from '../../../utils/withSocket';

import styles from './perform.module.scss';
const bStyles = classnames.bind(styles);

export default function Perform() {
  const loggedInUser = useSelector(getLoggedInUser);
  const [audit, setAudit] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSumbitSafetyAudit, setOpenSumbitSafetyAudit] = useState(false);
  const [openSumbitSafetyAuditBad, setOpenSumbitSafetyAuditBad] = useState(
    false
  );
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const [note, setNote] = useState();
  const [startTime, setStartTime] = useState('');
  const [startingPerformTime, setStartingPerformTime] = useState(0);
  const { safetyAuditId } = useParams();
  const socket = useSocket();

  useEffect(() => {
    if (safetyAuditId) {
      fetchSafetyAuditById(safetyAuditId).then(response => {
        const local = localStorage.getItem('activeAudit');
        if (local && JSON.parse(local)._id === safetyAuditId) {
          response.sections = response.sections.map((s, si) => {
            const questions = s.questions.map((q, qi) => {
              q.answer = JSON.parse(local).sections[si].questions[qi].answer;
              return q;
            });
            s.questions = questions;
            return s;
          });
        }
        setAudit(response);
        setStartingPerformTime(response.timeSpent);
        localStorage.removeItem('activeAudit');
      });
    }

    setStartTime(moment());
  }, [safetyAuditId]);

  const updateTimeSpent = () => {
    let secondsAsMinutes = moment().diff(startTime, 'seconds') / 60;

    secondsAsMinutes += parseFloat(startingPerformTime);
    audit.timeSpent = secondsAsMinutes.toFixed(2);

    socket.emit('updateSATime', {
      saId: audit._id,
      timeSpent: audit.timeSpent
    });
    setAudit(audit);
  };

  const updateAnswer = (sectionIndex, questionIndex, value) => {
    audit.sections[sectionIndex].questions[questionIndex].answer = value;
    setAudit({ ...audit, sections: [...audit.sections] });
    setHasChanges(true);
  };

  const updateImmediatelyCorrected = (sectionIndex, questionIndex, value) => {
    audit.sections[sectionIndex].questions[
      questionIndex
    ].immediatelyFixed = value;
    setAudit({ ...audit, sections: [...audit.sections] });
    setHasChanges(true);
  };

  const submit = isComplete => {
    updateTimeSpent();
    audit.isCompleted = isComplete;
    localStorage.removeItem('activeAudit');
    updateSafetyAudit(audit).then(() => history.goBack());
  };

  const deleteSAAttachment = attachment => {
    deleteAttachment(attachment._id).then(fetchAudit);
  };

  const addQuestionNote = () => {
    addNote({
      ownerType: 'safetyAuditQuestion',
      ownerId: questionId,
      value: note
    }).then(() => {
      fetchAudit();
    });
  };

  const fetchAudit = () => {
    fetchSafetyAuditById(safetyAuditId).then(response => {
      response.sections = response.sections.map((s, si) => {
        const questions = s.questions.map((q, qi) => {
          q.answer = audit.sections[si].questions[qi].answer;
          q.immediatelyFixed =
            audit.sections[si].questions[qi].immediatelyFixed;
          return q;
        });
        s.questions = questions;
        return s;
      });
      response.additionalNotes = audit.additionalNotes;
      setNote('');
      setAudit(response);
      setQuestionId();
      setOpenNoteModal(false);
    });
  };

  const answerAllQuestionsInSection = (answer, sectionIndex) => {
    let questions = audit.sections[sectionIndex].questions.map(question => {
      return { ...question, answer };
    });

    audit.sections[sectionIndex].questions = questions;
    setAudit({ ...audit });
    setHasChanges(true);
  };

  const submitSafetyAuditName = newName => {
    setAudit(audit => ({ ...audit, safetyAuditNumber: newName }));
    setSafetyAuditNumber(audit._id, newName);
  };

  const canSubmit = () => {
    const sectionCount = audit?.sections?.filter(s => {
      return s.questions.some(
        q =>
          // eslint-disable-next-line eqeqeq
          q?.answer == 2 &&
          ((audit.requireCAForUnacceptableItems && !q.task) ||
            (audit.requireNoteForUnacceptableItems && !q.notes.length) ||
            (audit.requirePhotoForUnacceptableItems && !q.attachments.length))
      );
    });

    return sectionCount?.length === 0;
  };

  const handleGoBack = () => {
    updateTimeSpent();
    localStorage.removeItem('activeAudit');
    history.goBack();
  };

  const handlePhotoUpload = async e => {
    const files = e.target.files;
    if (!files.length) return;

    let data = new FormData();

    Object.entries(files).forEach(async file => {
      data.append('attachments', file[1], file[1].name);
    });

    uploadAttachment(data, {
      ownerId: questionId,
      ownerType: 'safetyAuditQuestion'
    }).then(fetchAudit);
  };

  const header = (
    <Header
      title="Perform Safety Audit"
      section={audit.safetyAuditNumber}
      editName={submitSafetyAuditName}
      needsSaved={hasChanges}
      clickBack={() =>
        hasChanges ? setOpenUnsavedChangesModal(true) : handleGoBack()
      }
      rightButtons={{
        text: 'Delete Safety Audit',
        onClick: () => setOpenDeleteModal(true),
        color: 'red',
        visible:
          loggedInUser._id === audit?.createdByUserId ||
          loggedInUser.accessLevel === 900
      }}
    />
  );

  const footer = (
    <SaveCancelFooter
      editing
      saveButtonClick={() => setOpenSumbitSafetyAudit(true)}
      cancelButtonClick={() =>
        hasChanges ? setOpenUnsavedChangesModal(true) : handleGoBack()
      }
    />
  );

  const summaryName = `${audit?.sections?.length + 2}`;
  const additionalNotesName = `${audit?.sections?.length + 1}`;
  const leftNav = audit?.sections?.length
    ? [
        { label: 'Instructions', id: 0 },
        ...audit?.sections.map((section, i) => ({
          label: section.title,
          id: i + 1
        })),
        {
          label: 'Additional Comments & Suggestions',
          id: additionalNotesName
        },
        { label: 'Summary', id: summaryName }
      ]
    : [];
  const left = <LeftNav items={leftNav} />;
  const createdBy = audit?.auditLog?.[0]?.user;

  let safeCount = 0;
  let unsafeCount = 0;
  let notApplicableCount = 0;
  let taskCount = 0;
  for (let i = 0; i < audit?.sections?.length; i++) {
    const questions = audit?.sections[i]?.questions;
    for (let j = 0; j < questions?.length; j++) {
      const question = questions[j];
      if (
        question.answer === 1 ||
        (question.answer === 2 && question.immediatelyFixed)
      ) {
        safeCount++;
      } else if (question.answer === 2) {
        unsafeCount++;
      } else if (question.answer === 3) {
        notApplicableCount++;
      }

      if (question.task) taskCount++;
    }
  }

  const data = [
    {
      label: 'Acceptable',
      value: safeCount,
      color: '#2F8738'
    },
    {
      label: 'Unacceptable',
      value: unsafeCount,
      color: '#C74846'
    },
    {
      label: 'N/A',
      value: notApplicableCount,
      color: '#5F5F5F'
    }
  ];

  return (
    <HeaderAndFooter Header={header} Footer={footer} Left={left} showFooter>
      <Card showHeader title="Instructions" name={0}>
        {audit?.instructions}
        <div className={styles.userRow}>
          <div>
            <UserBadge
              byline="Created By"
              showName
              showImage
              userId={createdBy}
            />
          </div>
          <div className={styles.assignees}>
            {audit?.assignedTo?.map((assignee, i) => (
              <UserBadge
                byline="Assigned To"
                showName
                showImage
                userId={assignee}
                key={i}
              />
            ))}
          </div>
        </div>
      </Card>
      {audit?.sections?.map((section, i) => (
        <Card
          key={i}
          title={section.title}
          showHeader
          name={i + 1}
          rightButton={
            audit.allowBulkActions ? (
              <Dropdown
                placeholder="Mark All as Selected"
                options={[
                  {
                    value: 1,
                    label: require('../../../assets/images/checkmark.png'),
                    alt: 'make entire section checkmark',
                    className: styles.green
                  },
                  {
                    value: 2,
                    label: require('../../../assets/images/Remove Icon.png'),
                    alt: 'make entire section checkmark'
                  },
                  {
                    value: 3,
                    label: require('../../../assets/images/NA.png'),
                    alt: 'make entire section checkmark'
                  }
                ]}
                isImage
                onChange={value => answerAllQuestionsInSection(value, i)}
              />
            ) : (
              undefined
            )
          }
        >
          {section?.questions.map((question, index) => (
            <QuestionCard
              question={question}
              questionNumber={`${index + 1}`}
              sectionNumber={i}
              key={index}
              updateAnswer={value => updateAnswer(i, index, value)}
              updateImmediatelyCorrected={value =>
                updateImmediatelyCorrected(i, index, value)
              }
              addNote={() => {
                setQuestionId(question._id);
                setOpenNoteModal(true);
              }}
              addAttachment={(e, sectionNumber, questionNumber) => {
                handlePhotoUpload(e, sectionNumber, questionNumber);
                e.target.value = '';
              }}
              setQuestionId={setQuestionId}
              section={section}
              requirePhotoForUnacceptableItems={
                audit.requirePhotoForUnacceptableItems
              }
              requireNoteForUnacceptableItems={
                audit.requireNoteForUnacceptableItems
              }
              requireCAForUnacceptableItems={
                audit.requireCAForUnacceptableItems
              }
              allowBulkItems={audit.allowBulkItems}
              deleteJsaQuestionAttachment={deleteSAAttachment}
              locationId={audit.groupId}
              projectId={audit?.projectId}
              audit={audit}
            />
          ))}
        </Card>
      ))}
      <AdditionalCommentsCard
        onChange={text => {
          setAudit({ ...audit, additionalNotes: text });
          setHasChanges(true);
        }}
        name={additionalNotesName}
        notes={audit?.additionalNotes}
      />
      <Card title="Summary" showHeader name={summaryName}>
        <InputRow>
          <PieChart width={200} height={200} key={200}>
            <Pie data={data} dataKey="value" nameKey="label">
              {data.map((k, i) => (
                <Cell key={k} fill={k.color} />
              ))}
            </Pie>
          </PieChart>
          <div className={styles.legend}>
            <div className={bStyles({ greenOutline: true })}>
              <span className={bStyles({ white: true })}>{safeCount}</span>{' '}
              Marked Acceptable
            </div>
            <div className={styles.divider} />
            <div className={bStyles({ redOutline: true })}>
              <span className={bStyles({ white: true })}>{unsafeCount}</span>{' '}
              Marked Unacceptable
            </div>
            <div className={styles.divider} />
            <div className={bStyles({ greyOutline: true })}>
              <span className={bStyles({ white: true })}>
                {notApplicableCount}
              </span>{' '}
              Marked Not Applicable
            </div>
          </div>
          <div className={styles.legend}>
            {taskCount} Corrective Action(s) Assigned
          </div>
        </InputRow>
      </Card>
      <DeleteItemModal
        isOpen={openDeleteModal}
        deletingWhat="Safety Audit"
        onRequestClose={() => setOpenDeleteModal(false)}
        submitActions={() =>
          deleteSafetyAudit(audit).then(() => history.goBack())
        }
      />
      <SaveChangesModal
        isOpen={openUnsavedChangesModal}
        onRequestClose={() => setOpenUnsavedChangesModal(false)}
        submitActions={() => {
          handleGoBack();
        }}
        savingWhat="Safety Audit"
      />
      <Modal
        title="Submit Safety Audit"
        titleClassName="greenHeader"
        isOpen={openSumbitSafetyAudit}
        submitButtonColor="green"
        submitButtonText="Submit Now"
        cancelButtonText="Submit Later"
        onRequestClose={() => setOpenSumbitSafetyAudit(false)}
        submitActions={() => submit(true)}
        cancelActions={() => submit(false)}
        disableSubmit={!canSubmit()}
      >
        Submitting this safety audit will lock it and prevent any further
        editing. Tasks may still be added.
      </Modal>
      <Modal
        title="Submit Safety Audit"
        titleClassName="redHeader"
        isOpen={openSumbitSafetyAuditBad}
        hideButtons
        onRequestClose={() => setOpenSumbitSafetyAuditBad(false)}
      >
        You are unable to submit the Safety Audit until you have added all
        required items to unacceptable responses
      </Modal>
      <Modal
        title="Add Note"
        titleClassName="blueHeader"
        isOpen={openNoteModal}
        submitButtonColor="blue"
        submitButtonText="Add Note"
        onRequestClose={() => {
          setNote('');
          setOpenNoteModal(false);
        }}
        submitActions={() => addQuestionNote()}
      >
        <Textbox
          type="textarea"
          onChange={text => setNote(text)}
          placeholder="Type Here"
          fieldLabel="Add Note"
          currentValue={note}
        />
      </Modal>
    </HeaderAndFooter>
  );
}
