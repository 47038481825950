import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isValid, submit } from 'redux-form';
import classnames from 'classnames/bind';

import Loading from '../../components/Loading';
import { fetchAllUsersForLocation } from '../../api/v4';
import history from '../../history';
import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments
} from '../../actions/attachments';
import {
  addCompanyLocationRequest,
  addCompanyRequest,
  addProjectRequest,
  setActiveCompany,
  setActiveGroups,
  setActiveLocation,
  setActiveProject,
  setAllLocations,
  setIncidentOwner
} from '../../actions/company';
import {
  createUserSecurityQuestionsRequest,
  updatePasswordRequest
} from '../../actions/user';
import { addMessage } from '../../actions/messages';
import {
  SECURITY_QUESTIONS_OPTIONS_SET_ONE,
  SECURITY_QUESTIONS_OPTIONS_SET_TWO
} from '../../constants/constants';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import { isFirstTimeLogin } from '../../selectors/auth';
import {
  getActiveCompany,
  getActiveLocationId,
  getAllLocations,
  getUserCompaniesSelector
} from '../../selectors/company';
import { getLoggedInUser } from '../../selectors/users';
import CompanyLogo from '../../components/CompanyLogo';
import CompanyCard from '../../components/CompanyCard';
import CompanyForm from '../../forms/CompanyForm';
import Modal from '../../components/Modal';
import NewLocationForm from '../../forms/NewLocationForm';
import ChangePasswordForm from '../../forms/ChangePasswordForm';
import UserBadge from '../../components/UserBadge';
import {
  Textbox,
  Dropdown,
  EmployeeDropdown,
  Button,
  InputRow
} from '../../components/inputs';
import RadioButtons from '../../components/inputs/RadioButtons';
import { stateAbbr } from '../../constants/states';
import yearToDateDropdown from '../../utils/yeartoDateDropdown';

import styles from './home.module.scss';
const bStyles = classnames.bind(styles);

export class Home extends Component {
  state = {
    modalOpen: 0,
    modalCompany: {},
    modalGroup: {},
    projectName: '',
    assignIncidentOwnerModal: false,
    reassignUser: undefined,
    securityQuestionsModalOpen: false,
    securityQuestion1: '',
    securityQuestion2: '',
    securityAnswer1: '',
    securityAnswer2: '',
    users: null,
    hierarchyId: null,
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
    isSeparateProject: null,
    hoursWorked: null,
    year: new Date().getFullYear()
  };

  handleOpenModal = (company, group, modalId) => {
    this.setState({
      modalOpen: modalId,
      modalCompany: company,
      modalGroup: group,
      projectName: ''
    });
  };

  handleCloseModal = () => {
    this.setState({
      modalOpen: 0,
      streetAddress: '',
      city: '',
      state: '',
      zip: '',
      isSeparateProject: null
    });
  };

  handleSecurityQuestionsCloseModal = () => {
    this.setState({ securityQuestionsModalOpen: false });
  };

  handleGoToDashboard = () => {
    this.handleCloseModal();
    history.push('/app/dashboard');
  };

  handleNewCompany = async values => {
    await this.props.addCompany({ ...values, isOnboarding: true });
    this.handleOpenModal({}, {}, 1);
  };

  handleNewGroup = async values => {
    await this.props.addCompanyLocation({ ...values, isOnboarding: true });
    this.handleGoToDashboard();
  };

  handleUpdateState = stateObject => {
    this.setState(stateObject);
  };

  handleProjectSelection = project => {
    this.props.setActiveCompany(this.state.modalCompany);
    this.props.setActiveLocation(this.state.modalGroup);
    this.props.setActiveProject(project);
    history.push('/app/dashboard');
  };

  componentDidMount() {
    // const msalConfig = {
    //   auth: config.auth
    // };
    // new UserAgentApplication(msalConfig);
    if (this.props.firstTimeLogin && !this.props.user.companies) {
      this.setState({ modalOpen: 4 });
    } else if (
      !this.props.user.email &&
      !this.props.user.phoneNumber &&
      !this.props.user.securityQuestions &&
      !this.props.user.isMicrosoftUser
    ) {
      this.setState({
        securityQuestionsModalOpen: true
      });
    }
  }

  handleNewCompanySubmit = (values, user) => {
    this.props.addCompany({ ...values });
    this.handleCloseModal();
  };

  handleNewLocationSubmit = (values, user, modalCompany) => {
    this.props.setActiveCompany(modalCompany);
    this.props.addCompanyLocation(values);
    this.handleCloseModal();
  };

  handleNewProjectSubmit = (
    companyId,
    groupId,
    name,
    streetAddress,
    city,
    state,
    zip,
    isSeparateProject,
    hoursWorked
  ) => {
    this.props.addProject({
      companyId,
      groupId,
      name,
      streetAddress,
      city,
      state,
      zip,
      isSeparateEstablishment: isSeparateProject,
      hoursWorked
    });
    this.handleCloseModal();
  };

  openAssigneeModal = (hierarchy, currentValue) => {
    const hierarchyId = hierarchy?.companyId ?? hierarchy?.locationId;
    this.setState(
      {
        users: null,
        assignIncidentOwnerModal: true,
        reassignUser: currentValue,
        hierarchyId
      },
      () =>
        fetchAllUsersForLocation(hierarchyId, {
          ...hierarchy,
          accessLevel: [500, 900]
        }).then(users => this.setState({ users }))
    );
  };

  submitDefaultIncidentOwner = () => {
    this.props.setIncidentOwner({
      locationId: this.state.hierarchyId,
      employeeId: this.state.reassignUser
    });
    this.setState({ assignIncidentOwnerModal: false });
  };

  canSubmitProject = () => {
    const {
      isSeparateProject,
      streetAddress,
      city,
      state,
      zip,
      projectName,
      modalGroup
    } = this.state;
    let validName =
      projectName && (projectName?.match(/[A-Za-z]/g) || []).length > 1;
    const isGroupSeparate = modalGroup._id
      ? modalGroup?.isSeparateEstablishment &&
        modalGroup.operationalForYearOrLonger
      : false;
    if (isSeparateProject) {
      const zipRegex = /^[0-9]{5}$/g;
      const validZip = zip.match(zipRegex);
      const validHours = this.checkForValidProjectHours();
      return (
        validZip &&
        streetAddress &&
        city &&
        zip &&
        state &&
        validName &&
        validHours
      );
    } else if (!isGroupSeparate) {
      return validName;
    }
    return validName && isSeparateProject === false;
  };

  checkForValidProjectHours() {
    const { hoursWorked, year, modalGroup } = this.state;
    let groupHours = modalGroup?.hoursWorked?.[year] ?? [];
    if (hoursWorked) {
      let sum = groupHours?.length > 0 ? groupHours.reduce((x, y) => x + y) : 0;
      let validHours = sum >= hoursWorked?.[year];
      return validHours;
    }
    return true;
  }

  render() {
    const {
      setActiveCompany,
      setActiveLocation,
      user,
      allLocations,
      addedAttachments,
      addAttachment,
      removeAttachment,
      companyValid,
      establishmentValid,
      firstTimeLogin,
      setActiveGroups,
      setActiveProject,
      saveUserSecurityQuestions,
      addMessage,
      submitPassword
    } = this.props;

    const {
      modalOpen,
      modalCompany,
      modalGroup,
      projectName,
      assignIncidentOwnerModal,
      reassignUser,
      securityQuestionsModalOpen,
      streetAddress,
      city,
      state,
      zip,
      isSeparateProject,
      hoursWorked,
      year
    } = this.state;

    const companies = this.props.userCompanies;
    const perrpOrDash = modalCompany?.reportingType === 2 ? 'PERRP' : 'OSHA';
    const isGroupSeparate = modalGroup._id
      ? modalGroup?.isSeparateEstablishment &&
        modalGroup.operationalForYearOrLonger
      : false;

    return (
      <div className={styles.home}>
        {user.accessLevel === 900 ? (
          <InputRow right>
            <Button
              color="greenOutline"
              onClick={() => this.handleOpenModal({}, {}, 2)}
              text="New Company Division"
            />
          </InputRow>
        ) : (
          <></>
        )}
        {!companies && <Loading />}
        {companies &&
          companies.map((company, index) => (
            <div key={index} className={styles.companies}>
              <CompanyLogo
                company={company}
                setActiveCompany={setActiveCompany}
                setActiveLocation={setActiveLocation}
                setActiveProject={setActiveProject}
                allLocations={allLocations}
                setActiveGroups={setActiveGroups}
                user={user}
                assignIncidentOwner={value =>
                  this.openAssigneeModal({ companyId: company._id }, value)
                }
              />
              <div className={styles.locations}>
                <h3>Long Term Establishment</h3>
                <div className={styles.establishments}>
                  {company.groups &&
                    company.groups
                      .filter(
                        group =>
                          group.isSeparateEstablishment &&
                          group.operationalForYearOrLonger
                      )
                      .map((location, index) => (
                        <div
                          className={styles['location-outerDiv']}
                          key={index}
                        >
                          <CompanyCard
                            location={location}
                            company={company}
                            setActiveCompany={setActiveCompany}
                            setActiveLocation={setActiveLocation}
                            setActiveProject={setActiveProject}
                            allLocations={allLocations}
                            setActiveGroups={setActiveGroups}
                            openProjectModal={() =>
                              this.handleOpenModal(company, location, 5)
                            }
                            openProjectDropdown={() =>
                              this.handleUpdateState({
                                modalCompany: company,
                                modalGroup: location
                              })
                            }
                            handleProjectSelection={project =>
                              this.handleProjectSelection(project)
                            }
                            user={user}
                            assignIncidentOwner={value =>
                              user.accessLevel > 400 &&
                              this.openAssigneeModal(
                                { locationId: location._id },
                                value
                              )
                            }
                          />
                        </div>
                      ))}
                  {user.accessLevel === 900 ? (
                    <div
                      onClick={() => this.handleOpenModal(company, {}, 1)}
                      className={bStyles('location-button', 'newLocation')}
                    >
                      <div>Add Long Term Establishment</div>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
                <h3>Short Term Establishment</h3>
                <div className={styles.establishments}>
                  {company.groups &&
                    company.groups
                      .filter(
                        group =>
                          !group.isSeparateEstablishment ||
                          (!group.operationalForYearOrLonger &&
                            group.isSeparateEstablishment)
                      )
                      .map((location, index) => (
                        <div
                          className={styles['location-outerDiv']}
                          key={index}
                        >
                          <CompanyCard
                            location={location}
                            company={company}
                            setActiveCompany={setActiveCompany}
                            setActiveLocation={setActiveLocation}
                            allLocations={allLocations}
                            setActiveGroups={setActiveGroups}
                            setActiveProject={setActiveProject}
                            openProjectModal={() =>
                              this.handleOpenModal(company, location, 5)
                            }
                            openProjectDropdown={() =>
                              this.handleUpdateState({
                                modalCompany: company,
                                modalGroup: location
                              })
                            }
                            handleProjectSelection={project =>
                              this.handleProjectSelection(project)
                            }
                            user={user}
                            assignIncidentOwner={value =>
                              this.openAssigneeModal(
                                { locationId: location._id },
                                value
                              )
                            }
                          />
                        </div>
                      ))}
                  {user.accessLevel === 900 ? (
                    <div
                      onClick={() => this.handleOpenModal(company, {}, 1)}
                      className={bStyles('location-button', 'newLocation')}
                    >
                      <div>
                        <p>Add a New Group</p>
                        <p>– or –</p>
                        <p>Short Term Establishment</p>
                      </div>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
            </div>
          ))}
        {modalOpen === 1 && (
          <Modal
            title="Add New Group or Establishment"
            titleClassName="greenHeader"
            isOpen={modalOpen === 1}
            submitButtonColor="green"
            onRequestClose={() => this.handleCloseModal()}
            submitActions={this.props.submitEstablishment}
            disableSubmit={!establishmentValid}
            submitButtonText="Create"
            hideCancelButton={firstTimeLogin}
          >
            <NewLocationForm
              activeCompany={this.props.activeCompany}
              onSubmit={values =>
                firstTimeLogin
                  ? this.handleNewGroup(values)
                  : this.handleNewLocationSubmit(values, user, modalCompany)
              }
              isPerrp={modalCompany.reportingType === 2}
            />
          </Modal>
        )}
        {securityQuestionsModalOpen && (
          <Modal
            title="Answer Security Questions"
            titleClassName="blueHeader"
            isOpen={securityQuestionsModalOpen}
            submitButtonColor="blue"
            submitButtonText="Save Changes"
            hideCancelButton={true}
            submitActions={() => {
              const questionsAnswers = {
                questions: [
                  this.state.securityQuestion1.label,
                  this.state.securityQuestion2.label
                ],
                answers: [
                  {
                    question: this.state.securityQuestion1.label,
                    answer: this.state.securityAnswer1
                  },
                  {
                    question: this.state.securityQuestion2.label,
                    answer: this.state.securityAnswer2
                  }
                ]
              };
              user.securityQuestions = questionsAnswers.questions;
              user.securityAnswers = questionsAnswers.answers;
              this.setState({
                securityQuestionsModalOpen: false
              });
              return saveUserSecurityQuestions(user);
            }}
            disableSubmit={
              !(
                this.state.securityQuestion1 &&
                this.state.securityAnswer1 &&
                this.state.securityQuestion2 &&
                this.state.securityAnswer2
              )
            }
          >
            <p>
              These security questions are for you to be able to reset your
              password in case you forget it. The answers provided here will not
              be shared with others.
            </p>
            <Dropdown
              options={SECURITY_QUESTIONS_OPTIONS_SET_ONE}
              fieldLabel="Security Question 1"
              onChange={e => {
                if (this.state.securityQuestion2 === e.label) {
                  alert('Questions cannot be the same');
                  e.value = null;
                  e.label = null;
                  return;
                }
                this.setState({
                  securityQuestion1: e
                });
              }}
              currentValue={this.state.securityQuestion1}
              bareValues={false}
            />
            <Textbox
              fieldLabel="Security Question Answer 1"
              onChange={v => this.handleUpdateState({ securityAnswer1: v })}
              isRequired={true}
              placeholder="Fill in with answer to Security Question 1"
            />
            <Dropdown
              options={SECURITY_QUESTIONS_OPTIONS_SET_TWO}
              fieldLabel="Security Question 2"
              onChange={e => {
                if (this.state.securityQuestion1 === e.label) {
                  alert('Questions cannot be the same');
                  e.value = null;
                  e.label = null;
                  return;
                }

                this.setState({
                  securityQuestion2: e
                });
              }}
              currentValue={this.state.securityQuestion2}
              bareValues={false}
            />
            <Textbox
              fieldLabel="Security Question Answer 2"
              onChange={v => this.handleUpdateState({ securityAnswer2: v })}
              isRequired={true}
              placeholder="Fill in with answer to Security Question 2"
            />
          </Modal>
        )}
        {modalOpen === 2 && (
          <Modal
            title="Add New Company Division"
            titleClassName="greenHeader"
            isOpen={modalOpen === 2}
            submitButtonColor="green"
            onRequestClose={() => this.handleCloseModal()}
            disableSubmit={!companyValid}
            submitActions={this.props.submitCompany}
            submitButtonText="Create"
            hideCancelButton={firstTimeLogin}
          >
            <CompanyForm
              onSubmit={values =>
                firstTimeLogin
                  ? this.handleNewCompany(values)
                  : this.handleNewCompanySubmit(values, user)
              }
              redirect={false}
              addedAttachments={addedAttachments}
              addAttachment={addAttachment}
              removeAttachment={removeAttachment}
            />
          </Modal>
        )}
        <Modal
          title="Thanks for trying iReport!"
          titleClassName="blueHeader"
          isOpen={modalOpen === 4}
          submitActions={() => this.handleOpenModal({}, {}, 2)}
          submitButtonText="Next"
          submitButtonColor="blue"
          hideCancelButton={true}
        >
          <p>
            We are going to gather some basic information so that you can try
            the app with ease.
          </p>
        </Modal>
        <Modal
          titleClassName="blueHeader"
          title="Add New Area"
          isOpen={modalOpen === 5}
          className="modalSmall"
          submitActions={() =>
            this.handleNewProjectSubmit(
              modalCompany._id,
              modalGroup._id,
              projectName,
              streetAddress,
              city,
              state,
              zip,
              isSeparateProject,
              hoursWorked
            )
          }
          submitButtonText="Create"
          submitButtonColor="green"
          onRequestClose={() => this.handleCloseModal()}
          disableSubmit={!this.canSubmitProject()}
        >
          <Textbox
            currentValue={projectName}
            fieldLabel="Area Name"
            onChange={v => this.handleUpdateState({ projectName: v })}
            isRequired={true}
            placeholder="e.g Hotel Areas"
          />
          {isGroupSeparate && (
            <RadioButtons
              fieldLabel={`Does this require its own ${perrpOrDash} Report?`}
              options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' }
              ]}
              isRequired={true}
              onChange={v => this.handleUpdateState({ isSeparateProject: v })}
              currentValue={isSeparateProject}
              bareValues={true}
              disabled={!isGroupSeparate}
            />
          )}
          {isSeparateProject && (
            <div style={{ display: 'block' }}>
              <Textbox
                currentValue={streetAddress}
                fieldLabel="Street Address "
                onChange={v => this.handleUpdateState({ streetAddress: v })}
                isRequired={true}
                placeholder="e.g. Mall Project"
              />
              <InputRow>
                <Textbox
                  currentValue={city}
                  fieldLabel="City "
                  onChange={v => this.handleUpdateState({ city: v })}
                  isRequired={true}
                  placeholder="City"
                />
                <Dropdown
                  fieldLabel="State "
                  isRequired
                  options={stateAbbr}
                  placeholder="State"
                  currentValue={state}
                  onChange={v => this.handleUpdateState({ state: v })}
                />
                <Textbox
                  currentValue={zip}
                  fieldLabel="Zip"
                  onChange={v => this.handleUpdateState({ zip: v })}
                  isRequired={true}
                  placeholder="Zip"
                />
              </InputRow>
              <InputRow>
                <Dropdown
                  fieldLabel="Year "
                  isRequired
                  options={yearToDateDropdown()}
                  placeholder="Year"
                  currentValue={year}
                  onChange={year => this.handleUpdateState({ year })}
                />
                <Textbox
                  currentValue={hoursWorked?.[year] ?? ''}
                  fieldLabel="Total Hours Worked"
                  onChange={hours => {
                    let updated = { ...hoursWorked, [year]: hours };
                    this.handleUpdateState({ hoursWorked: updated });
                  }}
                  placeholder="0"
                  warning={
                    this.checkForValidProjectHours()
                      ? null
                      : `Hours must be less than ${modalGroup.name}'s hours`
                  }
                  touched={true}
                />
              </InputRow>
            </div>
          )}
        </Modal>
        <Modal
          title={
            reassignUser ? 'Reassign Incident Owner' : 'Assign Incident Owner'
          }
          titleClassName="blueHeader"
          isOpen={assignIncidentOwnerModal}
          submitButtonColor="blue"
          submitButtonText="Save Changes"
          onRequestClose={() =>
            this.setState({ assignIncidentOwnerModal: false })
          }
          submitActions={this.submitDefaultIncidentOwner}
          disableSubmit={!reassignUser}
        >
          <div className={styles['reportSectionContainer-emailAssigneeText']}>
            From here you can assign a {reassignUser ? 'different' : ''} user to
            be the default incident owner for anything reported within this
            Division/Establishment/Group and any children without a default
            incident owner. Incident ownership can only be assigned to
            Collaborators or Admins with access to the Establishment/Group of
            this incident.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              If there is no default incident owner for a
              Division/Establishment/Group, ownership will go to the default
              incident owner of the parent location. If this reaches all the way
              to company level, the Account Owner will be assigned as the
              incident owner.
            </span>
          </div>
          <div className={styles['incidentContainer-reassignIncident']}>
            <UserBadge showImage hoverName size="large" userId={reassignUser} />
          </div>
          {this.state.users === null ? (
            <Loading />
          ) : (
            <EmployeeDropdown
              employees={this.state.users}
              fieldLabel="Default Incident Owner"
              currentValue={reassignUser}
              onChange={value => this.setState({ reassignUser: value })}
              placeholder="No Default Incident Owner"
            />
          )}
        </Modal>
        {user?.needsToUpdatePassword && (
          <Modal
            testID="changePassword"
            title="Choose a New Password"
            titleClassName="blueHeader"
            className={styles.passwordModal}
            isOpen={user?.needsToUpdatePassword}
            submitButtonColor="green"
            onRequestClose={() => {
              addMessage({
                error: true,
                message: 'You must change your password.'
              });
            }}
            formName="ChangePasswordForm"
          >
            <ChangePasswordForm onSubmit={values => submitPassword(values)} />
          </Modal>
        )}
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  userCompanies: getUserCompaniesSelector(state),
  user: getLoggedInUser(state),
  firstTimeLogin: isFirstTimeLogin(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  companyValid: isValid('CompanyForm')(state),
  establishmentValid: isValid('NewLocationForm')(state),
  loacationList: getAllLocations(state),
  activeLocationId: getActiveLocationId(state),
  activeCompany: getActiveCompany(state),
  activeLocation: getActiveLocationId(state)
});

export const mapDispatchToProps = dispatch => ({
  setIncidentOwner: payload => dispatch(setIncidentOwner(payload)),
  setActiveCompany: company => dispatch(setActiveCompany(company)),
  setActiveLocation: locationId => dispatch(setActiveLocation(locationId)),
  addCompany: values => dispatch(addCompanyRequest(values)),
  addCompanyLocation: values => dispatch(addCompanyLocationRequest(values)),
  allLocations: values => dispatch(setAllLocations(values)),
  addAttachment: values => dispatch(addUploadedAttachmentsRequest(values)),
  removeAttachment: () => dispatch(clearUploadedAttachments()),
  setActiveGroups: values => dispatch(setActiveGroups(values)),
  addProject: values => dispatch(addProjectRequest(values)),
  setActiveProject: project => dispatch(setActiveProject(project)),
  saveUserSecurityQuestions: payload =>
    dispatch(createUserSecurityQuestionsRequest(payload)),
  submitEstablishment: () => dispatch(submit('NewLocationForm')),
  submitCompany: () => dispatch(submit('CompanyForm')),
  submitPassword: values => dispatch(updatePasswordRequest(values)),
  addMessage: message => dispatch(addMessage(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
