import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  REACTIVE_REPORT_TYPES,
  PROACTIVE_REPORT_TYPES
} from '../../../constants/constants';
import {
  PART_OF_BODY_OPTIONS,
  NATURE_OF_INJURY_OPTIONS,
  INJURY_CAUSE_CODE_OPTIONS,
  MARITAL_STATUS,
  EMPLOYEMENT_STATUS,
  WAGES_PAID_FOR_DATE_OF_INJURY_OPTIONS,
  ABSENCE_FROM_WORK_DUE_TO_INJURY_OPTIONS,
  INITIAL_TREATMENT_OPTIONS,
  EVENT_LOCATION_DESCRIPTION,
  CLAIM_REPORTED_BY_TITLE
} from '../../../constants/libertyMutualMapping';
import { states } from '../../../constants/states';
import { getActiveCompany, getAllLocations } from '../../../selectors/company';
import { getAllUsers, getMyUserId } from '../../../selectors/users';
import {
  normalizeLibertyMutualPhone,
  normalizeSSN,
  notFutureDate
} from '../../../utils/formValidation';
import Card from '../../Card';
import { DatePicker } from '../../inputs/DateTimePicker';
import HierarchySelector from '../../HierarchySelector';
import List from '../../List';
import { Textbox, Dropdown, EmployeeDropdown, TwoColumn } from '../../inputs';
import styles from './claimCard.module.scss';

const formatDate = date => (date ? moment(date).format('MM/DD/YYYY') : null);

const getDropdownAnswer = (dropdown, value) =>
  dropdown?.find(d => d.value === parseInt(value))?.label;

export default function ClaimCard({
  name,
  onChange,
  editing,
  claimSection,
  title,
  openEdit,
  isSubmitted,
  viewOnly,
  ...props
}) {
  return (
    <Card
      showHeader
      title={title}
      name={name}
      rightButton={{
        text: 'Edit',
        visible: !editing && !isSubmitted && !viewOnly,
        color: 'blue',
        onClick: () => openEdit(true)
      }}
    >
      {title === 'Employee Information' ? (
        <EmployeeCard
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
        />
      ) : title === 'Injury Information' ? (
        <InjuryCard
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
        />
      ) : title === 'Accident Information' ? (
        <AccidentCard
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
          {...props}
        />
      ) : title === 'Policy Information' ? (
        <PolicyCard
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
          {...props}
        />
      ) : title === 'Overview' ? (
        <OverviewCard claimSection={claimSection} />
      ) : (
        <></>
      )}
    </Card>
  );
}

function EmployeeCard({ claimSection, onChange, editing }) {
  return (
    <>
      {editing ? (
        <div>
          <EmployeeDropdown
            fieldLabel="Injured Employee Name"
            currentValue={claimSection?.employeeId}
            onChange={value => onChange({ ...claimSection, employeeId: value })}
            searchable
            name="employeeInformation"
            isRequired
            disabled
          />
          <TwoColumn>
            <Dropdown
              options={[
                { label: 'Male', value: 1 },
                { label: 'Female', value: 2 }
              ]}
              currentValue={claimSection?.employeeGender}
              onChange={value =>
                onChange({ ...claimSection, employeeGender: value })
              }
              fieldLabel="Gender"
              isRequired
            />
            <Textbox
              currentValue={claimSection?.employeeSSN}
              fieldLabel="Social Security Number"
              onChange={value =>
                onChange({ ...claimSection, employeeSSN: normalizeSSN(value) })
              }
              placeholder="XXX-XX-XXXX"
              isRequired
              tooltip="Full SSN will not be saved in our system, just last four. Don't add until you are ready to submit the claim."
              displayTooltip
            />
            <Textbox
              currentValue={claimSection?.streetAddress}
              fieldLabel="Street Address"
              onChange={value =>
                onChange({ ...claimSection, streetAddress: value })
              }
              isRequired
            />
            <Textbox
              currentValue={claimSection?.city}
              fieldLabel="City"
              onChange={value => onChange({ ...claimSection, city: value })}
              isRequired
            />
            <Dropdown
              fieldLabel="State"
              options={states}
              currentValue={claimSection?.state}
              onChange={value => onChange({ ...claimSection, state: value })}
              isRequired
            />
            <Textbox
              fieldLabel="Zip Code"
              currentValue={claimSection?.zip}
              onChange={value => onChange({ ...claimSection, zip: value })}
              isRequired
              warning={
                claimSection.zip?.length !== 5
                  ? 'Zip Code must be 5 numbers'
                  : null
              }
              touched={claimSection.zip?.length > 0}
              type="number"
            />
            <Textbox
              currentValue={claimSection?.employeePhone}
              fieldLabel="Phone Number"
              onChange={value =>
                onChange({
                  ...claimSection,
                  employeePhone: normalizeLibertyMutualPhone(value)
                })
              }
              placeholder="XXX-XXXXXXX"
              isRequired
            />
            <Dropdown
              fieldLabel="Marital Status"
              options={MARITAL_STATUS}
              isRequired
              onChange={value =>
                onChange({ ...claimSection, employeeMaritalStatus: value })
              }
              currentValue={claimSection?.employeeMaritalStatus}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <DatePicker
                fieldLabel="Death Date"
                currentValue={claimSection?.employeeDeathDate}
                onChange={value =>
                  onChange({ ...claimSection, employeeDeathDate: value })
                }
                className={styles.deathDate}
                touched={claimSection?.employeeDeathDate}
                error={notFutureDate(claimSection?.employeeDeathDate)}
                name="deathDate"
              />
              <DatePicker
                fieldLabel="Hire Date"
                currentValue={claimSection?.employeeDateOfHire}
                onChange={value =>
                  onChange({ ...claimSection, employeeDateOfHire: value })
                }
                isRequired
                name="hireDate"
              />
            </div>
            <Textbox
              fieldLabel="Occupation"
              currentValue={claimSection?.employeeOccupation}
              onChange={value =>
                onChange({ ...claimSection, employeeOccupation: value })
              }
              isRequired
              touched={claimSection?.employeeOccupation}
              error={
                claimSection.employeeOccupation?.length > 35 ||
                claimSection.employeeOccupation?.length < 2
                  ? 'Occupation must be between 2 and 35 characters'
                  : null
              }
            />
            <Dropdown
              fieldLabel="Employment Status"
              options={EMPLOYEMENT_STATUS}
              isRequired
              onChange={value =>
                onChange({ ...claimSection, employmentStatus: value })
              }
              currentValue={claimSection?.employmentStatus}
            />
            <Textbox
              fieldLabel="Number of Days Worked Per Week"
              currentValue={claimSection?.numberOfDaysWorkedPerWeek}
              onChange={value =>
                onChange({ ...claimSection, numberOfDaysWorkedPerWeek: value })
              }
              isRequired
              type="number"
              touched={claimSection.numberOfDaysWorkedPerWeek}
              error={
                claimSection.numberOfDaysWorkedPerWeek > 7 ||
                claimSection.numberOfDaysWorkedPerWeek < 1
                  ? 'Number of Days must be between 1 and 7'
                  : null
              }
            />
            <Textbox
              fieldLabel="Number of Hours Worked Per Week"
              currentValue={claimSection?.numberOfHoursWorkedPerWeek}
              onChange={value =>
                onChange({ ...claimSection, numberOfHoursWorkedPerWeek: value })
              }
              isRequired
              type="number"
              touched={claimSection.numberOfHoursWorkedPerWeek}
              error={
                claimSection.numberOfHoursWorkedPerWeek > 168 ||
                claimSection.numberOfHoursWorkedPerWeek < 1
                  ? 'Number of Hours must be between 1 and 168'
                  : null
              }
            />
          </TwoColumn>
        </div>
      ) : (
        <TwoColumn className={styles.claimCard}>
          <div className={styles.claimLabel}>Injured Employee Name</div>
          <div>{`${claimSection?.employeeFirstName} ${claimSection?.employeeLastName}`}</div>
          <div className={styles.claimLabel}>Gender</div>
          <div>
            {claimSection?.employeeGender === 1
              ? 'Male'
              : claimSection?.employeeGender === 2
              ? 'Female'
              : 'N/A'}
          </div>
          <div className={styles.claimLabel}>Social Security Number</div>
          <div>{claimSection?.employeeSSN}</div>
          <div className={styles.claimLabel}>Employee Address</div>
          <div className={styles.stackedText}>
            <div>{claimSection?.streetAddress}</div>
            <div>{`${claimSection?.city}, ${claimSection?.state} ${claimSection?.zip}`}</div>
          </div>
          <div className={styles.claimLabel}>Phone Number</div>
          <div>{claimSection?.employeePhone}</div>
          <div className={styles.claimLabel}>Marital Status</div>
          <div>
            {getDropdownAnswer(
              MARITAL_STATUS,
              claimSection.employeeMaritalStatus
            )}
          </div>
          <div className={styles.claimLabel}>Date of Death</div>
          <div>{formatDate(claimSection?.employeeDeathDate) ?? 'N/A'}</div>
          <div className={styles.claimLabel}>Date of Hire</div>
          <div>{formatDate(claimSection?.employeeDateOfHire)}</div>
          <div className={styles.claimLabel}>Occupation</div>
          <div>{claimSection?.employeeOccupation}</div>
          <div className={styles.claimLabel}>Employement Status</div>
          <div>
            {getDropdownAnswer(
              EMPLOYEMENT_STATUS,
              claimSection.employmentStatus
            )}
          </div>
          <div className={styles.claimLabel}>
            Number of Days Worked Per Week
          </div>
          <div>{claimSection?.numberOfDaysWorkedPerWeek}</div>
          <div className={styles.claimLabel}>
            Number of Hours Worked Per Day
          </div>
          <div>{claimSection?.numberOfHoursWorkedPerWeek}</div>
        </TwoColumn>
      )}
    </>
  );
}

function InjuryCard({ claimSection, onChange, editing }) {
  return (
    <>
      {editing ? (
        <TwoColumn>
          <Dropdown
            searchable
            fieldLabel="Body Part Affected"
            options={PART_OF_BODY_OPTIONS}
            currentValue={parseInt(claimSection?.bodyPartAffected)}
            alphabetize
            onChange={values =>
              onChange({ ...claimSection, bodyPartAffected: values })
            }
            isRequired
          />
          <Dropdown
            searchable
            fieldLabel="Nature Of Injury"
            options={
              claimSection.bodyPartAffected
                ? NATURE_OF_INJURY_OPTIONS.filter(o =>
                    o.applicableBodyParts.includes(
                      parseInt(claimSection?.bodyPartAffected)
                    )
                  )
                : NATURE_OF_INJURY_OPTIONS
            }
            currentValue={claimSection?.natureOfInjury}
            alphabetize
            onChange={values =>
              onChange({ ...claimSection, natureOfInjury: values })
            }
            isRequired
          />
          <Dropdown
            searchable
            fieldLabel="Injury Cause"
            options={INJURY_CAUSE_CODE_OPTIONS}
            currentValue={claimSection?.injuryCauseCode}
            alphabetize
            onChange={values =>
              onChange({ ...claimSection, injuryCauseCode: values })
            }
            isRequired
          />
          <Textbox
            type="text"
            fieldLabel="Source of injury"
            onChange={value =>
              onChange({ ...claimSection, sourceOfInjury: value })
            }
            currentValue={claimSection.sourceOfInjury}
            isRequired
            touched={claimSection.sourceOfInjury}
            error={
              claimSection.sourceOfInjury?.length > 15 ||
              claimSection.sourceOfInjury?.length < 2
                ? 'Source of Injury must be between 2 and 15 Characters'
                : null
            }
          />
          <Textbox
            type="text"
            fieldLabel="Injury Description"
            onChange={value =>
              onChange({ ...claimSection, injuryDescription: value })
            }
            currentValue={claimSection.injuryDescription}
            isRequired
            touched={claimSection.injuryDescription}
            error={
              claimSection.injuryDescription?.length > 121 ||
              claimSection.injuryDescription?.length < 2
                ? 'Injury Description must be between 2 and 120 Characters'
                : null
            }
          />
          <Dropdown
            searchable
            fieldLabel="Initial Treatment"
            options={INITIAL_TREATMENT_OPTIONS}
            currentValue={claimSection?.initialTreatment}
            onChange={values =>
              onChange({ ...claimSection, initialTreatment: values })
            }
            isRequired
          />
          <Dropdown
            selectButtons
            fieldLabel="Wages Paid for Date of Injury"
            options={WAGES_PAID_FOR_DATE_OF_INJURY_OPTIONS}
            currentValue={claimSection?.wagesPaidForDateOfInjury}
            onChange={values =>
              onChange({ ...claimSection, wagesPaidForDateOfInjury: values })
            }
            isRequired
          />
          <Dropdown
            selectButtons
            fieldLabel="Absence from Work Due to Injury"
            options={ABSENCE_FROM_WORK_DUE_TO_INJURY_OPTIONS}
            currentValue={claimSection?.absenceFromWorkDueToInjury}
            onChange={values =>
              onChange({
                ...claimSection,
                absenceFromWorkDueToInjury: values
              })
            }
            isRequired
          />
        </TwoColumn>
      ) : (
        <TwoColumn className={styles.claimCard}>
          <div className={styles.claimLabel}>Body Part Affected</div>
          <div>
            {getDropdownAnswer(
              PART_OF_BODY_OPTIONS,
              claimSection.bodyPartAffected
            )}
          </div>
          <div className={styles.claimLabel}>Nature Of Injury</div>
          <div>
            {getDropdownAnswer(
              NATURE_OF_INJURY_OPTIONS,
              claimSection.natureOfInjury
            )}
          </div>
          <div className={styles.claimLabel}>Injury Cause</div>
          <div>
            {getDropdownAnswer(
              INJURY_CAUSE_CODE_OPTIONS,
              claimSection.injuryCauseCode
            )}
          </div>
          <div className={styles.claimLabel}>Source Of Injury</div>
          <div>{claimSection.sourceOfInjury}</div>
          <div className={styles.claimLabel}>Injury Description</div>
          <div>{claimSection.injuryDescription}</div>
          <div className={styles.claimLabel}>Initial Treatment</div>
          <div>
            {getDropdownAnswer(
              INITIAL_TREATMENT_OPTIONS,
              claimSection.initialTreatment
            )}
          </div>
          <div className={styles.claimLabel}>Wages Paid for Date of Injury</div>
          <div>
            {getDropdownAnswer(
              WAGES_PAID_FOR_DATE_OF_INJURY_OPTIONS,
              claimSection.wagesPaidForDateOfInjury
            )}
          </div>
          <div className={styles.claimLabel}>
            Absence from Work Due to Injury
          </div>
          <div>
            {getDropdownAnswer(
              ABSENCE_FROM_WORK_DUE_TO_INJURY_OPTIONS,
              claimSection.absenceFromWorkDueToInjury
            )}
          </div>
        </TwoColumn>
      )}
    </>
  );
}

function AccidentCard({ claimSection, onChange, editing, permissions }) {
  const allGroups = useSelector(getAllLocations);
  const myUserId = useSelector(getMyUserId);
  const users = useSelector(getAllUsers);

  const findGroupName = groupId =>
    allGroups?.find(g => g._id === groupId)?.name;

  const findProjectName = (groupId, projectId) =>
    allGroups
      ?.find(g => g._id === groupId)
      ?.projects?.find(p => p._id === projectId)?.name;

  return (
    <>
      {editing ? (
        <div>
          <HierarchySelector
            groupId={claimSection.groupId}
            projectId={claimSection.projectId}
            onGroupChange={value =>
              onChange({ ...claimSection, groupId: value })
            }
            onProjectChange={value =>
              onChange({ ...claimSection, projectId: value })
            }
          />
          <TwoColumn>
            <DatePicker
              fieldLabel="Incident Date"
              pickTime
              currentValue={claimSection.incidentDate}
              onChange={values =>
                onChange({ ...claimSection, incidentDate: values })
              }
              isRequired
              name="incidentDate"
              touched={claimSection?.incidentDate}
              error={notFutureDate(claimSection?.incidentDate)}
            />
            <Textbox
              currentValue={claimSection?.streetAddress}
              fieldLabel="Incident Street Address"
              onChange={value =>
                onChange({ ...claimSection, streetAddress: value })
              }
              isRequired
            />
            <Textbox
              currentValue={claimSection?.city}
              fieldLabel="Incident City"
              onChange={value => onChange({ ...claimSection, city: value })}
              isRequired
            />
            <Dropdown
              fieldLabel="Incident State"
              options={states}
              currentValue={claimSection?.state}
              onChange={value => onChange({ ...claimSection, state: value })}
              isRequired
            />
            <Textbox
              fieldLabel="Incident Zip Code"
              currentValue={claimSection?.zip}
              onChange={value => onChange({ ...claimSection, zip: value })}
              isRequired
              warning={
                claimSection.zip?.length !== 5
                  ? 'Zip Code must be 5 numbers'
                  : null
              }
              touched={claimSection.zip?.length > 0}
              type="number"
            />
            <Textbox
              type="text"
              fieldLabel="Incident Location County"
              currentValue={claimSection.incidentLocationCounty}
              onChange={values =>
                onChange({ ...claimSection, incidentLocationCounty: values })
              }
              isRequired
            />
            <Dropdown
              searchable
              fieldLabel="Incident Location Description"
              options={EVENT_LOCATION_DESCRIPTION}
              currentValue={claimSection?.incidentLocationDescription}
              onChange={value =>
                onChange({
                  ...claimSection,
                  incidentLocationDescription: value
                })
              }
              isRequired
            />
            <Dropdown
              fieldLabel="Claim Jurisdiction"
              options={states}
              currentValue={claimSection?.claimJurisdictionCode}
              onChange={value =>
                onChange({ ...claimSection, claimJurisdictionCode: value })
              }
              isRequired
              displayTooltip
              tooltip="The code representing which State's workers compensation rules may apply to this claim. Please select the majority state for the following; state the incident occurred, state in which the injured worker is paid, state the injured work performs their job, state the injured worker resides, state from which the injured worker is supervised, state in which the injured worker was hired. When unknown or unsure, select the same state in which the incident occurred."
            />
            <EmployeeDropdown
              fieldLabel="Should also Receive Communication from Liberty Mutual?"
              currentValue={claimSection?.claimNotificationOther}
              onChange={value =>
                onChange({ ...claimSection, claimNotificationOther: value })
              }
              searchable
              name="claimNotificationOther"
              limit={permissions?.map(p => p.userId)}
              exclude={[myUserId]}
            />
            <Dropdown
              searchable
              fieldLabel="Claim Reported by Title"
              options={CLAIM_REPORTED_BY_TITLE}
              currentValue={claimSection?.claimReportedByTitle}
              onChange={value =>
                onChange({
                  ...claimSection,
                  claimReportedByTitle: value
                })
              }
              isRequired
            />
          </TwoColumn>
          <Textbox
            type="textarea"
            fieldLabel="Incident Description"
            currentValue={claimSection.incidentDescription}
            onChange={values =>
              onChange({ ...claimSection, incidentDescription: values })
            }
            isRequired
            touched={claimSection.incidentDescription}
            error={
              claimSection.incidentDescription.length > 200 ||
              claimSection.incidentDescription.length < 2
                ? 'Incident Description must be between 2 and 200 Characters'
                : null
            }
          />
        </div>
      ) : (
        <TwoColumn className={styles.claimCard}>
          <div className={styles.claimLabel}>Group/Est.</div>
          <div>{findGroupName(claimSection.groupId)}</div>
          <div className={styles.claimLabel}>Area</div>
          <div>
            {findProjectName(claimSection.groupId, claimSection.projectId)}
          </div>
          <div className={styles.claimLabel}>Incident Date</div>
          <div>{formatDate(claimSection.incidentDate)}</div>
          <div className={styles.claimLabel}>Incident Address</div>
          <div className={styles.stackedText}>
            <div>{claimSection?.streetAddress}</div>
            <div>{`${claimSection?.city}, ${claimSection?.state} ${claimSection?.zip}`}</div>
          </div>
          <div className={styles.claimLabel}>Incident Location County</div>
          <div>{claimSection?.incidentLocationCounty}</div>
          <div className={styles.claimLabel}>Incident Location Description</div>
          <div>
            {getDropdownAnswer(
              EVENT_LOCATION_DESCRIPTION,
              claimSection.incidentLocationDescription
            )}
          </div>
          <div className={styles.claimLabel}>Claim Jurisdiction Code</div>
          <div>
            {getDropdownAnswer(states, claimSection.claimJurisdictionCode)}
          </div>
          <div className={styles.claimLabel}>
            Should also Receive Communication from Liberty Mutual?
          </div>
          <div>
            {getDropdownAnswer(
              users.map(u => ({
                label: `${u.firstName} ${u.lastName}`,
                value: u._id
              })),
              claimSection.claimNotificationOther
            )}
          </div>
          <div className={styles.claimLabel}>Claim Reported by Title</div>
          <div>
            {getDropdownAnswer(
              CLAIM_REPORTED_BY_TITLE,
              claimSection.claimReportedByTitle
            )}
          </div>
          <div className={styles.claimLabel}>Incident Description</div>
          <div>{claimSection?.incidentDescription}</div>
        </TwoColumn>
      )}
    </>
  );
}

function PolicyCard({ claimSection, onChange, editing, availablePolicies }) {
  const handlePolicyNumberChange = value => {
    const policy = availablePolicies.find(a => a._id === value);

    onChange({
      lineOfBusinessCode: policy.lineOfBusinessCode,
      policyEffectiveDate: policy.policyEffectiveDate,
      policyEndDate: policy.policyExpirationDate,
      policyNumber: value
    });
  };

  const AVAILABLE_POLICIES_OPTIONS = availablePolicies?.map(p => ({
    label: p.policyNumber,
    value: p._id
  }));

  return (
    <>
      {editing ? (
        <TwoColumn>
          <Dropdown
            searchable
            fieldLabel="Policy Number"
            options={AVAILABLE_POLICIES_OPTIONS}
            currentValue={claimSection?.policyNumber}
            onChange={handlePolicyNumberChange}
            isRequired
          />
          <Textbox
            fieldLabel="Line of Business Code"
            currentValue={claimSection.lineOfBusinessCode}
            onChange={values =>
              onChange({ ...claimSection, lineOfBusinessCode: values })
            }
            isRequired
            disabled
          />
          <DatePicker
            fieldLabel="Policy Effective Date"
            currentValue={claimSection.policyEffectiveDate}
            onChange={values =>
              onChange({ ...claimSection, policyEffectiveDate: values })
            }
            isRequired
            name="policyEffectiveDate"
            disabled
          />
          <DatePicker
            fieldLabel="Policy End Date"
            currentValue={claimSection.policyEndDate}
            onChange={values =>
              onChange({ ...claimSection, policyEndDate: values })
            }
            isRequired
            name="policyEndDate"
            disabled
          />
        </TwoColumn>
      ) : (
        <TwoColumn className={styles.claimCard}>
          <div className={styles.claimLabel}>Policy Number</div>
          <div>{claimSection?.policyNumber}</div>
          <div className={styles.claimLabel}>Line Of Business Code</div>
          <div>{claimSection?.lineOfBusinessCode}</div>
          <div className={styles.claimLabel}>Policy Period Effective Date</div>
          <div>{formatDate(claimSection?.policyEffectiveDate)}</div>
          <div className={styles.claimLabel}>Policy Period End Date</div>
          <div>{formatDate(claimSection?.policyEndDate)}</div>
        </TwoColumn>
      )}
    </>
  );
}

function OverviewCard({ claimSection }) {
  const company = useSelector(getActiveCompany);
  const reportTypes = [
    ...REACTIVE_REPORT_TYPES,
    ...PROACTIVE_REPORT_TYPES.filter(r => r.value !== 8 && r.value !== 10)
  ];
  const columns = [
    {
      key: 'groupId',
      label: 'Group/Est',
      datatype: 'group'
    },
    {
      key: 'reportTemplateIds',
      label: 'Report Types',
      accessor: r => r.incidentId.reportTemplateIds.map(v => parseInt(v, 10)),
      datatype: 'svgs',
      disableSortBy: true,
      enum: company?.showAircraft
        ? reportTypes
        : reportTypes.filter(r => r.value !== 6 && r.value !== 16)
    },
    {
      key: 'incidentNumber',
      label: 'Incident Number',
      accessor: r => r.incidentId.incidentNumber
    },
    {
      key: 'incidentDate',
      label: 'Incident Date',
      datatype: 'date',
      accessor: r => r.incidentDate
    },
    {
      key: 'claimNumber',
      label: 'Claim Number'
    },
    { key: 'lastUpdatedAt', datatype: 'date' }
  ];
  return (
    <>
      <TwoColumn className={styles.claimCard}>
        <div className={styles.claimLabel}>Claim Number:</div>
        <div>{claimSection?.claimNumber}</div>
        <div className={styles.claimLabel}>Claim Sent:</div>
        <div>{formatDate(claimSection?.submissionDate)}</div>
        <div className={styles.claimLabel}>Employee Other Claims</div>
      </TwoColumn>
      <List
        data={claimSection.previousClaims}
        settings={columns}
        dataIsHash
        saveKey="previousClaims"
      />
    </>
  );
}
