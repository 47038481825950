import React, { useState, useEffect } from 'react';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import List from '../../components/List';
import {
  fetchEquipmentListAll,
  downloadEquipmentQrCodes,
  uploadEquipment
} from '../../api/v4';
import history from '../../history';
import Modal from '../../components/Modal';
import CSVUploader from '../../components/CSVUploader';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import { useDispatch, useSelector } from 'react-redux';
import { clearUploadedAttachments } from '../../actions/attachments';
import { Button } from '../../components/inputs';
import { EQUIPMENT_BULK_UPLOAD_TEMPLATE } from '../../constants/constants';
import { addMessage } from '../../actions/messages';

export default function EquipmentList() {
  const dispatch = useDispatch();
  const [equipmentList, setList] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [problems, setProblems] = useState([]);

  const addedAttachments = useSelector(getAddedAttachmentsSelector);

  useEffect(() => {
    fetchEquipmentListAll().then(setList);
  }, []);

  const downloadQR = ids => {
    downloadEquipmentQrCodes({ ids }).then(response =>
      window.open(response, '_blank')
    );
  };

  const handleUpload = () => {
    uploadEquipment(addedAttachments).then(
      r => {
        fetchEquipmentListAll().then(setList);
        dispatch(
          addMessage({
            error: false,
            message: 'Equipment Successfully Uploaded'
          })
        );
      },
      e => {
        setProblems(e.data);
        fetchEquipmentListAll().then(setList);
      }
    );

    dispatch(clearUploadedAttachments());
    setUploadModal(false);
  };
  const header = (
    <Header
      title="Equipment"
      pageActionOptions={[
        {
          label: 'Create Equipment',
          visible: true,
          color: 'blueOutline',
          onClick: () => history.push('/app/equipmentContainer/')
        },
        {
          label: 'Upload Template',
          visible: true,
          color: 'blueOutline',
          onClick: () => setUploadModal(true)
        }
      ]}
    />
  );

  const columns = [
    {
      key: 'title',
      label: 'Name'
    },
    {
      key: 'status',
      label: 'Status',
      enum: [
        { value: 1, label: 'Operational' },
        { value: 2, label: 'Service' },
        { value: 3, label: 'Not Operational' }
      ]
    },
    {
      key: 'location',
      label: 'Location'
    },
    {
      key: 'numAssocTasks',
      label: 'Number of Associated Tasks'
    }
  ];

  return (
    <HeaderAndFooter Header={header}>
      <ScrollToTopOnMount />
      <List
        data={equipmentList}
        dataIsHash
        settings={columns}
        getRowId={r => r._id}
        rowClick={row => history.push(`/app/equipmentContainer/${row._id}`)}
        saveKey={'EquipmentList'}
        actions={[
          {
            color: 'blue',
            label: 'Download QR Codes',
            onClick: ids => downloadQR(ids)
          }
        ]}
      />
      <Modal
        title="Upload Equipment Template"
        titleClassName="blueHeader"
        isOpen={uploadModal}
        className="modalSmall"
        onRequestClose={() => {
          dispatch(clearUploadedAttachments());
          setUploadModal(false);
        }}
        submitButtonColor="blue"
        submitActions={handleUpload}
        disableSubmit={!addedAttachments.length}
      >
        <Button
          color="blue"
          onClick={() => {
            var win = window.open(EQUIPMENT_BULK_UPLOAD_TEMPLATE, '_blank');
            win.focus();
          }}
          text="Download CSV Template"
        />
        <CSVUploader documentType="equipmentUpload" className="dropzone" />
      </Modal>
      <Modal
        title="Problems with Equipment Upload"
        titleClassName="redHeader"
        isOpen={problems.length}
        className="modalSmall"
        onRequestClose={() => {
          setProblems([]);
        }}
        hideButtons
      >
        <ul>
          {problems.map((issue, index) => (
            <li key={index}>{issue}</li>
          ))}
        </ul>
      </Modal>
    </HeaderAndFooter>
  );
}
