import React from 'react';
import './svg.css';

export default props => (
  <svg
    version="1.1"
    x={props.x || "0px"}
    y={props.y || "0px"}
    viewBox="0 0 512 512"
    width="30px"
    height="25px"
  >
    <path
      d="M512,158.8h-35.7h-21.1H56.8H35.7H0v73.8h35.7v60.3c0,33.3,27.1,60.4,60.4,60.4h84.2
                    c33.3,0,60.4-27.1,60.4-60.4v-28.5c0-8.5,6.9-15.4,15.4-15.4h0.5c8.5,0,15.4,6.9,15.4,15.4v36.7l0.1,0.8c4.4,29.3,30,51.4,59.6,51.4
                    h84.2c33.3,0,60.4-27.1,60.4-60.4v-60.3H512L512,158.8L512,158.8z M21.1,211.5v-31.6h14.6v31.6H21.1z M455.2,292.9
                    c0,21.7-17.6,39.3-39.3,39.3h-84.2c-19,0-35.5-14-38.7-32.7v-35.1c0-20.1-16.4-36.5-36.5-36.5H256c-20.1,0-36.5,16.4-36.5,36.5v28.5
                    c0,21.7-17.6,39.3-39.3,39.3H96.1c-21.7,0-39.3-17.6-39.3-39.3v-60.3v-38.3l31.4,31.4l14.9-14.9l-30.9-30.9h37.4l45.8,45.8
                    l14.9-14.9l-30.9-30.9h37.4l45.8,45.8l14.9-14.9l-30.9-30.9H244l45.8,45.8l14.9-14.9l-30.9-30.9h37.4l45.8,45.8l14.9-14.9
                    l-30.9-30.9h37.4l45.8,45.8l14.9-14.9l-30.9-30.9h46.9v52.7L455.2,292.9L455.2,292.9z M490.9,211.5h-14.6v-31.6h14.6V211.5z"
    />
  </svg>
);
