import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import history from '../../../history';
import * as api from '../../../api';
import { fetchAllUsersForLocation } from '../../../api/v4';
import {
  addUploadedAttachmentsRequest,
  deleteAttachmentRequest,
  clearUploadedAttachments
} from '../../../actions/attachments';
import {
  addTrainingRequest,
  updateTrainingRequest,
  deleteTrainingRequest,
  fetchCustomTrainingTemplatesRequest,
  setActiveTrainingResponse
} from '../../../actions/training';
import { getAddedAttachmentsSelector } from '../../../selectors/attachments';
import {
  getActiveLocationId,
  getActiveProject,
  getActiveCompany
} from '../../../selectors/company';
import { getDocumentFoldersSelector } from '../../../selectors/documents';
import {
  getTemplateListSelector,
  getActiveTrainingSelector
} from '../../../selectors/training';
import { getLoggedInUser } from '../../../selectors/users';
import AddYouTubeLinksModal from '../../../components/Modal/addYouTubeLinksModal';
import Card from '../../../components/Card';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import RadioButtons from '../../../components/inputs/RadioButtons';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import { Dropdown } from '../../../components/inputs';
import styles from './styles.module.scss';
import TrainingDetails from '../TrainingDetails';
import { checkIfLinkIsYoutube } from '../../../utils/attachmentSelector';

class TrainingContainer extends Component {
  state = {
    title: '',
    instructions: '',
    needsApproval: false,
    doesExpire: false,
    expiresAfterNumber: '',
    expiresAfterOption: 'days',
    hasUnsavedChanges: false,
    isEditing: false,
    isCreating: false,
    isReassigned: false,
    openDeleteConfirmationModal: false,
    openUnsavedChages: false,
    attachments: [],
    schedule: {},
    trainingType: null,
    frequency: '',
    repeatingOn: [],
    dueBy: null,
    dueDate: '',
    assignedTo: [],
    approver: null,
    dueByTime: '',
    trainingTemplateId: '',
    assignBeforeExpirationNumber: '',
    assignBeforeExpirationOption: 'days',
    groupIds: [],
    projectIds: [],
    documentFolderId: '',
    completionDate: '',
    youtubeLinks: [],
    openYoutubeLinksModal: false,
    quizzes: [],
    quizMasterId: '',
    titleList: [],
    users: {},
    category: null,
    assignOn: '',
    admins: [{}],
    isApproving: false,
    activeTraining: {},
    onTheSpotAdmin: null,
    isPaused: false
  };

  componentDidMount() {
    this.props.fetchCustomTrainingTemplates();

    const activeTemplate = this.props.activeTraining;

    if (activeTemplate?._id) {
      this.setState({
        ...activeTemplate,
        trainingType: activeTemplate.legacy
          ? { label: 'On the Spot (In-Person)', value: 3 }
          : activeTemplate.trainingType
      });

      if (activeTemplate.isReassigned) {
        this.setState({ dueDate: '', assignedTo: [], completionDate: '' });
      }

      if (activeTemplate?.groupIds?.length > 0) {
        this.fetchUsers(activeTemplate.groupIds);
      }
    } else {
      this.setState({
        isCreating: true,
        expiresAfterOption: 'days',
        isFromEmployee: activeTemplate?.isFromEmployee,
        equipmentId: activeTemplate?.equipmentId
      });
    }
    api
      .fetchQuizzes(this.props.activeCompany._id)
      .then(response => this.setState({ quizzes: response }));

    api
      .getTrainingTitles()
      .then(response => this.setState({ titleList: response }));
  }

  handleTrainingTypeSelection = value => {
    if (value === undefined) {
      let trainingType = this.state.trainingType;
      this.resetState();
      this.setState({ trainingType });
      return;
    }
    const { templates } = this.props;

    const template = templates.find(template => template._id === value);

    this.setState({
      ...template,
      _id: 0,
      trainingTemplateId: template._id,
      needsApproval:
        this.state.trainingType.label === 'Mobile/Web (Online)'
          ? template.needsApproval
          : false,
      quizMasterId:
        this.state.trainingType.label === 'Mobile/Web (Online)'
          ? template.quizMasterId
          : '',
      category: template.category,
      expiresAfterNumber:
        template.expiresAfterNumber?.value ?? template.expiresAfterNumber,
      expiresAfterOption:
        template.expiresAfterOption?.value ?? template.expiresAfterOption,
      youtubeLinks: template.youtubeLinks.filter(
        link => checkIfLinkIsYoutube(link) || link.link === ''
      )
    });
  };

  resetState = () => {
    this.setState({
      title: '',
      instructions: '',
      needsApproval: false,
      doesExpire: false,
      expiresAfterNumber: '',
      expiresAfterOption: 'days',
      hasUnsavedChanges: false,
      openDeleteConfirmationModal: false,
      openUnsavedChages: false,
      attachments: [],
      schedule: {},
      trainingType: {},
      frequency: '',
      repeatingOn: [],
      dueBy: null,
      dueDate: '',
      assignedTo: [],
      approver: null,
      dueByTime: '',
      trainingTemplateId: '',
      assignBeforeExpirationNumber: '',
      assignBeforeExpirationOption: 'days',
      completionDate: '',
      youtubeLinks: [],
      openYoutubeLinksModal: false,
      quizMasterId: '',
      category: null,
      assignOn: '',
      admins: [{}],
      isPaused: false
    });
  };

  handleTypeSelection = values => {
    const schedule = this.props.activeTraining?.equipmentId
      ? { value: 1, label: 'Immediately' }
      : values.value === 1
      ? {}
      : { value: 1, label: 'Immediately' };

    this.resetState();
    this.setState({
      trainingType: values,
      hasUnsavedChanges: true,
      schedule
    });
  };

  handleSubmit = () => {
    const { addTraining, updateTraining } = this.props;

    if (this.state._id && !this.state.isReassigned) {
      updateTraining(this.state);
    } else {
      addTraining({
        ...this.state,
        equipmentId: this.props.activeTraining?.equipmentId
      });
    }
  };

  canSubmit = () => {
    const hasAttachmentOrQuiz =
      this.state?.attachments?.length > 0 ||
      this.props?.addedAttachments?.length > 0 ||
      this.state?.youtubeLinks?.length > 0 ||
      this.state?.quizMasterId?.length > 0;

    const hasCorrectAssignees =
      this.state?.trainingType?.value === 2
        ? this.state?.admins.every(a => a?.adminId && a?.groupIds?.length > 0)
        : this.state?.schedule?.label === 'Scan on Demand'
        ? true
        : this.state?.assignedTo?.length > 0;

    const completedBeforeExpiration =
      this.state.expirationDate && this.state.doesExpire
        ? moment(this.state.expirationDate).diff(
            this.state.completionDate,
            'days'
          ) > 0
        : true;

    if (
      this.state.title.trim().length > 2 &&
      hasCorrectAssignees &&
      ((this.state.schedule?.value === 1 &&
        ((this.state.dueDate && moment().isBefore(this.state.dueDate)) ||
          this.state.trainingType.value === 3)) ||
        (this.state.schedule?.value === 2 &&
          ((this.state.frequency &&
            (this.state.repeatingOn.length > 0 ||
              this.state.repeatingOn.value)) ||
            this.state.frequency === 'quarterly' ||
            this.state.doesExpire) &&
          this.state.dueBy &&
          this.state.dueByTime) ||
        this.state.schedule?.label === 'Scan on Demand')
    ) {
      if (!hasCorrectAssignees) {
        return false;
      }
      if (
        !(
          this.state.trainingType?.value === 3 &&
          this.state.schedule?.value === 1
        ) &&
        !hasAttachmentOrQuiz
      ) {
        return false;
      }

      if (this.state.needsApproval && !this.state.approver) {
        return false;
      }

      if (
        this.state.doesExpire &&
        this.state.trainingType?.value !== 3 &&
        (!this.state.expiresAfterNumber ||
          parseInt(this.state.expiresAfterNumber) === 0)
      ) {
        return false;
      }

      if (!this.validExpirationNumber()) {
        return false;
      }

      if (
        this.state.doesExpire &&
        this.state.schedule?.label === 'Repeating' &&
        (!this.state.assignBeforeExpirationNumber ||
          parseInt(this.state.assignBeforeExpirationNumber) === 0)
      ) {
        return false;
      }

      if (
        this.state.trainingType.value === 3 &&
        this.state.schedule.value === 1
      ) {
        if (!this.state.completionDate) return false;
        if (this.state.doesExpire && !this.state.expirationDate) {
          return false;
        }
        if (!completedBeforeExpiration) {
          return false;
        }
      }

      return true;
    }

    return false;
  };

  handleDelete = () => {
    const { deleteTraining } = this.props;

    deleteTraining(this.state);
  };

  handleBack = () => {
    if (!this.state.isReassigned) {
      this.props.setActiveTemplate();
      this.props.clearUploadedAttachments();
    }
    history.goBack();
  };

  addAttachments = async e => {
    const files = e.target.files;
    if (!files.length > 0) return;

    for (let i = 0; i < files.length; i++) {
      const FileSize = files[i].size / 1024 / 1024; // in MB
      if (FileSize > 2000) {
        alert('File size exceeds 2000 MB');
        return;
      }
    }

    let data = new FormData();

    Object.entries(files).forEach(async file => {
      data.append('attachments', file[1], file[1].name);
    });

    await this.props.addAttachments({
      data,
      ownerId: this.state.isEditing ? this.props.activeTraining._id : '1234',
      ownerType: 'TrainingTemplate'
    });

    if (this.state.isCreating) this.setState({ hasUnsavedChanges: true });
  };

  fetchUsers = ids =>
    ids.forEach(id => {
      if (this.state.users[id]) return;
      fetchAllUsersForLocation(id, { locationId: id }).then(data =>
        this.setState(state => ({
          users: { ...state.users, [id]: data }
        }))
      );
    });

  validExpirationNumber = () => {
    const {
      assignBeforeExpirationOption,
      assignBeforeExpirationNumber,
      expiresAfterNumber,
      expiresAfterOption
    } = this.state;

    if (
      !assignBeforeExpirationOption ||
      !assignBeforeExpirationNumber ||
      !expiresAfterNumber ||
      !expiresAfterOption
    ) {
      return true;
    }
    const assign = moment().add(
      assignBeforeExpirationNumber,
      assignBeforeExpirationOption
    );
    const expiration = moment().add(expiresAfterNumber, expiresAfterOption);
    return assign.isBefore(expiration);
  };

  handleDeleteAttachment = async attachment => {
    let updatedAttachments = this.state.attachments?.filter(
      a => a._id !== attachment._id
    );
    this.setState({ attachments: updatedAttachments });
    await this.props.deleteAttachment({ ...attachment, fromOnTheSpot: true });
  };

  render() {
    const {
      hasUnsavedChanges,
      isEditing,
      isCreating,
      isReassigned,
      openDeleteConfirmationModal,
      openUnsavedChages,
      trainingType,
      groupIds,
      documentFolderId,
      youtubeLinks,
      openYoutubeLinksModal,
      attachments,
      quizzes
    } = this.state;

    const {
      addedAttachments,
      templates,
      activeGroup,
      activeProject,
      activeCompany,
      documentFolders
    } = this.props;

    const permissions =
      activeGroup?._id || groupIds.length > 0 ? [400, 500, 900] : [900];

    const excludeDeactivatedUsers = [100, 400, 500, 900];

    const quizDropdown = quizzes?.map(quiz => {
      return {
        label: quiz.title,
        value: quiz._id
      };
    });

    const header = (
      <Header
        title="Training"
        section={
          !isCreating && !isEditing && !isReassigned
            ? 'View Training'
            : isEditing
            ? 'Edit Training'
            : isReassigned
            ? 'Reassign Training'
            : 'Create Training'
        }
        clickBack={() =>
          hasUnsavedChanges
            ? this.setState({ openUnsavedChages: true })
            : this.handleBack()
        }
        rightButtons={
          isEditing || isReassigned
            ? {
                text: 'Delete Training',
                onClick: () =>
                  this.setState({ openDeleteConfirmationModal: true }),
                color: 'red',
                visible: !isCreating
              }
            : {
                text: 'Edit Training',
                onClick: () => this.setState({ isEditing: true }),
                color: 'blue',
                visible: !isCreating
              }
        }
      />
    );

    const footer = (
      <SaveCancelFooter
        editing={isEditing}
        saveButtonDisabled={!this.canSubmit()}
        saveButtonClick={this.handleSubmit}
        cancelButtonClick={() =>
          hasUnsavedChanges
            ? this.setState({ openUnsavedChages: true })
            : history.goBack()
        }
      />
    );

    const trainingOptions = this.state?.equipmentId
      ? [
          { label: 'Mobile/Web (Online)', value: 1 },
          { label: 'On the Spot (In-Person)', value: 3 }
        ]
      : [
          { label: 'Mobile/Web (Online)', value: 1 },
          { label: 'Scheduled (In-Person)', value: 2 },
          { label: 'On the Spot (In-Person)', value: 3 }
        ];

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          showFooter={isEditing || isCreating || isReassigned}
          className={styles.trainingContainer}
        >
          <Card showHeader title="Summary">
            <Dropdown
              fieldLabel="Add To Folder"
              options={documentFolders}
              labelKey="name"
              valueKey="_id"
              currentValue={documentFolderId}
              onChange={value => this.setState({ documentFolderId: value })}
              disabled={!isCreating && !isEditing}
            />
            <RadioButtons
              options={trainingOptions}
              currentValue={trainingType}
              onChange={this.handleTypeSelection}
              fieldLabel="Training Format"
              disabled={!isCreating}
              bareValues={false}
              needSeparateLines
              isRequired
            />
          </Card>
          <TrainingDetails
            updateAnswer={value =>
              this.setState(prevState => ({
                ...prevState,
                ...value,
                hasUnsavedChanges: true
              }))
            }
            activeTraining={{
              ...this.state,
              validTemplateIds: this.props.activeTraining?.validTemplateIds
            }}
            permissions={permissions}
            excludeDeactivatedUsers={excludeDeactivatedUsers}
            activeCompany={activeCompany}
            templates={templates}
            activeGroup={activeGroup}
            activeProject={activeProject}
            handleTrainingTypeSelection={this.handleTrainingTypeSelection}
            handleTypeSelection={this.handleTypeSelection}
            fetchUsers={this.fetchUsers}
            addAttachments={r => {
              this.addAttachments(r);
              r.target.value = '';
            }}
            addedAttachments={addedAttachments}
            deleteAttachment={this.handleDeleteAttachment}
            attachments={attachments}
            quizDropdown={quizDropdown}
            validExpirationNumber={this.validExpirationNumber}
          />
        </HeaderAndFooter>
        <SaveChangesModal
          isOpen={openUnsavedChages}
          savingWhat="Training"
          onRequestClose={() => this.setState({ openUnsavedChages: false })}
          submitActions={this.handleBack}
        />
        <DeleteItemModal
          isOpen={openDeleteConfirmationModal}
          deletingWhat="Training"
          onRequestClose={() =>
            this.setState({ openDeleteConfirmationModal: false })
          }
          submitActions={() => this.handleDelete()}
        />
        <AddYouTubeLinksModal
          isOpen={openYoutubeLinksModal}
          onRequestClose={() => this.setState({ openYoutubeLinksModal: false })}
          submitActions={values =>
            this.setState({
              youtubeLinks: values,
              openYoutubeLinksModal: false
            })
          }
          youtubeLinks={youtubeLinks}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  activeTraining: getActiveTrainingSelector(state),
  templates: getTemplateListSelector(state),
  activeGroup: getActiveLocationId(state),
  activeProject: getActiveProject(state),
  activeCompany: getActiveCompany(state),
  documentFolders: getDocumentFoldersSelector(state)
});

const mapDispatchToProps = dispatch => ({
  addAttachments: payload => dispatch(addUploadedAttachmentsRequest(payload)),
  setActiveTemplate: () => dispatch(setActiveTrainingResponse()),
  addTraining: payload => dispatch(addTrainingRequest(payload)),
  updateTraining: payload => dispatch(updateTrainingRequest(payload)),
  deleteTraining: payload => dispatch(deleteTrainingRequest(payload)),
  fetchCustomTrainingTemplates: payload =>
    dispatch(fetchCustomTrainingTemplatesRequest(payload)),
  deleteAttachment: payload => dispatch(deleteAttachmentRequest(payload)),
  clearUploadedAttachments: () => dispatch(clearUploadedAttachments())
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingContainer);
