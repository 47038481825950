import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Provider as AlertProvider, useAlert } from 'react-alert';
import { PersistGate } from 'redux-persist/lib/integration/react';

import history from '../../history';
import config from '../../config/config';
import { removeMessage } from '../../actions/messages';
import { isLoading } from '../../selectors/loading';
import { getMessageSelector } from '../../selectors/messages';
import { persistor } from '../../store';
import { unregister } from '../../registerServiceWorker';

import App from '../App';
import Card from '../../components/Card';
import AuthenticatedRoute from '../AuthenticatedRoute';
import ErrorPage from '../../components/ErrorPage';
import Home from '../Home';
import Loading from '../../components/Loading';
import Login from '../Login';
import Register from '../Register';
import TopHeader from '../TopHeader';

// Running test to see if this stops the browser performance issues we've been seeing from CS and customers
// Using the default react-loading component instead of our ireport gif
// import loadingGif from '../../assets/images/iReportSourceLoading.gif';
import styles from './root.module.scss';

unregister();

const options = {
  position: 'top center',
  timeout: 4000,
  offset: '30px',
  transition: 'scale',
  width: '100%'
};

ReactGA.initialize(config.gaTrackingID);

const AlertTemplate = ({ options, message, close }) => (
  <div
    className={[
      styles.alertStyle,
      options.type === 'error'
        ? styles.alertStyleError
        : '' + options.type === 'success'
        ? styles.alertStyleSuccess
        : ''
    ].join(' ')}
  >
    <div className={styles.alertMessage}>{message}</div>
    <div onClick={close} className="modalBody-header--X">
      <img
        src={require('../../assets/images/modalX.png')}
        alt="close the alert"
      />
    </div>
  </div>
);

const Switcher = () => {
  const message = useSelector(getMessageSelector, shallowEqual);
  const dispatch = useDispatch();
  const alert = useAlert();

  useEffect(() => {
    if (message) {
      let m = message?.message;
      let options = message?.options || {};
      if (message instanceof Error) {
        m = m.message;
      }

      if (!options?.timeout) {
        options.timeout = 150 * m.length;
        if (options.timeout < 4000) {
          options.timeout = 4000;
        } else if (options.timeout > 15000) {
          options.timeout = 15000;
        }
      }

      message.error ? alert.error(m, options) : alert.success(m, options);
      dispatch(removeMessage());
    }
  });

  return (
    <>
      <TopHeader />
      <Switch>
        <AuthenticatedRoute exact path="/home" component={Home} />
        <AuthenticatedRoute path="/app" component={App} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/error" component={ErrorPage} />
        <Route exact path="/useraccounts/microsoftlogin" component={Home} />
        <Redirect from="/" to="/login" />
      </Switch>
    </>
  );
};

const Root = props => {
  const loading = useSelector(isLoading);
  const message = config?.maintenanceMessage;
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <PersistGate loading={<Loading />} persistor={persistor}>
      {message ? (
        <Card showHeader title={message?.title}>
          {message.message}
        </Card>
      ) : (
        <Router history={history}>
          <div id="app" className="root">
            <AlertProvider template={AlertTemplate} {...options}>
              <Switcher />
            </AlertProvider>
            <div
              className={`${styles.loading} ${loading ? '' : styles.hidden}`}
            >
              {/* <div>
                  <img src={loadingGif} alt="Loading..." />
                </div> */}
              <Loading />
            </div>
          </div>
        </Router>
      )}
    </PersistGate>
  );
};

export default Root;
