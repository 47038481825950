import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { EQUIPMENT_STATUS_DROPDOWN } from '../../constants/constants';

import {
  uploadAttachment,
  deleteAttachment,
  updateAttachment
} from '../../api/';
import {
  createEquipment,
  deleteEquipment,
  fetchEquipmentById,
  fetchSafetyAuditTemplates,
  updateEquipmentById,
  equipmentUpdateStatus,
  fetchCustomTrainingTemplates,
  updateDocument,
  createDocument,
  fetchDocumentsByType,
  deleteDocument,
  fetchDocumentFoldersByType
} from '../../api/v4';

import { addMessage } from '../../actions/messages';
import { loadTask, setActiveTaskRequest } from '../../actions/tasks';
import { formatFiles } from '../../utils/attachmentSelector';
import AttachmentUploadCard from '../../components/AttachmentUploadCard';
import Card from '../../components/Card';
import EquipmentDocumentsList from '../../components/EquipmentCards/EquipmentDocumentsList';
import EquipmentSAList from '../../components/EquipmentCards/EquipmentSAList';
import EquipmentTaskList from '../../components/EquipmentCards/EquipmentTaskList';
import EquipmentTrainingList from '../../components/EquipmentCards/EquipmentTrainingList';
import { SaveCancelFooter } from '../../components/Footer';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import HierarchySelector from '../../components/HierarchySelector';
import { Dropdown, TwoColumn, Textbox, Button } from '../../components/inputs';
import LeftNav from '../../components/LeftNav';
import Modal from '../../components/Modal';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import StageSelector from '../../components/StageSelector';
import TypeSelection from '../../components/TypeSelection';

import history from '../../history';

import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject,
  getAllLocations
} from '../../selectors/company';

import styles from './index.module.scss';
import EquipmentDocumentForm from '../../forms/EquipmentDocumentForm';
import moment from 'moment';
import { clearUploadedAttachments } from '../../actions/attachments';

export default function EquipmentContainer() {
  const { equipmentId } = useParams();
  const dispatch = useDispatch();

  const activeCompany = useSelector(getActiveCompany);
  const activeLocation = useSelector(getActiveLocationId);
  const activeProject = useSelector(getActiveProject);
  const allGroups = useSelector(getAllLocations);
  const addedAttachments = useSelector(getAddedAttachmentsSelector);

  // navigation states
  const [isCreating, setCreating] = useState(true);
  const [isEditing, setEditing] = useState(false);
  const [hasUnsavedChanges, setUnsaved] = useState(false);
  const [openUnsavedChangesModal, setUnsavedModal] = useState(false);
  const [openDeleteEquipmentModal, setDeleteModal] = useState(false);
  const [safetyProcedureModal, setProcedureModal] = useState(false);
  const [updatingSI, setUpdatingSI] = useState(false);
  const [view, setView] = useState('Safety Audits');

  //fetched data
  const [saTemplateDropdown, setSATemplateDropdown] = useState([]);
  const [trainingTemplateDropdown, setTrainingTemplateDropdown] = useState([]);
  const [companyDocuments, setCompanyDocuments] = useState([]);
  const [documentFolders, setDocumentFolders] = useState([]);
  const [activeSI, setActiveSI] = useState(null);

  // stored attachments prior to creating
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [attachmentsToUpload, setAttachmentsToUpload] = useState([]);

  // copy to be edited
  const [activeEquipment, updateEquipmentState] = useState({});
  const [editingEquipment, setEditingEquipment] = useState({
    groupId: null,
    projectId: null,
    title: '',
    location: '',
    serial: '',
    companyThatMade: '',
    model: '',
    status: 1,
    linkedSafetyAuditTemplates: [],
    linkedDocuments: [],
    linkedTrainingTemplates: []
  });
  const {
    groupId,
    projectId,
    title,
    location,
    serial,
    companyThatMade,
    model,
    status,
    linkedSafetyAuditTemplates,
    linkedDocuments,
    linkedTrainingTemplates
  } = editingEquipment;

  const emptySI = {
    label: '',
    documentFolderId: '',
    expires: '',
    documentData: {
      description: '',
      currentPriority: '',
      nextSteps: '',
      notes: '',
      questionsPhone: '',
      questionsEmail: '',
      comments: ''
    }
  };
  const [SIForm, setSIForm] = useState(emptySI);
  const formGroup = activeCompany.groups.find(r => r._id === groupId);
  const formProject = formGroup?.projects.find(r => r._id === projectId);

  const resetSIForm = () => {
    setActiveSI(null);
    setSIForm(emptySI);
    dispatch(clearUploadedAttachments());
  };

  const handleAnswer = update => {
    setEditingEquipment({ ...editingEquipment, ...update });
    setUnsaved(true);
  };

  const findGroupName = groupId =>
    allGroups?.find(g => g._id === groupId)?.name;

  const findProjectName = (groupId, projectId) =>
    allGroups
      ?.find(g => g._id === groupId)
      ?.projects.find(p => p._id === projectId)?.name;

  const canSubmit = () =>
    (groupId || activeLocation._id) &&
    title.trim().length > 2 &&
    location.trim().length > 2;

  const handleBack = () => {
    if (hasUnsavedChanges) setUnsavedModal(true);
    else history.push('/app/equipmentList');
  };

  const setActiveEquipment = update => {
    updateEquipmentState({
      ...update.equipment,
      attachments: update?.attachments || activeEquipment.attachments || []
    });
    if (!isEditing)
      setEditingEquipment({ ...editingEquipment, ...update.equipment });
  };

  const createOrUpdateEquipment = () => {
    try {
      let id = activeEquipment?._id;
      if (isCreating) {
        if (!groupId)
          setEditingEquipment({
            ...editingEquipment,
            groupId: activeLocation._id
          });
        createEquipment({
          ...editingEquipment,
          companyId: activeCompany._id
        }).then(response => {
          id = response._id;
          if (filesToUpload?.length > 0) {
            let data = formatFiles(filesToUpload);

            uploadAttachment(data, {
              ownerId: id,
              ownerType: 'equipment',
              isSignature: false,
              isPhotoEvidence: false,
              photoType: ''
            }).then(r => {
              setAttachmentsToUpload([]);
              setFilesToUpload([]);
              history.push('/app/equipmentContainer/' + id);
            });
          } else {
            history.push('/app/equipmentContainer/' + id);
          }
        });
      } else {
        updateEquipmentById(editingEquipment).then(r => {
          fetchEquipmentById(r.equipment._id).then(r2 =>
            setActiveEquipment(r2)
          );
        });
      }
    } catch (e) {
      dispatch(addMessage({ error: true, message: e }));
    }
    setEditing(false);
    setUnsaved(false);
  };

  const updateEquipmentStage = status =>
    equipmentUpdateStatus({
      _id: activeEquipment._id,
      status
    }).then(r => {
      setActiveEquipment(r);
    });

  const addAttachments = async attachments => {
    try {
      let data = formatFiles(attachments);
      uploadAttachment(data, {
        ownerId: activeEquipment._id ?? 'pending',
        ownerType: 'equipment',
        isSignature: false,
        photoType: ''
      }).then(response => {
        updateEquipmentState({
          ...activeEquipment,
          attachments: activeEquipment.attachments.concat(response)
        });
        dispatch(
          addMessage({
            error: false,
            message: 'Attachment Successfully Uploaded'
          })
        );
      });
    } catch (e) {
      dispatch(addMessage({ error: true, message: e }));
    }
  };

  const handleDeleteAttachment = attachment => {
    try {
      deleteAttachment(attachment._id).then(
        fetchEquipmentById(activeEquipment._id).then(response =>
          updateEquipmentState({
            ...activeEquipment,
            attachments: response.attachments
          })
        )
      );
    } catch (e) {
      dispatch(addMessage({ error: true, message: e }));
    }
  };
  const updateDocumentAttachments = r => {
    if (r.attachments.length)
      for (let i = 0; i < r.attachments.length; i++) {
        const att = r.attachments[i];
        updateAttachment({ ...att, ownerId: r._id }).then(() =>
          updateDocumentList()
        );
      }
    else updateDocumentList();
  };

  const createSafetyDocument = () => {
    const values = {
      ...SIForm,
      expires: SIForm.expires
        ? moment(SIForm.expires).format('MM/DD/YYYY')
        : '',
      documentType: 'SafetyInstruction',
      companyId: activeCompany._id,
      groupIds: formGroup,
      projectIds: formProject,
      attachments: addedAttachments
    };

    if (updatingSI && activeSI) {
      updateDocument(values).then(r => {
        updateDocumentAttachments(r);
      });
    } else {
      if (isCreating || isEditing)
        createDocument({
          ...values,
          documentType: 'SafetyInstruction'
        }).then(r => {
          linkedDocuments.push(r);
          setUnsaved(true);
          updateDocumentAttachments(r);
        });
      else
        createDocument({
          ...values,
          ownerType: 'equipment',
          ownerId: equipmentId,
          documentType: 'SafetyInstruction'
        }).then(r => {
          linkedDocuments.push(r);
          fetchEquipmentById(activeEquipment._id).then(setActiveEquipment);
          updateDocumentAttachments(r);
        });
    }
    resetSIForm();
    setProcedureModal(false);
  };

  const updateDocumentList = () => {
    fetchDocumentsByType('SafetyInstruction').then(r => {
      setCompanyDocuments(r);
    });
  };

  useEffect(() => {
    fetchSafetyAuditTemplates().then(response => {
      setSATemplateDropdown(
        response.map(template => {
          return { label: template.title, _id: template._id };
        })
      );
    });
    fetchCustomTrainingTemplates().then(response => {
      setTrainingTemplateDropdown(
        response.map(tt => ({ label: tt.title, _id: tt._id }))
      );
    });
    fetchDocumentsByType('SafetyInstruction').then(r => {
      if (companyDocuments.length !== r.length) setCompanyDocuments(r);
    });
    fetchDocumentFoldersByType('SafetyInstruction').then(setDocumentFolders);

    if (equipmentId) {
      fetchEquipmentById(equipmentId).then(r => {
        setActiveEquipment(r);
        setCreating(false);
      });
    } else {
      setEditingEquipment({
        ...editingEquipment,
        groupId: activeLocation?._id ?? groupId,
        projectId: activeProject?._id ?? projectId
      });
    }

    // eslint wants to include setState functions (which triggers a loop)
    // eslint-disable-next-line
  }, [equipmentId, activeLocation, activeProject, companyDocuments]);

  const header = (
    <Header
      title={
        !isEditing && !isCreating
          ? title
          : isCreating
          ? 'Add Equipment'
          : `Edit ${title}`
      }
      needsSaved={hasUnsavedChanges}
      clickBack={handleBack}
      center={
        !isCreating &&
        !isEditing && (
          <StageSelector
            label="Status"
            options={EQUIPMENT_STATUS_DROPDOWN}
            currentValue={status}
            onChange={updateEquipmentStage}
            testID="stageSelector"
          />
        )
      }
      rightButtons={
        (isEditing && {
          text: 'Delete Equipment',
          color: 'red',
          onClick: () => setDeleteModal(true)
        }) ||
        (!isCreating &&
          !isEditing && {
            text: 'Edit',
            color: 'blue',
            onClick: () => setEditing(true)
          })
      }
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonClick={createOrUpdateEquipment}
      saveButtonDisabled={!canSubmit() || !hasUnsavedChanges}
      cancelButtonClick={() =>
        isEditing && hasUnsavedChanges
          ? setUnsavedModal(true)
          : isEditing
          ? setEditing(false)
          : history.goBack()
      }
      type="textarea"
      editing={isEditing}
    />
  );

  const disabled = !isEditing && !isCreating;

  const leftNavEditing = [
    { label: 'Equipment Information', id: 'equipmentEditing' },
    { label: 'Attachments', id: 'attachments' },
    { label: 'Documents', id: 'documents' },
    { label: 'Safety Audits', id: 'safetyAudits' },
    { label: 'Trainings', id: 'trainings' }
  ];
  const leftEditing = <LeftNav items={leftNavEditing} />;

  const leftNav = [
    { label: 'Equipment Information', id: 'equipmentInfo' },
    { label: 'Attachments', id: 'attachments' },
    { label: 'Assignables', id: 'assignables' }
  ];
  const left = <LeftNav items={leftNav} />;

  return (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        Left={isCreating || isEditing ? leftEditing : left}
        showFooter={isCreating || isEditing}
      >
        {isCreating || isEditing ? (
          <>
            <Card
              name="equipmentEditing"
              title="Equipment Information"
              showHeader
            >
              <TwoColumn>
                <Textbox
                  currentValue={title}
                  fieldLabel="Title"
                  onChange={v => handleAnswer({ title: v })}
                  placeholder="Type a clear and concise title."
                  isRequired={true}
                  disabled={disabled}
                  warning={
                    title && title.length < 3
                      ? 'Title must be 3 or more characters'
                      : null
                  }
                  touched={true}
                />
                <Textbox
                  currentValue={location}
                  fieldLabel="Location"
                  onChange={v => handleAnswer({ location: v })}
                  placeholder="Type here"
                  isRequired={true}
                  disabled={disabled}
                  warning={
                    location && location.length < 3
                      ? 'Location must be 3 or more characters'
                      : null
                  }
                  touched={true}
                />
              </TwoColumn>
              <HierarchySelector
                onGroupChange={v =>
                  handleAnswer({ groupId: v, projectId: null })
                }
                groupId={groupId}
                groupDisabled={disabled}
                onProjectChange={v =>
                  handleAnswer({ projectId: v.length ? v : null })
                }
                projectId={projectId}
                projectDisabled={disabled}
                groupWarning={
                  editingEquipment.groupId !== activeEquipment.groupId &&
                  !isCreating
                    ? "Changing an equipment's group will generate a new QR code"
                    : null
                }
              />
              <TwoColumn>
                <Textbox
                  currentValue={serial}
                  fieldLabel="Serial Number"
                  onChange={v => handleAnswer({ serial: v })}
                  placeholder="Type here"
                  disabled={disabled}
                />
                <Textbox
                  currentValue={companyThatMade}
                  fieldLabel="Company"
                  onChange={v => handleAnswer({ companyThatMade: v })}
                  placeholder="Type here"
                  disabled={disabled}
                />
                <Textbox
                  currentValue={model}
                  fieldLabel="Model"
                  onChange={v => handleAnswer({ model: v })}
                  placeholder="Type here"
                  disabled={disabled}
                />
                <Dropdown
                  options={EQUIPMENT_STATUS_DROPDOWN}
                  fieldLabel="Status"
                  currentValue={status}
                  onChange={v => handleAnswer({ status: v })}
                  disabled={disabled}
                />
              </TwoColumn>
            </Card>{' '}
          </>
        ) : (
          <div id="equipmentInfo" className={styles.infoAndQrFlexbox}>
            <Card
              title="Equipment Information"
              className={styles.equipmentInfo}
              showHeader
            >
              <div className={styles.infoCard}>
                <div className={styles.infoLabels}>
                  {[
                    'Title',
                    'Location',
                    'Group/Est.',
                    'Area',
                    'Serial Number',
                    'Company',
                    'Model',
                    'Status'
                  ].map(label => (
                    <>
                      {`${label}:`}
                      <br />
                    </>
                  ))}
                </div>
                <div className={styles.info}>
                  {[
                    activeEquipment?.title,
                    activeEquipment?.location,
                    findGroupName(activeEquipment?.groupId) || '',
                    findProjectName(
                      activeEquipment?.groupId,
                      activeEquipment?.projectId
                    ) || '',
                    activeEquipment?.serial || '',
                    activeEquipment?.companyThatMade || '',
                    activeEquipment?.model || '',
                    EQUIPMENT_STATUS_DROPDOWN.filter(
                      v => v.value === activeEquipment.status
                    )[0]?.label || 'N/A'
                  ].map(v => (
                    <>
                      {v}
                      <br />
                    </>
                  ))}
                </div>
              </div>
            </Card>
            <Card
              name="qrCode"
              title="QR Code"
              className={styles.qrCodeCard}
              showHeader
            >
              <img
                src={'https://' + activeEquipment.qrCodeImageUrl}
                className={styles.qrCode}
                alt={'Error Displaying QR Code'}
              />
              <Button
                color="blue"
                text="Download"
                onClick={() =>
                  window.open(
                    `https://${activeEquipment.qrCodePdfUrl}`,
                    '_blank'
                  )
                }
              />
            </Card>
          </div>
        )}
        <AttachmentUploadCard
          name="attachments"
          addAttachments={addAttachments}
          deleteAttachment={handleDeleteAttachment}
          isCreating={isCreating}
          isEditing={isEditing}
          addedAttachments={activeEquipment.attachments || []}
          attachmentsToUpload={attachmentsToUpload}
          handleAnswer={(currentAttachments, currentFiles) => {
            setAttachmentsToUpload(currentAttachments);
            setFilesToUpload(currentFiles);
            setUnsaved(true);
          }}
          ownerType="equipment"
        />
        {isCreating || isEditing ? (
          <>
            <Card
              name="documents"
              title="Documents"
              showHeader
              rightButton={
                <Button
                  onClick={() => {
                    setProcedureModal(true);
                    setUpdatingSI(true);
                  }}
                  type="button"
                  text="Upload Document"
                  color="blue"
                  disabled={!canSubmit() && isCreating}
                />
              }
            >
              <Dropdown
                multi
                placeholder="Choose one or more"
                options={companyDocuments}
                disabled={!canSubmit() && isCreating}
                currentValue={linkedDocuments}
                onChange={v => handleAnswer({ linkedDocuments: v })}
                searchable
                selectButtons
                valueKey={'_id'}
                testID="Documents Dropdown"
              />
            </Card>
            <Card name="safetyAudits" title="Safety Audits" showHeader>
              <Dropdown
                multi
                placeholder="Choose one or more"
                options={saTemplateDropdown}
                disabled={!canSubmit() && isCreating}
                currentValue={linkedSafetyAuditTemplates}
                onChange={v => handleAnswer({ linkedSafetyAuditTemplates: v })}
                searchable
                selectButtons
                valueKey={'_id'}
                testID="Safety Audits Dropdown"
              />
            </Card>
            <Card name="trainings" title="Trainings" showHeader>
              <Dropdown
                multi
                placeholder="Choose one or more"
                options={trainingTemplateDropdown}
                disabled={!canSubmit() && isCreating}
                currentValue={linkedTrainingTemplates}
                onChange={v => handleAnswer({ linkedTrainingTemplates: v })}
                searchable
                selectButtons
                valueKey={'_id'}
                testID="Trainings Dropdown"
              />
            </Card>
          </>
        ) : (
          <div
            className={styles.AssignablesList}
            id="assignables"
            title="Assignables"
          >
            <TypeSelection
              className={styles.typeSelection}
              selected={view}
              selectedArray={[
                'Safety Audits',
                'Trainings',
                'Tasks',
                'Documents'
              ]}
              onClick={setView}
            />
            {view === 'Tasks' && (
              <EquipmentTaskList
                setActiveTask={task => dispatch(setActiveTaskRequest(task))}
                loadTask={t => dispatch(loadTask(t))}
                activeEquipment={activeEquipment}
                cardClassName={styles.equipmentAttachmentLists}
              />
            )}
            {view === 'Documents' && (
              <EquipmentDocumentsList
                data={companyDocuments.filter(r => {
                  return (isEditing
                    ? linkedDocuments
                    : activeEquipment.linkedDocuments
                  )
                    .map(doc => doc._id)
                    .includes(r._id);
                })}
                cardClassName={styles.equipmentAttachmentLists}
                createDocument={() => {
                  resetSIForm();
                  setUpdatingSI(true);
                  setProcedureModal(true);
                }}
                setDocument={row => {
                  setSIForm(row);
                  setActiveSI(row);
                  setProcedureModal(true);
                }}
              />
            )}
            {view === 'Safety Audits' && (
              <EquipmentSAList
                activeEquipment={activeEquipment}
                cardClassName={styles.equipmentAttachmentLists}
              />
            )}
            {view === 'Trainings' && (
              <EquipmentTrainingList
                activeEquipment={activeEquipment}
                cardClassName={styles.equipmentAttachmentLists}
              />
            )}
          </div>
        )}
      </HeaderAndFooter>

      <SaveChangesModal
        isOpen={openUnsavedChangesModal}
        onRequestClose={() => setUnsavedModal(false)}
        submitActions={history.goBack}
        savingWhat="a page"
      />
      <Modal
        submitActions={createSafetyDocument}
        disableSubmit={!SIForm.label}
        hideButtons={!updatingSI}
        title={
          (activeSI ? (updatingSI ? 'Update' : 'View') : 'Add') +
          ' Safety Procedure'
        }
        titleClassName="greenHeader"
        wide
        submitButtonColor="green"
        testID="modal"
        isOpen={safetyProcedureModal}
        onRequestClose={() => {
          resetSIForm();
          setProcedureModal(false);
          setUpdatingSI(false);
        }}
      >
        <EquipmentDocumentForm
          activeCompany={activeCompany}
          onDelete={value => {
            deleteDocument(value).then(() => {
              updateDocumentList();
              resetSIForm();
              setProcedureModal(false);
            });
          }}
          documentFolders={documentFolders}
          activeSI={activeSI}
          siForm={SIForm}
          setSIForm={setSIForm}
          addedAttachments={addedAttachments}
          updating={updatingSI}
          setUpdating={setUpdatingSI}
        />
      </Modal>

      <Modal
        title="Delete Equipment"
        titleClassName="redHeader"
        isOpen={openDeleteEquipmentModal}
        submitButtonColor="red"
        submitButtonText="Delete Equipment"
        onRequestClose={() => setDeleteModal(false)}
        submitActions={() =>
          deleteEquipment(activeEquipment).then(() => {
            history.push('/app/equipmentList/');
            dispatch(
              addMessage({ error: false, message: 'Deleted Successfully' })
            );
          })
        }
      >
        <div className="reportSectionContainer-emailAssigneeText">
          <div style={{ color: '#c74846', fontWeight: 'bold' }}>
            Deleting an Equipment is permanent. No one will be able to view or
            edit this specific Equipment again. This will delete any assoicated
            tasks, audits, and trainings.
          </div>
          A new Equipment that is similar or identical can still be created.
        </div>
      </Modal>
    </>
  );
}
