import React, { Component } from 'react';
import history from '../../history';

import UserBadge from '../UserBadge';
import { TwoColumn } from '../inputs';
import Button from '../inputs/Button';
import Modal from '../Modal';
import { noBubble } from '../../utils/events';

import './index.css';

export const getClassName = (isLongTerm, user) => {
  let classes = '';
  classes = isLongTerm ? 'longTermLocation' : 'location';

  classes +=
    user.accessLevel === 400 && user.projectIds && user.projectIds.length > 0
      ? ' location--noAccess'
      : '';

  return classes;
};

export default class CompanyCard extends Component {
  state = {
    modalOpen: false
  };

  handleDivClick = async (
    event,
    company,
    location,
    setActiveCompany,
    setActiveLocation,
    allLocations,
    setActiveGroups,
    setActiveProject
  ) => {
    noBubble(event);
    setActiveCompany(company);
    setActiveLocation({
      ...location
    });
    setActiveProject();
    allLocations(company.allLocations);
    setActiveGroups(company.allLocations);
    history.push('/app/dashboard');
  };

  openModal = e => {
    noBubble(e);
    this.setState({ modalOpen: true });
  };

  closeModal = e => {
    noBubble(e);
    this.setState({ modalOpen: false });
  };

  render() {
    const {
      company,
      location,
      setActiveCompany,
      setActiveLocation,
      allLocations,
      setActiveGroups,
      openProjectModal,
      openProjectDropdown,
      setActiveProject,
      handleProjectSelection,
      user,
      assignIncidentOwner
    } = this.props;

    const { modalOpen } = this.state;
    const visibleProjects = location?.projects?.some(
      project => !project.isHidden
    );
    return (
      <>
        <div
          className={`location-button ${getClassName(
            location.isSeparateEstablishment &&
              location.operationalForYearOrLonger,
            user
          )}`}
          onClick={e =>
            this.handleDivClick(
              e,
              company,
              location,
              setActiveCompany,
              setActiveLocation,
              allLocations,
              setActiveGroups,
              setActiveProject
            )
          }
        >
          <UserBadge
            showImage
            hoverName
            userId={location?.defaultIncidentOwner?.userId}
            size="medium"
            className={
              location.isSeparateEstablishment &&
              location.operationalForYearOrLonger
                ? 'locationIncidentOwnerPictureWhite'
                : 'locationIncidentOwnerPictureBlue'
            }
            onClick={e => {
              noBubble(e);
              assignIncidentOwner(
                location?.defaultIncidentOwner?.userId || undefined
              );
            }}
          />
          <div className="assignIncidentOwner">Assign Incident Owner</div>
          <div className="locationName">{location.name}</div>
          {visibleProjects ? (
            <div
              className={
                location.isSeparateEstablishment &&
                location.operationalForYearOrLonger
                  ? 'location-existingProjects white'
                  : 'location-existingProjects blue'
              }
              onClick={e => this.openModal(e)}
            >
              <div
                className={
                  location.isSeparateEstablishment &&
                  location.operationalForYearOrLonger
                    ? 'location-existingProjects--blueWords'
                    : 'location-existingProjects--whiteWords'
                }
              >
                Areas
              </div>
            </div>
          ) : (
            <div>
              {(user.accessLevel === 500 || user.accessLevel === 900) && (
                <div
                  className="location-newProject"
                  onMouseDown={noBubble(openProjectModal)}
                >
                  <div
                    className={
                      location.isSeparateEstablishment &&
                      location.operationalForYearOrLonger
                        ? 'location-newProject--whiteWords'
                        : 'location-newProject--blueWords'
                    }
                  >
                    Add First Area
                  </div>
                  <img
                    src={
                      location.isSeparateEstablishment &&
                      location.operationalForYearOrLonger
                        ? require('../../assets/images/newProject-white.png')
                        : require('../../assets/images/newProject-blue.png')
                    }
                    alt="boxWarning"
                    className="location-newProject--addImage"
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <Modal
          title={`${location.name}'s Areas`}
          titleClassName="blueHeader"
          isOpen={modalOpen}
          onRequestClose={e => this.closeModal(e)}
          hideButtons={true}
        >
          <div className="projectModal">
            <TwoColumn className="projectButtons">
              {location?.projects?.map?.((project, index) => {
                if (project.isHidden) return <></>;
                return (
                  <Button
                    key={index}
                    className="projectButton"
                    color="blueOutline"
                    text={project.name}
                    onClick={async () => {
                      await openProjectDropdown();
                      handleProjectSelection(project);
                    }}
                  />
                );
              })}
              {(user.accessLevel === 500 || user.accessLevel === 900) && (
                <Button
                  color="grey"
                  onClick={openProjectModal}
                  text="Add New Area"
                />
              )}
            </TwoColumn>
          </div>
        </Modal>
      </>
    );
  }
}
