import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import history from '../../history';
import * as apiV4 from '../../api/v4';
import { updateUserRequest } from '../../actions/user';
import { getLoggedInUser, getAccessLevel } from '../../selectors/users';
import { SaveCancelFooter } from '../../components/Footer';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Notifications from './Notifications';
import UserProfile from './UserProfile';

export default function Profile() {
  let activeUser = useSelector(getLoggedInUser);
  let accessLevel = useSelector(getAccessLevel);
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [username, setUsername] = useState();
  const [userProfileImage, setUserProfileImage] = useState();
  const [targetImage, setTargetImage] = useState();
  const [notifications, setNotifications] = useState({
    emailNotificationsActive: false
  });
  const [emailNotificationsActive, setEmailNotificationsActive] = useState(
    false
  );
  const [firstNameError, setFirstNameError] = useState();
  const [lastNameError, setLastNameError] = useState();
  useEffect(() => {
    apiV4
      .getUserNotifications(activeUser._id)
      .then(response => {
        setEmailNotificationsActive(response.emailNotificationsActive);
        setNotifications(response);
      })
      .catch(e => console.error(e));
    setFirstName(activeUser.firstName);
    setLastName(activeUser.lastName);
    setEmail(activeUser.email);
    setPhoneNumber(activeUser.phoneNumber);
    setUsername(activeUser.username);
  }, [
    activeUser._id,
    activeUser.email,
    activeUser.firstName,
    activeUser.lastName,
    activeUser.phoneNumber,
    activeUser.username
  ]);

  const handleNotificationChange = (values, options, label) => {
    let userNotifications = { ...notifications };
    if (values === 'clearAll') {
      let newAnswer = userNotifications[label].filter(
        v => !options.includes(v)
      );
      userNotifications[label] = newAnswer;
    } else if (values === 'selectAll') {
      let answer = [];
      options.forEach(option => {
        answer.push(option.value);
      });
      userNotifications[label] = answer;
    } else if (label === 'groups' || label === 'projects') {
      let answer = [];
      options.forEach(option => {
        if (values?.includes(option.value)) {
          answer.push(option.value);
        }
      });
      userNotifications[label] = answer;
    } else {
      //eslint-disable-next-line no-unused-expressions
      options?.forEach(notification => {
        userNotifications[label][notification.value] =
          values.findIndex(value => value === notification.value) > -1;
      });
    }
    setNotifications(userNotifications);
  };

  const submit = () => {
    let tempEdit = false;
    if (
      /[^\0-\x20"',\-.0-9\x41-\x5A\x60-\x7A~\x7F\xC0-\xD6\xD8-\xF6\xF8-\u017E]+ */i.test(
        firstName
      )
    ) {
      setFirstNameError('Only common name characters');
      tempEdit = true;
    } else {
      setFirstNameError();
    }
    if (
      /[^\0-\x20"',\-.0-9\x41-\x5A\x60-\x7A~\x7F\xC0-\xD6\xD8-\xF6\xF8-\u017E]+ */i.test(
        lastName
      )
    ) {
      setLastNameError('Only common name characters');
      tempEdit = true;
    } else {
      setLastNameError();
    }
    if (tempEdit) return;

    if (notifications) {
      notifications.emailNotificationsActive = emailNotificationsActive;
    }

    let data = null;
    if (targetImage) {
      const files = targetImage;

      data = new FormData();

      Object.entries(files).forEach(async file => {
        data.append('attachments', file[1], file[1].name);
      });
    }

    setIsEditing(false);
    dispatch(
      updateUserRequest({
        notifications,
        firstName,
        lastName,
        email,
        phoneNumber,
        username,
        data
      })
    );
  };

  const cancel = () => {
    setFirstName(activeUser.firstName);
    setLastName(activeUser.lastName);
    setEmail(activeUser.email);
    setPhoneNumber(activeUser.phoneNumber);
    setUsername(activeUser.username);
    setIsEditing(false);
    setFirstNameError();
    setLastNameError();
  };

  const updateUserProfileImage = e => {
    const reader = new FileReader();
    setTargetImage(e.target.files);

    reader.readAsDataURL(e.target.files[0]);
    const base64data = new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });

    base64data.then(response => {
      setUserProfileImage(response);
    });
  };

  return (
    <HeaderAndFooter
      Header={
        <Header
          title="User Profile"
          clickBack={() => history.goBack()}
          rightButtons={{
            text: 'Edit User Info',
            color: 'blue',
            visible: !isEditing,
            onClick: () => setIsEditing(true)
          }}
        />
      }
      showFooter={isEditing}
      Footer={
        <SaveCancelFooter
          editing
          saveButtonClick={submit}
          cancelButtonClick={cancel}
        />
      }
    >
      <UserProfile
        isEditing={isEditing}
        firstName={firstName}
        lastName={lastName}
        email={email}
        phoneNumber={phoneNumber}
        username={username}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setEmail={setEmail}
        setPhoneNumber={setPhoneNumber}
        setUsername={setUsername}
        updateUserProfileImage={updateUserProfileImage}
        userProfileImage={userProfileImage}
        firstNameError={firstNameError}
        lastNameError={lastNameError}
        setFirstNameError={setFirstNameError}
      />
      {accessLevel > 100 && (
        <Notifications
          isEditing={isEditing}
          emailNotificationsActive={emailNotificationsActive}
          notifications={notifications}
          setEmailNotificationsActive={setEmailNotificationsActive}
          setNotifications={handleNotificationChange}
        />
      )}
    </HeaderAndFooter>
  );
}
