import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import localforage from 'localforage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
// import LogRocket from 'logrocket';

import rootReducer from '../reducers/index';
// import storage from 'redux-persist/lib/storage';
import rootSaga from '../sagas/index';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage: localforage,
  stateReconciler: hardSet,
  blacklist: ['loading', 'messages']
};

const pReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  pReducer,
  composeEnhancers(
    applyMiddleware(sagaMiddleware /*, LogRocket.reduxMiddleware()*/)
  )
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default store;
