import { all, call, put, select } from 'redux-saga/effects';
import LogRocket from 'logrocket';

import history from '../history';
import { addUploadedAttachmentsResponse } from '../actions/attachments';
import {
  authSuccess,
  loginFailure,
  registrationFailure,
  newUserRegistrationResponse,
  setSecurityQuestions,
  signOut
} from '../actions/auth';
import {
  fetchUserCompaniesResponse,
  setActiveCompany,
  setActiveLocation
} from '../actions/company';
import {
  setActiveSafetyInstruction,
  setActiveSds,
  setActiveQualification
} from '../actions/documents';
import {
  fetchIncidentBasicsResponse,
  setActiveIncidentResponse
} from '../actions/incidents';
import { addMessage } from '../actions/messages';
import { setActiveEmployeeResponse } from '../actions/personnel';
import { loadUserResponse } from '../actions/user';
import { createReportResponse } from '../actions/reports';
import { setActiveSafetyWalk } from '../actions/safetyWalks';
import { loadTask } from '../actions/tasks';
import { fetchUserProfile } from '../actions/user';
import { getLoggedInUser } from '../selectors/users';
import reportTemplateMapper from '../utils/reportTemplateMapper';
import {
  INVESTIGATION_COMPONENTS,
  ACTION_COMPONENTS,
  CLOSURE_COMPONENTS
} from '../constants/constants';

export function* logInUser(api, apiV4, { payload }) {
  try {
    const referrer = payload.referrer;
    delete payload.referrer;

    const responseData = payload.isMicrosoft
      ? yield call(api.userMicrosoftLogin, { internal: true })
      : yield call(api.fetchUserAccount, payload);
    // localStorage.clear();
    sessionStorage.setItem('token', responseData.token);
    sessionStorage.setItem('user', JSON.stringify(responseData.user));
    const response = responseData.user;
    response.securityQuestions = responseData.securityQuestions;

    yield put(authSuccess());
    yield put(fetchUserProfile(response));
    try {
      LogRocket.identify(response.username, {
        ...response
      });
    } catch (err) {
      console.log(err);
    }
    let companies = responseData.companies;

    companies.map(res => {
      res.allLocations = res.groups;
      res.locations = res.groups;
      return res;
    });

    const companyNames = companies.map(company => company.name);
    localStorage.setItem('companies', companyNames.join(', '));

    yield put(fetchUserCompaniesResponse(companies));
    yield put(setActiveCompany(companies[0]));
    if (responseData.user.accessLevel !== 900)
      yield put(setActiveLocation(companies[0]?.groups[0]));

    if (responseData.user.needsToUpdatePassword) {
      history.push('/home');
    } else {
      yield handleEmailLinks(apiV4, referrer, companies);
    }
  } catch (e) {
    yield put(loginFailure(e));
    yield put(addMessage({ error: true, message: `${e}` }));
  }
}

export function* createUserSecurityQuestions(api, { payload }) {
  try {
    const responseData = yield call(api.createUserSecurityQuestions, payload);
    yield put(addMessage({ message: `${responseData.data}` }));
  } catch (e) {
    yield put(addMessage({ error: true, message: `${e.data}` }));
  }
}

export function* validateSecurityQuestions(api, { payload }) {
  try {
    const responseData = yield call(api.validateSecurityQuestions, payload);
    // localStorage.clear();
    sessionStorage.setItem('token', responseData.token);

    const user = responseData.user;
    user.securityQuestions = responseData.securityQuestions;
    yield put(fetchUserProfile(user));

    let companies = responseData.companies;

    companies.map(res => {
      res.allLocations = res.groups;
      res.locations = res.groups;
      return res;
    });

    const companyNames = companies.map(company => company.name);
    localStorage.setItem('companies', companyNames.join(', '));

    yield put(fetchUserCompaniesResponse(companies));

    history.push('/home');
  } catch (e) {
    yield put(signOut());
    yield put(addMessage({ error: true, message: e?.data ?? e }));
    history.push('/login');
  }
}

export function* loginMicrosoftUser(api, apiV4, { payload }) {
  try {
    const referrer = payload.referrer;
    delete payload.referrer;

    const responseData = yield call(api.userMicrosoftLogin, payload);

    sessionStorage.setItem('token', responseData.token);
    sessionStorage.setItem('user', JSON.stringify(responseData.user));
    const response = responseData.user;
    response.securityQuestions = responseData.securityQuestions;

    yield put(authSuccess());
    yield put(fetchUserProfile(response));
    try {
      LogRocket.identify(response.username, {
        ...response
      });
    } catch (err) {
      console.log(err);
    }
    let companies = responseData.companies;

    companies.map(res => {
      res.allLocations = res.groups;
      res.locations = res.groups;
      return res;
    });

    const companyNames = companies.map(company => company.name);
    localStorage.setItem('companies', companyNames.join(', '));

    yield put(fetchUserCompaniesResponse(companies));
    yield put(setActiveCompany(companies[0]));
    if (responseData.user.accessLevel !== 900)
      yield put(setActiveLocation(companies[0]?.groups[0]));

    yield handleEmailLinks(apiV4, referrer, companies);
  } catch (e) {
    yield put(loginFailure(e));
    yield put(addMessage({ error: true, message: `${e}` }));
  }
}

export function* forgotPasswordReset(api, { payload }) {
  try {
    const response = yield call(api.forgotPasswordReset, payload);
    if (Array.isArray(response)) {
      yield put(setSecurityQuestions(response));
      history.push('/login');
    } else {
      yield put(
        addMessage({
          error: false,
          message: 'Email has been successfully sent!'
        })
      );
      history.push('/login');
    }
  } catch (e) {
    yield put(addMessage({ error: true, message: `${e}` }));
  }
}

export function* newUserRegistration(api, { payload }) {
  try {
    yield put(signOut());
    const response = yield call(api.createUserAccount, {
      ...payload,
      accessLevel: 900,
      freeUser: true
    });

    // localStorage.clear();
    sessionStorage.setItem('token', response.token);
    yield put(fetchUserProfile(response._doc));
    yield put(newUserRegistrationResponse(true));
    history.push('/home');
  } catch (e) {
    yield put(registrationFailure(e));
    yield put(addMessage({ error: true, message: `${e}` }));
  }
}

export function* updateUser(api, apiV4, { payload }) {
  try {
    const loggedInUser = yield select(getLoggedInUser);

    if (payload.data) {
      let profilePictureUrl = yield call(api.uploadAttachment, payload.data, {
        ownerId: loggedInUser._id,
        ownerType: 'userProfile'
      });

      payload.profilePictureUrl = profilePictureUrl[0].source_url;
    }

    payload._id = loggedInUser._id;
    delete payload.data;
    if (payload.notifications === '') delete payload.notifications;
    delete payload?.notifications?.expoPushNotificationId;

    let response = yield call(apiV4.updateEmployee, payload);

    yield put(setActiveEmployeeResponse(response));
    yield put(fetchUserProfile(response));
    yield put(loadUserResponse(response));

    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e?.data ?? e }));
  }
}

export function* updatePassword(api, { payload }) {
  try {
    const activeUser = yield select(getLoggedInUser);
    const neededToUpdatePassword = activeUser.needsToUpdatePassword;

    let response = yield call(api.updatePassword, activeUser._id, payload);
    if (response === '' && neededToUpdatePassword) {
      activeUser.needsToUpdatePassword = false;
    }

    yield put(fetchUserProfile(activeUser));
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

export function* updateDefaultPassword(api, { payload }) {
  try {
    yield call(api.updateDefaultPassword, payload);
    yield put(addMessage({ error: false, message: 'Saved Successfully' }));
  } catch (e) {
    yield put(addMessage({ error: true, message: e }));
  }
}

const isValidObjectId = id => {
  const idRegex = /\d/g;
  const validIdLength = 24;
  return id && idRegex.test(id) && id.length === validIdLength;
};

function* handleEmailLinks(apiV4, referrer, companies) {
  const referrerUrl = referrer ? referrer.split('/') : [];

  if (referrerUrl?.length > 0) {
    // this makes sure that if you hit the back button you wont get redirected to the login screen
    history.push(`/app/dashboard`);

    const id = referrerUrl[referrerUrl.length - 1];

    if (!isValidObjectId(id)) {
      history.push('/home');
      return;
    }

    if (referrerUrl.includes('taskContainer')) {
      yield put(loadTask(id));
    } else if (referrerUrl.includes('performTraining')) {
      history.push(`/app/training/performTraining/${id}`);
    } else if (referrerUrl.includes('trainingSummary')) {
      history.push(`/app/training/trainingSummary/${id}`);
    } else if (referrerUrl.includes('approveTraining')) {
      history.push(`/app/training/approveTraining/${id}`);
    } else if (referrerUrl.includes('incidentContainer')) {
      const incidentResponse = yield call(apiV4.fetchIncidentById, id);

      const company =
        companies.find(c => c._id === incidentResponse.companyId) ||
        companies[0];
      yield put(setActiveCompany(company));

      yield put(
        setActiveIncidentResponse({
          ...incidentResponse,
          backToDashboard: true
        })
      );

      yield put(
        addUploadedAttachmentsResponse(
          incidentResponse.incidentActivity.attachments.filter(
            a => !a.isRemoved
          )
        )
      );

      const incidentBasics = yield call(apiV4.fetchIncidentBasics);

      const reportBasics = yield all(
        incidentResponse.reportTemplateIds.map(fieldGroupId =>
          call(
            apiV4.fetchReportBasics,
            reportTemplateMapper(fieldGroupId).basic
          )
        )
      );

      const basics = reportBasics.map(report => report[0]);

      incidentResponse.sections = [...incidentBasics, ...basics];

      yield put(fetchIncidentBasicsResponse(incidentResponse));

      history.push('/app/incidentContainer');
    } else if (referrerUrl.includes('safetyWalkContainer')) {
      const swResponse = yield call(apiV4.fetchSafetyWalkById, id);

      const company =
        companies.find(c => c._id === swResponse.company) || companies[0];
      yield put(setActiveCompany(company));

      yield put(
        setActiveSafetyWalk({
          ...swResponse,
          backToDashboard: true
        })
      );
      history.push('/app/safetyWalkContainer');
    } else if (referrerUrl.includes('ViewEdit')) {
      const docResponse = yield call(apiV4.fetchDocumentById, id);

      const company =
        companies.find(c => c._id === docResponse.companyId) || companies[0];
      yield put(setActiveCompany(company));

      if (referrerUrl.includes('Safety Procedure')) {
        yield put(
          setActiveSafetyInstruction({
            ...docResponse,
            backToDashboard: true
          })
        );
        history.push('/app/ViewEdit/Safety Procedure');
      } else if (referrerUrl.includes('Qualifications')) {
        yield put(
          setActiveQualification({
            ...docResponse
          })
        );
        history.push('/app/documents/qualifications');
      } else {
        yield put(
          setActiveSds({
            ...docResponse,
            backToDashboard: true
          })
        );
        history.push('/app/ViewEdit/SDS');
      }
    } else if (referrerUrl.includes('reportSectionContainer')) {
      const taskResponse = yield call(apiV4.fetchTaskById, id);
      const incidentResponse = yield call(
        apiV4.fetchIncidentById,
        taskResponse.ownerId
      );

      const company =
        companies.find(c => c._id === incidentResponse.companyId) ||
        companies[0];

      yield put(setActiveCompany(company));

      const activeReport = incidentResponse.reportComponents.filter(
        reportComponent =>
          reportComponent[0] === taskResponse.reportComponentName
      );
      const label = taskResponse.label;
      if (INVESTIGATION_COMPONENTS.some(v => label.includes(v))) {
        activeReport.stage = 'investigation';
      } else if (ACTION_COMPONENTS.some(v => label.includes(v))) {
        activeReport.stage = 'action';
      } else if (CLOSURE_COMPONENTS.some(v => label.includes(v))) {
        activeReport.stage = 'closure';
      }

      yield put(setActiveIncidentResponse(incidentResponse));

      yield put(createReportResponse(activeReport));
      history.push('/app/reportSectionContainer');
    } else if (referrerUrl.includes('safetyAudits')) {
      if (referrerUrl.includes('summary')) {
        history.push(`/app/safetyAudits/summary/${id}`);
      } else if (referrerUrl.includes('perform')) {
        history.push(`/app/safetyAudits/perform/${id}`);
      } else {
        history.push('/home');
      }
    } else if (referrerUrl.includes('witnessStatements')) {
      history.push(`/app/witnessStatements/${id}`);
    } else if (referrerUrl.includes('behaviorObservations')) {
      // need to set company correctly to the template and group dropdown populate
      const response = yield call(apiV4.fetchBehaviorObservationById, id);
      const company =
        companies.find(c => c._id === response.companyId) || companies[0];
      yield put(setActiveCompany(company));

      history.push(`/app/behaviorObservation/container/${id}`);
    } else {
      history.push('/home');
    }
  } else {
    history.push('/home');
  }
}
