import React, { useState } from 'react';
import {
  fetchTrainingExceptionTitles,
  fetchTrainingExceptionReport,
  downloadExceptionList
} from '../../api/v4';
import Card from '../../components/Card';
import { Button, Dropdown } from '../../components/inputs';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import HierarchySelector from '../../components/HierarchySelector';
import List from '../../components/List';
import RemoveExceptionItemModel from '../../components/Modal/removeExcptionItemModal';
import TypeSelection from '../../components/TypeSelection';
import { TwoColumn } from '../../components/inputs';
import { SingleDatePicker } from 'react-dates';
import { EXCEPTION_REPORTING_FREQUENCY_OPTIONS } from '../../constants/constants';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import styles from './styles.module.scss';
import moment from 'moment';

export default function TrainingReport() {
  const { company, location, project } = useActiveHeirarchy();

  const [selectedTab, setSelectedTab] = useState('Status');
  const [dateRange, setDateRange] = useState(null);
  const [trainingTitles, setTrainingTitles] = useState([]);
  const [nonValidTitles, setNonValidTitles] = useState([]);
  const [selectedTrainings, setSelectedTrainings] = useState([]);
  const [groupIds, setGroupIds] = useState([]);
  const [projectIds, setProjectIds] = useState([]);
  const [hireDateRange, setHireDateRange] = useState(null);
  const [exceptionData, setExceptionData] = useState([]);
  const [exceptionTitles, setExceptionTitles] = useState([]);
  const [exceptionColumns, setExceptionColumns] = useState([]);
  const [hasRanReport, setHasRanReport] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [rangeStartDate, setRangeStartDate] = useState(null);
  const [rangeStartDateFocused, setRangeStartDateFocused] = useState(false);
  const [rangeEndDate, setRangeEndDate] = useState(null);
  const [rangeEndDateFocused, setRangeEndDateFocused] = useState(false);
  const [hireStartDate, setHireStartDate] = useState(null);
  const [hireStartDateFocused, setHireStartDateFocused] = useState(false);
  const [hireEndDate, setHireEndDate] = useState(null);
  const [hireEndDateFocused, setHireEndDateFocused] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  const [tempDateRange, setTempDateRange] = useState(null);
  const [tempTrainingTitles, setTempTrainingTitles] = useState([]);
  const [tempRangeStartDate, setTempRangeStartDate] = useState(null);
  const [tempRangeEndDate, setTempRangeEndDate] = useState(null);
  const [dateRangeErrorMessage, setDateRangeErrorMessage] = useState(null);
  const [hireDateErrorMessage, setHireDateErrorMessage] = useState(null);

  const handleExportTable = async () => {
    const src = await downloadExceptionList({
      exceptionData,
      titleList: exceptionTitles,
      type: selectedTab
    });
    window.location = src;
  };

  const handleRunReport = () => {
    fetchTrainingExceptionReport({
      dateRange,
      startDate: rangeStartDate,
      endDate: rangeEndDate,
      trainingTitles: selectedTrainings,
      hireDateRange: hireDateRange === 'clear' ? null : hireDateRange,
      hireStartDate,
      hireEndDate,
      groupIds,
      projectIds,
      tabType: selectedTab
    }).then(response => {
      setExceptionData(response.exceptions);
      setExceptionTitles(response.titleList);

      selectedTab === 'Status'
        ? getStatusColumns(response.titleList)
        : getExpirationColumns(response.titleList);
      setHasRanReport(true);
      setHasChanges(false);
    });
  };

  const getStatusColumns = titles => {
    let columns = [
      {
        key: 'assignee',
        label: 'Employee Name',
        datatype: 'users'
      }
    ];

    titles.forEach((t, i) => {
      columns.push({
        key: `${t.title}Index${i}`,
        label: `${t.title}`,
        accessor: r =>
          r.trainings.find(training => t.masterId === training.id)?.status ??
          'Unassigned'
      });
    });

    columns.push({
      key: 'completionPercentage',
      label: `Completion Percentage`
    });

    setExceptionColumns(columns);
    return columns;
  };

  const getExpirationColumns = titles => {
    let columns = [
      {
        key: 'assignee',
        label: 'Employee Name',
        datatype: 'users'
      }
    ];

    titles.forEach((t, i) => {
      columns.push({
        key: `${t.title}Index${i}`,
        label: `${t.title}`,
        accessor: r =>
          r.trainings.find(training => t.masterId === training.id)
            ?.expiration ?? 'Unassigned'
      });
    });

    columns.push({
      key: 'expirationPercentage',
      label: `Expired`
    });

    setExceptionColumns(columns);
    return columns;
  };

  const handleCustomDateChange = async (start, end, type = 'end') => {
    let titles;

    if (type === 'start') {
      setTempRangeStartDate(start);
      if (!selectedTrainings?.length) setRangeStartDate(start);
    } else {
      setTempRangeEndDate(end);
      if (!selectedTrainings?.length) setRangeEndDate(end);
    }
    if (start && end) {
      if (moment(start).isBefore(end)) {
        titles = await fetchTrainingExceptionTitles({
          dateRange,
          startDate: start,
          endDate: end
        });
        if (selectedTrainings?.length) {
          checkForRemovedTitles(titles, 'customRange');
          setDateRangeErrorMessage('');
        } else {
          setTrainingTitles(titles);
          setDateRange('customRange');
          setDateRangeErrorMessage('');
        }
      } else {
        setDateRangeErrorMessage('Invalid Date Range');
      }
    }
  };

  const handleDateChange = async value => {
    let titles;
    if (value !== 'customRange') {
      titles = await fetchTrainingExceptionTitles({ dateRange: value });
      setTempDateRange(value);
      handleTitlesForDateChange(value, titles);
    } else {
      if (!selectedTrainings?.length) {
        setDateRange(value);
        // make sure values get reset
        setTempRangeStartDate(null);
        setTempRangeEndDate(null);
        setRangeStartDate(null);
        setRangeEndDate(null);
      } else {
        setTempDateRange(value);
      }
    }
  };

  const handleTitlesForDateChange = (date, titles) => {
    if (!selectedTrainings?.length) {
      setDateRange(date);
      setTrainingTitles(titles);
    } else {
      checkForRemovedTitles(titles, date);
    }
  };

  const checkForRemovedTitles = (titles, date) => {
    if (titles.length < selectedTrainings.length) {
      let nonValidTitles = selectedTrainings.filter(
        t => !titles?.some(title => title.label === t)
      );
      setNonValidTitles(nonValidTitles);
      setOpenRemoveModal(true);
      setTempTrainingTitles(titles);
      setTempDateRange(date);
    } else {
      setDateRange(date);
      setTrainingTitles(titles);
      setHasChanges(true);
      setRangeStartDate(tempRangeStartDate);
      setRangeEndDate(tempRangeEndDate);
    }
  };

  const handleSubmitRemoval = () => {
    setSelectedTrainings(tempTrainingTitles?.map(t => t.value));
    setDateRange(tempDateRange);
    setTrainingTitles(tempTrainingTitles);
    setOpenRemoveModal(false);
    setHasChanges(true);
    setTempDateRange(null);
  };

  const handleCancelRemoval = () => {
    setOpenRemoveModal(false);
    setNonValidTitles([]);
    setTempRangeStartDate(rangeStartDate);
    setTempRangeEndDate(rangeEndDate);
    setTempDateRange(null);
  };

  const handleGroupChange = values => {
    const validProjectIds = company.groups
      .map(g => {
        if (values.includes(g._id)) {
          return g.projects.map(p => p._id);
        } else {
          return [];
        }
      })
      .reduce((acc, x) => acc.concat(x), []);
    setGroupIds(values);

    const newProjectIds = projectIds.filter(p => validProjectIds.includes(p));
    setProjectIds(newProjectIds);
    if (hasRanReport) setHasChanges(true);
  };

  const header = (
    <Header
      title="Training Report"
      rightButtons={{
        color: 'blue',
        text: 'Export Table',
        disabled: !hasRanReport,
        onClick: () => handleExportTable()
      }}
    />
  );

  const checkForValidHireRange = (start, end) => {
    let message = moment(start).isBefore(end) ? null : 'Invalid Date Range';
    setHireDateErrorMessage(message);
  };

  const canRun =
    dateRange &&
    selectedTrainings?.length > 0 &&
    ((hireDateRange === 'customRange' &&
      hireStartDate &&
      hireEndDate &&
      !hireDateErrorMessage) ||
      hireDateRange !== 'customRange');
  const hireDateOptions = [
    {
      value: 'clear',
      label: ' '
    },
    ...EXCEPTION_REPORTING_FREQUENCY_OPTIONS
  ];

  return (
    <HeaderAndFooter Header={header}>
      <TypeSelection
        selectedArray={['Status', 'Expiration']}
        selected={selectedTab}
        onClick={value => {
          setSelectedTab(value);
          if (hasRanReport) setHasChanges(true);
        }}
      />
      <Card>
        <TwoColumn>
          {dateRange === 'customRange' || tempDateRange === 'customRange' ? (
            <TwoColumn>
              <Dropdown
                options={EXCEPTION_REPORTING_FREQUENCY_OPTIONS}
                fieldLabel="Date"
                currentValue={tempDateRange ?? dateRange}
                onChange={value => handleDateChange(value)}
                isRequired
              />
              <div
                className={{ display: 'flex', flexDirection: 'column-reverse' }}
              >
                <div className={styles.datePicker}>
                  <SingleDatePicker
                    date={tempRangeStartDate ?? rangeStartDate}
                    onDateChange={value =>
                      selectedTrainings?.length > 0
                        ? handleCustomDateChange(
                            value,
                            tempRangeEndDate,
                            'start'
                          )
                        : handleCustomDateChange(value, rangeEndDate, 'start')
                    }
                    focused={rangeStartDateFocused}
                    onFocusChange={f => setRangeStartDateFocused(f.focused)}
                    placeholder="Start"
                    isOutsideRange={d => false}
                  />
                  <SingleDatePicker
                    date={tempRangeEndDate ?? rangeEndDate}
                    onDateChange={value =>
                      selectedTrainings?.length > 0
                        ? handleCustomDateChange(tempRangeStartDate, value)
                        : handleCustomDateChange(rangeStartDate, value)
                    }
                    focused={rangeEndDateFocused}
                    onFocusChange={f => setRangeEndDateFocused(f.focused)}
                    placeholder="End"
                    isOutsideRange={d => false}
                  />
                </div>
                <span className={styles.dateWarning}>
                  {dateRangeErrorMessage}
                </span>
              </div>
            </TwoColumn>
          ) : (
            <Dropdown
              options={EXCEPTION_REPORTING_FREQUENCY_OPTIONS}
              fieldLabel="Date"
              currentValue={tempDateRange ?? dateRange}
              onChange={value => handleDateChange(value)}
              isRequired
            />
          )}
          <Dropdown
            options={trainingTitles}
            fieldLabel="Training Title"
            currentValue={selectedTrainings}
            onChange={value => {
              setSelectedTrainings(value);
              if (hasRanReport) setHasChanges(true);
            }}
            isRequired
            multi
            disabled={!dateRange?.length}
            selectButtons
            searchable
          />
        </TwoColumn>
        <HierarchySelector
          onGroupChange={values => handleGroupChange(values)}
          groupId={location ? [location._id] : groupIds}
          groupDisabled={dateRange?.length < 1 || selectedTrainings?.length < 1}
          onProjectChange={values => {
            setProjectIds(values);
            if (hasRanReport) setHasChanges(true);
          }}
          projectId={project ? [project._id] : projectIds}
          projectDisabled={groupIds?.length < 1 && !location}
          groupRequired={false}
          multi
          alwaysShowGroupDropdown
          alwaysShowProjectDropdown
        />
        <TwoColumn>
          {hireDateRange === 'customRange' ? (
            <TwoColumn>
              <Dropdown
                searchable
                options={hireDateOptions}
                fieldLabel="Hire Date"
                currentValue={hireDateRange}
                onChange={value => {
                  setHireDateRange(value);
                  if (value === 'clear') {
                    setHireEndDate(null);
                    setHireStartDate(null);
                  }
                  if (hasRanReport) setHasChanges(true);
                }}
                disabled={
                  dateRange?.length < 1 || selectedTrainings?.length < 1
                }
                placeholder="Choose one or more options"
              />
              <div
                className={{ display: 'flex', flexDirection: 'column-reverse' }}
              >
                <div className={styles.datePicker}>
                  <SingleDatePicker
                    date={hireStartDate}
                    onDateChange={value => {
                      setHireStartDate(value);
                      checkForValidHireRange(value, hireEndDate);
                    }}
                    focused={hireStartDateFocused}
                    onFocusChange={f => setHireStartDateFocused(f.focused)}
                    placeholder="Start"
                    isOutsideRange={d => false}
                  />
                  <SingleDatePicker
                    date={hireEndDate}
                    onDateChange={value => {
                      setHireEndDate(value);
                      checkForValidHireRange(hireStartDate, value);
                    }}
                    focused={hireEndDateFocused}
                    onFocusChange={f => setHireEndDateFocused(f.focused)}
                    placeholder="End"
                    isOutsideRange={d => false}
                  />
                </div>
                <span className={styles.dateWarning}>
                  {hireDateErrorMessage}
                </span>
              </div>
            </TwoColumn>
          ) : (
            <Dropdown
              searchable
              options={hireDateOptions}
              fieldLabel="Hire Date"
              currentValue={hireDateRange}
              onChange={value => {
                setHireDateRange(value);
                if (hasRanReport) setHasChanges(true);
              }}
              disabled={dateRange?.length < 1 || selectedTrainings?.length < 1}
              placeholder="Choose one or more options"
            />
          )}
        </TwoColumn>
        <Button
          color="blue"
          text={hasRanReport ? 'Update Report' : 'Run Report'}
          onClick={handleRunReport}
          disabled={
            (!hasRanReport && !canRun) ||
            (hasRanReport && (!hasChanges || !canRun))
          }
          className={styles.button}
        />
      </Card>
      <Card>
        {canRun && hasRanReport ? (
          <List data={exceptionData} dataIsHash settings={exceptionColumns} />
        ) : (
          <div className={styles.messageText}>
            Fill fields above to generate report
          </div>
        )}
      </Card>
      <RemoveExceptionItemModel
        isOpen={openRemoveModal}
        onRequestClose={() => handleCancelRemoval()}
        submitActions={() => handleSubmitRemoval()}
        itemsToRemove={nonValidTitles}
        removeWhat="Trainings"
      />
    </HeaderAndFooter>
  );
}
