import { call, put, select } from 'redux-saga/effects';
import history from '../history';
import { setActiveCustomReportQuestions } from '../actions/reports';
import {
  fetchIncidentBasicsResponse,
  setActiveIncidentResponse
} from '../actions/incidents';
import { addUploadedAttachmentsResponse } from '../actions/attachments';
import { fetchOshaFormResponse } from '../actions/oshaLogs';
import { getActiveCompany } from '../selectors/company';
import { getActiveCustomReportQuestionsSelector } from '../selectors/reports';
import { oshaQuestionMapper } from '../utils/oshaDbMapper';

export function* setActiveReport(api, apiV4, { payload }) {
  try {
    const activeIncident = yield call(apiV4.fetchIncidentById, payload.id);

    const froi =
      activeIncident.reportTemplateIds.indexOf('2') > -1 ||
      activeIncident.reportTemplateIds.indexOf('5') > -1;

    if (froi) {
      let oshaForm = yield call(apiV4.populateOshaForms, activeIncident._id);

      if (oshaForm.length > 0) {
        oshaForm.map(
          form => (form.formAnswers = oshaQuestionMapper(form.questions))
        );
      }

      yield put(fetchOshaFormResponse(oshaForm));
    } else {
      yield put(fetchOshaFormResponse([]));
    }

    const response = { sections: [] };

    const incidentBasics = activeIncident.incidentBasicsFieldGroup;

    const reportBasics = activeIncident.reportBasicsFieldGroups;

    response.sections = [incidentBasics, ...reportBasics];

    yield put(
      setActiveIncidentResponse({
        ...activeIncident,
        returnPath: payload.returnPath
      })
    );
    yield put(fetchIncidentBasicsResponse(response));
    yield put(
      addUploadedAttachmentsResponse(
        activeIncident.incidentActivity.attachments || []
      )
    );
    history.push('/app/incidentContainer');
  } catch (e) {
    console.log(e);
  }
}

export function* fetchCustomReportQuestions(apiV4) {
  try {
    const activeReport = yield select(getActiveCustomReportQuestionsSelector);

    const response = yield call(
      apiV4.fetchCustomReportQuestions,
      activeReport.reportTemplateNumber,
      activeReport.reportComponentName
    );

    if (response.length > 0) {
      yield put(setActiveCustomReportQuestions(response[0]));
    }
    history.push('/app/customReportQuestionContainer');
  } catch (error) {
    console.log(error);
  }
}

export function* createCustomReportQuestions(apiV4, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const activeReport = yield select(getActiveCustomReportQuestionsSelector);

    payload.fields.forEach((field, index) => {
      field.subSectionOrder = 1000 + index;
      field.type = field.type.value;
      field.options = field.options.map(option => option.option);
    });

    payload = {
      ...payload,
      label: 'Custom Questions',
      companyId: activeCompany._id,
      reportTemplateNumber: activeReport.reportTemplateNumber,
      reportComponentName: activeReport.reportComponentName
    };
    yield call(apiV4.createCustomReportQuestions, payload);
    yield put(setActiveCustomReportQuestions({}));
    history.goBack();
  } catch (error) {
    console.log(error);
  }
}

export function* updateCustomReportQuestions(apiV4, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);

    payload.fields.forEach((field, index) => {
      field.subSectionOrder = 1000 + index;
      field.type = field.type.value ? field.type.value : field.type;

      if (field.options.length > 0 && field.options[0].option) {
        field.options = field.options.map(option => option.option);
      }
    });

    payload = {
      ...payload,
      label: 'Custom Questions',
      companyId: activeCompany._id
    };

    yield call(apiV4.updateCustomReportQuestions, payload);
    yield put(setActiveCustomReportQuestions({}));
    history.goBack();
  } catch (error) {
    console.log(error);
  }
}

export function* deleteCustomReportQuestions(apiV4, { payload }) {
  try {
    yield call(apiV4.deleteCustomReportQuestions, payload);
    yield put(setActiveCustomReportQuestions({}));
    history.goBack();
  } catch (error) {
    console.log(error);
  }
}
