import React from 'react';
import AssignCorrectiveActionButton from '../../inputs/AssignCorrectiveActionButton';
import Card from '../../Card';
import { FieldRenderer, Textbox } from '../../inputs';
const findAnswer = (fields, questionId) => {
  const fieldIndex = fields.findIndex(field => field._id === questionId);
  if (fieldIndex > -1) {
    return fields[fieldIndex].answer;
  }

  return '';
};

export default function GeneratorCard({
  disabled,
  name,
  observation,
  onChange,
  creating,
  editing
}) {
  const handleAnswer = (field, answer, index) => {
    field.answer = answer;

    const fields = observation.fields;
    fields[index] = field;
    onChange({ fields });
  };

  const handleComments = (field, comments, index) => {
    field.additionalComments = comments;

    const fields = observation.fields;
    fields[index] = field;
    onChange({ fields });
  };

  return (
    <Card title={observation?.title} name={name} showHeader={true}>
      {observation?.fields?.map((field, index) => {
        return (
          <div key={index}>
            <FieldRenderer
              richText
              key={index}
              fieldLabel={`${index + 1}. ${field.label}`}
              isRequired={field.required}
              name={field.label}
              currentValue={findAnswer(observation?.fields, field._id)}
              onChange={v => handleAnswer(field, v, index)}
              disabled={disabled}
              options={field.options}
              type={field.type}
              bareValues={false}
              testID={field.label}
            />
            {field.allowAdditionalComments ? (
              <Textbox
                fieldLabel="Additional Comments"
                currentValue={field.additionalComments}
                onChange={v => handleComments(field, v, index)}
                type="textarea"
                disabled={disabled}
              />
            ) : null}
          </div>
        );
      })}
      {!creating ? (
        <AssignCorrectiveActionButton
          groupId={observation.groupId}
          task={observation?.task}
          ownerType="observation"
          label={`${observation.title} - Corrective Action`}
          schedule="immediate"
          projectId={observation.projectId}
          ownerId={observation._id}
          assignedTo={[observation.employeeObserved]}
          pictures={observation.attachments}
          onClick={() =>
            localStorage.setItem(
              'activeObservation',
              JSON.stringify(observation)
            )
          }
          disabled={!editing}
        />
      ) : null}
    </Card>
  );
}
