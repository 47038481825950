import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAccessLevel } from '../../../selectors/users';
import {
  REPORT_NOTIFICATION_OPTIONS,
  FROI_NOTIFICATION_OPTIONS,
  RISK_ASSESSMENT_NOTIFICATION_OPTIONS,
  SAFETY_WALK_NOTIFICATION_OPTIONS,
  TRAINING_NOTIFICATION_OPTIONS,
  DOCUMENT_NOTIFICATION_OPTIONS,
  TASK_NOTIFICATION_OPTIONS,
  REPORT_NOTIFICATION_TYPES,
  BEHAVIOR_OBSERVATION_NOTIFICATION_OPTIONS,
  CLAIMS_NOTIFICATIONS
} from '../../../constants/constants';
import {
  getIsPerrp,
  getActiveCompany,
  getUserCompaniesSelector
} from '../../../selectors/company';
import BlockText from '../../../components/inputs/BlockText';
import Card from '../../../components/Card';
import Dropdown from '../../../components/inputs/Dropdown';
import Switch from '../../../components/inputs/Switch';
import { TwoColumn } from '../../../components/inputs/index';
import { usePrevious } from '../../../utils/hooks';
import styles from './notifications.module.scss';

export default function Notifications({
  isEditing,
  emailNotificationsActive,
  notifications,
  setEmailNotificationsActive,
  setNotifications,
  testID
}) {
  let accessLevel = useSelector(getAccessLevel);
  const activeCompany = useSelector(getActiveCompany);
  const isPerrp = useSelector(getIsPerrp);
  const userCompanies = useSelector(getUserCompaniesSelector);
  const [projectDropdown, setProjectDropdown] = useState([]);

  let groupDropdown = [];
  let reportTypes = REPORT_NOTIFICATION_TYPES;
  if (!activeCompany.showAircraft) {
    reportTypes = REPORT_NOTIFICATION_TYPES.filter(r => !r.showAircraft);
  }
  if (userCompanies.length > 0) {
    userCompanies.forEach(company =>
      company.groups.forEach(group =>
        groupDropdown.push({
          value: group._id,
          label: group.name
        })
      )
    );
  }

  const prevNotifcationsGroups = usePrevious(notifications.groups);
  useEffect(() => {
    let dropdown = getProjectDropdown();
    setProjectDropdown(dropdown);

    if (prevNotifcationsGroups?.length <= notifications?.groups?.length) {
      let differentGroup = notifications?.groups?.filter(
        g => !prevNotifcationsGroups?.includes(g)
      )?.[0];

      if (differentGroup) {
        setNotifications('selectAll', dropdown, 'projects');
      }
    } else {
      let differentGroup = prevNotifcationsGroups?.filter(
        g => !notifications?.groups?.includes(g)
      )?.[0];
      if (differentGroup) {
        let dropdownToClear = getGroupProjectIds(differentGroup);
        setNotifications('clearAll', dropdownToClear, 'projects');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications.groups, prevNotifcationsGroups]);

  const handleUpdateProjects = values => {
    let onlyValues = values.map(v => v.value);
    let projectGroupIds = [...new Set(projectDropdown.map(v => v.groupId))];

    let newValues = onlyValues;
    for (let i = 0; i < projectGroupIds.length; i++) {
      let containsProjectStill = clearedGroupProjects(
        projectGroupIds[i],
        onlyValues
      );

      if (!containsProjectStill) {
        let projectValues = projectDropdown
          .filter(p => p.groupId === projectGroupIds[i])
          .map(p => p.value);
        newValues = newValues.concat(projectValues);
      }
    }
    setNotifications(newValues, projectDropdown, 'projects');
  };

  const clearedGroupProjects = (groupId, updatedAnswer) => {
    let groupProjectIds = getGroupProjectIds(groupId);
    return updatedAnswer.some(a => groupProjectIds.includes(a));
  };

  const getAnswers = (options, label) => {
    let answers = [];
    if (label === 'groups' || label === 'projects') {
      options.forEach(option => {
        if (notifications[label]?.includes(option.value)) {
          answers.push(option);
        }
      });
      return answers;
    } else {
      options.forEach(option => {
        if (notifications[label]?.[option.value]) {
          answers.push(option);
        }
      });
      return answers;
    }
  };

  const getProjectDropdown = () => {
    let chosenGroupIds = getAnswers(groupDropdown, 'groups').map(g => g.value);

    let chosenGroups = userCompanies
      .map(company =>
        company.groups.filter(group => chosenGroupIds.includes(group._id))
      )
      .reduce((elem1, elem2) => elem1.concat(elem2));

    let groupProjects = chosenGroups
      .map(group =>
        group?.projects.length > 0
          ? {
              groupName: group.name,
              projects: group.projects,
              groupId: group._id
            }
          : []
      )
      ?.reduce((elem1, elem2) => elem1.concat(elem2), []);

    let dropdown = groupProjects
      ?.map(project =>
        project.projects?.map(p => ({
          value: p._id,
          label: `${project.groupName} - ${p.name}`,
          groupId: project.groupId
        }))
      )
      .reduce((elem1, elem2) => elem1.concat(elem2), []);

    return dropdown;
  };

  const getGroupProjectIds = groupId => {
    let group = userCompanies
      .map(c => c.groups.find(g => g._id === groupId))
      .filter(g => g)?.[0];

    let groupProjects = group?.projects?.map(p => p._id) ?? [];

    return groupProjects;
  };

  return (
    <Card title="Notification Settings" showHeader>
      <Switch
        testID="emailNotifications"
        currentValue={emailNotificationsActive}
        onChange={value => setEmailNotificationsActive(value)}
        label="Email Notifications"
        name="userEmailSwitch"
        disabled={!isEditing}
      />
      <BlockText
        blockOfText="You will always receive notifications for tasks, safety audits, and safety trainings assigned to you."
        className={styles.blockText}
      />
      <BlockText
        blockOfText="When selecting a group all areas for that group will be added to the project dropdown - if a group contains area(s) at least one area must be chosen"
        className={styles.blockText}
      />
      {(emailNotificationsActive && accessLevel !== 100 && (
        <TwoColumn>
          <Dropdown
            multi
            fieldLabel="Group/Establishment"
            placeholder="Notify me when..."
            options={groupDropdown}
            disabled={!isEditing}
            currentValue={getAnswers(groupDropdown, 'groups')}
            onChange={values =>
              setNotifications(values, groupDropdown, 'groups')
            }
            searchable
            selectButtons
          />
          <Dropdown
            multi
            fieldLabel="Area"
            placeholder="Notify me when..."
            options={projectDropdown}
            disabled={!isEditing}
            currentValue={getAnswers(projectDropdown, 'projects')}
            onChange={handleUpdateProjects}
            searchable
            selectButtons
            bareValues={false}
          />
          <Dropdown
            multi
            fieldLabel="First Report of Injury"
            placeholder="Notify me when..."
            options={FROI_NOTIFICATION_OPTIONS.filter(
              option => option.minAccessLevel <= accessLevel
            ).map(option => {
              if (option.label === 'Marked OSHA Recordable' && isPerrp) {
                return {
                  label: 'Marked PERRP Recordable',
                  value: option.value
                };
              }

              return option;
            })}
            disabled={!isEditing}
            currentValue={getAnswers(FROI_NOTIFICATION_OPTIONS, 'froi')}
            onChange={values =>
              setNotifications(values, FROI_NOTIFICATION_OPTIONS, 'froi')
            }
            searchable
            selectButtons
          />
          {reportTypes.map(r => (
            <Dropdown
              multi
              fieldLabel={r.label}
              placeholder="Notify me when..."
              options={REPORT_NOTIFICATION_OPTIONS}
              disabled={!isEditing}
              currentValue={getAnswers(REPORT_NOTIFICATION_OPTIONS, r.value)}
              onChange={values =>
                setNotifications(values, REPORT_NOTIFICATION_OPTIONS, r.value)
              }
              searchable
              selectButtons
            />
          ))}
          <Dropdown
            multi
            fieldLabel="Behavior Observations"
            placeholder="Notify me when..."
            options={BEHAVIOR_OBSERVATION_NOTIFICATION_OPTIONS}
            disabled={!isEditing}
            currentValue={getAnswers(
              BEHAVIOR_OBSERVATION_NOTIFICATION_OPTIONS,
              'behaviorObservation'
            )}
            onChange={values =>
              setNotifications(
                values,
                BEHAVIOR_OBSERVATION_NOTIFICATION_OPTIONS,
                'behaviorObservation'
              )
            }
            searchable
            selectButtons
          />
          <Dropdown
            multi
            fieldLabel="Safety Audits"
            placeholder="Notify me when..."
            options={RISK_ASSESSMENT_NOTIFICATION_OPTIONS}
            disabled={!isEditing}
            currentValue={getAnswers(
              RISK_ASSESSMENT_NOTIFICATION_OPTIONS,
              'safetyAudit'
            )}
            onChange={values =>
              setNotifications(
                values,
                RISK_ASSESSMENT_NOTIFICATION_OPTIONS,
                'safetyAudit'
              )
            }
            searchable
            selectButtons
          />
          <Dropdown
            multi
            fieldLabel={activeCompany.isDash ? 'DASH Report' : 'Safety Walks'}
            placeholder="Notify me when..."
            options={SAFETY_WALK_NOTIFICATION_OPTIONS}
            disabled={!isEditing}
            currentValue={getAnswers(
              SAFETY_WALK_NOTIFICATION_OPTIONS,
              'safetyWalk'
            )}
            onChange={values =>
              setNotifications(
                values,
                SAFETY_WALK_NOTIFICATION_OPTIONS,
                'safetyWalk'
              )
            }
            searchable
            selectButtons
          />
          <Dropdown
            multi
            fieldLabel="Training"
            placeholder="Notify me when..."
            options={TRAINING_NOTIFICATION_OPTIONS}
            disabled={!isEditing}
            currentValue={getAnswers(TRAINING_NOTIFICATION_OPTIONS, 'training')}
            onChange={values =>
              setNotifications(
                values,
                TRAINING_NOTIFICATION_OPTIONS,
                'training'
              )
            }
            searchable
            selectButtons
          />
          <Dropdown
            multi
            fieldLabel="Documents"
            placeholder="Notify me when..."
            options={DOCUMENT_NOTIFICATION_OPTIONS}
            disabled={!isEditing}
            currentValue={getAnswers(DOCUMENT_NOTIFICATION_OPTIONS, 'document')}
            onChange={values =>
              setNotifications(
                values,
                DOCUMENT_NOTIFICATION_OPTIONS,
                'document'
              )
            }
            searchable
            selectButtons
          />
          <Dropdown
            multi
            fieldLabel="Tasks"
            placeholder="Notify me when..."
            options={TASK_NOTIFICATION_OPTIONS}
            disabled={!isEditing}
            currentValue={getAnswers(TASK_NOTIFICATION_OPTIONS, 'task')}
            onChange={values =>
              setNotifications(values, TASK_NOTIFICATION_OPTIONS, 'task')
            }
            searchable
            selectButtons
          />
          {activeCompany.showClaims ? (
            <Dropdown
              multi
              fieldLabel="Claims"
              placeholder="Notify me when..."
              options={CLAIMS_NOTIFICATIONS}
              disabled={!isEditing}
              currentValue={getAnswers(CLAIMS_NOTIFICATIONS, 'claims')}
              onChange={values =>
                setNotifications(values, CLAIMS_NOTIFICATIONS, 'claims')
              }
              searchable
              selectButtons
            />
          ) : null}
        </TwoColumn>
      )) ||
        undefined}
    </Card>
  );
}
