import React, { Component } from 'react';
import ReactLoading from 'react-loading';

import { FieldRenderer } from '../../inputs';
import Button from '../../inputs/Button';
import { DatePicker } from '../../inputs/DateTimePicker';
import FileDownloader from '../../FileDownloader';
import FileUploader from '../../FileUploader';

import './index.css';

const findAnswer = (fields, questionId) => {
  const fieldIndex = fields.findIndex(field => field._id === questionId);
  if (fieldIndex > -1) {
    return fields[fieldIndex].answer;
  }

  return '';
};

export default class CustomDocumentGenerator extends Component {
  handleAnswer = (field, answer) => {
    field.answer = answer;

    const fields = this.props.fields;
    fields[field._id] = field;
    this.props.answerQuestion(fields);
  };

  render() {
    const {
      fields,
      disabled,
      activeCustomDoc,
      handleExpires,
      printDocument,
      downloadCustomDoc,
      addedAttachments,
      addAttachment,
      deleteAttachment,
      activeCompanyId,
      loading,
      deleteCDAttachment,
      hasAccess
    } = this.props;

    return (
      <div className="customDocumentGenerator">
        <h2 className="customDocumentGenerator-header">
          {activeCustomDoc?.label}
          {!downloadCustomDoc ? (
            <div>
              {disabled && (
                <Button
                  text="Print Document"
                  color="blue"
                  onClick={() => printDocument()}
                />
              )}
            </div>
          ) : (
            <ReactLoading
              type="spinningBubbles"
              color="#2f8738"
              className="loading"
            />
          )}
        </h2>
        {(activeCustomDoc?.shouldHaveExpiredDate ||
          activeCustomDoc?.expires) && (
          <DatePicker
            fieldLabel="Expires"
            onChange={handleExpires}
            currentValue={activeCustomDoc?.expires}
            disabled={disabled}
            name="expirationDate"
          />
        )}
        {fields &&
          fields
            .filter(f => !f?.legacy)
            .map((field, index) => (
              <FieldRenderer
                key={index}
                fieldLabel={field.label}
                isRequired={field.required}
                name={field.label}
                currentValue={findAnswer(fields, field._id)}
                onChange={v => this.handleAnswer(field, v)}
                disabled={disabled}
                options={field.options}
                type={field.type}
                bareValues={false}
              />
            ))}
        <div>
          <h4>Attachments</h4>
          {addedAttachments &&
            addedAttachments.map((attachment, attachmentIndex) => (
              <FileDownloader
                attachment={attachment}
                key={attachmentIndex}
                attachmentIndex={attachmentIndex}
                deleteAttachment={deleteAttachment}
                disabled={!hasAccess || disabled}
              />
            ))}
          {activeCustomDoc?.attachments?.map((attachment, attachmentIndex) => (
            <FileDownloader
              attachment={attachment}
              key={attachmentIndex}
              attachmentIndex={attachmentIndex}
              deleteAttachment={deleteCDAttachment}
              disabled={!hasAccess || disabled}
            />
          ))}
        </div>
        {!disabled && (
          <FileUploader
            activeCompanyId={activeCompanyId}
            addAttachment={addAttachment}
            loading={loading}
            ownerId="1234"
            ownerType="documents"
          />
        )}
      </div>
    );
  }
}
