import React, { Component } from 'react';
import { connect } from 'react-redux';

import { addMessage } from '../../actions/messages';
import { getLoggedInUser } from '../../selectors/users';
import { isValid } from 'redux-form';
import { clearUploadedAttachments } from '../../actions/attachments';
import {
  getUserCompaniesSelector,
  getActiveLocationId
} from '../../selectors/company';
import {
  getAttachmentLoadingSelector,
  getAddedAttachmentsSelector
} from '../../selectors/attachments';
import {
  downloadEmployeeQRcodeRequest,
  setActiveEmployeeRequest
} from '../../actions/personnel';
import history from '../../history';
import {
  EMPLOYEE_BULK_UPLOAD_TEMPLATE,
  USER_ROLES
} from '../../constants/constants';
import Button from '../inputs/Button';
import CSVUploader from '../../components/CSVUploader';
import EmployeeForm from '../../forms/EmployeeForm';
import Modal from '../Modal';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import HeaderAndFooter from '../HeaderAndFooter';
import Header from '../Header';
import { InputRow } from '../inputs';
import List from '../List';
import { doesUsernameExist } from '../../api';
import {
  addEmployee,
  listEmployees,
  exportEmployeesToCSV,
  importEmployees
} from '../../api/v4';

export class EmployeeList extends Component {
  state = {
    modalOpen: 0,
    userExists: null,
    data: null,
    messages: null
  };

  componentDidMount() {
    listEmployees().then(data => this.setState({ data }));
  }

  handleOpenModal(modalId) {
    this.setState({
      modalOpen: modalId
    });
  }

  handleCloseModal = () => {
    this.setState({ modalOpen: 0 });
  };

  exportEmployeesToCSV = async () => {
    const src = await exportEmployeesToCSV();
    window.location = src;
  };

  handleImport = () => {
    try {
      if (!this.props.attachments[0])
        this.props.addMessage({
          error: true,
          message: 'File required!'
        });
      else {
        importEmployees(this.props.attachments[0]?.source_url).then(
          data => {
            this.props.addMessage({
              error: false,
              message: 'Imported successfully!'
            });
            this.props.clearAttachment();
          },
          error => {
            this.setState({ messages: error.data });
            this.handleOpenModal(3);
            this.props.clearAttachment();
          }
        );
      }
    } catch (e) {
      if (e.status !== 400) throw e;
      let messages = e.data;
      if (!Array.isArray(messages)) messages = [messages];
      this.setState({ messages });
    }
    this.handleCloseModal();
  };

  handleAddEmployeeSubmit = values => {
    addEmployee(values).then(() =>
      listEmployees().then(data => this.setState({ data }))
    );
    this.handleCloseModal();
  };

  checkUsernameAvailability = username => {
    if (!username) {
      this.setState({ userExists: null });
      return;
    }

    doesUsernameExist({
      candidateUsername: username
    }).then(response =>
      this.setState({ userExists: response?.userExists ?? null })
    );
  };

  render() {
    const {
      setActiveEmployee,
      userCompanies,
      loggedInUser,
      employeeValid,
      attachmentLoading,
      downloadQRCodes,
      activeLocation
    } = this.props;

    const { modalOpen, data, userExists } = this.state;
    const header = <Header title="Employees" />;

    const columns = [
      { key: 'firstName' },
      { key: 'lastName' },
      {
        key: 'employeeNumber',
        label: 'Employee Id'
      },
      { label: 'Hire Date', key: 'dateOfHire', datatype: 'date' },
      { key: 'username' },
      {
        key: 'userType',
        accessor: row => (row.isDeactivated ? 0 : row.accessLevel),
        enum: USER_ROLES
      }
    ];

    if (!activeLocation) {
      columns.unshift({
        key: 'groupIds',
        label: 'Group/Est.',
        datatype: 'groups'
      });
    }

    return (
      <>
        <HeaderAndFooter Header={header}>
          <ScrollToTopOnMount />
          <InputRow right>
            <Button
              color="green"
              text="Add Employee"
              onClick={() => this.handleOpenModal(1)}
            />
            <Button
              color="green"
              text="Upload Employee Templates"
              onClick={() => this.handleOpenModal(2)}
            />
            <Button
              color="green"
              text="Export Employees To CSV"
              onClick={this.exportEmployeesToCSV}
            />
          </InputRow>
          <List
            settings={columns}
            actions={[
              {
                color: 'blue',
                label: 'Download QR Codes',
                onClick: ids => downloadQRCodes({ ids })
              }
            ]}
            dataIsHash
            getRowId={r => r._id}
            data={data}
            rowClick={row => {
              setActiveEmployee({ ...row });
              history.push('/app/viewEditPersonnel');
            }}
            saveKey="empList"
          />
        </HeaderAndFooter>
        {modalOpen === 1 && (
          <Modal
            title="Add New Employee"
            titleClassName="greenHeader"
            wide
            isOpen={modalOpen === 1}
            onRequestClose={this.handleCloseModal}
            formName="EmployeeForm"
            submitButtonColor="green"
            disableSubmit={!employeeValid || userExists}
          >
            <EmployeeForm
              onRequestClose={this.handleCloseModal}
              onSubmit={values => this.handleAddEmployeeSubmit({ ...values })}
              userCompanies={userCompanies}
              accessLevel={loggedInUser.accessLevel}
              checkUsernameAvailability={this.checkUsernameAvailability}
              userClassName={
                userExists
                  ? 'user-exists'
                  : userExists === false
                  ? 'user-valid'
                  : ''
              }
            />
          </Modal>
        )}
        <Modal
          title="Import Employee"
          titleClassName="blueHeader"
          isOpen={modalOpen === 2}
          className="modalSmall"
          onRequestClose={this.handleCloseModal}
          submitButtonColor="blue"
          submitActions={this.handleImport}
          disableSubmit={attachmentLoading}
        >
          <Button
            color="blue"
            onClick={() => {
              var win = window.open(EMPLOYEE_BULK_UPLOAD_TEMPLATE, '_blank');
              win.focus();
            }}
            text="Download CSV Template"
          />
          <CSVUploader documentType="EmployeesUpload" className="dropzone" />
        </Modal>
        <Modal
          title="Issues with Employee Upload"
          titleClassName="redHeader"
          isOpen={modalOpen === 3}
          className="modalMedium"
          onRequestClose={this.handleCloseModal}
          hideCancelButton={true}
          submitActions={this.handleCloseModal}
          submitButtonText="Close"
          showXButton={true}
        >
          <ul>
            {this.state.messages?.map((issue, index) => (
              <li key={index}>{issue}</li>
            ))}
          </ul>
        </Modal>
      </>
    );
  }
}

export const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state),
  userCompanies: getUserCompaniesSelector(state),
  employeeValid: isValid('EmployeeForm')(state),
  attachmentLoading: getAttachmentLoadingSelector(state),
  attachments: getAddedAttachmentsSelector(state),
  activeLocation: getActiveLocationId(state)
});

export const mapDispatchToProps = dispatch => ({
  setActiveEmployee: employee => dispatch(setActiveEmployeeRequest(employee)),
  clearAttachment: () => dispatch(clearUploadedAttachments()),
  addMessage: payload => dispatch(addMessage(payload)),
  downloadQRCodes: values => dispatch(downloadEmployeeQRcodeRequest(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList);
