import React from 'react';
import { Input, nameInput } from '../';
import '../input.css';
import './index.css';

const Textbox = ({
  name,
  placeholder,
  onChange,
  onBlur,
  currentValue,
  disabled,
  type,
  inputClassName,
  rows,
  onMouseDown,
  autoFocus,
  inputRef,
  testID,
  enter,
  onKeyDown,
  warning,
  touched,
  error,
  ...props
}) => {
  const keyDown = e => {
    if (e.keyCode === 27 && escape) {
      escape();
    } else if ((e.keyCode === 13 || e.keyCode === 10) && enter) {
      enter(currentValue);
    } else if (onKeyDown) {
      return onKeyDown(e);
    }
  };
  const change = e => onChange?.(e.target.value, e);

  return (
    <Input
      {...props}
      name={name}
      disabled={disabled}
      warning={warning}
      touched={touched}
      error={error}
    >
      {type === 'textarea' ? (
        <textarea
          data-cy={testID ?? props.fieldLabel}
          value={currentValue}
          rows={rows}
          placeholder={placeholder}
          className={`textbox ${inputClassName}`}
          onChange={change}
          onKeyDown={keyDown}
          onBlur={onBlur}
          disabled={disabled}
          min="0"
          id={name}
          onDragStart={e => e.preventDefault()}
          onDrop={e => e.preventDefault()}
          autoFocus={autoFocus}
          ref={inputRef}
        />
      ) : (
        <input
          value={currentValue}
          type={type}
          placeholder={placeholder}
          className={`textbox ${inputClassName}`}
          onChange={change}
          onKeyDown={keyDown}
          onBlur={onBlur}
          onMouseDown={onMouseDown}
          disabled={disabled}
          min="0"
          step="1"
          id={name}
          autoFocus={autoFocus}
          ref={inputRef}
          data-cy={testID ?? props.fieldLabel}
        />
      )}
    </Input>
  );
};

let t = nameInput(Textbox);
export { t as Textbox };
export default t;
