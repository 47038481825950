import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { fetchSafetyAuditTemplates } from '../../api/v4';
import history from '../../history';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import List from '../../components/List';
import Modal from '../../components/Modal';
import { Dropdown } from '../../components/inputs';

export default function SafetyAuditTemplates() {
  const [templates, setTemplates] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [templateDropdown, setTemplateDropdown] = useState([]);

  useEffect(() => {
    fetchSafetyAuditTemplates().then(response => {
      setTemplates(response);
      setTemplateDropdown(
        response.map(r => {
          return { value: r._id, label: r.title };
        })
      );
    });
  }, []);

  const header = (
    <Header
      title="Safety Audit Templates"
      pageActionOptions={[
        {
          label: 'Add Safety Audit Template',
          visible: true,
          color: 'blueOutline',
          onClick: () => history.push(`/app/safetyAuditTemplates/template/`)
        },
        {
          label: 'Duplicate Safety Audit Template',
          onClick: () => setOpenModal(true),
          color: 'blueOutline',
          visible: templates?.length > 0
        }
      ]}
    />
  );

  const columns = [
    { key: 'title' },
    { key: 'description' },
    {
      key: 'updatedAt',
      accessor: r =>
        r?.auditLog?.length
          ? moment(r.auditLog[r.auditLog.length - 1].when).format('l LT')
          : ''
    }
  ];

  return (
    <HeaderAndFooter Header={header}>
      <List
        saveKey="safetyTemplates"
        rowClick={row =>
          history.push(`/app/safetyAuditTemplates/template/${row._id}`)
        }
        dataIsHash
        data={templates}
        settings={columns}
      />
      <Modal
        title="Choose Template"
        titleClassName="blueHeader"
        isOpen={openModal}
        submitButtonColor="blue"
        submitButtonText="Create"
        onRequestClose={() => setOpenModal(false)}
        submitActions={() =>
          history.push(
            `/app/safetyAuditTemplates/template/${currentTemplate}/true`
          )
        }
      >
        <Dropdown
          options={templateDropdown}
          fieldLabel="Safety Audit Template"
          currentValue={currentTemplate}
          isRequired
          onChange={value => setCurrentTemplate(value)}
          placeholder="Select a safety audit"
          searchable
        />
      </Modal>
    </HeaderAndFooter>
  );
}
