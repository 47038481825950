import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import * as api from '../../../api';
import history from '../../../history';
import {
  setActiveTrainingResponse,
  setActiveTrainingAttachment,
  updateTrainingRequest
} from '../../../actions/training';
import { getActiveTrainingSelector } from '../../../selectors/training';
import { getLoggedInUser } from '../../../selectors/users';
import BlockText from '../../../components/inputs/BlockText';
import Card from '../../../components/Card';
import { DatePicker } from '../../../components/inputs/DateTimePicker';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import LeftNav from '../../../components/LeftNav';
import MarkItemAsCompleteModal from '../../../components/Modal/markItemAsCompleteModal';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import SignatureCard from '../../../components/SignatureCard';
import { SubmitSaveForLaterFooter } from '../../../components/Footer';
import TrainingTable from '../../../components/training/TrainingTable';
import Input from '../../../components/inputs';
import Button from '../../../components/inputs/Button';
import { fetchPerformTraining } from '../../../api/v4';
import QuizScoreBox from './quizScoreBox.js';
import Modal from '../../../components/Modal/index';
import {
  Textbox,
  Dropdown,
  EmployeeDropdown
} from '../../../components/inputs';
import { getActiveCompany } from '../../../selectors/company';

import preformTrainingStyles from './styles.module.scss';
import TrainingMaterials from '../TrainingMaterials';

class PerformTraining extends Component {
  state = {
    hasUnsavedChanges: false,
    openUnsavedChanges: false,
    openMarkAsCompleteModal: false,
    employeeSignature: undefined,
    openAddtionalAttempts: false,
    assignedTo: null,
    projectIds: null
  };

  defaultTrainingTitle = 'Safety Training';

  componentDidMount() {
    let id = this.props?.match?.params?.trainingId;

    if (id) {
      fetchPerformTraining(id).then(response => {
        this.props.setActiveTraining({ ...response });
        this.setState({
          employeeSignature: response.employeeSignature,
          assignedTo: response.assignedTo,
          projectIds: response.projectIds
        });
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.activeTraining, this.props.activeTraining)) {
      this.setState({ hasUnsavedChanges: true });
    }
  }

  handleBack = () => {
    this.props.setActiveTraining({});
    history.goBack();
  };

  handleSubmit = isComplete => {
    const { updateTraining, activeTraining } = this.props;
    let response = { isCompleted: isComplete };

    if (isComplete) {
      response.completionDate = moment();
    }

    activeTraining.employeeSignature = this.state.employeeSignature;
    if (activeTraining.trainingType.value === 2) {
      activeTraining.assignedTo = this.state.assignedTo;
      activeTraining.projectIds = this.state.projectIds;
    }

    updateTraining({ ...activeTraining, ...response, isPerform: true });
  };

  canSubmit = () => {
    const { activeTraining } = this.props;

    const hasAttachments = activeTraining?.attachments?.length;
    const wasOpened =
      activeTraining?.trainingType?.value === 2
        ? true
        : activeTraining?.attachments?.every(
            attachment => attachment.timeSpent
          );
    const hasQuiz = activeTraining?.quiz;

    const quizCompleted = activeTraining?.quiz?.isCompleted;
    let hasAssignees = true;

    if (
      activeTraining?.trainingType?.value === 2 &&
      this.state?.assignedTo?.length === 0
    )
      hasAssignees = false;

    return (
      ((hasAttachments && wasOpened && hasQuiz && quizCompleted) ||
        (hasAttachments && wasOpened && !hasQuiz) ||
        (!hasAttachments && hasQuiz && quizCompleted)) &&
      hasAssignees &&
      this.state.employeeSignature
    );
  };

  getQuickNav = () => {
    const { activeTraining } = this.props;
    const label =
      activeTraining?.trainingType?.value === 2
        ? 'Administrator Signature'
        : 'Employee Signature';
    const id =
      activeTraining?.trainingType?.value === 2
        ? 'administratorSignature'
        : 'employeeSignature';

    let leftNav = [
      { label: activeTraining?.title || this.defaultTrainingTitle, id: '0' }
    ];

    const wasOpened =
      activeTraining?.trainingType?.value === 2
        ? true
        : activeTraining?.attachments?.every(
            attachment => attachment.timeSpent
          );
    const hasAttachments = activeTraining?.attachments?.length;
    const hasQuiz = activeTraining?.quiz;
    const quizCompleted = activeTraining?.quiz?.isCompleted;

    if (hasAttachments)
      leftNav = [...leftNav, { label: 'Training Material', id: '1' }];

    if (hasQuiz && wasOpened)
      leftNav = [...leftNav, { label: 'Quiz', id: '2' }];

    if (
      (hasAttachments && wasOpened && hasQuiz && quizCompleted) ||
      (hasAttachments && wasOpened && !hasQuiz) ||
      (!hasAttachments && hasQuiz && quizCompleted)
    ) {
      leftNav = [
        ...leftNav,
        {
          label: label,
          id: id
        }
      ];
    }

    return leftNav;
  };

  printTrainingSummary = async () => {
    const { activeTraining } = this.props;

    const url = await api.printTrainingSummary(activeTraining);

    window.open(url, '_blank');
  };

  render() {
    const { activeTraining, setActiveAttachment, activeCompany } = this.props;

    const {
      openUnsavedChanges,
      openMarkAsCompleteModal,
      hasUnsavedChanges,
      openAddtionalAttempts,
      assignedTo,
      projectIds
    } = this.state;

    const header = (
      <Header
        title="Safety Training"
        section={activeTraining?.title}
        clickBack={() => this.handleBack()}
        hasUnsavedChanges={hasUnsavedChanges}
        rightButtons={{
          text: 'Print',
          onClick: () => this.printTrainingSummary(),
          color: 'blue',
          visible: activeTraining.isCompleted
        }}
      />
    );

    const footer = activeTraining?.isCompleted ? null : (
      <SubmitSaveForLaterFooter
        submitButtonDisabled={!this.canSubmit()}
        submitButtonClick={() =>
          !activeTraining.quiz?.passedQuiz &&
          activeTraining?.quiz?.canBeRetaken &&
          activeTraining.quiz?.attemptNumber <=
            activeTraining?.quiz?.numberOfRetriesAllowed
            ? this.setState({ openAddtionalAttempts: true })
            : this.setState({ openMarkAsCompleteModal: true })
        }
        saveButtonClick={() => this.handleSubmit(false)}
      />
    );

    const percenageNeededToPass =
      (activeTraining?.quiz?.numberOfQuestionsCorrectNeededToPass /
        activeTraining?.quiz?.questions?.length) *
      100;

    const quizButtonText =
      (activeTraining?.quiz?.canBeRetaken &&
        activeTraining?.quiz?.attemptNumber <=
          activeTraining?.quiz?.numberOfRetriesAllowed + 1 &&
        !activeTraining.quiz.passedQuiz) ||
      (!activeTraining?.quiz?.canBeRetaken &&
        !activeTraining?.quiz?.isCompleted)
        ? 'Take Quiz'
        : 'View Quiz';

    const getProjectDropdown = () => {
      let groups;
      if (activeTraining.groupIds?.length) {
        groups = activeTraining.groupIds;
      } else {
        return [];
      }

      let projectsDropdown = [];
      groups.forEach(g => {
        let group = activeCompany?.locations.find(l => l._id === g);
        if (!group) return;
        group.projects.forEach(project =>
          projectsDropdown.push({
            value: project._id,
            label: `${group.name} - ${project.name}`
          })
        );
      });

      return projectsDropdown;
    };

    return (
      <>
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          showFooter={this.props.activeTraining?.isEditing}
          Left={<LeftNav items={this.getQuickNav()} />}
        >
          <Card
            title={activeTraining?.title || this.defaultTrainingTitle}
            showHeader
            name={0}
            wide
          >
            <Textbox
              currentValue={activeTraining?.instructions}
              disabled
              fieldLabel="Instructions"
              type="textarea"
            />
            {activeTraining?.dueDate && (
              <DatePicker
                fieldLabel="Due Date"
                pickTime
                currentValue={activeTraining?.dueDate}
                disabled
                name="performTrainingDueDate"
              />
            )}
            {activeTraining?.trainingType?.value === 2 && (
              <>
                <Dropdown
                  multi
                  selectButtons
                  searchable={true}
                  options={getProjectDropdown()}
                  fieldLabel="Area:"
                  currentValue={projectIds}
                  onChange={projectIds => {
                    this.setState({ projectIds });
                  }}
                  disabled={activeTraining.isCompleted}
                />

                <EmployeeDropdown
                  multi
                  selectButtons
                  testID="attendee"
                  fieldLabel="Attendee(s)"
                  currentValue={assignedTo}
                  onChange={assignedTo => this.setState({ assignedTo })}
                  searchable
                  name="multiEmployees"
                  isRequired
                  disabled={activeTraining.isCompleted}
                  group={activeTraining.groupIds}
                  project={projectIds}
                />
              </>
            )}
          </Card>
          {activeTraining?.attachments?.length ? (
            <Card title="Training Material" showHeader name={1} wide>
              <BlockText blockOfText="This is where you will see all training materials. Please go through each one by one. When you have completed all the training material, sign at the bottom and submit." />
              {activeTraining?.trainingType.value === 1 ? (
                <TrainingTable
                  attachments={activeTraining.attachments}
                  setActiveAttachment={values => {
                    setActiveAttachment(values);
                    history.push('/app/training/TrainingAttachmentViewer');
                  }}
                  trainingType={activeTraining?.trainingType}
                />
              ) : (
                <TrainingMaterials
                  fromApproveTraining
                  attachments={activeTraining.attachments}
                />
              )}
            </Card>
          ) : null}
          {this.getQuickNav().findIndex(nav => nav.label === 'Quiz') > -1 ? (
            <Card
              title={activeTraining?.quiz.title}
              showHeader
              name={2}
              className={preformTrainingStyles.quizCard}
              wide
            >
              <Input fieldLabel="Number of Questions" />
              <span className={preformTrainingStyles.quizAnswers}>
                {activeTraining?.quiz.questions.length}
              </span>
              <Input fieldLabel="Score to Pass" />
              <span className={preformTrainingStyles.quizAnswers}>
                {percenageNeededToPass}%
              </span>
              <Input fieldLabel="Attempts" />
              <span className={preformTrainingStyles.quizAnswers}>
                {activeTraining?.quiz?.numberOfRetriesAllowed + 1 ?? 1}{' '}
                Attempt(s) Allowed
              </span>
              <div>
                {activeTraining?.quizScores?.map((s, index) => (
                  <QuizScoreBox
                    quizScore={s}
                    key={index}
                    attemptNumber={index + 1}
                    didPass={s >= percenageNeededToPass}
                  />
                ))}
              </div>
              <Button
                text={quizButtonText}
                onClick={() => history.push('/app/training/TrainingQuizViewer')}
                color="blue"
                className={preformTrainingStyles.button}
              />
            </Card>
          ) : null}
          {this.getQuickNav().findIndex(
            nav =>
              nav.label === 'Employee Signature' ||
              nav.label === 'Administrator Signature'
          ) > -1 ? (
            <SignatureCard
              wide
              header={
                activeTraining?.trainingType?.value === 2
                  ? 'Administrator Signature'
                  : 'Employee Signature'
              }
              currentValue={this.state.employeeSignature}
              name="Employee Signature"
              onChange={sig => this.setState({ employeeSignature: sig })}
              ownerType="training"
              ownerId={activeTraining._id}
              disabled={activeTraining.isCompleted}
            />
          ) : (
            ''
          )}
        </HeaderAndFooter>
        <SaveChangesModal
          isOpen={openUnsavedChanges}
          savingWhat={activeTraining?.title}
          onRequestClose={() => this.setState({ openUnsavedChanges: false })}
          submitActions={() => this.handleBack()}
        />
        <MarkItemAsCompleteModal
          isOpen={openMarkAsCompleteModal}
          completingWhat="Training"
          onRequestClose={() =>
            this.setState({ openMarkAsCompleteModal: false })
          }
          submitActions={() => this.handleSubmit(true)}
          cancelActions={() => this.handleSubmit(false)}
        />
        <Modal
          title="Additional Quiz Attempts"
          titleClassName="blueHeader"
          isOpen={openAddtionalAttempts}
          submitButtonColor="blue"
          submitButtonText="Complete"
          onRequestClose={() => this.setState({ openAddtionalAttempts: false })}
          submitActions={() => this.handleSubmit(true)}
          cancelActions={() => this.setState({ openAddtionalAttempts: false })}
          cancelButtonText="Cancel"
          cancelButtonColor="white"
        >
          <div className="">
            You have additional attempts available to pass the training quiz.
            Are you sure you would like to submit before completeing additional
            attempts?
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeTraining: getActiveTrainingSelector(state),
  loggedInUser: getLoggedInUser(state),
  activeCompany: getActiveCompany(state)
});

const mapDispatchToProps = dispatch => ({
  setActiveTraining: payload => dispatch(setActiveTrainingResponse(payload)),
  setActiveAttachment: payload =>
    dispatch(setActiveTrainingAttachment(payload)),
  updateTraining: payload => dispatch(updateTrainingRequest(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(PerformTraining);
