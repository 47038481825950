import React, { Component } from 'react';
import Modal from '.';

import styles from './styles.module.scss';

export class DeleteTemplateModal extends Component {
  render() {
    const what = this.props.deletingWhat || 'page';
    return (
      <Modal
        title={`Delete ${what} Template`}
        titleClassName="redHeader"
        isOpen={this.props.isOpen}
        submitButtonColor="red"
        submitButtonText="Delete"
        onRequestClose={this.props.onRequestClose}
        submitActions={this.props.submitActions}
      >
        <div className={styles.text}>
          <span className={styles.warningText}>
            Deleting a {what} template is permanent. No one will be able to view
            or edit this specific {what} template again.
          </span>
          A new {what} template that is similar or identical can still be
          created.
        </div>
      </Modal>
    );
  }
}

export default DeleteTemplateModal;
