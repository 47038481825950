import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';

import history from '../../history';
import { signOut } from '../../actions/auth';
import { leftNavigation } from '../../constants/leftNavigation';
import {
  addDocumentFolderRequest,
  fetchQualificationRequest,
  fetchSafetyInstructionRequest,
  fetchSdsRequest,
  setActiveDocumentFolderRequest
} from '../../actions/documents';
import {
  fetchIncidentBasicsRequest,
  setActiveIncidentResponse
} from '../../actions/incidents';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import {
  getDocumentFoldersSelector,
  getActiveFolderSelector
} from '../../selectors/documents';
import { getLoggedInUser } from '../../selectors/users';
import svgMapper from '../../utils/svgMapper';
import Button from '../../components/inputs/Button';
import LeftNavSection from '../../components/LeftNavSection/';
import Modal from '../../components/Modal';
import UserBadge from '../../components/UserBadge';
import ReportSelector from '../../components/inputs/ReportSelector';

import styles from './mobileHeader.module.scss';
const bStyles = classnames.bind(styles);

export class MobileHeader extends Component {
  state = {
    menuCollapsed: true,
    sectionOpen: 0,
    reportTemplates: [],
    modalOpen: false
  };

  toggleMenuCollapsed = () => {
    this.setState({ menuCollapsed: !this.state.menuCollapsed });
  };

  handleSectionOpen = sectionOpen => {
    this.setState({ sectionOpen });
  };

  handleOpenModal = () => {
    this.setState({
      modalOpen: true,
      menuCollapsed: true
    });
  };

  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  trackNewReportCreation = async () => {};

  handleSubmit = () => {
    this.props.setJsa();
    this.props.setIncident();
    this.handleCloseModal();

    this.trackNewReportCreation();

    if (this.state.reportTemplates.indexOf(8) > -1) {
      history.push('/app/jsaBasics');
    } else {
      this.props.fetchIncidentBasics(this.state.reportTemplates);
      this.props.fetchReportTemplates(this.state.reportTemplates);
    }
  };

  render() {
    const { menuCollapsed, sectionOpen, modalOpen } = this.state;
    const {
      user,
      activeCompany,
      activeLocation,
      activeProject,
      documentFolders,
      addDocumentFolder,
      setActiveFolder,
      fetchSds,
      fetchQualifications,
      fetchSafetyInstructions,
      logOut,
      activeSection,
      activeFolder
    } = this.props;

    return (
      <div className={styles.mobileHeader}>
        <div className={styles.header}>
          <div className={styles.container} onClick={this.toggleMenuCollapsed}>
            <div
              className={bStyles('bar1', {
                change: !menuCollapsed
              })}
            />
            <div
              className={bStyles('bar2', {
                change: !menuCollapsed
              })}
            />
            <div
              className={bStyles('bar3', {
                change: !menuCollapsed
              })}
            />
          </div>
        </div>
        <div
          className={bStyles('sideMenu', {
            hidden: menuCollapsed
          })}
        >
          <aside className={styles.sections}>
            <Button
              text="New Report"
              color="green"
              onClick={() => this.handleOpenModal()}
            />
            <div className={styles.section}>
              <p className={bStyles('label', 'activeSection')}>Navigation</p>
              <ul className={bStyles('menu', 'activeSection')}>
                <li>
                  <Link
                    to={{
                      pathname: '/home'
                    }}
                    onClick={() => this.toggleMenuCollapsed()}
                  >
                    {svgMapper(`home`)} <span>Home</span>
                  </Link>
                </li>
              </ul>
            </div>
            {leftNavigation.sections.map((navSection, index) => {
              return navSection.accessLevel.indexOf(user.accessLevel) !== -1 ? (
                <LeftNavSection
                  fromMobileHeader
                  activeSection={
                    index === activeSection?.[0] ? activeSection[1] : null
                  }
                  activeSubSection={index}
                  title={navSection.title}
                  section={navSection.section}
                  key={index}
                  accessLevel={user.accessLevel}
                  sectionOpen={
                    parseInt(sectionOpen) >= 0
                      ? sectionOpen === index
                      : activeSection?.[0] === index
                  }
                  openNewSection={this.handleSectionOpen}
                  closeModalMenu={this.toggleMenuCollapsed}
                  activeCompany={activeCompany}
                  activeLocation={activeLocation}
                  activeProject={activeProject}
                  documentFolders={documentFolders}
                  addDocumentFolder={addDocumentFolder}
                  setActiveFolder={setActiveFolder}
                  fetchSds={fetchSds}
                  fetchQualifications={fetchQualifications}
                  fetchSafetyInstructions={fetchSafetyInstructions}
                  loggedInUser={user}
                  activeFolder={activeFolder}
                />
              ) : (
                ''
              );
            })}
          </aside>
          <div className={styles.bottomNav}>
            <UserBadge userId={user._id} showName showImage nameUnder />
            <Button
              text="Profile"
              color="blue"
              onClick={() => history.push('/app/profile')}
            />
            <Button text="Logout" color="white" onClick={() => logOut()} />
          </div>
        </div>
        <Modal
          title="Select what happened (one or more)"
          titleClassName="blueHeader"
          isOpen={modalOpen}
          submitButtonColor="blue"
          onRequestClose={this.handleCloseModal}
          submitActions={this.handleSubmit}
        >
          <ReportSelector
            fromMobileHeader
            accessLevel={user.accessLevel}
            handleReportSelection={values =>
              this.setState({ reportTemplates: values })
            }
          />
        </Modal>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  user: getLoggedInUser(state),
  activeCompany: getActiveCompany(state),
  activeLocation: getActiveLocationId(state),
  activeProject: getActiveProject(state),
  documentFolders: getDocumentFoldersSelector(state),
  activeFolder: getActiveFolderSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  fetchIncidentBasics: reportTypes =>
    dispatch(fetchIncidentBasicsRequest(reportTypes)),
  setIncident: () => dispatch(setActiveIncidentResponse({})),
  logOut: () => dispatch(signOut()),
  addDocumentFolder: values => dispatch(addDocumentFolderRequest(values)),
  setActiveFolder: values => dispatch(setActiveDocumentFolderRequest(values)),
  fetchSds: () => dispatch(fetchSdsRequest({ page: 1, limit: 10000 })),
  fetchQualifications: () =>
    dispatch(fetchQualificationRequest({ page: 1, limit: 10000 })),
  fetchSafetyInstructions: () =>
    dispatch(fetchSafetyInstructionRequest({ page: 1, limit: 10000 }))
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
