import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as api from '../../../api';
import history from '../../../history';
import { setActiveTrainingResponse } from '../../../actions/training';
import { getActiveTrainingSelector } from '../../../selectors/training';
import Card from '../../../components/Card';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import QuizCard from '../../../components/training/QuizCard';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import Input from '../../../components/inputs';
import styles from './styles.module.scss';
import QuizScoreBox from '../PerformTraining/quizScoreBox.js';
import LeftNav from '../../../components/LeftNav';

class TrainingQuizViewer extends Component {
  state = {
    hasUnsavedChanges: false,
    openUnsavedChangesModal: false
  };

  handleGoBack = () => {
    if (this.props.activeTraining.isFromSummary) {
      this.props.setActiveTraining(this.props.activeTraining.masterTraining);
    }

    history.goBack();
  };

  handleSubmit = async () => {
    const response = await api.submitQuiz(this.props.activeTraining);
    this.props.setActiveTraining(response);
    history.goBack();
  };

  getQuickNav = () => {
    const { activeTraining } = this.props;

    let leftNav = [{ label: activeTraining.quiz.title, id: '0' }];

    // eslint-disable-next-line no-unused-expressions
    activeTraining?.quiz?.questions?.forEach((q, index) =>
      leftNav.push({ label: q.label, id: index + 1 })
    );

    return leftNav;
  };

  render() {
    const { activeTraining } = this.props;

    const header = (
      <Header
        title="Safety Training"
        section={activeTraining?.quiz?.title}
        needsSaved={this.state.hasUnsavedChanges}
        clickBack={() =>
          this.state.hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : this.handleGoBack()
        }
      />
    );

    const footer = (
      <SaveCancelFooter
        saveButtonClick={() => this.handleSubmit()}
        saveButtonText="Submit Quiz"
        cancelButtonClick={() =>
          this.state.hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : this.handleGoBack()
        }
      />
    );

    const percenageNeededToPass =
      (activeTraining?.quiz?.numberOfQuestionsCorrectNeededToPass /
        activeTraining?.quiz?.questions?.length) *
      100;

    const currentAttempt =
      activeTraining?.quiz?.attemptNumber <=
      activeTraining?.quiz?.numberOfRetriesAllowed + 1
        ? `${activeTraining?.quiz?.attemptNumber}/${activeTraining?.quiz
            ?.numberOfRetriesAllowed + 1 ?? 1}`
        : `${activeTraining?.quiz?.numberOfRetriesAllowed + 1 ??
            1}/${activeTraining?.quiz?.numberOfRetriesAllowed + 1 ?? 1}`;

    return (
      <>
        <HeaderAndFooter
          Header={header}
          showFooter={
            !activeTraining.quiz.isCompleted ||
            (activeTraining?.quiz?.attemptNumber <=
              activeTraining?.quiz?.numberOfRetriesAllowed + 1 &&
              !activeTraining?.quiz.passedQuiz &&
              activeTraining?.quiz?.canBeRetaken)
          }
          Footer={activeTraining.isFromSummary ? null : footer}
          Left={<LeftNav items={this.getQuickNav()} />}
        >
          <Card
            title={activeTraining?.quiz.title}
            showHeader
            className={styles.quizCard}
            border
            name="0"
          >
            <Input fieldLabel="Number of Questions" />
            <span className={styles.quizAnswers}>
              {activeTraining?.quiz.questions.length}
            </span>
            <Input fieldLabel="Score to Pass" />
            <span className={styles.quizAnswers}>{percenageNeededToPass}%</span>
            <Input fieldLabel="Current Attempt" />
            <span className={styles.quizAnswers}>{currentAttempt}</span>
            <div>
              {activeTraining?.quizScores.map((s, index) => (
                <QuizScoreBox
                  quizScore={s}
                  key={index}
                  attemptNumber={index + 1}
                  didPass={s >= percenageNeededToPass}
                />
              ))}
            </div>
          </Card>
          {activeTraining?.quiz?.questions.map((q, index) => (
            <QuizCard
              quiz={activeTraining?.quiz}
              handleQuizAnswers={question => {
                activeTraining.quiz.questions[index] = question;
                this.setState({
                  hasUnsavedChanges: true
                });
              }}
              question={q}
              questionNumber={index}
              key={index}
              name={index + 1}
              isRetaking={
                activeTraining?.quiz?.attemptNumber <=
                  activeTraining?.quiz?.numberOfRetriesAllowed + 1 &&
                !activeTraining?.quiz.passedQuiz &&
                activeTraining?.quiz?.canBeRetaken
              }
              isFromSummary={activeTraining.isFromSummary}
            />
          ))}
        </HeaderAndFooter>
        <SaveChangesModal
          isOpen={this.state.openUnsavedChangesModal}
          savingWhat="Quiz"
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() => history.goBack()}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeTraining: getActiveTrainingSelector(state)
});

const mapDispatchToProps = dispatch => ({
  setActiveTraining: payload => dispatch(setActiveTrainingResponse(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingQuizViewer);
