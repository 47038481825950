import React, { useState, useEffect } from 'react';
import moment from 'moment';
import classnames from 'classnames/bind';
import { fetchWitnessStatement } from '../../../api/v4';
import { DatePicker } from '../DateTimePicker';
import { isFutureDate } from '../../../utils/formValidation';
import Card from '../../../components/Card';
import { Checkbox } from '../../../components/inputs';
import WitnessStatementContainer from '../../../containers/WitnessStatementContainer';

import UserBadge from '../../UserBadge';

import styles from './statement.module.scss';
import Modal from '../../Modal';
const bStyles = classnames.bind(styles);

const getWitnessStatementHeaders = statement => {
  const isPastDue = moment().isAfter(Date.parse(statement.dueDate));

  if (statement.isCompleted) {
    return {
      top: 'Complete',
      style: 'greenOutline'
    };
  } else if (statement.isNa) {
    return {
      top: 'Not Applicable',
      style: 'greenOutline'
    };
  } else if (isPastDue) {
    return {
      top: 'Statement Past Due',
      style: 'redOutline'
    };
  } else if (statement.hasStarted) {
    return {
      top: 'In-Progress',
      style: 'blueOutline'
    };
  } else {
    return {
      top: 'Assigned',
      style: 'blueOutline'
    };
  }
};

export default function WitnessStatementCard({
  statementId,
  index,
  assigneeName,
  handleWsNa,
  handleWsDueDate
}) {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [statement, setStatement] = useState({});
  const [dueDate, setDueDate] = useState(null);
  const [isNa, setIsNa] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [assignedTo, setAssignedTo] = useState(null);
  const [modalDueDate, setModalDueDate] = useState(null);
  const [hasStarted, setHasStarted] = useState(false);

  useEffect(() => {
    fetchWitnessStatement(statementId, false).then(response => {
      setStatement({ ...response });
      setDueDate(response.dueDate);
      setIsNa(response.isNa);
      setIsCompleted(response.isCompleted);
      setAssignedTo(response.assignedTo);
      setModalDueDate(response.dueDate);
      setHasStarted(response.hasStarted);
    });
  }, [statementId]);

  const header = getWitnessStatementHeaders({
    hasStarted,
    isCompleted,
    isNa
  });
  const classes = bStyles({
    button: true,
    disabled: isCompleted || isNa,
    [header.style]: true
  });
  let dueDateFormatted = dueDate
    ? `Due Date: ${moment(dueDate).format('MM/DD/YYYY hh:mm A')}`
    : isCompleted
    ? 'No Due Date Assigned'
    : 'Assign Due Date';
  return (
    <Card
      name={`wsCard${index}`}
      title={`${assigneeName}'s Witness Statement`}
      wide
      showHeader
      key={index}
      testID={`witnessStatementCard${index}`}
    >
      {isCompleted && !isNa ? (
        <WitnessStatementContainer statement={statement} />
      ) : (
        <>
          <Checkbox
            fieldLabel="Mark as Not Applicable"
            currentValue={isNa}
            disabled={isCompleted}
            onChange={value => {
              setIsNa(value);
              handleWsNa(value);
            }}
          />
          <div
            className={classes}
            onClick={() => {
              if (isCompleted || isNa) return;
              setShowDatePicker(true);
              setModalDueDate(dueDate);
            }}
          >
            <UserBadge size="medium" userId={assignedTo} showImage />
            <div>
              <div className={styles.text}>{header.top}</div>
              {!isNa && (
                <div className={`${styles.text} ${styles.notBold}`}>
                  {dueDateFormatted}
                </div>
              )}
            </div>
          </div>
          <Modal
            title="Assign Due Date"
            titleClassName="blueHeader"
            isOpen={showDatePicker}
            submitButtonColor="blue"
            onRequestClose={() => {
              setShowDatePicker(false);
            }}
            disableSubmit={!modalDueDate || isFutureDate(modalDueDate)}
            submitActions={() => {
              setDueDate(modalDueDate);
              handleWsDueDate(modalDueDate);
              setShowDatePicker(false);
            }}
            submitButtonText="Assign"
            cancelActions={() => {
              setShowDatePicker(false);
            }}
            tall
          >
            <DatePicker
              pickTime
              fieldLabel="Due Date"
              isRequired
              onChange={value => setModalDueDate(value)}
              currentValue={modalDueDate}
              name="wsDueDate"
              warning={isFutureDate(modalDueDate)}
              touched={modalDueDate}
              disabled={isCompleted}
            />
          </Modal>
        </>
      )}
    </Card>
  );
}
