import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames/bind';

import {
  createAnalyticsChart,
  deleteAnalyticsChart,
  getAnalyticsDashboard,
  updateAnalyticsDashboard
} from '../../../api/v4';
import history from '../../../history';
import { useDragDrop } from '../../../utils/hooks';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import GearMenu from '../../../components/GearMenu';
import { AddFooter } from '../../../components/Footer';
import AnalyticsChart from '../../../components/AnalyticsChart';
import AnalyticsPeriodPicker from '../../../components/AnalyticsPeriodPicker';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';

import styles from './dashboard.module.scss';
const bStyles = classnames.bind(styles);

export const MyAnalyticsDashboard = props => {
  const [currentPeriod, setCurrentPeriod] = useState({});
  const [dashboard, setDashboard] = useState(null);
  const [confirmDeleteId, setConfirmDeleteId] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const { dashboardId } = useParams();

  const renderItem = chart => (
    <>
      <div className={styles.chartTitle}>
        {chart.title}
        {!readOnly && (
          <GearMenu
            gear
            options={[
              {
                label: 'Detailed View',
                onClick: () =>
                  history.push({
                    pathname: `/app/myAnalytics/detail/${chart._id}`,
                    state: {
                      chart,
                      dashboardId: dashboardId,
                      period: currentPeriod
                    }
                  })
              },
              {
                label: 'Edit',
                onClick: () =>
                  history.push({
                    pathname: `/app/myAnalytics/card/${chart._id}`,
                    state: { chart }
                  })
              },
              {
                label: 'Clone',
                onClick: () => {
                  let newChart = { ...chart };
                  delete newChart._id;
                  newChart.title += ' - Clone';
                  createAnalyticsChart(dashboardId, newChart).then(data => {
                    newChart._id = data.chartId;
                    setDashboard(current => ({
                      ...current,
                      charts: [...current.charts, newChart]
                    }));
                  });
                }
              },
              {
                label: 'Delete',
                color: 'red',
                onClick: () => setConfirmDeleteId(chart._id)
              }
            ]}
          />
        )}
      </div>
      <AnalyticsChart
        fromDashboard
        settings={chart}
        period={currentPeriod}
        className={styles.chart}
        noDataClassName={styles.noData}
        onClick={() =>
          history.push({
            pathname: `/app/myAnalytics/detail/${chart._id}`,
            state: { dashboardId, period: currentPeriod }
          })
        }
      />
    </>
  );

  const { render } = useDragDrop({
    renderItem,
    data: dashboard?.charts,
    disabled: readOnly,
    dropStyle: styles.dropTarget,
    dropFunc: (srcIdx, targetIdx) => {
      let charts = dashboard.charts.map(c => c._id);
      const tmp = charts[srcIdx];
      charts[srcIdx] = charts[targetIdx];
      charts[targetIdx] = tmp;
      updateAnalyticsDashboard(dashboardId, { charts }).then(() => {
        setDashboard(current => {
          let newDashboard = { ...current };
          const t = newDashboard.charts[srcIdx];
          newDashboard.charts[srcIdx] = newDashboard.charts[targetIdx];
          newDashboard.charts[targetIdx] = t;
          return newDashboard;
        });
      });
    }
  });

  useEffect(() => {
    if (!dashboardId) return;

    getAnalyticsDashboard(dashboardId).then(dashboardData => {
      setDashboard(dashboardData);
      setCurrentPeriod({ ...dashboardData.period });
      setReadOnly(dashboardData.readOnly);
    });
  }, [dashboardId]);

  const onPeriodSave = v =>
    setDashboard(current => ({ ...current, period: v }));

  const renameDashboard = newName =>
    setDashboard(current => {
      let newDashboard = { ...current };
      newDashboard.name = newName;
      if (dashboardId) updateAnalyticsDashboard(dashboardId, newDashboard);
      return newDashboard;
    });

  const confirmDeleteChart = () => {
    deleteAnalyticsChart(confirmDeleteId).then(() => {
      setDashboard(current => {
        let newDashboard = { ...current };
        let idx = newDashboard.charts.findIndex(d => d._id === confirmDeleteId);
        newDashboard.charts.splice(idx, 1);
        return newDashboard;
      });
      setConfirmDeleteId(null);
    });
  };

  const newCard = () => {
    history.push({
      pathname: '/app/myAnalytics/card',
      state: { dashboardId }
    });
  };

  const name = dashboard?.name ?? 'New Dashboard';
  const footer = <AddFooter onClick={newCard} />;
  const header = (
    <Header
      readOnly={readOnly}
      title={undefined}
      section={name}
      clickBack={history.goBack}
      editName={renameDashboard}
      rightButtons={{
        text: 'Create Card',
        type: 'button',
        color: 'blue',
        onClick: newCard,
        visible: !readOnly
      }}
    />
  );

  return (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        className={styles.container}
        showFooter={!readOnly}
      >
        <AnalyticsPeriodPicker
          className={styles.datePicker}
          period={dashboard?.period}
          onChange={setCurrentPeriod}
          onSave={onPeriodSave}
          dashboardId={dashboardId}
          readOnly={readOnly}
          showFrequencyPicker
        />
        <div className={bStyles('charts', { readOnly })}>{render()}</div>
      </HeaderAndFooter>
      <DeleteItemModal
        isOpen={confirmDeleteId !== null}
        onRequestClose={() => setConfirmDeleteId(null)}
        submitActions={confirmDeleteChart}
        deletingWhat="Chart"
      />
    </>
  );
};

export default MyAnalyticsDashboard;
