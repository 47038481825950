import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateDefaultPasswordRequest,
  updatePasswordRequest
} from '../../../actions/user';
import { USER_ROLES } from '../../../constants/constants';
import { getLoggedInUser } from '../../../selectors/users';
import Button from '../../../components/inputs/Button';
import Card from '../../../components/Card';
import ChangePasswordForm from '../../../forms/ChangePasswordForm';
import ChangeDefaultPasswordForm from '../../../forms/ChangeDefaultPasswordForm';
import { InputRow, TwoColumn } from '../../../components/inputs';
import Modal from '../../../components/Modal';
import Textbox from '../../../components/inputs/Textbox';
import UserBadge from '../../../components/UserBadge';

import styles from './userprofile.module.scss';

export default function UserProfile({
  isEditing,
  firstName,
  lastName,
  username,
  email,
  phoneNumber,
  setFirstName,
  setLastName,
  setEmail,
  setPhoneNumber,
  setUsername,
  userProfileImage,
  updateUserProfileImage,
  firstNameError,
  lastNameError
}) {
  const activeUser = useSelector(getLoggedInUser);
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [openDefaultPasswordModal, setOpenDefaultPasswordModal] = useState(
    false
  );
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);

  const handleChangePassword = values => {
    dispatch(updatePasswordRequest(values));
    setOpenModal(false);
  };

  const handleChangeDefaultPassword = values => {
    dispatch(updateDefaultPasswordRequest(values));
    setOpenDefaultPasswordModal(false);
  };

  return (
    <Card title="User Info" showHeader>
      <TwoColumn>
        <InputRow className={styles.userAccessLevel}>
          {USER_ROLES?.find(role => role.value === activeUser.accessLevel)
            ?.label ?? undefined}
          {activeUser.isAccountOwner ? ' (Account Owner)' : undefined}
        </InputRow>
        <InputRow className={styles.right}>
          {activeUser.isAccountOwner && (
            <Button
              color="blue"
              text="Change Users Default Password"
              onClick={() => setOpenDefaultPasswordModal(true)}
            />
          )}
          <Button
            testID="changePassword"
            color="blue"
            text="Change Password"
            onClick={() => setOpenModal(true)}
          />
        </InputRow>
      </TwoColumn>
      <TwoColumn className={styles.twoColumn}>
        <UserBadge
          userId={activeUser?._id}
          size="xx-large"
          showImage
          onClick={() => (isEditing ? inputRef.current.click() : console.log())}
          data={userProfileImage}
        />
        <input
          type="file"
          name="myfile"
          onChange={e => updateUserProfileImage(e)}
          className={styles.fileInput}
          ref={inputRef}
        />
        <div>
          <InputRow className={styles.inputRow}>
            <Textbox
              fieldLabel="First Name:"
              className={styles.nameBox}
              currentValue={firstName}
              disabled={!isEditing}
              onChange={value => {
                setFirstNameTouched(true);
                setFirstName(value);
              }}
              touched={firstNameTouched}
              error={firstNameError}
            />
            <Textbox
              fieldLabel="Last Name:"
              className={styles.nameBox}
              currentValue={lastName}
              disabled={!isEditing}
              onChange={value => {
                setLastNameTouched(true);
                setLastName(value);
              }}
              touched={lastNameTouched}
              error={lastNameError}
            />
          </InputRow>
          <Textbox
            fieldLabel="Username:"
            className={styles.nameBox}
            currentValue={username}
            disabled={!isEditing}
            onChange={setUsername}
          />
          <Textbox
            fieldLabel="Contact Info (at least one):"
            currentValue={email}
            placeholder="Email"
            disabled={!isEditing}
            onChange={setEmail}
          />
          <Textbox
            currentValue={phoneNumber}
            placeholder="Phone Number"
            disabled={!isEditing}
            onChange={setPhoneNumber}
          />
        </div>
      </TwoColumn>

      {openModal && (
        <Modal
          testID="changePassword"
          title="Change Password"
          titleClassName="blueHeader"
          isOpen={openModal}
          submitButtonColor="green"
          onRequestClose={() => setOpenModal(false)}
          formName="ChangePasswordForm"
        >
          <ChangePasswordForm onSubmit={handleChangePassword} />
        </Modal>
      )}
      {openDefaultPasswordModal && (
        <Modal
          title="Change Users Default Password"
          titleClassName="blueHeader"
          isOpen={openDefaultPasswordModal}
          submitButtonColor="green"
          onRequestClose={() => setOpenDefaultPasswordModal(false)}
          formName="ChangeDefaultPasswordForm"
        >
          <ChangeDefaultPasswordForm
            onSubmit={values => handleChangeDefaultPassword(values)}
          />
        </Modal>
      )}
    </Card>
  );
}
