import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../history';
import {
  fetchCustomDocumentTemplatesRequest,
  setActiveCustomDocumentTemplate
} from '../../actions/documents';
import { getCustomDocumentTemplatesSelector } from '../../selectors/documents';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import List from '../../components/List';
import Modal from '../../components/Modal';
import { Dropdown } from '../../components/inputs';

export default function CustomDocumentList() {
  const [openModal, setOpenModal] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);

  const dispatch = useDispatch();
  const templates = useSelector(getCustomDocumentTemplatesSelector);

  useEffect(() => {
    dispatch(fetchCustomDocumentTemplatesRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const templateDropdown = templates?.map(t => {
    return { value: t._id, label: t.label };
  });

  const header = (
    <Header
      title="Custom Document Templates"
      pageActionOptions={[
        {
          label: 'Create Document Template',
          visible: true,
          color: 'blueOutline',
          onClick: () => {
            dispatch(setActiveCustomDocumentTemplate({}));
            history.push('/app/customDocumentTemplateContainer');
          }
        },
        {
          label: 'Duplicate Document Template',
          onClick: () => setOpenModal(true),
          color: 'blueOutline',
          visible: templates?.length > 0
        }
      ]}
    />
  );

  const handleDuplicate = template => {
    let templateToCopy = templates.find(t => t._id === template);
    delete templateToCopy._id;
    templateToCopy.fields.forEach(f => delete f._id);
    dispatch(
      setActiveCustomDocumentTemplate({
        ...templateToCopy,
        label: `${templateToCopy.label} - Copy`,
        duplicated: true
      })
    );

    history.push('/app/customDocumentTemplateContainer');
  };

  const columns = [
    { key: 'label', label: 'Title' },
    { key: 'createdAt', label: 'Created Date', datatype: 'date' },
    { key: 'updatedAt', label: 'Updated Date', datatype: 'date' }
  ];

  return (
    <HeaderAndFooter Header={header}>
      <List
        saveKey="customDocTempList"
        rowClick={row => {
          dispatch(setActiveCustomDocumentTemplate(row));
          history.push('/app/customDocumentTemplateContainer');
        }}
        dataIsHash
        data={templates}
        settings={columns}
      />
      <Modal
        title="Choose Template"
        titleClassName="blueHeader"
        isOpen={openModal}
        submitButtonColor="blue"
        submitButtonText="Create"
        onRequestClose={() => setOpenModal(false)}
        submitActions={() => handleDuplicate(currentTemplate)}
        disableSubmit={!currentTemplate}
      >
        <Dropdown
          options={templateDropdown}
          fieldLabel="Custom Document Template"
          currentValue={currentTemplate}
          isRequired
          onChange={value => setCurrentTemplate(value)}
          placeholder="Select a template"
          searchable
        />
      </Modal>
    </HeaderAndFooter>
  );
}
