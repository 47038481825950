import React, { Component } from 'react';
import {
  normalizePhone,
  email as validateEmail
} from '../../utils/formValidation';
import { InputRow } from '../../components/inputs';
import Button from '../../components/inputs/Button';
import Textbox from '../../components/inputs/Textbox';
import Checkbox from '../../components/inputs/Checkbox';
import UserAccessButtons from '../../components/inputs/UserAccessButtons';

import { resendWelcomeNotification, resetPassword } from '../../api/v4';

class UserForm extends Component {
  state = {
    username: '',
    accessLevel: '',
    disableUserName: false,
    isDeactivated: false,
    _id: undefined,
    isValidEmail: true
  };

  componentDidMount = () => {
    const { user } = this.props;

    if (user?._id) {
      this.setState({
        username: user.username,
        accessLevel: user.accessLevel,
        isAccountOwner: user.isAccountOwner,
        phoneNumber: user.phoneNumber,
        email: user.email,
        disableUserName: user.username,
        _id: user._id,
        isDeactivated: user.isDeactivated
      });
    }
  };

  componentDidUpdate = prevProps => {
    const { user } = this.props;
    if (user && JSON.stringify(user) !== JSON.stringify(prevProps.user)) {
      this.setState({
        username: user.username,
        accessLevel: user.accessLevel,
        isAccountOwner: user.isAccountOwner,
        phoneNumber: user.phoneNumber,
        email: user.email,
        disableUserName: user.username,
        isDeactivated: user.isDeactivated
      });
    }
  };

  updateState = stateObject => {
    this.setState(stateObject);
  };

  handleSubmit = () => {
    this.setState(
      state => ({ isValidEmail: validateEmail(state.email) === undefined }),
      () =>
        this.state.isValidEmail &&
        this.props.updateActiveUser({ ...this.state })
    );
  };

  render() {
    const {
      accessLevel,
      username,
      oldAccessLevel,
      isAccountOwner,
      email,
      isValidEmail,
      phoneNumber,
      disableUserName,
      _id,
      isDeactivated
    } = this.state;

    return (
      <div className="addUserForm">
        <InputRow right>
          <Button
            text="Update"
            color="blue"
            onClick={() => this.handleSubmit()}
            disabled={!username}
          />
          <Button
            text="Reset Password"
            color="blue"
            onClick={() => resetPassword(_id)}
            disabled={!_id}
          />
          <Button
            text="Resend 'Welcome Notification' to User"
            color="blue"
            onClick={() => resendWelcomeNotification(_id)}
            disabled={!_id}
          />
        </InputRow>
        <div className="addUserForm-userInfo">
          <Textbox
            fieldLabel="User Name:"
            onChange={username => this.updateState({ username })}
            currentValue={username}
            disabled={disableUserName}
          />
          <Textbox
            fieldLabel="Email:"
            onChange={email => this.updateState({ email })}
            currentValue={email}
            placeholder="eg@email.com"
            error={isValidEmail ? '' : 'Must be a valid email format'}
            touched
          />
          <Textbox
            fieldLabel="Phone:"
            onChange={v => this.updateState({ phoneNumber: normalizePhone(v) })}
            currentValue={phoneNumber}
            placeholder="(000) 000-0000"
          />
          {!isAccountOwner && (
            <Checkbox
              fieldLabel="Deactivate User (user will not be able to log in)"
              onChange={v => {
                this.updateState({ isDeactivated: v });
              }}
              currentValue={isDeactivated}
            />
          )}
          {!isAccountOwner && (
            <UserAccessButtons
              onClick={value => this.updateState({ accessLevel: value })}
              currentValue={accessLevel !== '' ? accessLevel : oldAccessLevel}
            />
          )}
        </div>
      </div>
    );
  }
}

export default UserForm;
