import React from 'react';
import './svg.css';

export default props => (
  <svg
    version="1.1"
    x={props.x || "0px"}
    y={props.y || "0px"}
    viewBox="0 0 96.261 96.261"
    width="30px"
    height="25px"
  >
    <g>
      <path d="M79.34,45.051c3.503-4.197,5.57-9.918,5.57-16.019c0-12.607-8.761-22.864-19.529-22.864c-7.47,0-13.968,4.939-17.25,12.172   c-3.282-7.233-9.78-12.172-17.25-12.172c-10.769,0-19.529,10.257-19.529,22.864c0,6.115,2.065,11.827,5.569,16.018   C10.796,47.93,0,53.331,0,69.592v6h6h12.421c-0.737,2.447-1.173,5.255-1.173,8.501v6h6h24.704h0.353h24.704h6v-6   c0-3.246-0.436-6.054-1.173-8.501h12.425h6v-6C96.261,53.332,85.465,47.931,79.34,45.051z M21.221,69.592H6   c0-18.067,15.867-18.062,19.388-22.786l0.403-2.154c-4.947-2.508-8.439-8.551-8.439-15.621c0-9.313,6.058-16.864,13.529-16.864   c5.292,0,9.86,3.798,12.081,9.319C34.694,24.146,28.6,33.02,28.6,43.532c0,6.115,2.065,11.827,5.569,16.017   C30.419,61.313,24.923,64.026,21.221,69.592z M48.305,84.093h-0.353H23.248c0-18.066,15.867-18.062,19.388-22.786l0.403-2.154   c-4.947-2.508-8.439-8.551-8.439-15.621c0-9.313,6.058-16.864,13.529-16.864c7.471,0,13.529,7.551,13.529,16.864   c0,7.008-3.43,13.015-8.311,15.561l0.459,2.449c3.863,4.495,19.202,4.788,19.202,22.551C73.008,84.093,48.305,84.093,48.305,84.093   z M75.036,69.592c-3.702-5.565-9.199-8.278-12.948-10.041c3.503-4.197,5.57-9.918,5.57-16.019c0-10.512-6.093-19.384-14.359-22.045   c2.221-5.522,6.79-9.32,12.082-9.32c7.472,0,13.529,7.551,13.529,16.864c0,7.009-3.43,13.015-8.311,15.562l0.459,2.449   c3.863,4.495,19.202,4.788,19.202,22.55H75.036z" />
    </g>
  </svg>
);
