import React from 'react';
import './svg.css';

export default props => (
  <svg
    data-v-5afa3ed0=""
    data-name="Layer 1"
    viewBox="0 0 25 25"
    x={props.x || "0px"}
    y={props.y || "0px"}
    width="30px"
    height="25px"
  >
    <path
      className="st0"
      d="M21,10.5C21,4.7,16.3,0,10.5,0S0,4.7,0,10.5S4.7,21,10.5,21c1.9,0,3.6-0.5,5.1-1.3l4.8,1.2
	c0.2,0.1,0.5-0.2,0.4-0.4l-1.2-4.8C20.5,14.1,21,12.3,21,10.5z M11.3,17.3H9.5v-1.9h1.8V17.3z M11.4,12.1c-0.2,0.4-0.3,1-0.3,1.7
	H9.5c0-0.9,0.1-1.6,0.3-2.1c0.2-0.5,0.7-1.1,1.4-1.8l0.7-0.7c0.2-0.2,0.4-0.4,0.5-0.6c0.3-0.4,0.4-0.8,0.4-1.2
	c0-0.6-0.2-1.1-0.5-1.6c-0.4-0.4-1-0.7-1.8-0.7c-1,0-1.8,0.4-2.2,1.1C8.1,6.7,8,7.3,8,8.1H6.4c0-1.3,0.4-2.4,1.1-3.2
	c0.8-0.8,1.8-1.2,3.1-1.2c1.2,0,2.2,0.3,2.9,1c0.7,0.7,1.1,1.6,1.1,2.6c0,0.6-0.1,1.2-0.4,1.6c-0.3,0.4-0.8,1-1.6,1.8
	C12,11.2,11.6,11.7,11.4,12.1z"
    />
  </svg>
);
