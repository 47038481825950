import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from '../../history';
import {
  fetchCustomDocumentsRequest,
  setActiveCustomDocument,
  setActiveCustomDocumentTemplate
} from '../../actions/documents';
import {
  getCustomDocumentsSelector,
  getCustomDocumentTemplatesSelector
} from '../../selectors/documents';
import { addMessage } from '../../actions/messages';
import { getLoggedInUser } from '../../selectors/users';
import Dropdown from '../../components/inputs/Dropdown';
import Modal from '../../components/Modal';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import List from '../../components/List';
import { getDistinctCustomDocTitles, exportCustomDocs } from '../../api/v4';

export class CustomDocumentList extends Component {
  state = {
    openCreateDocumentModal: false,
    selectedTemplate: '',
    openExportDocumentModal: false,
    titles: [],
    selectedTitle: ''
  };

  componentDidMount() {
    this.props.fetchCustomTemplates();
    getDistinctCustomDocTitles().then(response =>
      this.setState({
        titles: response.map(r => ({ label: r, value: r }))
      })
    );
  }

  handleCreateDocument = () => {
    const {
      templates,
      setActiveCustomDocument,
      setActiveCustomDocumentTemplate
    } = this.props;
    const { selectedTemplate } = this.state;

    const selected = templates.filter(
      template => template.label === selectedTemplate.label
    )[0];

    setActiveCustomDocument({});
    setActiveCustomDocumentTemplate(selected);
    history.push('/app/customDocumentContainer');
  };

  handleViewDocument = document => {
    const {
      templates,
      setActiveCustomDocument,
      setActiveCustomDocumentTemplate
    } = this.props;

    const selected = templates.filter(
      template => template.label === document.label
    )[0];

    setActiveCustomDocument(document);
    setActiveCustomDocumentTemplate(selected);
    history.push('/app/customDocumentContainer');
  };

  handleExportDocument = async () => {
    this.setState({
      openExportDocumentModal: false,
      selectedTitle: ''
    });
    try {
      const src = await exportCustomDocs(this.state.selectedTitle);
      window.location = src;
    } catch (e) {
      this.props.addMessage({ error: true, message: e?.data });
    }
  };

  render() {
    const { templates, list } = this.props;
    const {
      openCreateDocumentModal,
      selectedTemplate,
      openExportDocumentModal,
      titles,
      selectedTitle
    } = this.state;

    const templateDropdown = templates?.map(template => {
      return { value: template.label, label: template.label };
    });

    const columns = [
      { key: 'label', label: 'Title' },
      {
        key: 'createdByUserId',
        label: 'Created By',
        datatype: 'user',
        accessor: r => r?.auditLog[0]?.user ?? r?.auditLog[0]?.userId
      },
      { key: 'createdAt', label: 'Created Date', datatype: 'date' },
      { key: 'updatedAt', label: 'Updated Date', datatype: 'date' }
    ];

    const header = (
      <Header
        title="Custom Documents"
        pageActionOptions={[
          {
            label: 'Create Document',
            visible: true,
            color: 'blueOutline',
            onClick: () => this.setState({ openCreateDocumentModal: true })
          },
          {
            label: 'Export Custom Document',
            visible: true,
            color: 'blueOutline',
            onClick: () =>
              this.setState({
                openExportDocumentModal: true
              })
          }
        ]}
      />
    );

    return (
      <>
        <HeaderAndFooter Header={header}>
          <List
            saveKey="customDocList"
            data={list}
            dataIsHash
            settings={columns}
            rowClick={this.handleViewDocument}
          />
        </HeaderAndFooter>
        <Modal
          title="Create Custom Document"
          titleClassName="blueHeader"
          isOpen={openCreateDocumentModal}
          submitButtonColor="blue"
          submitButtonText="Create Document"
          onRequestClose={() =>
            this.setState({ openCreateDocumentModal: false })
          }
          submitActions={() => this.handleCreateDocument()}
          disableSubmit={!selectedTemplate}
        >
          <Dropdown
            fieldLabel="Select Custom Document to add"
            options={templateDropdown}
            searchable
            name="templateDD"
            onChange={values => this.setState({ selectedTemplate: values })}
            isRequired
            currentValue={selectedTemplate}
            bareValues={false}
          />
        </Modal>
        <Modal
          title="Export Custom Document"
          titleClassName="blueHeader"
          isOpen={openExportDocumentModal}
          submitButtonColor="blue"
          submitButtonText="Export"
          onRequestClose={() =>
            this.setState({ openExportDocumentModal: false })
          }
          submitActions={() => this.handleExportDocument()}
          disableSubmit={!selectedTitle}
        >
          <Dropdown
            fieldLabel="Select Custom Document to export"
            options={titles}
            searchable
            name="titleDD"
            onChange={values => this.setState({ selectedTitle: values })}
            isRequired
            currentValue={selectedTitle}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  templates: getCustomDocumentTemplatesSelector(state),
  list: getCustomDocumentsSelector(state),
  activeUser: getLoggedInUser(state)
});

const mapDispatchToProps = dispatch => ({
  fetchCustomTemplates: () => dispatch(fetchCustomDocumentsRequest()),
  setActiveCustomDocument: payload =>
    dispatch(setActiveCustomDocument(payload)),
  setActiveCustomDocumentTemplate: payload =>
    dispatch(setActiveCustomDocumentTemplate(payload)),
  addMessage: payload => dispatch(addMessage(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomDocumentList);
