import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  fetchSafetyAudits,
  fetchSafetyAuditRepeatables,
  fetchSafetyAuditScanOnDemands
} from '../../api/v4';

import history from '../../history';
import { getActiveLocationId, getActiveProject } from '../../selectors/company';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import List from '../../components/List';
import TypeSelection from '../../components/TypeSelection';
import RepeatableList from '../../components/RepeatableList';

export default function SafetyAudits() {
  const activeGroup = useSelector(getActiveLocationId);
  const activeProject = useSelector(getActiveProject);
  const [audits, setAudits] = useState([]);
  const [repeatables, setRepeatables] = useState([]);
  const [scans, setScans] = useState([]);
  const [schedule, setSchedule] = useState('Active');
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    fetchSafetyAudits().then(setAudits);
    fetchSafetyAuditRepeatables('safetyAudit').then(setRepeatables);
    fetchSafetyAuditScanOnDemands('safetyAudit').then(setScans);
  }, []);

  const header = (
    <Header
      title="Safety Audits"
      rightButtons={{
        color: 'blue',
        text: 'Add Safety Audit',
        onClick: () => history.push(`/app/safetyAudits/create/`)
      }}
    />
  );

  const repeatingColumns = [
    {
      key: 'title',
      label: 'Safety Audit(s)'
    },
    {
      key: 'lastUpdatedAt',
      label: 'Last Updated',
      datatype: 'date'
    },
    {
      key: 'repeatingOn',
      label: 'Repeat On',
      datatype: 'dow',
      frequency: 'frequency'
    }
  ];

  const onDemandColumns = [
    {
      key: 'title',
      label: 'Safety Audit(s)'
    },
    {
      key: 'lastUpdatedAt',
      label: 'Last Updated',
      datatype: 'date'
    },
    {
      key: 'qrCodeUrl',
      label: 'QR Code',
      datatype: 'url',
      text: 'Download QR Code'
    }
  ];

  const activeColumns = [
    { key: 'safetyAuditNumber', label: 'SA Number' },
    {
      key: 'title',
      label: 'Safety Audit(s)'
    },
    {
      key: 'assignedTo',
      datatype: 'users'
    },
    {
      key: 'dueDate',
      label: 'Due By',
      datatype: 'futureDate'
    },
    {
      key: 'lastUpdatedAt',
      label: 'Last Updated',
      datatype: 'date'
    }
  ];

  if (!activeProject) {
    activeColumns.unshift({
      key: 'projectId',
      label: 'Area',
      datatype: 'project'
    });
    repeatingColumns.unshift({
      key: 'projectId',
      label: 'Area',
      datatype: 'project'
    });
    onDemandColumns.unshift({
      key: 'projectId',
      label: 'Area',
      datatype: 'project'
    });
  }

  if (!activeGroup) {
    activeColumns.unshift({
      key: 'groupId',
      label: 'Group/Est.',
      datatype: 'group'
    });
    repeatingColumns.unshift({
      key: 'groupId',
      label: 'Group/Est.',
      datatype: 'group'
    });
    onDemandColumns.unshift({
      key: 'groupId',
      label: 'Group/Est.',
      datatype: 'group'
    });
  }

  const statuses = ['New', 'In Progress', 'Severity Review', 'Closed'];

  const reportContent = statuses.map((status, index) => {
    return audits.filter(jsa => jsa.currentWfStage === index);
  });

  const tabs = {
    selected: selectedTab,
    onClick: i => setSelectedTab(i),
    list: statuses.map((name, i) => ({
      color: 'blue',
      name,
      count: reportContent[i]?.length
    }))
  };

  return (
    <HeaderAndFooter Header={header}>
      <TypeSelection
        selected={schedule}
        selectedArray={['Active', 'Repeating', 'On Demand']}
        onClick={value => setSchedule(value)}
      />
      {schedule === 'Repeating' ? (
        <RepeatableList
          repeatables={repeatables}
          repeatingColumns={repeatingColumns}
          handleRowClick={row =>
            history.push(`/app/safetyAudits/create/${row._id}/repeatable`)
          }
          saveKey="repeatingRas"
          type="Safety Audits"
          updateData={() =>
            fetchSafetyAuditRepeatables('safetyAudit').then(response =>
              setRepeatables(response)
            )
          }
        />
      ) : schedule === 'On Demand' ? (
        <List
          saveKey="scanRas"
          rowClick={row =>
            history.push(`/app/safetyAudits/create/${row._id}/scan`)
          }
          dataIsHash
          data={scans}
          settings={onDemandColumns}
        />
      ) : (
        <List
          saveKey="immediateRas"
          rowClick={row =>
            row.isCompleted
              ? history.push(`/app/safetyAudits/summary/${row._id}`)
              : history.push(`/app/safetyAudits/perform/${row._id}`)
          }
          data={reportContent[selectedTab]}
          dataIsHash
          settings={activeColumns}
          tabs={tabs}
          getRowId={r => r._id}
        />
      )}
    </HeaderAndFooter>
  );
}
