import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';

import history from '../../history';
import svgMapper from '../../utils/svgMapper';
import Modal from '../../components/Modal';
import Textbox from '../../components/inputs/Textbox';
import Button from '../../components/inputs/Button';

import styles from './leftNavSection.module.scss';
const bStyles = classnames.bind(styles);

class LeftNavSection extends PureComponent {
  state = {
    documentType: '',
    folderName: '',
    openModal: false,
    folderExists: false
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.activeFolder !== this.props.activeFolder &&
      Object.keys(this.props.activeFolder).length === 0
    ) {
      this.setState({ openFolder: 'All Folders' });
    }
  }

  handleAddFolder = async () => {
    const trimmed = this.state.folderName.trim();
    if (
      this.props.documentFolders.some(
        f => !f.name.localeCompare(trimmed, 'en', { sensitivity: 'base' })
      )
    ) {
      this.setState({ folderExists: true });
      return;
    }
    await this.props.addDocumentFolder({
      documentType: this.state.documentType,
      name: this.state.folderName
    });
    this.handleCloseModal();
  };

  openFolder = (folder, link) => {
    this.props.setActiveFolder({ ...folder, page: 1, limit: 10000 });
    history.push(link);
  };

  handleOpenModal = value => {
    this.setState({ openModal: true, documentType: value });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false, folderName: '', folderExists: false });
  };

  fetchAllDocuments = (documentType, link) => {
    this.props.setActiveFolder({});

    if (documentType === 'SDS') {
      this.props.fetchSds();
    } else if (documentType === 'SafetyInstruction') {
      this.props.fetchSafetyInstructions();
    } else if (documentType === 'SafetyTraining') {
      this.props.fetchTrainings();
    } else if (documentType === 'Qualification') {
      this.props.fetchQualifications();
    }

    history.push(link);
  };

  openNewSection = () => {
    this.props.openNewSection(this.props.activeSubSection);
  };

  render() {
    const shouldDisplay = (
      navItem,
      project,
      location,
      company,
      accessLevel,
      loggedInUser
    ) => {
      const navItemAccess = navItem.accessLevel.indexOf(accessLevel) !== -1;
      const navItemAppProject =
        navItem.appLevel.indexOf('Project') !== -1 && project;
      const navItemAppLocation =
        navItem.appLevel.indexOf('Group') !== -1 && location;
      const navItemAppCompany =
        navItem.appLevel.indexOf('Division') !== -1 && company;

      if (!navItem.showFeature) {
        return false;
      }

      if (
        (navItem.text === 'Aircraft Reports' ||
          navItem.text === 'Spill/Misfuel Reports') &&
        !activeCompany.showAircraft
      )
        return false;

      if (navItem.text === 'My Analytics' && activeCompany.hideAnalytics)
        return false;

      if (
        navItem.text === 'Covid-19 Tracking' &&
        !activeCompany.turnOnCovidTracking
      )
        return false;

      if (
        navItem.text === 'Claims' &&
        ((!activeCompany.showClaims && !loggedInUser.isAccountOwner) ||
          !activeCompany.showClaims)
      ) {
        return false;
      }

      if (project) {
        return navItemAccess && navItemAppProject;
      } else if (location) {
        return navItemAccess && navItemAppLocation;
      } else {
        return navItemAccess && navItemAppCompany;
      }
    };

    const {
      title,
      section,
      accessLevel,
      sectionOpen,
      activeCompany,
      activeLocation,
      activeProject,
      documentFolders,
      activeFolder,
      activeSection,
      testID,
      fromMobileHeader,
      loggedInUser
    } = this.props;

    const { folderName, openModal } = this.state;
    return (
      <div
        className={styles.section}
        data-cy={testID}
        onClick={this.openNewSection}
      >
        <p
          className={bStyles('label', {
            activeSection: sectionOpen
          })}
        >
          {title}
        </p>
        <ul
          className={bStyles('menu', {
            activeSection: sectionOpen
          })}
        >
          {section.map((navItem, index) => {
            let active = index === activeSection;
            return shouldDisplay(
              navItem,
              activeProject,
              activeLocation,
              activeCompany,
              accessLevel,
              loggedInUser
            ) ? (
              <li
                key={index}
                data-cy={
                  fromMobileHeader ? `${navItem.text}Mobile` : navItem.text
                }
              >
                <Link
                  className={bStyles({ active })}
                  to={{ pathname: navItem.link }}
                  onClick={() =>
                    this.fetchAllDocuments(navItem.folderType, navItem.link)
                  }
                >
                  {navItem.text === 'Require Acknowledgment' ? (
                    <img
                      src={require('../../assets/images/Require Authorization.png')}
                      alt="Require Authorzation option"
                      style={{
                        width: '20px',
                        height: '20px',
                        paddingTop: '5px'
                      }}
                    />
                  ) : (
                    svgMapper(navItem.image)
                  )}
                  <span>
                    {activeCompany.reportingType === 2 &&
                    navItem.text === 'OSHA Forms'
                      ? 'PERRP Forms'
                      : activeCompany.isDash && navItem.text === 'Safety Walks'
                      ? 'DASH Report'
                      : navItem.text}
                  </span>
                </Link>
                {navItem.hasFolders && active && (
                  <div className={styles.folderContainer}>
                    <div
                      className={bStyles('allFolder', {
                        selected: activeFolder?._id === undefined
                      })}
                      onClick={() =>
                        this.fetchAllDocuments(navItem.folderType, navItem.link)
                      }
                    >
                      {activeFolder?._id === undefined ? (
                        <img
                          src={require('../../assets/images/Folder Open Black.png')}
                          className={styles.addButton}
                          alt="folder open"
                        />
                      ) : (
                        <img
                          src={require('../../assets/images/Folder Closed White.png')}
                          className={styles.addButton}
                          alt="folder closed"
                        />
                      )}
                      All Folders
                    </div>
                    <ul className={styles.folderList}>
                      {documentFolders.map((folder, index) => (
                        <li
                          key={index}
                          className={bStyles({
                            selected: activeFolder?._id === folder._id
                          })}
                          onClick={() => this.openFolder(folder, navItem.link)}
                        >
                          {activeFolder?._id === folder._id ? (
                            <img
                              src={require('../../assets/images/Folder Open Black.png')}
                              className={styles.addButton}
                              alt="folder open"
                            />
                          ) : (
                            <img
                              src={require('../../assets/images/Folder Closed White.png')}
                              className={styles.addButton}
                              alt="folder closed"
                            />
                          )}{' '}
                          {folder.name}
                        </li>
                      ))}
                      {accessLevel !== 100 && (
                        <li>
                          <Button
                            color="blue"
                            text="Add Folder"
                            onClick={() =>
                              this.handleOpenModal(navItem.folderType)
                            }
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </li>
            ) : (
              ''
            );
          })}
        </ul>
        <Modal
          title="Add Folder"
          titleClassName="greenHeader"
          isOpen={openModal}
          onRequestClose={this.handleCloseModal}
          submitActions={this.handleAddFolder}
          submitButtonColor="green"
          disableSubmit={folderName.trim().length < 3}
        >
          <Textbox
            fieldLabel="Folder Name"
            onChange={v =>
              this.setState({ folderName: v, folderExists: false })
            }
            isRequired={true}
            currentValue={folderName}
            touched={this.state.folderExists}
            error="This folder name already exists"
          />
        </Modal>
      </div>
    );
  }
}

export default LeftNavSection;
