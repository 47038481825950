import React from 'react';
import './svg.css';

export default props => (
  <svg
    version="1.1"
    x={props.x || "0px"}
    y={props.y || "0px"}
    width="30px"
    height="25px"
    viewBox="0 0 1000 1000"
  >
    <g id="Layer_2">
      <g id="Layer_2-2">
        <path
          d="M513.7,0l11.2,1l27.2,5.5l9.6,2.7l25.1,9.5c2.5,2.5,5.8,4.1,9.3,4.6l20.2,12.4l4.9,3.4L638.4,53l7.5,7.2
                        l13.7,15.4l7.1,9.3l11.5,18l3.9,7l11.4,26.4l1.5,5.8l6.6,27.6l1.8,10.8l1.5,32c-1.4,4.4-1.7,9.1-1.1,13.7l-5.1,38.7
                        c-1.9,4.2-3,8.8-3.1,13.4c-5.5,18-11.8,35.7-19.1,53L664.1,356c-15.2,23.1-31.8,44.9-52.7,63.3c-7.6,6.7-1.7,15.4-1.3,23.5
                        c-0.1,5.1,1.2,10.2,3.9,14.6c1.2,7.6,6,14.2,12.8,17.9c3.5,3,8.1,4.4,12.7,3.9l29.4,6.2l9.3,1.5l27.2,5.6l11,2.2l28.1,5.1
                        c2.8,1.6,6,2.4,9.3,2.1c9.7,2.7,19.6,4.5,29.7,5.4c2.7,1.8,5.9,2.4,9.1,1.8l27.4,6.7c2.4,2.5,5.7,2.5,8.8,3.3l20.1,8.6l9.9,5.9
                        l17.1,12.4l7.6,7l15.4,19.4l1.9,3.1l11.6,22.9l3.5,8.8l7,27.3l2,9.3l2.8,29.6c-0.6,4.6-0.3,9.3,1.1,13.8
                        c-1.1,10.6-1.1,21.2,0,31.8c-1.2,4.6-1.2,9.4,0,13.9c-1.2,10.5-1.2,21.2,0,31.7c-1.2,4.6-1.2,9.3,0,13.9
                        c-1.2,10.5-1.2,21.2,0,31.7c-1.2,4.6-1.2,9.3,0,13.9c-1.2,10.5-1.2,21.2,0,31.7c-1.2,4.6-1.2,9.3,0,13.9l-1.1,32.2l-1.8,13
                        c-11.7,42.6-39,70-81.2,82.7l-13.7,2.3l-36.5,0.4H785h-34.2h-11.5h-36.5h-9.3H657h-9.3h-36.5h-9.3h-36.5h-9.3H520h-9.3l-34.2-0.6
                        c-2.8-1.7-6-2.5-9.3-2l-21.9-7.5l-9.5-5.4L419,972.4l-8.9-9l-11.7-16.2l-4.1-6.9l-9-25.1c0.3-4-0.3-7.9-1.9-11.6l1.5-34l4.5-16
                        c12.4-30.4,36.8-54.3,67.4-65.9l15.4-3.7l34.5-2h11.5h34.1c27.1,0,21.1,6.7,25.2-22.2c0-0.8-0.1-1.5-0.2-2.3
                        c2.9-12.8,4.6-25.7,5.2-38.8c1.9-4.3,2.5-9,1.8-13.7c2.9-12.8,4.7-25.7,5.3-38.8c1.8-4.3,2.4-9,1.7-13.6
                        c2.9-12.7,4.7-25.6,5.4-38.6c1.7-4.4,2.2-9.2,1.6-13.9c3.5-12.8,4.1-25.9,5.7-39c1.3-4.4,1.8-8.9,1.3-13.5
                        c3.6-12,3.4-24.5,5.7-36.7c0.5-2-0.6-4.1-2.7-4.7c-0.3-0.1-0.5-0.1-0.8-0.1l-6.7-4.8l-15.6-19.2l-1.5-3.3l-8.8-27l-1.9-9.3v-34.2
                        c2.7-4.9,6.4-9.2,10.9-12.6c19.2-15.2,35.2-34,47.1-55.5c12.5-18.9,21.6-39.9,26.8-61.9c2.7-4.9,4.1-10.3,4.1-15.8
                        c4.4-12.8,5.4-26,6.2-39.4l0.3-13.1c0.7-10.7,1.2-21.4-2-31.9c0-3.1,0.6-6.4-1.8-9.1c-1-9.5-3.6-18.8-7.8-27.4l-3.4-7
                        c-2.7-8.2-6.9-15.9-12.4-22.6c-1.3-3.6-3.5-6.8-6.5-9.3c-3.5-6.2-8.1-11.6-13.7-15.9c-2.2-3.7-5.4-6.8-9.3-8.9
                        c-4.2-5-9.4-9-15.3-11.9c-1.8-2.5-4.4-4.3-7.5-5c-8.1-6.2-17.4-10.9-27.2-13.7c-1.5-1.5-3.6-2.3-5.7-2.1c-9-4.1-18.6-6.7-28.4-7.6
                        l-11.7-1.1c-11.3-2.5-23-2.4-34.2,0.3c-4.7-0.8-9.5-0.1-13.7,2c-20.6,2.8-40.3,10.5-57.2,22.5c-22.9,13.1-41.7,32.3-54.3,55.4
                        c-11.3,16.6-18.8,35.5-22,55.3l-2.2,15.4c-1.3,10.6-1.4,21.3-0.4,32c-0.7,3.9-0.2,7.9,1.4,11.6c-0.1,10,1.4,20,4.6,29.6
                        c0.2,3.1,0,6.5,2.3,9.3c1,8.7,3.5,17.1,7.4,25c0.2,3.4,1.4,6.6,3.4,9.3c2.7,8.9,6.7,17.3,11.8,25.1c0.8,3.4,2.5,6.5,5.1,9
                        c2.8,6.7,6.8,12.8,11.7,18.1l5.3,7.3c4.5,6.9,9.9,13,16.2,18.3l7.6,7c5,6.8,13.6,10.1,17.7,17.9l1.1,4.4l-0.6,34.2
                        c-2.4,4.1-1.9,8.9-2.6,13.4c-8.6,29.1-24.5,50.9-56.7,56.2c-4.4,0.8-9.3,0.3-13.2,2.8l-41.2,7.7l-15.8,3.1L256,537l-15.4,2.7
                        c-13.9,2.6-28,4.4-41.6,8.8c-45.9,10.1-73.5,38.5-84,84c-3.9,12.7-6,25.8-6.5,39.1l-0.6,13.3l-0.2,34.2v11.4v34.2v11.4v34.2v11.4
                        v34.2v11.4v34.2v11.4V947v11.4c-0.2,6.4-0.4,12.8-0.5,19.2c-0.3,20.3-13.2,27.4-30.9,16.9l-3.7-7L71.5,956c1.1-3.7,1.1-7.7,0-11.4
                        c1.1-11.4,1.1-22.9,0-34.3c1.1-3.7,1.1-7.6,0-11.3c1.1-11.4,1.1-22.9,0-34.3c1.1-3.7,1.1-7.7,0-11.4c1.1-11.4,1.1-22.9,0-34.3
                        c1.1-3.7,1.1-7.7,0-11.4c1.1-11.4,1.1-22.9,0-34.3c1.1-3.7,1.1-7.7,0-11.4c1.1-11.4,1.1-22.8,0-34.2c1.1-3.7,1.1-7.7,0-11.4
                        l0.6-34.3c1.2-4.4,1.6-9.1,0.9-13.6l5.1-38.7c2.3-5.1,3.7-10.5,4.2-16c5.7-14,10.8-28.1,19.2-40.8c16.7-25.2,39.8-41.9,67.7-52.5
                        c3.8-1.5,7.6-3.1,11.4-4.7c5.6-0.4,11-1.9,16-4.3c14.7-1.9,29.2-4.8,43.4-8.7c4.6-0.4,9.4,0,13.6-2.7c13.8-1.7,27.5-4.2,41-7.6
                        l15.9-3.5c13.8-1.7,27.6-4.3,41.1-7.8c27.1-3.8,34.9-11.6,38.4-38.3c0.9-9.8,7.2-20.9-6.2-27.4l-9.3-9.1L359.1,389
                        c-0.8-2.6-2.5-4.8-4.8-6.2l-12.1-17.7c-1.3-3.7-3.4-7-6.2-9.6l-11.3-21.2c0.1-2.2-1-4.4-2.8-5.7l-9.6-25.1l-3.3-9.3l-6.5-25.2
                        l-2.3-11.3l-3.8-29.4l-1-11.5l1-32.8l1.3-12.6c3.5-16.6,8.4-33,14.7-48.8l10.9-21.6c18.2-29.5,42.8-54.4,72-73l22.6-11.6
                        c15.6-5.7,31.6-10.5,47.9-14.2c4.7,0.4,9.3-0.3,13.7-2L513.7,0z M828.9,558.1c-12.9-7.4-27.2-10.4-41.4-13.6
                        c-4.7-1-6.2-0.5-5.2,4.6c2.6,15.4,3.4,31.3,7.5,46.6c0.2,10.1,1.6,20.1,4.1,29.8l1.3,11.6l4.2,29.3c-0.5,3.2,0.2,6.5,1.8,9.3
                        c0.2,10,1.6,20,4.2,29.7l1.3,11.7l4.2,29.3c-0.4,3.2,0.2,6.5,1.8,9.3c0.2,10,1.6,20,4.2,29.7l1.3,11.7l3.9,27
                        c-0.4,3.9,0.3,7.9,2,11.4c0.2,10,1.6,20,4.2,29.7l1.3,11.7l3.9,27c-0.4,3.9,0.3,7.9,2,11.4c0.2,10,1.6,20,4.3,29.7
                        c0,1.5,0,3.1,0,4.5c1.1,13.3,1.3,13.4,12.8,7.2c22.9-12.4,36.6-31.1,39.3-57.4l0.3-34.2v-11.4v-34.2V808v-34.2v-11.4v-34.2v-11.4
                        v-34.2c0.6-3.9,0.4-7.9-0.8-11.7c-0.2-9.9-1.5-19.7-3.9-29.3l-2.3-9.3c-1.5-8.8-4.5-17.3-8.9-25.1c-0.3-2.7-1.6-5.3-3.6-7
                        c-3.1-7.8-8-14.8-14.4-20.3c-2.1-3.7-5.3-6.7-9.1-8.7C843.4,565.5,836.5,561,828.9,558.1L828.9,558.1z M634.8,602.5
                        c-1.6,4.4-2.3,9.1-2,13.7c-2.6,12.8-4.2,25.8-4.9,38.8c-1.7,4.3-2.4,9.1-2,13.7c-2.6,12.8-4.3,25.7-4.9,38.7
                        c-1.8,4.3-2.5,9-1.9,13.7c-2.7,12.8-4.4,25.7-5,38.8c-1.9,4.3-2.5,9-1.9,13.7c-2.8,12.8-4.5,25.7-5.1,38.8
                        c-1.9,4.3-2.5,9-1.9,13.7c-2.9,12.8-4.6,25.7-5.2,38.8c-1.9,4.3-2.5,9-1.8,13.7c-2.9,12.8-4.7,25.8-5.3,38.8
                        c-1.8,4.3-2.4,9-1.7,13.6c-1.3,7.7-2.3,15.4-4.1,22.9c-2.4,10.1,1.7,12.7,10.8,10.4h13.8h31.8h13.8h31.8h13.8h77.5
                        c1.1,0.2,2.2,0.4,3.3,0.5c23.3,0.7,23.3,0.7,19-22.4l-10-68.7l-1.5-11.4l-4.3-29.3c0.6-3.2,0-6.5-1.7-9.3
                        c-0.2-10.1-1.6-20.2-4.1-29.9l-1.4-11.5l-4.3-29.3c0.6-3.2,0-6.5-1.8-9.3c-0.2-10-1.6-20-4.1-29.7l-1.4-11.7l-4.2-29.3
                        c0.5-3.2-0.1-6.5-1.8-9.3c-0.2-10-1.6-20-4.1-29.7l-1.3-11.7l-4.2-29.3c0.5-3.2-0.2-6.5-1.8-9.3c-0.1-10-1.6-20-4.2-29.7
                        l-1.3-11.7c0.9-3.2-0.9-6.5-4.1-7.4c-0.5-0.2-1.1-0.2-1.7-0.2c-7.6-1.1-15.3-2.4-22.9-3.6c-2.7-1.9-6-2.6-9.3-2
                        c-8.8-2.8-17.9-4.6-27.2-5.3c-9.5-2.1-19.1-4.1-28.5-6.3c-4.9-1.2-7,0.6-7.5,5.3c-1.5,13.5-3.1,26.9-4.8,40.4L634.8,602.5z
                         M421.4,906.2c0.6,3.1,0.4,6.4,2.8,8.9c2,8.6,6.3,16.4,12.6,22.6c1.9,3.6,4.9,6.6,8.5,8.6c5.5,5.9,12.6,10.1,20.4,12.2
                        c3.3,2.5,7.3,3.9,11.5,3.9c22.6,4.6,45.4,1.1,68,2.4c4.9,0.3,5.7-2.8,6.2-6.2c3.4-27.5,6.8-54.9,10.2-82.4c1.8-4.3,2.5-9,1.9-13.7
                        c2.7-12.8,4.4-25.7,5-38.8c1.8-3.5,2.6-6.5-3.1-6.5c-3.6,0-7.3,0.3-10.9,0.4h-31.8H509c-12.4-0.6-24.7-1.7-36.6,3.4
                        c-26.5,7.1-42.9,24.1-49.9,50.5C418.8,882.9,418.4,894.9,421.4,906.2L421.4,906.2L421.4,906.2z"
        />
        <path
          d="M254.3,999.1l-6.7-4.5c-6.2-8.2-3.7-18-5.1-27.2c1-3.7,1-7.7,0-11.4c1-11.4,1-22.8,0-34.2c1-3.7,1-7.7,0-11.4
                        c1-11.4,1-22.8,0-34.2c1-3.7,1-7.7,0-11.4c1-11.4,1-22.8,0-34.2c1-3.7,1-7.7,0-11.4c1-11.4,1-22.8,0-34.2l3.6-13.1l3.6-3.8
                        c4.1-2.2,8.6-3.5,13.2-3.8c6.5,1.5,11.9,6,14.7,12.1l1.4,10.9v34.2v11.4V867v11.4v34.2V924v34.2v11.4c-0.2,2.3-0.5,4.5-0.5,6.7
                        C278.2,994.4,272.3,1000,254.3,999.1z"
        />
      </g>
    </g>
  </svg>
);
