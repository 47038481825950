import React from 'react';
import './svg.css';

export default props => (
  <svg
    width="30"
    height="25"
    viewBox="0 0 720 960"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M720 940C720 951.047 711.047 960 700 960H19.9996C8.95264 960 -0.000366211 951.047 -0.000366211 940V208C-0.000366211 202.695 2.1051 197.609 5.85901 193.859L193.859 5.85891C197.609 2.10501 202.695 -0.000488281 208 -0.000488281H700C711.047 -0.000488281 720 8.95261 720 19.9995L720 940ZM39.9996 920H680V39.9999H240V220C240 231.047 231.047 240 220 240H39.9996V920ZM56.3976 200H199.998V56.3999L56.3976 200Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M600 380V740C600 751.047 591.047 760 580 760H140C128.953 760 120 751.047 120 740V380C120 368.953 128.953 360 140 360H580C591.047 360 600 368.953 600 380ZM560 400H400V480H560V400ZM560 520H400V600H560V520ZM560 640H400V720H560V640ZM360 400H160V480H360V400ZM360 520H160V600H360V520ZM360 640H160V720H360V640Z"
    />
  </svg>
);
