import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  listReports,
  importReports,
  exportCustomReportQuestions
} from '../../api/v4';
import { clearUploadedAttachments } from '../../actions/attachments';
import { addMessage } from '../../actions/messages';
import { setActiveReportRequest } from '../../actions/reports';
import {
  TYPE_NAMES,
  FROI_BULK_UPLOAD_TEMPLATE,
  VEHICLE_UPLOAD_TEMPLATE
} from '../../constants/constants';
import {
  getAttachmentLoadingSelector,
  getAddedAttachmentsSelector
} from '../../selectors/attachments';
import {
  getIsPerrp,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import Button from '../../components/inputs/Button';
import CSVUploader from '../../components/CSVUploader';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import List from '../../components/List';
import Modal from '../../components/Modal';

export class ReportStages extends Component {
  state = {
    data: null,
    openImportFroiModal: false,
    openImportProblemsModal: false,
    messages: []
  };

  componentDidMount = () => {
    this.getReports(this.props.match.params.templateId);
    this.props.clearAttachments();
  };

  componentDidUpdate = prevProps => {
    if (
      prevProps.match.params.templateId !== this.props.match.params.templateId
    ) {
      this.getReports(this.props.match.params.templateId);
    }
  };

  getReports = templateId => {
    listReports(templateId).then(data => this.setState({ data }));
  };

  handleImport = () => {
    try {
      if (!this.props.attachments[0]) {
        this.props.addMessage({ error: true, message: 'File Required' });
      } else {
        importReports(
          this.props.attachments[0]?.source_url,
          this.props.match.params.templateId
        ).then(
          data => {
            this.props.addMessage({
              error: false,
              message: 'Imported successfully!'
            });
          },
          error => {
            this.setState({
              messages: error.data,
              openImportProblemsModal: true
            });
          }
        );
      }
    } catch (e) {
      if (e.status !== 400) throw e;

      let messages = e.data;
      if (!Array.isArray(messages)) messages = [messages];
      this.setState({ messages, openImportProblemsModal: true });
    }

    this.getReports(this.props.match.params.templateId);
    this.props.clearAttachments();
    this.setState({ openImportFroiModal: false });
  };

  handleExport = async templateId => {
    const src = await exportCustomReportQuestions({
      templateId,
      incidents: [...this.state.data]
    });
    window.location = src;
  };

  render() {
    const {
      loadReport,
      isPerrp,
      activeLocation,
      attachmentLoading,
      activeProject
    } = this.props;

    const reportTemplateId = this.props.match.params.templateId;

    const columns = [
      { key: 'incidentNumber' },
      {
        key: 'createdByUserId',
        label: 'Reporter',
        datatype: 'user'
      },
      {
        key: 'dateIncidentOccured',
        label: 'Date Occurred',
        datatype: 'date'
      },
      {
        key: 'reportStage',
        label: 'Status',
        accessor: r =>
          r.reportStage?.label === 'Closed' ? 'Completed' : 'Open',
        enum: [
          { value: 'Open', label: 'Open' },
          { value: 'Completed', label: 'Completed' }
        ]
      }
    ];

    switch (reportTemplateId) {
      case '1': {
        columns.splice(1, 0, {
          key: 'driver',
          label: 'Driver',
          datatype: 'users'
        });
        break;
      }
      case '2': {
        columns.splice(
          1,
          0,
          {
            key: 'injuredEmployeeIds',
            label: 'Injured Employee(s)',
            datatype: 'users'
          },
          {
            key: 'oshaStatus',
            label: `${isPerrp ? 'PERRP' : 'OSHA'} Status`,
            enum: [
              { value: 'notStarted', label: 'Not Started' },
              { value: 'incomplete', label: 'Incomplete' },
              { value: 'recordable', label: 'Recordable' },
              { value: 'notApplicable', label: 'Not Recordable' }
            ]
          }
        );
        break;
      }
      case '3': {
        columns.splice(1, 0, {
          key: 'involvedEmployee',
          datatype: 'users'
        });
        columns.push({
          key: 'lossCategory',
          label: 'Category of Loss',
          datatype: 'list'
        });
        break;
      }
      case '4':
      case '16': {
        columns.splice(1, 0, {
          key: 'involvedEmployee',
          datatype: 'users'
        });
        break;
      }
      case '5': {
        columns.splice(1, 0, {
          key: 'injuredPerson',
          label: 'Injured Person'
        });
        break;
      }
      case '7': {
        columns.splice(1, 0, {
          key: 'involvedEmployee',
          datatype: 'users'
        });
        columns.push({
          key: 'nearMissCategory',
          label: 'Category of Near Miss',
          datatype: 'list'
        });
        break;
      }
      default:
    }

    if (!activeLocation) {
      columns.unshift({
        key: 'locationId',
        label: 'Group/Est.',
        datatype: 'group'
      });
    }
    if (activeLocation && !activeProject) {
      columns.unshift({
        key: 'projectId',
        label: 'Area',
        datatype: 'project'
      });
    }

    const header = (
      <Header
        title={TYPE_NAMES[reportTemplateId]}
        pageActionOptions={[
          {
            label:
              reportTemplateId === '2'
                ? 'Upload FROI Template'
                : 'Upload Vehicle Template',
            visible: reportTemplateId === '2' || reportTemplateId === '1',
            color: 'blueOutline',
            onClick: () => this.setState({ openImportFroiModal: true })
          },
          {
            label: 'Export Custom Report Questions',
            color: 'blueOutline',
            onClick: () => this.handleExport(reportTemplateId),
            visible: this.state.data?.length > 0
          }
        ]}
      />
    );
    let saveKey = `reportStages-${reportTemplateId}`;
    const newSaveKey =
      this.state.data?.length > 0
        ? !activeLocation
          ? `${saveKey}Groups`
          : activeLocation && !activeProject
          ? `${saveKey}Areas`
          : saveKey
        : null;

    return (
      <HeaderAndFooter Header={header}>
        <List
          saveKey={newSaveKey}
          rowClick={row =>
            loadReport({
              id: row._id,
              returnPath: `/app/reportStages/${reportTemplateId}`
            })
          }
          dataIsHash
          data={this.state.data?.sort((a, b) => {
            return (
              new Date(b.dateIncidentOccured) - new Date(a.dateIncidentOccured)
            );
          })}
          settings={columns}
        />
        <Modal
          title={`Import Historical ${
            reportTemplateId === '2'
              ? 'FROI and Recordables'
              : 'Vehicle Reports'
          }`}
          titleClassName="blueHeader"
          isOpen={this.state.openImportFroiModal}
          className="modalSmall"
          onRequestClose={() => {
            this.props.clearAttachments();
            this.setState({ openImportFroiModal: false });
          }}
          submitButtonColor="blue"
          submitActions={this.handleImport}
          disableSubmit={attachmentLoading}
        >
          <Button
            color="blue"
            onClick={() => {
              var win = window.open(
                reportTemplateId === '2'
                  ? FROI_BULK_UPLOAD_TEMPLATE
                  : VEHICLE_UPLOAD_TEMPLATE,
                '_blank'
              );
              win.focus();
            }}
            text="Download CSV Template"
          />
          <CSVUploader documentType="FroiUpload" className="dropzone" />
        </Modal>
        <Modal
          title={`Issues with ${
            reportTemplateId === '2' ? 'FROI' : 'Vehicle'
          } Upload`}
          titleClassName="redHeader"
          isOpen={this.state.openImportProblemsModal}
          className="modalMedium"
          onRequestClose={() =>
            this.setState({ openImportProblemsModal: false })
          }
          hideCancelButton={true}
          submitActions={() =>
            this.setState({ openImportProblemsModal: false })
          }
          submitButtonText="Close"
          showXButton={true}
        >
          <ul>
            {this.state.messages?.map((issue, index) => (
              <li key={index}>{issue}</li>
            ))}
          </ul>
        </Modal>
      </HeaderAndFooter>
    );
  }
}

export const mapStateToProps = state => ({
  isPerrp: getIsPerrp(state),
  activeLocation: getActiveLocationId(state),
  attachmentLoading: getAttachmentLoadingSelector(state),
  attachments: getAddedAttachmentsSelector(state),
  activeProject: getActiveProject(state)
});

export const mapDispatchToProps = dispatch => ({
  loadReport: payload => dispatch(setActiveReportRequest(payload)),
  clearAttachments: () => dispatch(clearUploadedAttachments()),
  addMessage: payload => dispatch(addMessage(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportStages);
