import React, { useState } from 'react';
import Button from '../../inputs/Button';
import Checkbox from '../../inputs/Checkbox';
import HoverImage from '../../HoverImage';
import { InputRow } from '../../inputs';
import Modal from '../../Modal';
import Textbox from '../../inputs/Textbox';

import customQuizCardStyle from './styles.module.scss';
import { Rearranger } from '../../Rearranger';

export default function CustomQuizQuestionCard(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState(0);

  const addAnswerToRow = index => {
    const newQuestions = [...props.questions];
    newQuestions[index].answerOptions = [
      ...newQuestions[index].answerOptions,
      { answer: '', isCorrectAnswer: false }
    ];

    props.updateQuestions(newQuestions);
  };

  const updateAnswerRow = ({
    answer,
    isCorrectAnswer,
    questionIndex,
    answerIndex
  }) => {
    const newQuestions = [...props.questions];
    newQuestions[questionIndex].answerOptions[answerIndex] = {
      answer,
      isCorrectAnswer
    };

    props.updateQuestions(newQuestions);
  };

  const removeAnswerRow = (index, answerIndex) => {
    const newQuestions = [...props.questions];
    newQuestions[index].answerOptions.splice(answerIndex, 1);

    props.updateQuestions(newQuestions);
  };

  const addRowsToQuestions = number => {
    const newQuestions = [...props.questions];
    for (let i = 1; i <= number; i++) {
      newQuestions.push({
        label: '',
        answerOptions: []
      });
    }
    props.updateQuestions(newQuestions);
  };

  const footer = !props.disabled && (
    <InputRow className={customQuizCardStyle.footer}>
      <Button
        text="Add Row"
        color="blue"
        onClick={() => addRowsToQuestions(1)}
      />
      <Button
        text="Add Five Rows"
        color="blue"
        onClick={() => addRowsToQuestions(5)}
      />
    </InputRow>
  );
  const renderItem = (item, index) => {
    return (
      <>
        <Textbox
          currentValue={item.label}
          onChange={v => {
            const newQuestions = [...props.questions];
            newQuestions[index].label = v;
            props.updateQuestions(newQuestions);
          }}
          fieldLabel="Question"
          placeholder="Type your question here (e.g. What is 2+2?)"
          isRequired
          disabled={props.disabled}
          name={`question-${index}`}
        />
        <InputRow>
          <Button
            text={item.answerOptions.length ? 'View Answers' : 'Add Answers'}
            color="blue"
            onClick={() => {
              setModalOpen(true);
              setRowIndex(index);
            }}
          />
        </InputRow>
      </>
    );
  };

  return (
    <>
      <Rearranger
        className={customQuizCardStyle.container}
        items={props.questions}
        onChange={props.updateQuestions}
        renderItem={renderItem}
        disabled={props.disabled}
      />
      {footer}
      <Modal
        title="Change Answers"
        titleClassName="blueHeader"
        isOpen={modalOpen}
        submitButtonColor="blue"
        submitButtonText="Save Changes"
        onRequestClose={() => {
          setModalOpen(false);
        }}
        submitActions={() => {
          setRowIndex(0);
          setModalOpen(false);
        }}
      >
        <div>
          <div className={customQuizCardStyle.options}>
            <div>Possible Answer(s)</div>
            <div>Mark Correct Answer(s)</div>
            <div></div>
          </div>
          {props?.questions[rowIndex]?.answerOptions.map((answer, index) => (
            <div className={customQuizCardStyle.options} key={index}>
              <Textbox
                currentValue={answer.answer}
                onChange={v =>
                  updateAnswerRow({
                    answer: v,
                    isCorrectAnswer: answer.isCorrectAnswer,
                    questionIndex: rowIndex,
                    answerIndex: index
                  })
                }
                placeholder="Type your option here."
                className={customQuizCardStyle.noMargin}
                disabled={props.disabled}
              />
              <Checkbox
                name={index}
                currentValue={answer.isCorrectAnswer}
                onChange={value =>
                  updateAnswerRow({
                    answer: answer.answer,
                    isCorrectAnswer: value,
                    questionIndex: rowIndex,
                    answerIndex: index
                  })
                }
                className={customQuizCardStyle.noMargin}
                disabled={props.disabled}
              />
              <HoverImage
                className={customQuizCardStyle.removeImage}
                onClick={() => removeAnswerRow(rowIndex, index)}
                src={require('../../../assets/images/removePermissions.png')}
                srcHover={require('../../../assets/images/removePermissonsHover.png')}
              />
            </div>
          ))}
          <Button
            text="Add Answer"
            color="blue"
            onClick={() => addAnswerToRow(rowIndex)}
            disabled={props.disabled}
          />
        </div>
      </Modal>
    </>
  );
}
