import React, { useRef, useEffect, useState } from 'react';

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useDragDrop = ({
  dropStyle,
  disabled,
  dropFunc,
  renderItem,
  data
}) => {
  const [dragIdx, setDragIdx] = useState(null);
  const [dragTargetIdx, setDragTargetIdx] = useState(null);

  const onDragStart = (e, i) => {
    e.dataTransfer.dropEffect = 'move';
    setDragIdx(i);
    setDragTargetIdx(null);
  };

  const onDragOver = (e, i) => {
    if (e.preventDefault) e.preventDefault();
    setDragTargetIdx(dragIdx === i ? null : i);
  };

  const onDragLeave = e => {
    setDragTargetIdx(null);
  };

  const onDrop = e => {
    if (e.preventDefault) e.preventDefault();
    if (dragIdx === dragTargetIdx || dragTargetIdx === null) return;

    dropFunc(dragIdx, dragTargetIdx);

    setDragIdx(null);
    setDragTargetIdx(null);
  };

  const renderInnerDiv = (data, i) => (
    <div
      draggable={!disabled}
      onDragStart={!disabled && (e => onDragStart(e, i))}
    >
      {renderItem(data)}
    </div>
  );

  const render = () => {
    if (!data?.length) return <></>;

    let ret = [];
    let nextItemIdx = 0;

    for (let i = 0; i < data.length; i++) {
      let item = null;
      if (dragIdx === null || dragTargetIdx !== i) {
        if (
          nextItemIdx === dragIdx &&
          dragTargetIdx !== null &&
          dragIdx !== dragTargetIdx
        )
          nextItemIdx++;
        item = renderInnerDiv(data[nextItemIdx], nextItemIdx);
        nextItemIdx++;
      }
      ret.push(
        <div
          key={i}
          onDragOver={!disabled && (e => onDragOver(e, i))}
          onDragLeave={!disabled && onDragLeave}
          onDrop={!disabled && onDrop}
          className={item === null ? dropStyle : undefined}
        >
          {item || <></>}
        </div>
      );
    }
    return ret;
  };

  return { render };
};

// tries to mimic setState(state => {}), won't be called on first render of component
export function useEffectUpdate(effect, deps) {
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current) {
      effect();
    }
  }, [effect]);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);
}
