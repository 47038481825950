import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../history';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import List from '../../../components/List';
import Modal from '../../../components/Modal';
import { Dropdown } from '../../../components/inputs';
import {
  fetchCustomTrainingTemplatesRequest,
  setActiveCustomTrainingTemplate
} from '../../../actions/training';
import { getTemplateListSelector } from '../../../selectors/training';

export default function TemplateList() {
  const [openModal, setOpenModal] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);

  const dispatch = useDispatch();
  const templates = useSelector(getTemplateListSelector);

  useEffect(() => {
    dispatch(fetchCustomTrainingTemplatesRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const templateDropdown = templates?.map(t => {
    return { value: t._id, label: t.title };
  });

  const header = (
    <Header
      title="Training Templates"
      pageActionOptions={[
        {
          label: 'Create Training Template',
          visible: true,
          color: 'blueOutline',
          onClick: () => {
            dispatch(setActiveCustomTrainingTemplate({}));
            history.push('/app/training/customTrainingTemplate');
          }
        },
        {
          label: 'Duplicate Training Template',
          onClick: () => setOpenModal(true),
          color: 'blueOutline',
          visible: templates?.length > 0
        }
      ]}
    />
  );

  const handleViewEdit = (template, duplicate = false) => {
    if (duplicate) {
      let templateToCopy = templates.find(t => t._id === template);
      delete templateToCopy._id;
      dispatch(
        setActiveCustomTrainingTemplate({
          ...templateToCopy,
          title: `${templateToCopy.title} - Copy`,
          duplicated: true
        })
      );
    } else {
      dispatch(setActiveCustomTrainingTemplate(template));
    }
    history.push('/app/training/customTrainingTemplate');
  };

  const columns = [
    { key: 'title' },
    { key: 'doesExpire', datatype: 'boolean' },
    { key: 'needsApproval', datatype: 'boolean' },
    { key: 'updatedAt', label: 'Last Updated', datatype: 'date' }
  ];

  return (
    <HeaderAndFooter Header={header}>
      <List
        saveKey="trainingTemplateList"
        rowClick={handleViewEdit}
        dataIsHash
        data={templates}
        settings={columns}
      />
      <Modal
        title="Choose Template"
        titleClassName="blueHeader"
        isOpen={openModal}
        submitButtonColor="blue"
        submitButtonText="Create"
        onRequestClose={() => setOpenModal(false)}
        submitActions={() => handleViewEdit(currentTemplate, true)}
      >
        <Dropdown
          options={templateDropdown}
          fieldLabel="Training Template"
          currentValue={currentTemplate}
          isRequired
          onChange={value => setCurrentTemplate(value)}
          placeholder="Select a template"
          searchable
        />
      </Modal>
    </HeaderAndFooter>
  );
}
