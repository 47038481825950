import React from 'react';
import moment from 'moment';
import {
  FREQUENCY_OPTIONS,
  MONTHLY_FREQUENCY_OPTIONS,
  YEARLY_FREQUENCY_OPTIONS,
  REPEATING_OPTIONS,
  ONTHESPOT_OPTIONS
} from '../../../constants/constants';
import { positiveIntsOnly } from '../../../utils/formValidation';
import Button from '../../../components/inputs/Button';
import Card from '../../../components/Card';
import { Checkbox } from '../../../components/inputs';
import { DatePicker } from '../../../components/inputs/DateTimePicker';
import DateUnitPicker from '../../../components/inputs/DateUnitPicker';
import DayOfWeekButtons from '../../../components/inputs/DayOfWeekButtons';
import RadioButtons from '../../../components/inputs/RadioButtons';
import TrainingTemplate from '../../../components/training/TrainingTemplate';
import DueBy from '../../../components/inputs/DueBy';
import {
  TwoColumn,
  Textbox,
  Dropdown,
  EmployeeDropdown
} from '../../../components/inputs';

import styles from '../TrainingContainer/styles.module.scss';

export default function TrainingDetails({
  addedAttachments,
  templates,
  activeGroup,
  deleteAttachment,
  activeProject,
  activeCompany,
  fromApproveTraining,
  updateAnswer,
  permissions,
  excludeDeactivatedUsers,
  activeTraining,
  fetchUsers,
  handleTrainingTypeSelection,
  addAttachments,
  name,
  wide,
  quizDropdown,
  isTemplateCreate,
  validExpirationNumber
}) {
  const {
    title,
    instructions,
    needsApproval,
    doesExpire,
    expiresAfterOption,
    expiresAfterNumber,
    isEditing,
    isCreating,
    isReassigned,
    attachments,
    schedule,
    trainingType,
    frequency,
    repeatingOn,
    dueBy,
    dueDate,
    dueByTime,
    assignedTo,
    approver,
    assignBeforeExpirationNumber,
    assignBeforeExpirationOption,
    groupIds,
    projectIds,
    trainingTemplateId,
    legacy,
    completionDate,
    quizzes,
    quizMasterId,
    category,
    assignOn,
    admins,
    titleList,
    youtubeLinks,
    onTheSpotAdmin,
    expirationDate,
    isPaused,
    equipmentId,
    validTemplateIds
  } = activeTraining;

  const completedBeforeExpiration =
    dueDate && doesExpire
      ? moment(dueDate).diff(completionDate, 'days') > 0
      : true;
  const dueBeforeToday = dueDate ? moment().diff(dueDate, 'days') > 0 : false;

  const hasAttachments =
    addedAttachments?.length > 0 || attachments?.length > 0;

  if (!templates?.find(t => t.value === '')) {
    // eslint-disable-next-line no-unused-expressions
    templates?.unshift({ value: '', label: ' ' });
  }

  const getProjectDropdown = () => {
    let groups;
    if (groupIds?.length) {
      groups = groupIds;
    } else if (activeGroup) {
      groups = [activeGroup._id];
    } else {
      return [];
    }

    let projectsDropdown = [];
    groups.forEach(g => {
      let group = activeCompany?.locations.find(l => l._id === g);
      if (!group) return;

      group.projects.forEach(project =>
        projectsDropdown.push({
          value: project._id,
          label: `${group.name} - ${project.name}`
        })
      );
    });

    return projectsDropdown;
  };

  return (
    <>
      {trainingType && (
        <Card
          showHeader
          title="Training Details"
          name={name}
          wide={wide}
          testID="trainingDetailsCard"
        >
          {trainingType?.value !== 2 && !fromApproveTraining && (
            <TwoColumn>
              {!activeGroup && (
                <Dropdown
                  multi
                  selectButtons
                  searchable
                  options={activeCompany?.locations}
                  labelKey="name"
                  valueKey="_id"
                  fieldLabel="Group or Establishment:"
                  currentValue={groupIds}
                  onChange={groupIds => {
                    updateAnswer({ groupIds });
                    fetchUsers(groupIds);
                  }}
                  disabled={!isCreating && !isEditing}
                />
              )}

              {!activeProject && (
                <Dropdown
                  multi
                  selectButtons
                  searchable
                  options={getProjectDropdown()}
                  fieldLabel="Area:"
                  currentValue={projectIds}
                  onChange={projectIds => {
                    updateAnswer({ projectIds });
                    fetchUsers(projectIds);
                  }}
                  disabled={!isCreating && !isEditing}
                />
              )}
            </TwoColumn>
          )}
          <Dropdown
            searchable
            options={
              equipmentId && isCreating
                ? templates?.filter(t => validTemplateIds?.includes(t._id))
                : templates
            }
            valueKey="_id"
            labelKey="title"
            fieldLabel="Training Template"
            currentValue={trainingTemplateId}
            onChange={values => handleTrainingTypeSelection(values)}
            disabled={!isCreating || templates?.length === 0}
            placeholder={
              fromApproveTraining
                ? 'No template selected'
                : templates?.length > 0
                ? 'Select A Template'
                : 'No Templates to choose from. Create one under custom templates'
            }
          />
          <TrainingTemplate
            updateAnswer={values => updateAnswer({ ...values })}
            title={title}
            instructions={instructions}
            needsApproval={needsApproval}
            doesExpire={doesExpire}
            expiresAfterNumber={expiresAfterNumber}
            expiresAfterOption={expiresAfterOption}
            isEditing={isEditing}
            isCreating={isCreating}
            disabled={trainingTemplateId ? true : false}
            approver={approver}
            trainingType={trainingType}
            quizzes={quizzes}
            quizMasterId={quizMasterId}
            isTemplateCreate={isTemplateCreate}
            titleList={titleList}
            permissions={permissions}
            hasAttachments={hasAttachments}
            category={category}
            fromApproveTraining={fromApproveTraining ?? false}
            addAttachments={addAttachments}
            addedAttachments={addedAttachments}
            attachments={attachments}
            deleteAttachment={deleteAttachment}
            youtubeLinks={youtubeLinks}
            quizDropdown={quizDropdown}
            createdByUserId={activeTraining?.createdByUserId}
            isMasterTraining={activeTraining?.isMaster}
            schedule={activeTraining?.schedule}
            equipmentId={equipmentId}
          />
          {trainingType?.value !== 2 && !fromApproveTraining && !equipmentId && (
            <RadioButtons
              fieldLabel="Schedule"
              options={
                trainingType.value === 1 ? REPEATING_OPTIONS : ONTHESPOT_OPTIONS
              }
              currentValue={schedule}
              bareValues={false}
              onChange={values =>
                updateAnswer({
                  schedule: values
                })
              }
              disabled={!isCreating}
              needSeparateLines
              isRequired
            />
          )}
          {trainingType.value === 1 && schedule.value === 2 && doesExpire && (
            <div className={styles.beforeExpires}>
              <Textbox
                fieldLabel={`Assign how many ${assignBeforeExpirationOption} before expiration`}
                type="number"
                onChange={v => {
                  const onlyNums = positiveIntsOnly(v);
                  updateAnswer({
                    assignBeforeExpirationNumber: onlyNums
                  });
                }}
                currentValue={assignBeforeExpirationNumber}
                disabled={!isCreating && !isEditing}
                isRequired
                warning={
                  assignBeforeExpirationNumber !== '' &&
                  assignBeforeExpirationNumber < 1
                    ? 'Must be greater than 0'
                    : validExpirationNumber()
                    ? ''
                    : "Cannot be greater or eqaul to 'Expires After'"
                }
                touched={true}
              />
              <div className={styles.topMargin}>
                <DateUnitPicker
                  currentValue={assignBeforeExpirationOption}
                  onChange={values =>
                    updateAnswer({
                      assignBeforeExpirationOption: values
                    })
                  }
                  disabled={!isCreating && !isEditing}
                  isRequired
                  warn="Cannot be greater than Expires After"
                  touched={() =>
                    validExpirationNumber(
                      assignBeforeExpirationNumber,
                      assignBeforeExpirationOption
                    )
                  }
                />
              </div>
            </div>
          )}
          {trainingType.value === 1 && schedule.value === 2 && !doesExpire && (
            <Dropdown
              options={FREQUENCY_OPTIONS}
              fieldLabel="Frequency"
              currentValue={frequency}
              onChange={frequency =>
                updateAnswer({
                  frequency,
                  repeatingOn: []
                })
              }
              disabled={!isCreating && !isEditing}
              isRequired
            />
          )}
          {trainingType.value === 1 &&
            schedule.value === 2 &&
            frequency === 'weekly' &&
            !doesExpire && (
              <DayOfWeekButtons
                fieldLabel="Assign Every"
                inputClassName="dayOfWeekInput"
                disabled={!isCreating && !isEditing}
                onChange={repeatingOn => updateAnswer({ repeatingOn })}
                currentValue={repeatingOn}
                isRequired
              />
            )}
          {trainingType.value === 1 &&
            schedule.value === 2 &&
            frequency === 'monthly' &&
            !doesExpire && (
              <Dropdown
                options={MONTHLY_FREQUENCY_OPTIONS}
                fieldLabel="Assign Every"
                currentValue={repeatingOn}
                onChange={values => updateAnswer({ repeatingOn: values })}
                disabled={!isCreating && !isEditing}
                isRequired
              />
            )}
          {trainingType.value === 1 &&
            schedule.value === 2 &&
            frequency === 'yearly' &&
            !doesExpire && (
              <Dropdown
                options={YEARLY_FREQUENCY_OPTIONS}
                fieldLabel="Assign Every"
                currentValue={repeatingOn}
                onChange={values => updateAnswer({ repeatingOn: values })}
                disabled={!isCreating && !isEditing}
                isRequired
              />
            )}
          {trainingType.value !== 3 && schedule.value === 1 && (
            <DatePicker
              fieldLabel="Assign On"
              onChange={value => updateAnswer({ assignOn: value })}
              currentValue={assignOn}
              disabled={!isCreating && !isEditing && !isReassigned}
              name="trainingAssignOn"
            />
          )}
          {trainingType.value === 1 && schedule.value === 2 ? (
            <DueBy
              fieldLabel="Due By"
              disabled={!isCreating && !isEditing}
              dueBy={dueBy}
              dueTime={dueByTime}
              onDueByChange={dueBy => updateAnswer({ dueBy })}
              onDueTimeChange={v => updateAnswer({ dueByTime: v })}
              isRequired
            />
          ) : (trainingType.value === 3 && doesExpire) || trainingType.value ? (
            <DatePicker
              fieldLabel={trainingType.value === 3 ? 'Expires On' : 'Due By '}
              onChange={value =>
                trainingType.value === 3
                  ? updateAnswer({ expirationDate: value })
                  : updateAnswer({ dueDate: value })
              }
              pickTime
              currentValue={trainingType.value === 3 ? expirationDate : dueDate}
              disabled={!isCreating && !isEditing && !isReassigned}
              name="trainingDueDate"
              isRequired
              warning={
                dueBeforeToday
                  ? 'Due Date Should be After Today'
                  : completedBeforeExpiration ||
                    trainingType.value !== 3 ||
                    schedule.label === 'Scan on Demand'
                  ? null
                  : 'Expiration Should be After Completion'
              }
              touched={
                (!completedBeforeExpiration || dueBeforeToday) && isCreating
              }
            />
          ) : null}
          {trainingType.value === 3 && schedule.value === 1 && (
            <>
              <DatePicker
                fieldLabel="Completed On"
                onChange={value => updateAnswer({ completionDate: value })}
                pickTime
                currentValue={completionDate}
                disabled={!isCreating && !isEditing}
                name="completedOnDueDate"
                isRequired
              />
              <EmployeeDropdown
                permissions={[400, 500, 900]}
                fieldLabel="Administrator"
                currentValue={onTheSpotAdmin}
                onChange={value => updateAnswer({ onTheSpotAdmin: value })}
                disabled={!isCreating && !isEditing}
                searchable
                name="dropdownEmployees"
                isRequired
              />
            </>
          )}
          {trainingType.value === 2 && (
            <div>
              <div className={styles.adminContainer}>
                <span className={styles.adminInfo}>
                  One training will be created for each group/establishment
                  selected
                </span>
              </div>
              {admins?.map((admin, index) => {
                return (
                  <TwoColumn className={styles.specialGrid}>
                    <TwoColumn>
                      <EmployeeDropdown
                        permissions={[400, 500, 900]}
                        fieldLabel="Administrator"
                        currentValue={admin.adminId}
                        onChange={adminId => {
                          let newAdmin = { ...admins[0], adminId };
                          admins[index] = { ...newAdmin };
                          updateAnswer({ admins });
                        }}
                        disabled={!isCreating && !isEditing}
                        searchable
                        name="dropdownEmployees"
                        isRequired
                      />
                      <Dropdown
                        multi
                        selectButtons
                        searchable
                        options={activeCompany?.locations}
                        labelKey="name"
                        valueKey="_id"
                        fieldLabel="Group or Establishment"
                        currentValue={admin.groupIds}
                        onChange={groupIds => {
                          let newGroup = { ...admins[index], groupIds };
                          admins[index] = newGroup;
                          updateAnswer({ admins });
                        }}
                        disabled={!isCreating && !isEditing}
                        isRequired
                      />
                    </TwoColumn>
                    {admins.length > 1 && (
                      <img
                        type="button"
                        src={require('../../../assets/images/remove.png')}
                        alt="delete admin"
                        onClick={() => {
                          admins.splice(index, 1);
                          updateAnswer({ admins });
                        }}
                        className={styles.removeImage}
                        data-cy="removeButton"
                      />
                    )}
                  </TwoColumn>
                );
              })}
              <Button
                text="Add Administrator"
                color="blue"
                onClick={() => {
                  admins.push({});
                  updateAnswer({ admins });
                }}
                disabled={!isCreating && !isEditing}
                inputClassName={styles.button}
              />
            </div>
          )}
          {trainingType.value !== 2 && schedule.label !== 'Scan on Demand' && (
            <EmployeeDropdown
              multi
              permissions={
                trainingType.value !== 3 ? excludeDeactivatedUsers : null
              }
              selectButtons
              fieldLabel={
                trainingType.value === 1 ? 'Assignee(s)' : 'Attendee(s)'
              }
              currentValue={assignedTo}
              onChange={assignedTo => updateAnswer({ assignedTo })}
              disabled={(!isCreating && !isEditing && !isReassigned) || legacy}
              searchable
              name="multiEmployees"
              filterChoices={[
                {
                  label: 'Inactive Employees',
                  value: 'inactive'
                }
              ]}
              isRequired
              group={groupIds.length > 0 ? groupIds : null}
            />
          )}
          {schedule.value === 2 && !isCreating && (
            <Checkbox
              fieldLabel="Pause Safety Training"
              name="pauseTraining"
              onChange={value => updateAnswer({ isPaused: value })}
              currentValue={isPaused}
              disabled={!isEditing}
            />
          )}
        </Card>
      )}
    </>
  );
}
