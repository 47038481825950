import React from 'react';
import { Dropdown, TwoColumn } from '../inputs';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';

export const HierarchySelector = ({
  onGroupChange,
  groupId,
  groupDisabled,
  onProjectChange,
  projectId,
  projectDisabled,
  alwaysShowGroupDropdown = false,
  alwaysShowProjectDropdown = false,
  groupRequired = true,
  alphabetize = false,
  multi = false,
  groupWarning,
  projectWarning
}) => {
  const { company, location, project } = useActiveHeirarchy();

  const groupDropdown = company.groups.map(group => {
    return {
      value: group._id,
      label: group.name
    };
  });

  let projectDropdown = [];

  if (multi) {
    let groups = [];
    if (location) {
      groups = [location._id];
    } else if (groupId?.length) {
      groups = groupId;
    }

    groups.forEach(g => {
      let group = company?.locations.find(l => l._id === g);
      if (!group) return;

      group.projects.forEach(project => {
        projectDropdown.push({
          value: project._id,
          label: `${group.name} - ${project.name}`
        });
      });
    });
  } else {
    const selectedGroup = company.groups.filter(
      group => group._id === location._id || group._id === groupId
    );

    if (selectedGroup.length > 0) {
      projectDropdown = selectedGroup[0].projects.map(project => {
        return {
          value: project._id,
          label: project.name
        };
      });
      if (projectDropdown.length)
        projectDropdown.unshift({ value: '', label: '      ' });
    }
  }

  return (
    <TwoColumn>
      {(!location || alwaysShowGroupDropdown) && (
        <Dropdown
          searchable
          options={groupDropdown}
          fieldLabel="Group or Establishment:"
          currentValue={groupId}
          onChange={onGroupChange}
          isRequired={groupRequired}
          disabled={groupDisabled}
          alphabetize={alphabetize}
          multi={multi}
          warning={groupWarning}
          touched={groupWarning}
        />
      )}
      {(!project || alwaysShowProjectDropdown) && (
        <Dropdown
          searchable
          options={projectDropdown}
          fieldLabel="Area:"
          currentValue={projectId?.length ? projectId : null}
          onChange={onProjectChange}
          disabled={projectDisabled || !projectDropdown.length}
          placeholder={
            !groupId
              ? 'Select a Group/Establishment'
              : !projectDropdown.length
              ? 'No Areas in Group/Establishment'
              : ''
          }
          alphabetize={alphabetize}
          multi={multi}
          warning={projectWarning}
          touched={projectWarning}
        />
      )}
    </TwoColumn>
  );
};

export default HierarchySelector;
