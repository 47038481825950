import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { email, renderField } from '../../utils/formValidation';
import Button from '../../components/inputs/Button';

import '../../components/inputs/input.css';
import '../../components/inputs/Textbox/index.css';
import '../form.css';
import { TwoColumn } from '../../components/inputs';

import styles from './registrationForm.module.scss';

const NewUserRegistrationForm = props => {
  const {
    handleSubmit,
    badFirstName,
    badUsername,
    badPassword,
    badLastName,
    badEmail,
    badConfirmPassword
  } = props;
  return (
    <div>
      <form className={styles.createAccountForm} onSubmit={handleSubmit}>
        <TwoColumn className={styles.formColumns}>
          <Field
            name="firstName"
            className={badFirstName && styles.badEntry}
            type="text"
            component={renderField}
            label="First Name"
            placeholder={''}
          />
          <Field
            name="lastName"
            className={badLastName && styles.badEntry}
            type="text"
            component={renderField}
            label="Last Name"
            placeholder={''}
          />
          <Field
            name="username"
            className={badUsername && styles.badEntry}
            type="text"
            component={renderField}
            label="Username"
            placeholder={''}
          />
          <Field
            name="email"
            className={badEmail && styles.badEntry}
            type="text"
            component={renderField}
            label="Email Address"
            placeholder="eg@email.com"
            warn={email}
          />
          <Field
            name="password"
            className={badPassword && styles.badEntry}
            type="password"
            component={renderField}
            label="Password"
            placeholder={''}
          />
          <Field
            name="confirmPassword"
            className={badConfirmPassword && styles.badEntry}
            type="password"
            component={renderField}
            label="Confirm Password"
            placeholder={''}
          />
        </TwoColumn>
        <Button type="submit" color="green" text="Register" />
      </form>
      {/* 
      Commenting out Google Login until we better support Google logins.
      Seems to be causing more issues than it is worth by confusing users.
      
      <GoogleLogin
        className={'add fullWidth google-login-button'}
        clientId="143104171078-4470tdmg30b7qa1tekch5c5kttfvav96.apps.googleusercontent.com"
        buttonText="Sign in with Google"
        onSuccess={onGoogleSuccess}
        onFailure={e => alert(e.message)}
        cookiePolicy={'single_host_origin'}
      /> */}
    </div>
  );
};

export default reduxForm({
  form: 'NewUserRegistrationForm'
})(NewUserRegistrationForm);
