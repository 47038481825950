import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Attachments from './attachments';
import Card from '../Card';
import Documents from './documents';
import { TwoColumn } from '../inputs';
import { isImage } from '../../utils/attachmentSelector';
import { getLoggedInUser } from '../../selectors/users';

export const AttachmentUploadCard = ({
  addedAttachments,
  name,
  handleAnswer,
  isCreating,
  isEditing,
  deleteAttachment,
  addAttachments,
  ownerType,
  createdBy
}) => {
  const fromEquipment = ownerType === 'equipment';
  const loggedInUser = useSelector(getLoggedInUser);
  const [picturesToUpload, setPicturesToUpload] = useState([]);
  // need them in this format to acutally uploaded
  const [formattedPicturesToUpload, setFormattedPicturesToUpload] = useState(
    []
  );
  // documents during creation
  const [documentsToUpload, setDocumentsToUpload] = useState([]);
  // need them in this format to acutally uploaded
  const [formattedDocumentsToUpload, setFormattedDocumentsToUpload] = useState(
    []
  );
  // pictures during editing  - uploaded to db right away
  const [uploadedPictures, setUploadedPictures] = useState([]);
  // documets during editing  - uploaded to db right away
  const [uploadedDocuments, setUploadedDocuments] = useState([]);

  useEffect(() => {
    setUploadedDocuments(addedAttachments?.filter(a => !isImage(a)));
    setUploadedPictures(addedAttachments?.filter(a => isImage(a)));
  }, [addedAttachments]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (
      isFirstRender.current &&
      [...picturesToUpload, ...documentsToUpload].length === 0
    )
      return;
    handleAnswer(
      [...picturesToUpload, ...documentsToUpload],
      [...formattedPicturesToUpload, ...formattedDocumentsToUpload]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    picturesToUpload,
    documentsToUpload,
    formattedPicturesToUpload,
    formattedDocumentsToUpload
  ]);

  const canEditAll =
    fromEquipment ||
    ((loggedInUser.accessLevel === 900 || createdBy === loggedInUser._id) &&
      isEditing);

  return (
    <Card showHeader title="Attachments" name={name}>
      <TwoColumn>
        <Documents
          isEditing={isEditing}
          isCreating={isCreating}
          documentsToUpload={documentsToUpload}
          uploadedDocuments={uploadedDocuments}
          updateDocumentsToUpload={(selected, formatted) => {
            let together = documentsToUpload.concat(selected);
            let ft = formattedDocumentsToUpload.concat(formatted);
            setDocumentsToUpload(together);
            setFormattedDocumentsToUpload(ft);
          }}
          removeDocumentsToUpload={(selected, formatted) => {
            setFormattedDocumentsToUpload(formatted);
            setDocumentsToUpload(selected);
          }}
          deleteAttachment={attachment => deleteAttachment(attachment)}
          addAttachments={attachments => addAttachments(attachments)}
          ownerType={ownerType}
          fromEquipment={fromEquipment}
          loggedInUser={loggedInUser}
          canEditAll={canEditAll}
        />
        <Attachments
          isEditing={isEditing}
          isCreating={isCreating}
          picturesToUpload={picturesToUpload}
          uploadedPictures={uploadedPictures}
          updatePicturesToUpload={(selected, formatted) => {
            let together = picturesToUpload.concat(selected);
            let ft = formattedPicturesToUpload.concat(formatted);
            setFormattedPicturesToUpload(ft);
            setPicturesToUpload(together);
          }}
          removePicturesToUpload={(selected, formatted) => {
            setFormattedPicturesToUpload(formatted);
            setPicturesToUpload(selected);
          }}
          deleteAttachment={attachment => deleteAttachment(attachment)}
          addAttachments={(attachments, photoType) =>
            addAttachments(attachments, photoType)
          }
          fromEquipment={fromEquipment}
          canEditAll={canEditAll}
          ownerType={ownerType}
        />
      </TwoColumn>
    </Card>
  );
};

export default AttachmentUploadCard;
