import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import socketIOClient from 'socket.io-client';

import { getLoggedInUser } from '../selectors/users';
import config from '../config/config';

const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state)
});

export function useSocket() {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const s = socketIOClient(config.apiUrl, {
      transports: ['websocket'],
      auth: { token }
    });

    setSocket(s);
    return () => socket?.close?.();
    // eslint-disable-next-line
  }, []);

  return socket;
}

const withSocket = WrappedComponent => {
  const withSocket = class extends Component {
    state = {
      socket: null,
      dirty: false,
      lockedUser: null,
      id: null,
      component: null
    };

    componentWillUnmount() {
      this.state.socket?.close && this.state.socket.close();
    }

    socketSubscribe = ({ id, component = undefined }) => {
      const token = sessionStorage.getItem('token');
      const socket = socketIOClient(config.apiUrl, {
        transports: ['websocket'],
        auth: { token }
      });
      socket.on('lockInfo', lock => {
        this.setState((state, props) => {
          if (
            (state.component && lock.component !== state.component) ||
            lock.id !== state.id ||
            lock.userId === props.loggedInUser._id
          ) {
            return;
          }
          return {
            dirty: lock.dirty ? true : state.dirty,
            lockedUser: lock.userId || null
          };
        });
      });
      this.setState({ id, component, socket });
      socket.emit('subscribe', { id, component });
    };

    socketEmit = (
      emitValue,
      data = {
        id: this.state.id,
        component: this.state.component
      }
    ) => {
      if (
        this.state.socket &&
        this.state.lockedUser !== this.props.loggedInUser._id
      ) {
        this.state.socket.emit(emitValue, data);
      }
    };

    socketLock = () => {
      this.socketEmit('lock');
    };

    socketDirty = () => {
      this.socketEmit('dirty');
    };

    render() {
      const { dirty, lockedUser } = this.state;
      return (
        <WrappedComponent
          socketLock={this.socketLock}
          socketDirty={this.socketDirty}
          socketEmit={this.socketEmit}
          socketSubscribe={this.socketSubscribe}
          lockInfo={{
            dirty,
            lockedUser,
            myUser: this.props.loggedInUser._id
          }}
          locked={
            dirty || (lockedUser && lockedUser !== this.props.loggedInUser._id)
          }
          {...this.props}
        />
      );
    }
  };

  return connect(mapStateToProps)(withSocket);
};

export { withSocket };
