import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setActiveIncidentRequest } from '../../actions/incidents';
import {
  createOshaLogRequest,
  fetchOshaLogsRequest,
  printOshaRequest,
  printSelectedOshaRequest
} from '../../actions/oshaLogs';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject,
  getAllLocations,
  getUserCompaniesSelector
} from '../../selectors/company';
import {
  getOsha300ALogSelector,
  getOsha300LogSelector
} from '../../selectors/oshaLogs';
import yearToDateDropdown from '../../utils/yeartoDateDropdown';
import Button from '../../components/inputs/Button';
import Dropdown from '../../components/inputs/Dropdown';
import Modal from '../../components/Modal';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import Osha300List from '../../components/Osha300List';
import Osha300AForm from '../../components/Osha300AForm';
import Osha301List from '../../components/Osha301List';
import OshaLogDownload from '../../components/OshaLogDownload';
import { Checkbox, InputRow } from '../../components/inputs';
import Signature from '../../components/inputs/Signature';

import './index.css';

export class OshaLog extends Component {
  state = {
    selected: 0,
    year: new Date().getFullYear(),
    selectedRow: {},
    divisonEstInfo: {},
    openPrintModal: false,
    printValues: [],
    canPrint300ACSV: true,
    missingNoInformation: true,
    includeSignature: false
  };

  componentDidMount() {
    this.props.fetchOshaLogs({ year: new Date().getFullYear() });
    const compAddress = this.props.activeCompany?.mainAddress;
    if (this.props.activeLocationId !== 0) {
      let location;
      let longerThanYear;
      let sepEst;
      const locationIndex = this.props.activeCompany.groups.findIndex(
        location =>
          location._id === this.props.activeLocationId.fromLocationId ||
          location._id === this.props.activeLocationId._id
      );

      if (locationIndex > -1) {
        location = this.props.activeCompany.groups[locationIndex];

        longerThanYear = location.operationalForYearOrLonger;
        sepEst = location.isSeparateEstablishment;
      }
      if (this.props.activeProject) {
        let project = this.props.activeProject;
        const projectObject =
          project.isSeparateEstablishment && longerThanYear && sepEst
            ? {
                name: project.name,
                mainAddress: {
                  streetAddress: project.streetAddress,
                  city: project.city,
                  stateName: project.state,
                  zip: project.zip
                },
                NAICS: location.NAICS,
                SIC: location.SIC,
                entityCode: project.entityCode,
                bwcNumber: project.bwcNumber,
                reportingType: project.reportingType,
                reportingTypeNumber: project?.reportingTypeNumber,
                hasPublicEntity: project.hasPublicEntity,
                hasSchoolEntity: project.hasSchoolEntity,
                numberOfAllOtherSupportStaff:
                  project.numberOfAllOtherSupportStaff,
                numberOfFireEmtParamedic: project.numberOfFireEmtParamedic,
                numberOfFullTime: project.numberOfFullTime,
                numberOfPartTime: project.numberOfPartTime,
                numberOfPoliceFireEmt: project.numberOfPoliceFireEmt,
                numberOfTeachersInstructors:
                  project.numberOfTeachersInstructors,
                isSeparateEstablishment: location.isSeparateEstablishment,
                isSeparateProject: true,
                locationName: location.name
              }
            : project
            ? {
                ...this.props.activeCompany,
                name: project.name,
                mainAddress: {
                  streetAddress: location.isSeparateEstablishment
                    ? location.streetAddress
                    : compAddress?.streetAddress,
                  city: location.isSeparateEstablishment
                    ? location.city
                    : compAddress?.city,
                  stateName: location.isSeparateEstablishment
                    ? location.state
                    : compAddress?.stateName,
                  zip: location.isSeparateEstablishment
                    ? location.zip
                    : compAddress?.zip
                },
                isSeparateEstablishment: location.isSeparateEstablishment,
                isSeparateProject: false,
                locationName: location.name,
                reportingTypeNumber: location?.reportingTypeNumber
              }
            : {
                ...this.props.activeCompany,
                name: location.name,
                isSeparateEstablishment: location.isSeparateEstablishment,
                isSeparateProject: false,
                locationName: location.name
              };

        this.setState({
          divisonEstInfo: {
            ...projectObject,
            goToLocation: longerThanYear
          }
        });
      } else {
        const locationObject =
          longerThanYear && sepEst
            ? {
                name: location.name,
                mainAddress: {
                  streetAddress: location.streetAddress,
                  city: location.city,
                  stateName: location.state,
                  zip: location.zip
                },
                NAICS: location.NAICS,
                SIC: location.SIC,
                entityCode: location.entityCode,
                bwcNumber: location.bwcNumber,
                reportingType: location.reportingType,
                hasPublicEntity: location.hasPublicEntity,
                hasSchoolEntity: location.hasSchoolEntity,
                numberOfAllOtherSupportStaff:
                  location.numberOfAllOtherSupportStaff,
                numberOfFireEmtParamedic: location.numberOfFireEmtParamedic,
                numberOfFullTime: location.numberOfFullTime,
                numberOfPartTime: location.numberOfPartTime,
                numberOfPoliceFireEmt: location.numberOfPoliceFireEmt,
                numberOfTeachersInstructors:
                  location.numberOfTeachersInstructors,
                isSeparateEstablishment: true,
                reportingTypeNumber: location?.reportingTypeNumber
              }
            : {
                ...this.props.activeCompany,
                name: location.name,
                isSeparateEstablishment: false
              };

        this.setState({
          divisonEstInfo: {
            ...locationObject,
            goToLocation: longerThanYear
          }
        });
      }
    } else {
      this.setState({
        divisonEstInfo: {
          ...this.props.activeCompany,
          goToLocation: false,
          isSeparateEstablishment: true
        }
      });
    }

    if (this.props.match.params.year && this.props.match.params.year !== '') {
      this.setState({ year: this.props.match.params.year, selected: 1 });
    }
  }

  componentDidUpdate = prevProps => {
    if (
      this.props.osha300Logs &&
      prevProps.osha300Logs !== this.props.osha300Logs
    ) {
      let incompleteCases = false;

      this.props.osha300Logs &&
        this.props.osha300Logs.forEach(item => {
          if (item.isIncomplete) {
            incompleteCases = true;
          }
        });

      if (incompleteCases) {
        this.setState({ canPrint300ACSV: false, missingNoInformation: false });
      } else {
        this.setState({ canPrint300ACSV: true, missingNoInformation: true });
      }
    }
  };

  updateState = stateObject => {
    this.setState(stateObject);
  };

  handleYearSelection = year => {
    this.props.fetchOshaLogs({ year });
    this.updateState({ year });
  };

  handleOpenModal = () => {
    this.setState({ openPrintModal: true });
  };

  handleCloseModal = () => {
    this.setState({
      openPrintModal: false,
      printValues: [],
      signature: '',
      includeSignature: false
    });
  };

  render() {
    const {
      selected,
      year,
      selectedRow,
      divisonEstInfo,
      openPrintModal,
      printValues,
      canPrint300ACSV,
      missingNoInformation,
      includeSignature,
      signature
    } = this.state;

    const {
      osha300Logs,
      osha300ALogs,
      createOshaLog,
      setActiveIncident,
      printOsha,
      printSelectedOsha,
      activeProject,
      activeLocationId,
      userCompanies
    } = this.props;

    const canPrint301 = osha300Logs?.length > 0;

    const header = (
      <Header
        title={
          divisonEstInfo.reportingType === 2 ? 'PERRP Forms' : 'OSHA Forms'
        }
      />
    );

    return (
      <>
        <HeaderAndFooter Header={header} className="oshaLog">
          <div className="oshaLog-header">
            <div className="oshaLog-tabs">
              <div
                className={
                  selected === 0
                    ? 'oshaLog-tab oshaLog-tab1 selected'
                    : 'oshaLog-tab oshaLog-tab1 oshaLog-tab--notSelected'
                }
                onClick={() => this.updateState({ selected: 0 })}
              >
                <div className="oshaLog-tabs--headerTopLine">
                  {divisonEstInfo.reportingType === 2 ? '300P' : 'OSHA 300'}
                </div>
                <div className="oshaLog-tabs--headerBottomLine">
                  Injury & Illness Log
                </div>
                {selected === 0 && <div className="oshaLog-tab--selected" />}
              </div>
              <div
                className={
                  selected === 1
                    ? 'oshaLog-tab oshaLog-tab2 selected'
                    : 'oshaLog-tab oshaLog-tab2 oshaLog-tab--notSelected'
                }
                onClick={() => this.updateState({ selected: 1 })}
              >
                <div className="oshaLog-tabs--headerTopLine">
                  {divisonEstInfo.reportingType === 2 ? '300AP' : 'OSHA 300A'}
                </div>
                <div className="oshaLog-tabs--headerBottomLine"> Summary</div>
                {selected === 1 && (
                  <div className="oshaLog-tab2 oshaLog-tab--selected" />
                )}
              </div>
              <div
                className={
                  selected === 2
                    ? 'oshaLog-tab oshaLog-tab3 selected'
                    : 'oshaLog-tab oshaLog-tab3 oshaLog-tab--notSelected'
                }
                onClick={() => this.updateState({ selected: 2 })}
              >
                <div className="oshaLog-tabs--headerTopLine">
                  {divisonEstInfo.reportingType === 2 ? '301P' : 'OSHA 301'}
                </div>
                <div className="oshaLog-tabs--headerBottomLine">
                  Incident Reports
                </div>
                {selected === 2 && (
                  <div className="oshaLog-tab3 oshaLog-tab--selected" />
                )}
              </div>
            </div>
            <InputRow className="oshaLog-selection">
              <Dropdown
                options={yearToDateDropdown()}
                currentValue={year}
                onChange={this.handleYearSelection}
                inputClassName="oshaLog-selection--dropdown"
              />
              <Button
                text="Download Files"
                color="green"
                onClick={this.handleOpenModal}
              />
            </InputRow>
          </div>
          <div className="oshaLog-tabContent">
            {selected === 0 && osha300Logs && osha300Logs.length === 0 ? (
              <div className="oshaLogTab1-noContent">
                <div style={{ fontWeight: 600 }}>No Cases Found </div>
                <div>Check whether you have correct year</div>
              </div>
            ) : selected === 0 ? (
              <Osha300List
                cases={osha300Logs}
                goTo301={values => this.updateState(values)}
                setActiveIncident={setActiveIncident}
              />
            ) : (
              ''
            )}
            {selected === 1 ? (
              <Osha300AForm
                aggrates={osha300ALogs}
                divisonEstInfo={divisonEstInfo}
                createOshaLog={createOshaLog}
                year={year}
                isPerrp={divisonEstInfo.reportingType === 2}
                location={activeLocationId}
                project={activeProject}
              />
            ) : (
              ''
            )}
            {selected === 2 && !osha300Logs?.length ? (
              <div className="oshaLogTab1-noContent">
                <div style={{ fontWeight: 600 }}>No Cases Found </div>
                <div>Check whether you have correct year</div>
              </div>
            ) : selected === 2 ? (
              <Osha301List
                incidentReports={osha300Logs}
                selectedRow={selectedRow}
                setActiveIncident={setActiveIncident}
                printSelectedOsha={printSelectedOsha}
                activeLocationId={activeLocationId}
                activeProject={activeProject}
                isPerrp={divisonEstInfo.reportingType === 2}
                userCompanies={userCompanies}
              />
            ) : (
              ''
            )}
          </div>
        </HeaderAndFooter>
        {JSON.stringify(divisonEstInfo) !== '{}' && (
          <Modal
            title="Download Official Files"
            titleClassName="blueHeader"
            submitButtonText="Download Files"
            isOpen={openPrintModal}
            submitButtonColor="green"
            onRequestClose={() => this.handleCloseModal()}
            submitActions={() =>
              printOsha({
                year: year,
                print: printValues,
                signature: includeSignature ? signature?.source_url : null
              }) && this.handleCloseModal()
            }
            disableSubmit={
              printValues.length === 0 || (includeSignature && !signature)
            }
          >
            <OshaLogDownload
              onChange={values => this.updateState({ printValues: values })}
              currentValue={printValues}
              canPrint300ACSV={canPrint300ACSV}
              canPrint301={canPrint301}
              missingNoInformation={missingNoInformation}
              selected={selected}
              isShortTermEstablishment={!divisonEstInfo.isSeparateEstablishment}
              aggrates={osha300ALogs}
              divisonEstInfo={divisonEstInfo}
              activeProject={activeProject}
            />
            {printValues.includes(1) && (
              <Checkbox
                fieldLabel="Include signature on 300A forms?"
                onChange={value =>
                  this.updateState({ includeSignature: value })
                }
                currentValue={includeSignature}
                selected={includeSignature}
              />
            )}
            {includeSignature && printValues.includes(1) && (
              <Signature
                fieldLabel="This signature will be added to your OSHA/PERRP form"
                currentValue={signature}
                onChange={value => this.updateState({ signature: value })}
              />
            )}
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  osha300Logs: getOsha300LogSelector(state),
  osha300ALogs: getOsha300ALogSelector(state),
  activeCompany: getActiveCompany(state),
  activeLocationId: getActiveLocationId(state),
  allLocations: getAllLocations(state),
  activeProject: getActiveProject(state),
  userCompanies: getUserCompaniesSelector(state)
});

const mapDispatchToProps = dispatch => ({
  fetchOshaLogs: year => dispatch(fetchOshaLogsRequest(year)),
  createOshaLog: values => dispatch(createOshaLogRequest(values)),
  setActiveIncident: incidentId =>
    dispatch(setActiveIncidentRequest(incidentId)),
  printOsha: values => dispatch(printOshaRequest(values)),
  printSelectedOsha: values => dispatch(printSelectedOshaRequest(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(OshaLog);
