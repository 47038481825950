import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isValid } from 'redux-form';
import history from '../../history';
import { createDocumentQRCodeSheet } from '../../api/v4';
import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments,
  deleteAttachmentRequest
} from '../../actions/attachments';
import {
  addDocumentsToFolderRequest,
  addSafetyInstructionRequest,
  fetchDocumentFoldersRequest,
  fetchSafetyInstructionRequest,
  setActiveSafetyInstruction
} from '../../actions/documents';
import {
  getAddedAttachmentsSelector,
  getAttachmentLoadingSelector
} from '../../selectors/attachments';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import {
  getActiveFolderSelector,
  getCompanySafetyInstructionsSelector,
  getDocumentFoldersSelector
} from '../../selectors/documents';
import { getAccessLevel } from '../../selectors/users';
import AddToFolderModal from '../../components/Modal/addToFolderModal';
import DocumentFolder from '../../components/DocumentFolder';
import Modal from '../../components/Modal';
import SafetyInstructionForm from '../../forms/SafetyInstructionForm';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import List from '../../components/List';

export class SafetyInstructionList extends Component {
  state = {
    openModal: false,
    addToFolder: [],
    addToFolderModal: false,
    documentFolderId: ''
  };

  componentDidMount() {
    this.props.fetchSafetyInstructions();

    this.props.fetchDocumentFolders({
      documentType: 'SafetyInstruction'
    });
  }

  handleOpenModal = () => this.setState({ openModal: true });
  handleCloseModal = () => this.setState({ openModal: false });

  handleSubmit = values => {
    this.props.addSafetyInstruction(values);
    this.props.clearAttachment();
    this.handleCloseModal();
  };

  handleAddMultipleToFolders = () => {
    const { content } = this.props;
    let addDocsToFolder = [];

    const addToFolder = this.state.addToFolder;

    content.forEach(document => {
      if (addToFolder.indexOf(document._id) > -1) {
        addDocsToFolder.push(document);
      }
    });

    this.props.addDocumentToFolder({
      documents: addDocsToFolder,
      documentFolderId: this.state.documentFolderId
    });

    this.setState({ addToFolderModal: false, addToFolder: [] });
  };

  createQRCodes = ids => {
    createDocumentQRCodeSheet(ids).then(response =>
      window.open(response, '_blank')
    );
  };

  render() {
    const {
      content,
      setSafetyInstruction,
      addAttachment,
      clearAttachment,
      activeCompany,
      loading,
      accessLevel,
      deleteAttachment,
      addedAttachments,
      activeLocation,
      activeProject,
      documentFolders,
      formValid
    } = this.props;

    const { openModal, addToFolderModal, documentFolderId } = this.state;

    const folderList = documentFolders.map(folder => {
      return {
        value: folder._id,
        label: folder.name
      };
    });

    const columns = [
      { key: 'label', label: 'Title' },
      { key: 'expires', label: 'Expiration Date', datatype: 'futureDate' },
      { key: 'attachments', datatype: 'attachments' }
    ];

    if (!activeLocation) {
      columns.unshift({
        key: 'groupIds',
        label: 'Group/Est.',
        datatype: 'groups'
      });
    }

    const header = (
      <Header
        title="Safety Procedures"
        rightButtons={[
          {
            color: 'green',
            text: 'Add Safety Procedure',
            visible: accessLevel !== 100,
            onClick: this.handleOpenModal
          }
        ]}
      />
    );

    return (
      <>
        <HeaderAndFooter Header={header}>
          <ScrollToTopOnMount />
          <DocumentFolder content={content} docType="Safety Training" />
          <List
            saveKey="siList"
            data={content}
            dataIsHash
            settings={columns}
            actions={[
              {
                color: 'blue',
                label: 'Add to Folder',
                visible: accessLevel !== 100,
                onClick: ids =>
                  this.setState({
                    addToFolder: ids,
                    addToFolderModal: true
                  })
              },
              {
                label: 'Download QR Codes',
                color: 'green',
                onClick: ids => this.createQRCodes(ids)
              }
            ]}
            rowClick={row => {
              setSafetyInstruction(row);
              history.push('/app/viewEdit/Safety Procedure');
            }}
            getRowId={r => r._id}
          />
        </HeaderAndFooter>
        {openModal && (
          <Modal
            title="Add Safety Procedure"
            titleClassName="greenHeader"
            wide
            submitButtonColor="green"
            isOpen={openModal}
            onRequestClose={() => clearAttachment() && this.handleCloseModal()}
            formName="SafetyInstructionForm"
            disableSubmit={!formValid}
            testID="safetyProc"
          >
            <SafetyInstructionForm
              onSubmit={values => this.handleSubmit(values)}
              addAttachment={addAttachment}
              activeCompanyId={activeCompany._id}
              loading={loading}
              locationList={activeCompany.groups}
              deleteAttachment={deleteAttachment}
              addedAttachments={addedAttachments}
              activeLocation={activeLocation}
              activeProject={activeProject}
              documentFolders={documentFolders}
            />
          </Modal>
        )}
        <AddToFolderModal
          isOpen={addToFolderModal}
          folderId={documentFolderId}
          onRequestClose={() => this.setState({ addToFolderModal: false })}
          submitActions={() => this.handleAddMultipleToFolders()}
          folderOptions={folderList}
          onChange={value => this.setState({ documentFolderId: value })}
        />
      </>
    );
  }
}

export const mapStateToProps = state => ({
  content: getCompanySafetyInstructionsSelector(state),
  activeCompany: getActiveCompany(state),
  loading: getAttachmentLoadingSelector(state),
  accessLevel: getAccessLevel(state),
  addedAttachments: getAddedAttachmentsSelector(state),
  activeProject: getActiveProject(state),
  activeLocation: getActiveLocationId(state),
  documentFolders: getDocumentFoldersSelector(state),
  formValid: isValid('SafetyInstructionForm')(state),
  activeFolder: getActiveFolderSelector(state)
});

export const mapDispatchToProps = dispatch => ({
  addSafetyInstruction: safetyInstruction =>
    dispatch(addSafetyInstructionRequest(safetyInstruction)),
  setSafetyInstruction: safetyInstruction =>
    dispatch(setActiveSafetyInstruction(safetyInstruction)),
  addAttachment: attachment =>
    dispatch(addUploadedAttachmentsRequest(attachment)),
  clearAttachment: () => dispatch(clearUploadedAttachments()),
  deleteAttachment: values => dispatch(deleteAttachmentRequest(values)),
  fetchDocumentFolders: values => dispatch(fetchDocumentFoldersRequest(values)),
  addDocumentToFolder: values => dispatch(addDocumentsToFolderRequest(values)),
  fetchSafetyInstructions: paginationInfo =>
    dispatch(fetchSafetyInstructionRequest(paginationInfo))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SafetyInstructionList);
