import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// import { uploadAttachment, deleteAttachment } from '../../../api';
import {
  fetchEmployee,
  createClaim,
  updateClaim,
  deleteClaim,
  fetchClaimById,
  printClaim
} from '../../../api/v4';
import config from '../../../config/config';
import history from '../../../history';
import { setActiveIncidentRequest } from '../../../actions/incidents';
import { addMessage } from '../../../actions/messages';
import { getActiveIncidentSelector } from '../../../selectors/incidents';
import { getActiveOshaLogSelector } from '../../../selectors/oshaLogs';
import { getMyUserId } from '../../../selectors/users';
// import { formatFiles } from '../../../utils/attachmentSelector';
import { normalizeLibertyMutualPhone } from '../../../utils/formValidation';
import { useActiveHeirarchy } from '../../../utils/useActiveHeirarchy';
// import AttachmentUploadCard from '../../../components/AttachmentUploadCard';
import ClaimCard from '../../../components/claims/Cards';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import InstructionCard from '../../../components/claims/InstructionCard';
import Modal from '../../../components/Modal';
import LeftNav from '../../../components/LeftNav';
import { SubmitSaveForLaterCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';

import styles from './claimCreate.module.scss';

export default function Create() {
  const activeIncident = useSelector(getActiveIncidentSelector);
  const { company, location, project } = useActiveHeirarchy();
  const activeOshaLog = useSelector(getActiveOshaLogSelector);
  const myUserId = useSelector(getMyUserId);
  const employeeId =
    activeIncident?.answers?.[config.staticIds.froiInjuredEmployeeQuestion];
  let { claimId } = useParams();
  const dispatch = useDispatch();

  const [id, setId] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [injuryInfo, setInjuryInfo] = useState({});
  const [employeeInfo, setEmployeeInfo] = useState({});
  const [accidentInfo, setAccidentInfo] = useState({});
  const [policyInfo, setPolicyInfo] = useState([]);
  const [overviewInfo, setOverviewInfo] = useState({});
  const [creating, setCreating] = useState(false);
  const [employeeEditing, setEmployeeEditing] = useState(false);
  const [injuryEditing, setInjuryEditing] = useState(false);
  const [accidentEditing, setAccidentEditing] = useState(false);
  const [policyEditing, setPolicyEditing] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saveChangesModal, setSaveChangesModal] = useState(false);
  const [sumbitModal, setSubmitModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [incidentId, setIncidentId] = useState();
  const [availablePolicies, setAvailablePolicies] = useState();
  const [attachments, setAttachments] = useState([]);
  useEffect(() => {
    if (!claimId) {
      setCreating(true);
      fetchEmployee(employeeId[0]?.value ?? employeeId[0]).then(employee => {
        const oshaAnswers = activeOshaLog[0]?.questions;
        let deathDate;
        if (oshaAnswers) {
          deathDate = oshaAnswers?.filter(o => o.key === 'dateOfDeath')?.value;
        }

        setEmployeeInfo({
          employeeId: employeeId,
          employeeFirstName: employee?.firstName,
          employeeLastName: employee?.lastName,
          employeeGender: employee?.gender,
          employeePhone: normalizeLibertyMutualPhone(employee?.phoneNumber),
          streetAddress: employee?.address?.streetAddress,
          city: employee?.address?.city,
          state: employee?.address?.stateName,
          zip: employee?.address?.zip,
          employeeOccupation: employee?.jobTitle,
          employeeDateOfHire: employee?.dateOfHire,
          employeeDeathDate: deathDate
        });
      });

      if (project) {
        setAccidentInfo({ projectId: project._id, groupId: location._id });
        setAvailablePolicies(company.policies);
        setPermissions(company.claimPermissions);
      } else if (location) {
        setAccidentInfo({ groupId: location._id });
        setAvailablePolicies(company.policies);
        setPermissions(company.claimPermissions);
      } else if (company) {
        setAvailablePolicies(company.policies);
        setPermissions(company.claimPermissions);
      }

      const answers = activeIncident?.answers;
      if (answers) {
        let incidentLocation =
          answers[config.staticIds.incidentLocation] &&
          !answers?.[config.staticIds.incidentLocation].includes(
            'Not Applicable'
          )
            ? answers?.[config.staticIds.incidentLocation]?.split(', ')
            : '';

        if (!incidentLocation) {
          const location = company.groups.find(
            g => g._id === activeIncident.groupId
          );
          incidentLocation = [
            location.streetAddress,
            location.city,
            location.state,
            location.zip
          ];
        }
        setAccidentInfo({
          incidentDate: answers[config.staticIds.timeIncidentOccured],
          incidentDescription: answers[config.staticIds.incidentDescription],
          streetAddress: incidentLocation?.[0],
          city: incidentLocation?.[1],
          state: incidentLocation?.[2],
          zip: incidentLocation?.[3] === 'USA' ? null : incidentLocation?.[3],
          groupId: activeIncident.groupId,
          projectId: activeIncident.projectId
        });

        setInjuryInfo({
          sourceOfInjury: answers[config.staticIds.froiObjectSubstance],
          injuryDescription: answers[config.staticIds.froiWorkerDoing]
        });
      }
    } else {
      fetchClaimById(claimId).then(claim => {
        setClaimInfo(claim);
      });
      if (company) {
        setAvailablePolicies(company.policies);
        setPermissions(company.claimPermissions);
      }
    }
  }, [
    activeIncident,
    activeOshaLog,
    claimId,
    company,
    employeeId,
    location,
    project
  ]);

  const setClaimInfo = claim => {
    setId(claim._id);
    setIsSubmitted(claim.isSubmitted);
    setIncidentId(claim.incidentId);
    setOverviewInfo({
      claimNumber: claim.claimNumber,
      previousClaims: claim.previousClaims,
      submissionDate: claim.submissionDate
    });
    setEmployeeInfo({
      employeeId: claim.employeeId,
      employeeFirstName: claim.employeeFirstName,
      employeeLastName: claim.employeeLastName,
      employeeGender: claim.employeeGender,
      employeePhone: claim.employeePhone,
      employeeSSN: claim?.employeeSSN,
      streetAddress: claim.employeeAddress?.streetAddress,
      city: claim.employeeAddress?.city,
      state: claim.employeeAddress?.state,
      zip: claim.employeeAddress?.zip,
      employeeOccupation: claim.employeeOccupation,
      employeeDateOfHire: claim.employeeDateOfHire,
      employeeDeathDate: claim.employeeDeathDate,
      employeeMaritalStatus: claim.employeeMaritalStatus,
      employmentStatus: claim.employmentStatus,
      numberOfDaysWorkedPerWeek: claim.numberOfDaysWorkedPerWeek,
      numberOfHoursWorkedPerWeek: claim.numberOfHoursWorkedPerWeek
    });
    setAccidentInfo({
      groupId: claim.groupId,
      projectId: claim.projectId,
      incidentDate: claim.incidentDate,
      streetAddress: claim.incidentLocation?.incidentStreetAddress,
      city: claim.incidentLocation?.incidentCity,
      state: claim.incidentLocation?.incidentState,
      zip: claim.incidentLocation?.incidentZip,
      incidentLocationCounty: claim.incidentLocationCounty,
      incidentLocationDescription: claim.incidentLocationDescription,
      incidentDescription: claim.incidentDescription,
      claimReportedByTitle: claim.claimReportedByTitle,
      claimJurisdictionCode: claim.claimJurisdictionCode,
      claimNotificationOther: claim.claimNotificationOther
    });
    setInjuryInfo({
      bodyPartAffected: claim.bodyPartAffected,
      natureOfInjury: claim.natureOfInjury,
      injuryCauseCode: claim.injuryCauseCode,
      sourceOfInjury: claim.sourceOfInjury,
      injuryDescription: claim.injuryDescription,
      initialTreatment: claim.initialTreatment,
      wagesPaidForDateOfInjury: claim.wagesPaidForDateOfInjury,
      absenceFromWorkDueToInjury: claim.absenceFromWorkDueToInjury
    });
    setPolicyInfo({
      policyEffectiveDate: claim.policyId?.policyEffectiveDate,
      policyEndDate: claim.policyId?.policyExpirationDate,
      policyNumber: claim.policyId?.policyNumber,
      lineOfBusinessCode: claim.policyId?.lineOfBusinessCode
    });
    setPermissions(claim.permissions);
    setAttachments(claim.attachments);
  };

  const handleSubmit = isSubmitted => {
    if (!id) {
      createClaim({
        ...employeeInfo,
        employeeAddress: {
          streetAddress: employeeInfo.streetAddress,
          city: employeeInfo.city,
          state: employeeInfo.state,
          zip: employeeInfo.zip
        },
        ...injuryInfo,
        ...accidentInfo,
        incidentLocation: {
          incidentStreetAddress: accidentInfo.streetAddress,
          incidentCity: accidentInfo.city,
          incidentState: accidentInfo.state,
          incidentZip: accidentInfo.zip
        },
        ...policyInfo,
        incidentId: activeIncident._id,
        oshaFormId: activeOshaLog?.[0]?._id,
        isSubmitted,
        permissions: company.claimPermissions,
        attachments
      }).then(claim => {
        setClaimInfo(claim);
        setId(claim._id);
        setSubmitModal(false);
        setUnsavedChanges(false);
        setCreating(false);
        if (claim.isSubmitted) {
          dispatch(
            addMessage({ error: false, message: 'Submitted Successfully!' })
          );
        } else {
          dispatch(
            addMessage({ error: false, message: 'Saved Successfully!' })
          );
        }
      });
    } else {
      updateClaim({
        _id: id,
        ...employeeInfo,
        employeeAddress: {
          streetAddress: employeeInfo.streetAddress,
          city: employeeInfo.city,
          state: employeeInfo.state,
          zip: employeeInfo.zip
        },
        ...injuryInfo,
        ...accidentInfo,
        incidentLocation: {
          incidentStreetAddress: accidentInfo.streetAddress,
          incidentCity: accidentInfo.city,
          incidentState: accidentInfo.state,
          incidentZip: accidentInfo.zip
        },
        ...policyInfo,
        incidentId: activeIncident._id,
        oshaFormId: activeOshaLog?.[0]?._id,
        isSubmitted,
        attachments
      }).then(claim => {
        setAccidentEditing(false);
        setInjuryEditing(false);
        setEmployeeEditing(false);
        setPolicyEditing(false);
        setUnsavedChanges(false);
        setClaimInfo(claim);
        setSubmitModal(false);
        if (claim.isSubmitted) {
          dispatch(
            addMessage({ message: 'Submitted Successfully!', error: false })
          );
        } else {
          dispatch(
            addMessage({ message: 'Saved Successfully!', error: false })
          );
        }
      });
    }
  };

  const handleDelete = () => {
    deleteClaim(id).then(() => history.goBack());
  };

  const handleCancel = goBack => {
    if (goBack) history.goBack();
    if (
      id &&
      (accidentEditing || injuryEditing || employeeEditing || policyEditing)
    ) {
      setAccidentEditing(false);
      setInjuryEditing(false);
      setEmployeeEditing(false);
      setPolicyEditing(false);
      setUnsavedChanges(false);
    } else if (unsavedChanges && !saveChangesModal) {
      setSaveChangesModal(true);
    } else {
      history.goBack();
    }
  };

  const print = () => {
    printClaim(id).then(response => window.open(response, '_blank'));
  };

  const employeeInfoComplete = () => {
    if (
      !employeeInfo.employeeGender ||
      !employeeInfo.employeeSSN ||
      !employeeInfo.employeePhone ||
      !employeeInfo.streetAddress ||
      !employeeInfo.city ||
      !employeeInfo.state ||
      !employeeInfo.zip ||
      !employeeInfo.employeeDateOfHire ||
      !employeeInfo.employeeOccupation ||
      !employeeInfo.employeeMaritalStatus ||
      !employeeInfo.employmentStatus ||
      !employeeInfo.numberOfDaysWorkedPerWeek ||
      employeeInfo.numberOfDaysWorkedPerWeek > 7 ||
      employeeInfo.numberOfDaysWorkedPerWeek < 0 ||
      !employeeInfo.numberOfHoursWorkedPerWeek
    )
      return false;

    return true;
  };

  const injuryInfoComplete = () => {
    if (
      !injuryInfo.bodyPartAffected ||
      !injuryInfo.natureOfInjury ||
      !injuryInfo.injuryCauseCode ||
      !injuryInfo.sourceOfInjury ||
      !injuryInfo.injuryDescription ||
      !injuryInfo.initialTreatment ||
      !injuryInfo.wagesPaidForDateOfInjury ||
      !injuryInfo.absenceFromWorkDueToInjury
    )
      return false;

    return true;
  };

  const accidentInfoComplete = () => {
    if (
      !accidentInfo.groupId ||
      !accidentInfo.incidentDate ||
      !accidentInfo.incidentDescription ||
      !accidentInfo.streetAddress ||
      !accidentInfo.city ||
      !accidentInfo.state ||
      !accidentInfo.zip ||
      !accidentInfo.incidentLocationCounty ||
      !accidentInfo.incidentLocationDescription ||
      !accidentInfo.claimReportedByTitle ||
      !accidentInfo.claimJurisdictionCode
    )
      return false;

    return true;
  };

  const policyInfoComplete = () => {
    if (
      !policyInfo.policyNumber ||
      !policyInfo.policyEffectiveDate ||
      !policyInfo.policyEndDate ||
      !policyInfo.lineOfBusinessCode
    )
      return false;
    return true;
  };

  const canSubmit = () =>
    employeeInfoComplete() &&
    injuryInfoComplete() &&
    accidentInfoComplete() &&
    policyInfoComplete();

  const isReadOnly = permissions?.find(p => p.userId === myUserId)?.readOnly;

  // don't need until we add auto and GC
  // const addAttachments = attachment => {
  //   if (!attachment.length > 0) return;
  //   let data = formatFiles(attachment);
  //   uploadAttachment(data, {
  //     ownerType: 'claim',
  //     isSignature: false,
  //     isPhotoEvidence: false
  //   }).then(response => {
  //     setAttachments([...attachments, ...response]);
  //   });
  // };

  // const deleteAtt = attachment => {
  //   deleteAttachment(attachment._id).then(() => {
  //     let a = attachments.filter(a => a !== attachment);
  //     setAttachments([...a]);
  //     if (claimId) {
  //       handleSubmit(isSubmitted);
  //     }
  //   });
  // };

  const header = (
    <Header
      title={activeIncident?.incidentNumber}
      section="Claim"
      pageActionOptions={[
        {
          label: 'Permissions',
          visible: !creating && !isReadOnly,
          color: 'blueOutline',
          onClick: () => history.push(`/app/permissions/Claim/${id}`)
        },
        {
          label: 'Return to Incident',
          visible: true,
          color: 'blueOutline',
          onClick: () =>
            dispatch(setActiveIncidentRequest(incidentId ?? activeIncident._id))
        },
        {
          label: 'Download Claim',
          visible: isSubmitted,
          color: 'greenOutline',
          onClick: print
        },
        {
          label: 'Delete Claim',
          visible: !creating && isSubmitted && !isReadOnly,
          color: 'redOutline',
          onClick: handleDelete
        }
      ]}
      clickBack={() =>
        unsavedChanges ? setSaveChangesModal(true) : history.goBack()
      }
      needsSaved={unsavedChanges}
    />
  );

  const footer = (
    <SubmitSaveForLaterCancelFooter
      submitButtonClick={() => setSubmitModal(true)}
      saveButtonClick={() => handleSubmit(false)}
      submitButtonDisabled={!canSubmit()}
      cancelButtonClick={handleCancel}
    />
  );

  const left = (
    <LeftNav
      items={[
        {
          value: 0,
          label: isSubmitted ? 'Overview' : 'Instructions',
          complete: true
        },
        {
          value: 1,
          label: 'Employee Information',
          complete: employeeInfoComplete()
        },
        {
          value: 2,
          label: 'Injury Information',
          complete: injuryInfoComplete()
        },
        {
          value: 3,
          label: 'Accident Information',
          complete: accidentInfoComplete()
        },
        {
          value: 4,
          label: 'Policy Information',
          complete: policyInfoComplete()
        }
        // { value: 5, label: 'Claim Attachments' }
      ]}
    />
  );

  return (
    <HeaderAndFooter
      Header={header}
      Footer={footer}
      Left={left}
      showFooter={!isSubmitted && !isReadOnly}
    >
      {isSubmitted ? (
        <ClaimCard
          name={0}
          claimSection={overviewInfo}
          title="Overview"
          viewOnly
        />
      ) : (
        <InstructionCard name={0} />
      )}
      <ClaimCard
        name={1}
        editing={creating || employeeEditing}
        viewOnly={isReadOnly}
        claimSection={employeeInfo}
        onChange={values => {
          setEmployeeInfo(values);
          setUnsavedChanges(true);
        }}
        title="Employee Information"
        openEdit={setEmployeeEditing}
        isSubmitted={isSubmitted}
      />
      <ClaimCard
        name={2}
        editing={creating || injuryEditing}
        viewOnly={isReadOnly}
        claimSection={injuryInfo}
        onChange={values => {
          setInjuryInfo(values);
          setUnsavedChanges(true);
        }}
        title="Injury Information"
        openEdit={setInjuryEditing}
        isSubmitted={isSubmitted}
      />
      <ClaimCard
        name={3}
        editing={creating || accidentEditing}
        viewOnly={isReadOnly}
        claimSection={accidentInfo}
        onChange={values => {
          setAccidentInfo(values);
          setUnsavedChanges(true);
        }}
        title="Accident Information"
        openEdit={setAccidentEditing}
        isSubmitted={isSubmitted}
        permissions={permissions}
      />
      <ClaimCard
        name={4}
        editing={creating || policyEditing}
        viewOnly={isReadOnly}
        claimSection={policyInfo}
        onChange={values => {
          setPolicyInfo(values);
          setUnsavedChanges(true);
        }}
        title="Policy Information"
        openEdit={setPolicyEditing}
        isSubmitted={isSubmitted}
        availablePolicies={availablePolicies}
      />
      {/* <AttachmentUploadCard
        name={5}
        addAttachments={addAttachments}
        deleteAttachment={deleteAtt}
        isEditing={!isSubmitted}
        addedAttachments={attachments}
        attachmentsToUpload={[]} // not sure if I need this
        ownerType="claim"
        createdBy={myUserId}
      /> */}
      <SaveChangesModal
        onRequestClose={() => setSaveChangesModal(false)}
        isOpen={saveChangesModal}
        submitActions={() => handleCancel(true)}
      />
      <DeleteItemModal
        onRequestClose={() => setDeleteModal(false)}
        isOpen={deleteModal}
        submitActions={handleDelete}
        hideCustomTemplate
        what="Claim"
      />
      <Modal
        title="Submit Claim"
        titleClassName="blueHeader"
        isOpen={sumbitModal}
        submitButtonColor="blue"
        submitButtonText="Submit"
        onRequestClose={() => setSubmitModal(false)}
        submitActions={() => handleSubmit(true)}
      >
        <div className={styles.text}>
          Once you submit the claim the data will no longer update within the
          incident or for your insurance. Make sure you have attached all
          supporting documentation before you submit.
        </div>
      </Modal>
    </HeaderAndFooter>
  );
}
