import { call, put, select } from 'redux-saga/effects';
import {
  addUploadedAttachmentsResponse,
  deleteAttachmentResponse
} from '../actions/attachments';
import { addMessage } from '../actions/messages';
import { getAddedAttachmentsSelector } from '../selectors/attachments';

export function* uploadDocument(api, { payload }) {
  try {
    const addedAttachments = yield select(getAddedAttachmentsSelector);

    let response = [];

    response = yield call(api.uploadAttachment, payload.data, {
      ownerId: payload.ownerId,
      ownerType: payload.ownerType,
      isSignature: payload.isSiganture ?? false,
      isPhotoEvidence: payload.isPhotoEvidence ?? false,
      photoType: payload?.photoType
    });

    yield put(
      addUploadedAttachmentsResponse([...addedAttachments, ...response])
    );

    yield put(addMessage({ error: false, message: 'Attachments Uploaded' }));
  } catch (e) {
    yield put(
      addMessage({
        error: true,
        message:
          'Attachment cannot be uploaded at this time. Please try again later.'
      })
    );
  }
}

export function* deleteAttachment(api, { payload }) {
  try {
    yield call(api.deleteAttachment, payload._id, payload?.fromOnTheSpot);
    yield put(deleteAttachmentResponse(payload));
  } catch (e) {
    yield put(
      addMessage({ error: true, message: 'Could not delete attachment' })
    );
  }
}

export function* updateAttachment(api, { payload }) {
  try {
    yield call(api.updateAttachment, payload);
  } catch (e) {
    yield put(
      addMessage({ error: true, message: 'Could not delete attachment' })
    );
  }
}
