import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';

import { createWitnessStatement } from '../../api/v4';
import history from '../../history';
import {
  emailIncidentAssigneeRequest,
  fetchIncidentByIdRequest,
  updateReportComponentRequest
} from '../../actions/incidents';
import {
  createOshaFormRequest,
  updateOshaFormRequest
} from '../../actions/oshaLogs';
import { setActiveTaskRequest } from '../../actions/tasks';
import { createReportResponse } from '../../actions/reports';
import { getAttachmentLoadingSelector } from '../../selectors/attachments';
import { getActiveCompany } from '../../selectors/company';
import {
  getActiveIncidentSelector,
  getIncidentsLoadingSelector
} from '../../selectors/incidents';
import { getActiveOshaLogSelector } from '../../selectors/oshaLogs';
import { getActiveReportsSelector } from '../../selectors/reports';
import { getLoggedInUser, getAllUsers } from '../../selectors/users';
import {
  canMarkAsComplete,
  hasAccess,
  isSectionComplete
} from '../../utils/incidentHelper';
import { isComplete } from '../../utils/reportHelper';
import {
  canSubmit,
  oshaDbMapper,
  numberOfDays
} from '../../utils/oshaDbMapper';
import AssignCorrectiveActionButton from '../../components/inputs/AssignCorrectiveActionButton';
import Card from '../../components/Card';
import Header from '../../components/Header';
import { HeaderAndFooter } from '../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../components/Footer';
import Modal from '../../components/Modal';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import MarkAsCompleteModal from '../../components/Modal/markAsCompleteModal';
import OshaComplianceCard from '../../components/reportCards/OshaComplianceCard';
import ReportSection from '../../components/reportCards/ReportSection';
import Textbox from '../../components/inputs/Textbox';
import LeftNav from '../../components/LeftNav';
import ViewOnly from '../../components/ViewOnly';
import Assignee from '../../components/Assignee';
import { withSocket } from '../../utils/withSocket';
import WitnessStatementCard from '../../components/inputs/WitnessStatementCard';
import { DatePicker } from '../../components/inputs/DateTimePicker';
import { isFutureDate } from '../../utils/formValidation';
import { EmployeeDropdown } from '../../components/inputs';
import isEqual from 'lodash/isEqual';
import { Checkbox } from '../../components/inputs';

import './index.css';

export class ReportSectionContainer extends Component {
  state = {
    openEmailAssigneeModal: false,
    openEmailStatementModal: false,
    openUnsavedChangesModal: false,
    openMarkAsCompleteModal: false,
    openDeleteIrrelevantReportModal: false,
    openUnlockAndReopenReportModal: false,
    emailMessage: '',
    emailAddress: '',
    activeReport: [],
    hasUnsavedChanges: false,
    oshaQuestionnaire: [],
    canSubmitOshaLog: false,
    openAddWitnessModal: false,
    additionalWitness: [],
    currentWitnesses: [],
    witnessDueDate: null
  };

  componentDidMount() {
    let reportName = '';
    let minSections = '';
    if (this.props.activeReport.report) {
      this.setState({
        activeReport: [...this.props.activeReport.report]
      });
      reportName = this.props.activeReport.report?.[0]?.[0];
      minSections = this.props.activeReport.report?.[0]?.[6].minSections;
      if (reportName === 'Witness Statements') {
        let assignees = this.props.activeReport.report[0]?.[1]?.statements?.map(
          s => s.assignee
        );
        this.setState({ currentWitnesses: assignees });
      }
    } else {
      this.setState({
        activeReport: [...this.props.activeReport]
      });
      reportName = this.props.activeReport?.[0]?.[0];
      minSections = this.props.activeReport?.[0]?.[6].minSections;
    }

    if (this.props.oshaForm.length > 0) {
      const oshaQuestionnaire = [];

      this.props.oshaForm.forEach(form =>
        oshaQuestionnaire.push({
          ...form.formAnswers,
          daysJobRestriction: numberOfDays(
            form.formAnswers.daysJobRestrictionArray,
            form.formAnswers.daysJobRestrictionCheckbox
          ),
          daysAwayFromWork: numberOfDays(
            form.formAnswers.daysAwayFromWorkArray,
            form.formAnswers.daysAwayFromWorkCheckbox
          ),
          incidentId: form._id,
          _id: form._id,
          isLegacy: form.isLegacy
        })
      );

      this.setState({ oshaQuestionnaire });
    } else if (reportName === 'OSHA Compliance') {
      this.setState({
        oshaQuestionnaire: Array(minSections).fill({})
      });
    }

    this.props.socketSubscribe({
      id: this.props.activeIncident._id,
      component: this.props.activeReport?.report?.[0]?.[0]
    });
  }

  componentDidUpdate(prevProps) {
    let reportName = '';
    if (!isEqual(this.props.activeReport, prevProps.activeReport)) {
      this.setState({
        activeReport: [...this.props.activeReport.report]
      });
      reportName = this.props.activeReport.report?.[0]?.[0];

      if (reportName === 'Witness Statements') {
        let assignees = this.props.activeReport.report[0]?.[1]?.statements?.map(
          s => s.assignee
        );
        this.setState({ currentWitnesses: assignees });
      }
    }
  }

  sendEmail = () => {
    const { emailIncidentAssignee, activeIncident } = this.props;
    const { activeReport } = this.state;

    const reportTask =
      activeIncident &&
      activeIncident.incidentActivity &&
      activeIncident.incidentActivity.openTasks.filter(
        task =>
          activeReport.length > 0 &&
          task.reportComponentName === this.getRCName(activeReport)
      );
    emailIncidentAssignee({
      messageContent: this.state.emailMessage,
      incidentId: this.props.activeIncident?._id,
      userId: this.props.loggedInUser?._id,
      sentMessageToId: reportTask?.[0]?.assignedTo?.[0]
    });

    this.setState({ openEmailAssigneeModal: false });
  };

  answerQuestion = async (fields, subSectionIndex) => {
    const { activeReport } = this.state;
    this.props.socketLock();

    activeReport[0][1].subSections[subSectionIndex][1] = fields;
    activeReport[0][5].isStarted = true;

    this.setState({
      activeReport,
      hasUnsavedChanges: true
    });
  };

  cancelAction = async () => {
    this.props.fetchIncidentById(this.props.activeIncident);
    history.push(`/app/incidentContainer/${this.props.match.params.stage}`);
  };

  reopenReportSection = async () => {
    const { activeReport } = this.state;
    activeReport[0][4].isComplete = false;
    await this.props.updateReportComponent({
      stage: this.props.match.params.stage
    });
  };

  answerOsha = (values, index) => {
    this.setState((state, props) => {
      const { oshaQuestionnaire } = state;
      const oshaForm = { ...oshaQuestionnaire[index], ...values };
      let osha = [...oshaQuestionnaire];
      osha[index] = oshaForm;
      return {
        oshaQuestionnaire: osha,
        hasUnsavedChanges: true
      };
    });

    this.props.socketLock();
  };

  handleImmediatelyFixed = (values, index) => {
    let { activeReport } = this.state;
    const correctiveActions = [...activeReport[0][1]?.correctiveActions];
    correctiveActions[index].immediatelyFixed = values;
    activeReport[0][1].correctiveActions = [...correctiveActions];
    this.setState({
      activeReport: [...activeReport],
      hasUnsavedChanges: true
    });
  };

  handleUpdateWitnessStatement = (values, index) => {
    let { activeReport } = this.state;

    this.setState({
      activeReport: [...activeReport],
      hasUnsavedChanges: true
    });
  };

  handleSectionSubmit = async (markAsComplete, dontLeave = false) => {
    const { activeReport, oshaQuestionnaire } = this.state;
    const { activeIncident } = this.props;
    this.setState({
      hasUnsavedChanges: false,
      intervalId: null
    });

    let rcName = this.getRCName(activeReport);

    const canSubmitOshaLog = oshaQuestionnaire.map(form => canSubmit(form));
    const isOldWitness =
      (rcName === 'Witness Statements' && activeIncident.isWitnessLegacy) ||
      rcName !== 'Witness Statements';

    const showComplete =
      rcName !== 'OSHA Compliance' &&
      rcName !== 'Corrective Actions' &&
      isOldWitness
        ? canMarkAsComplete(activeReport[0][1].subSections)
        : rcName === 'OSHA Compliance'
        ? canSubmitOshaLog.indexOf(false) === -1
        : rcName === 'Witness Statements'
        ? this.canMarkWitnessStatementComplete(activeReport[0][1].statements)
        : false;
    if (showComplete && markAsComplete === undefined) {
      this.setState({ openMarkAsCompleteModal: true });
      return;
    }

    this.setState({ openMarkAsCompleteModal: false });

    if (markAsComplete) {
      activeReport[0][4].isComplete = true;
    }

    if (activeReport[0][0] === 'OSHA Compliance') {
      oshaQuestionnaire.map(async (form, index) => {
        const oshaAnswers = oshaDbMapper(form);
        const payload = {
          incidentId: activeIncident._id,
          companyId: activeIncident.companyId,
          locationId: activeIncident.locationId,
          projectId: activeIncident?.projectId,
          questions: oshaAnswers
        };
        if (form._id) {
          await this.props.updateOsha({
            ...oshaQuestionnaire[index],
            ...payload
          });
        } else {
          await this.props.createOsha(payload);
        }
      });
      activeReport[0][5].isStarted = true;
    }

    await this.props.updateReportComponent({
      stage: this.props.match.params.stage,
      dontLeavePage: dontLeave
    });

    this.props.socketDirty();
  };

  handleNaSection = async (value, subSectionIndex) => {
    const { activeReport } = this.state;
    this.props.socketLock();

    if (activeReport[0][0] === 'OSHA Compliance') {
      activeReport[0][6].subSections[subSectionIndex][3] = {
        notApplicable: value
      };
    } else {
      activeReport[0][1].subSections[subSectionIndex][3] = {
        notApplicable: value
      };
    }

    this.setState({ hasUnsavedChanges: true });
  };

  handleDiscard = async () => {
    this.props.fetchIncidentById(this.props.activeIncident);
    history.push(`/app/incidentContainer/${this.props.match.params.stage}`);
  };

  getLeftNavLabel = (activeReport, activeCompany) => {
    let rcName = this.getRCName(activeReport);
    const isOldWitness =
      (rcName === 'Witness Statements' &&
        this.props.activeIncident.isWitnessLegacy) ||
      rcName !== 'Witness Statements';

    return rcName !== 'OSHA Compliance' &&
      rcName !== 'Corrective Actions' &&
      isOldWitness
      ? this.getFirstSubsectionOf(activeReport)
      : this.isOshaType(activeReport, activeCompany)
      ? [{ label: 'OSHA Compliance' }]
      : this.isPERRPType(activeReport, activeCompany)
      ? [{ label: 'PERRP' }]
      : rcName === 'Corrective Actions'
      ? this.getCAs(activeReport)
      : rcName === 'Witness Statements'
      ? this.getWitnessStatements(activeReport)
      : [];
  };

  isPERRPType = (activeReport, activeCompany) =>
    this.getRC(activeReport)?.[0] === 'OSHA Compliance' &&
    activeCompany.reportingType === 2;

  isOshaType = (activeReport, activeCompany) =>
    this.getRC(activeReport)?.[0] === 'OSHA Compliance' &&
    activeCompany.reportingType === 1;

  getFirstSubsectionOf = activeReport =>
    activeReport?.[0]?.[1]?.subSections?.map(subsection => ({
      label: subsection[0],
      complete: isSectionComplete(subsection)
    }));

  getCAs = activeReport =>
    this.getRC(activeReport)?.[1]?.correctiveActions?.map(ca => ({
      label: ca.label,
      complete: ca?.task?.isComplete
    }));

  getWitnessStatements = activeReport => {
    return this.getRC(activeReport)?.[1]?.statements?.map((s, index) => ({
      label: this.getAssigneeName(s.assignee),
      complete: false,
      id: `wsCard${index}`
    }));
  };

  getRCName = activeReport => this.getRC(activeReport) && activeReport[0][0];

  getRC = activeReport => this.hasRC(activeReport) && activeReport[0];

  hasRC = activeReport => activeReport && activeReport.length > 0;

  getAssigneeName = assignee => {
    let createdBy = this.props.users.find(u => u._id === assignee);
    return createdBy
      ? `${createdBy.firstName} ${createdBy.lastName}`
      : 'Unknown User';
  };

  createWitnessStatements = async () => {
    const { additionalWitness, witnessDueDate } = this.state;
    const { activeIncident } = this.props;

    let wsBody = {
      assignees: additionalWitness,
      dueDate: witnessDueDate,
      incidentId: activeIncident._id,
      companyId: activeIncident.companyId,
      groupId: activeIncident.groupId,
      projectId:
        activeIncident.projectId === '' ? null : activeIncident.projectId,
      incidentDateTime: activeIncident.dateIncidentOccured
    };

    let updatedIncident = await createWitnessStatement(wsBody);

    const reportComponent = updatedIncident.reportComponents.find(
      rc => rc[0] === 'Witness Statements'
    );
    await this.props.setActiveReportComponent({
      report: [reportComponent],
      stage: 'investigation'
    });
    this.setState({
      openAddWitnessModal: false,
      additionalWitness: [],
      witnessDueDate: null
    });
  };

  canMarkWitnessStatementComplete = statements => {
    return statements.every(s => s.isComplete || s.isNa);
  };

  handleWsNa = (values, index) => {
    let { activeReport } = this.state;
    const statements = [...activeReport[0][1]?.statements];
    statements[index].isNa = values;
    activeReport[0][1].statements = [...statements];
    this.setState({
      activeReport: [...activeReport],
      hasUnsavedChanges: true
    });
  };

  handleWsDueDate = (values, index) => {
    let { activeReport } = this.state;
    const statements = [...activeReport[0][1]?.statements];
    statements[index].dueDate = values;
    activeReport[0][1].statements = [...statements];
    this.setState({
      activeReport: [...activeReport],
      hasUnsavedChanges: true
    });
  };

  render() {
    const {
      loggedInUser,
      activeIncident,
      setActiveTask,
      activeCompany,
      incidentLoading,
      attachmentLoading
    } = this.props;

    const {
      openEmailAssigneeModal,
      openEmailStatementModal,
      openUnsavedChangesModal,
      openMarkAsCompleteModal,
      openDeleteIrrelevantReportModal,
      openUnlockAndReopenReportModal,
      emailMessage,
      emailAddress,
      activeReport,
      hasUnsavedChanges,
      oshaQuestionnaire,
      openAddWitnessModal,
      additionalWitness,
      currentWitnesses,
      witnessDueDate
    } = this.state;

    const leftNav = this.getLeftNavLabel(activeReport, activeCompany);
    const disabled = this.props.locked;

    const reportTask = activeIncident?.incidentActivity?.openTasks?.filter?.(
      task =>
        activeReport.length > 0 &&
        task.reportComponentName === activeReport[0][0]
    );
    const assignedUser = activeReport?.[0]?.[2]?.assignedTo;

    const footer = (
      <SaveCancelFooter
        saveButtonClick={() => this.handleSectionSubmit(undefined)}
        saveButtonDisabled={
          (!hasUnsavedChanges &&
            this.getRCName(activeReport) !== 'OSHA Compliance') ||
          attachmentLoading
        }
        cancelButtonClick={this.cancelAction}
        editing={true}
      />
    );

    const headerCenter = (
      <ViewOnly
        canUnlock={
          activeReport[0]?.[4]?.isComplete &&
          (loggedInUser?.accessLevel === 900 ||
            activeIncident.incidentOwner === loggedInUser?._id)
        }
        incident={activeIncident}
        loggedInUser={loggedInUser}
        onClick={() => this.setState({ openUnlockAndReopenReportModal: true })}
        activeReport={this.props.activeReport}
      />
    );

    const right =
      this.getRC(activeReport)?.[2]?.assignedTo !== 'unassigned' ? (
        <Assignee
          user={assignedUser}
          options={[
            {
              label: 'Email Assignee',
              onClick: () =>
                this.setState({
                  openEmailAssigneeModal: true
                }),
              visible: true
            },
            {
              label: 'Edit Task',
              visible: activeIncident?.incidentOwner === loggedInUser._id,
              onClick: () =>
                setActiveTask({
                  ...reportTask[0],
                  stage: this.props.match.params.stage
                })
            }
          ]}
        />
      ) : (
        <></>
      );

    const showMarkAsComplete =
      (!isComplete(activeReport) &&
        this.getRCName(activeReport) === 'Corrective Actions') ||
      (!isComplete(activeReport) &&
        this.getRCName(activeReport) === 'Witness Statements' &&
        !activeIncident.isWitnessLegacy &&
        (this.getRC(activeReport)?.[1]?.statements?.length === 0 ||
          this.canMarkWitnessStatementComplete(
            this.getRC(activeReport)?.[1]?.statements
          )));

    const header = (
      <Header
        title={activeIncident && activeIncident.incidentNumber}
        section={
          this.getRC(activeReport)?.[0] === 'OSHA Compliance' &&
          activeCompany.reportingType === 2
            ? 'PERRP'
            : activeReport && activeReport.length > 0 && activeReport[0][0]
        }
        needsSaved={hasUnsavedChanges}
        clickBack={() =>
          hasUnsavedChanges
            ? this.setState({ openUnsavedChangesModal: true })
            : this.handleDiscard()
        }
        center={headerCenter}
        right={right}
        rightButtons={[
          {
            visible:
              activeIncident &&
              activeIncident.isWitnessLegacy &&
              activeIncident.incidentOwner === loggedInUser._id &&
              this.getRC(activeReport)?.[2]?.assignedTo === 'unassigned' &&
              !isComplete(activeReport) &&
              this.getRCName(activeReport) !== 'Corrective Actions',
            text: 'Assign Report as Task',
            color: 'blue',
            onClick: () =>
              setActiveTask({
                ownerId: activeIncident._id,
                ownerType: 'incident',
                reportComponentName: activeReport[0][0],
                label: `${activeIncident.incidentNumber}, ${activeReport[0][0]}`,
                groupId: activeIncident.groupId,
                currentCategory: [6, 16],
                stage: this.props.match.params.stage
              })
          },
          {
            visible: showMarkAsComplete,
            text: 'Mark Report as Complete',
            color: 'blue',
            onClick: () => this.setState({ openMarkAsCompleteModal: true })
          },
          {
            visible:
              this.getRCName(activeReport) === 'Witness Statements' &&
              !activeIncident.isWitnessLegacy &&
              !isComplete(activeReport),
            text: 'Add Witness',
            color: 'blue',
            onClick: () => this.setState({ openAddWitnessModal: true })
          }
        ]}
      />
    );

    const left = <LeftNav items={leftNav} />;
    return (
      <>
        {incidentLoading && (
          <ReactLoading
            type="spinningBubbles"
            color="#2f8738"
            className="loading"
          />
        )}
        <HeaderAndFooter
          Header={header}
          Footer={footer}
          Left={left}
          lockInfo={this.props.lockInfo}
          showFooter={!isComplete(activeReport)}
        >
          {this.getRC(activeReport)?.[1]?.statements?.length === 0 ? (
            <div className="reportSectionContainer-correctiveActionText">
              No Witnesses were selected in the Initial Report. Click the Add
              Witness Button above to assign Statements.
            </div>
          ) : this.getRC(activeReport)?.[0] === 'Witness Statements' &&
            !activeIncident.isWitnessLegacy ? (
            activeReport[0]?.[1]?.statements
              ?.sort((x, y) => x.isComplete - y.isComplete)
              ?.map((s, index) => (
                <WitnessStatementCard
                  statementId={s.id}
                  index={index}
                  assigneeName={this.getAssigneeName(s.assignee)}
                  handleWsNa={value => {
                    this.handleWsNa(value, index);
                  }}
                  handleWsDueDate={value => {
                    this.handleWsDueDate(value, index);
                  }}
                />
              ))
          ) : this.getRC(activeReport)?.[0] !== 'OSHA Compliance' &&
            (this.getRC(activeReport)?.[0] !== 'Corrective Actions' ||
              activeIncident.legacy) ? (
            activeReport[0]?.[1]?.subSections?.map((subsection, index) => (
              <ReportSection
                name={index}
                reportSectionHeader={subsection[0]}
                fields={subsection[1]}
                key={index}
                answerQuestion={fields => this.answerQuestion(fields, index)}
                disabled={
                  this.getRC(activeReport)?.[4]?.isComplete ||
                  !hasAccess(
                    loggedInUser,
                    this.props.activeReport.stage,
                    activeIncident
                  ) ||
                  disabled
                }
                canSectionNotApplicable={
                  subsection[2] && subsection[2].showNotApplicable
                }
                sectionNotApplicable={
                  subsection[3] && subsection[3].notApplicable
                }
                handleNaSection={value => this.handleNaSection(value, index)}
                incidentId={activeIncident._id}
                groupId={activeIncident.groupId}
              />
            ))
          ) : this.getRC(activeReport)?.[0] === 'Corrective Actions' &&
            this.getRC(activeReport)?.[1]?.correctiveActions.length > 0 ? (
            this.getRC(activeReport)?.[1]?.correctiveActions.map(
              (ca, index) => (
                <Card
                  name={index}
                  title={ca.label}
                  wide
                  showHeader
                  key={index}
                  testID={`caCard${index}`}
                >
                  <Checkbox
                    fieldLabel="Immediately Corrected"
                    currentValue={ca.immediatelyFixed}
                    disabled={
                      this.getRC(activeReport)?.[4]?.isComplete ||
                      !hasAccess(
                        loggedInUser,
                        this.props.activeReport.stage,
                        activeIncident
                      ) ||
                      disabled
                    }
                    onChange={values =>
                      this.handleImmediatelyFixed(values, index)
                    }
                  />
                  <AssignCorrectiveActionButton
                    groupId={activeIncident.groupId}
                    task={ca?.task}
                    ownerType="incident"
                    label={`${ca.label} - Corrective Action`}
                    schedule="immediate"
                    projectId={activeIncident.projectId}
                    immediatelyFixed={ca?.immediatelyFixed}
                    ownerId={activeIncident._id}
                    testID={`caButton${index}`}
                    disabled={
                      this.getRC(activeReport)?.[4]?.isComplete && !ca?.task
                    }
                    onClick={() => {
                      if (this.state.hasUnsavedChanges)
                        this.handleSectionSubmit(false, true);
                    }}
                  />
                </Card>
              )
            )
          ) : this.getRC(activeReport)?.[0] === 'Corrective Actions' &&
            this.getRC(activeReport)?.[1]?.correctiveActions?.length === 0 ? (
            <div className="reportSectionContainer-correctiveActionText">
              Complete the Root Cause to populate Corrective Actions
            </div>
          ) : (
            <div>
              {activeReport &&
                activeReport.length > 0 &&
                Array.apply(
                  null,
                  Array(activeReport[0][6].minSections)
                ).map((x, i) => (
                  <OshaComplianceCard
                    answerOsha={values => this.answerOsha(values, i)}
                    oshaForm={oshaQuestionnaire[i]}
                    key={i}
                    name={i}
                    disabled={
                      (activeReport.length > 0 &&
                        activeReport[0][4].isComplete) ||
                      !hasAccess(
                        loggedInUser,
                        this.props.activeReport.stage,
                        activeIncident
                      )
                    }
                  />
                ))}
            </div>
          )}
        </HeaderAndFooter>
        <Modal
          title="Email Assignee"
          titleClassName="blueHeader"
          isOpen={openEmailAssigneeModal}
          submitButtonColor="blue"
          submitButtonText="Send Email"
          onRequestClose={() =>
            this.setState({ openEmailAssigneeModal: false })
          }
          submitActions={() => this.sendEmail()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            This email will go through the iReportSource system for record
            keeping purposes.
          </div>
          <Textbox
            fieldLabel="Email Content"
            rows={5}
            type="textarea"
            placeholder="Type email content here"
            currentValue={emailMessage}
            onChange={v => this.setState({ emailMessage: v })}
          />
        </Modal>
        <Modal
          title="Email Statement"
          titleClassName="blueHeader"
          isOpen={openEmailStatementModal}
          submitButtonColor="blue"
          submitButtonText="Send Email"
          onRequestClose={() =>
            this.setState({ openEmailStatementModal: false })
          }
          submitActions={() => this.sendEmail()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            Sending out this statement as an email will allow the recipient to
            respond with their answers, and populate the fields within the
            report.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Resending this statement will invalidate the email in which this
              statement was previously sent.
            </span>{' '}
            Sending this email will not invalidate different statements that
            have been sent.
          </div>
          <Textbox
            fieldLabel="Recipient's Email Address"
            placeholder="Enter only one email address."
            currentValue={emailAddress}
            onChange={v => this.setState({ emailAddress: v })}
          />
        </Modal>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() =>
            this.setState({ openUnsavedChangesModal: false })
          }
          submitActions={() => this.handleDiscard()}
          savingWhat="a report"
        />
        <MarkAsCompleteModal
          isOpen={openMarkAsCompleteModal}
          onRequestClose={() =>
            this.setState({ openMarkAsCompleteModal: false })
          }
          submitActions={() => this.handleSectionSubmit(true)}
          cancelActions={() => this.handleSectionSubmit(false)}
        />
        <Modal
          title="Delete Irrelevant Report"
          titleClassName="redHeader"
          isOpen={openDeleteIrrelevantReportModal}
          submitButtonColor="red"
          submitButtonText="Delete Report"
          onRequestClose={() =>
            this.setState({ openDeleteIrrelevantReportModal: false })
          }
          submitActions={() => this.sendEmail()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            This report is no longer relevant due to the incident being
            recategorized, and as such it may not be unlocked and edited
            further.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              This report wasn’t deleted during recategorization due to it
              containing data. Make sure you have reviewed (and migrated if
              necessary) all the information in this report, as deleting this
              report will get rid of it forever.{' '}
            </span>
          </div>
        </Modal>
        <Modal
          title="Unlock and Re-Open Report"
          titleClassName="blueHeader"
          isOpen={openUnlockAndReopenReportModal}
          submitButtonColor="blue"
          submitButtonText="Re-open Report"
          onRequestClose={() =>
            this.setState({ openUnlockAndReopenReportModal: false })
          }
          submitActions={() => this.reopenReportSection()}
        >
          <div className="reportSectionContainer-emailAssigneeText">
            Unlocking this report will allow you –and users with edit
            permission– to make changes and additions to the report.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              This also means that the report will no longer be considered
              completed until it is filled out, saved, and marked as complete
              once more. Re-Opening this report will also remove the currently
              assigned user.
            </span>{' '}
            You can reassign this report to the same or a different user as a
            new task.
          </div>
        </Modal>
        <Modal
          title="Add Witnesses"
          titleClassName="blueHeader"
          isOpen={openAddWitnessModal}
          submitButtonColor="blue"
          submitButtonText="Submit"
          onRequestClose={() =>
            this.setState({
              openAddWitnessModal: false,
              additionalWitness: [],
              witnessDueDate: null
            })
          }
          submitActions={this.createWitnessStatements}
          disableSubmit={
            additionalWitness.length < 1 ||
            isFutureDate(witnessDueDate) === 'Must be a Future Date'
          }
          tall
          wide
          testID="addWitnessModal"
        >
          <EmployeeDropdown
            fieldLabel="Additional Witnesses"
            currentValue={additionalWitness}
            onChange={users => this.setState({ additionalWitness: users })}
            searchable
            name="dropdownEmployees"
            isRequired
            group={activeIncident.groupId}
            multi
            exclude={currentWitnesses}
            permissions={[100, 400, 500, 900]}
          />
          <DatePicker
            pickTime
            fieldLabel="Due Date"
            isRequired
            onChange={value => this.setState({ witnessDueDate: value })}
            currentValue={witnessDueDate}
            name="witnessDueDate"
            warning={
              isFutureDate(witnessDueDate) ? 'Must be a Future Date' : null
            }
            touched={witnessDueDate}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: getLoggedInUser(state),
  activeReport: getActiveReportsSelector(state),
  activeIncident: getActiveIncidentSelector(state),
  oshaForm: getActiveOshaLogSelector(state),
  activeCompany: getActiveCompany(state),
  incidentLoading: getIncidentsLoadingSelector(state),
  attachmentLoading: getAttachmentLoadingSelector(state),
  users: getAllUsers(state)
});

const mapDispatchToProps = dispatch => ({
  emailIncidentAssignee: payload =>
    dispatch(emailIncidentAssigneeRequest(payload)),
  updateReportComponent: payload =>
    dispatch(updateReportComponentRequest(payload)),
  createOsha: payload => dispatch(createOshaFormRequest(payload)),
  updateOsha: payload => dispatch(updateOshaFormRequest(payload)),
  fetchIncidentById: payload => dispatch(fetchIncidentByIdRequest(payload)),
  setActiveTask: payload => dispatch(setActiveTaskRequest(payload)),
  setActiveReportComponent: payload => dispatch(createReportResponse(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSocket(ReportSectionContainer));
