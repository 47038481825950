import React from 'react';
import { connect } from 'react-redux';

import Button from '../inputs/Button';
import { InputRow } from '../inputs';
import classnames from 'classnames/bind';
import { getCards } from '../../api/v4';
import { getActiveLocationId, getActiveProject } from '../../selectors/company';
import Loading from '../Loading';
import ToDoCard from './ToDoCard';
import IncidentCard from './IncidentCard';
import ItemGraphCard from './ItemGraphCard';

import styles from './headerCards.module.scss';
const bStyles = classnames.bind(styles);

export const HeaderCards = ({ location, className, locationId, projectId }) => {
  const [selected, setSelected] = React.useState(undefined);
  const [cards, setCards] = React.useState(undefined);
  const card = cards && selected !== undefined ? cards[selected] : undefined;

  React.useEffect(() => {
    setCards(undefined);
    getCards().then(data => {
      setCards(data);
      setSelected(0);
    });
  }, [location, locationId, projectId]);

  if (cards === undefined) {
    return <Loading />;
  }

  return (
    <div className={classnames(styles.container, className)}>
      {card?.type === 'todo' ? (
        <ToDoCard card={card} key={card?.name} right />
      ) : card?.type === 'savedDrafts' ? (
        <ToDoCard card={card} key={card?.name} />
      ) : card?.type === 'assignments' ? (
        <ToDoCard card={card} key={card?.name} reminders right />
      ) : card?.type === 'incidents' ? (
        <IncidentCard card={card} key={card?.name} />
      ) : card?.type === 'reportType' ? (
        <ItemGraphCard card={card} key={card?.name} />
      ) : (
        <></>
      )}
      <InputRow className={styles.buttons} center>
        {cards.map((card, index) => (
          <Button
            className={bStyles({
              button: true,
              selected: selected === index
            })}
            key={index}
            onClick={() => setSelected(index)}
            text={card.name}
            color="white"
          />
        ))}
      </InputRow>
    </div>
  );
};
const mapStateToProps = state => ({
  locationId: getActiveLocationId(state)?._id,
  projectId: getActiveProject(state)?._id
});

export default connect(mapStateToProps)(HeaderCards);
