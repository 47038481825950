import { connect } from 'react-redux';
import React, { Component } from 'react';

import history from '../../history';

import {
  addProjectRequest,
  deleteProjectRequest,
  setActiveLocation,
  setActiveProject,
  updateProjectRequest
} from '../../actions/company';
import { getActiveCompany } from '../../selectors/company';

import Dropdown from '../inputs/Dropdown';
import Modal from '../Modal';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import Textbox from '../inputs/Textbox';
import ViewEditList from '../ViewEditList';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import RadioButtons from '../inputs/RadioButtons';
import { InputRow } from '../inputs';
import { stateAbbr } from '../../constants/states';
import yearToDateDropdown from '../../utils/yeartoDateDropdown';

export class ProjectList extends Component {
  state = {
    modalOpen: 0,
    projectName: '',
    assignedToGroup: '',
    row: {},
    isSeparateProject: null,
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
    hoursWorked: null,
    year: new Date().getFullYear()
  };

  handleOpenModal = (modalId, row) => {
    this.setState({
      modalOpen: modalId,
      projectName: row?.name,
      assignedToGroup: row?.groupId,
      row: row,
      streetAddress: row?.streetAddress,
      city: row?.city,
      state: row?.state,
      zip: row?.zip,
      isSeparateProject: row?.isSeparateEstablishment,
      hoursWorked: row?.hoursWorked
    });
  };

  handleCloseModal = () => {
    this.setState({ modalOpen: 0 });
  };

  getGroups = () => {
    return (
      this.props.activeCompany.groups &&
      this.props.activeCompany.groups.map(group => {
        return {
          value: group._id,
          label: group.name
        };
      })
    );
  };

  handleAddProject = () => {
    const {
      projectName,
      assignedToGroup,
      isSeparateProject,
      streetAddress,
      city,
      zip,
      state,
      hoursWorked
    } = this.state;
    const { addProject, activeCompany } = this.props;

    addProject({
      companyId: activeCompany._id,
      groupId: assignedToGroup,
      name: projectName,
      streetAddress,
      city,
      state,
      zip,
      isSeparateEstablishment: isSeparateProject ?? false,
      hoursWorked
    });

    this.handleCloseModal();
  };

  handleUpdateProject = () => {
    const {
      projectName,
      assignedToGroup,
      row,
      isSeparateProject,
      streetAddress,
      city,
      zip,
      state,
      hoursWorked
    } = this.state;
    const { updateProject } = this.props;

    updateProject({
      ...row,
      groupId: assignedToGroup,
      name: projectName,
      streetAddress,
      city,
      state,
      zip,
      isSeparateEstablishment: isSeparateProject,
      hoursWorked
    });

    this.handleCloseModal();
  };

  handleHideProject = () => {
    const { row } = this.state;
    const { updateProject } = this.props;

    updateProject({
      ...row,
      isHidden: !row.isHidden
    });

    this.handleCloseModal();
  };

  deleteProject = id => {
    const { project } = this.findGroupAndProject(id);
    const { deleteProject } = this.props;

    deleteProject({
      ...project
    });
  };

  handleChange = stateObject => {
    this.setState(stateObject);
  };

  findGroupAndProject(projectId) {
    let project, group;
    this.props.activeCompany.groups.forEach(g => {
      let p = g.projects.find(p => p._id === projectId);
      if (p) {
        project = p;
        group = g;
      }
    });

    return { project, group };
  }

  canSubmit() {
    const {
      isSeparateProject,
      streetAddress,
      city,
      state,
      zip,
      projectName,
      assignedToGroup
    } = this.state;
    let validName =
      projectName && (projectName?.match(/[A-Za-z]/g) || []).length > 1;
    let group = this.props.activeCompany?.groups?.find(
      g => g._id === assignedToGroup
    );
    const isGroupSeparate = assignedToGroup
      ? group?.isSeparateEstablishment
      : false;
    if (isSeparateProject) {
      const zipRegex = /^[0-9]{5}$/g;
      const validZip = zip?.match(zipRegex);
      const validHours = this.checkForValidProjectHours();
      return (
        validZip &&
        streetAddress &&
        city &&
        zip &&
        state &&
        validName &&
        assignedToGroup &&
        validHours
      );
    } else if (!isGroupSeparate) {
      return validName && assignedToGroup;
    }
    return validName && assignedToGroup && isSeparateProject === false;
  }

  checkForValidProjectHours() {
    const { assignedToGroup, hoursWorked, year } = this.state;
    let groupHours =
      this.props.activeCompany?.groups.find(g => g._id === assignedToGroup)
        ?.hoursWorked?.[year] ?? [];
    if (hoursWorked) {
      let sum = groupHours?.length > 0 ? groupHours.reduce((x, y) => x + y) : 0;
      let validHours = sum >= hoursWorked?.[year];
      return validHours;
    }
    return true;
  }

  renderProjectCard() {
    const {
      isSeparateProject,
      streetAddress,
      city,
      state,
      zip,
      projectName,
      assignedToGroup,
      hoursWorked,
      year,
      modalOpen
    } = this.state;
    const perrpOrDash =
      this.props.activeCompany.reportingType === 2 ? 'PERRP' : 'OSHA';
    const group = this.props.activeCompany?.groups?.find(
      g => g._id === assignedToGroup
    );
    const isGroupSeparate = assignedToGroup
      ? group?.isSeparateEstablishment
      : false;

    return (
      <div>
        <Dropdown
          fieldLabel="Group to Add Area "
          isRequired
          options={this.getGroups()}
          placeholder="Select a group"
          currentValue={assignedToGroup}
          onChange={value => this.handleChange({ assignedToGroup: value })}
          disabled={modalOpen === 2}
        />
        <Textbox
          currentValue={projectName}
          fieldLabel="Area Name"
          onChange={v => this.handleChange({ projectName: v })}
          isRequired={true}
          placeholder="e.g Hotel Area"
        />
        {isGroupSeparate && (
          <RadioButtons
            fieldLabel={`Does this require its own ${perrpOrDash} Report?`}
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' }
            ]}
            isRequired={true}
            onChange={v => this.handleChange({ isSeparateProject: v })}
            currentValue={isSeparateProject}
            bareValues={true}
            disabled={!isGroupSeparate}
          />
        )}
        {isSeparateProject && (
          <div style={{ display: 'block' }}>
            <Textbox
              currentValue={streetAddress}
              fieldLabel="Street Address "
              onChange={v => this.handleChange({ streetAddress: v })}
              isRequired={true}
              placeholder="e.g. Mall Area"
            />
            <InputRow>
              <Textbox
                currentValue={city}
                fieldLabel="City "
                onChange={v => this.handleChange({ city: v })}
                isRequired={true}
                placeholder="City"
              />
              <Dropdown
                fieldLabel="State "
                isRequired
                options={stateAbbr}
                placeholder="State"
                currentValue={state}
                onChange={v => this.handleChange({ state: v })}
              />
              <Textbox
                currentValue={zip}
                fieldLabel="Zip"
                onChange={v => this.handleChange({ zip: v })}
                isRequired={true}
                placeholder="Zip"
              />
            </InputRow>
            <InputRow>
              <Dropdown
                fieldLabel="Year "
                isRequired
                options={yearToDateDropdown()}
                placeholder="Year"
                currentValue={year}
                onChange={year => this.handleChange({ year })}
              />
              <Textbox
                currentValue={hoursWorked?.[year] ?? ''}
                fieldLabel="Total Hours Worked"
                onChange={hours => {
                  let updated = { ...hoursWorked, [year]: hours };
                  this.handleChange({ hoursWorked: updated });
                }}
                placeholder="0"
                warning={
                  this.checkForValidProjectHours()
                    ? null
                    : `Hours must be less than ${group.name}'s hours`
                }
                touched={true}
              />
            </InputRow>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { activeCompany, setActiveLocation, setActiveProject } = this.props;
    const { modalOpen, projectName, row } = this.state;

    const header = (
      <Header
        title="Areas"
        rightButtons={{
          text: 'New Area',
          color: 'green',
          onClick: () => this.handleOpenModal(1)
        }}
      />
    );

    return (
      <>
        <HeaderAndFooter Header={header} showFooter={false}>
          <ScrollToTopOnMount />
          <ViewEditList
            data={activeCompany?.groups}
            subCat="projects"
            onView={id => {
              let { group, project } = this.findGroupAndProject(id);
              setActiveLocation(group);
              setActiveProject(project);
              history.push('/app/dashboard');
            }}
            onHide={id => {
              let { project } = this.findGroupAndProject(id);
              this.handleOpenModal(3, project);
            }}
            onEdit={id => {
              let { project } = this.findGroupAndProject(id);
              this.handleOpenModal(2, project);
            }}
            onDelete={this.deleteProject}
          />
        </HeaderAndFooter>
        {modalOpen === 1 && (
          <Modal
            title="Add New Area"
            titleClassName="greenHeader"
            isOpen={modalOpen === 1}
            submitButtonColor="green"
            onRequestClose={this.handleCloseModal}
            submitActions={this.handleAddProject}
            disableSubmit={!this.canSubmit()}
            testID="project"
          >
            {this.renderProjectCard()}
          </Modal>
        )}
        <Modal
          title="Edit Area"
          titleClassName="blueHeader"
          isOpen={modalOpen === 2}
          submitButtonColor="blue"
          onRequestClose={this.handleCloseModal}
          submitActions={this.handleUpdateProject}
          disableSubmit={!this.canSubmit()}
          testID="updateProject"
        >
          {this.renderProjectCard()}
        </Modal>
        <Modal
          title={row?.isHidden ? 'Show Area' : 'Hide Area'}
          titleClassName="blueHeader"
          isOpen={modalOpen === 3}
          submitButtonColor="blue"
          onRequestClose={() => this.handleCloseModal()}
          submitActions={() => this.handleHideProject()}
        >
          <h2>
            Are you sure you want to {row?.isHidden ? 'show ' : 'hide '}
            {projectName}?
          </h2>
          {row?.isHidden ? (
            <h3>
              Showing an area means that it will show up on the home page and
              will still show on this page.
            </h3>
          ) : (
            <h3>
              Hiding an area means that it won't show up on the home page but
              will still show on this page.
            </h3>
          )}
        </Modal>
      </>
    );
  }
}

export const mapStateToProps = state => ({
  activeCompany: getActiveCompany(state)
});

export const mapDispatchToProps = dispatch => ({
  setActiveLocation: locationId => dispatch(setActiveLocation(locationId)),
  setActiveProject: values => dispatch(setActiveProject(values)),
  addProject: values => dispatch(addProjectRequest(values)),
  updateProject: values => dispatch(updateProjectRequest(values)),
  deleteProject: values => dispatch(deleteProjectRequest(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
