import React from 'react';
import './svg.css';

export default props => (
  <svg
    version="1.1"
    x={props.x || "0px"}
    y={props.y || "0px"}
    width="30px"
    height="25px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
  >
    <g>
      <g>
        <g>
          <rect x="9.818" y="86.604" width="86.727" height="7.92" />
          <g>
            <rect x="55.94" y="34.306" width="8.58" height="9.416" />
            <rect x="73.716" y="34.306" width="8.582" height="9.416" />
          </g>
          <g>
            <rect x="19.097" y="14.96" width="7.65" height="9.419" />
            <rect x="34.941" y="14.96" width="7.651" height="9.419" />
          </g>
          <path d="M46.781,45.56V26.219H14.908v58.546h76.549V45.56H46.781z M28.036,75.362h-6.983V68.38h6.983V75.362z      M28.036,59.901h-6.983v-6.982h6.983V59.901z M28.036,44.44h-6.983v-6.979h6.983V44.44z M40.635,75.362h-6.98V68.38h6.98V75.362z      M40.635,59.901h-6.98v-6.982h6.98V59.901z M40.635,44.44h-6.98v-6.979h6.98V44.44z M59.518,75.412h-7.085v-7.084h7.085V75.412z      M59.518,59.952h-7.085v-7.085h7.085V59.952z M72.659,75.412h-7.08v-7.084h7.08V75.412z M72.659,59.952h-7.08v-7.085h7.08V59.952     z M85.805,75.412h-7.083v-7.084h7.083V75.412z M85.805,59.952h-7.083v-7.085h7.083V59.952z" />
        </g>
      </g>
    </g>
  </svg>
);
