import React from 'react';
import CheckboxGroup from '../inputs/CheckboxGroup';

const OshaLogDownload = ({
  currentValue,
  canPrint300ACSV,
  canPrint301,
  missingNoInformation,
  isShortTermEstablishment,
  aggrates,
  divisonEstInfo,
  onChange,
  activeProject
}) => {
  let canPrint300A = false;
  if (
    aggrates.annualAverageNumberOfWorkers &&
    aggrates.totalHoursWorkedByAllEmployees &&
    aggrates.maximumNumberOfWorkersEmployed &&
    divisonEstInfo.name &&
    divisonEstInfo.mainAddress.streetAddress &&
    divisonEstInfo.mainAddress.city &&
    divisonEstInfo.mainAddress.stateName &&
    divisonEstInfo.mainAddress.zip &&
    divisonEstInfo.NAICS
  ) {
    canPrint300A = true;
  }

  const projectDisable = activeProject
    ? isShortTermEstablishment || !activeProject.isSeparateEstablishment
    : false;
  const options =
    isShortTermEstablishment ||
    divisonEstInfo.reportingType === 2 ||
    projectDisable
      ? [
          {
            value: 0,
            label:
              divisonEstInfo.reportingType === 2
                ? 'PERRP’s Form 300. Log of Work Related Injuries and Illnesses'
                : 'OSHA’s Form 300. Log of Work Related Injuries and Illnesses',
            disabled: isShortTermEstablishment || projectDisable
          },
          {
            value: 1,
            label:
              divisonEstInfo.reportingType === 2
                ? 'PERRP’s Form 300A. Summary of Work-Related Injuries and Illnesses'
                : 'OSHA’s Form 300A. Summary of Work-Related Injuries and Illnesses',
            disabled: isShortTermEstablishment || projectDisable
          },
          {
            value: 2,
            label:
              divisonEstInfo.reportingType === 2
                ? 'PERRP’s Form 301. Injury and Illness Incident Report(s)'
                : 'OSHA’s Form 301. Injury and Illness Incident Report(s)',
            disabled: !canPrint301
          }
        ]
      : [
          {
            value: 0,
            label:
              'OSHA’s Form 300. Log of Work Related Injuries and Illnesses',
            disabled: false
          },
          {
            value: 1,
            label:
              'OSHA’s Form 300A. Summary of Work-Related Injuries and Illnesses',
            disabled: false
          },
          {
            value: 2,
            label: 'OSHA’s Form 301. Injury and Illness Incident Report(s)',
            disabled: !canPrint301
          },
          {
            value: 3,
            label:
              'Injury Tracking Application Formatted CSV. www.osha.gov/injuryreporting/index.html',
            disabled: !canPrint300ACSV || !canPrint300A
          }
        ];

  return (
    <div>
      <CheckboxGroup
        options={options}
        name="oshaFormTypes"
        onChange={onChange}
        currentValue={currentValue}
      />
      {(!canPrint300ACSV || !canPrint300A) &&
        divisonEstInfo.reportingType !== 2 && (
          <p style={{ color: '#ff0000', fontWeight: 'bold' }}>
            ITA Formatted CSV cannot be created from incomplete forms.
          </p>
        )}
      {!canPrint301 && (
        <p style={{ color: '#ff0000', fontWeight: 'bold' }}>
          {`No recorded cases to print as ${
            divisonEstInfo.reportingType === 2 ? "PERRP's" : 'OSHA’s'
          } Form 301.`}
        </p>
      )}{' '}
      {!missingNoInformation && currentValue.length > 0 && (
        <p style={{ color: '#ff0000', fontWeight: 'bold' }}>
          {`One or more files are missing information required by ${
            divisonEstInfo.reportingType === 2 ? 'PERRP' : 'OSHA'
          }. Are you
            sure you want to download?`}
        </p>
      )}
      {projectDisable && !isShortTermEstablishment ? (
        <p style={{ color: '#ff0000', fontWeight: 'bold' }}>
          {`${
            divisonEstInfo.reportingType === 2 ? "PERRP's" : "OSHA's"
          } Form 300 and 300A for this project
            are included in the Group: ${divisonEstInfo.locationName}`}
        </p>
      ) : activeProject && isShortTermEstablishment ? (
        <p style={{ color: '#ff0000', fontWeight: 'bold' }}>
          {`${
            divisonEstInfo.reportingType === 2 ? "PERRP's" : "OSHA's"
          } Form 300 and 300A for this project
            is included in the Company Division.`}
        </p>
      ) : isShortTermEstablishment ? (
        <p style={{ color: '#ff0000', fontWeight: 'bold' }}>
          {`${
            divisonEstInfo.reportingType === 2 ? "PERRP's" : "OSHA's"
          } Form 300 and 300A for this group or short term establishment
            is included in the Company Division.`}
        </p>
      ) : null}
      <p style={{ color: '#ff0000', fontWeight: 'bold' }}>
        Strikethrough will not be displayed in the downloaded 300
      </p>
    </div>
  );
};

export default OshaLogDownload;
