import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import history from '../../../history';
import {
  fetchBehaviorObservationTemplates,
  fetchBehaviorObservationTemplateById,
  createBehaviorObservationTemplate,
  updateBehaviorObservationTemplate,
  deleteBehaviorObservationTemplate
} from '../../../api/v4';
import BehaviorObservationCard from '../../../components/behaviorObservations/TemplateCard';
import DeleteTemplateModal from '../../../components/Modal/deleteTemplateModal';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';

export default function BehaviorObservationTemplate() {
  let { templateId, duplicate } = useParams();
  const [behaviorObservation, setBehaviorObservation] = useState({});
  const [templates, setTemplates] = useState([]);
  const [id, setId] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [editing, setEditing] = useState(false);
  const [creating, setCreating] = useState(false);
  const [openSaveChangesModal, setOpenSaveChangesModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  useEffect(() => {
    fetchBehaviorObservationTemplates().then(setTemplates);

    if (templateId) {
      fetchBehaviorObservationTemplateById(templateId).then(response => {
        let temp = { ...response };

        if (duplicate) {
          delete temp._id;
          temp.fields.forEach(f => delete f._id);
          temp.title = `${temp.title} - Copy`;
          setCreating(true);
        }

        setBehaviorObservation(temp);
      });
      setId(templateId);
    } else {
      setCreating(true);
    }
  }, [templateId, duplicate]);

  const canSubmit = () => {
    const valid = isTitleValid(behaviorObservation?.title);
    const questionsHaveAllParts = behaviorObservation?.fields?.filter(
      question =>
        !question.type ||
        !question.label ||
        (question.type &&
          ['dropdown', 'multiselect', 'radiobuttons', 'checkbox'].includes(
            question.type
          ) &&
          question.options.length === 0)
    );

    if (
      behaviorObservation?.fields?.length > 0 &&
      questionsHaveAllParts.length === 0 &&
      behaviorObservation?.title?.length &&
      valid
    ) {
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    if (id && !duplicate) {
      updateBehaviorObservationTemplate(behaviorObservation).then(response => {
        setBehaviorObservation(response);
        setEditing(false);
        setUnsavedChanges(false);
      });
    } else {
      createBehaviorObservationTemplate(behaviorObservation).then(response => {
        setBehaviorObservation(response);
        setCreating(false);
        setUnsavedChanges(false);
        setId(response._id);
      });
    }
  };

  const deleteTemplate = () => {
    deleteBehaviorObservationTemplate(behaviorObservation._id).then(() => {
      history.push('/app/behaviorObservation/templates');
    });
  };

  const isTitleValid = title => {
    let formattedTitle = title
      ?.split(' ')
      ?.filter(s => s)
      ?.join(' ');

    let valid = true;
    if (creating && formattedTitle?.length !== 0) {
      valid =
        templates.filter(
          t => t.title.toLowerCase() === formattedTitle?.toLowerCase()
        ).length === 0;
    } else if (editing) {
      let template = templates.filter(
        t => t.title.toLowerCase() === formattedTitle?.toLowerCase()
      )?.[0];
      if (template) {
        valid = template?._id === templateId;
      }
    }
    return valid;
  };

  const header = (
    <Header
      title="Custom Behavior Observation"
      section={
        !creating && !editing
          ? 'View Template'
          : creating
          ? 'Create Template'
          : 'Edit Template'
      }
      needsSaved={unsavedChanges}
      clickBack={() =>
        unsavedChanges ? setOpenSaveChangesModal(true) : history.goBack()
      }
      rightButtons={{
        text: editing ? 'Delete Template' : 'Edit Template',
        onClick: editing
          ? () => setOpenDeleteModal(true)
          : () => setEditing(true),
        visible: !creating,
        color: editing ? 'red' : 'blue'
      }}
    />
  );

  const footer = (
    <SaveCancelFooter
      editing={editing}
      saveButtonDisabled={!canSubmit()}
      saveButtonClick={handleSubmit}
      cancelButtonClick={() =>
        editing && unsavedChanges
          ? setOpenSaveChangesModal(true)
          : editing && !unsavedChanges
          ? setEditing(false)
          : setOpenSaveChangesModal(true)
      }
    />
  );

  return (
    <HeaderAndFooter
      Header={header}
      Footer={footer}
      showFooter={creating || editing}
    >
      <BehaviorObservationCard
        onChange={(values, e) => {
          if (values?.title) {
            e.target.style.background = isTitleValid(e.target.value)
              ? 'lightgreen'
              : 'lightcoral';
          }

          setBehaviorObservation({ ...behaviorObservation, ...values });
          setUnsavedChanges(true);
        }}
        disabled={!editing && !creating}
        editing={editing}
        duplicating={behaviorObservation.duplicated}
        observation={behaviorObservation}
      />
      <DeleteTemplateModal
        deletingWhat="Behavior Observation"
        isOpen={openDeleteModal}
        onRequestClose={() => setOpenDeleteModal(false)}
        submitActions={deleteTemplate}
      />
      <SaveChangesModal
        savingWhat="Behavior Observation"
        isOpen={openSaveChangesModal}
        onRequestClose={() => setOpenSaveChangesModal(false)}
        submitActions={() => history.goBack()}
      />
    </HeaderAndFooter>
  );
}
