import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import classnames from 'classnames/bind';

import { loadUserRequest } from '../../actions/user';
import { getLoadedUsers } from '../../selectors/users';
import Loading from '../Loading';

import styles from './userBadge.module.scss';
const bStyles = classnames.bind(styles);

export const UserBadge = ({
  userId,
  showName,
  showImage,
  nameUnder,
  byline,
  hoverName,
  size,
  onClick,
  square,
  right,
  plain,
  className,
  nameOnly,
  time,
  data
}) => {
  const users = useSelector(getLoadedUsers);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (userId) {
      dispatch(loadUserRequest(userId));
    }
  }, [userId, dispatch]);

  let image = require('../../assets/images/unselectedPortrait.png');
  let s = (size ?? 'small').toLowerCase();
  let user;

  if (userId) {
    user = users?.[userId];
    if (user?.error) {
      user = { firstName: 'Unknown', lastName: 'User' };
    } else {
      image =
        user?.profilePictureUrl ||
        require('../../assets/images/blank-profile-picture.jpg');
    }
  } else {
    user = { firstName: 'Unassigned', lastName: 'Owner' };
  }

  let fullName =
    user && !user?.loading
      ? `${user.firstName} ${user.lastName}`
      : 'Loading User';

  if (nameOnly) return fullName;

  let classes = {
    profileBadge: true,
    column: nameUnder,
    medium: s === 'medium',
    large: s === 'large',
    xLarge: s === 'x-large',
    xxLarge: s === 'xx-large',
    employeeSnapshot: s === 'employeesnapshot',
    square,
    right,
    plain
  };

  return (
    <div className={bStyles(className, classes)} onClick={onClick}>
      {showImage &&
        (user && !user?.loading ? (
          <img
            className={bStyles({
              image: true,
              space: showName && !nameUnder
            })}
            src={data || image}
            alt="profile pic"
            title={hoverName && `${user.firstName} ${user.lastName}`}
          />
        ) : (
          <Loading mode="auto" className={styles.loading} />
        ))}
      {showName && (
        <div className="name">
          {byline && <span className={styles.byline}>{byline}</span>}
          <span className={styles.username}>{fullName}</span>
          {time && (
            <span className={styles.time}>
              {moment(time).format('MM/DD/YYYY hh:mm A')}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default UserBadge;
