import React, { Component } from 'react';
import history from '../../history';
import Button from '../../components/inputs/Button';
import { DatePicker } from '../../components/inputs/DateTimePicker';
import { InputRow } from '../../components/inputs';
import Dropdown from '../../components/inputs/Dropdown';
import FileDownloader from '../../components/FileDownloader';
import FileUploader from '../../components/FileUploader';
import SdsButtons from '../../components/inputs/SdsButtons';
import Textbox from '../../components/inputs/Textbox';

class SdsForm extends Component {
  state = {
    label: '',
    expires: '',
    ghsLabels: [],
    activeAttachments: [],
    groupIds: [],
    projectIds: [],
    documentFolderId: '',
    _id: 0,
    touched: {
      label: false,
      expires: false
    }
  };

  componentDidMount() {
    const { sds } = this.props;
    sds._id &&
      this.setState({
        label: sds.label,
        expires: sds.expires,
        ghsLabels:
          sds.documentData && sds.documentData.ghsLabels
            ? sds.documentData.ghsLabels
            : [],
        _id: sds._id,
        groupIds: sds.groupIds,
        projectIds: sds.projectIds,
        documentFolderId: sds.documentFolderId
      });
  }

  updateState(stateObject) {
    this.setState(stateObject);
    this.props.handleSdsSelection({ ...this.state, ...stateObject });
    if (this.props.onDirty) this.props.onDirty();
  }

  handleBlur = field => evt => {
    this.setState({
      touched: { ...this.state.touched, [field]: true }
    });
  };

  handleCancel = () => {
    this.props.onCancel();
    history.push('/app/documents/sds');
  };

  render() {
    const {
      label,
      expires,
      ghsLabels,
      groupIds,
      projectIds,
      documentFolderId
    } = this.state;

    const {
      onDelete,
      isUpdate,
      submitForm,
      activeCompanyId,
      addAttachment,
      activeAttachments,
      deleteSDSAttachment,
      loading,
      accessLevel,
      activeLocation,
      locationList,
      activeProject,
      addedAttachments,
      deleteAttachment,
      documentFolders
    } = this.props;

    const hasAccess = accessLevel !== 100;

    const folderDropdown = documentFolders.map(folder => {
      return {
        value: folder._id,
        label: folder.name
      };
    });

    const locationDropdown = locationList.map(location => {
      return {
        value: location._id,
        label: location.name
      };
    });

    const getProjectDropdown = () => {
      let group = '';
      if (!groupIds && !activeLocation) {
        return [];
      } else if (activeLocation) {
        group = [activeLocation._id];
      } else {
        group = groupIds;
      }

      const selectedGroup = locationList.filter(o =>
        group.find(o2 => o._id === (o2?.value ?? o2))
      );

      let projectsDropdown = [];

      if (selectedGroup.length > 0) {
        selectedGroup.forEach(group => {
          group.projects.forEach(project => {
            projectsDropdown.push({
              value: project._id,
              label: `${group.name} - ${project.name}`
            });
          });
        });
      }

      return projectsDropdown;
    };

    return (
      <div className="sdsForm">
        {isUpdate && hasAccess ? (
          <InputRow right>
            <Button
              disabled={label?.length < 3}
              color="blue"
              text="Update"
              onClick={() => submitForm(this.state)}
            />
            <Button
              color="red"
              text="Delete"
              onClick={() => {
                onDelete && onDelete();
                history.push('/app/documents/sds');
              }}
            />
            <Button
              text="Cancel"
              color="white"
              onClick={() => this.handleCancel()}
            />
          </InputRow>
        ) : (
          ''
        )}
        <div className="sdsForm-userInfo">
          {!activeLocation ? (
            <Dropdown
              multi
              fieldLabel="Group or Establishment:"
              options={locationDropdown}
              placeholder="Select a group/establishment"
              currentValue={groupIds}
              onChange={value => this.updateState({ groupIds: value })}
              name="locationId"
              displayTooltip={true}
              searchable
              selectButtons
              bareValues={false}
              tooltip={
                <p>
                  You can share with one or more Groups / Establishments and
                  that will make it available to them as well
                </p>
              }
            />
          ) : (
            ''
          )}
          {!activeProject && accessLevel !== 100 ? (
            <Dropdown
              multi
              fieldLabel="Area:"
              options={getProjectDropdown()}
              placeholder="Select an area"
              currentValue={projectIds}
              onChange={value => this.updateState({ projectIds: value })}
              name="projectId"
              displayTooltip={true}
              searchable
              selectButtons
              bareValues={false}
              tooltip={
                <p>
                  You can share this document with one or more Areas and that
                  will make it available to them as well
                </p>
              }
            />
          ) : (
            ''
          )}
          <Textbox
            fieldLabel="Title:"
            onChange={label => this.updateState({ label })}
            onBlur={this.handleBlur('label')}
            currentValue={label}
            disabled={!hasAccess}
            isRequired={true}
          />
          <DatePicker
            fieldLabel="Expires:"
            onChange={value => this.updateState({ expires: value })}
            currentValue={expires}
            disabled={!hasAccess}
            name="sdsExpires"
          />
          <Dropdown
            fieldLabel="Add to Folder:"
            options={folderDropdown}
            currentValue={documentFolderId}
            onChange={value => this.updateState({ documentFolderId: value })}
            disabled={!hasAccess}
          />
          <SdsButtons
            handleButtonClick={value => this.updateState({ ghsLabels: value })}
            currentValue={ghsLabels.length > 0 ? ghsLabels : []}
            disabled={!hasAccess}
          />
          <div>
            <h4>Attachments</h4>
            {addedAttachments &&
              addedAttachments.map((attachment, attachmentIndex) => (
                <FileDownloader
                  attachment={attachment}
                  key={attachmentIndex}
                  attachmentIndex={attachmentIndex}
                  deleteAttachment={deleteAttachment}
                  disabled={!hasAccess}
                />
              ))}
            {activeAttachments &&
              activeAttachments.map((attachment, attachmentIndex) => (
                <FileDownloader
                  attachment={attachment}
                  key={attachmentIndex}
                  attachmentIndex={attachmentIndex}
                  deleteAttachment={deleteSDSAttachment}
                  disabled={!hasAccess}
                />
              ))}
          </div>
          {hasAccess && (
            <div>
              <FileUploader
                activeCompanyId={activeCompanyId}
                addAttachment={addAttachment}
                loading={loading}
                ownerId="1234"
                ownerType="documents"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SdsForm;
