import React from 'react';
import './svg.css';

export default props => (
  <svg
    version="1.1"
    x={props.x || "0px"}
    y={props.y || "0px"}
    viewBox="0 0 500 500"
    width="30px"
    height="25px"
  >
    <g id="_14-test" data-name="14-test">
      <g id="linear_color" data-name="linear color">
        <circle cx="256" cy="56" r="12" />
        <path d="M168,220h48a12,12,0,0,0,12-12V160a12,12,0,0,0-12-12H168a12,12,0,0,0-12,12v48A12,12,0,0,0,168,220Zm12-48h24v24H180Z" />
        <path d="M168,324h48a12,12,0,0,0,12-12V264a12,12,0,0,0-12-12H168a12,12,0,0,0-12,12v48A12,12,0,0,0,168,324Zm12-48h24v24H180Z" />
        <path d="M264,196h72a12,12,0,0,0,0-24H264a12,12,0,0,0,0,24Z" />
        <path d="M264,300h72a12,12,0,0,0,0-24H264a12,12,0,0,0,0,24Z" />
        <path d="M452,328.051V68a24.028,24.028,0,0,0-24-24H306.6A52.008,52.008,0,0,0,205.4,44H85A24.027,24.027,0,0,0,61,68V444a24.027,24.027,0,0,0,24,24H312.051A99.966,99.966,0,1,0,452,328.051Zm-24-13.344a99.173,99.173,0,0,0-24-5.984V68h24ZM216,68a12,12,0,0,0,11.938-10.773A12.145,12.145,0,0,0,228,56a28,28,0,0,1,56,0,12,12,0,0,0,12,12h20v32H196V68Zm-44,0v44a12,12,0,0,0,12,12H328a12,12,0,0,0,12-12V68h40V308.723A100.193,100.193,0,0,0,292.723,396H132V68ZM85,444V68h23V408a12,12,0,0,0,12,12H292.723a99.173,99.173,0,0,0,5.984,24Zm307,40a76,76,0,1,1,76-76A76.086,76.086,0,0,1,392,484Z" />
        <path d="M423.515,375.515,384,415.029l-15.515-15.514a12,12,0,0,0-16.97,16.97l24,24a12,12,0,0,0,16.97,0l48-48a12,12,0,0,0-16.97-16.97Z" />
      </g>
    </g>
  </svg>
);
