import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  fetchCustomReportQuestionsRequest,
  setActiveCustomReportQuestions
} from '../../actions/reports';
import {
  REPORT_COMPONENTS_CUSTOMIZABLE,
  REPORT_TYPES_CUSTOMIZABLE
} from '../../constants/constants';
import { getActiveCompany } from '../../selectors/company';
import svgMapper from '../../utils/svgMapper';
import Button from '../../components/inputs/Button';
import Header from '../../components/Header';

import './index.css';

export class CustomReportQuestionsList extends Component {
  state = {
    selected: '',
    showReportComponents: false
  };

  handleSelectReport = selected => {
    this.setState({ selected, showReportComponents: true });
  };

  handleSelectReportComponent = selected => {
    this.props.setActiveCustomTemplate({
      reportTemplateNumber: this.state.selected,
      reportComponentName: selected.label
    });
    this.props.fetchCustomReport();
  };

  getReportComponents = selected => {
    return REPORT_COMPONENTS_CUSTOMIZABLE.filter(component =>
      component.reports.includes(selected)
    );
  };

  render() {
    const { selected, showReportComponents } = this.state;
    const customizableReports = this.props?.activeCompany?.showAircraft
      ? REPORT_TYPES_CUSTOMIZABLE
      : REPORT_TYPES_CUSTOMIZABLE.filter(
          reportType => reportType.value !== 6 && reportType.value !== 16
        );

    return (
      <div className="customReportQuestionsList">
        <Header title="Custom Report Questions" />
        {!showReportComponents && (
          <div className="customReportQuestionsList-table">
            <h2 className="reportSelector-headers">Type of Report</h2>
            <div className="reportSelector-buttonArray">
              {customizableReports.map((button, index) => (
                <button
                  className={
                    selected === button.value
                      ? 'reportSelector-button activeButton'
                      : 'reportSelector-button'
                  }
                  key={index}
                  onClick={() => this.handleSelectReport(button.value)}
                >
                  <div
                    className="reportSelector-button--buttonStuff"
                    data-test={button.label}
                  >
                    {svgMapper(button.value)}
                    <span className="reportSelector-button--text">
                      {button.label}
                    </span>
                  </div>
                </button>
              ))}
            </div>
          </div>
        )}
        {showReportComponents && (
          <div className="customReportQuestionsList-table">
            <h2 className="reportSelector-headers">
              <div>Type Of Report Component</div>
              <Button
                text="Back To Report Selection"
                color="blue"
                onClick={() => this.setState({ showReportComponents: false })}
              />
            </h2>
            <div className="reportSelector-buttonArray">
              {this.getReportComponents(selected).map((button, index) => (
                <button
                  className="reportSelector-button"
                  key={index}
                  onClick={() => this.handleSelectReportComponent(button)}
                >
                  <div
                    className="reportSelector-button--buttonStuff"
                    data-test={button.label}
                  >
                    <span className="customReportQuestionsList-reportComponentText">
                      {button.label}
                    </span>
                  </div>
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeCompany: getActiveCompany(state)
});

const mapDispatchToProps = dispatch => ({
  setActiveCustomTemplate: payload =>
    dispatch(setActiveCustomReportQuestions(payload)),
  fetchCustomReport: () => dispatch(fetchCustomReportQuestionsRequest())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomReportQuestionsList);
