import React, { useState } from 'react';
import { Button } from '../inputs';
import Carousel from '../Carousel';
import Modal from '../Modal';
import cameraImage from '../../assets/images/CameraIcon.png';
import { isImage } from '../../utils/attachmentSelector';
import { ATTACHMENT_TYPES_ACCEPTED } from '../../constants/constants';
import styles from './index.module.scss';

export const Attachments = ({
  isCreating,
  isEditing,
  fromEquipment,
  canEditAll,
  updatePicturesToUpload, // pictures during creation
  picturesToUpload,
  uploadedPictures,
  removePicturesToUpload,
  deleteAttachment,
  addAttachments,
  ownerType
}) => {
  const noPhotoTypes = fromEquipment || ownerType === 'behaviorObservation';
  const allPictures = [...picturesToUpload, ...uploadedPictures];

  const [photoType, setPhotoType] = useState('');
  const [
    openAddCorrectiveDefectiveModal,
    setOpenAddCorrectiveDefectiveModal
  ] = useState(false);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [formattedSelectedPhotos, setFormattedSelectedPhotos] = useState([]);
  const [openAddAttachmentModal, setOpenAddAttachmentModal] = useState(false);

  // deleting a picture during the creation that hasnt been uploaded to db yet
  const deletetPictureToUpload = attachment => {
    let updated = picturesToUpload.filter(p => p !== attachment);
    let fileToRemove = formattedSelectedPhotos.find(
      a => a.name === attachment.name
    );
    let formattedFileToRemove = new File([fileToRemove], fileToRemove.name);
    let formattedUpdated = formattedSelectedPhotos.filter(
      f => f === formattedFileToRemove
    );
    removePicturesToUpload(updated, formattedUpdated);
  };

  const checkFormatOfPhotos = e => {
    let files = e.target.files;
    if (!files.length) return;
    for (let i = 0; i < files.length; i++) {
      if (!isImage(files[i])) {
        let filesAccepted = ATTACHMENT_TYPES_ACCEPTED.join(', ');
        alert(`File must be one of ${filesAccepted} types`);
        return;
      }
    }
    formatFiles(e);
  };

  const formatFiles = e => {
    const files = e.target.files;
    let data = [];
    let filesToUpload = [];

    if (!files.length) return;

    for (let i = 0; i < files.length; i++) {
      const FileSize = files[i].size / 1024 / 1024; // in MB
      if (FileSize > 2000) {
        alert('File size exceeds 2000 MB');
        return;
      } else {
        const formatted = {
          name: files[i].name,
          source_url: URL.createObjectURL(files[i]),
          photoType
        };

        data.push(formatted);
        filesToUpload.push(files[i]);
      }
    }

    // need a way to keep track of different photoTypes
    setFormattedSelectedPhotos(filesToUpload);
    setSelectedPhotos(data);
    setOpenAddAttachmentModal(true);
  };

  const handlePhotosToUpload = () => {
    updatePicturesToUpload(selectedPhotos, formattedSelectedPhotos);
    setOpenAddAttachmentModal(false);
  };

  const handleAddAttachments = () => {
    setOpenAddAttachmentModal(false);
    addAttachments(formattedSelectedPhotos, photoType);
  };

  return (
    <div>
      <Button
        type="button"
        testID="addPhoto"
        text="Add Photo"
        color="blue"
        image={
          <img
            src={cameraImage}
            className={styles.cameraButtonImage}
            alt="Button"
          />
        }
        onClick={() => {
          if (noPhotoTypes) {
            document.getElementById(`myImage`).click();
            setPhotoType('');
          } else if (isCreating) {
            setPhotoType('defective');
            document.getElementById(`myImage`).click();
          } else {
            setOpenAddCorrectiveDefectiveModal(true);
          }
        }}
        disabled={!isEditing && !isCreating}
      />
      <input
        type="file"
        id="myImage"
        className={styles.fileUpload}
        onChange={e => {
          checkFormatOfPhotos(e);
          e.target.value = '';
        }}
        multiple
        accept={ATTACHMENT_TYPES_ACCEPTED.join(', ')}
      />
      {allPictures?.length < 1 ? (
        <span className={styles.attachmentText}>No Attachments Added</span>
      ) : (
        <div className={styles.carousel}>
          <Carousel
            items={allPictures}
            deleteAction={attachment =>
              isCreating
                ? deletetPictureToUpload(attachment)
                : deleteAttachment(attachment)
            }
            canDelete={isEditing || isCreating}
            canDownload
            hasStatus={!noPhotoTypes}
            showPostedBy={!isCreating}
            onlyDeleteOwnImages={
              isCreating || fromEquipment ? false : !canEditAll
            }
            ownerType={ownerType}
          />
        </div>
      )}
      <Modal
        title="Add Attachment(s)"
        titleClassName="blueHeader"
        isOpen={openAddAttachmentModal}
        submitButtonColor="blue"
        submitButtonText="Save"
        cancelButtonText="Cancel"
        onRequestClose={() => {
          setFormattedSelectedPhotos([]);
          setSelectedPhotos([]);
          setOpenAddAttachmentModal(false);
        }}
        submitActions={() =>
          isCreating ? handlePhotosToUpload() : handleAddAttachments()
        }
      >
        <Carousel
          items={selectedPhotos}
          canDelete={false}
          canDownload={false}
        />
        {isCreating && !noPhotoTypes && (
          <div className={styles.text}>
            The photo will be marked as
            <span className={styles.blueText}> defective </span>
          </div>
        )}
        {(isEditing || fromEquipment) && (
          <div className={styles.text}>
            Are you sure you would like to upload the following{' '}
            {!noPhotoTypes && (
              <>
                <span
                  className={
                    photoType === 'defective'
                      ? styles.blueText
                      : styles.greenText
                  }
                >
                  {photoType === 'corrective' ? 'corrected' : photoType}
                </span>{' '}
              </>
            )}
            photo(s)
          </div>
        )}
      </Modal>
      <Modal
        title="Add Attachment(s)"
        titleClassName="blueHeader"
        isOpen={openAddCorrectiveDefectiveModal}
        submitButtonColor="greenOutline"
        submitButtonText="Corrected Photo"
        cancelButtonText="Defective Photo"
        cancelButtonColor="redOutline"
        onRequestClose={() => setOpenAddCorrectiveDefectiveModal(false)}
        submitActions={() => {
          setPhotoType('corrective');
          document.getElementById(`myImage`).click();
          setOpenAddCorrectiveDefectiveModal(false);
        }}
        cancelActions={() => {
          setPhotoType('defective');
          document.getElementById(`myImage`).click();
          setOpenAddCorrectiveDefectiveModal(false);
        }}
      >
        <div className={styles.text}>
          Would you like to upload a{' '}
          <span className={styles.greenText}> corrected </span> photo or a
          <span className={styles.redText}> defective </span> photo
        </div>
      </Modal>
    </div>
  );
};

export default Attachments;
