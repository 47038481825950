import React from 'react';
import { InputRow, Button, nameInput } from '../';
import classnames from 'classnames';

import styles from './buttonGroup.module.scss';

const ButtonGroup = ({
  text,
  color,
  inputClassName,
  type,
  leftImage,
  centerImage,
  rightImage,
  selected,
  name,
  onClick,
  disabled,
  shrink,
  testID,
  imageClass,
  ...props
}) => {
  return (
    <InputRow {...props} className={styles.buttonGroup}>
      <Button
        onClick={() => onClick(1)}
        image={leftImage}
        middle
        inputClassName={styles.button}
        className={styles.inputGroup}
        imageClass={classnames([
          styles.image,
          selected === 1
            ? styles.white
            : selected
            ? styles.greyCheck
            : styles.green
        ])}
        color={selected === 1 ? 'green' : 'white'}
        testID="safeButton"
      />
      <Button
        onClick={() => onClick(2)}
        image={centerImage}
        middle
        inputClassName={styles.button}
        className={styles.inputGroup}
        imageClass={classnames([
          styles.image,
          selected === 2 ? styles.white : selected ? styles.grey : styles.red
        ])}
        color={selected === 2 ? 'red' : 'white'}
        testID="unsafeButton"
      />
      <Button
        onClick={() => onClick(3)}
        image={rightImage}
        middle
        inputClassName={styles.button}
        className={styles.inputGroup}
        imageClass={classnames([
          styles.image,
          selected === 3 ? styles.white : selected ? styles.greyCheck : ''
        ])}
        color={selected === 3 ? 'grey' : 'white'}
        testID="NAButton"
      />
    </InputRow>
  );
};

const nButtonGroup = nameInput(ButtonGroup);
export { nButtonGroup as ButtonGroup };
export default nButtonGroup;
