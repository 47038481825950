import {
  take,
  fork,
  takeEvery,
  takeLatest,
  takeLeading
} from 'redux-saga/effects';
import * as api from '../api';
import * as apiV4 from '../api/v4';
import * as actionTypes from '../constants/actionTypes';
import {
  deleteAttachment,
  uploadDocument,
  updateAttachment
} from './attachment';
import {
  createUserSecurityQuestions,
  forgotPasswordReset,
  loginMicrosoftUser,
  logInUser,
  newUserRegistration,
  updateDefaultPassword,
  updatePassword,
  updateUser,
  validateSecurityQuestions
} from './auth';
import {
  addCompany,
  addCompanyLocation,
  addGroupProject,
  deleteCompany,
  deleteCompanyLocation,
  deleteGroupProject,
  setIncidentOwner,
  updateCompany,
  updateCompanyLocation,
  updateGroupProject
} from './company';
import {
  addDocumentFolder,
  addDocumentsToFolder,
  addQualification,
  addSafetyInstruction,
  addSds,
  createContinuousCare,
  createCustomDocument,
  createCustomDocumentTemplate,
  createMyDocument,
  deleteContinuousCare,
  deleteCustomDocument,
  deleteCustomDocumentAttachment,
  deleteCustomDocumentTemplate,
  deleteDocumentFolder,
  deleteMyDocument,
  deleteQualification,
  deleteQualificationAttachment,
  deleteSafetyInstruction,
  deleteSafetyInstructionAttachment,
  deleteSds,
  deleteSdsAttachment,
  fetchAuthorizationDocuments,
  fetchCompanySafetyInstructions,
  fetchCompanySds,
  fetchCustomDocuments,
  fetchCustomDocumentTemplates,
  fetchDocumentFolders,
  fetchDocumentsByFolder,
  fetchMyDocuments,
  fetchQualifications,
  fetchQualificationTypes,
  updateContinuousCare,
  updateCustomDocument,
  updateCustomDocumentTemplate,
  updateDocumentFolder,
  updateMyDocument,
  updateQualification,
  updateSafetyInstruction,
  updateSds
} from './documents';
import {
  addIncidentComment,
  addIncidentNote,
  createIncident,
  deleteIncident,
  deleteIncidentAttachment,
  emailIncidentAssignee,
  fetchIncidentBasics,
  fetchIncidentById,
  saveIncidentBasics,
  setActiveIncident,
  updateIncidentBasics,
  updateIncidentComment,
  updateIncidentNote,
  updateReportComponent
} from './incidents';
import {
  createOshaForm,
  createOshaLog,
  fetchOshaLogs,
  printOshaFiles,
  printSelectedOsha301,
  updateOshaForm
} from './oshaLogs';
import {
  downloadPersonnelQRCode,
  setActiveEmployee,
  updateLocationEmployee
} from './personnel';
import {
  createCustomReportQuestions,
  deleteCustomReportQuestions,
  fetchCustomReportQuestions,
  setActiveReport,
  updateCustomReportQuestions
} from './reports';
import {
  addSafetyWalk,
  deleteSafetyWalk,
  fetchSafetyWalks,
  loadSafetyWalk
} from './safetyWalks';
import { searchByTerms } from './search';
import {
  addUserTask,
  deleteUserTask,
  fetchUsersTasks,
  loadTask,
  setActiveTask,
  updateUserTask,
  sendReminder
} from './tasks';
import {
  fetchCustomTemplates,
  addCustomTemplate,
  updateCustomTemplate,
  deleteCustomTemplate,
  fetchTrainings,
  addTraining,
  updateTraining,
  deleteTraining,
  setActiveTraining
} from './training';
import { loadUser, loadAllUsers, loadAllCompanyUsers } from './user';

const takeLeadingUserId = (patternOrChannel, saga, ...args) =>
  fork(function*() {
    const loadingIds = {};
    while (true) {
      const action = yield take(patternOrChannel);
      const userId = action.payload;

      if (!(loadingIds[userId] && loadingIds[userId].isRunning())) {
        loadingIds[userId] = yield fork(saga, ...args.concat(action));
      }
    }
  });

export default function* rootSaga() {
  yield takeLatest(
    actionTypes.CREATE_USER_SECURITY_QUESTIONS_REQUEST,
    createUserSecurityQuestions,
    api
  );

  yield takeLatest(
    actionTypes.SUBMIT_SECURITY_QUESTIONS,
    validateSecurityQuestions,
    api
  );

  yield takeLatest(actionTypes.AUTH_SIGNIN, logInUser, api, apiV4);

  yield takeLatest(actionTypes.MICROSOFT_LOGIN, loginMicrosoftUser, api, apiV4);

  yield takeLatest(
    actionTypes.FORGOT_PASSWORD_REQUEST,
    forgotPasswordReset,
    api
  );

  yield takeLatest(
    actionTypes.NEW_USER_REGISTRATION_REQUEST,
    newUserRegistration,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_USERS_TASKS_REQUEST,
    fetchUsersTasks,
    apiV4
  );

  yield takeLatest(
    actionTypes.FETCH_SAFETY_INSTRUCTION_REQUEST,
    fetchCompanySafetyInstructions,
    api,
    apiV4
  );

  yield takeLatest(actionTypes.FETCH_SDS_REQUEST, fetchCompanySds, api, apiV4);

  yield takeLatest(actionTypes.SET_INCIDENT_OWNER, setIncidentOwner, apiV4);

  yield takeLatest(actionTypes.ADD_COMPANY_REQUEST, addCompany, api, apiV4);

  yield takeLatest(
    actionTypes.ADD_COMPANY_LOCATION_REQUEST,
    addCompanyLocation,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_COMPANY_LOCATION_REQUEST,
    updateCompanyLocation,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_COMPANY_LOCATION_REQUEST,
    deleteCompanyLocation,
    apiV4
  );

  yield takeLatest(actionTypes.LOAD_TASK, loadTask, api, apiV4);

  yield takeLatest(actionTypes.SEND_REMINDER_REQUEST, sendReminder, apiV4);

  yield takeLatest(actionTypes.SET_ACTIVE_TASK_REQUEST, setActiveTask, api);

  yield takeLatest(actionTypes.ADD_USER_TASK_REQUEST, addUserTask, api, apiV4);

  yield takeLatest(
    actionTypes.UPDATE_USER_TASK_REQUEST,
    updateUserTask,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_USER_TASK_REQUEST,
    deleteUserTask,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_LOCATION_EMPLOYEE_REQUEST,
    updateLocationEmployee,
    apiV4
  );

  yield takeLatest(actionTypes.ADD_SDS_REQUEST, addSds, api, apiV4);

  yield takeLatest(actionTypes.UPDATE_SDS_REQUEST, updateSds, api, apiV4);

  yield takeLatest(actionTypes.DELETE_SDS_REQUEST, deleteSds, apiV4);

  yield takeLatest(
    actionTypes.ADD_SAFETY_INSTRUCTION_REQUEST,
    addSafetyInstruction,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_SAFETY_INSTRUCTION_REQUEST,
    updateSafetyInstruction,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_SAFETY_INSTRUCTION_REQUEST,
    deleteSafetyInstruction,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_COMPANY_REQUEST,
    updateCompany,
    api,
    apiV4
  );

  yield takeLatest(actionTypes.DELETE_COMPANY_REQUEST, deleteCompany, api);

  yield takeLatest(
    actionTypes.FETCH_INCIDENT_BASICS_REQUEST,
    fetchIncidentBasics,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.SAVE_INCIDENT_BASICS_REQUEST,
    saveIncidentBasics,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_INCIDENT_BASICS_REQUEST,
    updateIncidentBasics,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_REPORT_COMPONENT_REQUEST,
    updateReportComponent,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.CREATE_INCIDENT_REQUEST,
    createIncident,
    api,
    apiV4
  );

  yield takeLatest(actionTypes.DELETE_INCIDENT_REQUEST, deleteIncident, apiV4);

  yield takeLatest(actionTypes.UPDATE_USER_REQUEST, updateUser, api, apiV4);

  yield takeLatest(actionTypes.UPDATE_PASSWORD_REQUEST, updatePassword, api);

  yield takeLatest(
    actionTypes.UPDATE_DEFAULT_PASSWORD_REQUEST,
    updateDefaultPassword,
    api
  );

  yield takeLatest(
    actionTypes.SET_ACTIVE_INCIDENT_REQUEST,
    setActiveIncident,
    apiV4
  );

  yield takeLatest(
    actionTypes.SET_ACTIVE_REPORT_REQUEST,
    setActiveReport,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.ADD_INCIDENT_COMMENTS_REQUEST,
    addIncidentComment,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.ADD_INCIDENT_NOTES_REQUEST,
    addIncidentNote,
    apiV4
  );

  yield takeLatest(
    actionTypes.SET_ACTIVE_EMPLOYEE_REQUEST,
    setActiveEmployee,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_SAFETY_INSTRUCTION_ATTACHMENT,
    deleteSafetyInstructionAttachment,
    api
  );

  yield takeEvery(
    actionTypes.ADD_UPLOADED_ATTACHMENTS_REQUEST,
    uploadDocument,
    api
  );

  yield takeLatest(actionTypes.DELETE_SDS_ATTACHMENT, deleteSdsAttachment, api);

  yield takeLatest(
    actionTypes.DELETE_INCIDENT_ATTACHMENT,
    deleteIncidentAttachment,
    api,
    apiV4
  );

  yield takeLatest(actionTypes.SEARCH_REQUEST, searchByTerms, api);

  yield takeLatest(actionTypes.CREATE_OSHA_FORM_REQUEST, createOshaForm, api);

  yield takeLatest(actionTypes.UPDATE_OSHA_FORM_REQUEST, updateOshaForm, api);

  yield takeLatest(actionTypes.FETCH_OSHA_LOGS_REQUEST, fetchOshaLogs, apiV4);

  yield takeLatest(actionTypes.CREATE_OSHA_LOG_REQUEST, createOshaLog, apiV4);

  yield takeLatest(actionTypes.PRINT_OSHA_REQUEST, printOshaFiles, apiV4);

  yield takeLatest(
    actionTypes.PRINT_SELECTED_OSHA_REQUEST,
    printSelectedOsha301,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_ATTACHMENT_REQUEST,
    deleteAttachment,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_QUALIFICATION_REQUEST,
    fetchQualifications,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.ADD_QUALIFICATION_REQUEST,
    addQualification,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_QUALIFICATION_REQUEST,
    updateQualification,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.DOWNLOAD_EMPLOYEE_QRCODE_REQUEST,
    downloadPersonnelQRCode,
    apiV4
  );

  yield takeLatest(actionTypes.ADD_PROJECT_REQUEST, addGroupProject, apiV4);

  yield takeLatest(
    actionTypes.UPDATE_PROJECT_REQUEST,
    updateGroupProject,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_PROJECT_REQUEST,
    deleteGroupProject,
    apiV4
  );

  yield takeLatest(
    actionTypes.FETCH_QUALIFICATION_TYPES_REQUEST,
    fetchQualificationTypes,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_QUALIFICATION_REQUEST,
    deleteQualification,
    apiV4
  );

  yield takeLatest(
    actionTypes.ADD_DOCUMENT_FOLDER_REQUEST,
    addDocumentFolder,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_DOCUMENT_FOLDERS_REQUEST,
    fetchDocumentFolders,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_DOCUMENT_FOLDER_REQUEST,
    updateDocumentFolder,
    api
  );

  yield takeLatest(
    actionTypes.DELETE_DOCUMENT_FOLDER_REQUEST,
    deleteDocumentFolder,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.SET_ACTIVE_DOCUMENT_FOLDER_REQUEST,
    fetchDocumentsByFolder,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.ADD_DOCUMENTS_TO_FOLDER_REQUEST,
    addDocumentsToFolder,
    apiV4
  );

  yield takeLatest(
    actionTypes.EMAIL_INCIDENT_ASSIGNEE_REQUEST,
    emailIncidentAssignee,
    api
  );

  yield takeLatest(
    actionTypes.UPDATE_INCIDENT_COMMENTS_REQUEST,
    updateIncidentComment,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_INCIDENT_NOTES_REQUEST,
    updateIncidentNote,
    apiV4
  );

  yield takeLatest(
    actionTypes.FETCH_INCIDENT_BY_ID_REQUEST,
    fetchIncidentById,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_QUALIFICATION_ATTACHMENT,
    deleteQualificationAttachment,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_SAFETY_WALKS_REQUEST,
    fetchSafetyWalks,
    apiV4
  );
  yield takeLatest(
    actionTypes.ADD_SAFETY_WALK_REQUEST,
    addSafetyWalk,
    api,
    apiV4
  );
  yield takeLatest(actionTypes.LOAD_SAFETY_WALK, loadSafetyWalk, apiV4);

  yield takeLatest(
    actionTypes.DELETE_SAFETY_WALK_REQUEST,
    deleteSafetyWalk,
    apiV4
  );

  yield takeLatest(
    actionTypes.FETCH_MY_DOCUMENTS_REQUEST,
    fetchMyDocuments,
    apiV4
  );

  yield takeLatest(
    actionTypes.CREATE_MY_DOCUMENT_REQUEST,
    createMyDocument,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_MY_DOCUMENT_REQUEST,
    updateMyDocument,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_MY_DOCUMENT_REQUEST,
    deleteMyDocument,
    apiV4
  );

  yield takeLatest(
    actionTypes.FETCH_REQUIRE_AUTHORIZATION_REQUEST,
    fetchAuthorizationDocuments,
    api,
    apiV4
  );

  // come back
  yield takeLatest(
    actionTypes.CREATE_CONTINUOUS_CARE_REQUEST,
    createContinuousCare,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_CONTINUOUS_CARE_REQUEST,
    updateContinuousCare,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_CONTINUOUS_CARE_REQUEST,
    deleteContinuousCare,
    apiV4
  );

  yield takeLatest(
    actionTypes.FETCH_CUSTOM_REPORT_QUESTIONS_REQUEST,
    fetchCustomReportQuestions,
    apiV4
  );

  yield takeLatest(
    actionTypes.CREATE_CUSTOM_REPORT_QUESTIONS_REQUEST,
    createCustomReportQuestions,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_CUSTOM_REPORT_QUESTIONS_REQUEST,
    updateCustomReportQuestions,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_CUSTOM_REPORT_QUESTIONS_REQUEST,
    deleteCustomReportQuestions,
    apiV4
  );

  yield takeLatest(
    actionTypes.FETCH_CUSTOM_DOCUMENTS_TEMPLATE_REQUEST,
    fetchCustomDocumentTemplates,
    apiV4
  );

  yield takeLatest(
    actionTypes.CREATE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST,
    createCustomDocumentTemplate,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST,
    updateCustomDocumentTemplate,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_CUSTOM_DOCUMENT_TEMPLATE_REQUEST,
    deleteCustomDocumentTemplate,
    apiV4
  );

  yield takeLatest(
    actionTypes.FETCH_CUSTOM_DOCUMENTS_REQUEST,
    fetchCustomDocuments,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.CREATE_CUSTOM_DOCUMENT_REQUEST,
    createCustomDocument,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_CUSTOM_DOCUMENT_REQUEST,
    updateCustomDocument,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_CUSTOM_DOCUMENT_REQUEST,
    deleteCustomDocument,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_CUSTOM_DOCUMENT_ATTACHMENT_REQUEST,
    deleteCustomDocumentAttachment,
    api
  );

  yield takeLatest(
    actionTypes.FETCH_CUSTOM_TRAINING_TEMPLATES_REQUEST,
    fetchCustomTemplates,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.ADD_CUSTOM_TRAINING_TEMPLATE_REQUEST,
    addCustomTemplate,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_CUSTOM_TRAINING_TEMPLATE_REQUEST,
    updateCustomTemplate,
    api,
    apiV4
  );

  yield takeLatest(
    actionTypes.DELETE_CUSTOM_TRAINING_TEMPLATE_REQUEST,
    deleteCustomTemplate,
    apiV4
  );

  yield takeLatest(
    actionTypes.FETCH_TRAININGS_REQUEST,
    fetchTrainings,
    api,
    apiV4
  );

  yield takeLatest(actionTypes.ADD_TRAINING_REQUEST, addTraining, api, apiV4);

  yield takeLatest(
    actionTypes.UPDATE_TRAINING_REQUEST,
    updateTraining,
    api,
    apiV4
  );

  yield takeLatest(actionTypes.DELETE_TRAINING_REQUEST, deleteTraining, apiV4);

  yield takeLatest(
    actionTypes.SET_ACTIVE_TRAINING_REQUEST,
    setActiveTraining,
    apiV4
  );

  yield takeLatest(
    actionTypes.UPDATE_ATTACHMENT_REQUEST,
    updateAttachment,
    api
  );

  yield takeLeadingUserId(actionTypes.LOAD_USER_REQUEST, loadUser, apiV4);
  yield takeLeading(actionTypes.LOAD_ALL_USERS_REQUEST, loadAllUsers, apiV4);
  yield takeLeading(
    actionTypes.LOAD_ALL_COMPANY_USERS_REQUEST,
    loadAllCompanyUsers,
    apiV4
  );
}
