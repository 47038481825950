import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import {
  getSelectedCompanies,
  getSelectedGroups
} from '../../utils/employeeDGPHelper';
import SvgWithTooltip from '../SvgWithTooltip';
import UserBadge from '../UserBadge';
import { TwoColumn } from '../inputs';
import { setActiveIncidentRequest } from '../../actions/incidents';
import { states } from '../../constants/states';

import styles from './styles.module.scss';

export default function EmployeeSnapshot({
  userCompanies,
  systemInteractions,
  activeEmployee
}) {
  const {
    address,
    firstName,
    lastName,
    trainings,
    qualifications,
    incidents
  } = activeEmployee;

  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const hireDate = activeEmployee.dateOfHire
      ? moment(Date.parse(activeEmployee.dateOfHire)).format('MM/DD/YYYY')
      : 'Not on File';
    const birthDate = activeEmployee.dateOfBirth
      ? moment(Date.parse(activeEmployee.dateOfBirth)).format('MM/DD/YYYY')
      : 'Not on File';

    if (!activeEmployee.dateOfHire && !activeEmployee.dateOfBirth) {
      setUserInfo([]);
    } else {
      const info = [
        { label: 'Hire Date', value: hireDate },
        {
          label: 'User Type',
          value: getUserAccessLevel(activeEmployee).level
        },
        { label: 'Job Title', value: activeEmployee.jobTitle ?? 'Not on File' },
        { label: 'Birth Date', value: birthDate }
      ];

      setUserInfo(info);
    }
  }, [activeEmployee]);

  const dispatch = useDispatch();
  const loadIncident = payload => dispatch(setActiveIncidentRequest(payload));

  const getUserAccessLevel = activeEmployee => {
    const accessLevel = activeEmployee.accessLevel;
    if (!accessLevel) {
      return {
        picture: require('../../assets/images/blank-profile-picture.jpg'),
        level: 'No User Info'
      };
    } else if (accessLevel === 100) {
      return {
        picture: require('../../assets/images/Reporter.png'),
        level: 'Reporter'
      };
    } else if (accessLevel === 400) {
      return {
        picture: require('../../assets/images/Supervisor.png'),
        level: 'Supervisor'
      };
    } else if (accessLevel === 500) {
      return {
        picture: require('../../assets/images/Collaborator.png'),
        level: 'Collaborator'
      };
    } else if (accessLevel === 900) {
      return {
        picture: require('../../assets/images/Admin.png'),
        level: 'Admin'
      };
    }
  };

  const selectedCompanies = getSelectedCompanies(
    userCompanies,
    activeEmployee.companyIds
  );

  const selectedGroups = getSelectedGroups(
    selectedCompanies,
    activeEmployee.groupIds
  );

  const last90DaysIncidents = incidents?.filter(i => {
    const whenDidIncidentOccur = moment(i.dateIncidentOccured);
    const today = moment(new Date());
    const difference = Math.abs(whenDidIncidentOccur.diff(today, 'days'));
    return difference <= 90;
  });

  const getLocationInfo = (location, type) => {
    let names =
      location.length > 2
        ? [location[0]?.name, location[1]?.name, `+ ${location.length - 2}`]
        : location.map(l => l.name);
    let otherNames = location.slice(2).map(l => l.name);

    return names.map((name, index) =>
      index === 2 ? (
        <div>
          <span
            className={styles.userLocationBubble}
            key={name}
            data-tip
            data-for={`${type}OtherLocations`}
          >
            {name}
          </span>
          <ReactTooltip className={styles.tooltip} id={`${type}OtherLocations`}>
            {otherNames.map(loc => (
              <span className={styles.userLocationBubble} key={name}>
                {loc}
              </span>
            ))}
          </ReactTooltip>
        </div>
      ) : (
        <span className={styles.userLocationBubble} key={name}>
          {name}
        </span>
      )
    );
  };

  const getPercentage = s => {
    let nums = s?.split('/');
    let width = (nums?.[0] / nums?.[1]) * 100;
    return (
      <div className={styles.progressBar}>
        {width > 0 && (
          <div
            className={styles.progressBarFilling}
            style={{
              width: `${width}%`
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <div className={styles.header}> Snapshot</div>
      <TwoColumn className={styles.snapshotColumns}>
        <div className={styles.userInfoContainer}>
          <div className={styles.userInfoHeader}> User Info</div>
          <UserBadge
            showImage
            size="employeeSnapshot"
            userId={activeEmployee._id}
            className={styles.employeeBadge}
          />
          <div
            className={styles.userInfoValue}
          >{`${firstName}  ${lastName}`}</div>

          <TwoColumn className={styles.userInfoColumns}>
            <div className={styles.userInfoLeftColumn}>
              {userInfo?.map(i => (
                <span className={styles.userInfoLabel} key={i.label}>
                  {i.label}
                </span>
              ))}

              <span className={styles.userInfoLabel} key="address">
                Address
              </span>
            </div>
            <div className={styles.userInfoRightColumn}>
              {userInfo?.map(i => (
                <span className={styles.userInfoValue} key={i.value}>
                  {i.value}
                </span>
              ))}
              <div className={styles.userInfoValue}>
                {address?.streetAddress}
              </div>
              <div className={styles.userInfoValue}> {address?.city} </div>
              <div className={styles.userInfoValue}>
                {states?.find(s => s.value === address?.stateName)?.label}
              </div>
              <div className={styles.userInfoValue}>{address?.zip} </div>
            </div>
          </TwoColumn>

          <div className={styles.divider} />
          <div className={styles.locationLabel}> Companies</div>
          {getLocationInfo(selectedCompanies, 'companies')}
          <div className={styles.locationLabel}> Group/Establishment</div>
          {getLocationInfo(selectedGroups, 'groups')}
        </div>
        <div>
          <TwoColumn>
            <div className={styles.sectionColumn}>
              <div className={styles.sectionHeader}> Expiring Trainings </div>
              <TwoColumn className={styles.snapshotColumns}>
                <div className={styles.expiringContainer}>
                  <div className={styles.expiringNumber}>
                    {trainings?.expiring3Months?.length}
                  </div>
                  <div className={styles.expiringLabel}>Expiring</div>
                </div>
                <ul className={styles.list}>
                  {trainings?.expiring3Months.length > 0 ? (
                    trainings?.expiring3Months.map(t => (
                      <li key={t.title}>
                        <span> {t.title} </span>
                      </li>
                    ))
                  ) : (
                    <span className={styles.noData}>No Expiring Trainings</span>
                  )}
                </ul>
              </TwoColumn>
            </div>
            <div className={styles.sectionColumn}>
              <div className={styles.sectionHeader}>
                Expiring Qualifications
              </div>
              <TwoColumn className={styles.snapshotColumns}>
                <div className={styles.expiringContainer}>
                  <div className={styles.expiringNumber}>
                    {qualifications?.expiring3Months?.length}
                  </div>
                  <div className={styles.expiringLabel}>Expiring</div>
                </div>
                <ul className={styles.list}>
                  {qualifications?.expiring3Months.length > 0 ? (
                    qualifications?.expiring3Months.map(q => (
                      <li key={q?.documentData?.qualificationType?.label}>
                        <span>{q?.documentData?.qualificationType?.label}</span>
                      </li>
                    ))
                  ) : (
                    <span className={styles.noData}>
                      No Expiring Qualifications
                    </span>
                  )}
                </ul>
              </TwoColumn>
            </div>
          </TwoColumn>
          <div className={styles.sectionColumn}>
            <div className={styles.sectionHeader}> Incidents </div>
            <div className={styles.subSectionHeader}>From the Past 90 Days</div>
            <TwoColumn>
              {last90DaysIncidents?.length > 0 ? (
                last90DaysIncidents?.map((i, index) => (
                  <button
                    key={index}
                    className={styles.incidentContainer}
                    onClick={() =>
                      loadIncident({
                        _id: i._id,
                        returnPath: `/app/settings/Employees`
                      })
                    }
                  >
                    <div>
                      <div className={styles.incidentInfo}>
                        {i.incidentNumber}
                      </div>
                      <div className={styles.incidentInfo}>
                        {moment(Date.parse(i.dateIncidentOccured)).format(
                          'MM/DD/YYYY h:mm A'
                        )}
                      </div>
                    </div>
                    <div className={styles.incidentSvgs}>
                      {i?.reportTemplateIds?.map((report, reportIndex) => (
                        <SvgWithTooltip type={report} key={reportIndex} />
                      ))}
                    </div>
                  </button>
                ))
              ) : (
                <span className={styles.noData}> No Incidents </span>
              )}
            </TwoColumn>
          </div>
          <div className={styles.sectionColumn}>
            <div className={styles.sectionHeader}> Analytics </div>
            <TwoColumn>
              <div className={styles.margin}>
                <div className={styles.locationLabel}> Tasks </div>
                {getPercentage(systemInteractions?.tasksData?.performedOnTime)}
                <span className={styles.analyticBoldText}>
                  {systemInteractions?.tasksData?.performedOnTime}
                </span>
                <span className={styles.analyticText}>Performed On Time</span>
                {getPercentage(systemInteractions?.tasksData?.pastDue)}
                <span className={styles.analyticBoldText}>
                  {systemInteractions?.tasksData?.pastDue}
                </span>
                <span className={styles.analyticText}> Past Due </span>
              </div>
              <div>
                <div className={styles.locationLabel}> Safety Audits </div>
                {getPercentage(systemInteractions?.saData?.performedOnTime)}
                <span className={styles.analyticBoldText}>
                  {systemInteractions?.saData?.performedOnTime}
                </span>
                <span className={styles.analyticText}> Performed On Time </span>
                {getPercentage(systemInteractions?.saData?.pastDue)}
                <span className={styles.analyticBoldText}>
                  {systemInteractions?.saData?.pastDue}
                </span>
                <span className={styles.analyticText}> Past Due </span>
              </div>
            </TwoColumn>
          </div>
        </div>
      </TwoColumn>
    </div>
  );
}
