import React, { Component } from 'react';

import classnames from 'classnames/bind';
import HoverImage from '../../components/HoverImage';

import styles from './leftNav.module.scss';
const bStyles = classnames.bind(styles);

export class LeftNav extends Component {
  #element = null;

  state = { selectedSection: null };

  componentDidMount() {
    this.startWatching();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.stopWatching();
      this.startWatching();
    }
  }

  componentWillUnmount() {
    this.stopWatching();
  }

  stopWatching = () => {
    if (this.element) {
      this.element.removeEventListener('scroll', this.onScroll);
    }
  };

  startWatching = () => {
    let item = (this.props.items || []).find(i => !i.removed);
    if (!item) return;

    this.element = document.getElementById(item.id || 0)?.parentNode;
    if (!this.element) return;
    this.element.addEventListener('scroll', this.onScroll);
    this.onScroll();
  };

  onScroll = e => {
    let distance = Number.POSITIVE_INFINITY;
    let targetId = null;

    (this.props.items || []).forEach((item, i) => {
      let id = item.id || i;
      const element = document.getElementById(id);
      if (!element) return;

      let d = Math.abs(
        element.offsetTop - this.element.offsetTop - this.element.scrollTop
      );
      if (d < distance) {
        distance = d;
        targetId = id;
      }
    });

    if (targetId !== null && targetId !== this.state.selectedSection) {
      this.setState({ selectedSection: targetId });
    }
  };

  scrollIntoView = idToScroll => {
    this.setState({ selectedSection: idToScroll });
    const element = document.getElementById(idToScroll);

    /*
    This is buggy.
    But it might be able to be fixed at some point.
    So it is left here for that eventuality.
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start'
    });
    */
    if (element && element.parentNode) {
      element.parentNode.scrollTop =
        element?.offsetTop - element?.parentNode?.offsetTop;
    }
  };

  renderItem = (item, i) => {
    let id = item.id || i;
    let classes = {
      selected: this.state.selectedSection === id,
      completed: item.complete,
      removed: item.removed
    };
    return (
      <li
        className={bStyles(classes)}
        onClick={() => this.scrollIntoView(id)}
        key={id}
      >
        {item.label}
      </li>
    );
  };

  render() {
    let items = this.props.items || [];
    const removed = this.props.removed || false;
    const removedItems = items.filter(i => i.removed);
    items = items.filter(i => !i.removed);

    return (
      <div className={styles.leftNav}>
        <ul className={styles.quickNav}>
          {items.map(this.renderItem)}
          {removed && removedItems.length ? (
            <>
              <li className={styles.removedHeader}>Removed</li>
              {removedItems.map(this.renderItem)}
            </>
          ) : (
            <></>
          )}
        </ul>
        {this.props.onAddEvidence && this.props.showAddEvidence && (
          <div className={styles.addEvidenceButton}>
            <img
              src={require('../../assets/images/addEvidenceButton.png')}
              alt="add evidence"
            />
            <input
              type="file"
              name="myfile"
              onChange={this.props.onAddEvidence}
              accept="image/png, image/jpeg, image/jpg"
              multiple
              data-cy="addEvidence"
            />
          </div>
        )}
        {this.props.onAddHazard && (
          <HoverImage
            src={require('../../assets/images/addHazard.png')}
            srcHover={require('../../assets/images/addHazardHover.png')}
            alt="add Hazard"
            testID="addHazard"
            onClick={this.props.onAddHazard}
            className={bStyles('addButton', 'img')}
          />
        )}
        {this.props.onAddBravos && (
          <HoverImage
            src={require('../../assets/images/addBravo.png')}
            srcHover={require('../../assets/images/addBravoHover.png')}
            alt="add Bravo"
            testID="addBravo"
            onClick={this.props.onAddBravos}
            className={bStyles('addButton', 'img')}
          />
        )}
      </div>
    );
  }
}

export default LeftNav;
