import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from '../../history';
import { InputRow, Button } from '../inputs';
import Modal from '../../components/Modal';
import List from '../../components/List';
import { addUploadedAttachmentsResponse } from '../../actions/attachments';

export class EmployeeDocumentRepo extends Component {
  state = { openCreateModal: false };

  handleView = original => {
    if (original.title) {
      if (original?.trainingType?.value === 1) {
        this.props.setActiveTraining({ ...original, isEditing: false });
        history.push('/app/training/performTraining');
      } else {
        this.props.setActiveTemplate({ ...original, isEditing: false });
      }
    } else {
      this.props.setActiveMyDocument({ ...original });

      if (
        original.label ===
        'Recurrence, Relapse During Recovery & Continuing Treatment'
      ) {
        history.push(`/app/recurrenceContainer/${original._id}`);
      } else if (original.label === 'Transitional Return to Work Program') {
        history.push(`/app/transitionContainer/${original._id}`);
      } else {
        this.props.addUploadedAttachments(original.attachments);
        history.push('/app/myDocumentContainer');
      }
    }
  };

  render() {
    const { content, setActiveMyDocument, employee } = this.props;
    const { openCreateModal } = this.state;

    const columns = [
      { key: 'label', label: 'Title' },
      { key: 'createdAt', label: 'Created Date', datatype: 'date' },
      {
        key: 'status',
        accessor: r => {
          if (
            r.label ===
            'Recurrence, Relapse During Recovery & Continuing Treatment'
          ) {
            if (r.isApprovedBySupervisor) {
              return 'Approved';
            } else {
              return 'Supervisor Review';
            }
          } else if (r.label === 'Transitional Return to Work Program') {
            if (r.isApprovedBySupervisor && r.isApprovedByHr) {
              return 'Approved';
            }
            if (r.isApprovedBySupervisor && !r.isApprovedByHr) {
              return 'HR Review';
            } else {
              return 'Supervisor Review';
            }
          } else {
            return '--';
          }
        },
        enum: ['Approved', 'HR Review', 'Supervisor Review']
      }
    ];

    return (
      <div style={{ width: '100%' }}>
        <InputRow right>
          <Button
            color="blue"
            text="Add Employee Document"
            onClick={() => {
              this.setState({ openCreateModal: true });
            }}
          />
        </InputRow>
        <List
          data={content}
          dataIsHash
          settings={columns}
          rowClick={this.handleView}
          saveKey="docRepo"
        />
        <Modal
          title="Choose Document Type"
          titleClassName="blueHeader"
          isOpen={openCreateModal}
          onRequestClose={() => this.setState({ openCreateModal: false })}
          hideButtons={true}
        >
          <div className="myDocumentList-reportSelection">
            <div
              className="myDocumentList-button"
              onClick={() =>
                setActiveMyDocument({ attachments: [] }) &&
                history.push(`/app/myDocumentContainer/${employee._id}`)
              }
            >
              <img
                src={require('../../assets/images/Upload Image.png')}
                className="myDocumentList-uploadForms"
                alt="Select For Employee Document Upload"
              />
              <span>Upload Images or Documents</span>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  addUploadedAttachments: payload =>
    dispatch(addUploadedAttachmentsResponse(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeDocumentRepo);
