import React from 'react';

import Signature from './Signature';
import CheckboxGroup from './CheckboxGroup';
import { DatePicker } from './DateTimePicker';
import GoogleMap from './GoogleMap';
import RadioButtons from './RadioButtons';
import Money from './Money';
import { Textbox, Dropdown, EmployeeDropdown } from './';
import Notebox from '../Notebox';

export const optionsMapper = options =>
  options.map((option, index) => ({ value: index, label: option }));

export const FieldRenderer = ({ options, type, ...props }) => {
  return type === 'radiobuttons' ? (
    <RadioButtons
      options={optionsMapper(options)}
      {...props}
      bareValues={false}
    />
  ) : type === 'textarea' ? (
    <Textbox type="textarea" rows={5} {...props} />
  ) : type === 'dateTimePicker' ? (
    <DatePicker pickTime {...props} />
  ) : type === 'datePicker' ? (
    <DatePicker {...props} />
  ) : type === 'dropdown' || type === 'dropUp' ? (
    <Dropdown
      options={optionsMapper(options)}
      searchable
      {...props}
      alphabetize
    />
  ) : type === 'multiselectDown' ||
    type === 'multiselectUp' ||
    type === 'multiselect' ? (
    <Dropdown
      multi
      selectButtons
      options={optionsMapper(options)}
      searchable
      alphabetize
      {...props}
      currentValue={props.currentValue.length > 0 ? props.currentValue : []}
    />
  ) : type === 'multiselectPersonnel' ? (
    <EmployeeDropdown
      searchable
      multi
      group={props.groupId}
      selectButtons
      {...props}
      currentValue={props.currentValue || []}
      bareValues={true}
      employees={props?.employees}
      alphabetize
      permissions={
        props.fieldLabel === 'Additional Witnesses of the Incident:'
          ? [100, 400, 500, 900]
          : null
      }
    />
  ) : type === 'supervisor' ? (
    <EmployeeDropdown
      searchable
      {...props}
      group={props.groupId}
      permissions={[400, 500, 900]}
      currentValue={props.currentValue || []}
      bareValues={true}
      employees={props?.employees}
      alphabetize
    />
  ) : type === 'checkbox' ? (
    <CheckboxGroup
      options={optionsMapper(options)}
      onChange={props.onChange}
      bareValues={false}
      alphabetize
      {...props}
    />
  ) : type === 'textbox' ? (
    <Textbox {...props} />
  ) : type === 'money' ? (
    <Money {...props} />
  ) : type === 'googleAddress' ? (
    <GoogleMap {...props} />
  ) : type === 'signature' ? (
    <Signature questionId={props.id} {...props} />
  ) : type === 'notebox' ? (
    <Notebox note={props.fieldLabel} />
  ) : type === 'blockOfText' ? (
    <div className="customDocumentGenerator-blockText">{props.fieldLabel}</div>
  ) : (
    <></>
  );
};

export default FieldRenderer;
