import React from 'react';
import './svg.css';

export default props => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3136 18.4995H10V17.2498H18.626L18.3136 18.4995ZM24.01 18.4995H20.3752L20.6876 17.2498H25V17.5095L24.01 18.4995ZM22.4124 12.2498H10V13.4995H21.1626L22.4124 12.2498ZM23.991 13.4995L25 12.4906V13.4995H23.991ZM10 8.49954V7.24977H25V8.49954H10ZM25 23.4995H10V22.2498H25V23.4995ZM1.01094 7.67548L2.26071 8.92525L5.2124 6L6.24977 7.03737L2.26071 11L0 8.76572L1.01094 7.67548ZM1.01094 17.6755L2.26071 18.9253L5.2124 16L6.24977 17.0337L2.26071 20.9964L0 18.7621L1.01094 17.6755Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 20L23.346 19.1635L27.8305 14.679L25.321 12.1695L20.8365 16.654L20 20ZM26.0281 11.4624L28.5376 13.9719L29.4803 13.0292C30.1732 12.3362 30.1732 11.2127 29.4803 10.5197C28.7873 9.82676 27.6638 9.82676 26.9708 10.5197L26.0281 11.4624Z"
    />
  </svg>
);
